/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import { SentryIdentifier } from './libs/SentryIdentifier';

import { Routes } from './routes';
import { TermsAndConditionsNagBox } from './pages/TermsAndConditionsNagBox';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Notifications,
  ProvideNotificationContext,
} from './pages/Layout/NavBar/Notifications';
import { auth } from './auth';
import { intercom } from 'libs';

export const App: React.FC = () => {
  return (
    <auth.Wrapper>
      <Box fontFamily="body">
        <ReactQueryDevtools />
        <ProvideNotificationContext>
          <intercom.Provider>
            <Notifications />
            <TermsAndConditionsNagBox />
            <SentryIdentifier />
            <auth.RequireMFASetupWrapper>
              <Routes />
            </auth.RequireMFASetupWrapper>
          </intercom.Provider>
        </ProvideNotificationContext>
      </Box>
    </auth.Wrapper>
  );
};

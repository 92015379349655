"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPAbortError = exports.HTTPRequestError = exports.HTTPUnknownError = exports.HTTPNotFoundError = exports.HTTPAuthError = exports.HTTPClientError = exports.HTTPDeserializationError = exports.HTTPServerError = exports.HTTPError = void 0;
const designed_1 = require("designed");
const DomainError_1 = require("../DomainError");
class HTTPError extends DomainError_1.DomainError {
    constructor() {
        super(...arguments);
        this.statusCode = 500;
    }
    getStringBody() {
        return designed_1.Optional.of(this.details?.response?.data).filter((v) => typeof v === 'string');
    }
    getJSONBody() {
        return designed_1.Optional.of(this.details?.response?.data).filter((v) => typeof v === 'object');
    }
}
exports.HTTPError = HTTPError;
HTTPError.apiCode = 'HTTP';
class HTTPServerError extends HTTPError {
}
exports.HTTPServerError = HTTPServerError;
HTTPServerError.apiCode = 'HTTP_SERVER';
class HTTPDeserializationError extends HTTPServerError {
}
exports.HTTPDeserializationError = HTTPDeserializationError;
HTTPDeserializationError.apiCode = 'HTTP_DESERIALIZATION';
class HTTPClientError extends HTTPError {
}
exports.HTTPClientError = HTTPClientError;
HTTPClientError.apiCode = 'HTTP_CLIENT';
class HTTPAuthError extends HTTPClientError {
}
exports.HTTPAuthError = HTTPAuthError;
HTTPAuthError.apiCode = 'HTTP_AUTH';
class HTTPNotFoundError extends HTTPClientError {
}
exports.HTTPNotFoundError = HTTPNotFoundError;
HTTPNotFoundError.apiCode = 'HTTP_NOT_FOUND';
class HTTPUnknownError extends HTTPError {
}
exports.HTTPUnknownError = HTTPUnknownError;
HTTPUnknownError.apiCode = 'HTTP_UNKNOWN';
class HTTPRequestError extends HTTPError {
}
exports.HTTPRequestError = HTTPRequestError;
HTTPRequestError.apiCode = 'HTTP_REQUEST';
class HTTPAbortError extends HTTPRequestError {
}
exports.HTTPAbortError = HTTPAbortError;
HTTPAbortError.apiCode = 'HTTP_ABORT';

import * as React from 'react';
import {
  Center,
  Flex,
  Image,
  Stack,
  useBreakpointValue,
  Text,
  Link,
} from '@chakra-ui/react';

import { Link as ReactRouterLink } from 'react-router-dom';
import { track } from '../../libs/track';
import image from 'assets/img/login-screen.png';

import LoginFlow from '../../auth/pages/LoginFlow';
import { Logo } from '../../components';

export const LoginPage: React.FC = () => {
  track.useOnMount('Viewed Login Page');

  const isCompactView = useBreakpointValue({ base: true, lg: false });

  return (
    <Center bgGradient="linear(to-b, brand.blue, brand.dark-blue)" minH="100vh">
      <Flex
        layerStyle="base"
        border="none"
        minH="500px"
        w={{ base: '100%', md: '400px', lg: '800px' }}
        m={4}
      >
        <Stack justify="space-between" p={{ base: 4, md: 6 }} w="100%">
          <Flex justify="flex-start">
            <Logo color="brand.blue" height="24px" />
          </Flex>

          <LoginFlow />

          <Text textAlign="center">
            New to Canyou?{' '}
            <Link as={ReactRouterLink} color="brand.blue" to="/signup">
              Sign up
            </Link>{' '}
            today!
          </Text>
        </Stack>

        {!isCompactView && (
          <Image
            w="400px"
            alt="process app"
            borderRadius="0 0.375rem 0.375rem 0"
            shadow="sm"
            src={image}
          />
        )}
      </Flex>
    </Center>
  );
};

import { GoogleConnectPurpose } from '@zap-onboard/api-client';
import { useHandler } from './useHandlerMutation';
import { onError } from '../libs/errorLib';

export const useGoogleConnect = (props: {
  purpose: GoogleConnectPurpose;
  afterSubmit?: () => unknown;
}) => {
  const { afterSubmit } = props;
  const [connectGoogle, isGoogleConnecting] = useHandler(
    (api) => api.identity().exchangeGoogleToken,
    {
      always: {
        onError,
        onSuccess: afterSubmit,
      },
    },
  );

  return {
    isGoogleConnecting,
    connectGoogle: async (args: { identityToken: string }) =>
      connectGoogle({
        googleIdToken: args.identityToken,
        purpose: props.purpose,
      }),
  };
};

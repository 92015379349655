import { Stack } from '@chakra-ui/react';
import { actions } from '@zap-onboard/web-components';

import { useEffect, useState } from 'react';
import { MFA } from '@zap-onboard/api-client';
import { MdCode, MdLockClock, MdTextsms } from 'react-icons/md';
import { AddSMSSourceForm } from './AddSMSSourceForm';
import { VerifySMSForm } from './VerifySMSForm';
import { OTPQRCodeForm } from './OTPQRCodeForm';
import { ConfirmOTPForm } from './ConfirmOTPForm';
import { useAuthState } from '../../useAuthState';
import { isDev } from '../../../helpers';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useHandler } from '../../../hooks/useHandlerMutation';

const MFASetupOptions = (props: {
  select: (type: 'SMS' | 'TOTP' | 'DEV') => unknown;
}) => {
  return (
    <>
      <actions.DetailButton
        onClick={() => props.select('TOTP')}
        icon={MdLockClock}
        title="Google Authenticator"
      >
        Use the Google Authenticator app or any password manager to generate a
        code
      </actions.DetailButton>

      <actions.DetailButton
        onClick={() => props.select('SMS')}
        icon={MdTextsms}
        title="Text Message"
      >
        Use your mobile phone to receive a security code
      </actions.DetailButton>
      {isDev && (
        <actions.DetailButton
          onClick={() => props.select('DEV')}
          icon={MdCode}
          title="Dev"
        >
          Click to verify
        </actions.DetailButton>
      )}
    </>
  );
};

export const SetupMFAContent = (args: { onClose?: () => unknown }) => {
  const { onClose } = args;
  const [type, setType] = useState<'SMS' | 'TOTP' | 'DEV' | null>(null);

  const [secondFactor, setSecondFactor] = useState<
    MFA.SMS.AddResponse | MFA.TOTP.AddResponse
  >();
  const [number, setNumber] = useState<string>('');

  const [addDev] = useHandler((api) => api.identity().mfa.addDevSource, {
    invalidates: () => [['MFA_SOURCES']],
    always: {
      onSuccess: () => {
        onClose?.();
        useAuthState.setState((s) => {
          return {
            user: s.user
              ? {
                  ...s.user,
                  mfaConfigured: true,
                  mfaSource: 'DEV',
                }
              : undefined,
          };
        });
      },
    },
  });
  useEffect(() => {
    if (type === 'DEV') {
      addDev();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Stack spacing={4}>
      {!type && <MFASetupOptions select={setType} />}
      {type === 'DEV' && <Spinner />}

      {type === 'SMS' && !(secondFactor instanceof MFA.SMS.AddResponse) && (
        <AddSMSSourceForm
          onAdded={(factor, number) => {
            setSecondFactor(factor);
            setNumber(number);
          }}
          onBack={() => {
            setType(null);
            setSecondFactor(undefined);
            setNumber('');
          }}
        />
      )}
      {secondFactor instanceof MFA.SMS.AddResponse && (
        <VerifySMSForm
          secondFactorId={secondFactor?.secondFactorId}
          number={number}
          onComplete={() => {
            onClose?.();
            useAuthState.setState((s) => {
              return {
                user: s.user
                  ? {
                      ...s.user,
                      mfaConfigured: true,
                      mfaSource: 'SMS',
                    }
                  : undefined,
              };
            });
          }}
          onBack={() => {
            setSecondFactor(undefined);
          }}
        />
      )}

      {type === 'TOTP' && !secondFactor && (
        <OTPQRCodeForm
          onNext={(factor) => setSecondFactor(factor)}
          onBack={() => {
            setType(null);
            setSecondFactor(undefined);
          }}
        />
      )}

      {secondFactor instanceof MFA.TOTP.AddResponse && (
        <ConfirmOTPForm
          onBack={() => setSecondFactor(undefined)}
          onComplete={() => {
            onClose?.();
            useAuthState.setState((s) => {
              return {
                user: s.user
                  ? {
                      ...s.user,
                      mfaConfigured: true,
                      mfaSource: 'TOTP',
                    }
                  : undefined,
              };
            });
          }}
          secondFactorId={secondFactor.secondFactorId}
        />
      )}
    </Stack>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import * as React from 'react';
import { Button, Center, Flex, Heading, Stack } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
  TaskActionType,
  XeroSyncEmployeeTask as APIXeroSyncEmployeeTask,
  flow,
} from '@zap-onboard/api-client';

import { TaskActionSubmitter } from '../../../../hooks/data/flow/useTaskActionSubmission';
import { InfoBox } from '../../../InfoBox';
import { TaskCompleted } from '../TaskStatus';
import { Spinner } from '../../../Spinner';

import { useHandlerQuery } from '../../../../hooks/useHandlerQuery';
import { ErrorDisplayMany } from '../../../../billing/ErrorDisplay';
import { FormProvider, useForm } from 'react-hook-form';
import { inputs } from '@zap-onboard/web-components';

import { trackWorkCompletion } from '../../../../helpers/tracking';
import { createToast } from '../../../../helpers';
import { SyncToXeroForm } from '../../../../pages/Business/UserDetail/ProfileCard/ProfileCardHeader/SyncToXeroModal/SyncToXeroForm';
import type { BaseTaskPropTypes } from './BaseTaskPropTypes';

const layout = (children: any) => (
  <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
    <Flex
      direction="column"
      justify="space-between"
      h="100%"
      minH={{ md: '360px' }}
    >
      <Stack w="100%">
        <Heading variant="section" textAlign="center">
          Xero
        </Heading>
        {children}
      </Stack>
    </Flex>
  </Stack>
);

type XeroSyncEmployeeTaskProps = {
  task: APIXeroSyncEmployeeTask;
  submitAction: TaskActionSubmitter;
  flow: flow.GetFlows.Flow;
} & BaseTaskPropTypes;

export function XeroSyncEmployeeTask({
  task,
  submitAction,
  flow,
  isSubmitting,
}: XeroSyncEmployeeTaskProps) {
  if (task.taskStateId === 'XERO_SYNC_EMPLOYEE_SYNCED') {
    return layout(<TaskCompleted />);
  }

  if (!task.allowedToAction) {
    return layout(
      <InfoBox
        icon={MdInfoOutline}
        heading="You cannot action this task"
        message="This task does not require your attention"
      />,
    );
  }

  if (!task.syncDetails?.isXeroLinkedToBusiness) {
    return layout(
      <InfoBox
        icon={MdInfoOutline}
        heading="Xero is not connected"
        message="Until you have connected Xero in your businesses settings, you will not be able to complete this task."
      >
        <Center pt={2}>
          <Button as={Link} to="/business/settings">
            Settings
          </Button>
        </Center>
      </InfoBox>,
    );
  }

  if (!task.syncDetails?.employeeProfileComplete) {
    return layout(
      <InfoBox
        icon={MdInfoOutline}
        heading="Incomplete user profile"
        message="Until they complete their profile you will not be able to sync their data to Xero."
      />,
    );
  }

  return layout(
    flow.memberId ? (
      <XeroSyncer
        {...{
          memberId: flow.memberId,
          isSubmitting,
          task,
          details: task.syncDetails,
          submitAction,
        }}
      />
    ) : null,
  );
}

const XeroSyncer: React.FC<{
  task: APIXeroSyncEmployeeTask;
  submitAction: TaskActionSubmitter;
  isSubmitting: boolean;
  memberId: string;
}> = ({ submitAction, task, memberId, isSubmitting }) => {
  const member = useHandlerQuery(
    (api) => api.membership().getMember({ memberId }),
    { key: ['MEMBER', memberId], staleAfter: '15s' },
  );

  return (
    <>
      <ErrorDisplayMany queries={[member]} />
      {!!member.data || <Spinner />}
      {!!member.data && (
        <SyncToXeroForm
          isSaving={isSubmitting}
          member={member.data}
          onSave={({
            sendTaxDetails,
            sendSuperDetails,
            additionalXeroTaxDetails,
            xeroEmployeeId,
          }) => {
            submitAction(TaskActionType.XERO_SYNC_EMPLOYEE_PERFORM, {
              taskId: task.taskId,
              sendSuperDetails,
              sendTaxDetails,
              additionalXeroTaxDetails,
              xeroEmployeeId,
            })?.tap(() => {
              trackWorkCompletion({
                workType: 'Administrative',
                workDetail: 'Xero',
              });
              createToast({
                status: 'success',
                description: 'Xero sync successful',
              });
            });
          }}
        />
      )}
    </>
  );
};

const CREATE_EMPLOYEE = 'CREATE_NEW_EMPLOYEE';
// This is no longer the real form. Don't care enough to update at this moment
export const XeroSyncEmployeeTaskPreview = () => {
  const form = useForm();

  const trueButton = (
    <inputs.radio.Button value="true">Yes</inputs.radio.Button>
  );

  const falseButton = (
    <inputs.radio.Button value="false">No</inputs.radio.Button>
  );

  return layout(
    <FormProvider {...form}>
      <Stack spacing={8}>
        <inputs.SelectInput
          label="Sync as"
          isDisabled
          name="xeroEmployeeAction"
          options={[{ name: 'Create New Employee', value: CREATE_EMPLOYEE }]}
        />

        <inputs.radio.Group
          name="sendTaxDetails"
          isDisabled
          label="Sync tax declaration?"
        >
          <Stack spacing={2} mt={1}>
            {trueButton}
            {falseButton}
          </Stack>
        </inputs.radio.Group>

        <inputs.radio.Group
          isDisabled
          name="sendSuperDetails"
          label="Sync super choice?"
        >
          <Stack spacing={2} mt={1}>
            {trueButton}
            {falseButton}
          </Stack>
        </inputs.radio.Group>
      </Stack>
    </FormProvider>,
  );
};

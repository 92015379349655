import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { StripeBillingAccountListingResponse } from '@zap-onboard/api-client';
import {
  DefaultBillingAccountDetails,
  UpdateBillingAccountDetailsForm,
} from './UpdateBillingAccountDetailsForm';

export const BillingDetailsModal = (args: {
  billingAccount: StripeBillingAccountListingResponse;
  disclosure: ReturnType<typeof useDisclosure>;
  defaults: DefaultBillingAccountDetails;
}) => {
  const { isOpen, onClose } = args.disclosure;

  return (
    <Modal closeOnOverlayClick isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">Update billing account</ModalHeader>
        <ModalBody fontSize="sm" pb={6}>
          <UpdateBillingAccountDetailsForm
            billingAccountId={args.billingAccount.billingAccountId}
            defaults={args.defaults}
            onSaved={onClose}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

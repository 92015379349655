"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateBusinessConfig = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const SuperFundResponse_1 = require("../../utils/SuperFundResponse");
var PrivateBusinessConfig;
(function (PrivateBusinessConfig) {
    class BillingAccount extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, tslib_1.__metadata)("design:type", String)
    ], BillingAccount.prototype, "billingAccountId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, tslib_1.__metadata)("design:type", String)
    ], BillingAccount.prototype, "billingAccountName", void 0);
    PrivateBusinessConfig.BillingAccount = BillingAccount;
    class DefaultAccessRequestPosition extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], DefaultAccessRequestPosition.prototype, "positionId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], DefaultAccessRequestPosition.prototype, "name", void 0);
    PrivateBusinessConfig.DefaultAccessRequestPosition = DefaultAccessRequestPosition;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "linkToDeputyURL", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Response.prototype, "isDeputyConnected", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "xeroTenantName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "xeroShortCode", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Response.prototype, "isXeroConnected", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Response.prototype, "isMyobAuthorized", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Response.prototype, "isCloudPayrollAuthorised", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "myobCompanyFileName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(SuperFundResponse_1.SuperFundResponse),
        (0, tslib_1.__metadata)("design:type", SuperFundResponse_1.SuperFundResponse)
    ], Response.prototype, "nominatedSuperFund", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(BillingAccount),
        (0, tslib_1.__metadata)("design:type", BillingAccount)
    ], Response.prototype, "billingAccount", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(DefaultAccessRequestPosition),
        (0, tslib_1.__metadata)("design:type", DefaultAccessRequestPosition)
    ], Response.prototype, "defaultAccessRequestPosition", void 0);
    PrivateBusinessConfig.Response = Response;
})(PrivateBusinessConfig = exports.PrivateBusinessConfig || (exports.PrivateBusinessConfig = {}));

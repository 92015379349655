import { Grid, GridItem, Heading } from '@chakra-ui/react';
import { LoadingStateQuery } from '../../../../../components';
import { useHandlerQuery } from '../../../../../hooks';
import { track } from '../../../../../libs';

import { PageLayoutContainer } from '../../../Layout';
import { NotificationSettingsForm } from './NotificationSettingsForm';

export const NotificationSettingsPage = () => {
  track.useOnMount('Viewed My Notification Settings');
  const settings = useHandlerQuery(
    (api) => api.notification().getMySettings(),
    {
      key: ['MY_NOTIFICATION_SETTINGS'],
    },
  );

  return (
    <PageLayoutContainer>
      <Heading variant="page">Notification Settings</Heading>
      <Grid
        w="100%"
        templateColumns="1fr"
        templateRows={{ base: '1fr 1fr', lg: '1fr' }}
        gap={4}
      >
        <GridItem
          colStart={{ base: 1, lg: 1 }}
          colSpan={1}
          rowStart={{ base: 1, lg: 1 }}
          rowSpan={1}
        >
          <LoadingStateQuery queries={[settings]} />
          {settings.data && (
            <NotificationSettingsForm settings={settings.data} />
          )}
        </GridItem>
      </Grid>
    </PageLayoutContainer>
  );
};

export const prettyTaskStatus = (taskStateId?: string): string => {
  let status;
  if (taskStateId) {
    status = {
      PROOF_OF_IDENTITY_UNSUBMITTED: 'Awating submission',
      PROOF_OF_IDENTITY_SUBMITTED: 'Awaiting approval',
      PROOF_OF_IDENTITY_APPROVED: 'Approved',
      PROOF_OF_IDENTITY_REJECTED: 'Rejected',
      META_SKIPPED: 'Skipped',
    }[taskStateId];
  }
  return status || 'Preview';
};

export const primaryPhotographicIdentificationDocuments = [
  'A driver licence or permit from Australia or overseas, including a digital driver licence',
  'An Australian passport',
  'A government proof of age card issued in Australia',
  'A foreign passport issued by a foreign government or the United Nations',
  'International travel documents issued by a foreign government or the United Nations',
  'A national identity card issued by a foreign government or the United Nations',
  'An ImmiCard provided by the Department of Home Affairs',
];

export const primaryNonPhotographicIdentificationDocuments = [
  'An Australian birth certificate, birth extract or citizenship certificate',
  'A foreign birth certificate or citizenship certificate',
  'A government issued concession card, such as a pensioner concession card, a health care card, or a senior’s health care card.',
];

export const secondaryIdentificationDocuments = [
  'A notice from the ATO or other government agency, such as Centrelink, that contains the individual’s name and residential address, issued in the past 12 months',
  'A municipal council rates notice or a utilities bill (such as a water, gas or electricity bill) that contains the individual’s name and residential address, issued in the past three months',
  'A Medicare card',
  'For an individual aged under 18, a letter from a school principal issued in the past three months that details the individual’s name, residential address and when they attended the school, or a student card if available',
  'Electoral roll details (checked against www.aec.gov.au/).',
];

import { UpdateVideoRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useUpdateVideo = (afterSubmit?: () => unknown) => {
  const [update, updateIsLoading] = useHandler(
    (api) => api.video().updateVideo,
    {
      invalidates: () => [['VIDEOS']],
      always: {
        onSuccess: async () => {
          if (afterSubmit) {
            await afterSubmit();
          }
        },
      },
    },
  );

  return {
    update: (data: Entity.Attributes<UpdateVideoRequest>) => update(data),
    updateIsLoading,
  };
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import { Center, Flex, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import { track } from '../../libs/track';

import { Logo } from '../../components/Logo';
import { CreateAccessRequest } from './CreateAccessRequest';
import { LandingSection } from './LandingSection/LandingSection';
import { EmailSection } from './EmailSection';
import { CheckEmailSection } from './CheckEmailSection/CheckEmailSection';
import { useHandlerQuery } from '../../hooks/useHandlerQuery';
import { ErrorDisplay } from '../../billing/ErrorDisplay';
import { auth } from '../../auth';

const { useEffect, useState } = React;

enum Stages {
  INITIAL_STATE,
  EMAIL_LOGIN_OR_REGISTRATION,
  CHECK_EMAIL,
}

export const RequestAccessToBusiness: React.FC = () => {
  track.useOnMount('Viewed Request Access Page');

  const { businessId } = useParams<{ businessId: string }>();
  const memberships = auth.useMemberships();
  const userProfile = auth.useProfile();
  const history = useHistory();

  const [email, setEmail] = useState<string>('');
  const [stage, setStage] = useState<Stages>(Stages.INITIAL_STATE);

  const business = useHandlerQuery((api) => api.business().get(businessId), {
    key: ['BUSINESS', businessId],
    staleAfter: '5m',
  });

  useEffect(() => {
    if (memberships.find((m) => m.businessId === businessId)) {
      history.push('/');
    }
  }, [businessId, history, memberships]);

  if (!business.data) {
    return (
      <Layout>
        <ErrorDisplay {...business} />
        <Spinner />
      </Layout>
    );
  }

  if (!userProfile) {
    return (
      <Layout>
        <ErrorDisplay {...business} />
        {stage === Stages.INITIAL_STATE && (
          <LandingSection
            business={business.data}
            nextStage={() => setStage(Stages.EMAIL_LOGIN_OR_REGISTRATION)}
          />
        )}
        {stage === Stages.EMAIL_LOGIN_OR_REGISTRATION && (
          <EmailSection
            businessId={businessId}
            nextStage={() => setStage(Stages.CHECK_EMAIL)}
            setEmail={setEmail}
          />
        )}
        {stage === Stages.CHECK_EMAIL && <CheckEmailSection email={email} />}
      </Layout>
    );
  }

  return (
    <Layout>
      <CreateAccessRequest business={business.data} userProfile={userProfile} />
    </Layout>
  );
};

const Layout: React.FC = ({ children }) => (
  <Center
    bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
    minH="100vh"
    minW="100vw"
  >
    <Flex
      layerStyle="base"
      w={{ base: '90%', md: '70%', lg: '60%', xl: '50%' }}
      minH="400px"
      m={3}
      p={3}
      justify="center"
    >
      <Grid templateColumns="1fr" templateRows="1fr auto" w="100%">
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
          <Center h="100%">{children}</Center>
        </GridItem>
        <GridItem colStart={1} colSpan={1} rowStart={2} rowSpan={1}>
          <Logo color="brand.blue" height="28px" />
        </GridItem>
      </Grid>
    </Flex>
  </Center>
);

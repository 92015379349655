/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormErrorMessage,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import {
  FileClaim,
  FileUploadFor,
  FileUploadTargetType,
  TaskActionType,
} from '@zap-onboard/api-client';
import { useForm } from 'react-hook-form';
import { TaskActionSubmitter } from '../../../../../hooks/data/flow/useTaskActionSubmission';
import { DocumentUpload } from '../../../../DocumentUpload/DocumentUpload';
import { InfoBox } from '../../../../InfoBox/InfoBox';

export const UploadEsignDocument = (props: {
  taskId: string;
  submitAction: TaskActionSubmitter;
}) => {
  const { taskId, submitAction } = props;
  const { control, handleSubmit, errors } = useForm<{ claim: FileClaim }>();

  const submit = handleSubmit(async ({ claim }) => {
    await submitAction(TaskActionType.ESIGN_ATTACH_DOCUMENT, {
      claim,
      taskId,
    });
  });

  return (
    <Stack>
      <InfoBox message="You will need to upload a file for the user to sign before they can action this task" />
      <FormControl isInvalid={!!errors.claim}>
        <Center>
          <DocumentUpload
            controlled={{
              control,
              name: 'claim',
              rules: { required: 'A file must be provided' },
            }}
            uploadedFor={{ type: FileUploadFor.MY_BUSINESS }}
            allowedTypes={Object.values(FileUploadTargetType)}
          />
        </Center>
        <Center>
          <FormErrorMessage w="100%" textAlign="center">
            {(errors.claim as any)?.message}
          </FormErrorMessage>
        </Center>
      </FormControl>

      <Spacer minH={12} />

      <Center>
        <ButtonGroup>
          <Button onClick={submit}>Confirm</Button>
        </ButtonGroup>
      </Center>
    </Stack>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permissions = void 0;
const errors_1 = require("@zap-onboard/errors");
const constants_1 = require("./constants");
class Permissions {
    constructor(permissions) {
        this.abilities = permissions.flatMap((permission) => constants_1.PermissionDefinitions[permission]);
    }
    static fromRoleId(roleId) {
        return new Permissions(constants_1.rolePermissions[roleId] ?? []);
    }
    can(ability) {
        return this.abilities.includes(ability);
    }
    requireOrThrow(ability) {
        if (this.can(ability)) {
            return;
        }
        throw errors_1.errs.AuthorizationError.create(`You cannot ${ability}`);
    }
}
exports.Permissions = Permissions;

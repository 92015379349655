/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Text,
  Grid,
  Center,
  GridItem,
  Button,
  Stack,
  Heading,
  Link,
} from '@chakra-ui/react';
import { XeroMyConnectionDetailsResponseConnection } from '@zap-onboard/api-client';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { auth } from '../../../../../../auth';
import { Logo } from '../../../../../../components';
import { logError } from '../../../../../../libs/errorLib';
import { useAPI } from '../../../../../../services/API';
import { SetupBusinessForm } from '../SetupBusinessForm';

enum STAGES {
  WELCOME,
  REGISTRATION_FORM,
}

export const SetupBusinessAfterRegisteringXeroPage = () => {
  const [stage, setStage] = useState(STAGES.WELCOME);

  const managers = auth.useBusinessManagers();
  const userProfile = auth.useProfile();
  const isXeroLead = auth.useIsXeroLead();

  const history = useHistory();
  const [xeroOrg, setXeroOrg] =
    useState<XeroMyConnectionDetailsResponseConnection>();
  const api = useAPI();

  useEffect(() => {
    if ((managers.length || 0) > 0) {
      history.push('/');
    }
  });

  useEffect(() => {
    api
      .xero()
      .getMyConnectionDetails()
      .tap(
        (connection) =>
          connection.map(({ connections }) => setXeroOrg(connections[0])),
        logError,
      );
  }, [api]);

  return (
    <Center
      align="center"
      bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
      justify="center"
      minW="100vw"
      minH="100vh"
    >
      <Grid
        templateColumns="1fr"
        layerStyle="base"
        m={4}
        w={{ base: '90%', md: '70%', lg: '60%' }}
        maxW="600px"
        minH="400px"
      >
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1} mt={4}>
          <Logo color="brand.blue" height="32px" />
        </GridItem>

        {stage === STAGES.WELCOME && (
          <Welcome setStage={setStage} name={userProfile?.givenName} />
        )}

        {stage === STAGES.REGISTRATION_FORM && (
          <Form xeroOrg={xeroOrg} isXeroLead={isXeroLead} setStage={setStage} />
        )}
      </Grid>
    </Center>
  );
};

const Form = (props: {
  setStage: (stage: STAGES) => unknown;
  xeroOrg: XeroMyConnectionDetailsResponseConnection | undefined;
  isXeroLead?: boolean;
}) => {
  const api = useAPI();
  const { xeroConnectedOrganizationId } = props.xeroOrg ?? {};
  return (
    <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1} mt={4} p={12}>
      <SetupBusinessForm
        onCancel={() => props.setStage(STAGES.WELCOME)}
        isXeroLead={props.isXeroLead}
        xeroOrg={props.xeroOrg}
        afterSetup={async (businessId) =>
          props.xeroOrg?.xeroConnectedOrganizationId
            ? api
                .xero()
                .setBusinessConnection({
                  businessId,
                  xeroConnectedOrganizationId,
                })
                .tap(() => {}, logError)
            : undefined
        }
      />
    </GridItem>
  );
};

const Welcome = (props: {
  name?: string;
  setStage: (stage: STAGES) => unknown;
}) => (
  <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1} mt={4} p={12}>
    <Center h="100%">
      <Stack mt={3} px={3} spacing={4} align="center">
        <Heading as="h1" fontSize="xl">
          {props.name ? `Welcome ${props.name}!` : 'Welcome!'}
        </Heading>
        <Text mb={4}>
          For product information, documentation, and support{' '}
          <Link isExternal color="brand.blue" href="https://canyou.com.au">
            visit our website.
          </Link>
        </Text>
        <Button onClick={() => props.setStage(STAGES.REGISTRATION_FORM)}>
          Start 30 Day Trial
        </Button>
      </Stack>
    </Center>
  </GridItem>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoList = exports.Video = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const RemoteFile_1 = require("../../file/RemoteFile");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
class Video extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Video.prototype, "videoId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Video.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], Video.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true, entity: RemoteFile_1.RemoteFile }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", Object)
], Video.prototype, "thumbnailClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], Video.prototype, "videoClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], Video.prototype, "uploadedAt", void 0);
exports.Video = Video;
class VideoList extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: Video }),
    (0, class_validator_1.IsInstance)(Video, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], VideoList.prototype, "videos", void 0);
exports.VideoList = VideoList;

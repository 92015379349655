var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
export var Row = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'standard' : _b, heading = _a.heading, subheading = _a.subheading, _c = _a.layerStyle, layerStyle = _c === void 0 ? 'base' : _c, _d = _a.p, p = _d === void 0 ? 4 : _d, renderCenter = _a.renderCenter, renderEnd = _a.renderEnd, onClick = _a.onClick, _wrapper = _a._wrapper;
    return (_jsxs(Grid, __assign({ templateRows: "auto", templateColumns: calculateColumns(type), layerStyle: layerStyle, p: p, gridColumnGap: 2, onClick: onClick, role: onClick ? 'button' : undefined, cursor: onClick ? 'pointer' : undefined, tabIndex: onClick ? 0 : undefined, _hover: {
            bg: onClick ? 'gray.50' : undefined,
        } }, _wrapper, { children: [_jsx(Headings, { heading: heading, subheading: subheading }, void 0), _jsx(CenterContent, { type: type, renderCenter: renderCenter }, void 0), _jsx(EndContent, { type: type, renderEnd: renderEnd }, void 0)] }), void 0));
};
var Headings = function (_a) {
    var heading = _a.heading, subheading = _a.subheading;
    return (_jsx(GridItem, __assign({ colStart: 1, colSpan: 1, rowStart: 1, rowSpan: 2 }, { children: _jsxs(Stack, __assign({ spacing: 0, h: "100%", justifyContent: "center" }, { children: [_jsx(RenderHeading, { type: "heading", content: heading }, void 0), subheading && _jsx(RenderHeading, { type: "subheading", content: subheading }, void 0)] }), void 0) }), void 0));
};
var CenterContent = function (_a) {
    var type = _a.type, renderCenter = _a.renderCenter;
    if (type === 'standard') {
        return (_jsx(GridItem, __assign({ colStart: 2, colSpan: 1, rowStart: 1, rowSpan: 2 }, { children: typeof renderCenter === 'function' ? renderCenter() : renderCenter }), void 0));
    }
    return null;
};
var EndContent = function (_a) {
    var type = _a.type, renderEnd = _a.renderEnd;
    if (type === 'standard' || type === 'compact') {
        return (_jsx(GridItem, __assign({ colStart: type === 'compact' ? 2 : 3, colSpan: 1, rowStart: 1, rowSpan: 2 }, { children: typeof renderEnd === 'function' ? renderEnd() : renderEnd }), void 0));
    }
    return null;
};
var RenderHeading = function (_a) {
    var type = _a.type, content = _a.content;
    if (typeof content === 'string') {
        return (_jsx(Box, __assign({ maxWidth: { base: '160px', md: 'none' } }, { children: _jsx(Text, __assign({ fontSize: type === 'subheading' ? 'sm' : 'md', color: type === 'subheading' ? 'brand.dark-gray' : 'brand.black', noOfLines: 2 }, { children: content }), void 0) }), void 0));
    }
    if (typeof content === 'function') {
        return content();
    }
    return null;
};
function calculateColumns(type) {
    switch (type) {
        case 'simple':
            return 'auto';
        case 'compact':
            return '60% 1fr';
        case 'standard':
            return '50% 1fr 1fr';
        default:
            throw new Error('Unknown row configuration');
    }
}

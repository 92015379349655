"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACN = void 0;
const errors_1 = require("@zap-onboard/errors");
const acnWeights = [8, 7, 6, 5, 4, 3, 2, 1];
// https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/
// https://drive.google.com/file/d/1hNh4TMCKhdLPkBfh7bW-lL07qfWdLfWW/view?usp=sharing
class ACN {
    constructor(value) {
        this.value = value;
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('ACN was given a non string value');
        }
        const onlyDigits = s.replace(/\D/g, '');
        const acn = new ACN(onlyDigits);
        acn.validate();
        return acn;
    }
    static isValid(s) {
        return ACN.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            ACN.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    static fake() {
        return ACN.fromJSON('000 000 019');
    }
    asJSON() {
        return this.value;
    }
    isValidLength() {
        return this.value.length === 9;
    }
    asNumbers() {
        const numbers = this.value.split('').map(Number);
        numbers.forEach((n) => {
            if (Number.isNaN(n)) {
                throw errors_1.errs.ValidationError.create('ACN is not a number');
            }
        });
        return numbers;
    }
    calculateCheckDigit() {
        const first8Numbers = this.asNumbers().slice(0, -1);
        const numbersSum = first8Numbers.reduce((sum, number, index) => {
            const weightedNumber = number * acnWeights[index];
            return sum + weightedNumber;
        }, 0);
        const remainder = numbersSum % 10;
        const complement = 10 - remainder;
        return complement === 10 ? 0 : complement;
    }
    validate() {
        if (!this.isValidLength) {
            throw errors_1.errs.ValidationError.create('ACN must be exactly 9 digits');
        }
        const calculatedCheckDigit = this.calculateCheckDigit();
        if (this.asNumbers()[8] !== calculatedCheckDigit) {
            throw errors_1.errs.ValidationError.create('ACN checksum validation failed');
        }
    }
}
exports.ACN = ACN;

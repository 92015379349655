"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemporaryRemoteFile = exports.RemoteFile = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const FileClaim_1 = require("./FileClaim");
const FileUploadTargetType_1 = require("./FileUploadTargetType");
class RemoteFile extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], RemoteFile.prototype, "fileId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], RemoteFile.prototype, "claim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], RemoteFile.prototype, "url", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(FileUploadTargetType_1.FileUploadTargetType),
    (0, tslib_1.__metadata)("design:type", String)
], RemoteFile.prototype, "type", void 0);
exports.RemoteFile = RemoteFile;
class TemporaryRemoteFile extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemporaryRemoteFile.prototype, "url", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(FileUploadTargetType_1.FileUploadTargetType),
    (0, tslib_1.__metadata)("design:type", String)
], TemporaryRemoteFile.prototype, "type", void 0);
exports.TemporaryRemoteFile = TemporaryRemoteFile;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSignatureSVGDataURL = exports.createSignatureSVG = exports.getFallbackFontName = exports.getDefaultFont = exports.fonts = exports.BLANK_PDF = exports.DEFAULT_FONT_NAME = exports.DEFAULT_FONT_COLOR = exports.DEFAULT_CHARACTER_SPACING = exports.DEFAULT_LINE_HEIGHT = exports.DEFAULT_ALIGNMENT = exports.DEFAULT_FONT_SIZE = void 0;
var constants_1 = require("./constants");
Object.defineProperty(exports, "DEFAULT_FONT_SIZE", { enumerable: true, get: function () { return constants_1.DEFAULT_FONT_SIZE; } });
Object.defineProperty(exports, "DEFAULT_ALIGNMENT", { enumerable: true, get: function () { return constants_1.DEFAULT_ALIGNMENT; } });
Object.defineProperty(exports, "DEFAULT_LINE_HEIGHT", { enumerable: true, get: function () { return constants_1.DEFAULT_LINE_HEIGHT; } });
Object.defineProperty(exports, "DEFAULT_CHARACTER_SPACING", { enumerable: true, get: function () { return constants_1.DEFAULT_CHARACTER_SPACING; } });
Object.defineProperty(exports, "DEFAULT_FONT_COLOR", { enumerable: true, get: function () { return constants_1.DEFAULT_FONT_COLOR; } });
Object.defineProperty(exports, "DEFAULT_FONT_NAME", { enumerable: true, get: function () { return constants_1.DEFAULT_FONT_NAME; } });
Object.defineProperty(exports, "BLANK_PDF", { enumerable: true, get: function () { return constants_1.BLANK_PDF; } });
Object.defineProperty(exports, "fonts", { enumerable: true, get: function () { return constants_1.fonts; } });
var helpers_1 = require("./helpers");
Object.defineProperty(exports, "getDefaultFont", { enumerable: true, get: function () { return helpers_1.getDefaultFont; } });
Object.defineProperty(exports, "getFallbackFontName", { enumerable: true, get: function () { return helpers_1.getFallbackFontName; } });
var signature_1 = require("./signature");
Object.defineProperty(exports, "createSignatureSVG", { enumerable: true, get: function () { return signature_1.createSignatureSVG; } });
Object.defineProperty(exports, "createSignatureSVGDataURL", { enumerable: true, get: function () { return signature_1.createSignatureSVGDataURL; } });

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateFileUploadTargetRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const IsInstanceOfAny_1 = require("../utils/validations/IsInstanceOfAny");
const FileUploadTargetType_1 = require("./FileUploadTargetType");
const FileUploadFor_1 = require("./FileUploadFor");
const FILE_UPLOAD_FOR_CLASSES_1 = require("./FILE_UPLOAD_FOR_CLASSES");
class CreateFileUploadTargetRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(FileUploadTargetType_1.FileUploadTargetType),
    (0, tslib_1.__metadata)("design:type", String)
], CreateFileUploadTargetRequest.prototype, "targetType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({
        taggedUnion: {
            getTag: (data) => data.type,
            handlers: {
                TASK: FileUploadFor_1.FileUploadForTask,
                MY_BUSINESS: FileUploadFor_1.FileUploadForMyBusiness,
                MEMBER: FileUploadFor_1.FileUploadForMember,
                CERTIFICATION: FileUploadFor_1.FileUploadForCertification,
                VIDEO: FileUploadFor_1.FileUploadForVideo,
            },
        },
    }),
    (0, IsInstanceOfAny_1.IsInstanceOfAny)(FILE_UPLOAD_FOR_CLASSES_1.FILE_UPLOAD_FOR_CLASSES),
    (0, tslib_1.__metadata)("design:type", void 0)
], CreateFileUploadTargetRequest.prototype, "uploadedFor", void 0);
exports.CreateFileUploadTargetRequest = CreateFileUploadTargetRequest;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactClient = exports.UpsertContactIndividualRequest = exports.UpdateContactRequest = exports.CreateContactRequest = exports.InviteContactPersonRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const utils_1 = require("../utils");
const ResponseBase_1 = require("../ResponseBase");
const handler_1 = require("../utils/handler");
const contact_1 = require("./models/contact");
class InviteContactPersonRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], InviteContactPersonRequest.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], InviteContactPersonRequest.prototype, "firstName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], InviteContactPersonRequest.prototype, "lastName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], InviteContactPersonRequest.prototype, "sendInvite", void 0);
exports.InviteContactPersonRequest = InviteContactPersonRequest;
class CreateContactRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MinLength)(1),
    (0, tslib_1.__metadata)("design:type", String)
], CreateContactRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], CreateContactRequest.prototype, "companyNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], CreateContactRequest.prototype, "isSupplier", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], CreateContactRequest.prototype, "isCustomer", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateContactRequest.prototype, "xeroSyncedContactId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: InviteContactPersonRequest, iterable: true }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(InviteContactPersonRequest, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], CreateContactRequest.prototype, "persons", void 0);
exports.CreateContactRequest = CreateContactRequest;
class UpdateContactRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateContactRequest.prototype, "contactId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.MinLength)(1),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateContactRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpdateContactRequest.prototype, "companyNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpdateContactRequest.prototype, "isSupplier", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpdateContactRequest.prototype, "isCustomer", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpdateContactRequest.prototype, "isArchived", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpdateContactRequest.prototype, "xeroSyncedContactId", void 0);
exports.UpdateContactRequest = UpdateContactRequest;
class UpsertContactIndividualRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpsertContactIndividualRequest.prototype, "contactId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpsertContactIndividualRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpsertContactIndividualRequest.prototype, "isActive", void 0);
exports.UpsertContactIndividualRequest = UpsertContactIndividualRequest;
class ContactClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.createContact = (0, handler_1.handler)(this, async (contact) => this.http
            .request()
            .to('/contacts')
            .bodyJSON(CreateContactRequest.create(contact))
            .post((d) => contact_1.Contact.create(d)));
        this.getContacts = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/contacts')
            .get((d) => contact_1.ContactList.create(d)));
        this.getContact = (0, handler_1.handler)(this, async (contactId) => this.http
            .request()
            .to(`/contacts/${contactId}`)
            .get((d) => contact_1.Contact.create(d)));
        this.updateContact = (0, handler_1.handler)(this, async (contact) => this.http
            .request()
            .to(`/contact/update`)
            .bodyJSON(UpdateContactRequest.create(contact))
            .post());
        this.upsertContactIndividual = (0, handler_1.handler)(this, async (contactIndividual) => this.http
            .request()
            .to('/contact-individuals')
            .bodyJSON(UpsertContactIndividualRequest.create(contactIndividual))
            .post());
    }
}
exports.ContactClient = ContactClient;

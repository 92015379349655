import { Heading, Stack, Text } from '@chakra-ui/react';
import { VisaCheck } from '@zap-onboard/api-client';

type Props = {
  visaCheck: VisaCheck;
};

export const LineItem = ({
  label,
  details,
}: {
  label: string;
  details?: string;
}) => (
  <>
    <Text color="brand.dark-gray">{label}</Text>
    <Text>{details || '-'}</Text>
  </>
);

export const VisaHistoryCheckDetails: React.FC<Props> = ({
  visaCheck: {
    givenNames,
    familyName,
    dateOfBirth,
    documentId,
    documentType,
    countryOfIssue,
  },
}) => {
  return (
    <Stack px={8} mx={8}>
      <Heading size="small">Submission details</Heading>
      <LineItem label="Document type" details={documentType} />
      <LineItem label="Given names" details={givenNames} />
      <LineItem label="Family names" details={familyName} />
      <LineItem label="Date of Birth" details={dateOfBirth?.asDDMonthYYYY()} />
      <LineItem label="Document id" details={documentId} />
      <LineItem label="Country of issue" details={countryOfIssue?.name} />
    </Stack>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripeBillingAccountPaymentMethod = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
class StripeBillingAccountPaymentMethod extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], StripeBillingAccountPaymentMethod.prototype, "expiresAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], StripeBillingAccountPaymentMethod.prototype, "last4Digits", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], StripeBillingAccountPaymentMethod.prototype, "brand", void 0);
exports.StripeBillingAccountPaymentMethod = StripeBillingAccountPaymentMethod;

import * as React from 'react';
import { Center, Flex, Spacer } from '@chakra-ui/react';

import { useCompactView } from '../../../hooks/useCompactView';

import { Logo } from '../../../components/Logo';
import { AvatarDropDown } from './AvatarDropDown';
import { NavBarMenu } from './Menu';
import { SelectBusinessDropdown } from '../SelectBusinessDropdown';
import { NotificationDrawerTrigger } from './Notifications';
import { useHistory } from 'react-router-dom';

type NavBarProps = {
  navBarHeight: string;
};

export const NavBar: React.FC<NavBarProps> = ({ navBarHeight }) => {
  const { isCompactView } = useCompactView();
  const history = useHistory();

  const handleLogout = async () => {
    history.push('/logout');
  };

  return (
    <Flex
      bg={isCompactView ? 'brand.blue' : '#fff'}
      h={navBarHeight}
      px={{ base: 4, sm: 8 }}
      shadow="sm"
    >
      <Flex align="center" h="100%">
        <Logo
          color={isCompactView ? 'white' : 'brand.blue'}
          height="32px"
          link="/"
        />
      </Flex>
      <Spacer />
      {isCompactView ? (
        <NavBarMenu />
      ) : (
        <Flex align="center" gridColumnGap={1}>
          <Center px={2} minWidth={300}>
            <SelectBusinessDropdown />
          </Center>
          <AvatarDropDown handleLogout={handleLogout} />
          <NotificationDrawerTrigger />
        </Flex>
      )}
    </Flex>
  );
};

import { AccessRequestStatus } from '@zap-onboard/api-client';
import { useHandlerQuery } from '../../useHandlerQuery';

export const useAccessRequestsToBusiness = () =>
  useHandlerQuery(
    (api) =>
      api.membership().accessRequest.forMyBusiness({
        status: AccessRequestStatus.REQUESTED,
      }),
    {
      key: ['BUSINESS_ACCESS_REQUESTS'],
    },
  );

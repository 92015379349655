"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientVerificationTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const BaseTask_1 = require("../Task/BaseTask");
const ClientVerificationSubmission_1 = require("./ClientVerificationSubmission");
class ClientVerificationTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(ClientVerificationSubmission_1.ClientVerification),
    (0, tslib_1.__metadata)("design:type", ClientVerificationSubmission_1.ClientVerification)
], ClientVerificationTask.prototype, "clientVerificationSubmission", void 0);
exports.ClientVerificationTask = ClientVerificationTask;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = exports.StartFlowRequest = exports.PrintableTasks = exports.SubmitTaskActionResponse = exports.GetTaskResponse = exports.GetBusinessFlowsRequest = exports.CancelFlowRequest = exports.ArchiveFlowRequest = exports.GetMyFlowsRequest = exports.TaskAssignmentResolutionRequest = exports.TaskAssignmentResolutionRequestGroup = exports.TaskAssignmentResolutionResponse = exports.TaskAssignmentResolutionResponseGroupMember = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../file");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
const handler_1 = require("../utils/handler");
const IsInstanceOfAny_1 = require("../utils/validations/IsInstanceOfAny");
const FlowStatus_1 = require("./FlowStatus");
const models_1 = require("./models");
const GetPrerequisites_1 = require("./models/GetPrerequisites");
const task_1 = require("./task");
const TaskActionRequest_1 = require("./task/TaskAction/TaskActionRequest");
class TaskAssignmentResolutionResponseGroupMember extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskAssignmentResolutionResponseGroupMember.prototype, "groupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskAssignmentResolutionResponseGroupMember.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskAssignmentResolutionResponseGroupMember.prototype, "userName", void 0);
exports.TaskAssignmentResolutionResponseGroupMember = TaskAssignmentResolutionResponseGroupMember;
class TaskAssignmentResolutionResponse extends ResponseBase_1.ResponseBase {
    find(groupId) {
        return this.grouped()[groupId] ?? [];
    }
    grouped() {
        const mapped = {};
        this.members.forEach((member) => {
            var _a;
            mapped[_a = member.groupId] ?? (mapped[_a] = []);
            mapped[member.groupId].push(member);
        });
        return mapped;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TaskAssignmentResolutionResponseGroupMember }),
    (0, class_validator_1.IsInstance)(TaskAssignmentResolutionResponseGroupMember, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], TaskAssignmentResolutionResponse.prototype, "members", void 0);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Function),
    (0, tslib_1.__metadata)("design:paramtypes", []),
    (0, tslib_1.__metadata)("design:returntype", void 0)
], TaskAssignmentResolutionResponse.prototype, "grouped", null);
exports.TaskAssignmentResolutionResponse = TaskAssignmentResolutionResponse;
class TaskAssignmentResolutionRequestGroup extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskAssignmentResolutionRequestGroup.prototype, "groupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: task_1.TaskAssignmentRequest }),
    (0, class_validator_1.IsInstance)(task_1.TaskAssignmentRequest, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], TaskAssignmentResolutionRequestGroup.prototype, "assignments", void 0);
exports.TaskAssignmentResolutionRequestGroup = TaskAssignmentResolutionRequestGroup;
class TaskAssignmentResolutionRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskAssignmentResolutionRequest.prototype, "subjectMemberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TaskAssignmentResolutionRequestGroup }),
    (0, class_validator_1.IsInstance)(TaskAssignmentResolutionRequestGroup, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], TaskAssignmentResolutionRequest.prototype, "groups", void 0);
exports.TaskAssignmentResolutionRequest = TaskAssignmentResolutionRequest;
class GetMyFlowsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(FlowStatus_1.FlowStatus, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetMyFlowsRequest.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)([true]),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetMyFlowsRequest.prototype, "requiresMyInput", void 0);
exports.GetMyFlowsRequest = GetMyFlowsRequest;
class ArchiveFlowRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], ArchiveFlowRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], ArchiveFlowRequest.prototype, "flowDesignId", void 0);
exports.ArchiveFlowRequest = ArchiveFlowRequest;
class CancelFlowRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CancelFlowRequest.prototype, "flowId", void 0);
exports.CancelFlowRequest = CancelFlowRequest;
class GetBusinessFlowsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsEnum)(FlowStatus_1.FlowStatus, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetBusinessFlowsRequest.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetBusinessFlowsRequest.prototype, "flowDesignId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetBusinessFlowsRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetBusinessFlowsRequest.prototype, "contactId", void 0);
exports.GetBusinessFlowsRequest = GetBusinessFlowsRequest;
class GetTaskResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({
        taggedUnion: {
            getTag: (t) => t.taskStateId === 'META_SKIPPED' ? t.taskStateId : t.type,
            handlers: task_1.TaskClasses,
        },
    }),
    (0, IsInstanceOfAny_1.IsInstanceOfAny)(Object.values(task_1.TaskClasses)),
    (0, tslib_1.__metadata)("design:type", Object)
], GetTaskResponse.prototype, "task", void 0);
exports.GetTaskResponse = GetTaskResponse;
class SubmitTaskActionResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(models_1.GetFlows.Flow),
    (0, tslib_1.__metadata)("design:type", models_1.GetFlows.Flow)
], SubmitTaskActionResponse.prototype, "flow", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], SubmitTaskActionResponse.prototype, "nextTaskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({
        taggedUnion: {
            getTag: (t) => t.taskStateId === 'META_SKIPPED' ? t.taskStateId : t.type,
            handlers: task_1.TaskClasses,
        },
    }),
    (0, IsInstanceOfAny_1.IsInstanceOfAny)(Object.values(task_1.TaskClasses)),
    (0, tslib_1.__metadata)("design:type", Object)
], SubmitTaskActionResponse.prototype, "task", void 0);
exports.SubmitTaskActionResponse = SubmitTaskActionResponse;
exports.PrintableTasks = [
    task_1.TaskDesignType.TFND,
    task_1.TaskDesignType.ESIGN,
    task_1.TaskDesignType.SUPER_CHOICE,
];
class StartFlowRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)('4', { each: true }),
    (0, class_validator_1.IsArray)(),
    (0, tslib_1.__metadata)("design:type", Array)
], StartFlowRequest.prototype, "flowDesignIds", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], StartFlowRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], StartFlowRequest.prototype, "contactId", void 0);
exports.StartFlowRequest = StartFlowRequest;
class Client {
    constructor(http, $error, designs, files) {
        this.http = http;
        this.$error = $error;
        this.designs = designs;
        this.files = files;
        this.getMyPrerequisites = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/flows/prerequisites')
            .get((d) => GetPrerequisites_1.GetPrerequisites.Response.create(d)));
        this.getMyFlows = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/flows/my')
            .optionalParams(models_1.GetFlows.Request.create(args).asJSON())
            .get((d) => models_1.GetFlows.Response.create(d)));
        this.getMyBusinessFlows = (0, handler_1.handler)(this, async (params) => this.http
            .request()
            .to('/flows')
            .optionalParams(GetBusinessFlowsRequest.create({ ...params }).asJSON())
            .get((d) => models_1.GetFlows.Response.create(d)));
        this.getFlow = (0, handler_1.handler)(this, async (flowId) => this.http
            .request()
            .to(`/flows/${flowId}`)
            .get((data) => models_1.GetFlows.Flow.create(data)));
        this.getTask = (0, handler_1.handler)(this, async (taskId) => this.http
            .request()
            .to(`/tasks/${taskId}`)
            .get((data) => GetTaskResponse.create(data).task));
        /**
         * The first argument of this method must be `TaskActionType`. The rest of the
         * variadic arguments will then be typed.
         *
         * This uses the `TaskAction.fromArgs(...args)` method you can see in
         * packages/api-client/src/flow/models/TaskAction/MetaTaskActions to determine
         * the remainder of the arguments
         *
         * @example
         * ```ts
         * api.client.flows().submitTaskAction(TaskActionType.RAW_TEXT_ACCEPT, { taskId });
         * api.client.flows().submitTaskAction(TaskActionType.META_SKIP, { taskId });
         * ```
         */
        this.submitTaskAction = (0, handler_1.handler)(this, async (type, ...args) => {
            const req = TaskActionRequest_1.TaskActionRequest.fromArgs(type, ...args);
            return this.http
                .request()
                .to(`/tasks/action`)
                .bodyJSON(req.asJSON())
                .post((data) => SubmitTaskActionResponse.create(data));
        });
        this.getTaskActivity = (0, handler_1.handler)(this, async (request) => utils_1.TokenPaginator.from({
            itemClass: models_1.FlowTaskActivityListing,
            contentsKey: 'activity',
            makeRequest: async (page) => this.http
                .request()
                .optionalParams({ count: request.count, page })
                .to(`/tasks/${request.taskId}/activity`)
                .get(),
        }));
        this.printTask = (0, handler_1.handler)(this, async (request) => this.http
            .request()
            .to(`/tasks/${request.taskId}/print`)
            .post((data) => file_1.TemporaryRemoteFile.create(data)));
        this.queryTasks = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/tasks/query`)
            .bodyJSON(models_1.TaskQuery.Request.create(args))
            .post((data) => models_1.TaskQuery.Response.create(data)));
        this.archiveFlow = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/flows/archive')
            .bodyJSON(ArchiveFlowRequest.create(args))
            .post());
        this.cancelFlow = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/flows/cancel')
            .bodyJSON(CancelFlowRequest.create(args))
            .post());
        this.quickAssignTemplate = (0, handler_1.handler)(this, async (businessId, template) => {
            return this.files
                .upsertTemplateVersion({
                ...template,
                anonymous: true,
            })
                .flatMapAsync(({ templateId }) => this.designs.createFlowDesign({
                businessId,
                description: '',
                anonymous: true,
                name: template.name,
                tasks: [
                    {
                        type: task_1.TaskDesignType.TEMPLATE,
                        templateId,
                        name: template.name,
                    },
                ],
            }))
                .flatMapAsync(({ flowDesignId }) => this.startFlow({ flowDesignId }))
                .getOrThrowFailure();
        });
        this.startFlow = (0, handler_1.handler)(this, async (args) => {
            return this.startFlows({
                flowDesignIds: [args.flowDesignId],
                memberId: args.memberId,
                contactId: args.contactId,
            })
                .map(({ flowIds }) => ({ flowId: flowIds[0] }))
                .getOrThrowFailure();
        });
        this.startFlows = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/flows')
                .bodyJSON(args)
                .post((body) => body);
        });
        this.quickAssign = (0, handler_1.handler)(this, async (args) => this.designs
            .createFlowDesign({ ...args.design, anonymous: true })
            .flatMapAsync(({ flowDesignId }) => this.startFlow({
            flowDesignId,
            memberId: args.memberId,
            contactId: args.contactId,
        }))
            .flatMapAsync(({ flowId }) => this.getFlow(flowId))
            .getOrThrowFailure());
        this.resolveAssignments = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/tasks/resolve-assignments')
                .bodyJSON(args)
                .post((data) => TaskAssignmentResolutionResponse.create(data));
        });
    }
}
exports.Client = Client;

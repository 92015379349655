"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactNumber = void 0;
const errors_1 = require("@zap-onboard/errors");
const designed_1 = require("designed");
const libphonenumber_js_1 = require("libphonenumber-js");
class ContactNumber {
    constructor(contactNumber) {
        this.contactNumber = contactNumber;
        if (!ContactNumber.isValid(contactNumber)) {
            throw errors_1.errs.ValidationError.create(`Invalid contact number`);
        }
    }
    static fake() {
        return new ContactNumber('+61451955560');
    }
    static isValid(contactNumber) {
        return (0, libphonenumber_js_1.isValidPhoneNumber)(contactNumber);
    }
    static isFormattable(string) {
        const [contactNumber] = ContactNumber.getFromString(string);
        return !!contactNumber;
    }
    // TODO: Simplify validation interface to single method
    static validationMessage(raw) {
        if (!this.isFormattable(raw)) {
            return 'Invalid contact number';
        }
        return undefined;
    }
    static formatContactNumber(unformattedContactNumber) {
        const [contactNumber] = ContactNumber.getFromString(unformattedContactNumber);
        if (contactNumber) {
            return contactNumber.number.number.toString();
        }
        throw errors_1.errs.ValidationError.create('Unable to format contact number');
    }
    static fromJSON(contactNumber) {
        return new ContactNumber(ContactNumber.formatContactNumber(contactNumber));
    }
    static try(contactNumber) {
        return designed_1.Result.fromThrowable(() => contactNumber instanceof ContactNumber
            ? contactNumber
            : ContactNumber.fromJSON(contactNumber))
            .toOptional()
            .orElse(undefined);
    }
    isInternational() {
        return !this.isLocal();
    }
    isAustralianMobile() {
        return (0, libphonenumber_js_1.parsePhoneNumber)(this.contactNumber, 'AU').getType() === 'MOBILE';
    }
    isLocal() {
        return this.contactNumber.startsWith('+61');
    }
    equals(other) {
        if (typeof other === 'string') {
            return this.contactNumber === other;
        }
        return this.contactNumber === other.contactNumber;
    }
    asJSON() {
        return `${this.contactNumber}`;
    }
    asNationalFormat() {
        return (0, libphonenumber_js_1.formatNumber)(this.contactNumber, 'NATIONAL');
    }
    static getFromString(string) {
        return (0, libphonenumber_js_1.findPhoneNumbersInText)(string, 'AU');
    }
    toCensoredString() {
        return this.contactNumber.replace(/.(?=.{3})/g, '*');
    }
}
exports.ContactNumber = ContactNumber;

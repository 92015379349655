"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Register = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const ABN_1 = require("../../utils/ABN");
var Register;
(function (Register) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(ABN_1.ABN),
        (0, tslib_1.__metadata)("design:type", ABN_1.ABN)
    ], Request.prototype, "abn", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.MaxLength)(150),
        (0, class_validator_1.MinLength)(4),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "isXeroLead", void 0);
    Register.Request = Request;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "businessId", void 0);
    Register.Response = Response;
})(Register = exports.Register || (exports.Register = {}));

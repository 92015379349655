"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationClient = exports.GetBusinessNotificationsResponse = exports.BusinessNotificationResponseItem = exports.GetBusinessNotificationsRequest = exports.SendSMSToMemberRequest = exports.UpdateNotificationsRequest = exports.PokeEsignSignersRequest = exports.PokeMemberRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TimeAndDate_1 = require("../utils/time/TimeAndDate");
const ResponseBase_1 = require("../ResponseBase");
const handler_1 = require("../utils/handler");
const models_1 = require("./models");
const NotificationListing_1 = require("./models/NotificationListing");
class PokeMemberRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], PokeMemberRequest.prototype, "memberId", void 0);
exports.PokeMemberRequest = PokeMemberRequest;
class PokeEsignSignersRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], PokeEsignSignersRequest.prototype, "taskId", void 0);
exports.PokeEsignSignersRequest = PokeEsignSignersRequest;
class UpdateNotificationsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsString)({ each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], UpdateNotificationsRequest.prototype, "notificationIds", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(NotificationListing_1.NotificationStatus),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateNotificationsRequest.prototype, "status", void 0);
exports.UpdateNotificationsRequest = UpdateNotificationsRequest;
class SendSMSToMemberRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], SendSMSToMemberRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.MinLength)(1),
    (0, class_validator_1.MaxLength)(320),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SendSMSToMemberRequest.prototype, "message", void 0);
exports.SendSMSToMemberRequest = SendSMSToMemberRequest;
class GetBusinessNotificationsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetBusinessNotificationsRequest.prototype, "memberId", void 0);
exports.GetBusinessNotificationsRequest = GetBusinessNotificationsRequest;
class BusinessNotificationResponseItem extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
], BusinessNotificationResponseItem.prototype, "timestamp", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
], BusinessNotificationResponseItem.prototype, "followedUpAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BusinessNotificationResponseItem.prototype, "subject", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BusinessNotificationResponseItem.prototype, "body", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BusinessNotificationResponseItem.prototype, "category", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
], BusinessNotificationResponseItem.prototype, "readAt", void 0);
exports.BusinessNotificationResponseItem = BusinessNotificationResponseItem;
class GetBusinessNotificationsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: BusinessNotificationResponseItem }),
    (0, class_validator_1.IsInstance)(BusinessNotificationResponseItem, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetBusinessNotificationsResponse.prototype, "notifications", void 0);
exports.GetBusinessNotificationsResponse = GetBusinessNotificationsResponse;
class NotificationClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.pokeMember = (0, handler_1.handler)(this, async (data) => this.http
            .request()
            .bodyJSON(PokeMemberRequest.create(data))
            .to('/notifications/poke')
            .post());
        this.getBusinessNotifications = (0, handler_1.handler)(this, async (data) => {
            return this.http
                .request()
                .optionalParams(data)
                .to('/notifications/business')
                .get((data) => GetBusinessNotificationsResponse.create(data));
        });
        this.pokeEsignSigners = (0, handler_1.handler)(this, async (data) => this.http
            .request()
            .bodyJSON(PokeEsignSignersRequest.create(data))
            .to('/notifications/esign-document/poke')
            .post());
        this.getNotifications = (0, handler_1.handler)(this, async (opts) => this.http
            .request()
            .optionalParams(opts)
            .to(`/notifications`)
            .get((data) => data.notifications.map((n) => NotificationListing_1.NotificationListing.create(n))));
        this.updateNotifications = (0, handler_1.handler)(this, async (data) => this.http.request().bodyJSON(data).to(`/notifications`).put());
        this.sendSMSToMember = (0, handler_1.handler)(this, async (message) => this.http
            .request()
            .to('notifications/sms/member')
            .bodyJSON(SendSMSToMemberRequest.create(message))
            .post());
        this.getMySettings = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/notifications/my/settings')
            .get((data) => models_1.Settings.GetResponse.create(data)));
        this.updateMySettings = (0, handler_1.handler)(this, async (data) => this.http
            .request()
            .bodyJSON(models_1.Settings.UpdateRequest.create(data))
            .to('/notifications/my/settings')
            .post((data) => models_1.Settings.GetResponse.create(data)));
    }
}
exports.NotificationClient = NotificationClient;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import {
  Button,
  Input,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Flex,
} from '@chakra-ui/react';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { inputs } from '@zap-onboard/web-components';

import {
  AbsoluteDate,
  cert,
  FileUploadFormSchemaItem,
  FileUploadFor,
  MultipleChoiceFormSchemaItem,
  MultipleChoiceFormSubmissionItem,
  TextBlockFormSchemaItem,
  TextQuestionFormSchemaItem,
  YoutubeVideoFormSchemaItem,
  VideoFormSchemaItem,
} from '@zap-onboard/api-client';

import React, { useMemo } from 'react';
import { useSubmitCert } from '../hooks/data/certs/useSubmitCert';
import { z } from '../helpers/schema';
import { Result } from 'designed';
import { HTMLDisplay } from '../components/HTMLDisplay';
import { DocumentUpload } from '../components/DocumentUpload';
import { CertUpload } from './CertUploadInput';
import { CertExpiryDateInput } from './CertExpiryDateInput';

export interface FreeformCertFormProps {
  submission: cert.GetSubmission.Submission.Freeform;
  afterSubmit?: () => unknown;
  onCancel?: () => unknown;
}

const dateValidation = z
  .any()
  .refine(
    (v) => Result.fromThrowable(() => AbsoluteDate.create(v!)).isSuccess(),
    {
      message: 'You must provide an expiry for this certification',
    },
  );
const Schema = (submission: cert.GetSubmission.Submission.Freeform) =>
  z.object({
    files: z
      .array(z.object({ claim: z.object({ token: z.string() }) }))
      .min(
        submission.latestDesign.fileCount.min,
        'Upload all pages of your certificate or both front and back of licence',
      )
      .max(
        submission.latestDesign.fileCount.max,
        `You can only upload up to ${submission.latestDesign.fileCount.max} files`,
      ),
    expiryDate:
      submission.latestDesign.hasExpiryDate == null
        ? dateValidation.or(z.undefined())
        : submission.latestDesign.hasExpiryDate
          ? dateValidation
          : z.undefined(),
    submission: z.object({
      items: z.object(
        Object.fromEntries(
          submission.latestDesign.schema.items.map(
            (item) => [item.formSchemaItemId!, SubmissionSchema(item)] as const,
          ) as any,
        ),
      ),
    }),
  });

const SubmissionSchema = (
  schema:
    | MultipleChoiceFormSchemaItem
    | TextQuestionFormSchemaItem
    | FileUploadFormSchemaItem
    | YoutubeVideoFormSchemaItem
    | VideoFormSchemaItem
    | TextBlockFormSchemaItem,
) => {
  if (schema.type === 'TEXT_BLOCK') {
    return z.object({
      type: z.literal(schema.type),
      formSchemaItemId: z.literal(schema.formSchemaItemId!),
    });
  }
  if (schema.type === 'TEXT_QUESTION') {
    return z.object({
      type: z.literal(schema.type),
      formSchemaItemId: z.literal(schema.formSchemaItemId!),
      value: schema.required ? z.string().min(1) : z.string().or(z.undefined()),
    });
  }
  if (schema.type === 'MULTIPLE_CHOICE') {
    return z.object({
      type: z.literal(schema.type),
      formSchemaItemId: z.literal(schema.formSchemaItemId!),
      value: schema.required ? z.string().min(1) : z.string().or(z.undefined()),
      isOther: z.boolean(),
    });
  }
  if (schema.type === 'FILE_UPLOAD') {
    return z.object({
      formSchemaItemId: z.literal(schema.formSchemaItemId!),
      type: z.literal(schema.type),
      claim: schema.required
        ? z.object({ token: z.string() })
        : z.object({ token: z.string() }).or(z.undefined()),
    });
  }

  return null;
};

export const FreeformCertForm: React.FC<FreeformCertFormProps> = ({
  submission,
  afterSubmit,
  onCancel,
}) => {
  const { submit, isSubmitting } = useSubmitCert({ submission, afterSubmit });

  const schema = useMemo(() => Schema(submission), [submission]);

  const form = useForm<z.infer<ReturnType<typeof Schema>>>({
    resolver: z.zodResolver(schema),
    shouldUnregister: false,
    defaultValues: {
      files: submission.files?.map(({ claim }) => ({ claim })) ?? [],
      expiryDate: submission.expiryDate,
      submission: {
        items: Object.fromEntries(
          submission.latestDesign.schema.items.map(
            (item) =>
              [
                item.formSchemaItemId!,
                submission.submission?.items
                  ?.find(
                    (sub) =>
                      sub.value.formSchemaItemId === item.formSchemaItemId,
                  )
                  ?.asJSON() ?? item.makeEmptySubmission(),
              ] as const,
          ),
        ),
      },
    },
  });

  const onSubmit = form.handleSubmit((data) =>
    submit({
      type: cert.CertificationType.FREEFORM,
      claims: data.files?.map((file) => file.claim),
      expiryDate: data.expiryDate
        ? AbsoluteDate.create(data.expiryDate)
        : undefined,
      submission: { items: Object.values(data.submission.items) as any },
    }),
  );

  return (
    <FormProvider {...form}>
      <CertUpload submission={submission} />
      {submission.latestDesign.hasExpiryDate == null ||
        (submission.latestDesign.hasExpiryDate && <CertExpiryDateInput />)}

      <>
        {submission.latestDesign.schema.items.map((item) => {
          const getName = (n: string) =>
            `submission.items.${item.formSchemaItemId}.${n}`;

          return (
            <>
              <inputs.Hidden name={getName('formSchemaItemId')} />
              <inputs.Hidden name={getName('type')} />

              {item.type === 'TEXT_QUESTION' && (
                <inputs.TextInput
                  name={getName('value')}
                  label={item.title}
                  isRequired={item.required}
                  description={item.description}
                />
              )}

              {item.type === 'TEXT_BLOCK' && (
                <HTMLDisplay>{item.text}</HTMLDisplay>
              )}
              {item.type === 'MULTIPLE_CHOICE' && (
                <MultipleChoiceInput
                  schemaItem={item}
                  name={`submission.items.${item.formSchemaItemId}`}
                  submissionItem={
                    submission.submission?.items.find(
                      (sub) =>
                        sub.value.formSchemaItemId === item.formSchemaItemId,
                    )?.value as MultipleChoiceFormSubmissionItem | undefined
                  }
                />
              )}

              {item.type === 'FILE_UPLOAD' && (
                <inputs.wrap.FormControl name={getName('claim')}>
                  {item.description ? <Text>{item.description}</Text> : null}
                  <Flex justify="flex-start">
                    <DocumentUpload
                      uploadedFor={{
                        type: FileUploadFor.CERTIFICATION,
                        certificationDesignId: submission.certificationDesignId,
                      }}
                      allowedTypes={item.permittedFileTypes}
                      controlled={{
                        name: getName('claim'),
                        control: form.control,
                      }}
                    />
                  </Flex>
                </inputs.wrap.FormControl>
              )}
            </>
          );
        })}
      </>

      <Stack>
        <Button isDisabled={isSubmitting} size="md" onClick={onSubmit} w="100%">
          Submit
        </Button>

        {onCancel && (
          <Button
            isDisabled={isSubmitting}
            variant="outline"
            size="md"
            onClick={onCancel}
            w="100%"
          >
            Cancel
          </Button>
        )}
      </Stack>
    </FormProvider>
  );
};

type Props = {
  schemaItem: MultipleChoiceFormSchemaItem;
  name: string;
  submissionItem: MultipleChoiceFormSubmissionItem | undefined;
};

export const MultipleChoiceInput: React.FC<Props> = ({
  schemaItem,
  submissionItem,
  name,
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, value, ref, onBlur },
  } = useController({
    control,
    name,
  });

  const [currentOption, setCurrentOption] = React.useState<
    string | 'OTHER' | undefined
  >(() =>
    submissionItem?.isOther ? 'OTHER' : submissionItem?.value ?? undefined,
  );
  const [otherValue, setOtherValue] = React.useState<string>(() =>
    submissionItem?.isOther ? submissionItem?.value ?? '' : '',
  );

  React.useEffect(() => {
    const newValue = {
      type: 'MULTIPLE_CHOICE',
      value: currentOption === 'OTHER' ? otherValue : currentOption,
      isOther: currentOption === 'OTHER',
      formSchemaItemId: schemaItem.formSchemaItemId,
    };

    if (newValue.value === value.value && value.isOther === newValue.isOther) {
      return;
    }

    onChange(newValue);
  }, [currentOption, onChange, otherValue, value, schemaItem.formSchemaItemId]);

  return (
    <inputs.wrap.FormControl name={name} label={schemaItem.title}>
      {schemaItem.description ? <Text>{schemaItem.description}</Text> : null}
      <RadioGroup value={currentOption} onChange={setCurrentOption}>
        <Stack direction="column">
          {schemaItem.multipleChoiceOptions.map((option, index) => (
            <Radio
              key={option.name}
              ref={index === 0 ? ref : undefined}
              value={option.name}
              isChecked={option.name === currentOption}
              onBlur={onBlur}
            >
              {option.name}
            </Radio>
          ))}

          {schemaItem.allowOther && (
            <Stack>
              <Radio
                value="OTHER"
                isChecked={currentOption === 'OTHER'}
                onBlur={onBlur}
              >
                Other
              </Radio>
              {currentOption === 'OTHER' && (
                <Input
                  placeholder="Your answer"
                  value={otherValue}
                  onChange={(e) => setOtherValue(e.target.value)}
                  onBlur={onBlur}
                />
              )}
            </Stack>
          )}
        </Stack>
      </RadioGroup>
    </inputs.wrap.FormControl>
  );
};

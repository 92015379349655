import { useStateIfMounted } from '../useStateIfMounted';

export function useSelectedElementIds() {
  const [selectedElementIds, setSelectedElementIds] = useStateIfMounted<
    string[]
  >([]);

  function selectOrDeselectElementId(selectedId: string) {
    const elementIndex = selectedElementIds.findIndex(
      (id) => id === selectedId,
    );

    if (elementIndex === -1) {
      const updatedList = selectedElementIds.concat(selectedId);
      return setSelectedElementIds(updatedList);
    }

    const listToUpdate = selectedElementIds;
    listToUpdate.splice(elementIndex, 1);
    return setSelectedElementIds(Array.from(listToUpdate));
  }

  function reset() {
    setSelectedElementIds([]);
  }

  return {
    selectedElementIds,
    selectOrDeselectElementId,
    reset,
  };
}

import { Button, Flex } from '@chakra-ui/react';
import { VisaHistoryCheckDetails } from './VisaHistoryCheckDetails';
import { VisaHistoryNavMenu } from './VisaHistoryNavMenu';
import { VisaHistoryCheckResult } from './VisaHistoryCheckResult';
import { useState, useEffect } from 'react';
import { VisaCheck } from '@zap-onboard/api-client';

type Props = {
  visaChecks?: VisaCheck[];
  setShowVisaCheckForm: (showVisaCheckForm: boolean) => void;
};

export const VisaHistory: React.FC<Props> = ({
  visaChecks,
  setShowVisaCheckForm,
}) => {
  const latestVisaCheckId = visaChecks ? visaChecks[0].visaCheckId : '';
  const [selectedNavMenuItem, setSelectedNavMenuItem] =
    useState(latestVisaCheckId);

  useEffect(() => {
    const latestVisaCheckId = visaChecks ? visaChecks[0].visaCheckId : '';
    setSelectedNavMenuItem(latestVisaCheckId);
  }, [visaChecks]);

  if (!visaChecks) {
    return <div>No visa checks</div>;
  }
  const selectedVisaCheck = visaChecks.find(
    (visaCheck) => visaCheck.visaCheckId === selectedNavMenuItem,
  );
  return (
    <Flex direction="column" gridGap="12px">
      <Button alignSelf="self-end" onClick={() => setShowVisaCheckForm(true)}>
        New visa check
      </Button>
      <Flex mt={5}>
        <VisaHistoryNavMenu
          visaChecks={visaChecks}
          handleNavMenuItemSelected={setSelectedNavMenuItem}
          selectedNavMenuItemId={selectedNavMenuItem}
        />
        {selectedVisaCheck && (
          <>
            <VisaHistoryCheckDetails visaCheck={selectedVisaCheck} />
            <VisaHistoryCheckResult visaCheck={selectedVisaCheck} />
          </>
        )}
      </Flex>
    </Flex>
  );
};

import * as React from 'react';
import { Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { cert } from '@zap-onboard/api-client';
import { useCertsToBeActioned } from '../../hooks/data/certs/useCertsToBeActioned';
import { createToast } from '../../helpers';

import { modal } from '@zap-onboard/web-components';
import { CertForm } from '../../certs/CertForm';
import { ErrorDisplay } from '../../billing/ErrorDisplay';
import {
  LoadingState,
  Status,
  SimpleTile,
  TileGroupLayout,
} from '../../components';

export const CertsToAction: React.FC<{ context: 'business' | 'user' }> = ({
  context,
}) => {
  const { data, error, isLoading } = useCertsToBeActioned();
  const { Layout, CertTile } = LocalComponents;

  if (isLoading) {
    return <LoadingState />;
  }

  if (data?.length === 0 || context !== 'user') {
    return null;
  }

  return (
    <Layout>
      {error && <ErrorDisplay error={error} />}
      <TileGroupLayout justify="center">
        {data?.map((submission) => (
          <CertTile
            key={submission.value.certificationDesignId}
            submission={submission.value}
            context={context}
          />
        ))}
      </TileGroupLayout>
    </Layout>
  );
};

namespace LocalComponents {
  export const Layout: React.FC = ({ children }) => (
    <Stack w="100%" align="center">
      <Heading size="sm" textAlign="center">
        Certifications to submit
      </Heading>
      {children}
    </Stack>
  );

  export const CertTile: React.FC<{
    submission: cert.GetSubmission.Submission.All;
    context: 'user' | 'business';
  }> = ({ submission, context }) => {
    const history = useHistory();
    const disclosure = useDisclosure();

    const afterSubmit = async () => {
      history.push(`/${context === 'user' ? 'user' : 'business'}/dashboard`);
      createToast({
        status: 'success',
        description: `Certification ${
          submission.certificationSubmissionId ? 'resubmitted' : 'submitted'
        }`,
      });
    };

    return (
      <>
        <modal.Simple
          disclosure={disclosure}
          header={`Submit your ${submission.name}`}
        >
          <Stack spacing={4}>
            <CertForm
              submission={submission}
              onCancel={disclosure.onClose}
              afterSubmit={afterSubmit}
            />
          </Stack>
        </modal.Simple>

        <SimpleTile
          key={submission.certificationDesignId}
          onClick={disclosure.onOpen}
        >
          <Text>{submission.name}</Text>
          <Status
            type="certSubmissionStatus"
            status={submission.status}
            label={Helpers.mapCertStatus(submission.status)}
          />
        </SimpleTile>
      </>
    );
  };
}

namespace Helpers {
  const statusMapper = {
    [cert.CertSubmissionStatus.APPROVED]: 'Approved',
    [cert.CertSubmissionStatus.REJECTED]: 'Rejected',
    [cert.CertSubmissionStatus.SUBMITTED]: 'Submitted',
    [cert.CertSubmissionStatus.UNSUBMITTED]: 'Unsubmitted',
  };

  export function mapCertStatus(status: cert.CertSubmissionStatus) {
    return statusMapper[status];
  }
}

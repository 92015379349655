"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILE_UPLOAD_FOR_CLASSES = void 0;
const FileUploadFor_1 = require("./FileUploadFor");
exports.FILE_UPLOAD_FOR_CLASSES = [
    FileUploadFor_1.FileUploadForTask,
    FileUploadFor_1.FileUploadForMember,
    FileUploadFor_1.FileUploadForMyBusiness,
    FileUploadFor_1.FileUploadForCertification,
    FileUploadFor_1.FileUploadForVideo,
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssignmentQuery = void 0;
const errors_1 = require("@zap-onboard/errors");
var AssignmentQuery;
(function (AssignmentQuery) {
    class Assignment {
        constructor(level) {
            this.level = level;
        }
        static fromJSON(level) {
            if (typeof level !== 'string') {
                throw errors_1.errs.EntityValidationError.create('Assignment must be a string');
            }
            if (!Assignment.options.includes(level)) {
                throw errors_1.errs.EntityValidationError.create('Assignment is invalid', {
                    details: {
                        level,
                    },
                });
            }
            return new Assignment(level);
        }
        includeAdminAssignments() {
            return this.level === 'ADMIN' || this.level === 'ANY';
        }
        includeCollaboratorAssignments() {
            return (this.level === 'COLLABORATOR' ||
                this.level === 'NON_ADMIN' ||
                this.level === 'ANY');
        }
        includeSubjectAssignments() {
            return (this.level === 'SUBJECT' ||
                this.level === 'NON_ADMIN' ||
                this.level === 'ANY');
        }
        asJSON() {
            return this.level;
        }
        toJSON() {
            return this.asJSON();
        }
    }
    Assignment.options = [
        'SUBJECT',
        'ADMIN',
        'COLLABORATOR',
        'NON_ADMIN',
        'ANY',
    ];
    AssignmentQuery.Assignment = Assignment;
    class Requirement {
        constructor(level) {
            this.level = level;
        }
        static fromJSON(level) {
            if (typeof level !== 'string') {
                throw errors_1.errs.EntityValidationError.create('Requirement must be a string');
            }
            if (!Requirement.options.includes(level)) {
                throw errors_1.errs.EntityValidationError.create('Requirement is invalid', {
                    details: {
                        level,
                    },
                });
            }
            return new Requirement(level);
        }
        getAssignments() {
            return Requirement.assignmentMap[this.level];
        }
        asJSON() {
            return this.level;
        }
        toJSON() {
            return this.asJSON();
        }
    }
    Requirement.options = ['ACTION_REQUIRED', 'ACTIONABLE', 'ALL'];
    Requirement.assignmentMap = {
        ALL: [
            'CAN_VIEW',
            'CAN_ACTION',
            'REQUIRES_ACTION',
        ],
        ACTIONABLE: ['CAN_ACTION', 'REQUIRES_ACTION'],
        ACTION_REQUIRED: ['REQUIRES_ACTION'],
    };
    AssignmentQuery.Requirement = Requirement;
})(AssignmentQuery = exports.AssignmentQuery || (exports.AssignmentQuery = {}));

import { keyframes } from '@chakra-ui/react';
import { RefObject, useEffect, useRef, useState } from 'react';

export const useObserveIntersections = (
  refs: Map<number, HTMLElement>,
  onObserve: (event: IntersectionObserverEntry[]) => unknown,
) => {
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    // Intersection not supported in IE and Safari
    if (!('IntersectionObserver' in window)) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((e) => onObserve(e), {
      threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    });

    refs.forEach((ref) => observer.current?.observe(ref));

    // eslint-disable-next-line consistent-return
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs]);
};

export const useHasBeenOnScreen = (ref?: RefObject<HTMLElement>) => {
  const [hasBeenOnScreen, setHasBeenOnScreen] = useState(false);
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    // Intersection not supported in IE and Safari
    if (!('IntersectionObserver' in window)) {
      setHasBeenOnScreen(true);
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setHasBeenOnScreen(true);
        }
      });
    });

    if (ref?.current) {
      observer.current.observe(ref.current);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [ref]);

  return hasBeenOnScreen;
};

/**
 * Only returns the src string once it is fully loaded
 */
export const useLazyImage = (src?: string) => {
  const [loadedSrc, setLoadedSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (src) {
      const img = new Image();
      img.src = src;
      img.onload = () => setLoadedSrc(src);
    }
  }, [src]);

  // clear the src if the src prop changes
  useEffect(() => {
    if (src !== loadedSrc) {
      setLoadedSrc(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return loadedSrc;
};

const loadingShimmer = keyframes`
    0%{background-position: 300% 0 }
    100%{background-position: -300% 0}
`;

export const useLazyBackground = (
  src?: string,
  opts?: {
    loadedCss?: React.CSSProperties;
  },
  ref: RefObject<HTMLElement> = { current: null },
) => {
  const hasBeenOnScreen = useHasBeenOnScreen(ref);
  const loadedSrc = useLazyImage(hasBeenOnScreen ? src : undefined);

  return {
    animation: loadedSrc ? undefined : `${loadingShimmer} infinite 10s linear`,
    style: {
      ...(loadedSrc
        ? opts?.loadedCss
        : {
            backgroundImage: `linear-gradient(
                90deg,
                rgba(255,255,255,0.1) 0,
                rgba(255,255,255,0.35) 20%,
                rgba(255,255,255,0.75) 25%,
                rgba(255,255,255,0.35) 30%,
                rgba(255,255,255,0.1)
            )`,
            backgroundColor: '#DDDBDD',
            backgroundSize: '400% 400%',
          }),

      ...(loadedSrc ? { backgroundImage: `url(${loadedSrc})` } : {}),
    },
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileActions = exports.ProfileCompleteAction = exports.ProfileActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const identity_1 = require("../../../identity");
const ResponseBase_1 = require("../../../ResponseBase");
var ProfileActionType;
(function (ProfileActionType) {
    ProfileActionType["PROFILE_COMPLETE"] = "PROFILE_COMPLETE";
})(ProfileActionType = exports.ProfileActionType || (exports.ProfileActionType = {}));
class ProfileCompleteAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return ProfileCompleteAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileCompleteAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(identity_1.UpdateUserProfile),
    (0, tslib_1.__metadata)("design:type", identity_1.UpdateUserProfile)
], ProfileCompleteAction.prototype, "profile", void 0);
exports.ProfileCompleteAction = ProfileCompleteAction;
exports.ProfileActions = {
    [ProfileActionType.PROFILE_COMPLETE]: ProfileCompleteAction,
};

import { useMemo } from 'react';
import type { FieldProps } from './Field';
import { pdf, TimeAndZone } from '@zap-onboard/api-client';
import { usePDFState } from '../usePDFState';
import { getTextLikeStyle } from './TextField';

type Props = FieldProps & { field: pdf.Schema.Field.TimeData };

const now = TimeAndZone.nowForCurrentProcess();

/**
 * Style changes should be synced with the text schema
 */
export const TimeField = ({ field }: Props) => {
  const time = usePDFState(
    (s) => s.groups.find((g) => g.groupId === field.groupId)?.submittedAt,
  );
  const sampleValue = useMemo(
    () => (time ?? now).toFormat(field.format),
    [field.format, time],
  );

  return (
    <div style={getTextLikeStyle(field)}>
      {sampleValue.split('').map((l, i) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          style={{
            letterSpacing: String(sampleValue).length === i + 1 ? 0 : 'inherit',
          }}
        >
          {l}
        </span>
      ))}
    </div>
  );
};

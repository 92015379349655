/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Icon,
} from '@chakra-ui/react';

import {
  FlowTaskStatus,
  PrintableTasks,
  Task,
  TaskActionType,
} from '@zap-onboard/api-client';
import { MdFastForward, MdMoreVert, MdPrint } from 'react-icons/md';
import { TaskActionSubmitter } from '../../../../../hooks/data/flow/useTaskActionSubmission';
import { usePrintTask } from '../../../../../hooks/usePrintTask';

interface TaskMetaActionProps {
  context: 'user' | 'business';
  task: Task;
  submitAction: TaskActionSubmitter;
}

export const TaskMetaActionMenu: React.FC<TaskMetaActionProps> = ({
  context,
  submitAction,
  task,
}: TaskMetaActionProps) => {
  const handleSkipTask = React.useCallback(() => {
    submitAction(TaskActionType.META_SKIP, { taskId: task.taskId });
  }, [task, submitAction]);

  const { handlePrintTask, isTaskPrinting } = usePrintTask({
    taskId: task.taskId,
  });

  const menuItems = [];

  if (
    task.status === FlowTaskStatus.COMPLETE &&
    PrintableTasks.includes(task.type as any)
  ) {
    menuItems.push(
      <MenuItem
        disabled={isTaskPrinting}
        key="PRINT_TASK"
        onClick={handlePrintTask}
      >
        <Icon as={MdPrint} w={6} h={6} color="brand.dark-gray" />
        <Text marginLeft={1}>Download task</Text>
      </MenuItem>,
    );
  }

  if (context === 'business' && !task.taskCompleted()) {
    menuItems.push(
      <MenuItem key="SKIP_TASK" onClick={handleSkipTask}>
        <Icon as={MdFastForward} w={6} h={6} color="brand.dark-gray" />
        <Text marginLeft={1}>Skip task</Text>
      </MenuItem>,
    );
  }

  return menuItems.length > 0 ? (
    <Menu placement="auto-start">
      <MenuButton
        as={IconButton}
        size="base"
        variant="base"
        icon={<MdMoreVert size={24} />}
        alignContent="center"
        color="brand.dark-gray"
        _hover={{ opacity: 0.8 }}
      />
      <MenuList>{menuItems}</MenuList>
    </Menu>
  ) : null;
};

import * as React from 'react';
import {
  Heading,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

import { SendEmail } from './SendEmail';
import { SendSMS } from './SendSMS';
import { membership } from '@zap-onboard/api-client';

type Props = {
  memberName: string;
  memberId: membership.GetMember.Response['memberId'];
  onClose: () => unknown;
};

const IS_FEATURE_FLAGGED = true;

export const SendMessage: React.FC<Props> = ({
  memberName,
  onClose,
  memberId,
}) => {
  const { Header } = Local;

  return (
    <Stack>
      <Header name={memberName} />
      <Tabs>
        <TabList>
          {!IS_FEATURE_FLAGGED && <Tab>Email</Tab>}
          <Tab>SMS</Tab>
        </TabList>

        <TabPanels>
          {!IS_FEATURE_FLAGGED && (
            <TabPanel>
              <SendEmail
                onClose={onClose}
                isFeatureFlagged={IS_FEATURE_FLAGGED}
              />
            </TabPanel>
          )}
          <TabPanel>
            <SendSMS memberId={memberId} onClose={onClose} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

namespace Local {
  export const Header: React.FC<{ name: string }> = ({ name }) => (
    <Stack>
      <Heading size="md">Message</Heading>
      <Text fontWeight="bold" color="brand.dark-gray">
        {name}
      </Text>
    </Stack>
  );
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { flow } from '@zap-onboard/api-client';
import { useMemo } from 'react';
import { useMyFlows } from './useFlows';

export class GroupedFlowListing {
  public flows: flow.GetFlows.Flow[] = [];

  get archived() {
    return this.first.archived;
  }

  get flowCount() {
    return this.flows.length;
  }

  get name(): string {
    return this.first.name;
  }

  get flowDesignId(): string {
    return this.first.flowDesignId;
  }

  get first(): flow.GetFlows.Flow {
    return this.flows[0]!;
  }

  performSort() {
    this.flows = this.flows.sort(
      (a, b) => b.startedAt.valueOf() - a.startedAt.valueOf(),
    );
  }
}

export const useGroupedFlows = (flows: ReturnType<typeof useMyFlows>) => {
  const groupedFlows = useMemo(() => {
    const indexedFlows: { [flowDesignId: string]: GroupedFlowListing } = {};

    flows.data?.flows.forEach((flow) => {
      if (!indexedFlows[flow.flowDesignId]) {
        indexedFlows[flow.flowDesignId] = new GroupedFlowListing();
      }
      indexedFlows[flow.flowDesignId].flows.push(flow);
    });
    return Object.values(indexedFlows).map((group) => {
      group.performSort();
      return group;
    });
  }, [flows.data]);

  return { ...flows, data: flows.data ? groupedFlows : undefined };
};

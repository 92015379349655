"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskAssignedResponse = exports.TaskAssignmentRequest = exports.AdminAssignment = exports.SubjectAssignment = exports.MemberAssignment = exports.PositionAssignment = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
class PositionAssignment extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.getKey = () => `POSITION${this.positionId}`;
    }
}
PositionAssignment.type = 'POSITION';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['POSITION']),
    (0, tslib_1.__metadata)("design:type", String)
], PositionAssignment.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], PositionAssignment.prototype, "positionId", void 0);
exports.PositionAssignment = PositionAssignment;
class MemberAssignment extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.getKey = () => `MEMBER${this.memberId}`;
    }
}
MemberAssignment.type = 'MEMBER';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['MEMBER']),
    (0, tslib_1.__metadata)("design:type", String)
], MemberAssignment.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], MemberAssignment.prototype, "memberId", void 0);
exports.MemberAssignment = MemberAssignment;
class SubjectAssignment extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.getKey = () => 'SUBJECT';
    }
}
SubjectAssignment.type = 'SUBJECT';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['SUBJECT']),
    (0, tslib_1.__metadata)("design:type", String)
], SubjectAssignment.prototype, "type", void 0);
exports.SubjectAssignment = SubjectAssignment;
class AdminAssignment extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.getKey = () => 'ADMIN';
    }
}
AdminAssignment.type = 'ADMIN';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['ADMIN']),
    (0, tslib_1.__metadata)("design:type", String)
], AdminAssignment.prototype, "type", void 0);
exports.AdminAssignment = AdminAssignment;
class TaskAssignmentRequest extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [
        SubjectAssignment,
        PositionAssignment,
        MemberAssignment,
        AdminAssignment,
    ],
}) {
    getKey() {
        return this.value.getKey();
    }
}
exports.TaskAssignmentRequest = TaskAssignmentRequest;
const levels = ['CAN_VIEW', 'CAN_ACTION', 'REQUIRES_ACTION'];
const value = {
    CAN_VIEW: 1,
    CAN_ACTION: 2,
    REQUIRES_ACTION: 3,
};
class PositionAssigned extends PositionAssignment {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PositionAssigned.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(levels),
    (0, tslib_1.__metadata)("design:type", Object)
], PositionAssigned.prototype, "level", void 0);
class MemberAssigned extends MemberAssignment {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MemberAssigned.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(levels),
    (0, tslib_1.__metadata)("design:type", Object)
], MemberAssigned.prototype, "level", void 0);
class SubjectAssigned extends SubjectAssignment {
    get name() {
        return 'Subject';
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(levels),
    (0, tslib_1.__metadata)("design:type", Object)
], SubjectAssigned.prototype, "level", void 0);
class AdminAssigned extends AdminAssignment {
    get name() {
        return 'Admin';
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(levels),
    (0, tslib_1.__metadata)("design:type", Object)
], AdminAssigned.prototype, "level", void 0);
class TaskAssignedResponse extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [SubjectAssigned, PositionAssigned, MemberAssigned, AdminAssigned],
}) {
    getValue() {
        return value[this.value.level];
    }
    greaterOf(other) {
        if (this.getValue() > other.getValue()) {
            return this;
        }
        return other;
    }
    getLevel() {
        return this.value.level;
    }
    getKey() {
        return this.value.getKey();
    }
    getName() {
        return this.value.name;
    }
    getType() {
        return this.value.type;
    }
    getMemberId() {
        if (this.value.type === 'MEMBER') {
            return this.value.memberId;
        }
        return undefined;
    }
    getPositionId() {
        if (this.value.type === 'POSITION') {
            return this.value.positionId;
        }
        return undefined;
    }
}
exports.TaskAssignedResponse = TaskAssignedResponse;

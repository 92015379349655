"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAbortError = exports.isSingleErrorResponse = exports.isNamedErrors = void 0;
function isNamedErrors(e) {
    return (typeof e === 'object' &&
        typeof e.error === 'object' &&
        typeof e.error.code === 'string');
}
exports.isNamedErrors = isNamedErrors;
function isSingleErrorResponse(e) {
    return typeof e === 'object' && typeof e.error === 'string';
}
exports.isSingleErrorResponse = isSingleErrorResponse;
function isAbortError(e) {
    return (typeof e === 'object' &&
        e != null &&
        'type' in e &&
        e.type === 'aborted');
}
exports.isAbortError = isAbortError;

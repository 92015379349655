/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  ButtonGroup,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Box,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { AbsoluteDate, cert } from '@zap-onboard/api-client';
import { inputs } from '@zap-onboard/web-components';

import React from 'react';
import { useSubmitCert } from '../hooks/data/certs/useSubmitCert';
import { z } from '../helpers/schema';

import { FreeformCertForm } from './FreeformCertForm';
import { Entity, Result } from 'designed';
import { CertExpiryDateInput } from './CertExpiryDateInput';
import { CertUpload } from './CertUploadInput';

export interface CertFormProps {
  submission: cert.GetSubmission.Submission.All;
  afterSubmit?: () => unknown;
  onCancel?: () => unknown;
}

export const CertForm: React.FC<CertFormProps> = ({
  submission,
  afterSubmit,
  onCancel,
}) => {
  return (
    <>
      {submission.rejectionMessage && (
        <Alert status="error" variant="left-accent" m={0}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Please Resubmit</AlertTitle>
            <AlertDescription display="block">
              This certification was rejected due to:{' '}
              <strong>{submission.rejectionMessage}</strong>
            </AlertDescription>
          </Box>
        </Alert>
      )}

      {submission.type === cert.CertificationType.FREEFORM && (
        <FreeformCertForm
          onCancel={onCancel}
          submission={submission}
          afterSubmit={afterSubmit}
        />
      )}
      {submission.type === cert.CertificationType.RSA && (
        <RSAForm
          onCancel={onCancel}
          submission={submission}
          afterSubmit={afterSubmit}
        />
      )}
      {submission.type === cert.CertificationType.FIRST_AID && (
        <FirstAidForm
          onCancel={onCancel}
          submission={submission}
          afterSubmit={afterSubmit}
        />
      )}
      {submission.type === cert.CertificationType.DRIVERS_LICENCE && (
        <DriversLicenceForm
          onCancel={onCancel}
          submission={submission}
          afterSubmit={afterSubmit}
        />
      )}
    </>
  );
};

interface RSACertFormProps {
  submission: cert.GetSubmission.Submission.RSA;
  afterSubmit?: () => unknown;
  onCancel?: () => unknown;
}

const RSASchema = z.object({
  files: z
    .array(z.object({ claim: z.object({ token: z.string() }) }))
    .min(1, 'Upload at least one copy of your RSA Certificate')
    .max(5, 'Upload at most 5 copies of your certificate'),
  expiryDate: z
    .any()
    .refine(
      (v: Entity.Attributes<AbsoluteDate> | undefined) =>
        Result.fromThrowable(() => AbsoluteDate.create(v!)).isSuccess(),
      {
        message: 'You must provide an expiry for this certification',
      },
    ),
  licenceNumber: z.string().min(2),
});

export const RSAForm: React.FC<RSACertFormProps> = ({
  submission,
  afterSubmit,
  onCancel,
}) => {
  const { submit, isSubmitting } = useSubmitCert({ submission, afterSubmit });

  const form = useForm<z.infer<typeof RSASchema>>({
    resolver: z.zodResolver(RSASchema),
    shouldUnregister: false,
    defaultValues: {
      files: submission.files?.map(({ claim }) => ({ claim })) ?? [],
      expiryDate: submission.expiryDate,
      licenceNumber: submission.licenceNumber ?? '',
    },
  });

  const onSubmit = form.handleSubmit((data) =>
    submit({
      type: cert.CertificationType.RSA,
      claims: data.files?.map((file) => file.claim),
      expiryDate: AbsoluteDate.create(data.expiryDate),
      licenceNumber: data.licenceNumber,
    }),
  );

  return (
    <FormProvider {...form}>
      <CertUpload submission={submission} />

      <CertExpiryDateInput />

      <inputs.TextInput
        name="licenceNumber"
        label="RSA Certification Number"
        placeholder="ABC0123456"
      />

      <ButtonGroup isDisabled={isSubmitting}>
        <Button onClick={onSubmit}>Submit</Button>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </FormProvider>
  );
};

interface DriversLicenceCertFormProps {
  submission: cert.GetSubmission.Submission.DriversLicence;
  afterSubmit?: () => unknown;
  onCancel?: () => unknown;
}

const DriversLicenceSchema = z.object({
  files: z
    .array(z.object({ claim: z.object({ token: z.string() }) }))
    .min(2, 'Please upload the front and back of your licence')
    .max(2, 'Please upload only the front and back of your licence'),
  expiryDate: z
    .any()
    .refine(
      (v: Entity.Attributes<AbsoluteDate> | undefined) =>
        Result.fromThrowable(() => AbsoluteDate.create(v!)).isSuccess(),
      {
        message: 'You must provide an expiry for this certification',
      },
    ),
  licenceNumber: z.string().min(2),
});

export const DriversLicenceForm: React.FC<DriversLicenceCertFormProps> = ({
  submission,
  afterSubmit,
  onCancel,
}) => {
  const { submit, isSubmitting } = useSubmitCert({ submission, afterSubmit });

  const form = useForm<z.infer<typeof DriversLicenceSchema>>({
    resolver: z.zodResolver(DriversLicenceSchema),
    shouldUnregister: false,
    defaultValues: {
      files:
        submission.files?.map(({ claim }) => ({ claim: claim.asJSON() })) ?? [],
      expiryDate: submission.expiryDate,
      licenceNumber: submission.licenceNumber ?? '',
    },
  });

  const onSubmit = form.handleSubmit((data) =>
    submit({
      type: cert.CertificationType.DRIVERS_LICENCE,
      claims: data.files?.map((file) => file.claim),
      expiryDate: AbsoluteDate.create(data.expiryDate),
      licenceNumber: data.licenceNumber,
    }),
  );

  return (
    <FormProvider {...form}>
      <CertUpload submission={submission} />

      <CertExpiryDateInput />

      <inputs.TextInput
        name="licenceNumber"
        label="Driver Licence Number"
        placeholder="0 123 123 467"
      />

      <ButtonGroup isDisabled={isSubmitting}>
        <Button onClick={onSubmit}>Submit</Button>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </FormProvider>
  );
};

interface FirstAidCertFormProps {
  submission: cert.GetSubmission.Submission.FirstAid;
  afterSubmit?: () => unknown;
  onCancel?: () => unknown;
}

const FirstAidSchema = z.object({
  files: z
    .array(z.object({ claim: z.object({ token: z.string() }) }))
    .min(1, 'Upload at least one copy of your first aid certificate')
    .max(5, 'Upload at least most one copy of your first aid certificate'),
  expiryDate: z
    .any()
    .refine(
      (v: Entity.Attributes<AbsoluteDate> | undefined) =>
        Result.fromThrowable(() => AbsoluteDate.create(v!)).isSuccess(),
      {
        message: 'You must provide an expiry for this certification',
      },
    ),
});

export const FirstAidForm: React.FC<FirstAidCertFormProps> = ({
  submission,
  afterSubmit,
  onCancel,
}) => {
  const { submit, isSubmitting } = useSubmitCert({ submission, afterSubmit });

  const form = useForm<z.infer<typeof FirstAidSchema>>({
    resolver: z.zodResolver(FirstAidSchema),
    shouldUnregister: false,
    defaultValues: {
      files: submission.files?.map(({ claim }) => ({ claim })) ?? [],
      expiryDate: submission.expiryDate,
    },
  });

  const onSubmit = form.handleSubmit((data) =>
    submit({
      type: cert.CertificationType.FIRST_AID,
      claims: data.files?.map((file) => file.claim),
      expiryDate: AbsoluteDate.create(data.expiryDate),
    }),
  );

  return (
    <FormProvider {...form}>
      <CertUpload submission={submission} />

      <CertExpiryDateInput />

      <ButtonGroup isDisabled={isSubmitting}>
        <Button onClick={onSubmit}>Submit</Button>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </FormProvider>
  );
};

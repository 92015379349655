"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Requirement = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var Requirement;
(function (Requirement) {
    let Position;
    (function (Position) {
        class AddRequest extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], AddRequest.prototype, "certificationDesignId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], AddRequest.prototype, "positionId", void 0);
        Position.AddRequest = AddRequest;
    })(Position = Requirement.Position || (Requirement.Position = {}));
    let Member;
    (function (Member) {
        class AddRequest extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], AddRequest.prototype, "certificationDesignId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], AddRequest.prototype, "memberId", void 0);
        Member.AddRequest = AddRequest;
    })(Member = Requirement.Member || (Requirement.Member = {}));
    class Item extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "certificationDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "name", void 0);
    Requirement.Item = Item;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Item }),
        (0, class_validator_1.IsInstance)(Item, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "certifications", void 0);
    Requirement.Response = Response;
    let List;
    (function (List) {
        class PositionItem extends ResponseBase_1.ResponseBase {
        }
        PositionItem.type = 'POSITION';
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)(['POSITION']),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionItem.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionItem.prototype, "certificationDesignId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionItem.prototype, "positionId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionItem.prototype, "name", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionItem.prototype, "certName", void 0);
        List.PositionItem = PositionItem;
        class MemberItem extends ResponseBase_1.ResponseBase {
        }
        MemberItem.type = 'MEMBER';
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)(['MEMBER']),
            (0, tslib_1.__metadata)("design:type", String)
        ], MemberItem.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], MemberItem.prototype, "certificationDesignId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], MemberItem.prototype, "memberId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], MemberItem.prototype, "name", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], MemberItem.prototype, "certName", void 0);
        List.MemberItem = MemberItem;
        class Union extends designed_1.Entity.Union.define({
            key: 'type',
            entries: [MemberItem, PositionItem],
        }) {
        }
        List.Union = Union;
        class Response extends ResponseBase_1.ResponseBase {
            getPositions() {
                return this.getItems().filter((v) => v.type === 'POSITION');
            }
            getMembers() {
                return this.getItems().filter((v) => v.type === 'MEMBER');
            }
            getItems() {
                return this.items.map((item) => item.value);
            }
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: Union }),
            (0, class_validator_1.IsInstance)(Union, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], Response.prototype, "items", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Memoize.All(),
            (0, tslib_1.__metadata)("design:type", Function),
            (0, tslib_1.__metadata)("design:paramtypes", []),
            (0, tslib_1.__metadata)("design:returntype", void 0)
        ], Response.prototype, "getPositions", null);
        (0, tslib_1.__decorate)([
            designed_1.Memoize.All(),
            (0, tslib_1.__metadata)("design:type", Function),
            (0, tslib_1.__metadata)("design:paramtypes", []),
            (0, tslib_1.__metadata)("design:returntype", void 0)
        ], Response.prototype, "getMembers", null);
        List.Response = Response;
    })(List = Requirement.List || (Requirement.List = {}));
})(Requirement = exports.Requirement || (exports.Requirement = {}));

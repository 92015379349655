import { useHandler } from 'hooks';
import { useEffect } from 'react';
import { Spinner } from '../../../components/Spinner/Spinner';

export const VerifyDevForm = ({
  onComplete,
  verifyMFAToken,
}: {
  onComplete: () => unknown;
  verifyMFAToken: string;
}) => {
  const [verify] = useHandler((api) => api.identity().mfa.verifyDevSource, {
    always: { onSuccess: onComplete },
  });

  useEffect(() => {
    verify({ verifyMFAToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};

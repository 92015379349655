"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BSB = void 0;
const errors_1 = require("@zap-onboard/errors");
class BSB {
    constructor(value) {
        this.value = value;
    }
    static fake() {
        return BSB.fromJSON('123-456');
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('BSB was given a non string value');
        }
        const onlyDigits = s.replace(/\D/g, '');
        const bsb = new BSB(onlyDigits);
        bsb.validate();
        return bsb;
    }
    static isValid(s) {
        return BSB.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            BSB.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    toString() {
        return `BSB{${this.value}}`;
    }
    asJSON() {
        return this.value;
    }
    validate() {
        if (this.value.length !== 6) {
            throw errors_1.errs.ValidationError.create('BSB must be six digits long');
        }
        if (this.value.split('').find((digit) => Number.isNaN(Number(digit)))) {
            throw errors_1.errs.ValidationError.create('BSB must only contain numbers');
        }
    }
}
exports.BSB = BSB;

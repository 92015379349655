/* eslint-disable no-negated-condition */
import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { auth } from '../../auth';

import { queryString } from '../../helpers';

export const UnauthenticatedRoute: React.FC<
  React.ComponentProps<typeof Route>
> = ({ children, ...rest }) => {
  const userProfile = auth.useProfile();

  let redirect = queryString('redirect');
  if (redirect?.includes('logout')) {
    redirect = null;
  }
  return (
    <Route {...rest} component={!userProfile ? rest.component : undefined}>
      {!userProfile ? (
        children
      ) : (
        <Redirect to={redirect == null ? '/' : redirect} />
      )}
    </Route>
  );
};

import * as React from 'react';
import { Text, Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

export const LoginLink: React.FC = () => (
  <Text textAlign="center">
    Already using Canyou?{' '}
    <Link as={ReactRouterLink} color="brand.blue" to="/login">
      Log in
    </Link>{' '}
    here!
  </Text>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountName = void 0;
const errors_1 = require("@zap-onboard/errors");
const maxLength = 32;
class AccountName {
    constructor(value) {
        this.value = value;
    }
    static parseSafe(s) {
        if (s.length > maxLength) {
            return AccountName.fromJSON(`${s.substring(0, maxLength - 3)}...`);
        }
        if (s.length < 1) {
            return AccountName.fromJSON('Unknown');
        }
        return AccountName.fromJSON(s);
    }
    static fake() {
        return AccountName.fromJSON('Fake Account Name');
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('Account name was given a non string value');
        }
        const name = new AccountName(s.trim());
        name.validate();
        return name;
    }
    static isValid(s) {
        return this.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            this.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    toString() {
        return `AccountName{${this.value}}`;
    }
    asJSON() {
        return this.value;
    }
    validate() {
        if (this.value.length < 1) {
            throw errors_1.errs.ValidationError.create('Account name must be at least one character');
        }
        if (this.value.length > maxLength) {
            throw errors_1.errs.ValidationError.create('Account name must be at most 32 characters');
        }
    }
}
exports.AccountName = AccountName;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisaDetailsTaskDesign = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const TaskDesign_1 = require("../TaskDesign/TaskDesign");
const TaskDesignTypes_1 = require("../TaskDesign/TaskDesignTypes");
class VisaDetailsTaskDesign extends TaskDesign_1.TaskDesign {
    toCreateArgs() {
        const { name, type, taskDesignId } = this;
        return {
            name,
            type,
            taskDesignId,
        };
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaDetailsTaskDesign.prototype, "type", void 0);
exports.VisaDetailsTaskDesign = VisaDetailsTaskDesign;

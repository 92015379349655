var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { FormControl as ChakraFormControl, FormErrorMessage, FormLabel, Tooltip, } from '@chakra-ui/react';
import { get, useFormContext } from 'react-hook-form';
export var FormControl = function (_a) {
    var _b, _c;
    var name = _a.name, _d = _a.id, id = _d === void 0 ? name : _d, label = _a.label, children = _a.children, _label = _a._label, _e = _a.errorPath, errorPath = _e === void 0 ? name : _e, isRequired = _a.isRequired, isDisabled = _a.isDisabled, isReadOnly = _a.isReadOnly, _control = _a._control, _f = _a.variant, variant = _f === void 0 ? 'normal' : _f, tooltip = _a.tooltip, uncontrolled = _a.uncontrolled, error = _a.error;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var context = uncontrolled ? undefined : useFormContext();
    var errorMessage = error !== null && error !== void 0 ? error : (_c = get((_b = context === null || context === void 0 ? void 0 : context.errors) !== null && _b !== void 0 ? _b : {}, errorPath, undefined)) === null || _c === void 0 ? void 0 : _c.message;
    var labelProps = React.useMemo(function () {
        if (variant === 'small') {
            return {
                fontSize: 'xs',
                fontWeight: 'bold',
            };
        }
        return {
            variant: 'light',
            fontWeight: 'bold',
        };
    }, [variant]);
    var withTooltip = function (c) {
        return tooltip ? (_jsx(Tooltip, __assign({ label: tooltip, placement: "bottom-start" }, { children: c }), void 0)) : (c);
    };
    return (_jsxs(ChakraFormControl, __assign({ id: id, isInvalid: !!errorMessage, isRequired: isRequired, isDisabled: isDisabled, isReadOnly: isReadOnly }, _control, { children: [label && (_jsxs(_Fragment, { children: [typeof label !== 'string' && _jsx(_Fragment, { children: label }, void 0), typeof label === 'string' &&
                        withTooltip(_jsx(FormLabel, __assign({}, labelProps, _label, { children: label }), void 0))] }, void 0)), children, _jsx(FormErrorMessage, { children: errorMessage || 'Invalid' }, void 0)] }), void 0));
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationStatus = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
class NoneStatus extends ResponseBase_1.ResponseBase {
}
NoneStatus.type = 'NONE';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['NONE']),
    (0, tslib_1.__metadata)("design:type", String)
], NoneStatus.prototype, "type", void 0);
class SentStatus extends ResponseBase_1.ResponseBase {
}
SentStatus.type = 'SENT';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['SENT']),
    (0, tslib_1.__metadata)("design:type", String)
], SentStatus.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], SentStatus.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], SentStatus.prototype, "sentAt", void 0);
class AcceptedStatus extends ResponseBase_1.ResponseBase {
}
AcceptedStatus.type = 'ACCEPTED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['ACCEPTED']),
    (0, tslib_1.__metadata)("design:type", String)
], AcceptedStatus.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], AcceptedStatus.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], AcceptedStatus.prototype, "acceptedAt", void 0);
class InvitationStatus extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [NoneStatus, SentStatus, AcceptedStatus],
}) {
}
exports.InvitationStatus = InvitationStatus;
(function (InvitationStatus) {
    InvitationStatus.Accepted = AcceptedStatus;
    InvitationStatus.Sent = SentStatus;
    InvitationStatus.None = NoneStatus;
})(InvitationStatus || (InvitationStatus = {}));
exports.InvitationStatus = InvitationStatus;

import React from 'react';
import { BaseIconProps } from '../BaseIcon';

import { ReactComponent as BlueIcon } from './dashboard-blue.svg';
import { ReactComponent as WhiteIcon } from './dashboard-white.svg';

type DashboardIconProps = BaseIconProps;

export const DashboardIcon: React.FC<DashboardIconProps> = ({
  iconColor,
  width,
  height,
}) =>
  iconColor === 'white' ? (
    <WhiteIcon width={width} height={height} />
  ) : (
    <BlueIcon width={width} height={height} />
  );

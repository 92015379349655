"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handler = void 0;
const designed_1 = require("designed");
const errors_1 = require("@zap-onboard/errors");
const mergedErrors = errors_1.errs.APIErrorClasses;
const handler = (client, method) => {
    const wrappedHandler = (...args) => {
        const result = designed_1.Result.fromPromise(method(...args));
        return result
            .mapFailure((err) => {
            if (mergedErrors.find((expectedErrorClass) => err instanceof expectedErrorClass)) {
                return err;
            }
            return coerceToUnexpectedError(err);
        })
            .mapFailure((error) => {
            client.$error.publish(error);
            return error;
        });
    };
    return wrappedHandler;
};
exports.handler = handler;
function coerceToUnexpectedError(err) {
    if (err instanceof Error) {
        return errors_1.errs.UnexpectedError.wrap(err);
    }
    return errors_1.errs.UnexpectedError.create(`non error value ${err} was thrown`);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YoutubeVideoFormSubmissionItem = exports.YoutubeVideoFormSchemaItem = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const utils_1 = require("../../../utils");
const FormSchemaItem_1 = require("./FormSchemaItem");
const type = 'YOUTUBE_VIDEO';
class YoutubeVideoFormSchemaItem extends FormSchemaItem_1.FormSchemaItem {
    constructor() {
        super(...arguments);
        this.type = type;
    }
    async validateItem() { }
    makeEmptySubmission() {
        return YoutubeVideoFormSubmissionItem.create({
            type: 'YOUTUBE_VIDEO',
            formSchemaItemId: this.formSchemaItemId,
        });
    }
}
YoutubeVideoFormSchemaItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], YoutubeVideoFormSchemaItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.YoutubeVideo),
    (0, tslib_1.__metadata)("design:type", utils_1.YoutubeVideo)
], YoutubeVideoFormSchemaItem.prototype, "video", void 0);
exports.YoutubeVideoFormSchemaItem = YoutubeVideoFormSchemaItem;
class YoutubeVideoFormSubmissionItem extends FormSchemaItem_1.FormSubmissionItemBase {
    constructor() {
        super(...arguments);
        this.type = type;
    }
}
YoutubeVideoFormSubmissionItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], YoutubeVideoFormSubmissionItem.prototype, "type", void 0);
exports.YoutubeVideoFormSubmissionItem = YoutubeVideoFormSubmissionItem;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwFromAPIIfError = void 0;
const tslib_1 = require("tslib");
const errs = (0, tslib_1.__importStar)(require("./Errors"));
const errorsByCode = Object.entries(errs).reduce((byCode, [, err]) => {
    if (byCode[err.apiCode]) {
        throw new Error(`${err.apiCode} has already been declared as an error`);
    }
    byCode[err.apiCode] = err;
    return byCode;
}, {});
const APIGWMessages = [
    'Internal Server Error',
    'Service Unavailable',
    'Too Many Requests',
    'Not Found',
];
function throwFromAPIIfError(data) {
    if (isAPIError(data)) {
        const errorByCode = errorsByCode[data.error.code];
        if (errorsByCode == undefined) {
            throw errs.UnexpectedError.create(data.error.message, {
                details: { responseBody: data },
            });
        }
        else {
            throw errorByCode.create(data.error.message, {
                details: { responseBody: data },
            });
        }
    }
    if (isAPIGWError(data)) {
        if (data.message === 'Internal Server Error') {
            throw errs.UnexpectedError.create('Connection error', {
                details: {
                    expectedType: 'Lambda exited unexpectedly',
                    responseBody: data,
                },
            });
        }
        if (data.message === 'Service Unavailable') {
            throw errs.UnexpectedError.create('Connection Error', {
                details: {
                    expectedType: 'Lambda is throttled or cannot be executed',
                    responseBody: data,
                },
            });
        }
        if (data.message === 'Too Many Requests') {
            throw errs.UnexpectedError.create('Connection Error', {
                details: {
                    expectedType: 'Lambda is being throttled',
                    responseBody: data,
                },
            });
        }
        if (data.message === 'Not Found') {
            throw errs.UnexpectedError.create('Connection Error', {
                details: {
                    expectedType: 'APIGW Likely does not contain a route for this request',
                    responseBody: data,
                },
            });
        }
    }
}
exports.throwFromAPIIfError = throwFromAPIIfError;
function isAPIGWError(data) {
    return (typeof data === 'object' &&
        !!data &&
        typeof data.message === 'string' &&
        APIGWMessages.includes(data.message));
}
function isAPIError(data) {
    return (!!data &&
        typeof data === 'object' &&
        typeof data.error === 'object' &&
        typeof data.error.code === 'string');
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsignTaskActions = exports.EsignAttachDocumentAction = exports.EsignSignAction = exports.EsignTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FileClaim_1 = require("../../../file/FileClaim");
const ResponseBase_1 = require("../../../ResponseBase");
var EsignTaskActionType;
(function (EsignTaskActionType) {
    EsignTaskActionType["ESIGN_SIGN_ACTION"] = "ESIGN_SIGN_ACTION";
    EsignTaskActionType["ESIGN_ATTACH_DOCUMENT"] = "ESIGN_ATTACH_DOCUMENT";
})(EsignTaskActionType = exports.EsignTaskActionType || (exports.EsignTaskActionType = {}));
class EsignSignAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return EsignSignAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], EsignSignAction.prototype, "taskId", void 0);
exports.EsignSignAction = EsignSignAction;
class EsignAttachDocumentAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return EsignAttachDocumentAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], EsignAttachDocumentAction.prototype, "claim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], EsignAttachDocumentAction.prototype, "taskId", void 0);
exports.EsignAttachDocumentAction = EsignAttachDocumentAction;
exports.EsignTaskActions = {
    [EsignTaskActionType.ESIGN_SIGN_ACTION]: EsignSignAction,
    [EsignTaskActionType.ESIGN_ATTACH_DOCUMENT]: EsignAttachDocumentAction,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = exports.GetUsersResponse = exports.GetUsersRowResponse = exports.GetUsersRequest = exports.GetMembersResponse = exports.GetMembersRowResponse = exports.GetMembersRequest = exports.GetFeatureFlagsResponse = exports.GetFeatureFlagsResponseRowResponse = exports.GetFeatureFlagsRequest = exports.SetFeatureFlagRequest = exports.ArchiveMemberRequest = exports.GetUserMembersResponse = exports.GetUserMembersResponseItem = exports.GetUserMembersRequest = exports.Change2FANumber = exports.ChangeEmailRequest = exports.DataRows = exports.DataRow = exports.DataGroups = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const AdminGetBusinesses_1 = require("./AdminGetBusinesses");
const ResponseBase_1 = require("../ResponseBase");
const ContactNumber_1 = require("../utils/contactNumber/ContactNumber");
const Email_1 = require("../utils/email/Email");
const handler_1 = require("../utils/handler");
exports.DataGroups = [
    'flows',
    'businesses',
    'users',
    'documents',
    'notifications',
    'esign',
    'billing',
    'integrations',
    'certs',
];
class DataRow extends ResponseBase_1.ResponseBase {
    getId() {
        return `${this.table}:${this.id}`;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], DataRow.prototype, "id", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], DataRow.prototype, "table", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DataRow.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DataRow.prototype, "graphed", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(exports.DataGroups),
    (0, tslib_1.__metadata)("design:type", String)
], DataRow.prototype, "group", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsString)({ each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], DataRow.prototype, "childUniqueIds", void 0);
exports.DataRow = DataRow;
class DataRows extends ResponseBase_1.ResponseBase {
    toGraph() {
        const root = this.rows.find((row) => row.table === 'businesses');
        if (!root) {
            return { nodes: [], links: [] };
        }
        const indexedRows = new Map(this.rows.map((row) => [row.getId(), row]));
        const getChildLinks = (row, source) => {
            const nextSource = row.graphed ? row.getId() : source;
            return row.childUniqueIds.flatMap((childId) => {
                if (!indexedRows.has(childId)) {
                    return [];
                }
                const child = indexedRows.get(childId);
                if (child.graphed) {
                    return [
                        { source: nextSource, target: childId },
                        ...getChildLinks(child, nextSource),
                    ];
                }
                return getChildLinks(child, nextSource);
            });
        };
        const bareLinks = getChildLinks(root, root.getId());
        const nodes = this.rows
            .filter((row) => row.graphed)
            .map((row) => {
            return {
                id: row.getId(),
                name: row.name ?? row.table,
                group: row.group,
            };
        });
        const graphedNodes = new Map(nodes.map((node) => [node.id, node]));
        const links = bareLinks.map((link) => {
            return {
                source: graphedNodes.get(link.source),
                target: graphedNodes.get(link.target),
            };
        });
        return { nodes, links };
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: DataRow }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsInstance)(DataRow, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], DataRows.prototype, "rows", void 0);
exports.DataRows = DataRows;
class ChangeEmailRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], ChangeEmailRequest.prototype, "from", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], ChangeEmailRequest.prototype, "to", void 0);
exports.ChangeEmailRequest = ChangeEmailRequest;
class Change2FANumber extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], Change2FANumber.prototype, "userEmail", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ContactNumber_1.ContactNumber),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", ContactNumber_1.ContactNumber)
], Change2FANumber.prototype, "number", void 0);
exports.Change2FANumber = Change2FANumber;
class GetUserMembersRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], GetUserMembersRequest.prototype, "email", void 0);
exports.GetUserMembersRequest = GetUserMembersRequest;
class GetUserMembersResponseItem extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUserMembersResponseItem.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUserMembersResponseItem.prototype, "businessName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUserMembersResponseItem.prototype, "businessId", void 0);
exports.GetUserMembersResponseItem = GetUserMembersResponseItem;
class GetUserMembersResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: GetUserMembersResponseItem }),
    (0, class_validator_1.IsInstance)(GetUserMembersResponseItem, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetUserMembersResponse.prototype, "members", void 0);
exports.GetUserMembersResponse = GetUserMembersResponse;
class ArchiveMemberRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], ArchiveMemberRequest.prototype, "memberId", void 0);
exports.ArchiveMemberRequest = ArchiveMemberRequest;
class SetFeatureFlagRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SetFeatureFlagRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], SetFeatureFlagRequest.prototype, "entityId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SetFeatureFlagRequest.prototype, "entityType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], SetFeatureFlagRequest.prototype, "value", void 0);
exports.SetFeatureFlagRequest = SetFeatureFlagRequest;
class GetFeatureFlagsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetFeatureFlagsRequest.prototype, "entityId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetFeatureFlagsRequest.prototype, "entityType", void 0);
exports.GetFeatureFlagsRequest = GetFeatureFlagsRequest;
class GetFeatureFlagsResponseRowResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetFeatureFlagsResponseRowResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetFeatureFlagsResponseRowResponse.prototype, "entityId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetFeatureFlagsResponseRowResponse.prototype, "entityName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetFeatureFlagsResponseRowResponse.prototype, "entityType", void 0);
exports.GetFeatureFlagsResponseRowResponse = GetFeatureFlagsResponseRowResponse;
class GetFeatureFlagsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: GetFeatureFlagsResponseRowResponse }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsInstance)(GetFeatureFlagsResponseRowResponse, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetFeatureFlagsResponse.prototype, "flags", void 0);
exports.GetFeatureFlagsResponse = GetFeatureFlagsResponse;
class GetMembersRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRequest.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRequest.prototype, "search", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRequest.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetMembersRequest.prototype, "archived", void 0);
exports.GetMembersRequest = GetMembersRequest;
class GetMembersRowResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRowResponse.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRowResponse.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRowResponse.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], GetMembersRowResponse.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetMembersRowResponse.prototype, "name", void 0);
exports.GetMembersRowResponse = GetMembersRowResponse;
class GetMembersResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: GetMembersRowResponse }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsInstance)(GetMembersRowResponse, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetMembersResponse.prototype, "members", void 0);
exports.GetMembersResponse = GetMembersResponse;
class GetUsersRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUsersRequest.prototype, "search", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUsersRequest.prototype, "userId", void 0);
exports.GetUsersRequest = GetUsersRequest;
class GetUsersRowResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUsersRowResponse.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], GetUsersRowResponse.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetUsersRowResponse.prototype, "userName", void 0);
exports.GetUsersRowResponse = GetUsersRowResponse;
class GetUsersResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: GetUsersRowResponse }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsInstance)(GetUsersRowResponse, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetUsersResponse.prototype, "users", void 0);
exports.GetUsersResponse = GetUsersResponse;
class Client {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.changeEmail = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/admin/change-email')
            .bodyJSON(ChangeEmailRequest.create(args))
            .post());
        this.change2FANumber = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/admin/change-2fa-number')
            .bodyJSON(Change2FANumber.create(args))
            .post());
        this.getMembers = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/user-members')
                .optionalParams(GetUserMembersRequest.create(args).asJSON())
                .get((r) => GetUserMembersResponse.create(r));
        });
        this.archiveMember = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/archive-member')
                .bodyJSON(ArchiveMemberRequest.create(args))
                .post();
        });
        this.getBusinessData = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/business-data')
                .optionalParams(args)
                .get((r) => DataRows.create(r));
        });
        this.deleteBusinessData = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/delete-business-data')
                .bodyJSON(args)
                .post();
        });
        this.getUserData = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/user-data')
                .optionalParams(args)
                .get((r) => DataRows.create(r));
        });
        this.deleteUserData = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/delete-user-data')
                .bodyJSON(args)
                .post();
        });
        this.sendApologyBatch = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/apology-batch')
                .bodyJSON(args ?? {})
                .post();
        });
        this.getBusinesses = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .optionalParams(args)
                .to('/admin/businesses')
                .get((r) => AdminGetBusinesses_1.GetBusinessResponse.create(r));
        });
        this.setFeatureFlag = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/feature-flag')
                .bodyJSON(args)
                .post();
        });
        this.getFeatureFlags = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/feature-flag')
                .optionalParams(args)
                .get((r) => GetFeatureFlagsResponse.create(r));
        });
        this.searchMembers = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/members')
                .optionalParams(args)
                .get((r) => GetMembersResponse.create(r));
        });
        this.searchUsers = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/admin/users')
                .optionalParams(args)
                .get((r) => GetUsersResponse.create(r));
        });
    }
}
exports.Client = Client;

import { TaskAssignmentResolutionRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useCurrentBusinessId } from '../../../auth/selectors';
import { useHandlerQuery } from '../../useHandlerQuery';

export const useFlowResolveAssignments = (args: {
  subjectMemberId?: string;
  businessId: string;
  groups: Entity.Attributes<
    TaskAssignmentResolutionRequest['groups'][number]
  >[];
}) => {
  const { groups, subjectMemberId, businessId } = args;

  const isManagerOfBusiness = useCurrentBusinessId() === businessId;

  return useHandlerQuery(
    (api) => api.flows().resolveAssignments({ subjectMemberId, groups }),
    {
      key: ['FLOW_RESOLVE_ASSIGNMENTS', { subjectMemberId, groups }],
      enabled: isManagerOfBusiness,
    },
  );
};

const Keys = ['ArrowDown', 'ArrowUp', 'Enter'] as const;

type KeyHandlerMap<RT> = {
  [k in (typeof Keys)[number]]: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => RT;
} & { else: (event: React.KeyboardEvent<HTMLInputElement>) => RT };

export const keyboardHandler =
  <RT>(handlerMap: Partial<KeyHandlerMap<RT>>) =>
  (event: React.KeyboardEvent<HTMLInputElement>) => {
    const handler = handlerMap[event.key as keyof typeof handlerMap];
    if (handler) {
      return handler(event);
    }
    if (handlerMap.else) {
      return handlerMap.else(event);
    }
    return null;
  };

import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Center,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { biz } from '@zap-onboard/api-client';

type LandingSectionProps = {
  business: biz.Get.Business.Response;
  nextStage: () => void;
};

export const LandingSection: React.FC<LandingSectionProps> = ({
  business,
  nextStage,
}) => {
  if (!business) {
    return <Text fontSize="md">That business does not exist</Text>;
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={9}>
        <Box>
          <Center>
            <Avatar
              sx={{
                '& img': {
                  objectFit: 'contain',
                },
              }}
              background={business.business.logo ? 'none' : undefined}
              borderRadius="0"
              size="xl"
              name={business?.business.name}
              src={business.business.logo?.url}
            />
          </Center>
          <Stack spacing={2}>
            <Heading textAlign="center" size="md">
              Get started at {business.business.name}
            </Heading>
            <Text textAlign="center">Setup your account to start work</Text>
          </Stack>
        </Box>
        <Center>
          <Button onClick={nextStage} minWidth="100px">
            Request Access
          </Button>
        </Center>
      </Stack>
    </Stack>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Position = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var Position;
(function (Position) {
    let Attach;
    (function (Attach) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "positionId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "memberId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], Request.prototype, "assignNewFlows", void 0);
        Attach.Request = Request;
    })(Attach = Position.Attach || (Position.Attach = {}));
    let Remove;
    (function (Remove) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "memberId", void 0);
        Remove.Request = Request;
    })(Remove = Position.Remove || (Position.Remove = {}));
})(Position = exports.Position || (exports.Position = {}));

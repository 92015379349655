import { XeroMyConnectionDetailsResponse } from '@zap-onboard/api-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { onError } from '../../../libs/errorLib';
import { track } from '../../../libs/track';
import { useAPI } from '../../../services/API';

export const useXeroConnectedAuth = () => {
  const api = useAPI();
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoadingXeroConnection, setIsLoadingXeroConnection] = useState(false);
  const [connectionDetails, setConnectionDetails] =
    useState<XeroMyConnectionDetailsResponse>();

  const loadConnections = useCallback(async () => {
    if (!isLoadingXeroConnection) {
      setIsLoadingXeroConnection(true);
      await api
        .xero()
        .getMyConnectionDetails()
        .map((r) => r.map(setConnectionDetails), onError);
      if (firstLoad) {
        setFirstLoad(false);
      }
      setIsLoadingXeroConnection(false);
    }
  }, [api, firstLoad, isLoadingXeroConnection]);

  const updateConnection = useCallback(
    async (args: {
      businessId: string;
      xeroConnectedOrganizationId?: string;
    }) => {
      if (!isLoadingXeroConnection) {
        setIsLoadingXeroConnection(true);
        await api
          .xero()
          .setBusinessConnection(args)
          .map(() => {
            loadConnections();
            if (args.xeroConnectedOrganizationId) {
              track.event('Xero Grant Applied');
            } else {
              track.event('Xero Grant Revoked');
            }
          }, onError);
      }
      setIsLoadingXeroConnection(false);
    },
    [api, isLoadingXeroConnection, loadConnections],
  );

  useEffect(() => {
    loadConnections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businesses = useMemo(
    () =>
      (connectionDetails?.businesses ?? []).sort((a, b) =>
        a.businessName.localeCompare(b.businessName),
      ),
    [connectionDetails?.businesses],
  );

  return {
    connections: connectionDetails?.connections ?? [],
    businesses,
    isMyXeroAccountConnected: !!connectionDetails,
    loadConnections,
    updateConnection,
    isLoadingXeroConnection,
    isFirstLoadXeroConnection: firstLoad,
  };
};

import React, { useEffect, useRef } from 'react';
import type { FieldProps } from './Field';
import { pdf } from '@zap-onboard/api-client';
import { usePDFState } from '../usePDFState';
import { Box, Tooltip, chakra, Center, Image } from '@chakra-ui/react';
import { FieldConfig } from '../Designer/FieldConfig';

type Props = FieldProps & { field: pdf.Schema.Field.SignatureData };

const fieldConfig = FieldConfig.signature;
const Icon = chakra(fieldConfig.icon);

export const SignatureField = ({
  field,
  isForm,
  tabIndex,
  onFocus,
  color,
  isReadonly,
}: Props) => {
  const isSigned = usePDFState((s) =>
    s.computed.getFieldValue(field.fieldId, false),
  );
  const setValue = usePDFState((s) => s.setFieldValue);
  const setIsSigned = () => !isSigned && setValue(field.fieldId, true);
  const signatureDataURL = usePDFState(
    (s) => s.groups.find((g) => g.groupId === field.groupId)?.signatureDataURL,
  );

  const openModal = usePDFState((s) => s.openSignatureModal);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      usePDFState.subscribe((a) => {
        if (a.focusedFieldId == field.fieldId) {
          inputRef.current?.focus?.({ preventScroll: true });
        }
      }),
    [field.fieldId],
  );

  if (!isForm) {
    return (
      <Center ref={inputRef} tabIndex={0} w="100%" h="100%" overflow="visible">
        <Icon color={color} size={24} />
      </Center>
    );
  }

  const wrap = (el: React.ReactElement) => (
    <Tooltip
      isDisabled={isReadonly}
      label={isSigned && signatureDataURL ? 'Signed' : 'Add Signature'}
    >
      <Box
        className="pdf-ignore-pan"
        ref={inputRef}
        onFocus={onFocus}
        onClick={onFocus}
        tabIndex={isReadonly ? undefined : tabIndex}
        _focus={{
          outline: 'thin solid #000',
        }}
        w="100%"
        h="100%"
        overflow="visible"
      >
        {el}
      </Box>
    </Tooltip>
  );

  if (!isSigned || !signatureDataURL) {
    return wrap(
      <Center
        onClick={() => {
          if (isReadonly) {
            return;
          }
          if (!signatureDataURL) {
            openModal(field.fieldId);
          } else {
            setIsSigned();
          }
        }}
        className="pdf-ignore-pan"
        w="100%"
        h="100%"
      >
        <Icon color={color} size={24} />
      </Center>,
    );
  }

  return wrap(
    <Center w="100%" h="100%" className="pdf-ignore-pan">
      <Image src={signatureDataURL} maxW="100%" maxH="100%" />
    </Center>,
  );
};

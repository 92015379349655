import { NotificationListing } from '@zap-onboard/api-client';
import { Topic } from 'designed';
import React, { createContext, useContext, useState } from 'react';
import { useNotifications } from './useNotifications';
import { useNotificationToast } from './useNotificationToast';

export interface NotificationContext {
  notificationDrawer$: Topic<{ action: 'CLOSE' } | { action: 'OPEN' }>;
  unread: NotificationListing[];
  markAllNotificationsAsRead: () => unknown;
  markNotificationAsRead: (notificationId: string) => unknown;
  hiddenNotificationIds: Set<string>;
  visitNotification: (notificationId: string) => unknown;
  notifications: NotificationListing[] | undefined;
}

const NotificationContext = createContext<NotificationContext | undefined>(
  undefined,
);

export const ProvideNotificationContext: React.FC = ({ children }) => {
  const {
    notifications,
    visitNotification,
    hiddenNotificationIds,
    markNotificationAsRead,
    markAllNotificationsAsRead,
    unread,
  } = useNotifications();

  const [notificationDrawer$] = useState<
    NotificationContext['notificationDrawer$']
  >(() => Topic.create());

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const ctx = {
    notificationDrawer$,
    notifications: notifications.data,
    unread,
    markAllNotificationsAsRead,
    markNotificationAsRead,
    hiddenNotificationIds,
    visitNotification,
  };

  useNotificationToast(ctx);

  return (
    <NotificationContext.Provider value={ctx}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotificationContext(): NotificationContext {
  const ctx = useContext(NotificationContext);
  if (ctx === undefined) {
    throw new TypeError('useCtx must be inside a Provider with a value');
  }
  return ctx;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowDesignSharing = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TimeAndDate_1 = require("../../../utils/time/TimeAndDate");
const ResponseBase_1 = require("../../../ResponseBase");
const DecodableToken_1 = require("../../../utils/DecodableToken");
var FlowDesignSharing;
(function (FlowDesignSharing) {
    class ExportTokenPayload extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportTokenPayload.prototype, "flowDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsNumber)(),
        (0, tslib_1.__metadata)("design:type", Number)
    ], ExportTokenPayload.prototype, "version", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportTokenPayload.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportTokenPayload.prototype, "businessName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportTokenPayload.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)({ each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], ExportTokenPayload.prototype, "taskNames", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], ExportTokenPayload.prototype, "expiry", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportTokenPayload.prototype, "tokenId", void 0);
    FlowDesignSharing.ExportTokenPayload = ExportTokenPayload;
    class ExportToken extends (0, DecodableToken_1.makeDecodableToken)(ExportTokenPayload) {
    }
    FlowDesignSharing.ExportToken = ExportToken;
    class ExportRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportRequest.prototype, "flowDesignId", void 0);
    FlowDesignSharing.ExportRequest = ExportRequest;
    class ExportResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(ExportToken),
        (0, tslib_1.__metadata)("design:type", ExportToken)
    ], ExportResponse.prototype, "exportToken", void 0);
    FlowDesignSharing.ExportResponse = ExportResponse;
    class ImportRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(ExportToken),
        (0, tslib_1.__metadata)("design:type", ExportToken)
    ], ImportRequest.prototype, "exportToken", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ImportRequest.prototype, "name", void 0);
    FlowDesignSharing.ImportRequest = ImportRequest;
    class ImportTemplateRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ImportTemplateRequest.prototype, "flowDesignId", void 0);
    FlowDesignSharing.ImportTemplateRequest = ImportTemplateRequest;
    class ImportResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ImportResponse.prototype, "flowDesignId", void 0);
    FlowDesignSharing.ImportResponse = ImportResponse;
})(FlowDesignSharing = exports.FlowDesignSharing || (exports.FlowDesignSharing = {}));

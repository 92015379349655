"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisaDetailsTaskActions = exports.VisaDetailsApprovalAction = exports.VisaDetailsSubmissionAction = exports.VisaDetailsTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const VisaDetails_1 = require("../../../visa/models/VisaDetails");
var VisaDetailsTaskActionType;
(function (VisaDetailsTaskActionType) {
    VisaDetailsTaskActionType["VISA_DETAILS_SUBMISSION"] = "VISA_DETAILS_SUBMISSION";
    VisaDetailsTaskActionType["VISA_DETAILS_COMPLETED"] = "VISA_DETAILS_COMPLETED";
})(VisaDetailsTaskActionType = exports.VisaDetailsTaskActionType || (exports.VisaDetailsTaskActionType = {}));
class VisaDetailsSubmissionAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return VisaDetailsSubmissionAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaDetailsSubmissionAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(VisaDetails_1.VisaDetails),
    (0, tslib_1.__metadata)("design:type", VisaDetails_1.VisaDetails)
], VisaDetailsSubmissionAction.prototype, "submission", void 0);
exports.VisaDetailsSubmissionAction = VisaDetailsSubmissionAction;
class VisaDetailsApprovalAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return VisaDetailsApprovalAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaDetailsApprovalAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], VisaDetailsApprovalAction.prototype, "isApproved", void 0);
exports.VisaDetailsApprovalAction = VisaDetailsApprovalAction;
exports.VisaDetailsTaskActions = {
    [VisaDetailsTaskActionType.VISA_DETAILS_SUBMISSION]: VisaDetailsSubmissionAction,
    [VisaDetailsTaskActionType.VISA_DETAILS_COMPLETED]: VisaDetailsApprovalAction,
};

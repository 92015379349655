import { NotificationStatus } from '@zap-onboard/api-client';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../../../auth';
import { useHandler } from '../../../../hooks/useHandlerMutation';
import { useHandlerQuery } from '../../../../hooks/useHandlerQuery';

export const useNotifications = () => {
  const history = useHistory();
  const userProfile = auth.useProfile();
  /**
   * Instead of optimistic updates we hide all notifications we have marked as seen
   */
  const [hiddenNotificationIds] = useState<Set<string>>(() => new Set());
  const notifications = useHandlerQuery(
    (api) => api.notification().getNotifications({ unread: true }),
    {
      key: ['MY_NOTIFICATIONS'],
      staleAfter: '5s',
      refetchEvery: '15s',
      enabled: !!userProfile,
    },
  );

  /**
   * No invalidation required as we hide removed notifications. This reduces
   * the amount of notification refetches required.
   */
  const [updateNotifications, isUpdatingNotifications] = useHandler(
    (api) => api.notification().updateNotifications,
    {},
  );

  const markNotificationAsRead = useCallback(
    (notificationId: string) => {
      hiddenNotificationIds.add(notificationId);
      updateNotifications({
        notificationIds: [notificationId],
        status: NotificationStatus.READ,
      });
    },
    [hiddenNotificationIds, updateNotifications],
  );

  const unread = useMemo(
    () =>
      notifications.data?.filter(
        ({ status, notificationId }) =>
          status === NotificationStatus.UNREAD &&
          !hiddenNotificationIds.has(notificationId),
      ) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hiddenNotificationIds, notifications.data, hiddenNotificationIds.size],
  );

  const visitNotification = useCallback(
    (notificationId: string) => {
      const notification = notifications.data?.find(
        (n) => n.notificationId === notificationId,
      );
      if (!notification) {
        return;
      }
      if (notification.link) {
        markNotificationAsRead(notificationId);
        if (notification.link.assume?.value?.type === 'BUSINESS') {
          auth.setCurrentBusinessId(notification.link.assume.value.businessId);
        } else if (notification.link.assume?.value?.type === 'EMPLOYEE') {
          auth.setCurrentBusinessId(undefined);
        }
      }
      if (notification.link?.path) {
        history.push(notification.link.path);
      }
    },
    [history, markNotificationAsRead, notifications.data],
  );

  return {
    unread,
    notifications,
    isUpdatingNotifications,
    hiddenNotificationIds,
    markNotificationAsRead,
    visitNotification,
    markAllNotificationsAsRead: () => {
      if (notifications.data && notifications.data.length > 0) {
        updateNotifications({
          notificationIds: notifications.data.map((n) => {
            hiddenNotificationIds.add(n.notificationId);
            return n.notificationId;
          }),
          status: NotificationStatus.READ,
        });
      }
    },
  };
};

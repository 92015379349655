/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import { flow, TemplateTask as APITemplateTask } from '@zap-onboard/api-client';
import { Tabs } from '@zap-onboard/web-components';

import { BaseTaskPropTypes } from '../BaseTaskPropTypes';
import { TemplateTaskSigner } from './TemplateTaskSigner';
import { TemplateTaskHistory } from './TemplateTaskHistory';
import { TaskCompleted } from 'src/components/Flows/TaskManager/TaskStatus';
import { FilePreviewModal } from 'src/components/FilePreviewModal';

interface TemplateTaskProps extends BaseTaskPropTypes {
  task: APITemplateTask;
  flow: flow.GetFlows.Flow;
}

export const TemplateTask: React.FC<TemplateTaskProps> = ({
  task,
  submitAction,
  isSubmitting,
  flow,
}) => {
  const groupToSign = task.getGroupICanSign();

  const inProgressLayout = (
    <Tabs
      tabs={['History']}
      tabPanelProps={{ p: 0, py: 4 }}
      tabPanels={[
        <TemplateTaskHistory
          task={task}
          memberId={flow.memberId}
          businessId={flow.businessId}
        />,
      ]}
    />
  );
  const signerLayout = (
    <Tabs
      tabs={['Sign', 'History']}
      tabPanelProps={{ py: 4 }}
      tabPanels={[
        groupToSign ? (
          <TemplateTaskSigner
            key={groupToSign.groupId}
            businessName={flow.businessName}
            isSubmitting={isSubmitting}
            submitAction={submitAction}
            task={task}
            current={groupToSign}
          />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        ),
        <TemplateTaskHistory
          task={task}
          memberId={flow.memberId}
          businessId={flow.businessId}
        />,
      ]}
    />
  );

  const completeLayout = task.finalFile && (
    <Tabs
      tabs={['Document', 'History']}
      tabPanelProps={{ p: 0, py: 4 }}
      tabPanels={[
        <Box p={2}>
          <TaskCompleted />
          <Box textAlign="center">
            <FilePreviewModal
              file={task.finalFile}
              trigger="View Signed Document"
              filename={`${task.name}-signed`}
            />
          </Box>
        </Box>,
        <TemplateTaskHistory
          task={task}
          memberId={flow.memberId}
          businessId={flow.businessId}
        />,
      ]}
    />
  );

  return (
    <Box spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Heading variant="section" textAlign="center">
        {task.name}
      </Heading>

      {!!groupToSign && signerLayout}
      {!groupToSign && !task.finalFile && inProgressLayout}
      {!!task.finalFile && completeLayout}
    </Box>
  );
};

import { UpdateUserProfile } from '@zap-onboard/api-client';
import { onError } from '../../../../libs/errorLib';
import { UpdateUserProfileAttributes } from '../../../../pages/Profile/UserProfile';
import { useRefreshUserProfile } from '../useRefreshUserProfile';
import { track } from '../../../../libs/track';
import { useHandler } from '../../../useHandlerMutation';

export const useUpdateUserProfile = () => {
  const { refreshUserProfile } = useRefreshUserProfile();

  const [handler, isUpdatingUserProfile] = useHandler(
    (api) => api.identity().updateMyProfile,
    {
      invalidates: () => [['MY_PROFILE']],
      always: {
        onError,
        onSuccess: async () => {
          await refreshUserProfile();
          track.event('Profile Updated');
        },
      },
    },
  );

  return {
    updateUserProfile: async (data: UpdateUserProfileAttributes) =>
      handler(
        UpdateUserProfile.build({
          ...data,
          secondaryContactNumber:
            data.secondaryContactNumber === ''
              ? null
              : data.secondaryContactNumber,
          otherGivenName:
            data.otherGivenName === '' ? null : data.otherGivenName,
        }),
      ),
    isUpdatingUserProfile,
  };
};

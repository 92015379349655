/* eslint-disable @typescript-eslint/no-explicit-any */
import { Result } from 'designed';
import m from 'mixpanel-browser';

const set = (data: PeopleSetArgs) => {
  Result.fromThrowable(() => {
    m.people.set(data);
  });
};

const setOnce = (data: PeopleSetOnceArgs) => {
  Result.fromThrowable(() => {
    m.people.set_once(data);
  });
};

const increment = (data: PeopleIncrementArgs) => {
  Result.fromThrowable(() => {
    m.people.increment(data);
  });
};

export const people = {
  set,
  setOnce,
  increment,
};

// **************************************************

type PeopleSetArgs = {
  workLastCompleted?: Date;
  workLastAssigned?: Date;
  $name?: string;
  businesses?: string[];
  businessIds?: string[];
  memberAtBusiness?: string[];
  positions?: Array<string[]>;
};

type PeopleSetOnceArgs = {
  workFirstAssigned?: Date;
  workFirstCompleted?: Date;
};

type PeopleIncrementArgs = {
  totalWorkAssigned?: 1;
  totalWorkCompleted?: 1;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const allTaskStates_generated_1 = require("../../../allTaskStates.generated");
const ResponseBase_1 = require("../../../ResponseBase");
const FlowTaskListing_1 = require("../../models/FlowTaskListing");
const TaskDesign_1 = require("../TaskDesign");
class BaseTask extends ResponseBase_1.ResponseBase {
    taskCompleted() {
        return !(FlowTaskListing_1.FlowTaskStatus.IN_PROGRESS === this.status ||
            FlowTaskListing_1.FlowTaskStatus.NOT_STARTED === this.status);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTask.prototype, "flowId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTask.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(allTaskStates_generated_1.TaskStates),
    (0, tslib_1.__metadata)("design:type", Object)
], BaseTask.prototype, "taskStateId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTask.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(TaskDesign_1.TaskDesignType),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTask.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(FlowTaskListing_1.FlowTaskStatus),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTask.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], BaseTask.prototype, "allowedToAction", void 0);
exports.BaseTask = BaseTask;

/* eslint-disable no-await-in-loop */
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';

import {
  TaskActionType,
  BankAccountTask as APIBankAccountTask,
  BSB,
  AccountName,
  AccountNumber,
} from '@zap-onboard/api-client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { TaskActionSubmitter } from '../../../../hooks/data/flow/useTaskActionSubmission';
import { TaskCompleted, TaskSkipped } from '../TaskStatus';

interface BankAccountTaskProps {
  context: 'user' | 'business';
  canEdit: boolean;
  task?: APIBankAccountTask;
  submitAction?: TaskActionSubmitter;
}

interface FormData {
  bsb: string;
  number: string;
  name: string;
}
const { useState } = React;

export const BankAccountTask: React.FC<BankAccountTaskProps> = ({
  context,
  task,
  canEdit,
  submitAction,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isBusiness = context === 'business';
  const isTaskCompleted = task?.taskCompleted();
  const isTaskDisabled = isSubmitting || isTaskCompleted || !canEdit;

  const { handleSubmit, register, errors } = useForm<FormData>();

  const submit: SubmitHandler<FormData> = async ({ name, number, bsb }) => {
    if (submitAction && task) {
      setIsSubmitting(true);
      await submitAction(TaskActionType.BANK_ACCOUNT_SUBMISSION, {
        taskId: task.taskId,
        accountSubmission: {
          bsb,
          number,
          name,
        },
      });
      setIsSubmitting(false);
    }
  };

  const taskHeading = (
    <Heading variant="section" textAlign="center">
      {task?.name || 'Nominate Bank Account'}
    </Heading>
  );

  const taskBody = (
    <Stack>
      <FormControl isInvalid={!!errors.name}>
        <FormLabel>Account Name</FormLabel>
        <Input
          name="name"
          isDisabled={isBusiness || isTaskDisabled}
          ref={register({
            required: 'Account name is required',
            validate: (v) => AccountName.validationMessage(v) ?? undefined,
          })}
          defaultValue={task?.accountSubmission?.name?.asJSON() ?? ''}
          placeholder="Your account name"
        />

        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.bsb}>
        <FormLabel>BSB</FormLabel>
        <Input
          name="bsb"
          isDisabled={isBusiness || isTaskDisabled}
          ref={register({
            required: 'BSB is required',
            validate: (v) => BSB.validationMessage(v) ?? undefined,
          })}
          defaultValue={task?.accountSubmission?.bsb?.asJSON() ?? ''}
          placeholder="Your BSB"
        />
        <FormErrorMessage>{errors.bsb?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.number}>
        <FormLabel>Account Number</FormLabel>
        <Input
          name="number"
          isDisabled={isBusiness || isTaskDisabled}
          ref={register({
            required: 'Account number is required',
            validate: (v) => AccountNumber.validationMessage(v) ?? undefined,
          })}
          defaultValue={task?.accountSubmission?.number?.asJSON() ?? ''}
          placeholder="Your account number"
        />
        <FormErrorMessage>{errors.number?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );

  const taskActions = (
    <Center mt={4}>
      <ButtonGroup>
        {!isBusiness && (
          <Button
            disabled={isTaskDisabled}
            onClick={handleSubmit(submit)}
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        )}
      </ButtonGroup>
    </Center>
  );

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        {taskBody}
        <TaskCompleted />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack w="100%">
          {taskHeading}
          {taskBody}
        </Stack>
        {taskActions}
      </Flex>
    </Stack>
  );
};

import * as React from 'react';
import { Box, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { MdClose, MdLink } from 'react-icons/md';

import { NotificationListing } from '@zap-onboard/api-client';

type Props = {
  notification: NotificationListing;
  markAsRead: () => void;
  handleNavigation?: () => unknown;
  index: number;
};

export const NotificationItem: React.FC<Props> = ({
  notification,
  index,
  markAsRead,
  handleNavigation,
}) => (
  <Layout index={index}>
    <Navigation handleNavigation={handleNavigation}>
      <Heading notification={notification} />
      <Body notification={notification} />
      <TimeElapsed notification={notification} />
    </Navigation>
    <MarkAsRead handleMarkAsRead={markAsRead} index={index} />
  </Layout>
);

const Layout: React.FC<{ index: number }> = ({ index, children }) => (
  <Stack
    p={2}
    _hover={{ layerStyle: 'hovered' }}
    data-testid={`notification-${index}`}
  >
    <Flex justify="space-between">{children}</Flex>
  </Stack>
);

const Navigation: React.FC<{ handleNavigation?: () => void }> = ({
  handleNavigation,
  children,
}) => (
  <Box
    {...(handleNavigation
      ? {
          onClick: handleNavigation,
          role: 'button',
        }
      : {})}
  >
    {children}
  </Box>
);

const Heading: React.FC<{ notification: NotificationListing }> = ({
  notification,
}) => (
  <Flex gridGap={2} align="center">
    <Text fontWeight="bold" fontSize="sm">
      {notification.subject}
    </Text>
    {notification.link && <Icon as={MdLink} />}
  </Flex>
);

const Body: React.FC<{ notification: NotificationListing }> = ({
  notification,
}) => (
  <Text color="brand.dark-gray" fontSize="sm">
    {notification.body}
  </Text>
);

const TimeElapsed: React.FC<{ notification: NotificationListing }> = ({
  notification,
}) => (
  <Text color="brand.blue" fontSize="sm">
    {notification.timestamp.toTimeFromNow()}
  </Text>
);

const MarkAsRead: React.FC<{ index: number; handleMarkAsRead: () => void }> = ({
  index,
  handleMarkAsRead,
}) => (
  <Icon
    as={MdClose}
    color="brand.dark-gray"
    data-testid={`markAsReadIcon-${index}`}
    onClick={handleMarkAsRead}
    _hover={{ cursor: 'pointer' }}
  />
);

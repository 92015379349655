"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReminderClient = exports.DeleteReminderRequest = exports.UpdateReminderRequest = exports.CreateReminderRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const models_1 = require("./models");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
const handler_1 = require("../utils/handler");
class CreateReminderRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateReminderRequest.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], CreateReminderRequest.prototype, "date", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.Max)(365),
    (0, tslib_1.__metadata)("design:type", Number)
], CreateReminderRequest.prototype, "dateNotificationPeriodInDays", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], CreateReminderRequest.prototype, "description", void 0);
exports.CreateReminderRequest = CreateReminderRequest;
class UpdateReminderRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateReminderRequest.prototype, "reminderId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateReminderRequest.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], UpdateReminderRequest.prototype, "date", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.Max)(365),
    (0, tslib_1.__metadata)("design:type", Number)
], UpdateReminderRequest.prototype, "dateNotificationPeriodInDays", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateReminderRequest.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpdateReminderRequest.prototype, "isCompleted", void 0);
exports.UpdateReminderRequest = UpdateReminderRequest;
class DeleteReminderRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeleteReminderRequest.prototype, "reminderId", void 0);
exports.DeleteReminderRequest = DeleteReminderRequest;
class ReminderClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.getReminders = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('business/reminders')
            .get((d) => models_1.ReminderList.create(d)));
        /**
         * @example
         * ```ts
         * api.reminders().createReminder({
         *    title: 'Increase pay rate',
         *    date: '23-8-2021',
         *    dateNotificationPeriodInDays: 30,
         *    description: 'For all the front of house crew',
         *  })
         * ```
         */
        this.createReminder = (0, handler_1.handler)(this, async (reminder) => this.http
            .request()
            .to('business/my/reminders')
            .bodyJSON(CreateReminderRequest.create(reminder))
            .post((d) => models_1.Reminder.create(d)));
        this.updateReminder = (0, handler_1.handler)(this, async (reminderUpdate) => this.http
            .request()
            .to(`business/my/reminders/update`)
            .bodyJSON(UpdateReminderRequest.create(reminderUpdate))
            .post((d) => models_1.Reminder.create(d)));
        this.deleteReminder = (0, handler_1.handler)(this, async (deleteReminder) => this.http
            .request()
            .to(`business/my/reminders`)
            .bodyJSON(DeleteReminderRequest.create(deleteReminder))
            .delete());
    }
}
exports.ReminderClient = ReminderClient;

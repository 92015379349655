"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroContactListResponse = exports.XeroContactListing = exports.XeroContactPersonListing = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
class XeroContactPersonListing extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroContactPersonListing.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroContactPersonListing.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroContactPersonListing.prototype, "firstName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroContactPersonListing.prototype, "lastName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], XeroContactPersonListing.prototype, "email", void 0);
exports.XeroContactPersonListing = XeroContactPersonListing;
class XeroContactListing extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroContactListing.prototype, "xeroSyncedContactId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroContactListing.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroContactListing.prototype, "isSupplier", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroContactListing.prototype, "isCustomer", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: XeroContactPersonListing }),
    (0, class_validator_1.IsInstance)(XeroContactPersonListing, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], XeroContactListing.prototype, "persons", void 0);
exports.XeroContactListing = XeroContactListing;
const contactSyncStatus = ['IN_PROGRESS', 'COMPLETE'];
class XeroContactListResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(contactSyncStatus),
    (0, tslib_1.__metadata)("design:type", Object)
], XeroContactListResponse.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: XeroContactListing }),
    (0, class_validator_1.IsInstance)(XeroContactListing, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], XeroContactListResponse.prototype, "contacts", void 0);
exports.XeroContactListResponse = XeroContactListResponse;

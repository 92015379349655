"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeDecodableToken = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const designed_1 = require("designed");
const TimeAndDate_1 = require("./time/TimeAndDate");
/**
 * Isomorphic decodable token with a typed base64 encoded body and signature.
 * The signature can only be verified on the server by an implemented `Decoder`
 * which may/may not use HMAC.
 *
 * If the payload has an "expiry" field of type TimeAndDate it will ensure it
 * has not passed during verification.
 */
const makeDecodableToken = (payloadKlass) => {
    var _DecodableToken_decoder, _DecodableToken_signature, _DecodableToken_body, _a;
    const parseb64 = typeof window === 'undefined'
        ? (d) => Buffer.from(d, 'base64').toString()
        : window.atob;
    const encodeb64 = typeof window === 'undefined'
        ? (d) => Buffer.from(d).toString('base64')
        : window.btoa;
    return _a = class DecodableToken {
            constructor(signature, body) {
                _DecodableToken_decoder.set(this, void 0);
                _DecodableToken_signature.set(this, void 0);
                _DecodableToken_body.set(this, void 0);
                (0, tslib_1.__classPrivateFieldSet)(this, _DecodableToken_signature, signature, "f");
                (0, tslib_1.__classPrivateFieldSet)(this, _DecodableToken_body, body, "f");
            }
            static fromJSON(raw) {
                const [signature, body] = raw.split(':');
                return new this(signature, body);
            }
            static encode(payload, decoder) {
                return designed_1.Result.fromThrowable(() => {
                    const body = encodeb64(JSON.stringify(payloadKlass.create(payload)));
                    return new this(decoder.encode({ body }).signature, body).withDecoder(decoder);
                });
            }
            withDecoder(decoder) {
                (0, tslib_1.__classPrivateFieldSet)(this, _DecodableToken_decoder, decoder, "f");
                return this;
            }
            /**
             * @example
             * DecodableToken
             *  .fromJSON('signature:body')
             *  .withDecoder(verifyHMAC)
             *  .verify()
             */
            verify() {
                return designed_1.Result.fromThrowable(() => {
                    if (!(0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_decoder, "f")) {
                        throw errors_1.errs.NotImplemented.create('Decoder was not set for DecodableToken.verify()', {
                            details: { body: (0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_body, "f") },
                        });
                    }
                    (0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_decoder, "f").decode({
                        body: (0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_body, "f"),
                        signature: (0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_signature, "f"),
                    });
                    const data = this.readUnverifiedPayload().getOrThrowFailure();
                    if (hasExpiry(data) && data.expiry.isInPast()) {
                        throw errors_1.errs.AuthorizationError.create(`Token expired ${data.expiry.toTimeFromNow()}`, {
                            details: { expiry: data.expiry.asJSON(), body: (0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_body, "f") },
                        });
                    }
                    return data;
                });
            }
            readUnverifiedPayload() {
                return designed_1.Result.fromThrowable(() => payloadKlass.create(JSON.parse(parseb64((0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_body, "f"))))).mapFailure((err) => errors_1.errs.SerializationError.wrap(err, 'Could not parse token data'));
            }
            asJSON() {
                return `${(0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_signature, "f")}:${(0, tslib_1.__classPrivateFieldGet)(this, _DecodableToken_body, "f")}`;
            }
            toJSON() {
                return this.asJSON();
            }
        },
        _DecodableToken_decoder = new WeakMap(),
        _DecodableToken_signature = new WeakMap(),
        _DecodableToken_body = new WeakMap(),
        _a;
};
exports.makeDecodableToken = makeDecodableToken;
function hasExpiry(v) {
    return typeof v === 'object' && v !== null && v.expiry instanceof TimeAndDate_1.TimeAndDate;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

type DeleteModalProps = {
  callBack: () => unknown;
  isLoading?: boolean;
  setIsLoading?: (v: boolean) => void;
  modalHeaderText: string;
  modalBodyText: string;
  isOpen: boolean;
  onClose: () => void;
  confirmText?: string;
  cancelText?: string;
};

export const DeleteModal: React.FC<DeleteModalProps> = ({
  callBack,
  isLoading,
  setIsLoading,
  modalHeaderText,
  modalBodyText,
  isOpen,
  onClose,
  confirmText = 'Delete',
  cancelText = 'Cancel',
}) => {
  const handleDelete = async () => {
    if (setIsLoading) {
      setIsLoading(true);
    }
    await callBack();
    if (setIsLoading) {
      setIsLoading(false);
    }
    onClose();
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-testid="deleteModalHeader">
          {modalHeaderText}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>{modalBodyText}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid="deleteModalButton"
            mr={3}
            variant="danger"
            isLoading={isLoading}
            onClick={handleDelete}
          >
            {confirmText}
          </Button>
          <Button disabled={isLoading} variant="outline" onClick={onClose}>
            {cancelText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;

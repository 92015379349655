/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { auth } from '../../../auth';
import { QueryConfig, useHandlerQuery } from '../../useHandlerQuery';

export const useCurrentBusiness = (
  overrides?: Partial<QueryConfig<unknown>>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const businessId = auth.useCurrentBusinessId()!;
  const result = useHandlerQuery((api) => api.business().get(businessId), {
    enabled: !!businessId,
    key: ['BUSINESS', businessId],
    staleAfter: '1m',
    ...overrides,
  });

  return {
    ...result,
    data: result.data
      ? {
          business: result.data.business,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore This is guaranteed if the business is managed by the user
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          config: result.data.config!,
          billingStatus: result.data.billingStatus,
        }
      : undefined,
  };
};

import { useHandler } from '../../useHandlerMutation';

export const useMyobCreateEmployee = () => {
  const [myobCreateEmployee, isLoading] = useHandler(
    (api) => api.myob().createEmployeeInMyob,
    {},
  );
  return {
    myobCreateEmployee,
    isLoading,
  };
};

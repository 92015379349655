"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TFNDTaskActions = exports.TFNDPayeeSubmissionAction = exports.TFNDTaskActionType = void 0;
const tslib_1 = require("tslib");
require("reflect-metadata");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const TFNDPayeeSubmission_1 = require("./TFNDPayeeSubmission");
var TFNDTaskActionType;
(function (TFNDTaskActionType) {
    TFNDTaskActionType["TFND_PAYEE_SUBMISSION"] = "TFND_PAYEE_SUBMISSION";
})(TFNDTaskActionType = exports.TFNDTaskActionType || (exports.TFNDTaskActionType = {}));
class TFNDPayeeSubmissionAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return TFNDPayeeSubmissionAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmissionAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TFNDPayeeSubmission_1.TFNDPayeeSubmission),
    (0, tslib_1.__metadata)("design:type", TFNDPayeeSubmission_1.TFNDPayeeSubmission)
], TFNDPayeeSubmissionAction.prototype, "submission", void 0);
exports.TFNDPayeeSubmissionAction = TFNDPayeeSubmissionAction;
exports.TFNDTaskActions = {
    [TFNDTaskActionType.TFND_PAYEE_SUBMISSION]: TFNDPayeeSubmissionAction,
};

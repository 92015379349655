import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, useDisclosure, MenuButton } from '@chakra-ui/react';
import { MdAddBusiness, MdLogout, MdLockOpen } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';

import { useCompactView } from '../../../../hooks';

import { SetupBusinessModal } from '../../../Business/Settings/Business/SetupBusinessModal';
import { Menu, MenuItems } from '@zap-onboard/web-components';
import { auth } from '../../../../auth';

type AvatarDropDownProps = {
  handleLogout: () => void;
};

export const AvatarDropDown: React.FC<AvatarDropDownProps> = ({
  handleLogout,
}) => {
  const userProfile = auth.useProfile();
  const memberships = auth.useMemberships();
  const history = useHistory();
  const { isCompactView } = useCompactView();
  const registerBusinessDisclosure = useDisclosure();
  const isAdminOrOwner = memberships.some(
    (m) => m.role.isAdmin() || m.role.isOwner(),
  );

  const menuItems: MenuItems = [
    {
      items: [
        {
          title: 'Profile',
          action: () => history.push('/profile'),
          Icon: <FaUser />,
        },

        {
          title: 'Authentication',
          Icon: <MdLockOpen />,
          action: () => history.push('/authentication'),
        },

        {
          title: 'Register business',
          action: registerBusinessDisclosure.onOpen,
          Icon: <MdAddBusiness />,
          hideMenuItem: !isAdminOrOwner,
        },
      ],
    },
    {
      items: [
        {
          title: 'Log out',
          action: handleLogout,
          Icon: <MdLogout />,
        },
      ],
    },
  ];

  return (
    <>
      <Menu
        menuItems={menuItems}
        render={() => (
          <Avatar
            as={MenuButton}
            name={
              userProfile?.givenName
                ? `${userProfile.givenName} ${userProfile.familyName}`
                : `${userProfile?.email}`
            }
            size="sm"
            bg={isCompactView ? '#fff' : 'brand.blue'}
            color={isCompactView ? 'brand.blue' : '#fff'}
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
          />
        )}
      />
      {isAdminOrOwner && <SetupBusinessModal {...registerBusinessDisclosure} />}
    </>
  );
};

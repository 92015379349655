"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeputyLinkEmployeeTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const deputy_1 = require("../../../deputy");
const BaseTask_1 = require("../Task/BaseTask");
class DeputyLinkEmployeeTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(deputy_1.DeputyEmployeeDetailsResponse),
    (0, tslib_1.__metadata)("design:type", deputy_1.DeputyEmployeeDetailsResponse)
], DeputyLinkEmployeeTask.prototype, "inferredEmployee", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(deputy_1.DeputyEmployeeDetailsResponse),
    (0, tslib_1.__metadata)("design:type", deputy_1.DeputyEmployeeDetailsResponse)
], DeputyLinkEmployeeTask.prototype, "linkedEmployee", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DeputyLinkEmployeeTask.prototype, "isBusinessLinkedToDeputy", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DeputyLinkEmployeeTask.prototype, "employeeProfileComplete", void 0);
exports.DeputyLinkEmployeeTask = DeputyLinkEmployeeTask;

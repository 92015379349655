import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  Grid,
  GridItem,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { UpdateUserProfile } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useUpdateUserProfile } from '../../../hooks/data/userProfile';

import { ProfileForm } from './Form/Form';
import { z } from '../../../helpers/schema';
import { auth } from '../../../auth';

const { useState } = React;

export type UpdateUserProfileAttributes = Entity.Attributes<UpdateUserProfile>;

export const UserProfile: React.FC = () => {
  const userProfile = auth.useProfile();
  const { updateUserProfile } = useUpdateUserProfile();

  const usedForm = useForm<z.infer<typeof ProfileForm.Schema>>({
    resolver: z.zodResolver(ProfileForm.Schema),
    defaultValues: ProfileForm.getDefaultValue(userProfile ?? undefined),
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = usedForm;

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onSubmit = handleSubmit(async (data) => {
    await updateUserProfile({
      ...data,
    });
    setIsEditMode(false);
  });

  const cancelUpdate = () => {
    reset(ProfileForm.getDefaultValue(userProfile ?? undefined));
    setIsEditMode(false);
  };

  if (!userProfile) {
    return (
      <Center bg="white" minH="200px" w="100%">
        <Text>No user profile available.</Text>
      </Center>
    );
  }

  return (
    <FormProvider {...usedForm}>
      <Grid
        templateColumns="1fr"
        templateRows="auto 1fr"
        p={4}
        layerStyle="base"
        w={{ base: '100%', md: '75%', lg: '50%' }}
        rowGap={2}
      >
        <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            justify="space-between"
          >
            <Heading variant="section" size="md" alignSelf="center">
              My details
            </Heading>

            {isEditMode ? (
              <ButtonGroup my={1} justifyContent="flex-end">
                <Button
                  type="submit"
                  onClick={onSubmit}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
                <Button
                  variant="outline"
                  onClick={cancelUpdate}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup my={1}>
                <Button variant="outline" onClick={() => setIsEditMode(true)}>
                  Edit
                </Button>
              </ButtonGroup>
            )}
          </Flex>
        </GridItem>
        <GridItem colStart={1} colSpan={1} rowStart={2} rowSpan={1}>
          <ProfileForm.Component isEditMode={isEditMode} />
        </GridItem>
      </Grid>
    </FormProvider>
  );
};

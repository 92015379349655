/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { AsyncResult } from 'designed';

export const useOTP = ({ gotOTP }: { gotOTP: (code: string) => unknown }) => {
  const [aborter, setAborter] = useState<AbortController>(
    new AbortController(),
  );

  const startOTP = useCallback(async () => {
    if ('OTPCredential' in window) {
      AsyncResult.fromPromise(
        navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: aborter.signal,
        } as any),
      ).tap((otp) => {
        const code = (otp as any)?.code;
        if (typeof code === 'string' && code.length === 4) {
          gotOTP(code);
        }
      });
    }
  }, [aborter, gotOTP]);

  useEffect(() => {
    startOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      if (!aborter.signal.aborted) {
        aborter.abort();
      }
      setAborter(new AbortController());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return {
    restartOTP: () => {
      if (!aborter.signal.aborted) {
        aborter.abort();
      }
      setAborter(new AbortController());
      startOTP();
    },
  };
};

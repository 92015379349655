"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingClient = exports.PurchaseProductRequest = exports.BillingAccountPaymentSummaryResponse = exports.PlanInvoice = exports.PlanInvoiceLine = exports.SubscribeBusinessWithBillingAccountRequest = exports.UnsubscribeBusinessRequest = exports.CloseBillingAccountRequest = exports.UpdateBillingAccountDetailsRequest = exports.AttachStripePaymentMethodToBillingAccountRequest = exports.CreateStripeCardSetupIntentResponse = exports.CreateStripeCardSetupIntentRequest = exports.CreateStripeBillingAccountResponse = exports.CreateStripeBillingAccountRequest = exports.BillingAccountSubscriptionResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
const handler_1 = require("../utils/handler");
const BillingAccountListingResponse_1 = require("./BillingAccountListingResponse");
const PlanPrice_1 = require("./PlanPrice");
class BillingAccountSubscriptionResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], BillingAccountSubscriptionResponse.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BillingAccountSubscriptionResponse.prototype, "businessName", void 0);
exports.BillingAccountSubscriptionResponse = BillingAccountSubscriptionResponse;
class CreateStripeBillingAccountRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateStripeBillingAccountRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], CreateStripeBillingAccountRequest.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], CreateStripeBillingAccountRequest.prototype, "phoneNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], CreateStripeBillingAccountRequest.prototype, "email", void 0);
exports.CreateStripeBillingAccountRequest = CreateStripeBillingAccountRequest;
class CreateStripeBillingAccountResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateStripeBillingAccountResponse.prototype, "billingAccountId", void 0);
exports.CreateStripeBillingAccountResponse = CreateStripeBillingAccountResponse;
class CreateStripeCardSetupIntentRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateStripeCardSetupIntentRequest.prototype, "billingAccountId", void 0);
exports.CreateStripeCardSetupIntentRequest = CreateStripeCardSetupIntentRequest;
class CreateStripeCardSetupIntentResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateStripeCardSetupIntentResponse.prototype, "stripeSetupIntentSecret", void 0);
exports.CreateStripeCardSetupIntentResponse = CreateStripeCardSetupIntentResponse;
class AttachStripePaymentMethodToBillingAccountRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], AttachStripePaymentMethodToBillingAccountRequest.prototype, "billingAccountId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], AttachStripePaymentMethodToBillingAccountRequest.prototype, "stripePaymentMethodId", void 0);
exports.AttachStripePaymentMethodToBillingAccountRequest = AttachStripePaymentMethodToBillingAccountRequest;
class UpdateBillingAccountDetailsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateBillingAccountDetailsRequest.prototype, "billingAccountId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateBillingAccountDetailsRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], UpdateBillingAccountDetailsRequest.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], UpdateBillingAccountDetailsRequest.prototype, "phoneNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], UpdateBillingAccountDetailsRequest.prototype, "email", void 0);
exports.UpdateBillingAccountDetailsRequest = UpdateBillingAccountDetailsRequest;
class CloseBillingAccountRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CloseBillingAccountRequest.prototype, "billingAccountId", void 0);
exports.CloseBillingAccountRequest = CloseBillingAccountRequest;
class UnsubscribeBusinessRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UnsubscribeBusinessRequest.prototype, "businessId", void 0);
exports.UnsubscribeBusinessRequest = UnsubscribeBusinessRequest;
class SubscribeBusinessWithBillingAccountRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], SubscribeBusinessWithBillingAccountRequest.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], SubscribeBusinessWithBillingAccountRequest.prototype, "billingAccountId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(PlanPrice_1.priceNames),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], SubscribeBusinessWithBillingAccountRequest.prototype, "stripePriceId", void 0);
exports.SubscribeBusinessWithBillingAccountRequest = SubscribeBusinessWithBillingAccountRequest;
class PlanInvoiceLine extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanInvoiceLine.prototype, "stripeInvoiceLineItemId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], PlanInvoiceLine.prototype, "timestamp", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanInvoiceLine.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Money),
    (0, tslib_1.__metadata)("design:type", utils_1.Money)
], PlanInvoiceLine.prototype, "amount", void 0);
exports.PlanInvoiceLine = PlanInvoiceLine;
class PlanInvoice extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanInvoice.prototype, "stripeInvoiceId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanInvoice.prototype, "stripeInvoiceURL", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanInvoice.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Money),
    (0, tslib_1.__metadata)("design:type", utils_1.Money)
], PlanInvoice.prototype, "amount", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], PlanInvoice.prototype, "start", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], PlanInvoice.prototype, "end", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: PlanInvoiceLine }),
    (0, tslib_1.__metadata)("design:type", Array)
], PlanInvoice.prototype, "lines", void 0);
exports.PlanInvoice = PlanInvoice;
class BillingAccountPaymentSummaryResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(PlanPrice_1.PlanPrice),
    (0, tslib_1.__metadata)("design:type", PlanPrice_1.PlanPrice)
], BillingAccountPaymentSummaryResponse.prototype, "price", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(PlanInvoice),
    (0, tslib_1.__metadata)("design:type", PlanInvoice)
], BillingAccountPaymentSummaryResponse.prototype, "upcomingInvoice", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: PlanInvoice }),
    (0, tslib_1.__metadata)("design:type", Array)
], BillingAccountPaymentSummaryResponse.prototype, "invoices", void 0);
exports.BillingAccountPaymentSummaryResponse = BillingAccountPaymentSummaryResponse;
class PurchaseProductRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], PurchaseProductRequest.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['1check', '10check', '50check', '100check']),
    (0, tslib_1.__metadata)("design:type", String)
], PurchaseProductRequest.prototype, "visaProductId", void 0);
exports.PurchaseProductRequest = PurchaseProductRequest;
class BillingClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        /**
         * Make an existing user (?) a billing admin
         */
        // addBillingAccountAdmin = handler(this, async () => {});
        this.getBillingAccounts = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to(`/billing/accounts`)
            .get((d) => d.accounts.map((a) => BillingAccountListingResponse_1.BillingAccountListingResponse.create(a))));
        this.closeBillingAccount = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/billing/account`)
            .bodyJSON(CloseBillingAccountRequest.create(args))
            .delete());
        /**
         * --------------
         * --- Stripe ---
         * --------------
         */
        /**
         * Creates an incomplete stripe billing account and stripe customer to be
         * linked to a payment method and be confirmed as a complete billing account.
         */
        this.createStripeBillingAccountRequest = (0, handler_1.handler)(this, async (data) => await this.http
            .request()
            .to('/billing/stripe/account/setup/start')
            .bodyJSON(CreateStripeBillingAccountRequest.create(data))
            .post((data) => CreateStripeBillingAccountResponse.fromJSON(data)));
        this.updateBillingAccountDetails = (0, handler_1.handler)(this, async (data) => this.http
            .request()
            .to(`/billing/account/update`)
            .bodyJSON(UpdateBillingAccountDetailsRequest.create(data))
            .post());
        /**
         * Stripe setup intents are confirmed and completed on the client side.
         *
         * The response of the confirmation contains a payment method which is
         * attached to the account through attachStripePaymentMethodToBillingAccount.
         */
        this.createStripeCardSetupIntent = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/billing/stripe/account/create-setup-intent')
            .bodyJSON(CreateStripeCardSetupIntentRequest.create(args))
            .post((d) => CreateStripeCardSetupIntentResponse.create(d)));
        /**
         * If no payment method is attached to a billing account this will make the
         * account active again.
         */
        this.attachStripePaymentMethodToBillingAccount = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/billing/stripe/account/attach-payment-method')
            .bodyJSON(AttachStripePaymentMethodToBillingAccountRequest.create(args))
            .post());
        this.subscribeBusinessWithBillingAccount = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/billing/account/subscribe-business')
            .bodyJSON(SubscribeBusinessWithBillingAccountRequest.create(args))
            .post());
        this.unsubscribeBusiness = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/billing/account/unsubscribe-business')
            .bodyJSON(args)
            .post());
        this.getBillingAccountSubscriptions = (0, handler_1.handler)(this, async ({ billingAccountId }) => this.http
            .request()
            .to(`/billing/account/${billingAccountId}/subscriptions`)
            .get((d) => d.map((a) => BillingAccountSubscriptionResponse.create(a))));
        this.getBillingAccountPaymentsSummary = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/billing/account/${args.billingAccountId}/payment-summary`)
            .get((d) => BillingAccountPaymentSummaryResponse.create(d)));
        this.cancelBillingAccountSubscription = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/billing/account/${args.billingAccountId}/subscription`)
            .delete());
        this.purchaseProduct = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/billing/account/purchase-product/visa`)
            .bodyJSON(PurchaseProductRequest.create({
            visaProductId: args.visaProductId,
            businessId: args.businessId,
        }))
            .post());
    }
}
exports.BillingClient = BillingClient;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisaDetails = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const utils_1 = require("../../utils");
const ResponseBase_1 = require("../../ResponseBase");
const VisaCheck_1 = require("./VisaCheck");
class AuOrNzResidentVisaDetails extends ResponseBase_1.ResponseBase {
}
AuOrNzResidentVisaDetails.type = 'AU_OR_NZ_RESIDENT';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['AU_OR_NZ_RESIDENT']),
    (0, tslib_1.__metadata)("design:type", String)
], AuOrNzResidentVisaDetails.prototype, "type", void 0);
class NonAuOrNzResidentVisaDetails extends ResponseBase_1.ResponseBase {
}
NonAuOrNzResidentVisaDetails.type = 'NON_AU_OR_NZ_RESIDENT';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['NON_AU_OR_NZ_RESIDENT']),
    (0, tslib_1.__metadata)("design:type", String)
], NonAuOrNzResidentVisaDetails.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], NonAuOrNzResidentVisaDetails.prototype, "givenNames", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], NonAuOrNzResidentVisaDetails.prototype, "familyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], NonAuOrNzResidentVisaDetails.prototype, "dateOfBirth", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], NonAuOrNzResidentVisaDetails.prototype, "documentId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(VisaCheck_1.visaCheckDocumentType.map((type) => type.code)),
    (0, tslib_1.__metadata)("design:type", String)
], NonAuOrNzResidentVisaDetails.prototype, "documentType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Country),
    (0, tslib_1.__metadata)("design:type", utils_1.Country)
], NonAuOrNzResidentVisaDetails.prototype, "countryOfIssue", void 0);
class VisaDetails extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [AuOrNzResidentVisaDetails, NonAuOrNzResidentVisaDetails],
}) {
}
exports.VisaDetails = VisaDetails;

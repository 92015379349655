import {
  TaskActionType,
  TaskActionArgsMap,
  Task,
  flow,
} from '@zap-onboard/api-client';
import { useCallback, useState } from 'react';
import { onError } from '../../../libs/errorLib';
import { track } from '../../../libs/track';
import { useAPI } from '../../../services/API';

export type TaskActionSubmitter = ReturnType<
  typeof useTaskActionSubmission
>['submitAction'];

export const useTaskActionSubmission = ({
  setData,
}: {
  context: 'user' | 'business';
  setData: (data: {
    task: Task;
    flow: flow.GetFlows.Flow;
    nextTaskId?: string;
  }) => void;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const api = useAPI();

  const submitAction = useCallback(
    <T extends TaskActionType>(type: T, ...args: TaskActionArgsMap[T]) => {
      if (!isLoading) {
        setLoading(true);
        const method = api.flows().submitTaskAction;

        track.event('Task Actioned', { type });
        const response = method(type, ...args).map(
          (data) => {
            setData(data);
            setLoading(false);
            return data;
          },
          (err) => {
            onError(err);
            setLoading(false);
            return err;
          },
        );
        return response;
      }
      return undefined;
    },
    [api, setData, isLoading],
  );

  return {
    isLoadingSubmission: isLoading,
    submitAction,
  };
};

import * as React from 'react';
import { Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { GetPrerequisites, UserProfile } from '@zap-onboard/api-client';
import { useUpdateUserProfile } from '../../hooks/data/userProfile';

import { UpdateUserProfileAttributes } from '../../pages/Profile/UserProfile';
import { Logo } from '../Logo';
import { track } from '../../libs/track';
import { ProfileForm } from '../../pages/Profile/UserProfile/Form/Form';
import { z } from '../../helpers/schema';

type CompleteUserProfileProps = {
  userProfile: UserProfile;
  onlyFields: GetPrerequisites.Response['profileAttributes'];
};

export const CompleteUserProfile: React.FC<CompleteUserProfileProps> = ({
  userProfile,
  onlyFields,
}) => {
  const { updateUserProfile } = useUpdateUserProfile();

  const usedForm = useForm<z.infer<typeof ProfileForm.Schema>>({
    resolver: z.zodResolver(
      ProfileForm.SelectedSchema({ onlyFields, isEditMode: true }),
    ),
    defaultValues: ProfileForm.getDefaultValue(userProfile),
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = usedForm;

  const onSubmit: SubmitHandler<UpdateUserProfileAttributes> = async (data) => {
    track.event('Completed Profile Before Flow');
    await updateUserProfile(data);
  };

  track.useOnMount('Viewed Complete Profile Before Flow');

  return (
    <FormProvider {...usedForm}>
      <Center h="100%" my={2}>
        <Stack
          layerStyle="base"
          spacing={6}
          minH="400px"
          minW={{ base: '90%', md: '70%', lg: '60%', xl: '50%' }}
          m={2}
          p={6}
        >
          <Stack>
            <Heading size="md">Update Your Profile</Heading>
            <Text>
              Before we begin your workflow, we need to make sure you have your
              profile set up.
            </Text>
          </Stack>
          <ProfileForm.Component onlyFields={onlyFields} isEditMode />
          <Center>
            <Button
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              size="sm"
              w="112px"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Center>
          <Logo color="brand.blue" height="28px" />
        </Stack>
      </Center>
    </FormProvider>
  );
};

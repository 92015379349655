import {
  Flex,
  Heading,
  Tag,
  TagLabel,
  TagLeftIcon,
  Stack,
} from '@chakra-ui/react';
import { flow } from '@zap-onboard/api-client';
import { MdBusiness } from 'react-icons/md';
import { FaUserAlt } from 'react-icons/fa';
import { auth } from '../../auth';
import { useHistory } from 'react-router-dom';

interface HeaderProps {
  flow: flow.GetFlows.Flow;
}

export const FlowDetailsHeader = (props: HeaderProps) => {
  const { flow } = props;

  const managers = auth.useBusinessManagers();
  const canManageBusiness = !!managers.find(
    (a) => a.businessId === flow.businessId,
  );

  const history = useHistory();

  return (
    <Stack alignItems="center" gridGap={4}>
      <Heading fontSize="lg" variant="section">
        {flow.name}
      </Heading>
      <Flex gridGap={2} flexWrap="wrap">
        <Tag
          onClick={
            canManageBusiness
              ? () => auth.setCurrentBusinessId(flow.businessId)
              : undefined
          }
          as="button"
          size="md"
        >
          <TagLeftIcon boxSize={3} as={MdBusiness} />
          <TagLabel>{flow.businessName}</TagLabel>
        </Tag>

        {flow.memberId && (
          <Tag
            onClick={
              canManageBusiness
                ? () => {
                    auth.setCurrentBusinessId(flow.businessId);
                    history.push(`/business/members/${flow.memberId}`);
                  }
                : undefined
            }
            as="button"
            size="md"
          >
            <TagLeftIcon boxSize={3} as={FaUserAlt} />
            <TagLabel>{flow.userName}</TagLabel>
          </Tag>
        )}
      </Flex>
    </Stack>
  );
};

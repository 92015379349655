var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Tag as ChakraTag, TagLabel } from '@chakra-ui/react';
export var Tag = function (_a) {
    var _b = _a.bg, bg = _b === void 0 ? 'gray.100' : _b, isUppercase = _a.isUppercase, isBold = _a.isBold, label = _a.label, _c = _a.labelColor, labelColor = _c === void 0 ? 'black' : _c, _d = _a.size, size = _d === void 0 ? 'md' : _d, onClick = _a.onClick, borderRadius = _a.borderRadius, px = _a.px, py = _a.py;
    return (_jsx(Box, { children: _jsx(ChakraTag, __assign({ bg: bg, size: size, borderRadius: borderRadius, px: px, py: py, onClick: onClick, _hover: {
                cursor: onClick ? 'pointer' : 'auto',
            } }, { children: _jsx(TagLabel, __assign({ color: labelColor, textTransform: isUppercase ? 'uppercase' : 'none', fontWeight: isBold ? 'bold' : 'normal' }, { children: label }), void 0) }), void 0) }, void 0));
};

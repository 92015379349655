/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { Center } from '@chakra-ui/react';
import {
  MembershipDetails,
  MFA,
  MyUserDetails,
  UserFeatureFlags,
  UserProfile,
} from '@zap-onboard/api-client';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import create from 'zustand';
import { Spinner } from '../components/Spinner/Spinner';
import { onError, track } from '../libs';
import { BaseAPIClient, useAPI } from '../services/API';
import { IdentityTokenStore } from '../services/stores/IdentityTokenStore';
import { Location, History } from 'history';
import {
  useBusinessManagers,
  useCurrentBusinessManager,
  useMemberships,
  useProfile,
} from './selectors';
import { errs } from '@zap-onboard/errors';
import { useAuthSubscriptions } from './subscriptions';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from '../config';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';

interface State {
  hasInitialized: boolean;
  initializing: boolean;
  currentBusinessId?: string;
  user?: {
    isXeroLead: boolean;
    profile: UserProfile | null;
    featureFlags: UserFeatureFlags;
    memberships: MembershipDetails[];
    hasAcceptedTerms: boolean;
    intercomToken?: string;
    authSource: MyUserDetails['authSource'];
    mfaSource: MyUserDetails['mfaSource'];
    superAdmin: boolean;
    mfaConfigured: boolean;
    mfaSetupRequired: boolean;
    googleConnected: boolean;
    googleAvatarUrl?: string;
    xeroConnected: boolean;
    xeroEmail?: string;
  };
  loginFlow?:
    | {
        type: 'VERIFY_MFA';
        mfaToken: string;
        sources: MFA.Sources.Item[];
      }
    | {
        type: 'NOT_REGISTERED';
      }
    | {
        type: 'EMAIL_SENT';
      };
}

/**
 * Until React18 is released, we need to use unstable_batchedUpdates to prevent
 * the occasional infinite loop
 */
// eslint-disable-next-line no-underscore-dangle
const _useAuthState = create<State>(() => ({
  hasInitialized: false,
  initializing: false,
  currentBusinessId: BaseAPIClient.getCurrentBusinessId().orElse(undefined),
  user: undefined,
}));
export const useAuthState: typeof _useAuthState & {
  setStateBatch: (typeof _useAuthState)['setState'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = _useAuthState as any;
const original = _useAuthState.setState;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
useAuthState.setState = function setStateMonkeyPatch(...args: any[]) {
  unstable_batchedUpdates(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    original(...args);
  });
};

const reload = (businessId: string | null | undefined, retries = 0) => {
  const state = useAuthState.getState();
  if (state.initializing && retries === 0) {
    return;
  }
  if (BaseAPIClient.getToken().isPresent()) {
    useAuthState.setState({ initializing: true, hasInitialized: true });
    BaseAPIClient.identity()
      .getMyUserDetails()
      .tap(
        ({
          isXeroLead,
          memberships,
          profile,
          intercomToken,
          authSource,
          mfaSource,
          mfaConfigured,
          superAdmin,
          mfaSetupRequired,
          googleConnected,
          googleAvatar,
          xeroConnected,
          xeroEmail,
          featureFlags,
        }) => {
          useAuthState.setState((s) => ({
            initializing: false,
            loginFlow: undefined,
            currentBusinessId:
              businessId === null
                ? undefined
                : businessId === undefined
                  ? s.currentBusinessId
                  : businessId,
            user: {
              featureFlags,
              hasAcceptedTerms: profile.hasAcceptedTermsAndConditions,
              isXeroLead,
              authSource,
              memberships,
              profile,
              superAdmin,
              intercomToken,
              mfaConfigured,
              mfaSource,
              mfaSetupRequired,
              googleConnected,
              googleAvatarUrl: googleAvatar,
              xeroConnected,
              xeroEmail,
            },
          }));
        },
        (e) => {
          onError(e);
          if (e instanceof errs.AuthenticationError || retries > 3) {
            BaseAPIClient.setToken(undefined);
            BaseAPIClient.setCurrentBusinessId(undefined);
            useAuthState.setState({
              initializing: false,
              user: undefined,
              loginFlow: undefined,
            });
          } else {
            setTimeout(() => reload(businessId, retries + 1), 500);
          }
        },
      );
  } else {
    useAuthState.setState({
      hasInitialized: true,
      initializing: false,
      user: undefined,
    });
  }
};

export const setProfile = (profile: UserProfile) => {
  useAuthState.setState((s) => ({
    user: s.user
      ? {
          ...s.user,
          profile,
        }
      : undefined,
  }));
};

export const setCurrentBusinessId = (businessId: string | undefined) => {
  BaseAPIClient.setCurrentBusinessId(businessId);
  useAuthState.setState({
    currentBusinessId: businessId,
  });
};

export const useTerms = () => {
  return {
    hasAcceptedTerms: useAuthState((s) => s.user?.hasAcceptedTerms),
    setHasAcceptedTerms: () => {
      useAuthState.setState((s) => ({
        user: s.user
          ? {
              ...s.user,
              hasAcceptedTerms: true,
            }
          : undefined,
      }));
    },
  };
};

export const useUserFeatureFlags = () =>
  useAuthState((s) => s.user?.featureFlags);

export const reInit = (args?: { currentBusinessId: string | undefined }) => {
  if (args) {
    reload(args.currentBusinessId ?? null);
  }
  reload(undefined);
};

export const logout = () => {
  BaseAPIClient.identity().logout();
};

const useToken = (): string | null => {
  const api = useAPI();

  const [token, setToken] = useState<string | null>(
    () => IdentityTokenStore.get() ?? null,
  );

  useEffect(
    () => api.$tokenUpdated.subscribe((token) => setToken(token ?? null)),
    [api],
  );

  return token;
};

const checkLocation = (history: History, location: Location) => {
  const ASSUME_BUSINESS_PARAM = 'asbusiness';
  const ASSUME_EMPLOYEE_PARAM = 'asemployee';
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has(ASSUME_BUSINESS_PARAM)) {
    useAuthState.setState({
      currentBusinessId: queryParams.get(ASSUME_BUSINESS_PARAM) ?? undefined,
    });
    queryParams.delete(ASSUME_BUSINESS_PARAM);
    history.replace({
      search: queryParams.toString(),
    });
  } else if (queryParams.has(ASSUME_EMPLOYEE_PARAM)) {
    useAuthState.setState({
      currentBusinessId: undefined,
    });
    queryParams.delete(ASSUME_EMPLOYEE_PARAM);
    history.replace({
      search: queryParams.toString(),
    });
  }
};

const Identity = () => {
  const businessManagerMemberships = useBusinessManagers();
  const userProfile = useProfile();
  const currentBusinessManagerMembership = useCurrentBusinessManager();
  const memberships = useMemberships();

  track.useIdentity({
    adminMemberships: businessManagerMemberships,
    currentBusinessManagerMembership,
    userProfile: userProfile ?? null,
    memberships,
  });

  return null;
};

const GoogleProvider: React.FC = ({ children }) => {
  if (!config.googleSigninClientId) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <GoogleOAuthProvider clientId={config.googleSigninClientId}>
      {children}
    </GoogleOAuthProvider>
  );
};

export const Wrapper: React.FC = ({ children }) => {
  const initializing = useAuthState((s) => s.initializing);
  const hasInitialized = useAuthState((s) => s.hasInitialized);

  const api = useAPI();

  const token = useToken();

  const history = useHistory();

  useAuthSubscriptions();

  useEffect(() => {
    checkLocation(history, history.location);
    return history.listen((l) => checkLocation(history, l));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reload(undefined);
  }, [api, token]);

  if (initializing || !hasInitialized) {
    return (
      <Center minH="100vh" minW="100vw">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Identity />
      <GoogleProvider>{children}</GoogleProvider>
    </>
  );
};

import React, { useEffect, useRef } from 'react';
import type { FieldProps } from './Field';
import { pdf } from '@zap-onboard/api-client';
import { usePDFState } from '../usePDFState';
import { Tooltip, Text, chakra, Center, Checkbox } from '@chakra-ui/react';
import { FieldConfig } from '../Designer/FieldConfig';

type Props = FieldProps & { field: pdf.Schema.Field.CheckboxData };

const fieldConfig = FieldConfig.checkbox;
const Icon = chakra(fieldConfig.icon);

export const CheckboxField = ({ field, isForm, color, isReadonly }: Props) => {
  const isChecked = usePDFState((s) =>
    s.computed.getFieldValue(field.fieldId, false),
  );
  const setValue = usePDFState((s) => s.setFieldValue);
  const validationMessage = usePDFState(
    (s) => s.fieldValidations[field.fieldId],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(
    () =>
      usePDFState.subscribe((a) => {
        if (a.focusedFieldId == field.fieldId) {
          inputRef.current?.focus?.({ preventScroll: true });
        }
      }),
    [field.fieldId],
  );

  if (!isForm) {
    return (
      <Center tabIndex={0} w="100%" h="100%" overflow="visible">
        <Icon color={color} size={24} />
      </Center>
    );
  }

  return (
    <Tooltip
      isDisabled={isReadonly}
      label={
        <>
          <Text>{field.name}</Text>
          <Text color="red">{validationMessage}</Text>
        </>
      }
    >
      <Center w="100%" h="100%" className="pdf-ignore-pan">
        <Checkbox
          isDisabled={isReadonly}
          isReadOnly={isReadonly}
          ref={inputRef}
          isChecked={isChecked}
          onChange={() => setValue(field.fieldId, !isChecked)}
        />
      </Center>
    </Tooltip>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import {
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  TaskActionType,
  TFNDTask as TFNDTaskModel,
  IndividualTFN,
  TFNDPayeeSubmissionExemption,
  AbsoluteDate,
  UserProfile,
  Country,
} from '@zap-onboard/api-client';
import { Optional } from 'designed';
import { TaskActionSubmitter } from '../../../../../hooks/data/flow/useTaskActionSubmission';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';
import { inputs } from '@zap-onboard/web-components';
import { z } from '../../../../../helpers/schema';
import { ProfileInformation } from './ProfileInformation';
import { InfoBox } from '../shared/InfoBox';
import { MergeUnion } from '../../../../../types/helpers';
import { auth } from '../../../../../auth';

interface TFNDTaskProps {
  task?: TFNDTaskModel;
  context: 'user' | 'business';
  submitAction?: TaskActionSubmitter;
  canEdit: boolean;
}

enum RadioOptions {
  true = 'true',
  false = 'false',
}

const { useEffect } = React;

const getDefaultValues = (
  task?: TFNDTaskModel,
  userProfile?: UserProfile,
  isBusiness?: boolean,
) => {
  const getTFNInfoOrProfile = (
    tfnInfo: string | undefined,
    profileInfo: string | undefined,
  ): string => {
    if (tfnInfo) {
      return tfnInfo;
    }
    if (isBusiness) {
      return '';
    }
    return profileInfo ?? '';
  };
  return {
    hasTaxFileNumber: task?.payeeSubmission
      ? `${task?.payeeSubmission?.tfnResponse?.type === 'TFN'}`
      : '',
    tfnResponse: task?.payeeSubmission?.tfnResponse?.asJSON() ?? {
      type: 'TFN',
      tfn: '',
    },
    title: task?.payeeSubmission?.title ?? ('' as any),
    familyName: getTFNInfoOrProfile(
      task?.payeeSubmission?.familyName,
      userProfile?.familyName,
    ),
    firstGivenName: getTFNInfoOrProfile(
      task?.payeeSubmission?.firstGivenName,
      userProfile?.givenName,
    ),
    otherGivenNames: getTFNInfoOrProfile(
      task?.payeeSubmission?.otherGivenNames,
      userProfile?.otherGivenName,
    ),
    address:
      task?.payeeSubmission?.address ??
      (isBusiness ? '' : userProfile?.address?.asString() ?? ''),
    primaryEmailAddress: getTFNInfoOrProfile(
      task?.payeeSubmission?.primaryEmailAddress.asJSON(),
      userProfile?.email?.asJSON(),
    ),
    dateOfBirth:
      task?.payeeSubmission?.dateOfBirth ??
      (isBusiness ? undefined : userProfile?.birthday),
    previousFamilyName: task?.payeeSubmission?.previousFamilyName ?? '',
    paymentBasis: task?.payeeSubmission?.paymentBasis ?? ('' as any),
    individualType: task?.payeeSubmission?.individualType ?? ('' as any),
    countryOfResidence:
      task?.payeeSubmission?.countryOfResidence?.alpha2Code ?? '',
    claimTaxFreeThreshold:
      task?.payeeSubmission?.claimTaxFreeThreshold === undefined
        ? ''
        : task?.payeeSubmission.claimTaxFreeThreshold
          ? RadioOptions.true
          : RadioOptions.false,
    hasSupportDebt:
      task?.payeeSubmission?.hasSupportDebt === undefined
        ? ''
        : task?.payeeSubmission.hasSupportDebt
          ? RadioOptions.true
          : RadioOptions.false,
  };
};

const tfnResponseSchema = z
  .object({
    type: z.enum(['TFN']),
    tfn: z.valueObject(z.string(), IndividualTFN),
  })
  .or(
    z.object({
      type: z.enum(['EXEMPTION']),
      exemption: z
        .string()
        .min(1, { message: 'Choice required' })
        .transform((x) => x as TFNDPayeeSubmissionExemption['exemption']),
    }),
  );

const commonFields = z.object({
  tfnResponse: tfnResponseSchema,
  hasTaxFileNumber: z.string().min(1, { message: 'Choice required' }),
  title: z.string().min(1, { message: 'Title required' }),
  previousFamilyName: z.string().optional(),
  paymentBasis: z.string().min(1, { message: 'Payment basis required' }),
  claimTaxFreeThreshold: z.string().min(1, { message: 'Choice required' }),
  hasSupportDebt: z.string().min(1, { message: 'Choice required' }),
});

const workingHolidayMaker = z
  .object({
    individualType: z.enum(['WORKING_HOLIDAY_MAKER']),
    countryOfResidence: z.string().min(1, { message: 'Choice required' }),
  })
  .merge(commonFields);

const resident = z
  .object({
    individualType: z.enum(['AUSTRALIAN_RESIDENT', 'FOREIGN_RESIDENT']),
  })
  .merge(commonFields);

const schema = workingHolidayMaker.or(resident);

type FormSchema = z.infer<typeof schema>;

export const TFNDTask: React.FC<TFNDTaskProps> = ({
  task,
  context,
  canEdit,
  submitAction,
}) => {
  const userProfile = auth.useProfile();
  const isUser = context === 'user';
  const isBusiness = context === 'business';
  const isTaskCompleted = task?.taskCompleted();
  const defaultValues = React.useMemo(
    () => getDefaultValues(task, userProfile ?? undefined, isBusiness),
    [isBusiness, task, userProfile],
  );
  const useFormMethods = useForm<FormSchema>({
    resolver: z.zodResolver(schema),
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    watch,
    register,
    reset,
  } = useFormMethods;

  const isTaskDisabled = isTaskCompleted || !canEdit || isSubmitting;

  const hasTaxFileNumber = watch('hasTaxFileNumber');
  const individualType = watch('individualType');

  const {
    familyName,
    otherGivenNames,
    firstGivenName,
    address,
    primaryEmailAddress,
    dateOfBirth,
  } = defaultValues;

  const onSubmit = handleSubmit(async (submitted) => {
    const {
      tfnResponse,
      previousFamilyName,
      claimTaxFreeThreshold,
      hasSupportDebt,
      individualType,
      paymentBasis,
      title,
      countryOfResidence,
    } = submitted as MergeUnion<typeof submitted>;
    if (!task || !submitAction) {
      return;
    }
    await submitAction(TaskActionType.TFND_PAYEE_SUBMISSION, {
      taskId: task.taskId,
      submission: {
        tfnResponse,
        previousFamilyName: filterEmptyString(previousFamilyName),
        otherGivenNames: filterEmptyString(otherGivenNames),
        address,
        claimTaxFreeThreshold: claimTaxFreeThreshold === RadioOptions.true,
        dateOfBirth: AbsoluteDate.create(dateOfBirth as AbsoluteDate),
        familyName,
        firstGivenName,
        hasSupportDebt: hasSupportDebt === RadioOptions.true,
        countryOfResidence: countryOfResidence || undefined,
        individualType: individualType as
          | 'AUSTRALIAN_RESIDENT'
          | 'FOREIGN_RESIDENT'
          | 'WORKING_HOLIDAY_MAKER',
        paymentBasis: paymentBasis as
          | 'FULL_TIME'
          | 'PART_TIME'
          | 'LABOUR_HIRE'
          | 'SUPERANNUATION'
          | 'CASUAL',
        primaryEmailAddress,
        title: title as 'MR' | 'MRS' | 'MISS' | 'MS',
      },
    });
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, reset, userProfile]);

  const trueButton = (
    <inputs.radio.Button value={RadioOptions.true}>Yes</inputs.radio.Button>
  );

  const falseButton = (
    <inputs.radio.Button value={RadioOptions.false}>No</inputs.radio.Button>
  );

  const taskHeading = (
    <Heading variant="section" textAlign="center">
      {task?.name ? task?.name : 'Tax File Number Declaration'}
    </Heading>
  );

  const taskBody = (
    <FormProvider {...useFormMethods}>
      <form>
        <Flex w="100%" justify="center">
          <Stack spacing={4} p={4} w={{ base: '100%', sm: '80%' }}>
            <inputs.radio.Group
              name="hasTaxFileNumber"
              label="Do you have a tax file number"
              isDisabled={isBusiness || isTaskDisabled}
            >
              <Stack spacing={2} mt={1} textAlign="center">
                {trueButton}
                {falseButton}
              </Stack>
            </inputs.radio.Group>
            <input
              type="hidden"
              name="tfnResponse.type"
              value={
                hasTaxFileNumber === RadioOptions.true ? 'TFN' : 'EXEMPTION'
              }
              ref={register()}
            />
            {hasTaxFileNumber === RadioOptions.true && (
              <inputs.TextInput
                name="tfnResponse.tfn"
                label="Tax File Number"
                isDisabled={isBusiness || isTaskDisabled}
                placeholder="Your TFN"
              />
            )}
            {hasTaxFileNumber === RadioOptions.false && (
              <inputs.radio.Group
                name="tfnResponse.exemption"
                label="Choose an exemption"
                isDisabled={isBusiness || isTaskDisabled}
              >
                <VStack mb={4}>
                  <inputs.radio.Button value="MADE_SEPERATE_QUERY_FOR_TFN">
                    I have made a seperate application/enquiry to the ATO for a
                    new or existing TFN
                  </inputs.radio.Button>
                  <inputs.radio.Button value="UNDER_18_AND_DOES_NOT_EARN_ENOUGH">
                    I am claiming an exemption because I am under 18 years of
                    age and do not earn enough to pay tax
                  </inputs.radio.Button>
                  <inputs.radio.Button value="RECEIVING_PENSION_BENEFIT_OR_ALLOWANCE">
                    I am claiming an exemption because I am in receipt of a
                    pension, benefit or allowance
                  </inputs.radio.Button>
                </VStack>
              </inputs.radio.Group>
            )}
            <inputs.radio.Group
              name="title"
              label="Title"
              isDisabled={isBusiness || isTaskDisabled}
            >
              <Stack
                mb={5}
                justify="center"
                direction={{ base: 'column', sm: 'row' }}
              >
                <inputs.radio.Button value="MR">Mr</inputs.radio.Button>
                <inputs.radio.Button value="MRS">Mrs</inputs.radio.Button>
                <inputs.radio.Button value="MISS">Miss</inputs.radio.Button>
                <inputs.radio.Button value="MS">Ms</inputs.radio.Button>
              </Stack>
            </inputs.radio.Group>
            <ProfileInformation
              showEditProfile={isUser}
              familyName={familyName}
              otherGivenNames={otherGivenNames}
              firstGivenName={firstGivenName}
              address={address}
              primaryEmailAddress={primaryEmailAddress}
              dateOfBirth={dateOfBirth?.asDMYFormat() || '-'}
            />
            <inputs.TextInput
              name="previousFamilyName"
              isDisabled={isBusiness || isTaskDisabled}
              label="Your previous family name (if any)"
            />
            <InfoBox>
              <Text>
                If you have changed your name since last time you dealt with the
                ATO, provide your previous family name
              </Text>
            </InfoBox>
            <inputs.radio.Group
              label="Payment basis"
              isDisabled={isBusiness || isTaskDisabled}
              name="paymentBasis"
            >
              <Stack spacing={2} mt={2} textAlign="center">
                <inputs.radio.Button value="FULL_TIME">
                  Full-time employment
                </inputs.radio.Button>
                <inputs.radio.Button value="PART_TIME">
                  Part-time employment
                </inputs.radio.Button>
                <inputs.radio.Button value="LABOUR_HIRE">
                  Labour hire
                </inputs.radio.Button>
                <inputs.radio.Button value="SUPERANNUATION">
                  Superannuation or annuity income stream
                </inputs.radio.Button>
                <inputs.radio.Button value="CASUAL">
                  Casual employment
                </inputs.radio.Button>
              </Stack>
            </inputs.radio.Group>
            <inputs.radio.Group
              isDisabled={isBusiness || isTaskDisabled}
              name="individualType"
              label="Are you:"
            >
              <Stack spacing={2} mt={2} textAlign="center">
                <inputs.radio.Button value="AUSTRALIAN_RESIDENT">
                  An Australian resident for tax purposes
                </inputs.radio.Button>
                <inputs.radio.Button value="FOREIGN_RESIDENT">
                  A foreign resident for tax purposes
                </inputs.radio.Button>
                <inputs.radio.Button value="WORKING_HOLIDAY_MAKER">
                  A working holiday maker
                </inputs.radio.Button>
              </Stack>
            </inputs.radio.Group>
            {individualType === 'WORKING_HOLIDAY_MAKER' && (
              <inputs.SelectInput
                isDisabled={isBusiness || isTaskDisabled}
                label="Country of residence"
                name="countryOfResidence"
                options={Country.options().concat({
                  name: 'Select..',
                  value: '',
                })}
              />
            )}
            <inputs.radio.Group
              name="claimTaxFreeThreshold"
              label="Tax free threshold"
              isDisabled={isBusiness || isTaskDisabled}
            >
              <Stack spacing={2}>
                <FormLabel>
                  Do you want to claim the tax-free threshold from this payer?
                </FormLabel>
                <Stack spacing={2} mt={1} textAlign="center">
                  {trueButton}
                  {falseButton}
                </Stack>
              </Stack>
            </inputs.radio.Group>
            <InfoBox>
              <Stack spacing={2}>
                <Text>
                  Only claim the tax-free threshold from one payer at a time,
                  unless your total income from all sources for the financial
                  year will be less than the tax-free threshold.
                </Text>
                <Text>
                  Answer no here if you are a foreign resident or working
                  holiday maker, except if you are a foreign resident in receipt
                  of an Australian Government pension or allowance.
                </Text>
              </Stack>
            </InfoBox>
            <inputs.radio.Group
              name="hasSupportDebt"
              label="Do you have a Higher Education Loan Program (HELP), VET Student
                Loan (VSL), Financial Supplement (FS), Student Start-up Loan
                (SSL) or Trade Support Loan (TSL) debt?"
              isDisabled={isBusiness || isTaskDisabled}
            >
              <Stack spacing={2}>
                <Stack spacing={2} mt={1} textAlign="center">
                  {trueButton}
                  {falseButton}
                </Stack>
              </Stack>
            </inputs.radio.Group>
            <InfoBox>
              <Text>
                Your payer will withhold additional amounts to cover any
                compulsory repayment that may be raised on your notice of
                assessment.
              </Text>
            </InfoBox>
          </Stack>
        </Flex>
      </form>
    </FormProvider>
  );

  const taskActions = (
    <Center mt={4}>
      {isUser && (
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Text>
              DECLARATION by payee: I declare that the information I have given
              is true and correct. There are penalties for deliberately making a
              false or misleading statement.
            </Text>
            <Text>
              By clicking the Sign button you are electronically signing your
              tax file number declaration
            </Text>
          </Stack>
          <Center>
            <Button
              onClick={onSubmit}
              isLoading={isSubmitting}
              isDisabled={isTaskDisabled}
            >
              Sign
            </Button>
          </Center>
        </Stack>
      )}
    </Center>
  );

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        {taskBody}
        <TaskCompleted />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack>
          {taskHeading}
          {taskBody}
        </Stack>
        {taskActions}
      </Flex>
    </Stack>
  );
};

function filterEmptyString(s: string | undefined): string | undefined {
  return Optional.of(s)
    .filter((s) => s.length > 0)
    .orElse(undefined);
}

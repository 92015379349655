/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import { event } from './event';

export const useOnMount = (
  eventName: string,
  properties?: Record<string, any>,
) => {
  useEffect(() => {
    event(eventName, properties);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
};

export const useOnTrue = (
  predicate: boolean,
  eventName: string,
  properties?: Record<string, any>,
) => {
  const [ran, setRan] = useState(false);

  useEffect(() => {
    if (predicate && !ran) {
      setRan(true);
      event(eventName, properties);
    }
  }, [eventName, predicate, properties, ran]);
};

export const useOnChange = (
  value: any,
  eventName: string,
  properties?: Record<string, any>,
) => {
  const lastValue = useRef(value);

  useEffect(() => {
    if (lastValue.current !== value) {
      event(eventName, properties);
    }
  }, [eventName, properties, value]);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import {
  Text,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Heading,
  Grid,
  GridItem,
  Stack,
  ButtonGroup,
  useDisclosure,
  Flex,
  Box,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { Spinner } from '../../../../components/Spinner';
import { useCurrentBusiness } from '../../../../hooks/data/business/useBusiness';
import { ErrorDisplay } from '../../../../billing/ErrorDisplay';
import { z } from '../../../../helpers/schema';
import {
  ABN,
  biz,
  FileClaim,
  FileUploadFor,
  FileUploadTargetType,
} from '@zap-onboard/api-client';
import { modal, inputs } from '@zap-onboard/web-components';
import { useHandler } from '../../../../hooks/useHandlerMutation';
import { withFileUpload } from '../../../../components/FileUploader';
import { FilePreviewModal } from '../../../../components/FilePreviewModal';
import { useClaimThumbnail } from '../../../../hooks';

export const BusinessProfile: React.FC = () => {
  const business = useCurrentBusiness();

  const edit = useDisclosure();
  const { fileThumbnail } = useClaimThumbnail({
    claim: business.data?.business.logo?.claim,
    width: 150,
    height: 150,
  });

  if (!business.data) {
    return (
      <>
        <ErrorDisplay {...business} />
        <Spinner />
      </>
    );
  }

  return (
    <Grid
      bg="white"
      layerStyle="base"
      p={4}
      templateColumns="1fr auto"
      templateRows="auto 1fr"
    >
      <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
        <Heading variant="section" fontSize="md" m={0}>
          Business Profile
        </Heading>
      </GridItem>

      <GridItem colStart={2} colSpan={1} rowStart={1} rowSpan={1}>
        <Center h="100%">
          <modal.Simple
            trigger="Edit"
            header="Update Business"
            disclosure={edit}
          >
            <UpdateBusinessForm
              onCancel={edit.onClose}
              business={business.data.business}
            />
          </modal.Simple>
        </Center>
      </GridItem>

      <GridItem colStart={1} colSpan={2} rowStart={2} rowSpan={1}>
        <Stack spacing={4}>
          <Box>
            <Text fontWeight="bold" fontSize="sm">
              Name
            </Text>
            <Text>{business.data.business.name}</Text>
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="sm">
              ABN
            </Text>
            <Text>{business.data.business.abn ?? '--'}</Text>
          </Box>

          <Box>
            <Text fontWeight="bold" fontSize="sm">
              Logo
            </Text>
            {!business.data?.business?.logo && <Text>--</Text>}
            {fileThumbnail && <img src={fileThumbnail?.url} />}
          </Box>
        </Stack>
      </GridItem>
    </Grid>
  );
};

const Schema = z.object({
  name: z.string().min(5).max(150),
  abn: z.optionalValueObject(z.string(), ABN),
  logo: z.instanceof(FileClaim).or(z.undefined()).or(z.null()),
});

const LogoUpload = withFileUpload(
  ({
    file,
    isUploading,
    isReadOnly,
    direction = 'row',
    onClick,
    clearFile,
  }) => {
    if (!file) {
      return isUploading ? (
        <Spinner />
      ) : (
        <Flex my={6}>
          <Button disabled={isUploading || isReadOnly} onClick={onClick}>
            Upload logo
          </Button>
        </Flex>
      );
    }

    return (
      <Stack direction={direction} spacing={2} my={6}>
        <FilePreviewModal file={file} trigger="View Logo" />

        <Button
          variant="outline"
          onClick={clearFile}
          disabled={isUploading || isReadOnly}
        >
          Remove logo
        </Button>
      </Stack>
    );
  },
);

const UpdateBusinessForm = ({
  business,
  onCancel,
}: {
  onCancel: () => unknown;
  business: biz.PublicBusinessDetailsResponse;
}) => {
  const form = useForm<z.infer<typeof Schema>>({
    resolver: z.zodResolver(Schema),
    defaultValues: {
      abn: business.abn,
      name: business.name,
      logo: business.logo?.claim,
    },
  });

  const [update, isUpdating] = useHandler(
    (api) => api.business().updateMyBusiness,
    {
      whenMounted: {
        onSuccess: () => onCancel(),
      },
      invalidates: () => [['BUSINESS']],
    },
  );

  const { control, handleSubmit, errors } = form;

  return (
    <FormProvider {...form}>
      <Stack spacing={4}>
        <inputs.TextInput label="Name" name="name" />

        <inputs.TextInput label="ABN" name="abn" />

        <FormControl isInvalid={!!errors.logo} textAlign="center">
          <LogoUpload
            uploadedFor={{ type: FileUploadFor.MY_BUSINESS }}
            allowedTypes={[FileUploadTargetType.PNG, FileUploadTargetType.JPEG]}
            controlled={{
              name: 'logo',
              control,
              defaultValue: business.logo?.claim,
            }}
          />
          <FormErrorMessage>{(errors.logo as any)?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup isDisabled={isUpdating} isLoading={isUpdating}>
          <Button
            onClick={handleSubmit(async (data) => {
              await update({
                abn: data.abn.trim().length === 0 ? undefined : data.abn,
                logo: data.logo ?? null,
                name: data.name,
              });
            })}
          >
            Submit
          </Button>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </Stack>
    </FormProvider>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroEmployeeDetails = exports.XeroEmployee = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const AustralianAddress_1 = require("../utils/AustralianAddress");
const ContactNumber_1 = require("../utils/contactNumber/ContactNumber");
const Email_1 = require("../utils/email/Email");
const AbsoluteDate_1 = require("../utils/time/AbsoluteDate");
class XeroEmployee extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "xeroEmployeeId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "xeroName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "firstName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "lastName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(Email_1.Email),
    (0, tslib_1.__metadata)("design:type", Email_1.Email)
], XeroEmployee.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(AbsoluteDate_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", AbsoluteDate_1.AbsoluteDate)
], XeroEmployee.prototype, "dob", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(ContactNumber_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", ContactNumber_1.ContactNumber)
], XeroEmployee.prototype, "phone", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(ContactNumber_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", ContactNumber_1.ContactNumber)
], XeroEmployee.prototype, "mobile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "gender", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "jobTitle", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AustralianAddress_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", AustralianAddress_1.AustralianAddress)
], XeroEmployee.prototype, "homeAddress", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroEmployee.prototype, "connectedMemberId", void 0);
exports.XeroEmployee = XeroEmployee;
class XeroEmployeeDetails extends XeroEmployee {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroEmployeeDetails.prototype, "inferred", void 0);
exports.XeroEmployeeDetails = XeroEmployeeDetails;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const BaseTask_1 = require("../Task/BaseTask");
const FormSchema_1 = require("./FormSchema");
const FormTaskActions_1 = require("./FormTaskActions");
class FormTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FormSchema_1.FormSchema),
    (0, tslib_1.__metadata)("design:type", FormSchema_1.FormSchema)
], FormTask.prototype, "schema", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(FormTaskActions_1.SubmitFormTaskAction),
    (0, tslib_1.__metadata)("design:type", FormTaskActions_1.SubmitFormTaskAction)
], FormTask.prototype, "submission", void 0);
exports.FormTask = FormTask;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaybeLoginFlowResponse = exports.LoginFlowResponse = exports.CompleteLoginFlowResponse = exports.NotRegisteredLoginFlowResponse = exports.MagicLinkSentLoginFlowResponse = exports.VerifyMFALoginFlowResponse = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const ResponseBase_1 = require("../../ResponseBase");
const MFA = (0, tslib_1.__importStar)(require("./MFA"));
const AppLink_1 = require("../../utils/AppLink");
class VerifyMFALoginFlowResponse extends ResponseBase_1.ResponseBase {
}
VerifyMFALoginFlowResponse.type = 'VERIFY_MFA';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], VerifyMFALoginFlowResponse.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], VerifyMFALoginFlowResponse.prototype, "verifyMFAToken", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: MFA.Sources.Item }),
    (0, class_validator_1.IsInstance)(MFA.Sources.Item, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], VerifyMFALoginFlowResponse.prototype, "mfaSources", void 0);
exports.VerifyMFALoginFlowResponse = VerifyMFALoginFlowResponse;
class MagicLinkSentLoginFlowResponse extends ResponseBase_1.ResponseBase {
}
MagicLinkSentLoginFlowResponse.type = 'MAGIC_LINK_SENT';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MagicLinkSentLoginFlowResponse.prototype, "type", void 0);
exports.MagicLinkSentLoginFlowResponse = MagicLinkSentLoginFlowResponse;
class NotRegisteredLoginFlowResponse extends ResponseBase_1.ResponseBase {
}
NotRegisteredLoginFlowResponse.type = 'NOT_REGISTERED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], NotRegisteredLoginFlowResponse.prototype, "type", void 0);
exports.NotRegisteredLoginFlowResponse = NotRegisteredLoginFlowResponse;
class CompleteLoginFlowResponse extends ResponseBase_1.ResponseBase {
}
CompleteLoginFlowResponse.type = 'COMPLETE';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CompleteLoginFlowResponse.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CompleteLoginFlowResponse.prototype, "accessToken", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AppLink_1.AppLink),
    (0, tslib_1.__metadata)("design:type", AppLink_1.AppLink)
], CompleteLoginFlowResponse.prototype, "redirectTo", void 0);
exports.CompleteLoginFlowResponse = CompleteLoginFlowResponse;
class LoginFlowResponse extends designed_1.Entity.Union.define({
    entries: [
        VerifyMFALoginFlowResponse,
        MagicLinkSentLoginFlowResponse,
        NotRegisteredLoginFlowResponse,
        CompleteLoginFlowResponse,
    ],
    key: 'type',
}) {
}
exports.LoginFlowResponse = LoginFlowResponse;
class MaybeLoginFlowResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(LoginFlowResponse),
    (0, tslib_1.__metadata)("design:type", LoginFlowResponse)
], MaybeLoginFlowResponse.prototype, "loginFlow", void 0);
exports.MaybeLoginFlowResponse = MaybeLoginFlowResponse;

import { Flex, Text, Link, Icon, VStack, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

type ProfileInformationProps = {
  familyName: string;
  otherGivenNames: string;
  firstGivenName: string;
  address: string;
  primaryEmailAddress: string;
  dateOfBirth: string;
  showEditProfile: boolean;
};

type ProfileItemProps = {
  label: string;
  value: string;
};

const ProfileItem: React.FC<ProfileItemProps> = ({ label, value }) => (
  <Box>
    <Text fontWeight="bold" color="gray.600">
      {label}
    </Text>
    <Text>{value || ' - '}</Text>
  </Box>
);

export const ProfileInformation: React.FC<ProfileInformationProps> = ({
  familyName,
  otherGivenNames,
  firstGivenName,
  address,
  primaryEmailAddress,
  dateOfBirth,
  showEditProfile,
}) => (
  <VStack spacing={4} align="stretch" bg="gray.50" borderRadius="md" p={5}>
    <Flex justify="space-between">
      <Text fontWeight="bold">Profile Information</Text>
      {showEditProfile && (
        <Link
          as={RouterLink}
          color="brand.blue"
          to="/profile"
          _hover={{ textDecoration: 'none' }}
          fontWeight="bold"
          fontSize="sm"
        >
          <Icon as={FiEdit} w={4} h={4} /> Edit profile
        </Link>
      )}
    </Flex>
    <ProfileItem label="Family name" value={familyName} />
    <ProfileItem label="First given name" value={firstGivenName} />
    <ProfileItem label="Other given names" value={otherGivenNames} />
    <ProfileItem label="Australian home address" value={address} />
    <ProfileItem label="Primary email address" value={primaryEmailAddress} />
    <ProfileItem label="Date of birth" value={dateOfBirth} />
  </VStack>
);

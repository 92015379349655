import { Task } from '@zap-onboard/api-client';
import { useCallback, useEffect, useState } from 'react';
import { onError } from '../../../libs/errorLib';
import { useAPI } from '../../../services/API';

export const useTask = (opts: { taskId: string }) => {
  const { taskId } = opts;
  const api = useAPI();
  const [isLoading, setLoading] = useState(true);
  const [task, setTask] = useState<Task | null>(null);
  const [isFirstCall, madeFirstCall] = useState<boolean>(true);

  const getTask = useCallback(async () => {
    if (isLoading && !isFirstCall) {
      return;
    }
    if (isFirstCall) {
      madeFirstCall(true);
    }
    const method = api.flows().getTask;
    setLoading(true);
    await method(taskId).map(setTask, onError);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, taskId]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  return {
    getTask,
    setTask: setTask as (task: Task) => void,
    isLoadingTask: isLoading,
    task,
  };
};

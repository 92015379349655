import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';

import { theme } from '../../theme';

const toast = createStandaloneToast({ theme });

export function createToast({
  id,
  status = 'info',
  title,
  description,
  duration = 3000,
  isClosable = true,
  position = 'top',
}: UseToastOptions): void {
  toast({
    id,
    status,
    title,
    description,
    duration,
    isClosable,
    position,
  });
}

import { useToast } from '@chakra-ui/react';
import { PurchaseProductRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const usePurchaseVisaProduct = () => {
  const toast = useToast();
  const [purchaseProduct, isPurchaseProductLoading] = useHandler(
    (api) => api.billing().purchaseProduct,
    {
      invalidates: () => [['VISA_CONFIG']],
      always: {
        onSuccess: async () => {
          toast({
            status: 'success',
            description: 'Purchase successful',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      },
    },
  );

  return {
    purchaseProduct: (data: Entity.Attributes<PurchaseProductRequest>) =>
      purchaseProduct(data),
    isPurchaseProductLoading,
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const identity_1 = require("../../../identity");
const BaseTask_1 = require("../Task/BaseTask");
class ProfileTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(identity_1.ProfileResponse),
    (0, tslib_1.__metadata)("design:type", identity_1.ProfileResponse)
], ProfileTask.prototype, "submittedProfile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(identity_1.ProfileResponse),
    (0, tslib_1.__metadata)("design:type", identity_1.ProfileResponse)
], ProfileTask.prototype, "currentProfile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsIn)(identity_1.AllProfileFields, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], ProfileTask.prototype, "includeFields", void 0);
exports.ProfileTask = ProfileTask;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultipleChoiceFormSubmissionItem = exports.MultipleChoiceFormSchemaItem = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const FormSchemaItem_1 = require("./FormSchemaItem");
class MultipleChoiceFormSchemaItemOption extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], MultipleChoiceFormSchemaItemOption.prototype, "name", void 0);
const type = 'MULTIPLE_CHOICE';
class MultipleChoiceFormSchemaItem extends FormSchemaItem_1.FormSchemaItem {
    constructor() {
        super(...arguments);
        this.type = type;
    }
    async validateItem({ item, }) {
        const { value, isOther } = item;
        // TODO: Use option id instead of name matching
        const matchingValue = this.multipleChoiceOptions.find(({ name }) => name === value);
        const errOverrides = {
            details: { schema: this, item },
        };
        if ((0, class_validator_1.isEmpty)(value) && this.required) {
            throw errors_1.errs.ValidationError.create('option value is empty but is required', errOverrides);
        }
        if (isOther && matchingValue) {
            throw errors_1.errs.ValidationError.create('isOther is ticked but value already exists in options', errOverrides);
        }
        if (isOther && !this.allowOther) {
            throw errors_1.errs.ValidationError.create('input does not allow other values', errOverrides);
        }
    }
    makeEmptySubmission() {
        return MultipleChoiceFormSubmissionItem.create({
            isOther: false,
            type: 'MULTIPLE_CHOICE',
            formSchemaItemId: this.formSchemaItemId,
            value: '',
        });
    }
}
MultipleChoiceFormSchemaItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], MultipleChoiceFormSchemaItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MultipleChoiceFormSchemaItem.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MultipleChoiceFormSchemaItem.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MultipleChoiceFormSchemaItem.prototype, "required", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MultipleChoiceFormSchemaItem.prototype, "allowOther", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: MultipleChoiceFormSchemaItemOption }),
    (0, class_validator_1.IsInstance)(MultipleChoiceFormSchemaItemOption, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], MultipleChoiceFormSchemaItem.prototype, "multipleChoiceOptions", void 0);
exports.MultipleChoiceFormSchemaItem = MultipleChoiceFormSchemaItem;
class MultipleChoiceFormSubmissionItem extends FormSchemaItem_1.FormSubmissionItemBase {
    constructor() {
        super(...arguments);
        this.type = type;
    }
}
MultipleChoiceFormSubmissionItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], MultipleChoiceFormSubmissionItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MultipleChoiceFormSubmissionItem.prototype, "value", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MultipleChoiceFormSubmissionItem.prototype, "isOther", void 0);
exports.MultipleChoiceFormSubmissionItem = MultipleChoiceFormSubmissionItem;

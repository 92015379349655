import * as React from 'react';
import { Flex } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

import { InfoBox } from '../../../../InfoBox';

export const TaskSkipped: React.FC = () => (
  <Flex justify="center">
    <InfoBox
      icon={MdInfoOutline}
      heading="Task skipped"
      message="This task has been skipped. No further action is required."
      w="90%"
    />
  </Flex>
);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { APIClient } from '@zap-onboard/api-client';
import { createContext, useContext } from 'react';
import config from '../config';
import { CurrentBusinessIdStore } from './stores/CurrentBusinessIdStore';
import { IdentityTokenStore } from './stores/IdentityTokenStore';

export const BaseAPIClient = APIClient.create({
  baseAPIURL: config.backendUrl,
  identityToken: IdentityTokenStore.get() || undefined,
});

BaseAPIClient.setCurrentBusinessId(CurrentBusinessIdStore.get() ?? undefined);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.api = BaseAPIClient;

export const APIContext = createContext<APIClient>(null!);

export const useAPI = () => {
  const ctx = useContext(APIContext);
  if (ctx == null) {
    throw new Error('API has not been initialized before useAPI was called');
  }
  return ctx;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperChoiceTaskActions = exports.SuperChoiceEmployeeSubmissionAction = exports.SuperChoiceTaskActionType = void 0;
const tslib_1 = require("tslib");
require("reflect-metadata");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const SuperChoiceEmployeeSubmission_1 = require("./SuperChoiceEmployeeSubmission");
var SuperChoiceTaskActionType;
(function (SuperChoiceTaskActionType) {
    SuperChoiceTaskActionType["SUPER_CHOICE_EMPLOYEE_SUBMISSION"] = "SUPER_CHOICE_EMPLOYEE_SUBMISSION";
})(SuperChoiceTaskActionType = exports.SuperChoiceTaskActionType || (exports.SuperChoiceTaskActionType = {}));
class SuperChoiceEmployeeSubmissionAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return SuperChoiceEmployeeSubmissionAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployeeSubmissionAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(SuperChoiceEmployeeSubmission_1.SuperChoiceEmployeeSubmission),
    (0, tslib_1.__metadata)("design:type", SuperChoiceEmployeeSubmission_1.SuperChoiceEmployeeSubmission)
], SuperChoiceEmployeeSubmissionAction.prototype, "submission", void 0);
exports.SuperChoiceEmployeeSubmissionAction = SuperChoiceEmployeeSubmissionAction;
exports.SuperChoiceTaskActions = {
    [SuperChoiceTaskActionType.SUPER_CHOICE_EMPLOYEE_SUBMISSION]: SuperChoiceEmployeeSubmissionAction,
};

import { BaseIconProps } from '../../components/Icons/BaseIcon';
import { DashboardIcon } from '../../components/Icons/Dashboard';
import { ZapIcon } from '../../components/Icons/Zap';
import { PositionsIcon } from '../../components/Icons/Positions';
import { SettingsIcon } from '../../components/Icons/Settings';
import { SupportIcon } from '../../components/Icons/Support';
import { UsersIcon } from '../../components/Icons/Users';
import { VideosIcon } from '../../components/Icons/Videos';
import { DeveloperIcon } from '../../components/Icons/Developer';
import { isDev } from '../../helpers';
import { RemindersIcon } from '../../components/Icons/Reminders';
import { membership } from '@zap-onboard/api-client';
import { FaFileSignature } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';
import { LogoutIcon, useFeatureFlag } from '../../components';

type MenuItem = {
  route: string;
  label: string;
  icon: React.FC<BaseIconProps>;
  displayIfUserHasAbility?: membership.Ability;
  flagged?: true;
  subMenu?: {
    route: string;
    label: string;
  }[];
};
type Context = 'user' | 'business';

const devMenuItems: MenuItem[] = [
  { route: '/dev', label: 'Dev', icon: DeveloperIcon },
];

const mobileMenuItems: MenuItem[] = [
  { route: '/profile', label: 'Profile', icon: UsersIcon },
  { route: '/logout', label: 'Log out', icon: LogoutIcon },
];

const businessMenuItems: MenuItem[] = [
  { route: '/business/dashboard', label: 'Dashboard', icon: DashboardIcon },
  {
    route: '/business/flow-designs',
    label: 'Workflows',
    icon: ZapIcon,
    subMenu: [
      {
        route: '/business/flow-designs',
        label: 'Designs',
      },
    ],
  },
  {
    route: '/business/certifications',
    label: 'Certifications',
    icon: PositionsIcon,
  },
  {
    route: '/business/esign',
    label: 'E-Sign',
    flagged: true,
    icon: ({ iconColor, height, width }) => (
      <Icon as={FaFileSignature} h={height} w={width} color={iconColor} />
    ),
    subMenu: [
      { route: '/business/esign/documents', label: 'Documents' },
      { route: '/business/esign/templates', label: 'Templates' },
    ],
  },
  {
    route: '/business/users',
    label: 'Users',
    icon: UsersIcon,
    subMenu: [
      {
        route: '/business/users/overview',
        label: 'Overview',
      },
      {
        route: '/business/users/positions',
        label: 'Positions',
      },
    ],
  },
  { route: '/business/contacts', label: 'Contacts', icon: UsersIcon },
  { route: '/business/reminders', label: 'Reminders', icon: RemindersIcon },
  {
    route: '/business/videos',
    label: 'Videos',
    icon: VideosIcon,
  },
  { route: '/business/support', label: 'Support', icon: SupportIcon },
  {
    route: '/business/settings',
    label: 'Settings',
    icon: SettingsIcon,
    displayIfUserHasAbility: 'manageBusinessProfile',
  },
];

const userMenuItems: MenuItem[] = [
  { route: '/user/dashboard', label: 'Dashboard', icon: DashboardIcon },
  { route: '/user/flows', label: 'Flows', icon: ZapIcon },
  {
    route: '/user/certifications',
    label: 'Certifications',
    icon: PositionsIcon,
  },
];

if (isDev) {
  businessMenuItems.push(...devMenuItems);
  userMenuItems.push(...devMenuItems);
}

export const useMenuItems = ({
  context,
  showMobileItems,
  role,
}: {
  context: Context;
  showMobileItems: boolean;
  role: membership.Role | undefined;
}): MenuItem[] => {
  let filteredBusinessMenuItems = businessMenuItems.filter(
    ({ displayIfUserHasAbility }) =>
      displayIfUserHasAbility
        ? role?.getPermissions().can(displayIfUserHasAbility)
        : true,
  );

  const isTemplatesOn = useFeatureFlag('ESIGN');
  filteredBusinessMenuItems = filteredBusinessMenuItems.filter(({ flagged }) =>
    flagged ? isTemplatesOn : true,
  );

  const menuItems =
    context === 'business' ? filteredBusinessMenuItems : userMenuItems;

  return showMobileItems ? [...menuItems, ...mobileMenuItems] : menuItems;
};

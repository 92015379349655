"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingAccountListingResponse = exports.XeroBillingAccountListingResponse = exports.StripeBillingAccountListingResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
const StripeBillingAccountPaymentMethod_1 = require("./StripeBillingAccountPaymentMethod");
class StripeBillingAccountListingResponse extends ResponseBase_1.ResponseBase {
}
StripeBillingAccountListingResponse.type = 'STRIPE';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['STRIPE']),
    (0, tslib_1.__metadata)("design:type", String)
], StripeBillingAccountListingResponse.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], StripeBillingAccountListingResponse.prototype, "billingAccountId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], StripeBillingAccountListingResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], StripeBillingAccountListingResponse.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], StripeBillingAccountListingResponse.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], StripeBillingAccountListingResponse.prototype, "phoneNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], StripeBillingAccountListingResponse.prototype, "delinquent", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], StripeBillingAccountListingResponse.prototype, "paidUntil", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(StripeBillingAccountPaymentMethod_1.StripeBillingAccountPaymentMethod),
    (0, tslib_1.__metadata)("design:type", StripeBillingAccountPaymentMethod_1.StripeBillingAccountPaymentMethod)
], StripeBillingAccountListingResponse.prototype, "stripePaymentMethod", void 0);
exports.StripeBillingAccountListingResponse = StripeBillingAccountListingResponse;
class XeroBillingAccountListingResponse extends ResponseBase_1.ResponseBase {
}
XeroBillingAccountListingResponse.type = 'XERO';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['XERO']),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBillingAccountListingResponse.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBillingAccountListingResponse.prototype, "billingAccountId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBillingAccountListingResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBillingAccountListingResponse.prototype, "xeroShortCode", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], XeroBillingAccountListingResponse.prototype, "paidUntil", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], XeroBillingAccountListingResponse.prototype, "closesAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.Money),
    (0, tslib_1.__metadata)("design:type", utils_1.Money)
], XeroBillingAccountListingResponse.prototype, "price", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBillingAccountListingResponse.prototype, "planName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], XeroBillingAccountListingResponse.prototype, "maxBusinesses", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBillingAccountListingResponse.prototype, "upcomingPlanName", void 0);
exports.XeroBillingAccountListingResponse = XeroBillingAccountListingResponse;
class BillingAccountListingResponse extends designed_1.Entity.Union.define({
    entries: [
        StripeBillingAccountListingResponse,
        XeroBillingAccountListingResponse,
    ],
    key: 'type',
}) {
    get billingAccountId() {
        return this.value.billingAccountId;
    }
    get name() {
        return this.value.name ?? 'Unknown';
    }
    get paidUntil() {
        return this.value.paidUntil;
    }
}
exports.BillingAccountListingResponse = BillingAccountListingResponse;

import * as React from 'react';
import { useIntercom, IntercomProvider } from 'react-use-intercom';
import { auth } from '../auth';
import config from '../config';
import { isNonprod } from 'helpers';

const IntercomUpdate = () => {
  const { update } = useIntercom();
  const memberships = auth.useMemberships();
  React.useEffect(() => {
    update({
      customAttributes: {
        memberOf: memberships.map((m) => m.businessName).join(', '),
        isBusinessManager: memberships.some((m) => m.role.isBusinessManager()),
        isOwner: memberships.some((m) => m.role.isOwner()),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships]);
  return <> </>;
};

export const useHide = () => {
  const { update } = useIntercom();
  React.useEffect(() => {
    update({ hideDefaultLauncher: true });
    return () => update({ hideDefaultLauncher: false });
  }, [update]);
};

export const Provider: React.FC = ({ children }) => {
  const userProfile = auth.useProfile();
  const memberships = auth.useMemberships();
  const intercomToken = auth.useIntercomToken();
  const currentBusinessManagerMembership = auth.useCurrentBusinessManager();
  const businessManagerMemberships = auth.useBusinessManagers();

  if (!config.intercomAppId) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <IntercomProvider
      autoBoot
      autoBootProps={{
        actionColor: isNonprod ? 'red' : '#2667FF',
        backgroundColor: isNonprod ? 'red' : '#2667FF',
        name: userProfile?.getUserName(),
        email: userProfile?.email?.asJSON(),
        userId: userProfile?.userId,
        phone: userProfile?.primaryContactNumber?.asJSON(),
        userHash: intercomToken,
        customAttributes: {
          memberOf: memberships.map((m) => m.businessName).join(', '),
          isBusinessManager: memberships.some((m) =>
            m.role.isBusinessManager(),
          ),
          isOwner: memberships.some((m) => m.role.isOwner()),
        },
        company: currentBusinessManagerMembership
          ? {
              companyId: currentBusinessManagerMembership.businessId,
              name: currentBusinessManagerMembership.businessName,
            }
          : undefined,
        companies: businessManagerMemberships?.map((membership) => ({
          companyId: membership.businessId,
          name: membership.businessName,
        })),
      }}
      appId={config.intercomAppId}
    >
      <IntercomUpdate />
      {children}
    </IntercomProvider>
  );
};

import { PDFViewport } from '../shared/PDFViewport';
import { usePDFState } from '../usePDFState';
import { useHotkey } from '../../hooks/useHotkey';
import { useMaintainZoomScroll } from '../hooks/useMaintainZoomScroll';
import { useLoadFont } from '../hooks/useLoadFont';
import { Form } from './Form';
import { RemoteFile } from '@zap-onboard/api-client';
import { useRemoteUIPreProcessor } from '../hooks/useRemoteUIPreProcessor';

export const FormViewport = ({ file }: { file: RemoteFile }) => {
  const size = usePDFState((s) => s.outerSize);

  const { backgrounds } = useRemoteUIPreProcessor({
    file,
    outerSize: size,
  });

  useLoadFont();
  useMaintainZoomScroll();

  useHotkey({
    keys: [
      'ctrl.=',
      'command.=',
      'ctrl.+',
      'command.+',
      'command.shift.=',
      'ctrl.shift.=',
      'ctrl.shift.+',
      'command.shift.+',
    ],
    preventDefault: true,
    action: usePDFState((s) => s.zoomIn),
  });

  useHotkey({
    keys: ['ctrl.-', 'command.-'],
    preventDefault: true,
    action: usePDFState((s) => s.zoomOut),
  });

  return (
    <PDFViewport>
      <Form backgrounds={backgrounds} />
    </PDFViewport>
  );
};

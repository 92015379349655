import * as React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { useCompactView } from '../../../hooks/useCompactView';
import { mapEmployeeOverviewResponseToActionItems } from '../../../helpers';

import { Spinner } from '../..';
import { BusinessActionItem, ActionItemList } from '../../ActionItemList';
import { useHandlerQuery } from '../../../hooks/useHandlerQuery';
import { ErrorDisplay } from '../../../billing/ErrorDisplay';

export const DashboardActionTabs: React.FC = () => {
  const { isCompactView } = useCompactView();

  const overviews = useHandlerQuery(
    (api) => api.position().getEmployeeOverview(),
    {
      key: ['EMPLOYEE_OVERVIEW'],
    },
  );

  const requiresAction = React.useMemo(
    () => overviews.data?.filter((e) => e.requiresBusinessInput()),
    [overviews.data],
  );

  const awaitingAction = React.useMemo(
    () => overviews.data?.filter((e) => e.requiresUserInput()),
    [overviews.data],
  );

  const error = <ErrorDisplay {...overviews} />;

  if (!overviews.data || !awaitingAction || !requiresAction) {
    return (
      <>
        {error}
        <Spinner />
      </>
    );
  }

  return (
    <>
      {error}
      <Tabs
        layerStyle="base"
        p={{ base: 0, lg: 2 }}
        w="100%"
        size="md"
        isFitted={isCompactView}
      >
        <TabList>
          <Tab>Required Action ({requiresAction.length})</Tab>
          <Tab>Awaiting Action ({awaitingAction.length})</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ActionItemList
              isLoading={false}
              isEmpty={requiresAction.length === 0}
            >
              {mapEmployeeOverviewResponseToActionItems({
                responses: requiresAction,
                isBusinessInputRequired: true,
              }).map((ai) => (
                <BusinessActionItem key={ai.id} {...ai} />
              ))}
            </ActionItemList>
          </TabPanel>
          <TabPanel>
            <ActionItemList
              isLoading={false}
              isEmpty={awaitingAction.length === 0}
            >
              {mapEmployeeOverviewResponseToActionItems({
                responses: awaitingAction,
                isBusinessInputRequired: false,
              }).map((ai) => (
                <BusinessActionItem key={ai.id} {...ai} />
              ))}
            </ActionItemList>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import DOMPurify from 'dompurify';

const purify = (data: string) =>
  DOMPurify.sanitize(data, {
    KEEP_CONTENT: true,
  });

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.tagName.toLowerCase() === 'a') {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

export const purifyHTML = (data: string) => {
  const purified = purify(`${data}`);
  return purified;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MYOBCompanyFileList = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
class CompanyFile extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CompanyFile.prototype, "companyFileId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CompanyFile.prototype, "name", void 0);
var MYOBCompanyFileList;
(function (MYOBCompanyFileList) {
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: CompanyFile }),
        (0, class_validator_1.IsInstance)(CompanyFile, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "companyFiles", void 0);
    MYOBCompanyFileList.Response = Response;
})(MYOBCompanyFileList = exports.MYOBCompanyFileList || (exports.MYOBCompanyFileList = {}));

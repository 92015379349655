import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { logError } from '../libs/errorLib';
import { track } from '../libs/track';
import { useAPI } from '../services/API';

const PRINTING_TOAST_ID = 'PRINTING_TASK';
export const usePrintTask = ({ taskId }: { taskId: string }) => {
  const api = useAPI();
  const [isTaskPrinting, setIsTaskPrinting] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => {
    if (!isTaskPrinting && toast.isActive(PRINTING_TOAST_ID)) {
      toast.close(PRINTING_TOAST_ID);
    }
  }, [isTaskPrinting, toast]);

  const handlePrintTask = useCallback(async () => {
    if (isTaskPrinting) {
      return;
    }
    setIsTaskPrinting(true);
    toast({
      id: PRINTING_TOAST_ID,
      title: 'Printing...',
      status: 'info',
      position: 'bottom-right',
      isClosable: false,
      duration: null,
    });
    await api
      .flows()
      .printTask({ taskId })
      .map((file) => {
        track.event('Printed Task', { taskId });
        window.open(file.url, '_blank');
      }, logError)
      .tap(
        (i) => i,
        () => {
          toast({
            title: 'Could not convert this task to a PDF',
            description:
              'This could be due to a file being too large or a service being too slow. Our support team has been notified.',
            status: 'info',
            isClosable: true,
            position: 'bottom-right',
          });
        },
      );
    setIsTaskPrinting(false);
  }, [api, isTaskPrinting, taskId, toast]);

  return { isTaskPrinting, handlePrintTask };
};

import {
  useToast,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';
import { NotificationListing } from '@zap-onboard/api-client';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NotificationContext } from './notificationContext';

export const useNotificationToast = ({
  notifications,
  markNotificationAsRead,
  notificationDrawer$,
  visitNotification,
}: NotificationContext) => {
  const toast = useToast({
    id: 'notification_popup',
    status: 'info',
    position: 'top',
    duration: 10_000,
    isClosable: true,
  });

  const history = useHistory();
  const [seenNotificationIdSet] = useState<Set<string>>(() => new Set());
  useEffect(() => {
    if (!notifications) {
      return;
    }
    if (seenNotificationIdSet.size === 0) {
      // Somewhat hacky way to make the notification set start off initialized
      // even given 0 notifications initially
      seenNotificationIdSet.add('INITIALIZED');
      notifications.forEach((n) => seenNotificationIdSet.add(n.notificationId));
      return;
    }
    const notificationsToAlert: NotificationListing[] = [];
    notifications?.forEach((n) => {
      if (!seenNotificationIdSet.has(n.notificationId)) {
        seenNotificationIdSet.add(n.notificationId);
        notificationsToAlert.push(n);
      }
    });

    if (notificationsToAlert.length > 1) {
      toast({
        render: ({ onClose: closeToast }) => (
          <Alert status="info">
            <AlertIcon />
            <Box
              flex="1"
              role="button"
              _hover={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                notificationDrawer$.publish({ action: 'OPEN' });
                closeToast();
              }}
            >
              <AlertTitle>
                {notificationsToAlert.length} new notifications
              </AlertTitle>
              <AlertDescription display="block">Click to View</AlertDescription>
            </Box>
            <CloseButton
              onClick={closeToast}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        ),
      });
    }

    if (notificationsToAlert.length === 1) {
      const [notification] = notificationsToAlert;
      toast({
        render: ({ onClose: closeToast }) => (
          <Alert status="info">
            <AlertIcon />
            <Box
              flex="1"
              role="button"
              _hover={{
                textDecoration: 'underline',
              }}
              onClick={() => {
                if (notification.link) {
                  visitNotification(notification.notificationId);
                } else {
                  notificationDrawer$.publish({ action: 'OPEN' });
                }
                closeToast();
              }}
            >
              <AlertTitle>{notification.subject}</AlertTitle>

              {notification.body && (
                <AlertDescription display="block">
                  {notification.body}
                </AlertDescription>
              )}
            </Box>
            <CloseButton
              onClick={closeToast}
              position="absolute"
              right="8px"
              top="8px"
            />
          </Alert>
        ),
      });
    }
  }, [
    history,
    markNotificationAsRead,
    seenNotificationIdSet,
    toast,
    notifications,
    notificationDrawer$,
    visitNotification,
  ]);
};

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FileClaim, RemoteFile } from '@zap-onboard/api-client';
import { useHandlerQuery } from '../../useHandlerQuery';

export const useFile = (
  claim?: FileClaim,
  afterLoaded?: (file: RemoteFile) => unknown,
) => {
  return useHandlerQuery((api) => api.file().getFile(claim!), {
    key: ['FILE', claim?.token!],
    staleAfter: '40m',
    onSuccess: (d) => (afterLoaded ? afterLoaded(d) : undefined),
    enabled: !!claim,
  });
};

import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useIsSuperAdmin } from '../../auth/selectors';

export const SuperadminRoute: React.FC<React.ComponentProps<typeof Route>> = ({
  children,
  ...rest
}) => {
  const superAdmin = useIsSuperAdmin();

  return (
    <Route {...rest} component={superAdmin ? rest.component : undefined}>
      {superAdmin ? children : <Redirect to="/" />}
    </Route>
  );
};

/* eslint-disable react/no-unstable-nested-components */
import {
  GridItem,
  Grid,
  Flex,
  Heading,
  Button,
  useDisclosure,
  HStack,
  Stack,
  Tag,
  Text,
  Spacer,
  Center,
  useToast,
  Box,
} from '@chakra-ui/react';
import { ErrorDisplay } from '../../billing/ErrorDisplay';
import { EmptyState, LoadingState } from '../../components';
import { useHandler, useHandlerQuery } from '../../hooks';
import { PageLayoutContainer } from '../../pages';
import { Table, modal, Menu, rawTheme } from '@zap-onboard/web-components';
import { SetupMFAContent } from './MFA/MFASetupFlow';
import { GoogleConnectButton } from 'components/GoogleConnectButton';
import { useGoogleConnect } from 'hooks/useGoogleConnect';

import {
  MdDelete,
  MdLockClock,
  MdTextsms,
  MdEmail,
  MdCode,
} from 'react-icons/md';
import { Sources } from '@zap-onboard/api-client/src/identity/models/MFA';
import {
  GoogleConnectPurpose,
  XeroConnectionPurpose,
} from '@zap-onboard/api-client';
import { FcGoogle } from 'react-icons/fc';
import { SiXero } from 'react-icons/si';
import { useGoogleAuthState, useXeroAuthState } from '../selectors';
import { useAuthState } from '../useAuthState';
import { MFAClickWrap } from './MFA/MFAWrapper';

const iconForType = {
  SMS: MdTextsms,
  TOTP: MdLockClock,
  DEV: MdCode,
};

export const AuthPage = () => {
  return (
    <PageLayoutContainer>
      <Grid
        gap={4}
        templateRows={{ base: 'auto 1fr', xl: '1fr' }}
        templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
      >
        <GridItem
          colStart={1}
          colSpan={{ base: 1, xl: 2 }}
          rowStart={1}
          rowSpan={1}
          h="100%"
        >
          <Flex justify="space-between">
            <Flex gridGap={2} align="center" justify="center">
              <Heading variant="page">Authentication</Heading>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          colStart={1}
          colSpan={1}
          rowStart={{ base: 3, xl: 2 }}
          rowSpan={1}
          h="100%"
        >
          <MFATable />
        </GridItem>
        <GridItem
          colStart={{ base: 1, xl: 2 }}
          colSpan={1}
          rowStart={2}
          rowSpan={1}
        >
          <LoginTable />
        </GridItem>
      </Grid>
    </PageLayoutContainer>
  );
};

const LoginTable = () => {
  const toast = useToast();
  const { connectGoogle } = useGoogleConnect({
    purpose: GoogleConnectPurpose.CONNECT_ACCOUNT,
    afterSubmit: () => {
      useAuthState.setState((s) => ({
        user: s.user
          ? {
              ...s.user,
              googleConnected: true,
            }
          : undefined,
      }));
      toast({
        title: 'Connected to Google',
        status: 'success',
        position: 'bottom',
      });
    },
  });

  const { googleConnected } = useGoogleAuthState();

  const { xeroConnected, xeroEmail } = useXeroAuthState();

  const email = useAuthState((s) => s.user?.profile?.email);

  const [connectXero, isXeroConnecting] = useHandler(
    (api) => api.xero().beginConnection,
    {
      always: {
        onSuccess: ({ connectionURL }) => {
          window.location.href = connectionURL;
        },
      },
    },
  );

  return (
    <Table.Body>
      <Flex dir="row" justify="space-between">
        <Heading variant="subSection">Login</Heading>
      </Flex>

      <Table.Row
        renderEnd={() => (
          <HStack h="100%" alignItems="center">
            <Spacer flexGrow={1} />
            <Text fontSize="sm" color="gray.500">
              {email?.asJSON()}
            </Text>
          </HStack>
        )}
        heading={() => (
          <HStack spacing={4}>
            <Box color="gray.600">
              <MdEmail />
            </Box>
            <Stack spacing={2}>
              <Text>Login with Email</Text>
              <Box>
                <Tag fontSize="xs" colorScheme="green">
                  Enabled
                </Tag>
              </Box>
            </Stack>
          </HStack>
        )}
      />

      <Table.Row
        heading={() => (
          <HStack spacing={4}>
            <FcGoogle />
            <Stack spacing={2}>
              <Text>Login with Google</Text>
              <Box>
                <Tag
                  fontSize="xs"
                  colorScheme={googleConnected ? 'green' : 'gray'}
                >
                  {googleConnected ? 'Enabled' : 'Not Connected'}
                </Tag>
              </Box>
            </Stack>
          </HStack>
        )}
        renderEnd={
          <HStack alignItems="center" h="100%">
            <Spacer flexGrow={1} />
            <GoogleConnectButton
              context="use"
              text="signin_with"
              onIdentityTokenReturned={connectGoogle}
            />
          </HStack>
        }
      />

      <Table.Row
        renderEnd={
          <HStack alignItems="center" h="100%">
            <Spacer flexGrow={1} />
            <MFAClickWrap
              renderCTA={(onOpen) => (
                <Stack>
                  <Box>
                    <Button color="gray.500" variant="ghost" onClick={onOpen}>
                      Setup MFA
                    </Button>
                  </Box>
                </Stack>
              )}
            >
              {!xeroConnected && !xeroEmail && (
                <Button
                  color="brand.xero.fg"
                  leftIcon={<SiXero />}
                  bg="brand.xero.bg"
                  isLoading={isXeroConnecting}
                  isDisabled={isXeroConnecting}
                  onClick={() => {
                    connectXero({
                      purpose: XeroConnectionPurpose.CONNECT_ORG,
                      isXeroLead: false,
                    });
                  }}
                >
                  Connect
                </Button>
              )}
              {xeroConnected && xeroEmail && (
                <Text fontSize="sm" color="gray.500">
                  {xeroEmail}
                </Text>
              )}
            </MFAClickWrap>
          </HStack>
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        heading={() => (
          <HStack spacing={4}>
            <SiXero color={rawTheme.colors.brand.xero.bg} />
            <Stack spacing={2}>
              <Text>Login with Xero</Text>
              <Box>
                <Tag
                  fontSize="xs"
                  colorScheme={xeroConnected ? 'green' : 'gray'}
                >
                  {xeroConnected ? 'Enabled' : 'Not Connected'}
                </Tag>
              </Box>
            </Stack>
          </HStack>
        )}
      />
    </Table.Body>
  );
};

const MFATable = () => {
  const sources = useHandlerQuery((api) => api.identity().mfa.getSources(), {
    key: ['MFA_SOURCES'],
  });

  return (
    <Table.Body h="100%">
      <Flex dir="row" justify="space-between">
        <Heading variant="subSection">Multi Factor Authentication</Heading>
        <modal.Simple
          header="Setting up MFA"
          _body={{ padding: 4 }}
          _modal={{ size: 'xl' }}
          _trigger={{ variant: 'outline' }}
          trigger="Setup MFA"
        >
          {({ onClose }) => <SetupMFAContent onClose={onClose} />}
        </modal.Simple>
      </Flex>

      <ErrorDisplay {...sources} />

      {!sources.data && <LoadingState />}

      {sources.data?.items?.length === 0 && (
        <EmptyState message="You have not configured multi factor authentication" />
      )}

      {sources.data?.items.map((i) => (
        <MFARow
          key={i.secondFactorId}
          item={i}
          canRemove={sources.data.items.length > 1}
        />
      ))}
    </Table.Body>
  );
};

const MFARow = (props: { item: Sources.Item; canRemove: boolean }) => {
  const { item: i, canRemove } = props;
  const Icon = iconForType[i.type];
  const removeD = useDisclosure();
  const [remove, isRemoving] = useHandler(
    (api) => api.identity().mfa.removeSource,
    {
      invalidates: () => [['MFA_SOURCES']],
    },
  );

  return (
    <>
      <modal.Dialog
        disclosure={removeD}
        confirm={{
          run: () => remove({ secondFactorId: i.secondFactorId }),
          isRunning: isRemoving,
          label: 'Remove',
          colorScheme: 'redd',
        }}
        header="Remove MFA"
      >
        <Text>Remove {i.name}?</Text>
      </modal.Dialog>

      <Table.Row
        // eslint-disable-next-line react/no-unstable-nested-components
        heading={() => (
          <HStack spacing={4}>
            <Icon />
            <Stack spacing={0}>
              <Text>{i.name}</Text>
              <Text fontSize="xs" color="gray.500">
                {i.type === 'SMS' ? 'SMS' : 'Authenticator'}
              </Text>
            </Stack>
          </HStack>
        )}
        renderCenter={() => (
          <Text fontSize="xs">Added {i.addedAt?.toTimeFromNow()}</Text>
        )}
        renderEnd={
          canRemove
            ? () => (
                <HStack>
                  <Spacer flexGrow={1} />
                  <Center h="100%">
                    <Menu
                      _button={{ size: 'xs' }}
                      menuItems={[
                        {
                          items: [
                            {
                              action: removeD.onOpen,
                              title: 'Remove',
                              Icon: <MdDelete />,
                            },
                          ],
                        },
                      ]}
                    />
                  </Center>
                </HStack>
              )
            : undefined
        }
      />
    </>
  );
};
// <Table.Row heading="hi">hi</Table.Row>

import { UpdateVisaConfigRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useUpdateVisaConfig = () => {
  const [updateVisaConfig, isUpdateVisaConfigLoading] = useHandler(
    (api) => api.visa().updateVisaConfig,
    {
      invalidates: () => [['VISA_CONFIG']],
    },
  );

  return {
    updateVisaConfig: (data: Entity.Attributes<UpdateVisaConfigRequest>) =>
      updateVisaConfig(data),
    isUpdateVisaConfigLoading,
  };
};

import { EmployeeOverviewResponse, FlowStatus } from '@zap-onboard/api-client';
import {
  ActionItemProps,
  ActionItemType,
} from '../../components/ActionItemList/ActionItem/ActionItem';

type mappedWorkflowStatuses = {
  [K in keyof typeof FlowStatus]: string;
};

export const workflowStatus: mappedWorkflowStatuses = {
  [FlowStatus.BUSINESS_CANCELLED]: 'Cancelled',
  [FlowStatus.NOT_STARTED]: 'Not started',
  [FlowStatus.IN_PROGRESS]: 'In progress',
  [FlowStatus.COMPLETE]: 'Completed',
};

export function mapEmployeeOverviewResponseToActionItems({
  responses,
  isBusinessInputRequired,
}: {
  responses: EmployeeOverviewResponse[];
  isBusinessInputRequired?: boolean;
}): ActionItemProps[] {
  return responses.map((response) => {
    const { memberId, userName } = response;

    const { type, certDesignId, flowId } = getActionItemDetails({
      response,
      isBusinessInputRequired: isBusinessInputRequired ?? false,
    });

    return {
      id: memberId,
      certificationDesignId: certDesignId,
      flowId,
      memberId,
      title: userName,
      subtitle: getSubtitle({
        response,
        isBusinessInputRequired: isBusinessInputRequired ?? false,
      }),
      progress: response.percentComplete(),
      type,
      context: 'business',
    };
  });
}

function getActionItemDetails({
  response,
  isBusinessInputRequired,
}: {
  response: EmployeeOverviewResponse;
  isBusinessInputRequired: boolean;
}): { type: ActionItemType; certDesignId?: string; flowId?: string } {
  const flows = getRelevantFlows({
    isBusinessInputRequired: isBusinessInputRequired ?? false,
    response,
  });
  const certs = getRelevantCerts({
    isBusinessInputRequired: isBusinessInputRequired ?? false,
    response,
  });

  if (flows.length === 1 && certs.length === 0) {
    return {
      type: ActionItemType.WORKFLOW,
      flowId: flows[0].flowId,
    };
  }

  if (certs.length === 1 && flows.length === 0) {
    return {
      type: ActionItemType.CERT,
      certDesignId: certs[0].certificationDesignId,
    };
  }

  return {
    type: ActionItemType.MULTI,
  };
}

function getSubtitle({
  isBusinessInputRequired,
  response,
}: {
  isBusinessInputRequired: boolean;
  response: EmployeeOverviewResponse;
}) {
  const flows = getRelevantFlows({
    isBusinessInputRequired: isBusinessInputRequired ?? false,
    response,
  });
  const certs = getRelevantCerts({
    isBusinessInputRequired: isBusinessInputRequired ?? false,
    response,
  });

  if (certs.length === 1 && flows.length === 0) {
    return certs[0].name;
  }

  if (flows.length === 1 && certs.length === 0) {
    return flows[0].name;
  }

  return isBusinessInputRequired
    ? response.businessTagline()
    : response.userTagline();
}

function getRelevantFlows({
  isBusinessInputRequired,
  response,
}: {
  isBusinessInputRequired: boolean;
  response: EmployeeOverviewResponse;
}) {
  return isBusinessInputRequired
    ? response.flowsRequiringBusinessInput()
    : response.flowsRequiringUserInput();
}

function getRelevantCerts({
  isBusinessInputRequired,
  response,
}: {
  isBusinessInputRequired: boolean;
  response: EmployeeOverviewResponse;
}) {
  return isBusinessInputRequired
    ? response.certificationsRequiringBusinessInput()
    : response.certificationsRequiringUserInput();
}

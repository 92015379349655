"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpsertDesign = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const CertificationType_1 = require("../enums/CertificationType");
const ResponseBase_1 = require("../../ResponseBase");
const flow_1 = require("../../flow");
const utils_1 = require("../../utils");
var UpsertDesign;
(function (UpsertDesign) {
    let Data;
    (function (Data) {
        class Base extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "certificationDesignId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsPositive)(),
            (0, class_validator_1.Max)(365),
            (0, tslib_1.__metadata)("design:type", Number)
        ], Base.prototype, "expiryNotificationPeriodInDays", void 0);
        class DriversLicence extends Base {
            getCertName() {
                return 'Drivers Licence';
            }
        }
        DriversLicence.type = CertificationType_1.CertificationType.DRIVERS_LICENCE;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.DRIVERS_LICENCE]),
            (0, tslib_1.__metadata)("design:type", String)
        ], DriversLicence.prototype, "type", void 0);
        Data.DriversLicence = DriversLicence;
        class FirstAid extends Base {
            getCertName() {
                return 'First Aid Certificate';
            }
        }
        FirstAid.type = CertificationType_1.CertificationType.FIRST_AID;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.FIRST_AID]),
            (0, tslib_1.__metadata)("design:type", String)
        ], FirstAid.prototype, "type", void 0);
        Data.FirstAid = FirstAid;
        class RSA extends Base {
            getCertName() {
                return 'Responsible Service of Alchohol';
            }
        }
        RSA.type = CertificationType_1.CertificationType.RSA;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.RSA]),
            (0, tslib_1.__metadata)("design:type", String)
        ], RSA.prototype, "type", void 0);
        Data.RSA = RSA;
        class Freeform extends Base {
            getCertName() {
                return this.name;
            }
        }
        Freeform.type = CertificationType_1.CertificationType.FREEFORM;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], Freeform.prototype, "hasExpiryDate", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.FREEFORM]),
            (0, tslib_1.__metadata)("design:type", String)
        ], Freeform.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, class_validator_1.IsNotEmpty)(),
            (0, class_validator_1.MaxLength)(50),
            (0, tslib_1.__metadata)("design:type", String)
        ], Freeform.prototype, "name", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(flow_1.FormSchema),
            (0, tslib_1.__metadata)("design:type", flow_1.FormSchema)
        ], Freeform.prototype, "schema", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(utils_1.RequiredIntegerRange),
            (0, tslib_1.__metadata)("design:type", utils_1.RequiredIntegerRange)
        ], Freeform.prototype, "fileCount", void 0);
        Data.Freeform = Freeform;
        class Union extends designed_1.Entity.Union.define({
            key: 'type',
            entries: [Freeform, RSA, FirstAid, DriversLicence],
        }) {
        }
        Data.Union = Union;
    })(Data = UpsertDesign.Data || (UpsertDesign.Data = {}));
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "certificationDesignId", void 0);
    UpsertDesign.Response = Response;
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Data.Union),
        (0, tslib_1.__metadata)("design:type", Data.Union)
    ], Request.prototype, "design", void 0);
    UpsertDesign.Request = Request;
})(UpsertDesign = exports.UpsertDesign || (exports.UpsertDesign = {}));

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../auth';
import { DisplayedXeroSetupStore } from '../../services/stores/DisplayedXeroSetupStore';

export const useManagedRedirects = () => {
  const managers = auth.useBusinessManagers();
  const loggedInWithXero = auth.useLoginSource() === 'XERO';
  const userProfile = auth.useProfile();
  const history = useHistory();

  useEffect(() => {
    if (userProfile && loggedInWithXero && managers.length === 0) {
      const userIdList = DisplayedXeroSetupStore.get() ?? [];
      if (!userIdList.includes(userProfile.userId)) {
        DisplayedXeroSetupStore.store([...userIdList, userProfile.userId]);
        history.push('/xero/aftersignup');
      }
    }
  }, [history, loggedInWithXero, userProfile, managers.length]);
};

import * as React from 'react';
import { Icon } from '@chakra-ui/react';

import { AiOutlineCalendar } from 'react-icons/ai';

import { BaseIconProps } from '../BaseIcon';

type RemindersIconProps = BaseIconProps;

export const RemindersIcon: React.FC<RemindersIconProps> = ({
  iconColor,
  width,
  height,
}) => <Icon as={AiOutlineCalendar} color={iconColor} w={width} h={height} />;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileClient = exports.DocumentListingResponse = exports.CreateDocumentRequest = exports.GetThumbnailRequest = exports.GetTemplateDocumentsResponse = exports.GetTemplateDocumentsResponseItem = exports.IMAGE_SCALE_INCREMENTS = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const handler_1 = require("../utils/handler");
const FileClaim_1 = require("./FileClaim");
const FileUploadFor_1 = require("./FileUploadFor");
const FileUploadTarget_1 = require("./FileUploadTarget");
const RemoteFile_1 = require("./RemoteFile");
const pdf = (0, tslib_1.__importStar)(require("./pdf"));
const templates = (0, tslib_1.__importStar)(require("./Templates"));
const FlowTaskListing_1 = require("../flow/models/FlowTaskListing");
const TimeAndDate_1 = require("../utils/time/TimeAndDate");
const TemplateSharing_1 = require("./TemplateSharing");
const exporters_1 = require("./exporters");
const OkResponse_1 = require("../meta/OkResponse");
exports.IMAGE_SCALE_INCREMENTS = [10, 32, 64, 150, 500];
class GetTemplateDocumentsResponseItem extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetTemplateDocumentsResponseItem.prototype, "flowId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetTemplateDocumentsResponseItem.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetTemplateDocumentsResponseItem.prototype, "templateId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetTemplateDocumentsResponseItem.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetTemplateDocumentsResponseItem.prototype, "useOrderedSigning", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetTemplateDocumentsResponseItem.prototype, "anonymous", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(FlowTaskListing_1.FlowTaskStatus),
    (0, tslib_1.__metadata)("design:type", String)
], GetTemplateDocumentsResponseItem.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)({ each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetTemplateDocumentsResponseItem.prototype, "alreadySignedNames", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)({ each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetTemplateDocumentsResponseItem.prototype, "waitingForSignatureNames", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], GetTemplateDocumentsResponseItem.prototype, "signedDocument", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
], GetTemplateDocumentsResponseItem.prototype, "timestamp", void 0);
exports.GetTemplateDocumentsResponseItem = GetTemplateDocumentsResponseItem;
class GetTemplateDocumentsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: GetTemplateDocumentsResponseItem }),
    (0, class_validator_1.IsInstance)(GetTemplateDocumentsResponseItem, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetTemplateDocumentsResponse.prototype, "documents", void 0);
exports.GetTemplateDocumentsResponse = GetTemplateDocumentsResponse;
class GetThumbnailRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], GetThumbnailRequest.prototype, "claim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsIn)(exports.IMAGE_SCALE_INCREMENTS),
    (0, tslib_1.__metadata)("design:type", Object)
], GetThumbnailRequest.prototype, "widthInPixels", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsPositive)(),
    (0, class_validator_1.IsIn)(exports.IMAGE_SCALE_INCREMENTS),
    (0, tslib_1.__metadata)("design:type", Object)
], GetThumbnailRequest.prototype, "heightInPixels", void 0);
exports.GetThumbnailRequest = GetThumbnailRequest;
class CreateDocumentRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], CreateDocumentRequest.prototype, "claim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MinLength)(2),
    (0, class_validator_1.MaxLength)(200),
    (0, tslib_1.__metadata)("design:type", String)
], CreateDocumentRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateDocumentRequest.prototype, "memberId", void 0);
exports.CreateDocumentRequest = CreateDocumentRequest;
class DocumentListingResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], DocumentListingResponse.prototype, "file", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DocumentListingResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DocumentListingResponse.prototype, "fileId", void 0);
exports.DocumentListingResponse = DocumentListingResponse;
class FileClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.createUploadTarget = (0, handler_1.handler)(this, async (opts) => this.http
            .request()
            .bodyJSON({
            uploadedFor: {
                type: FileUploadFor_1.FileUploadFor.MY_BUSINESS,
            },
            ...opts,
        })
            .to('/files/upload-target')
            .post((data) => FileUploadTarget_1.FileUploadTarget.create(data)));
        this.getThumbnail = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/generate/thumbnail`)
            .bodyJSON(GetThumbnailRequest.create(args))
            .post((d) => (d.file ? RemoteFile_1.TemporaryRemoteFile.create(d.file) : null)));
        this.getThumbnails = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/generate/thumbnails`)
            .bodyJSON({
            requests: args.map((r) => GetThumbnailRequest.create(r)),
        })
            .post((d) => d.files.map((f) => RemoteFile_1.TemporaryRemoteFile.create(f))));
        this.confirmUpload = (0, handler_1.handler)(this, async (fileId) => this.http
            .request()
            .bodyJSON({ fileId })
            .to('/files/upload-target/confirmation')
            .post((data) => RemoteFile_1.RemoteFile.create(data)));
        this.getFile = (0, handler_1.handler)(this, async (claim) => this.http
            .request()
            .to(`/files/file`)
            .optionalParams({ claim: claim.token })
            .get((data) => RemoteFile_1.RemoteFile.create(data)));
        this.createDocumentForEmployee = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/documents')
            .bodyJSON(CreateDocumentRequest.create(args))
            .post());
        this.getEmployeeDocument = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/documents`)
            .optionalParams(args)
            .get((res) => res.documents.map((d) => DocumentListingResponse.create(d))));
        this.deleteDocumentForEmployee = (0, handler_1.handler)(this, async (args) => this.http.request().to(`/documents/${args.fileId}`).delete());
        this.deleteTemplate = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/document/template`)
            .bodyJSON(args)
            .delete());
        this.upsertTemplateVersion = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/document/template`)
            .bodyJSON(args)
            .post((d) => templates.TemplateResponse.create(d)));
        this.getTemplates = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/document/templates`)
            .optionalParams(args)
            .get((r) => r.templates.map((t) => templates.TemplateResponse.create(t))));
        this.getTemplate = (args) => this.getTemplates({
            includeAnonymous: true,
            templateId: args.templateId,
            includeDeleted: true,
        }).map((value) => {
            if (!value[0]) {
                throw errors_1.errs.NotFoundError.create('Could not find that template');
            }
            return value[0];
        });
        this.getTemplateDocuments = (0, handler_1.handler)(this, async () => {
            return this.http
                .request()
                .to(`/files/document/templates/documents`)
                .get((r) => GetTemplateDocumentsResponse.create(r));
        });
        this.upgradeFileToPDF = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/upgrade-to-pdf`)
            .bodyJSON(args)
            .post((r) => pdf.Upgrade.UpgradeResponse.create(r)));
        this.getAccessRequestPDF = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/files/access-request-pdf')
            .get((r) => RemoteFile_1.TemporaryRemoteFile.create(r)));
        this.importTemplate = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/document/template/import`)
            .bodyJSON(TemplateSharing_1.TemplateSharing.ImportRequest.create(args).toJSON())
            .post((d) => TemplateSharing_1.TemplateSharing.ImportResponse.create(d)));
        this.exportTemplate = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/files/document/template/export`)
            .bodyJSON(TemplateSharing_1.TemplateSharing.ExportRequest.create(args).toJSON())
            .post((d) => TemplateSharing_1.TemplateSharing.ExportResponse.create(d)));
        this.queueExport = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/files/exports')
            .bodyJSON(exporters_1.exporters.QueueExportRequest.create(args))
            .post((d) => OkResponse_1.OkResponse.create(d)));
        this.getExports = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/files/exports')
            .get((d) => exporters_1.exporters.ListResponse.create(d)));
    }
}
exports.FileClient = FileClient;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateTaskActions = exports.TemplateSignAction = exports.TemplateTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../../../file");
const ResponseBase_1 = require("../../../ResponseBase");
var TemplateTaskActionType;
(function (TemplateTaskActionType) {
    TemplateTaskActionType["TEMPLATE_SIGN"] = "TEMPLATE_SIGN";
})(TemplateTaskActionType = exports.TemplateTaskActionType || (exports.TemplateTaskActionType = {}));
class TemplateSignAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return TemplateSignAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateSignAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateSignAction.prototype, "templateGroupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.pdf.Schema.Submission),
    (0, tslib_1.__metadata)("design:type", file_1.pdf.Schema.Submission)
], TemplateSignAction.prototype, "submission", void 0);
exports.TemplateSignAction = TemplateSignAction;
exports.TemplateTaskActions = {
    [TemplateTaskActionType.TEMPLATE_SIGN]: TemplateSignAction,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Percentage = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
class Percentage extends ResponseBase_1.ResponseBase {
    static fake() {
        return Percentage.create({ total: 10, value: 5 });
    }
    static of(set, subset) {
        return Percentage.create({ total: set.length, value: subset.length });
    }
    exact() {
        return (this._exact || (this._exact = this.total === 0 ? 100 : (this.value / this.total) * 100));
    }
    add(that) {
        return Percentage.create({
            total: this.total + that.total,
            value: this.value + that.value,
        });
    }
    getRemainder() {
        return this.total - this.value;
    }
    isComplete() {
        return this.total === this.value;
    }
    toPlaces(places) {
        return this.exact().toFixed(places);
    }
    toString() {
        return `${this.toPlaces(0)}%`;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Percentage.prototype, "total", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Percentage.prototype, "value", void 0);
exports.Percentage = Percentage;

import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';

import { BaseIconProps } from '../BaseIcon';

type UsersIconProps = BaseIconProps;

export const UsersIcon: React.FC<UsersIconProps> = ({ iconColor }) => (
  <Icon as={FaUser} boxSize="12px" color={iconColor} />
);

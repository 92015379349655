import React, { useEffect, useRef } from 'react';
import type { FieldProps } from './Field';
import { pdf } from '@zap-onboard/api-client';
import { usePDFState } from '../usePDFState';
import { Box, chakra, Text, Center } from '@chakra-ui/react';
import { FieldConfig } from '../Designer/FieldConfig';
import { getTextLikeStyle } from './TextField';

type Props = FieldProps & { field: pdf.Schema.Field.DropdownData };

const fieldConfig = FieldConfig.dropdown;
const Icon = chakra(fieldConfig.icon);

export const DropdownField = ({ field, isForm, color, isReadonly }: Props) => {
  const optionId = usePDFState((s) =>
    s.computed.getFieldValue(field.fieldId, undefined),
  );
  const setValue = usePDFState((s) => s.setFieldValue);

  const inputRef = useRef<HTMLSelectElement>(null);
  useEffect(
    () =>
      usePDFState.subscribe((a) => {
        if (a.focusedFieldId == field.fieldId) {
          inputRef.current?.focus?.({ preventScroll: true });
        }
      }),
    [field.fieldId],
  );

  const styles = getTextLikeStyle(field);
  const {
    fontSize,
    fontFamily,
    letterSpacing,
    whiteSpace,
    textAlign,
    lineHeight,
    width,
    height,
  } = styles;

  const option = field.options.find((o) => o.optionId === optionId);

  if (!isForm) {
    const defaultOption = field.options.find(
      (o) => o.optionId === field.defaultOptionId,
    );

    return (
      <Box
        position="relative"
        tabIndex={0}
        w="100%"
        h="100%"
        overflow="visible"
      >
        <Text
          isTruncated
          style={{
            fontSize,
            fontFamily,
            letterSpacing,
            whiteSpace,
            textAlign,
            lineHeight,
          }}
        >
          {defaultOption?.label || field.name}
        </Text>
        <Center position="absolute" top={0} right={0}>
          <Icon flexShrink={0} color={color} size={16} />
        </Center>
      </Box>
    );
  }

  if (isReadonly) {
    return (
      <div style={styles}>
        {/*  Set the letterSpacing of the last character to 0. */}
        {(option?.label ?? '').split('').map((l, i) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{
              letterSpacing:
                String(option?.label ?? '').length === i + 1 ? 0 : 'inherit',
            }}
          >
            {l}
          </span>
        ))}
      </div>
    );
  }

  return (
    <Center w="100%" h="100%">
      <select
        ref={inputRef}
        value={optionId}
        onChange={(e) => setValue(field.fieldId, e.target.value)}
        className="pdf-ignore-pan"
        style={{
          overflow: 'visible',
          width,
          height,
          fontSize,
          fontFamily,
          letterSpacing,
          whiteSpace,
          textAlign,
          lineHeight,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option value=""> </option>
        {field.options.map(({ label, optionId }) => {
          return (
            <option key={optionId} value={optionId}>
              {label}
            </option>
          );
        })}
      </select>
    </Center>
  );
};

import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { errs } from '@zap-onboard/errors';
import { useAPI } from '../../services/API';
import { logError } from '../../libs/errorLib';
import { track } from '../../libs/track';
import { Center } from '@chakra-ui/react';
import { Spinner } from '../../components/Spinner';
import { ExpiredLink } from './ExpiredLink';

const { useEffect, useState } = React;

export const MagicLink = () => {
  const api = useAPI();
  const history = useHistory();
  const location = useLocation();

  const [failed, hasFailed] = useState<boolean>(false);
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    (async () => {
      if (!token) {
        hasFailed(true);
        logError(
          errs.UnexpectedError.create(
            'User has navigated to magic-link-login without a token',
          ),
        );
        history.push('/');
        return;
      }
      const response = await api.identity().loginWithMagicLinkToken(token);
      response.tap(
        () => {
          track.event('MagicLink Login');
        },
        () => {
          hasFailed(true);
          history.push('/');
        },
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {failed || (
        <Center minH="100vh" minW="100vw">
          <Spinner />
        </Center>
      )}
      {failed && <ExpiredLink />}
    </>
  );
};

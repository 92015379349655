import { flow } from '@zap-onboard/api-client';
import { errs } from '@zap-onboard/errors';
import { useCallback, useEffect, useState } from 'react';
import { onError } from '../../../libs';
import { useAPI } from '../../../services/API';

export const useFlow = (opts: { flowId: string }) => {
  const { flowId } = opts;
  const api = useAPI();
  const [isLoading, setLoading] = useState(true);
  const [flow, setFlow] = useState<flow.GetFlows.Flow | null>(null);
  const [isFirstCall, madeFirstCall] = useState<boolean>(true);

  const getFlow = useCallback(async () => {
    if (isLoading && !isFirstCall) {
      return;
    }
    if (isFirstCall) {
      madeFirstCall(true);
    }
    setLoading(true);
    await api
      .flows()
      .getFlow(flowId)
      .tap(setFlow, (err) => {
        if (err.findInstanceofAny(errs.NotFoundError)) {
          setFlow(null);
        } else {
          onError(err);
        }
      });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, flowId]);

  useEffect(() => {
    getFlow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    getFlow,
    setFlow: setFlow as (flow: flow.GetFlows.Flow) => void,
    isLoadingFlow: isLoading,
    flow,
  };
};

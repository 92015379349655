"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SMS = exports.TOTP = exports.Dev = exports.Sources = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const ContactNumber_1 = require("../../utils/contactNumber/ContactNumber");
const TimeAndDate_1 = require("../../utils/time/TimeAndDate");
var Sources;
(function (Sources) {
    class Item extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], Item.prototype, "addedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "secondFactorId", void 0);
    Sources.Item = Item;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Item }),
        (0, class_validator_1.IsInstance)(Item, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "items", void 0);
    Sources.Response = Response;
    class RemoveRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], RemoveRequest.prototype, "secondFactorId", void 0);
    Sources.RemoveRequest = RemoveRequest;
})(Sources = exports.Sources || (exports.Sources = {}));
var Dev;
(function (Dev) {
    class VerifyRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "verifyMFAToken", void 0);
    Dev.VerifyRequest = VerifyRequest;
})(Dev = exports.Dev || (exports.Dev = {}));
var TOTP;
(function (TOTP) {
    class AddRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AddRequest.prototype, "name", void 0);
    TOTP.AddRequest = AddRequest;
    class AddResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AddResponse.prototype, "totpSecret", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AddResponse.prototype, "totpURL", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AddResponse.prototype, "secondFactorId", void 0);
    TOTP.AddResponse = AddResponse;
    class VerifyRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "code", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "secondFactorId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "verifyMFAToken", void 0);
    TOTP.VerifyRequest = VerifyRequest;
})(TOTP = exports.TOTP || (exports.TOTP = {}));
var SMS;
(function (SMS) {
    class AddRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(ContactNumber_1.ContactNumber),
        (0, tslib_1.__metadata)("design:type", ContactNumber_1.ContactNumber)
    ], AddRequest.prototype, "number", void 0);
    SMS.AddRequest = AddRequest;
    class AddResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AddResponse.prototype, "secondFactorId", void 0);
    SMS.AddResponse = AddResponse;
    class CodeRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], CodeRequest.prototype, "secondFactorId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], CodeRequest.prototype, "verifyMFAToken", void 0);
    SMS.CodeRequest = CodeRequest;
    class VerifyRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "code", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "verifyMFAToken", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], VerifyRequest.prototype, "secondFactorId", void 0);
    SMS.VerifyRequest = VerifyRequest;
})(SMS = exports.SMS || (exports.SMS = {}));

import { Avatar, AvatarBadge, Center } from '@chakra-ui/react';
import { MdNotifications } from 'react-icons/md';
import { useCompactView } from '../../../../hooks/useCompactView';
import { useNotificationContext } from './notificationContext';

export const NotificationDrawerTrigger = () => {
  const { isCompactView } = useCompactView();
  const { notificationDrawer$, unread } = useNotificationContext();
  return (
    <Center align="center" ml={2} h="100%">
      <Avatar
        icon={<MdNotifications fontSize="1.5rem" />}
        size="sm"
        bg={isCompactView ? '#fff' : 'brand.blue'}
        color={isCompactView ? 'brand.blue' : '#fff'}
        cursor="pointer"
        _hover={{
          opacity: 0.8,
        }}
        onClick={() => notificationDrawer$.publish({ action: 'OPEN' })}
      >
        {unread && unread.length > 0 && (
          <AvatarBadge borderColor="papayawhip" bg="tomato" boxSize="1.50em">
            {unread.length > 9 ? 9 : unread.length}
          </AvatarBadge>
        )}
      </Avatar>
    </Center>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevokeAccess = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var RevokeAccess;
(function (RevokeAccess) {
    class UserRequest extends ResponseBase_1.ResponseBase {
    }
    UserRequest.type = 'USER';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['USER']),
        (0, tslib_1.__metadata)("design:type", String)
    ], UserRequest.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], UserRequest.prototype, "userId", void 0);
    RevokeAccess.UserRequest = UserRequest;
    class MemberRequest extends ResponseBase_1.ResponseBase {
    }
    MemberRequest.type = 'MEMBER';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['MEMBER']),
        (0, tslib_1.__metadata)("design:type", String)
    ], MemberRequest.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], MemberRequest.prototype, "memberId", void 0);
    RevokeAccess.MemberRequest = MemberRequest;
    class Request extends designed_1.Entity.Union.define({
        key: 'type',
        entries: [MemberRequest, UserRequest],
    }) {
    }
    RevokeAccess.Request = Request;
})(RevokeAccess = exports.RevokeAccess || (exports.RevokeAccess = {}));

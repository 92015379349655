import React from 'react';
import { ReactComponent as BlueIcon } from './zap-blue.svg';
import { ReactComponent as WhiteIcon } from './zap-white.svg';
import { BaseIconProps } from '../BaseIcon';

type ZapIconProps = BaseIconProps;

export const ZapIcon: React.FC<ZapIconProps> = ({
  iconColor,
  width,
  height,
}: ZapIconProps) =>
  iconColor === 'white' ? (
    <WhiteIcon width={width} height={height} />
  ) : (
    <BlueIcon width={width} height={height} />
  );

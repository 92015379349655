import React from 'react';
import { ReactComponent as BlueIcon } from './positions-blue.svg';
import { ReactComponent as WhiteIcon } from './positions-white.svg';
import { BaseIconProps } from '../BaseIcon';

type PositionsIconProps = BaseIconProps;

export const PositionsIcon: React.FC<PositionsIconProps> = ({
  iconColor,
  width,
  height,
}) =>
  iconColor === 'white' ? (
    <WhiteIcon width={width} height={height} />
  ) : (
    <BlueIcon width={width} height={height} color={iconColor} />
  );

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultFont = exports.getFallbackFontName = exports.b64toUint8Array = exports.typedArrayToBuffer = exports.encodeBase64 = void 0;
const constants_1 = require("./constants");
const getByteString = (base64) => {
    if (typeof window !== 'undefined' && window.atob) {
        return window.atob(base64);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-error Node buffer in frontend
    return Buffer.from(base64, 'base64').toString('binary');
};
function encodeBase64(text) {
    // handle if browser
    if (typeof window !== 'undefined' && window.btoa) {
        return window.btoa(text);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-error Node buffer in frontend
    return Buffer.from(text).toString('base64');
}
exports.encodeBase64 = encodeBase64;
function typedArrayToBuffer(array) {
    return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset);
}
exports.typedArrayToBuffer = typedArrayToBuffer;
// https://stackoverflow.com/questions/12092633/pdf-js-rendering-a-pdf-file-using-a-base64-file-source-instead-of-url
const b64toUint8Array = (base64) => {
    const data = base64.split(';base64,')[1]
        ? base64.split(';base64,')[1]
        : base64;
    const byteString = getByteString(data);
    const unit8arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
        unit8arr[i] = byteString.charCodeAt(i);
    }
    return unit8arr;
};
exports.b64toUint8Array = b64toUint8Array;
const getFallbackFontName = (font) => {
    const initial = '';
    const fallbackFontName = Object.entries(font).reduce((acc, cur) => {
        const [fontName, fontValue] = cur;
        return !acc && fontValue.fallback ? fontName : acc;
    }, initial);
    if (fallbackFontName === initial) {
        throw Error(`fallback flag is not found in font. true fallback flag must be only one.`);
    }
    return fallbackFontName;
};
exports.getFallbackFontName = getFallbackFontName;
const getDefaultFont = () => ({
    [constants_1.DEFAULT_FONT_NAME]: {
        data: (0, exports.b64toUint8Array)(constants_1.fonts.Helvetica),
        fallback: true,
    },
    DancingScript: {
        data: (0, exports.b64toUint8Array)(constants_1.fonts.DancingScript),
    },
});
exports.getDefaultFont = getDefaultFont;

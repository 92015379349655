import shallow from 'zustand/shallow';
import { useAuthState } from './useAuthState';

export const useBusinessManagers = () => {
  return (
    useAuthState(
      (s) => s.user?.memberships?.filter((f) => f.role.isBusinessManager()),
      shallow,
    ) ?? []
  );
};

export const useCurrentBusinessManager = () => {
  const member = useAuthState((s) =>
    s.user?.memberships.find((m) => m.businessId === s.currentBusinessId),
  );
  return member?.role.isBusinessManager() ? member : undefined;
};

export const usePermissions = () => {
  const member = useCurrentBusinessManager();
  return member?.role?.getPermissions();
};

export const useEmail = () => {
  return useAuthState((s) => s.user?.profile?.email);
};

export const useProfile = () => {
  return useAuthState((s) => s.user?.profile, shallow) ?? undefined;
};

export const useIsXeroLead = () => {
  return useAuthState((s) => s.user?.isXeroLead);
};

export const useCurrentBusinessId = () => {
  return useCurrentBusinessManager()?.businessId;
};

export const useMemberships = () => {
  return useAuthState((s) => s.user?.memberships, shallow) ?? [];
};

export const useIsSuperAdmin = () => {
  return useAuthState((s) => s.user?.superAdmin);
};

export const useLoginFlow = () => {
  return useAuthState((s) => s.loginFlow);
};

export const useLoginSource = () => {
  return useAuthState((s) => s.user?.authSource);
};

export const useIntercomToken = () => {
  return useAuthState((s) => s.user?.intercomToken);
};

export const useMFASetupRequired = () => {
  return useAuthState(
    (s) => !!s.user && s.user.mfaSetupRequired && !s.user.mfaConfigured,
  );
};

export const useGoogleAuthState = () => {
  return useAuthState(
    (s) => ({
      googleConnected: s.user?.googleConnected,
      googleAvatarUrl: s.user?.googleAvatarUrl,
    }),
    shallow,
  );
};

export const useXeroAuthState = () => {
  return useAuthState(
    (s) => ({
      xeroConnected: s.user?.xeroConnected,
      xeroEmail: s.user?.xeroEmail,
    }),
    shallow,
  );
};

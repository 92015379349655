/* eslint-disable @typescript-eslint/ban-types */
export type QueryKeys =
  | readonly [key: 'MY_BILLING_ACCOUNTS']
  | readonly [key: 'BILLING_ACCOUNT_SUBSCRIPTIONS', billingAccountId: string]
  | readonly [key: 'BILLING_ACCOUNT_PAYMENT_SUMMARY', billingAccountId: string]
  | readonly [key: 'MY_PROFILE']
  | readonly [key: 'MY_NOTIFICATIONS']
  | readonly [key: 'BUSINESS_NOMINATED_SUPER_FUND', businessId: string]
  | readonly [key: 'SUPER_LOOKUP', query: string]
  | readonly [key: 'MEMBER_SEARCH', query: Object]
  | readonly [key: 'POSITION_LIST']
  | readonly [key: 'POSITION_WORKFLOWS', positionId: string]
  | readonly [key: 'POSITION_CERT_REQUIREMENTS', positionId: string]
  | readonly [key: 'BUSINESS_ACCESS_REQUESTS']
  | readonly [key: 'MY_FLOWS', query: Object]
  | readonly [key: 'FLOW_DESIGNS', query: Object]
  | readonly [key: 'BUSINESS_FLOWS', query: Object]
  | readonly [key: 'MEMBER', memberId: string]
  | readonly [key: 'MY_INVITES']
  | readonly [key: 'BUSINESS_CERT_DESIGNS', query: Object]
  | readonly [key: 'BUSINESS_CERT_DESIGN', certificationDesignId: string]
  | readonly [key: 'MY_CERT_SUMMARIES', query: Object]
  | readonly [key: 'USER_DOCUMENTS', memberId: string]
  | readonly [key: 'MEMBER_CERT_REQUIREMENTS', memberId: string]
  | readonly [key: 'BUSINESS_CERT_SUMMARY', query: Object]
  | readonly [key: 'EMPLOYEE_CERT_SUBMISSION', query: Object]
  | readonly [key: 'EMPLOYEE_OVERVIEW']
  | readonly [key: 'BUSINESS', businessId: string]
  | readonly [key: 'ASSET_MANIFEST']
  | readonly [key: 'FILE', claim: string]
  | readonly [key: 'XERO_MY_CONNECTIONS']
  | readonly [key: 'MY_PREREQUISITES']
  | readonly [key: 'XERO_EMPLOYEES']
  | readonly [key: 'CERT_REQUIREMENTS', query: Object]
  | readonly [key: 'FILE_THUMBNAIL', args: Object]
  | readonly [key: 'REMINDER_LIST']
  | readonly [key: 'VISA_CHECKS', memberId: string]
  | readonly [key: 'VISA_DETAILS', memberId: string]
  | readonly [key: 'VISA_CONFIG']
  | readonly [key: 'XERO_SYNC_EMPLOYEE_DETAILS', args: Object]
  | readonly [key: 'VIDEOS']
  | readonly [key: 'VIDEOS', videoId: string]
  | readonly [key: 'CONTACTS']
  | readonly [key: 'CONTACTS', contactId: string]
  | readonly [key: 'MYOB_COMPANY_FILE_LIST']
  | readonly [key: 'SELF_SERVICE_DESIGNS']
  | readonly [key: 'MY_NOTIFICATION_SETTINGS']
  | readonly [key: 'SUPERADMIN_USER_MEMBERS', email: string]
  | readonly [key: 'TEMPLATES']
  | readonly [key: 'TEMPLATE', templateId: string]
  | readonly [key: 'PDF_UPGRADE', fileId: string]
  | readonly [key: 'XERO_CONTACTS']
  | readonly [key: 'TEMPLATE_DOCUMENTS']
  | readonly [key: 'SUPERADMIN_USERS', query: Object]
  | readonly [key: 'SUPERADMIN_MEMBERS', query: Object]
  | readonly [key: 'SUPERADMIN_BUSINESSES', query: Object]
  | readonly [key: 'SUPERADMIN_BUSINESS_DATA', businessId: string]
  | readonly [key: 'SUPERADMIN_USER_DATA', userId: string]
  | readonly [key: 'SUPERADMIN_FEATURE_FLAGS', query: Object]
  | readonly [key: 'BUSINESS_NOTIFICATIONS', memberId: string]
  | readonly [key: 'FLOW_DESIGN_TEMPLATES']
  | readonly [key: 'FLOW_RESOLVE_ASSIGNMENTS', query: Object]
  | readonly [key: 'TASK_QUERY', query: Object]
  | readonly [key: 'MFA_SOURCES'];

export const QueryKeysToIgnoreBusinessContext: QueryKeys[0][] = [
  'MFA_SOURCES',
  'ASSET_MANIFEST',
  'FILE_THUMBNAIL',
  'XERO_MY_CONNECTIONS',
  'FILE',
  'SELF_SERVICE_DESIGNS',
  'MY_INVITES',
  'SUPER_LOOKUP',
  'MY_NOTIFICATIONS',
  'MY_PROFILE',
  'MY_BILLING_ACCOUNTS',
  'BILLING_ACCOUNT_SUBSCRIPTIONS',
  'BILLING_ACCOUNT_PAYMENT_SUMMARY',
];

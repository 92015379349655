/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Center, Flex, Stack, Text } from '@chakra-ui/react';

import { track } from '../../libs/track';

import { Spinner } from '../../components/Spinner/Spinner';
import { logout } from '../useAuthState';

export const LogoutPage: React.FC = () => {
  track.useOnMount('Viewed Logout Page');

  React.useEffect(() => logout(), []);

  return (
    <Center bgGradient="linear(to-b, brand.blue, brand.dark-blue)" minH="100vh">
      <Flex p={8} layerStyle="base" border="none" m={4}>
        <Stack>
          <Text>Logging you out...</Text>
          <Spinner />
        </Stack>
      </Flex>
    </Center>
  );
};

export const max = <T>(list: T[], f: (a: T, b: T) => boolean): T => {
  let highest = list[0];
  for (let i = 1; i < list.length; i += 1) {
    const next = list[i];
    if (f(highest, next)) {
      highest = next;
    }
  }
  return highest;
};

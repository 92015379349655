"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequiredIntegerRange = exports.IntegerRange = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
class IntegerRange extends ResponseBase_1.ResponseBase {
    contains(n) {
        return this.getMin() < n && n < this.getMax();
    }
    inclusiveContains(n) {
        return this.getMin() <= n && n <= this.getMax();
    }
    getMin() {
        return this.min ?? Number.NEGATIVE_INFINITY;
    }
    getMax() {
        return this.max ?? Number.POSITIVE_INFINITY;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], IntegerRange.prototype, "max", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], IntegerRange.prototype, "min", void 0);
exports.IntegerRange = IntegerRange;
class RequiredIntegerRange extends IntegerRange {
    static validationMessage(value) {
        if (Number.isNaN(value?.max) || Number.isNaN(value.min)) {
            return 'Must select a range';
        }
        return undefined;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], RequiredIntegerRange.prototype, "max", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], RequiredIntegerRange.prototype, "min", void 0);
exports.RequiredIntegerRange = RequiredIntegerRange;

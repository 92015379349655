"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserFeatureFlags = exports.BusinessFeatureFlags = exports.FeatureFlags = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
exports.FeatureFlags = [
    'ESIGN',
    'POSITION_ASSIGNMENT',
    'HIDE_SUBSCRIPTION_BANNER',
];
class BusinessFeatureFlags {
    constructor(flags) {
        this.flags = flags;
    }
    static fromJSON(json) {
        if (!json || typeof json !== 'object') {
            throw errors_1.errs.SerializationError.create('Invalid feature flags', {
                details: { json },
            });
        }
        return new BusinessFeatureFlags(json);
    }
    isEnabled(businessId, flag) {
        return this.flags[businessId]?.includes(flag);
    }
    asJSON() {
        return this.flags;
    }
    toJSON() {
        return this.flags;
    }
}
exports.BusinessFeatureFlags = BusinessFeatureFlags;
class UserFeatureFlags extends ResponseBase_1.ResponseBase {
    isEnabled(flag, businessId) {
        if (this.flags.includes(flag)) {
            return true;
        }
        if (businessId) {
            return this.businessFlags.isEnabled(businessId, flag);
        }
        return false;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsString)({ each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], UserFeatureFlags.prototype, "flags", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: BusinessFeatureFlags }),
    (0, class_validator_1.IsInstance)(BusinessFeatureFlags),
    (0, tslib_1.__metadata)("design:type", BusinessFeatureFlags)
], UserFeatureFlags.prototype, "businessFlags", void 0);
exports.UserFeatureFlags = UserFeatureFlags;

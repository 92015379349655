import { Box } from '@chakra-ui/react';
import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from '../../ErrorBoundary';
import { inputs } from '@zap-onboard/web-components';
import { Spinner } from '../../Spinner/Spinner';
import { ErrorDisplay } from '../../../billing/ErrorDisplay';

const LazyEditor = lazy(() => import('./LazyQuill'));

export const HTMLInput = (props: React.ComponentProps<typeof LazyEditor>) => {
  return (
    <Box>
      <ErrorBoundary
        log
        renderError={({ retry, error }) => (
          <Box>
            <ErrorDisplay
              error={error}
              title="Failed to load text editor"
              action={{ title: 'Retry', run: retry }}
            />
            <inputs.Hidden name={props.name} />
          </Box>
        )}
      >
        <Suspense
          fallback={
            <Box>
              <Spinner />
              <inputs.Hidden name={props.name} />
            </Box>
          }
        >
          <LazyEditor {...props} />
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
};

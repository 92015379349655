/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'react-app-polyfill/stable';
import { APIContext, BaseAPIClient } from './services/API';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import 'focus-visible';
import { QueryClient, QueryClientProvider } from 'react-query';
import { VersionPoll } from './libs/VersionPoll';

import './index.css';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { theme } from './theme';
import { initializeSentry } from './libs/initializeSentry';

import { App } from './App';
import { track } from './libs/track';
import { createBrowserHistory } from 'history';
import { errs } from '@zap-onboard/errors';
import { Helmet } from 'react-helmet';

dayjs.extend(relativeTime);

const history = createBrowserHistory();

const TracedApp = initializeSentry(App, history);

track.initialize();

const queries = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, err) => {
        if (count < 2) {
          if (err instanceof errs.UnexpectedError) {
            return true;
          }
          if (err instanceof errs.TimeoutError) {
            return true;
          }
          if (err instanceof errs.SerializationError) {
            return true;
          }
          if (err instanceof errs.NotFoundError) {
            return true;
          }
        }
        return false;
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Canyou</title>
      <meta name="description" content="Canyou" />
    </Helmet>
    <Router history={history}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queries}>
          <APIContext.Provider value={BaseAPIClient}>
            <VersionPoll />
            <TracedApp />
          </APIContext.Provider>
        </QueryClientProvider>
      </ChakraProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

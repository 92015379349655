"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowStatus = void 0;
var FlowStatus;
(function (FlowStatus) {
    FlowStatus["BUSINESS_CANCELLED"] = "BUSINESS_CANCELLED";
    FlowStatus["NOT_STARTED"] = "NOT_STARTED";
    FlowStatus["IN_PROGRESS"] = "IN_PROGRESS";
    FlowStatus["COMPLETE"] = "COMPLETE";
})(FlowStatus = exports.FlowStatus || (exports.FlowStatus = {}));

import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';

import {
  RawTextTask as APIRawTextTask,
  TaskActionType,
} from '@zap-onboard/api-client';
import { BaseTaskPropTypes } from '../BaseTaskPropTypes';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';
import { HTMLDisplay } from '../../../../HTMLDisplay';

interface RawTaskProps extends BaseTaskPropTypes {
  task: APIRawTextTask;
}

const { useState } = React;

export const RawTextTask: React.FC<RawTaskProps> = ({
  context,
  task,
  canEdit,
  submitAction,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isTaskCompleted = task.taskCompleted();
  const isTaskDisabled = isTaskCompleted || isSubmitting || !canEdit;

  const handleTaskAccept = async () => {
    setIsSubmitting(true);
    await submitAction(TaskActionType.RAW_TEXT_ACCEPT, {
      taskId: task.taskId,
    });
    setIsSubmitting(false);
  };

  const taskHeading = (
    <Heading variant="section" textAlign="center">
      {task.name}
    </Heading>
  );

  const taskBody = task.text;

  if (task.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        <TaskSkipped />
        <HTMLDisplay>{taskBody}</HTMLDisplay>
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        <HTMLDisplay>{taskBody}</HTMLDisplay>
        <TaskCompleted />
      </Stack>
    );
  }

  const taskActions = (
    <Center>
      <ButtonGroup>
        {context === 'user' && (
          <Button onClick={handleTaskAccept} disabled={isTaskDisabled}>
            Accept
          </Button>
        )}
      </ButtonGroup>
    </Center>
  );

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack h="100%">
          {taskHeading}
          <HTMLDisplay>{taskBody}</HTMLDisplay>
        </Stack>
        {taskActions}
      </Flex>
    </Stack>
  );
};

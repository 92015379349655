"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateTask = exports.TemplateTaskGroup = exports.TemplateTaskGroupBlocked = exports.TemplateTaskGroupPending = exports.TemplateTaskGroupSigned = exports.TemplateTaskCompletedDetails = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../../../file");
const RemoteFile_1 = require("../../../file/RemoteFile");
const ResponseBase_1 = require("../../../ResponseBase");
const TimeAndZone_1 = require("../../../utils/time/TimeAndZone");
const BaseTask_1 = require("../Task/BaseTask");
class TemplateTaskCompletedDetails extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TimeAndZone_1.TimeAndZone),
    (0, tslib_1.__metadata)("design:type", TimeAndZone_1.TimeAndZone)
], TemplateTaskCompletedDetails.prototype, "completedAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], TemplateTaskCompletedDetails.prototype, "finalFile", void 0);
exports.TemplateTaskCompletedDetails = TemplateTaskCompletedDetails;
class TemplateTaskGroupSigned extends file_1.templates.GroupResponse {
    getLabel() {
        return '✅ Signed';
    }
}
TemplateTaskGroupSigned.type = 'SIGNED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupSigned.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupSigned.prototype, "ipAddress", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupSigned.prototype, "userAgent", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TimeAndZone_1.TimeAndZone),
    (0, tslib_1.__metadata)("design:type", TimeAndZone_1.TimeAndZone)
], TemplateTaskGroupSigned.prototype, "submittedAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupSigned.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupSigned.prototype, "memberName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], TemplateTaskGroupSigned.prototype, "signedFile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: file_1.pdf.Schema.Value.Union }),
    (0, class_validator_1.IsInstance)(file_1.pdf.Schema.Value.Union, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], TemplateTaskGroupSigned.prototype, "values", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupSigned.prototype, "signatureSVG", void 0);
exports.TemplateTaskGroupSigned = TemplateTaskGroupSigned;
class TemplateTaskGroupPending extends file_1.templates.GroupResponse {
    getLabel() {
        return '✉️ Sent';
    }
}
TemplateTaskGroupPending.type = 'PENDING';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupPending.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], TemplateTaskGroupPending.prototype, "canISign", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: file_1.pdf.Schema.Value.Union }),
    (0, class_validator_1.IsInstance)(file_1.pdf.Schema.Value.Union, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], TemplateTaskGroupPending.prototype, "initialValues", void 0);
exports.TemplateTaskGroupPending = TemplateTaskGroupPending;
/**
 * Should only be possible in ordered-signing
 */
class TemplateTaskGroupBlocked extends file_1.templates.GroupResponse {
    getLabel(prev) {
        return prev ? `⏰ Waiting for ${prev.name}` : '⏰ Waiting';
    }
}
TemplateTaskGroupBlocked.type = 'BLOCKED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskGroupBlocked.prototype, "type", void 0);
exports.TemplateTaskGroupBlocked = TemplateTaskGroupBlocked;
class TemplateTaskGroup extends designed_1.Entity.Union.define({
    entries: [
        TemplateTaskGroupSigned,
        TemplateTaskGroupPending,
        TemplateTaskGroupBlocked,
    ],
    key: 'type',
}) {
}
exports.TemplateTaskGroup = TemplateTaskGroup;
class TemplateTask extends BaseTask_1.BaseTask {
    getPendingGroups() {
        return this.groups
            .map((v) => v.value)
            .filter((group) => group.type === 'PENDING');
    }
    getGroupICanSign() {
        const current = this.groups.find((g) => g.value.type === 'PENDING' && g.value.canISign)?.value;
        return current;
    }
    getSubmissions() {
        return this.groups
            .map((v) => v.value)
            .filter((group) => group.type === 'SIGNED')
            .sort((a, b) => a.submittedAt.time.getTime() - b.submittedAt.time.getTime());
    }
    getCurrentFile() {
        if (this.finalFile) {
            return this.finalFile;
        }
        const latestSignature = this.getSubmissions()[0]?.signedFile;
        return latestSignature ?? this.templateFile;
    }
    getHistoryLines() {
        return this.groups
            .map((g, idx) => ({ prev: this.groups[idx - 1], current: g.value }))
            .map(({ current: group, prev }) => {
            return {
                status: group.type,
                label: group.getLabel(prev?.value),
                name: group.type === 'SIGNED' ? group.memberName : group.name,
                groupId: group.groupId,
                signedFile: group.type === 'SIGNED' ? group.signedFile : undefined,
                ipAddress: group.type === 'SIGNED' ? group.ipAddress : 'Unknown',
                userAgent: group.type === 'SIGNED' ? group.userAgent : 'Unknown',
                submittedAt: group.type === 'SIGNED'
                    ? group.submittedAt.toFormat('shortDateTime')
                    : 'Unknown',
                submittedAtAgo: group.type === 'SIGNED'
                    ? group.submittedAt.time.toTimeFromNow()
                    : 'Unknown',
            };
        });
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], TemplateTask.prototype, "templateFile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], TemplateTask.prototype, "finalFile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TemplateTaskGroup }),
    (0, class_validator_1.IsInstance)(TemplateTaskGroup, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], TemplateTask.prototype, "groups", void 0);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Function),
    (0, tslib_1.__metadata)("design:paramtypes", []),
    (0, tslib_1.__metadata)("design:returntype", void 0)
], TemplateTask.prototype, "getPendingGroups", null);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Function),
    (0, tslib_1.__metadata)("design:paramtypes", []),
    (0, tslib_1.__metadata)("design:returntype", void 0)
], TemplateTask.prototype, "getGroupICanSign", null);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Function),
    (0, tslib_1.__metadata)("design:paramtypes", []),
    (0, tslib_1.__metadata)("design:returntype", void 0)
], TemplateTask.prototype, "getSubmissions", null);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Function),
    (0, tslib_1.__metadata)("design:paramtypes", []),
    (0, tslib_1.__metadata)("design:returntype", void 0)
], TemplateTask.prototype, "getHistoryLines", null);
exports.TemplateTask = TemplateTask;

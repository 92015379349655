import { TimeAndDate } from '@zap-onboard/api-client';
import { z } from 'zod';

const auOrNzResidentVisaDetails = z.object({
  type: z.literal('AU_OR_NZ_RESIDENT', {
    errorMap: () => ({ message: 'Select a resident type' }),
  }),
});

const nonAuOrNzResidentVisaDetails = z.object({
  type: z.literal('NON_AU_OR_NZ_RESIDENT', {
    errorMap: () => ({ message: 'Select a resident type' }),
  }),
  givenNames: z.string().min(1, { message: 'Given names required' }),
  familyName: z.string().min(1, { message: 'Family name required' }),
  dateOfBirthDay: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(1, { message: 'Must be between 1 and 31' })
      .max(31, { message: 'Must be between 1 and 31' }),
  ),
  dateOfBirthMonth: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(1, { message: 'Must be between 1 and 12' })
      .max(12, { message: 'Must be between 1 and 12' }),
  ),
  dateOfBirthYear: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(TimeAndDate.present().getFullYear() - 100, {
        message: `Invalid year`,
      })
      .max(TimeAndDate.present().getFullYear(), {
        message: `Can't be in the future`,
      }),
  ),
  documentType: z.enum([
    'PASSPORT',
    'IMMICARD',
    'DFTTA',
    'PL056',
    'TITRE_DE_VOYAGE',
  ]),
  documentId: z.string().min(1, { message: 'Required' }),
  countryOfIssue: z.string().min(1, { message: 'Required' }),
});

export const VisaDetailsFormSchema = z.union([
  auOrNzResidentVisaDetails,
  nonAuOrNzResidentVisaDetails,
]);

export type VisaDetailsFormValues = z.infer<typeof VisaDetailsFormSchema>;

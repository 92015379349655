/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-mutable-exports */
import React, { useMemo } from 'react';
import { PX_IN_MM, SELECTABLE_CLASSNAME } from '../constants';
import { TextField } from './TextField';
import { SignatureField } from './SignatureField';
import { pdf } from '@zap-onboard/api-client';
import { usePDFState } from '../usePDFState';
import { Box } from '@chakra-ui/react';
import { TimeField } from './TimeField';
import { FieldConfig } from '../Designer/FieldConfig';
import { CheckboxField } from './CheckboxField';
import { DropdownField } from './DropdownField';

interface Props {
  field: pdf.Schema.Field.UIData;
  isForm: boolean;
  tabIndex?: number;
  onFocus?: () => unknown;
}

export interface FieldProps extends Props {
  color?: string;
  isReadonly: boolean;
}

export const Field: React.FC<Props> = (props: Props) => {
  const { field } = props;

  const group = useMemo(
    () =>
      usePDFState.getState().groups.find((g) => g.groupId === field.groupId),
    [field.groupId],
  );

  const isReadonly = usePDFState((s) => s.currentGroup !== group?.groupId);
  const fieldProps = {
    ...props,
    onFocus: isReadonly ? undefined : props.onFocus,
    color: group?.color,
    isReadonly,
  };

  const cfg = FieldConfig[field.type];

  let fieldEl: React.ReactNode;
  if (field.type === 'text') {
    fieldEl = <TextField {...fieldProps} field={field} />;
  } else if (field.type === 'signature') {
    fieldEl = <SignatureField {...fieldProps} field={field} />;
  } else if (field.type === 'time') {
    fieldEl = <TimeField {...fieldProps} field={field} />;
  } else if (field.type === 'checkbox') {
    fieldEl = <CheckboxField {...fieldProps} field={field} />;
  } else if (field.type === 'dropdown') {
    fieldEl = <DropdownField {...fieldProps} field={field} />;
  } else {
    // @ts-expect-error field.type should be never
    throw new Error(`Unknown field type: ${field.type}`);
  }

  const validationMessage = usePDFState(
    (s) => s.fieldValidations[field.fieldId],
  );
  const decorEnabled =
    usePDFState((s) => s.enableDecoration) &&
    (props.isForm ? cfg.editable && !isReadonly : true);

  return (
    <div
      ref={(el) =>
        el
          ? usePDFState.getState().refs.fields.set(field.fieldId, el)
          : usePDFState.getState().refs.fields.delete(field.fieldId)
      }
      onClick={() => usePDFState.setState({ focusedFieldId: field.fieldId })}
      key={field.fieldId}
      title={field.name}
      className={SELECTABLE_CLASSNAME}
      data-moveable="true"
      data-field-type={field.type}
      id={field.fieldId}
      style={{
        position: 'absolute',
        cursor: props.isForm && !isReadonly ? 'pointer' : undefined,
        height: field.placement.height * PX_IN_MM,
        width: field.placement.width * PX_IN_MM,
        top: field.placement.y * PX_IN_MM,
        left: field.placement.x * PX_IN_MM,
        overflow: 'visible',
      }}
    >
      {decorEnabled && (
        <Box
          pointerEvents="none"
          position="absolute"
          boxShadow={validationMessage ? '0 0 0 2px red' : 'none'}
          blendMode="multiply"
          backgroundColor={`${group?.color}`}
          border="1px solid black"
          width="100%"
          height="100%"
          opacity={0.2}
        />
      )}

      {validationMessage && (
        <Box
          pointerEvents="none"
          position="absolute"
          boxShadow={validationMessage ? '0 0 0 1px red' : 'none'}
          width="100%"
          height="100%"
        />
      )}
      {fieldEl}
    </div>
  );
};

// When modifying layerStyle transitions please check storybook for
// weirdness with the sort.Child and card.Item components.
export var layerStyles = {
    base: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'brand.light-gray',
        rounded: 'md',
        shadow: 'sm',
        transition: 'box-shadow 0.2s ease-in-out',
    },
    card: {
        bg: 'white',
        transition: 'box-shadow 0.2s ease-in-out',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: '8px',
    },
    hoveredOutline: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'gray.300',
        rounded: 'md',
        shadow: 'md',
    },
    hovered: {
        bg: '#F5F5F5',
        rounded: 'md',
        cursor: 'pointer',
    },
    outline: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'gray.300',
        rounded: 'md',
    },
    selected: {
        bg: 'blue.50',
        border: '1px solid',
        borderColor: 'blue.400',
        rounded: 'md',
        shadow: 'sm',
    },
    dotted: {
        border: '2px solid',
        borderStyle: 'dotted',
        borderColor: 'brand.blue',
        rounded: 'md',
        shadow: 'sm',
    },
    none: {},
};

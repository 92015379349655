"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicBusinessDetailsResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const RemoteFile_1 = require("../../file/RemoteFile");
const ResponseBase_1 = require("../../ResponseBase");
class PublicBusinessDetailsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], PublicBusinessDetailsResponse.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PublicBusinessDetailsResponse.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PublicBusinessDetailsResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], PublicBusinessDetailsResponse.prototype, "logo", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], PublicBusinessDetailsResponse.prototype, "isXeroLead", void 0);
exports.PublicBusinessDetailsResponse = PublicBusinessDetailsResponse;

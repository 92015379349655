import { useHandler } from '../../hooks';
import { track } from '../../libs/track';
import { createToast } from '../../helpers';
import { useQueryInvalidator } from '../../hooks/useHandlerQuery';
import { onError } from '../../libs/errorLib';
import { useAPI } from '../../services/API';
import { auth } from '../../auth';

export const useBillingMutations = (
  args: { onSubscribe?: () => unknown } = {},
) => {
  const { onSubscribe } = args;
  const api = useAPI();
  const invalidate = useQueryInvalidator();
  const manager = auth.useCurrentBusinessManager();

  const [subscribeBusinessWithBillingAccount, isSubscribing] = useHandler(
    (api) => api.billing().subscribeBusinessWithBillingAccount,
    {
      always: {
        onSuccess: (_, { billingAccountId, businessId }) => {
          createToast({
            id: 'BUSINESS_SUB_SUCCESS',
            title: 'Subscription created 🎉',
            description: 'Your business has been subscribed',
            status: 'success',
          });
          track.event('Subscribed', {
            billingAccountId,
            businessId,
            businessName: manager?.businessName,
          });
          onSubscribe?.();
        },
      },
      invalidates: ({ billingAccountId, businessId }) => [
        ['BILLING_ACCOUNT_SUBSCRIPTIONS', billingAccountId],
        ['MY_BILLING_ACCOUNTS'],
        ['BUSINESS', businessId],
        ['BILLING_ACCOUNT_PAYMENT_SUMMARY', billingAccountId],
      ],
    },
  );
  return {
    subscribeBusinessWithBillingAccount,
    isSubscribing,
    cancelSubscription: ({ billingAccountId }: { billingAccountId: string }) =>
      api
        .billing()
        .cancelBillingAccountSubscription({ billingAccountId })
        .tap(() => {
          invalidate(
            ['MY_BILLING_ACCOUNTS'],
            ['BILLING_ACCOUNT_SUBSCRIPTIONS', billingAccountId],
            ['BILLING_ACCOUNT_PAYMENT_SUMMARY', billingAccountId],
          );
        }, onError),
    unsubscribe: ({
      billingAccountId,
      businessId,
    }: {
      businessId: string;
      billingAccountId: string;
    }) =>
      api
        .billing()
        .unsubscribeBusiness({ businessId })
        .tap(() => {
          invalidate(
            ['BUSINESS', businessId],
            ['MY_BILLING_ACCOUNTS'],
            ['BILLING_ACCOUNT_SUBSCRIPTIONS', billingAccountId],
          );
        }, onError),
  };
};

import React from 'react';
import {
  Box,
  InputLeftElement,
  List,
  ListItem,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { SuperLookup } from '@zap-onboard/api-client';
import { MdSearch } from 'react-icons/md';
import { Optional } from 'designed';
import { useDebouncedValue } from '../../../../../../hooks/useDebouncedValue';
import { logError } from '../../../../../../libs/errorLib';
import { inputs } from '@zap-onboard/web-components';
import { useHandlerQuery } from '../../../../../../hooks/useHandlerQuery';
import { ErrorDisplay } from '../../../../../../billing/ErrorDisplay';
import { keyboardHandler } from '../../../../../../helpers/keyboardHandler';

interface Props {
  isDisabled: boolean;
  onPicked: (lookup: SuperLookup) => unknown;
}

export const SuperChoiceNameAutocomplete: React.FC<Props> = ({
  isDisabled,
  onPicked,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const { watch } = useFormContext();
  const fundName: string = watch('superChoice.fundName', '');
  const debouncedFundName = useDebouncedValue(fundName, 500);
  const [selectedSuperidx, setSelectedSuperidx] = React.useState<number | null>(
    null,
  );

  const superLookup = useHandlerQuery(
    (api) =>
      api.gov().searchSuper({ nameContains: debouncedFundName, count: 15 }),
    {
      key: ['SUPER_LOOKUP', debouncedFundName],
      retry: 2,
      enabled: debouncedFundName !== '',
      onError: logError,
      staleAfter: '10m',
      onChange: () => setSelectedSuperidx(0),
    },
  );

  const handleKeydown = keyboardHandler({
    ArrowDown: () => {
      let newIdx = (selectedSuperidx ?? 0) + 1;
      if (newIdx >= (superLookup?.data?.length ?? 0)) {
        newIdx = 0;
      }
      setSelectedSuperidx(newIdx);
    },

    ArrowUp: () => {
      let newIdx = (selectedSuperidx ?? 0) - 1;
      if (newIdx < 0) {
        newIdx = (superLookup?.data?.length ?? 1) - 1;
      }
      setSelectedSuperidx(newIdx);
    },

    Enter: () => {
      Optional.of(selectedSuperidx)
        .map((idx) => (superLookup.data ?? [])[idx])
        .map(onPicked);
      setSelectedSuperidx(null);
      setIsOpen(false);
    },

    else: () => setIsOpen(true),
  });

  return (
    <>
      <ErrorDisplay title="Could not load super funds" {...superLookup} />
      <inputs.TextInput
        label="Fund Name"
        name="superChoice.fundName"
        placeholder="Type to search funds..."
        onFocus={() => setIsOpen(true)}
        onKeyDown={handleKeydown}
        isDisabled={isDisabled}
        leftInputElement={
          <InputLeftElement pointerEvents="none">
            {superLookup.isLoading ? (
              <Spinner />
            ) : (
              <MdSearch color="gray.300" />
            )}
          </InputLeftElement>
        }
      >
        {isOpen && !!superLookup.data && superLookup.data.length > 0 && (
          <Box pb={4} mb={4}>
            <List
              bg="white"
              border={isOpen ? '1px solid rgba(0,0,0,0.1)' : undefined}
              boxShadow="6px 5px 8px rgba(0,50,30,0.02)"
            >
              {superLookup.data.map((s, i) => (
                <ListItem
                  key={s.superLookupId}
                  tabIndex={-1}
                  bg={i === selectedSuperidx ? 'gray.100' : 'white'}
                  borderBottom="1px solid rgba(0,0,0,0.1)"
                  aria-label="Selected"
                  px={2}
                  py={1}
                  onMouseOver={() => setSelectedSuperidx(i)}
                  onClick={() => {
                    setIsOpen(false);
                    setSelectedSuperidx(null);
                    onPicked(s);
                  }}
                >
                  <Text>{s.productName}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </inputs.TextInput>
    </>
  );
};

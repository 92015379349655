type Environment = {
  name: string;
  backendUrl: string;
  landingPageUrl: string;
  url: string;
  gaTrackingId: string | null;
  mixpanelToken: string | null;
  stripePublicKey: string | null;
  googleSigninClientId: string | null;
  intercomAppId?: string;
  xeroAppId: string;
};

type Environments = {
  dev: Environment;
  test: Environment;
  staging: Environment;
  prod: Environment;
};

const environments: Environments = {
  dev: {
    name: 'dev',
    backendUrl: `http://${window.location.hostname}:4000/api`,
    url: `http://${window.location.hostname}:3000`,
    landingPageUrl: 'https://nonprod.canyou.com.au',
    gaTrackingId: null,
    mixpanelToken: 'ea6818a5730b6483bab97d58e41e7d08',
    googleSigninClientId:
      '1769343583-1risfp6brr4bm8fghsitvsbasf1464ad.apps.googleusercontent.com',
    stripePublicKey:
      'pk_test_51J3c1tItRJyyhellk0Cm55cFpEcI0g6ukqpLOKwIGYbCr9o52KpZfeFrU4VVd0shsylaXlP4URwmtzEZuJhB6pnJ00hxJIpoGZ',
    intercomAppId: 'rdetpwlg',
    xeroAppId: 'd0d4d348-eb87-4329-90b4-33be50bca72d',
  },

  get test() {
    return {
      backendUrl: `http://${window.location.hostname}:3777/api`,
      name: 'test',
      url: 'http://localhost:3000',
      landingPageUrl: 'https://nonprod.canyou.com.au',
      gaTrackingId: null,
      mixpanelToken: null,
      googleSigninClientId: null,
      stripePublicKey: null,
      xeroAppId: 'xero-app-id',
    };
  },

  staging: {
    name: 'staging',
    backendUrl: 'https://app.nonprod.canyou.com.au/api',
    url: 'https://app.nonprod.canyou.com.au',
    landingPageUrl: 'https://nonprod.canyou.com.au',
    gaTrackingId: null,
    mixpanelToken: 'f55c5b51dd64e4758c7742fd98e4a710',
    googleSigninClientId:
      '1769343583-goi4s6absfkeqefdtnahuheqoopc2ivl.apps.googleusercontent.com',
    stripePublicKey:
      'pk_test_51J3c1tItRJyyhellk0Cm55cFpEcI0g6ukqpLOKwIGYbCr9o52KpZfeFrU4VVd0shsylaXlP4URwmtzEZuJhB6pnJ00hxJIpoGZ',
    intercomAppId: 'rdetpwlg',
    xeroAppId: '37919f5d-db08-4c12-b9b6-6d11d15dec7b',
  },

  prod: {
    name: 'production',
    backendUrl: 'https://app.canyou.com.au/api',
    url: 'https://app.canyou.com.au',
    landingPageUrl: 'https://canyou.com.au',
    gaTrackingId: null,
    mixpanelToken: 'c53f2164eb8a45076171ba015f897721',
    googleSigninClientId:
      '693901916181-94n8aoqsudtte7b1h50gs8vcnmhblght.apps.googleusercontent.com',
    stripePublicKey:
      'pk_live_51J2QOQLchw1isYWy82oOmPrOt1Sdq8M00ss1ExdxQHKHYQE7Te3Upi4srIgG9D6xpbshYcVaLQfTbcoqol8EY5vU00qRGDhhNp',
    intercomAppId: 'p3ww739u',
    xeroAppId: '38f50c1c-1484-43d6-805a-543d0a74fc0f',
  },
};

type EnvironmentNames = keyof typeof environments;

const { REACT_APP_STAGE } = process.env;

const isValidStageEnv = (str: string): str is EnvironmentNames =>
  Object.keys(environments).includes(str as EnvironmentNames);

let stage: EnvironmentNames;
if (REACT_APP_STAGE && isValidStageEnv(REACT_APP_STAGE)) {
  stage = REACT_APP_STAGE;
} else {
  stage = 'dev';
}

export const config = environments[stage];

export const APP_STAGE = stage;

export default {
  ...config,
};

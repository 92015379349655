"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
const designed_1 = require("designed");
const handler_1 = require("../utils/handler");
const SuperFundResponse_1 = require("../utils/SuperFundResponse");
const Get_1 = require("./models/Get");
const Register_1 = require("./models/Register");
const Update_1 = require("./models/Update");
class Client {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.register = (0, handler_1.handler)(this, async (data) => await this.http
            .request()
            .to('business')
            .bodyJSON(Register_1.Register.Request.create(data))
            .post((data) => Register_1.Register.Response.fromJSON(data)));
        this.updateMyBusiness = (0, handler_1.handler)(this, async (data) => await this.http
            .request()
            .to(`business/my`)
            .bodyJSON(Update_1.Update.Request.create(data))
            .post());
        this.get = (0, handler_1.handler)(this, async (businessId) => await this.http
            .request()
            .to(`business/${businessId}`)
            .get((data) => Get_1.Get.Business.Response.fromJSON(data)));
        this.getBusinessNominatedSuper = (0, handler_1.handler)(this, async (businessId) => this.http
            .request()
            .to(`/business/${businessId}/nominated-super-fund`)
            .get((data) => designed_1.Optional.of(data.fund).map((fund) => SuperFundResponse_1.SuperFundResponse.create(fund))));
        this.updateBusinessNominatedSuper = (0, handler_1.handler)(this, async (data) => this.http
            .request()
            .to(`/business/nominated-super-fund`)
            .bodyJSON(Update_1.Update.NominatedSuper.Request.create(data))
            .post());
    }
}
exports.Client = Client;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsignTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../../../file");
const BaseTask_1 = require("../Task/BaseTask");
class EsignTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(file_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", file_1.RemoteFile)
], EsignTask.prototype, "document", void 0);
exports.EsignTask = EsignTask;

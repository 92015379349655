import * as React from 'react';
import { Button, Flex, Heading, Stack } from '@chakra-ui/react';

import {
  ProfileTask as APIProfileTask,
  TaskActionType,
} from '@zap-onboard/api-client';
import { BaseTaskPropTypes } from './BaseTaskPropTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { ProfileForm } from '../../../../pages/Profile/UserProfile/Form';
import { z } from '../../../../helpers/schema';

interface RawTaskProps extends BaseTaskPropTypes {
  task: APIProfileTask;
}

export const ProfileTask: React.FC<RawTaskProps> = ({
  task,
  canEdit,
  submitAction,
}) => {
  const canSubmit =
    task.taskStateId === 'PROFILE_PENDING' && task.allowedToAction;

  const form = useForm<z.infer<typeof ProfileForm.Schema>>({
    defaultValues: ProfileForm.getDefaultValue(
      task.submittedProfile ?? task.currentProfile,
    ),
    resolver: z.zodResolver(
      ProfileForm.SelectedSchema({
        onlyFields: task.includeFields,
        isEditMode: canSubmit,
      }),
    ),
  });

  return (
    <FormProvider {...form}>
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
        <Flex
          direction="column"
          justify="space-between"
          h="100%"
          minH={{ md: '360px' }}
        >
          <Stack h="100%">
            <Heading variant="section" textAlign="center">
              Complete your profile
            </Heading>
            <ProfileForm.Component
              isEditMode={canSubmit}
              onlyFields={task.includeFields}
            />
            {canSubmit && (
              <Button
                onClick={form.handleSubmit(async (data) =>
                  submitAction(TaskActionType.PROFILE_COMPLETE, {
                    taskId: task.taskId,
                    profile: {
                      ...data,
                      secondaryContactNumber:
                        data.secondaryContactNumber === ''
                          ? null
                          : data.secondaryContactNumber,
                      otherGivenName:
                        data.otherGivenName === '' ? null : data.otherGivenName,
                    },
                  }),
                )}
                isLoading={form.formState.isSubmitting}
                isDisabled={!canEdit}
              >
                Update profile
              </Button>
            )}
          </Stack>
        </Flex>
      </Stack>
    </FormProvider>
  );
};

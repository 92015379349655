"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityClient = exports.ExchangeGoogleResult = exports.ExchangeGoogleTokenRequest = exports.ProvisionAPIKeyRequest = exports.GoogleConnectPurpose = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const handler_1 = require("../utils/handler");
const models_1 = require("./models");
const utils_1 = require("../utils");
const ResponseBase_1 = require("../ResponseBase");
const LoginFlowResponse_1 = require("./models/LoginFlowResponse");
var GoogleConnectPurpose;
(function (GoogleConnectPurpose) {
    GoogleConnectPurpose["SIGN_IN"] = "SIGN_IN";
    GoogleConnectPurpose["SIGN_UP"] = "SIGN_UP";
    GoogleConnectPurpose["CONNECT_ACCOUNT"] = "CONNECT_ACCOUNT";
})(GoogleConnectPurpose = exports.GoogleConnectPurpose || (exports.GoogleConnectPurpose = {}));
class ProvisionAPIKeyRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], ProvisionAPIKeyRequest.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], ProvisionAPIKeyRequest.prototype, "remove", void 0);
exports.ProvisionAPIKeyRequest = ProvisionAPIKeyRequest;
class ExchangeGoogleTokenRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(GoogleConnectPurpose),
    (0, tslib_1.__metadata)("design:type", String)
], ExchangeGoogleTokenRequest.prototype, "purpose", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsJWT)(),
    (0, tslib_1.__metadata)("design:type", String)
], ExchangeGoogleTokenRequest.prototype, "googleIdToken", void 0);
exports.ExchangeGoogleTokenRequest = ExchangeGoogleTokenRequest;
class ExchangeGoogleResult extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(GoogleConnectPurpose),
    (0, tslib_1.__metadata)("design:type", String)
], ExchangeGoogleResult.prototype, "purpose", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(LoginFlowResponse_1.LoginFlowResponse),
    (0, tslib_1.__metadata)("design:type", LoginFlowResponse_1.LoginFlowResponse)
], ExchangeGoogleResult.prototype, "loginFlow", void 0);
exports.ExchangeGoogleResult = ExchangeGoogleResult;
class IdentityClient {
    constructor(http, $error, $tokenUpdated, $loginFlow) {
        this.http = http;
        this.$error = $error;
        this.$tokenUpdated = $tokenUpdated;
        this.$loginFlow = $loginFlow;
        this.register = (0, handler_1.handler)(this, async (args) => {
            await this.http.request().to('register').bodyJSON(args).post();
        });
        this.confirmRegistration = (0, handler_1.handler)(this, async (confirmationToken) => {
            const response = await this.http
                .request()
                .to('confirm-registration')
                .bodyJSON({ confirmationToken })
                .post((data) => {
                return LoginFlowResponse_1.LoginFlowResponse.create(data);
            });
            this.$loginFlow.publish(response);
            return response;
        });
        /**
         * @example login or register and send a link to the businesses application URL
         *
         * ```ts
         * api.identity().magicLoginOrRegister({
         *   email,
         *   redirectRequest: {
         *     type: LoginRedirectType.DIRECT,
         *     businessId
         *   }
         * })
         * ```
         */
        this.magicLoginOrRegister = (0, handler_1.handler)(this, async (opts) => this.http.request().to('magic-login-or-register').bodyJSON(opts).post());
        /**
         * Uses the ?token=.* parameter from the /magic-link-login page the user is directed to.
         */
        this.loginWithMagicLinkToken = (0, handler_1.handler)(this, async (magicLinkToken) => {
            const response = await this.http
                .request()
                .to('login')
                .bodyJSON({ magicLinkToken })
                .post((data) => LoginFlowResponse_1.LoginFlowResponse.create(data));
            this.$loginFlow.publish(response);
            return response;
        });
        this.beginLogin = (0, handler_1.handler)(this, async (opts) => {
            const response = await this.http
                .request()
                .to('begin-login')
                .bodyJSON(opts)
                .post((data) => LoginFlowResponse_1.LoginFlowResponse.create(data));
            this.$loginFlow.publish(response);
            return response;
        });
        this.logout = (0, handler_1.handler)(this, async () => {
            try {
                await this.http.request().to('logout').bodyJSON({}).post();
            }
            finally {
                this.$tokenUpdated.publish(undefined);
            }
        });
        this.myProfile = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('user/my/profile')
            .get((d) => models_1.UserProfile.fromJSON(d.profile)));
        this.updateMyProfile = (0, handler_1.handler)(this, async (profile) => {
            await this.http
                .request()
                .to(`user/my/profile`)
                .bodyJSON(models_1.UpdateUserProfile.create(profile).asJSON())
                .post();
        });
        this.acceptTerms = (0, handler_1.handler)(this, async () => this.http.request().to('/terms/accept').post());
        this.exchangeGoogleToken = (0, handler_1.handler)(this, async (res) => {
            const response = await this.http
                .request()
                .to('/google/token-exchange')
                .bodyJSON(ExchangeGoogleTokenRequest.create(res))
                .post((data) => LoginFlowResponse_1.MaybeLoginFlowResponse.create(data));
            response
                .maybe('loginFlow')
                .tap((response) => this.$loginFlow.publish(response));
            return response;
        });
        this.provisionAPIKey = (0, handler_1.handler)(this, async (args) => {
            return this.http
                .request()
                .to('/identity/provision-api-key')
                .bodyJSON(ProvisionAPIKeyRequest.create(args))
                .post();
        });
        this.loginWithAPIKey = (0, handler_1.handler)(this, async (apiKey) => {
            const response = await this.http
                .request()
                .to('/identity/login-with-api-key')
                .bodyJSON({ apiKey })
                .post((data) => LoginFlowResponse_1.LoginFlowResponse.create(data));
            this.$loginFlow.publish(response);
            return response;
        });
        this.getMyUserDetails = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to(`/users/me`)
            .get((data) => models_1.MyUserDetails.create(data)));
        this.mfa = {
            getSources: (0, handler_1.handler)(this, async () => this.http
                .request()
                .to('/mfa/sources')
                .get((r) => models_1.MFA.Sources.Response.fromJSON(r))),
            removeSource: (0, handler_1.handler)(this, async (body) => this.http.request().to('/mfa/sources').bodyJSON(body).delete()),
            addTOTPSource: (0, handler_1.handler)(this, async (body) => this.http
                .request()
                .to('/mfa/sources/totp/add')
                .bodyJSON(body)
                .post((d) => models_1.MFA.TOTP.AddResponse.create(d))),
            verifyTOTPSource: (0, handler_1.handler)(this, async (body) => {
                const response = await this.http
                    .request()
                    .to('/mfa/sources/totp/verify')
                    .bodyJSON(body)
                    .post((data) => LoginFlowResponse_1.MaybeLoginFlowResponse.create(data));
                response
                    .maybe('loginFlow')
                    .tap((response) => this.$loginFlow.publish(response));
                return response.loginFlow;
            }),
            addSMSNumber: (0, handler_1.handler)(this, async (body) => this.http
                .request()
                .to('/mfa/sources/sms/add')
                .bodyJSON(body)
                .post((d) => models_1.MFA.SMS.AddResponse.create(d))),
            verifySMSNumber: (0, handler_1.handler)(this, async (body) => {
                const response = await this.http
                    .request()
                    .to('/mfa/sources/sms/verify')
                    .bodyJSON(body)
                    .post((data) => LoginFlowResponse_1.MaybeLoginFlowResponse.create(data));
                response
                    .maybe('loginFlow')
                    .tap((response) => this.$loginFlow.publish(response));
                return response.loginFlow;
            }),
            requestSMSCode: (0, handler_1.handler)(this, async (body) => this.http
                .request()
                .to('/mfa/sources/sms/request-code')
                .bodyJSON(body)
                .post()),
            addDevSource: (0, handler_1.handler)(this, async () => this.http.request().to('/devaction/mfa-add').post()),
            verifyDevSource: (0, handler_1.handler)(this, async (body) => {
                const response = await this.http
                    .request()
                    .to('/devaction/mfa-verify')
                    .bodyJSON(body)
                    .post((data) => LoginFlowResponse_1.MaybeLoginFlowResponse.create(data));
                response
                    .maybe('loginFlow')
                    .tap((response) => this.$loginFlow.publish(response));
                return response.loginFlow;
            }),
        };
    }
}
exports.IdentityClient = IdentityClient;

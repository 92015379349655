import * as React from 'react';

import { logError, onError } from '../../libs/errorLib';

import { ErrorBoundaryDisplay } from './Display';

type Props = {
  children: React.ReactNode;
  log?: boolean;
  renderError?: (args: {
    retry: () => unknown;
    error: Error;
  }) => React.ReactNode;
};

type State = {
  error: Error | null;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    if (this.props.log) {
      logError(error);
    } else {
      onError(error);
    }
  }

  render() {
    const retry = () => {
      this.setState({ error: null });
    };

    if (this.state.error) {
      return (
        this.props.renderError?.({ retry, error: this.state.error }) ?? (
          <ErrorBoundaryDisplay />
        )
      );
    }

    return this.props.children;
  }
}

import { Icon, Flex } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

type Props = React.ComponentProps<typeof Flex>;

export const InfoBox: React.FC<Props> = ({ children, ...props }) => (
  <Flex
    layerStyle="outline"
    p={4}
    m={4}
    w="100%"
    bg="blue.50"
    flexBasis="space-around"
    {...props}
  >
    <Icon as={MdInfoOutline} color="brand.blue" boxSize={6} mx={2} />
    <Flex direction="column">{children}</Flex>
  </Flex>
);

import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { MdOpenInNew, MdFileDownload } from 'react-icons/md';

import {
  TaskActionType,
  EsignTask as APIEsignTask,
} from '@zap-onboard/api-client';
import { BaseTaskPropTypes } from '../BaseTaskPropTypes';

import { TaskCompleted } from '../../TaskStatus';
import { UploadEsignDocument } from './UploadEsignDocument';
import { InfoBox } from '../../../../InfoBox';
import { FilePreviewer } from '../../../../FilePreviewModal';
import {
  TaskActionSubmitter,
  usePrintTask,
  useStateIfMounted,
} from '../../../../../hooks';
import { NameConfirmation } from './NameConfirmation';
import { createToast } from '../../../../../helpers';

interface EsignTaskProps extends BaseTaskPropTypes {
  task: APIEsignTask;
}

export const EsignTask: React.FC<EsignTaskProps> = ({
  context,
  task,
  canEdit,
  submitAction,
}) => {
  const [isNameConfirmed, setIsNameConfirmed] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useStateIfMounted<boolean>(false);

  const waitingForDocument = task.taskStateId === 'ESIGN_WAITING_FOR_DOCUMENT';
  const isUnsigned = task.taskStateId === 'ESIGN_UNSIGNED';
  const isTaskDisabled =
    task.taskStateId === 'ESIGN_SIGNED' || isSubmitting || !canEdit;

  const { Layout, Header, Actions } = ESignTaskComponents;
  const { DocumentPreviewer } = Shared;

  if (waitingForDocument) {
    return (
      <Layout>
        {context === 'business' && (
          <UploadEsignDocument
            taskId={task.taskId}
            submitAction={submitAction}
          />
        )}

        {context === 'user' && (
          <InfoBox message="The business has not yet uploaded this document for you to sign." />
        )}
      </Layout>
    );
  }

  if (task.taskStateId === 'ESIGN_SIGNED') {
    return (
      <Layout>
        <Header task={task} />
        <DocumentPreviewer task={task} />
        <TaskCompleted />
      </Layout>
    );
  }

  return (
    <Layout>
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack spacing={2}>
          <Header task={task} />
          <Stack>
            {isUnsigned && (
              <Text>
                Please read this document and electronically sign below.
              </Text>
            )}
            <DocumentPreviewer task={task} />
            {isUnsigned && (
              <NameConfirmation
                isNameConfirmed={isNameConfirmed}
                isTaskDisabled={isTaskDisabled}
                setIsNameConfirmed={setIsNameConfirmed}
              />
            )}
          </Stack>
        </Stack>
        <Actions
          context={context}
          isNameConfirmed={isNameConfirmed}
          task={task}
          isTaskDisabled={isTaskDisabled}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          submitAction={submitAction}
        />
      </Flex>
    </Layout>
  );
};

namespace ESignTaskComponents {
  export const Layout: React.FC = ({ children }) => (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      {children}
    </Stack>
  );

  export const Header: React.FC<{ task: APIEsignTask }> = ({ task }) => {
    const { handlePrintTask, isTaskPrinting } = usePrintTask({
      taskId: task.taskId,
    });

    return (
      <Stack>
        <Heading variant="section" textAlign="center">
          {task.name}
        </Heading>
        {task.taskStateId === 'ESIGN_SIGNED' && (
          <Button
            variant="link"
            leftIcon={<MdFileDownload />}
            onClick={handlePrintTask}
            isLoading={isTaskPrinting}
            isDisabled={isTaskPrinting}
          >
            Download e-signed document
          </Button>
        )}
      </Stack>
    );
  };

  /* eslint-disable react/jsx-curly-brace-presence */
  export const Actions: React.FC<{
    context: 'business' | 'user';
    task: APIEsignTask;
    isTaskDisabled: boolean;
    isNameConfirmed: boolean;
    submitAction: TaskActionSubmitter;
    isSubmitting: boolean;
    setIsSubmitting: (arg: boolean) => unknown;
  }> = ({
    context,
    isNameConfirmed,
    isTaskDisabled,
    task,
    isSubmitting,
    setIsSubmitting,
    submitAction,
  }) => {
    const [isFileReadAndUnderstood, setIsFileReadAndUnderstood] =
      useStateIfMounted<boolean>(false);

    const handleTaskAccept = async () => {
      setIsSubmitting(true);
      await submitAction(TaskActionType.ESIGN_SIGN_ACTION, {
        taskId: task.taskId,
      });
      createToast({
        status: 'success',
        description: 'E-signed document',
      });
      setIsFileReadAndUnderstood(false);
      setIsSubmitting(false);
    };

    return (
      <Center mt={4}>
        <ButtonGroup>
          {context === 'user' && (
            <Stack spacing={3}>
              <Checkbox
                data-testid="documentEsignAcknowledgementCheckbox"
                fontSize="sm"
                defaultChecked={false}
                isChecked={isFileReadAndUnderstood}
                isDisabled={isTaskDisabled}
                onChange={() =>
                  setIsFileReadAndUnderstood(!isFileReadAndUnderstood)
                }
              >
                I have read and understood the document. I understand by
                clicking {'"Sign"'} that this is a legal representation of my
                signature.
              </Checkbox>
              <Center>
                <Button
                  onClick={handleTaskAccept}
                  isLoading={isSubmitting}
                  disabled={
                    isTaskDisabled ||
                    !isFileReadAndUnderstood ||
                    !isNameConfirmed
                  }
                >
                  Sign
                </Button>
              </Center>
            </Stack>
          )}
        </ButtonGroup>
      </Center>
    );
  };
}

namespace Shared {
  export const DocumentPreviewer: React.FC<{ task: APIEsignTask }> = ({
    task,
  }) => (
    <Flex>
      {task.document && (
        <Stack w="100%">
          <FilePreviewer file={task.document} height="90vh" />
          <Button
            as="a"
            leftIcon={<MdOpenInNew />}
            variant="outline"
            target="_blank"
            rel="noopener"
            href={task.document.url}
          >
            Open
          </Button>
        </Stack>
      )}
    </Flex>
  );
}

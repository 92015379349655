import { Box } from '@chakra-ui/react';
import { ComponentProps, forwardRef } from 'react';

/**
 * Scales an exactly sized element to a specific scale.
 *
 * This wraps and prevents overflow by using an absolutely positioned child. so
 * that it does not break normal layouts.
 */
export const ScaledBox = forwardRef(
  (
    args: {
      children: React.ReactNode;
      scale: number;
      width: number;
      height: number;
      wrapperRef?: React.Ref<HTMLDivElement>;
    } & Omit<
      ComponentProps<typeof Box>,
      'height' | 'width' | 'scale' | 'style' | 'children'
    >,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { children, height, scale, width, wrapperRef, ...props } = args;
    return (
      <Box
        ref={wrapperRef}
        w={width * scale}
        h={height * scale}
        style={{ position: 'relative' }}
      >
        <Box
          ref={ref}
          style={{
            width,
            height,
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            top: `0px`,
            left: '0px',
            position: 'absolute',
          }}
          {...props}
        >
          {children}
        </Box>
      </Box>
    );
  },
);

/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import {
  Button,
  Text,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Center,
  Stack,
  VStack,
} from '@chakra-ui/react';

import { useAPI } from '../../services/API';
import { auth } from '../../auth';

export const TermsAndConditionsNagBox = () => {
  const api = useAPI();
  const { hasAcceptedTerms, setHasAcceptedTerms } = auth.useTerms();
  const userProfile = auth.useProfile();

  const onAccept = React.useCallback(async () => {
    setHasAcceptedTerms();
    await api.identity().acceptTerms();
  }, [api, setHasAcceptedTerms]);

  if (!userProfile || hasAcceptedTerms) {
    return null;
  }

  return (
    <Modal isOpen isCentered onClose={() => {}}>
      <ModalOverlay />

      <ModalContent p={4}>
        <ModalHeader align="center">Welcome to Canyou 👋</ModalHeader>
        <ModalBody>
          <Center height="100%">
            <Stack spacing={8}>
              <Button
                fontWeight="bold"
                whiteSpace="normal"
                size="md"
                variant="solid"
                textTransform="uppercase"
                isFullWidth
                autoFocus
                onClick={onAccept}
              >
                Get started
              </Button>
            </Stack>
          </Center>
        </ModalBody>

        <ModalFooter>
          <VStack spacing={3}>
            <Text fontSize={12} align="center">
              By proceeding you are agreeing to the Canyou{' '}
              <Link
                textDecoration="underline"
                color="brand.blue"
                href="https://canyou.com.au/privacy-policy"
                isExternal
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                textDecoration="underline"
                href="https://canyou.com.au/terms-and-conditions"
                color="brand.blue"
                isExternal
              >
                Terms and Conditions
              </Link>
            </Text>
            <Text>
              <Link
                textDecoration="underline"
                href="https://canyou.com.au"
                color="brand.blue"
                isExternal
              >
                Find out more about Canyou
              </Link>
            </Text>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const BankAccount_1 = require("../../../utils/BankAccount");
const BaseTask_1 = require("../Task/BaseTask");
class BankAccountTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(BankAccount_1.BankAccount),
    (0, tslib_1.__metadata)("design:type", BankAccount_1.BankAccount)
], BankAccountTask.prototype, "accountSubmission", void 0);
exports.BankAccountTask = BankAccountTask;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteMember = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const Email_1 = require("../../utils/email/Email");
var InviteMember;
(function (InviteMember) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Email_1.Email),
        (0, tslib_1.__metadata)("design:type", Email_1.Email)
    ], Request.prototype, "email", void 0);
    InviteMember.Request = Request;
})(InviteMember = exports.InviteMember || (exports.InviteMember = {}));

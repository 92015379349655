import * as React from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
import {
  IndividualTFN,
  SuperChoiceTask,
  UserProfile,
} from '@zap-onboard/api-client';
import { InfoBox } from '../../../../../InfoBox';
import { z } from '../../../../../../helpers/schema';
import { inputs } from '@zap-onboard/web-components';

type SuperChoiceUserDetailsProps = {
  isDisabled: boolean;
};

export const getSuperChoiceUserDetailsDefaults = (
  task?: SuperChoiceTask,
  subjectUser?: UserProfile,
): z.infer<typeof SuperChoiceUserDetailsSchema> => {
  if (!task?.employeeSubmission) {
    return {
      userName: subjectUser?.getFullName() ?? '',
      userEmployeeIdentificationNumber: '',
      userTFN: '',
    };
  }

  return {
    userTFN: task.employeeSubmission.tfn?.asJSON() ?? '',
    userName: task.employeeSubmission.name ?? '',
    userEmployeeIdentificationNumber:
      task.employeeSubmission?.employeeIdentificationNumber ?? '',
  };
};

export const SuperChoiceUserDetailsSchema = z.object({
  userName: z.string().min(1, { message: 'Required' }),
  userEmployeeIdentificationNumber: z
    .string()
    .max(20, { message: 'Max 20 characters' }),
  userTFN: z.optionalValueObject(z.string(), IndividualTFN),
});

export const SuperChoiceUserDetails: React.FC<SuperChoiceUserDetailsProps> = ({
  isDisabled,
}) => (
  <Stack w="100%">
    <Heading variant="subSection">Your details</Heading>
    <Stack spacing={6}>
      <inputs.TextInput
        label="Your Full Name"
        name="userName"
        placeholder="Your official name"
        isDisabled={isDisabled}
        isReadOnly
      />

      <inputs.TextInput
        label="Employee identification number (if applicable)"
        name="userEmployeeIdentificationNumber"
        isDisabled={isDisabled}
        placeholder="If applicable, please fill in"
      />

      <inputs.TextInput
        label="Tax file number (TFN)"
        name="userTFN"
        isDisabled={isDisabled}
        placeholder="Your TFN"
      />

      <Stack spacing={4} align="center">
        <InfoBox
          icon={MdInfoOutline}
          message="You do not have to quote your TFN but if you do not provide it, your contributions may be taxed at a higher rate. Your TFN also helps you keep track of your super and allows you to make personal contributions to your fund."
        />
      </Stack>
    </Stack>
  </Stack>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Email = void 0;
const class_validator_1 = require("class-validator");
const errors_1 = require("@zap-onboard/errors");
const designed_1 = require("designed");
/**
 * While real emails can be up to 320 characters our integration partners
 * require emails to have lengths LTE 100
 */
class Email {
    constructor(value) {
        this.value = value;
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('Email was given a non string value');
        }
        if (!(0, class_validator_1.isEmail)(s)) {
            throw errors_1.errs.ValidationError.create('Email is not valid');
        }
        if (s.length > 100) {
            throw errors_1.errs.ValidationError.create('Email cannot be more than 100 characters');
        }
        return new Email(s.trim());
    }
    static isValid(s) {
        return Email.validationMessage(s) == null;
    }
    static try(s) {
        return designed_1.Result.fromThrowable(() => Email.fromJSON(s))
            .toOptional()
            .orElse(undefined);
    }
    static validationMessage(s) {
        try {
            Email.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    equals(e) {
        return this.value.toLowerCase() === e.value.toLowerCase();
    }
    toString() {
        return this.value;
    }
    asJSON() {
        return this.value;
    }
    toJSON() {
        return this.value;
    }
}
exports.Email = Email;

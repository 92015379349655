import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BaseIconProps } from '../Icons/BaseIcon';

type CompactMenuItemProps = {
  route: string;
  label: string;
  Icon?: React.FC<BaseIconProps>;
  onClick: () => void;
  prefix?: string;
  subMenu?: {
    route: string;
    label: string;
  }[];
};

export const CompactMenuItem: React.FC<CompactMenuItemProps> = ({
  label,
  route,
  Icon,
  onClick,
  subMenu,
  prefix,
}) => {
  const { pathname } = useLocation();
  const currentRoute = pathname === route ? route : undefined;

  const primary = currentRoute === route ? 'brand.blue' : 'white';
  const secondary = currentRoute === route ? 'white' : 'brand.blue';

  if (subMenu) {
    return (
      <>
        {subMenu.map(({ route, label: nestedLabel }) => {
          return (
            <CompactMenuItem
              key={label}
              Icon={Icon}
              route={route}
              prefix={label}
              label={nestedLabel}
              onClick={onClick}
            />
          );
        })}
      </>
    );
  }

  return (
    <Box
      as={Link}
      to={route}
      borderRadius="3px"
      bg={primary}
      p={2}
      px={4}
      onClick={onClick}
    >
      <Flex dir="row">
        <Flex justify="center" align="center" mr={6}>
          {Icon && <Icon iconColor={secondary} width="12px" height="12px" />}
        </Flex>
        <Box>
          <HStack spacing={2}>
            <Text color={secondary} fontSize="sm">
              {prefix} {label}
            </Text>
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};

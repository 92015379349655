"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextBlockFormSubmissionItem = exports.TextBlockFormSchemaItem = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FormSchemaItem_1 = require("./FormSchemaItem");
const type = 'TEXT_BLOCK';
class TextBlockFormSchemaItem extends FormSchemaItem_1.FormSchemaItem {
    constructor() {
        super(...arguments);
        this.type = type;
    }
    async validateItem() { }
    makeEmptySubmission() {
        return TextBlockFormSubmissionItem.create({
            type: 'TEXT_BLOCK',
            formSchemaItemId: this.formSchemaItemId,
        });
    }
}
TextBlockFormSchemaItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], TextBlockFormSchemaItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextBlockFormSchemaItem.prototype, "text", void 0);
exports.TextBlockFormSchemaItem = TextBlockFormSchemaItem;
class TextBlockFormSubmissionItem extends FormSchemaItem_1.FormSubmissionItemBase {
    constructor() {
        super(...arguments);
        this.type = type;
    }
}
TextBlockFormSubmissionItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], TextBlockFormSubmissionItem.prototype, "type", void 0);
exports.TextBlockFormSubmissionItem = TextBlockFormSubmissionItem;

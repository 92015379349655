import { Box, Button, ButtonGroup, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { auth } from '../../auth';
import { TickFancy } from '../../billing/SubscriptionWizard/pages/PlanSelector/TickFancy';
import { workRightsAddOnProducts } from '../../billing/SubscriptionWizard/products';
import { usePurchaseVisaProduct } from '../../hooks';

const AddOnItem: React.FC<{
  id: string;
  amount: number;
  selectedWorkRightsAddonTier: string;
  handleCheckboxChange: (checkboxName: string) => void;
  quantity: number;
  description: string;
}> = ({
  id,
  amount,
  selectedWorkRightsAddonTier,
  handleCheckboxChange,
  quantity,
  description,
}) => {
  const isSelected = selectedWorkRightsAddonTier === id;

  return (
    <Flex
      h="178px"
      p="24px"
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      gridGap="16px"
      layerStyle="card"
      borderColor={isSelected ? 'brand.blue' : 'brand.gray'}
      borderWidth="1px"
      w="280px"
    >
      <Box
        as="input"
        id={id}
        name="billingAccount"
        type="checkbox"
        transform="scale(1.5)"
        checked={selectedWorkRightsAddonTier === id}
        onChange={() => handleCheckboxChange(id)}
      />
      <Box
        as="label"
        display="flex"
        flexDirection="column"
        htmlFor={id}
        gridGap="16px"
      >
        <Text fontSize="18px" fontWeight="bold">
          {description}
        </Text>
        <Flex direction="column">
          <Text fontSize="20px" fontWeight="bold">
            $ {Number.isInteger(amount) ? amount : amount.toFixed(2)}
          </Text>
          <Text fontSize="14px">
            ${(amount / quantity).toFixed(2)} per check
          </Text>
          <Text fontSize="14px">GST inclusive</Text>
        </Flex>
      </Box>
    </Flex>
  );
};

const WorkRightsFeatureListItem: React.FC = ({ children }) => (
  <Flex alignItems="center" gridGap="10px">
    <TickFancy />
    <Text fontSize="14px">{children}</Text>
  </Flex>
);

type Props = {
  onClose: () => unknown;
};
type VisaPriceId = (typeof workRightsAddOnProducts)[number]['id'];
export const VisaProductSelector: React.FC<Props> = ({ onClose }) => {
  const [selectedWorkRightsAddonTier, setSelectedWorkRightsAddOnTier] =
    React.useState('');
  const [formError, setFormError] = React.useState('');
  const businessId = auth.useCurrentBusinessId()!;
  const { isPurchaseProductLoading, purchaseProduct } =
    usePurchaseVisaProduct();

  const handleCheckboxChange = (checkboxName: string) => {
    setSelectedWorkRightsAddOnTier(
      selectedWorkRightsAddonTier === checkboxName ? '' : checkboxName,
    );
  };
  const handleSubmit = async () => {
    if (!selectedWorkRightsAddonTier) {
      setFormError('Please select a pack');
      return;
    }
    setFormError('');
    await purchaseProduct({
      visaProductId: selectedWorkRightsAddonTier as VisaPriceId,
      businessId,
    });
  };
  return (
    <Flex
      direction="column"
      alignItems="flex-start"
      gridGap="19px"
      flex="1 0 0"
    >
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gridGap="12px"
        alignSelf="stretch"
      >
        <Flex
          padding="20px 0px"
          direction="column"
          alignItems="flex-start"
          gridGap="12px"
          alignSelf="stretch"
        >
          <Flex alignItems="baseline" gridGap="12px" alignSelf="stretch">
            <Text fontSize="16px" fontWeight="bold">
              Work rights check
            </Text>
            <Text fontSize="14px">Top up as needed</Text>
          </Flex>
          <Text fontSize="14px">
            Instant visa check - Improve the employee experience and ensure your
            team has the rights to work
          </Text>
          <Flex
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            gridGap="4px"
          >
            <WorkRightsFeatureListItem>
              All checks via Department of Home Affairs&apos; VEVO system
            </WorkRightsFeatureListItem>
            <WorkRightsFeatureListItem>
              Easy check of visa status, class and subclass and work rights of
              temporary and permanent visa holders provides
            </WorkRightsFeatureListItem>
            <WorkRightsFeatureListItem>
              Notifies when visa&apos;s are approaching expiry
            </WorkRightsFeatureListItem>
            <WorkRightsFeatureListItem>
              Electronic audit trail to avoid penalties under the Employer
              Sanctions legislation
            </WorkRightsFeatureListItem>
          </Flex>
          <Flex alignItems="baseline" gridGap="12px" alignSelf="stretch" mt={2}>
            <Text fontSize="16px" fontWeight="bold">
              Select a pack
            </Text>
          </Flex>
          <form id="subscription-wizard" onSubmit={handleSubmit}>
            <Flex
              alignItems="flex-start"
              gridGap="12px"
              alignSelf="stretch"
              flexWrap="wrap"
            >
              {!!workRightsAddOnProducts &&
                workRightsAddOnProducts.map(
                  ({ amount, quantity, id, description }) => (
                    <AddOnItem
                      id={id}
                      key={id}
                      amount={amount}
                      handleCheckboxChange={handleCheckboxChange}
                      selectedWorkRightsAddonTier={selectedWorkRightsAddonTier}
                      quantity={quantity}
                      description={description}
                    />
                  ),
                )}
            </Flex>
            <Flex my={7}>
              <Flex direction="column" flex="1 0 0">
                <Text
                  fontSize="14px"
                  letterSpacing="-0.22px"
                  lineHeight="150%"
                  fontWeight="bold"
                >
                  Visa checks are charged immediately to the same card as your
                  subscription.
                </Text>
                <Text
                  fontSize="14px"
                  letterSpacing="-0.22px"
                  lineHeight="150%"
                  opacity={0.5}
                >
                  Your available balance can be viewed in the settings menu.
                </Text>
              </Flex>
              <ButtonGroup justifyContent="center" pt={2} display="flex">
                <Button
                  isLoading={isPurchaseProductLoading}
                  isDisabled={isPurchaseProductLoading}
                  onClick={handleSubmit}
                >
                  Pay now
                </Button>
                <Button
                  variant="outline"
                  onClick={onClose}
                  isDisabled={isPurchaseProductLoading}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Flex>
            {formError && (
              <Text fontSize="14px" color="red.500">
                {formError}
              </Text>
            )}
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

import * as React from 'react';
import { Text } from '@chakra-ui/react';

import {
  EmptyState,
  LoadingState,
  SimpleTile,
  TileGroupLayout,
} from '../../../components';

type Tile = {
  id: string;
  name: string;
};

type TileGroupSelectProps = {
  tiles: Tile[];
  isLoading?: boolean;
  selectedTileIds: string[];
  selectOrDeselectTile: (arg: string) => void;
};

export const TileGroupSelect: React.FC<TileGroupSelectProps> = ({
  tiles,
  isLoading,
  selectedTileIds,
  selectOrDeselectTile,
}) => {
  function setLayerStyle(id: string) {
    return selectedTileIds.find((t) => t === id) ? 'selected' : 'base';
  }

  if (isLoading) {
    return (
      <TileGroupLayout>
        <LoadingState />
      </TileGroupLayout>
    );
  }

  if (tiles.length === 0) {
    return (
      <TileGroupLayout>
        <EmptyState message="Your list is empty" />
      </TileGroupLayout>
    );
  }

  return (
    <TileGroupLayout>
      {tiles.map(({ id, name }, index) => (
        <SimpleTile
          key={id}
          data-testid={`tile-${index}`}
          layerStyle={setLayerStyle(id)}
          onClick={() => selectOrDeselectTile(id)}
        >
          <Text
            fontSize={{ base: 'sm', lg: 'md' }}
            textAlign="center"
            overflow="hidden"
            w="100%"
          >
            {name}
          </Text>
        </SimpleTile>
      ))}
    </TileGroupLayout>
  );
};

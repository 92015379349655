import * as React from 'react';
import { Center, Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react';

import { ReactComponent as IssueIcon } from '../../../assets/img/issue.svg';

export const ErrorBoundaryDisplay: React.FC = () => {
  const supportEmail = 'support@canyou.com.au';

  return (
    <Center minH="100vh">
      <Stack spacing={2} textAlign="center" p={4}>
        <Flex justify="center">
          <Icon as={IssueIcon} boxSize={{ base: '240px' }} pl={{ base: 6 }} />
        </Flex>

        <Heading size="md">Sorry!</Heading>
        <Text>Something went wrong. Please try again later.</Text>
        <Text color="brand.dark-gray">{supportEmail}</Text>
      </Stack>
    </Center>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const Role_1 = require("../AccessControl/Role");
var Search;
(function (Search) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, class_validator_1.IsNotEmpty)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "search", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "positionId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "contactId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "userId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsIn)([true]),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "anonymous", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "archived", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsIn)([true]),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "uninvited", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsIn)([true]),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "invited", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsIn)([true]),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "isOwnerOrAdmin", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "role", void 0);
    Search.Request = Request;
    class MembershipPosition extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], MembershipPosition.prototype, "positionId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], MembershipPosition.prototype, "positionName", void 0);
    Search.MembershipPosition = MembershipPosition;
    class Invitation extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Invitation.prototype, "sentAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Invitation.prototype, "acceptedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Invitation.prototype, "declinedAt", void 0);
    Search.Invitation = Invitation;
    class Member extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Member.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Member.prototype, "archived", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Member.prototype, "userName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Member.prototype, "userId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.Email),
        (0, tslib_1.__metadata)("design:type", utils_1.Email)
    ], Member.prototype, "email", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(Invitation),
        (0, tslib_1.__metadata)("design:type", Invitation)
    ], Member.prototype, "invite", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Role_1.Role),
        (0, tslib_1.__metadata)("design:type", Role_1.Role)
    ], Member.prototype, "role", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.Percentage),
        (0, tslib_1.__metadata)("design:type", utils_1.Percentage)
    ], Member.prototype, "certs", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.Percentage),
        (0, tslib_1.__metadata)("design:type", utils_1.Percentage)
    ], Member.prototype, "tasks", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: MembershipPosition }),
        (0, class_validator_1.IsInstance)(MembershipPosition, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Member.prototype, "membershipPositions", void 0);
    Search.Member = Member;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Member }),
        (0, class_validator_1.IsInstance)(Member, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "members", void 0);
    Search.Response = Response;
})(Search = exports.Search || (exports.Search = {}));

import { UpdateReminderRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useUpdateReminder = (afterSubmit?: () => unknown) => {
  const [update, updateIsLoading] = useHandler(
    (api) => api.reminder().updateReminder,
    {
      invalidates: () => [['REMINDER_LIST']],
      always: {
        onSuccess: async () => {
          if (afterSubmit) {
            await afterSubmit();
          }
        },
      },
    },
  );

  return {
    update: (data: Entity.Attributes<UpdateReminderRequest>) => update(data),
    updateIsLoading,
  };
};

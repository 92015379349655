/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Stack,
  Table,
  Td,
  Tr,
  Tbody,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../../../auth';
import { useMyFlows } from '../../../../hooks/data/flow/useFlows';

export const FlowHistory: React.FC<{
  flowDesignId: string;
  memberId?: string;
  currentFlowId: string;
}> = ({ memberId, flowDesignId, currentFlowId }) => {
  const businessId = auth.useCurrentBusinessId();

  const history = useHistory();

  const flows = useMyFlows({
    assignedAs: 'ANY',
    requirements: 'ALL',
    subjectMemberId: memberId,
    flowDesignId,
  });

  if (!flows.data) {
    return null;
  }

  const getFlowLink = (flowId: string) => {
    if (businessId) {
      return `/business/flows/${flowId}`;
    }
    return `/user/flows/${flowId}`;
  };

  return (
    <Box maxH="200px" overflow="auto">
      <Table size="sm">
        <Tbody>
          {flows.data.flows.map((f) => {
            const notFocused = f.flowId !== currentFlowId;
            return (
              <Tr
                role={notFocused ? 'button' : undefined}
                bg={notFocused ? undefined : 'gray.50'}
                _hover={
                  notFocused
                    ? {
                        bg: 'brand.light-gray',
                      }
                    : {}
                }
                onClick={
                  notFocused
                    ? () => history.push(getFlowLink(f.flowId))
                    : undefined
                }
                key={f.flowId}
              >
                <Td>
                  <Stack>
                    <HStack>
                      <Text>{f.name}</Text>
                    </HStack>
                    <Text fontSize="sm" color="brand.dark-gray">
                      {f.getLastStateChange()?.toTimeFromNow()}
                    </Text>
                  </Stack>
                </Td>

                <Td>
                  <Stack>
                    <Text>{f.getTag()}</Text>
                    <Text fontSize="sm" color="brand.dark-gray">
                      {f.getLastStateChange()?.toDMY()}
                    </Text>
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

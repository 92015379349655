var parts = ['field', 'addon'];
function variantOutline() {
    return {
        field: {
            border: '1px solid',
            borderColor: 'brand.gray',
            bg: 'inherit',
            _hover: {
                borderColor: 'brand.darkish-gray',
            },
            _readOnly: {
                boxShadow: 'none !important',
                userSelect: 'all',
            },
            _disabled: {
                bg: 'brand.gray',
                borderColor: 'brand.gray',
                color: 'brand.black',
                opacity: 1,
            },
            _invalid: {
                borderColor: 'brand.red',
                boxShadow: "0 0 0 1px brand.red",
            },
            _focus: {
                bg: 'brand.focus-blue',
                borderColor: 'none',
                boxShadow: 'none',
                caretColor: 'brand.blue',
            },
        },
        addon: {
            border: '1px solid',
            borderColor: 'inherit',
            bg: 'inherit',
        },
    };
}
var variants = {
    outline: variantOutline,
};
var defaultProps = {
    size: 'md',
    variant: 'outline',
};
export default {
    parts: parts,
    variants: variants,
    defaultProps: defaultProps,
};

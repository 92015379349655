import { lazy, Suspense } from 'react';
import { LoadingState } from '../../components';

const SubscriptionWizard = lazy(
  () => import('../../billing/SubscriptionWizard/SubscriptionWizard'),
);

export const Subscribe = () => (
  <Suspense fallback={<LoadingState layoutProps={{ display: 'none' }} />}>
    <SubscriptionWizard />
  </Suspense>
);

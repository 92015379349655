import * as React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { track } from '../../libs/track';

export const NotFoundPage: React.FC = () => {
  const { onClose } = useDisclosure();
  track.useOnMount('Viewed 404 Page');

  return (
    <Modal closeOnOverlayClick={false} isOpen isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader>Page does not exist</ModalHeader>
        <ModalBody pb={6}>
          <Stack spacing={2}>
            <Text>
              You are trying to access a page that does not exist. It is
              possible that the URL may have been incorrectly typed.
            </Text>
            <Text>Please contact us for support if you need.</Text>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button as={Link} to="/" onClick={onClose}>
            Dashboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/* eslint-disable @typescript-eslint/ban-types */
import { colors } from './colors';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { FormLabel } from './formLabel';
import Input from './input';
import { Heading } from './heading';
import { Radio } from './radio';
import Select from './select';
import { Text } from './text';
import { Textarea } from './textarea';
import { layerStyles } from './layer';
var fonts = {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    mono: 'Menlo, monospace',
};
var styles = {
    global: {
        body: {
            bg: 'gray.50',
        },
    },
};
export var rawTheme = {
    colors: colors,
    fonts: fonts,
    layerStyles: layerStyles,
    styles: styles,
    components: {
        Button: Button,
        Checkbox: Checkbox,
        FormLabel: FormLabel,
        Heading: Heading,
        Input: Input,
        Radio: Radio,
        Select: Select,
        Text: Text,
        Textarea: Textarea,
    },
};

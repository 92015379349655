import * as React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { useMenuItems } from '../menuItems';
import { DesktopMenuItem } from '../../../components/MainMenuItem';
import { auth } from '../../../auth';

export const SideBar: React.FC = () => {
  const manager = auth.useCurrentBusinessManager();

  const context = manager ? 'business' : 'user';
  return (
    <Box
      bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
      h="100vh"
      pl={2}
      pt={6}
      overflowX="scroll"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Stack spacing={1} w="100%" pl={2} pt={{ base: 0, large: 6 }}>
        {useMenuItems({
          context,
          showMobileItems: false,
          role: manager?.role,
        }).map(({ label, route, icon, subMenu }) => (
          <DesktopMenuItem
            subMenu={subMenu}
            key={label}
            label={label}
            route={route}
            Icon={icon}
          />
        ))}
      </Stack>
    </Box>
  );
};

/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  IconButton,
  Text,
  Button,
  Stack,
  useDisclosure,
  MenuList,
  Menu,
  Icon,
  MenuItem,
  MenuButton,
  HStack,
  Spacer,
  Heading,
  Flex,
  TabPanel,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  Spinner,
  Badge,
} from '@chakra-ui/react';
import {
  StripeBillingAccountListingResponse,
  BillingAccountSubscriptionResponse,
} from '@zap-onboard/api-client';
import React from 'react';
import { FaRegTrashAlt, FaStripe } from 'react-icons/fa';
import { MdMoreVert } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { DeleteModal, EmptyState, Spinner as BigSpinner } from '../components';
import { capitalizeFirstLetter } from '../helpers';
import { onError } from '../libs/errorLib';
import { PageLayoutContainer } from '../pages';
import { useAPI } from '../services/API';
import { BillingDetailsModal } from './BillingDetailsModal';
import { ErrorDisplay } from './ErrorDisplay';
import {
  useBillingAccountSubscriptions,
  useBillingPaymentSummary,
} from './hooks/useBillingAccountSubscriptions';
import { useBillingMutations } from './hooks/useBillingMutations';
import { useMyBillingAccounts } from './hooks/useMyBillingAccounts';
import { PaymentMethodModal } from './PaymentMethodModal';
import { PaymentsPanel } from './PaymentsPanel';
import { XeroAccountListing } from './XeroAccountListing';

export const BillingPage = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const myBillingAccounts = useMyBillingAccounts();

  return (
    <PageLayoutContainer>
      <Box maxWidth={800}>
        <Heading variant="page">Billing Accounts</Heading>
        <ErrorDisplay
          title="Could not load your billing accounts"
          {...myBillingAccounts}
        />

        {myBillingAccounts.isLoading && <BigSpinner />}
        <Stack spacing={16}>
          {myBillingAccounts.data?.map((a) => (
            <React.Fragment key={a.billingAccountId}>
              {a.allCases({
                STRIPE: (acc) => <AccountListing billingAccount={acc} />,
                XERO: (acc) => <XeroAccountListing billingAccount={acc} />,
              })}
            </React.Fragment>
          ))}
        </Stack>
        {myBillingAccounts.data?.length === 0 && (
          <EmptyState message="You do not have any billing accounts. You can create one by starting a subscription on the settings page" />
        )}
      </Box>
    </PageLayoutContainer>
  );
};

const AccountListing = (args: {
  billingAccount: StripeBillingAccountListingResponse;
}) => {
  const a = args.billingAccount;

  const api = useAPI();
  const qc = useQueryClient();
  const closeAccount = async () => {
    await api
      .billing()
      .closeBillingAccount({ billingAccountId: a.billingAccountId })
      .tap(() => {
        qc.invalidateQueries('MY_BILLING_ACCOUNTS');
      }, onError);
  };

  const summary = useBillingPaymentSummary({
    billingAccountId: args.billingAccount.billingAccountId,
  });

  return (
    <Box layerStyle="base">
      <HStack p={6}>
        <HStack>
          <Heading variant="subSection">{a.name ?? a.billingAccountId}</Heading>
          {!!a.paidUntil && (
            <Badge>
              Paid Until {a.paidUntil.toAbsoluteDate().asShortPretty()}
            </Badge>
          )}
        </HStack>
        <Spacer />
        <Icon color="brand.darker-gray" w={10} h={10} as={FaStripe} />
        {!summary.data?.upcomingInvoice && (
          <Menu>
            <MenuButton
              as={IconButton}
              size="base"
              variant="base"
              icon={<MdMoreVert size={32} />}
              alignContent="center"
              color="brand.darker-gray"
              _hover={{ opacity: 0.8 }}
            />
            <MenuList>
              <MenuItem onClick={closeAccount}>
                <Icon as={FaRegTrashAlt} w={6} h={6} />
                <Text marginLeft={1}>Close account</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>

      <Tabs variant="line" size="sm" isLazy>
        <TabList flexDir={{ sm: 'row', base: 'column' }}>
          <Tab>Account</Tab>
          <Tab>Invoices</Tab>
          <Tab>Linked businesses</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={6}>
            <AccountDetailPanel billingAccount={a} />
          </TabPanel>
          <TabPanel p={6}>
            <PaymentsPanel billingAccount={a} />
          </TabPanel>
          <TabPanel p={6}>
            <LinkedBusinessPanel billingAccount={a} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const AccountDetailPanel = ({
  billingAccount: a,
}: {
  billingAccount: StripeBillingAccountListingResponse;
}) => {
  const paymentMethodDisclosure = useDisclosure();
  const billingDetailsDisclosure = useDisclosure();
  const summary = useBillingPaymentSummary({
    billingAccountId: a.billingAccountId,
  });

  if (!summary.isFetched) {
    return <Spinner />;
  }
  const errors = (
    <ErrorDisplay title="Could not load your summary" {...summary} />
  );
  if (summary.data == null) {
    return errors;
  }
  return (
    <>
      <BillingDetailsModal
        billingAccount={a}
        disclosure={billingDetailsDisclosure}
        defaults={{
          abn: a.abn?.asJSON(),
          name: a.name,
          email: a.email?.asJSON(),
          phoneNumber: a.phoneNumber?.asJSON(),
        }}
      />

      {errors}
      <Stack spacing={6}>
        {summary.data.price && (
          <Box p={3} layerStyle="outline">
            <Heading size="md" fontWeight="extrabold">
              {summary.data.price.name}
            </Heading>
            <Text fontSize="sm">{summary.data.price.description}</Text>
          </Box>
        )}

        <Box p={3} layerStyle="outline">
          <Text mb={6} fontWeight="bold" fontSize="sm">
            Invoice details
          </Text>
          <Flex flexDir={{ sm: 'row', base: 'column' }}>
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Account name
              </Text>
              <Text>{a.name ?? '-'}</Text>

              <Text fontSize="xs" fontWeight="bold">
                Contact number
              </Text>
              <Text>{a.phoneNumber?.asJSON() ?? '-'}</Text>
            </Box>

            <Spacer maxWidth={12} />
            <Box>
              <Text fontSize="xs" fontWeight="bold">
                Email
              </Text>
              <Text>{a.email?.asJSON() ?? '-'}</Text>
              <Text fontSize="xs" fontWeight="bold">
                ABN
              </Text>
              <Text>{a.abn?.asJSON() ?? '-'}</Text>
            </Box>
          </Flex>
          <Button mt={4} size="xs" onClick={billingDetailsDisclosure.onToggle}>
            Edit
          </Button>
        </Box>

        <Box p={3} layerStyle="outline">
          <Text mb={6} fontWeight="bold" fontSize="sm">
            Payment method
          </Text>

          <Text fontWeight="bold" fontSize="md">
            {capitalizeFirstLetter(a.stripePaymentMethod?.brand ?? 'card')} ···
            ···· ··· {a.stripePaymentMethod?.last4Digits ?? 'XXXX'}
          </Text>

          <Text fontSize="sm">
            Expires at {a.stripePaymentMethod?.expiresAt?.asMMYY() ?? 'Unknown'}
          </Text>
          <Button mt={4} size="xs" onClick={paymentMethodDisclosure.onToggle}>
            Edit
          </Button>
        </Box>
      </Stack>

      <PaymentMethodModal
        billingAccount={a}
        disclosure={paymentMethodDisclosure}
      />
    </>
  );
};

const LinkedBusinessPanel = ({
  billingAccount,
}: {
  billingAccount: StripeBillingAccountListingResponse;
}) => {
  const subscriptions = useBillingAccountSubscriptions({
    billingAccountId: billingAccount.billingAccountId,
  });

  if (!subscriptions.isFetched) {
    return <Spinner />;
  }
  const errors = (
    <ErrorDisplay title="Could not load your subscription" {...subscriptions} />
  );
  if (subscriptions.data == null) {
    return errors;
  }

  return (
    <Stack spacing={6}>
      <Stack spacing={3}>
        {subscriptions.data.length > 0 ? (
          subscriptions.data?.map((sub) => (
            <BusinessRow
              key={sub.businessId}
              billingAccountId={billingAccount.billingAccountId}
              subscription={sub}
            />
          ))
        ) : (
          <Text>No linked businesses</Text>
        )}
      </Stack>
    </Stack>
  );
};

const BusinessRow = ({
  billingAccountId,
  subscription: { businessId, businessName },
}: {
  billingAccountId: string;
  subscription: BillingAccountSubscriptionResponse;
}) => {
  const disclosure = useDisclosure();
  const { unsubscribe } = useBillingMutations();
  return (
    <HStack p={3} layerStyle="outline" key={businessId}>
      <Text fontWeight="bold">{businessName}</Text>
      <Spacer />
      <Button onClick={disclosure.onOpen} variant="outline">
        Remove from billing account
      </Button>
      <DeleteModal
        {...disclosure}
        modalBodyText="Your account will be credited for the remaining subscription period at the end of the current billing cycle."
        modalHeaderText={`Remove ${businessName} from your Subscription`}
        confirmText="Remove"
        callBack={() =>
          unsubscribe({
            businessId,
            billingAccountId,
          })
        }
      />
    </HStack>
  );
};

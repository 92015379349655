"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MYOBClient = void 0;
const models_1 = require("./models");
const handler_1 = require("../utils/handler");
class MYOBClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.beginOAuthAuthentication = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/myob/account/oauth/authorize/begin')
            .get((d) => models_1.MYOBOAuth.Begin.Response.create(d)));
        this.completeOAuthAuthentication = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/myob/account/oauth/authorize/complete')
            .bodyJSON(models_1.MYOBOAuth.Complete.Request.create(args))
            .post());
        this.getCompanyFiles = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/myob/company-files')
            .get((d) => models_1.MYOBCompanyFileList.Response.create(d)));
        this.createMyobCompanyFileAuthorization = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/myob/company-file/authorize')
            .bodyJSON(models_1.MYOBCompanyFileAuthorization.Create.Request.create(args))
            .post());
        this.deleteMyobCompanyFileAuthorization = (0, handler_1.handler)(this, async (businessId) => this.http
            .request()
            .to(`/myob/company-file/authorize/${businessId}`)
            .delete());
        this.createEmployeeInMyob = (0, handler_1.handler)(this, async (memberId) => this.http.request().to(`/myob/employee/${memberId}`).post());
    }
}
exports.MYOBClient = MYOBClient;

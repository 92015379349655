"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSubmissionResponse = exports.FormSubmissionItem = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const MultipleChoiceFormItem_1 = require("./MultipleChoiceFormItem");
const FileUploadFormItem_1 = require("./FileUploadFormItem");
const TextBlockFormItem_1 = require("./TextBlockFormItem");
const YoutubeVideoFormItem_1 = require("./YoutubeVideoFormItem");
const TextQuestionFormItem_1 = require("./TextQuestionFormItem");
const ResponseBase_1 = require("../../../ResponseBase");
const VideoFormItem_1 = require("./VideoFormItem");
class FormSubmissionItem extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [
        MultipleChoiceFormItem_1.MultipleChoiceFormSubmissionItem,
        TextQuestionFormItem_1.TextQuestionFormSubmissionItem,
        FileUploadFormItem_1.FileUploadFormSubmissionItem,
        TextBlockFormItem_1.TextBlockFormSubmissionItem,
        YoutubeVideoFormItem_1.YoutubeVideoFormSubmissionItem,
        VideoFormItem_1.VideoFormSubmissionItem,
    ],
}) {
}
exports.FormSubmissionItem = FormSubmissionItem;
class FormSubmissionResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: FormSubmissionItem }),
    (0, class_validator_1.IsInstance)(FormSubmissionItem, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], FormSubmissionResponse.prototype, "items", void 0);
exports.FormSubmissionResponse = FormSubmissionResponse;

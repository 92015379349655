import * as React from 'react';
import {
  Center,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  Text,
  Avatar,
} from '@chakra-ui/react';

import { Spinner } from '../../../../components/Spinner';
import { biz } from '@zap-onboard/api-client';

type ContentProps = {
  business: biz.PublicBusinessDetailsResponse;
  isLoading: boolean;
  handleAcceptInvite: () => unknown;
};

export const UserInvitationContent: React.FC<ContentProps> = ({
  business,
  isLoading,
  handleAcceptInvite,
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Center h="100%">
      <Stack mt={3} px={3} spacing={4} align="center">
        <Avatar
          sx={{
            '& img': {
              objectFit: 'contain',
            },
          }}
          background={business.logo ? 'none' : undefined}
          borderRadius="0"
          size="xl"
          name={business.name}
          src={business.logo?.url}
        />
        <Heading as="h1" fontSize="xl">{`${business.name}`}</Heading>
        <>
          <Text>{`You have been invited to join ${business.name}`}</Text>
          <ButtonGroup spacing={3}>
            <Button
              data-testid="acceptInviteButton"
              onClick={handleAcceptInvite}
            >
              Accept
            </Button>
          </ButtonGroup>
        </>
      </Stack>
    </Center>
  );
};

import { z } from '../../../../../../../helpers/schema';

const regular = z.object({
  taxScaleType: z.literal('REGULAR').or(z.literal('HORTICULTURISTORSHEARER')),
});

const actorsArtistsEntertainers = z.object({
  taxScaleType: z.literal('ACTORSARTISTSENTERTAINERS'),
  workCondition: z
    .literal('PROMOTIONAL')
    .or(z.literal('THREELESSPERFORMANCESPERWEEK'))
    .or(z.literal('NONE')),
});

const seniorPensioners = z.object({
  taxScaleType: z.literal('SENIORORPENSIONER'),
  seniorMaritalStatus: z
    .literal('MEMBEROFCOUPLE')
    .or(z.literal('MEMBEROFILLNESSSEPARATEDCOUPLE'))
    .or(z.literal('SINGLE')),
});

const foreign = z.object({
  taxScaleType: z.literal('FOREIGN'),
});

const taxScaleTypes = regular
  .or(actorsArtistsEntertainers)
  .or(seniorPensioners)
  .or(foreign);

const salaryAndCloselyHeld = z
  .object({
    incomeType: z.literal('SALARYANDWAGES').or(z.literal('CLOSELYHELDPAYEES')),
    employmentBasis: z
      .literal('FULLTIME')
      .or(z.literal('PARTTIME'))
      .or(z.literal('CASUAL')),
  })
  .and(taxScaleTypes);

const labourHire = z
  .object({
    incomeType: z.literal('LABOURHIRE'),
    employmentBasis: z.literal('LABOURHIRE'),
  })
  .and(taxScaleTypes);

const workingHolidayMaker = z.object({
  incomeType: z.literal('WORKINGHOLIDAYMAKER'),
  taxScaleType: z.literal('WORKINGHOLIDAYMAKER'),
  employmentBasis: z
    .literal('FULLTIME')
    .or(z.literal('PARTTIME'))
    .or(z.literal('CASUAL')),
  countryOfResidence: z.string(),
});

const nonEmployee = z.object({
  incomeType: z.literal('NONEMPLOYEE'),
  employmentBasis: z.literal('NONEMPLOYEE'),
});

export const syncToXeroSchema = z.object({
  sendTaxDetails: z.string(),
  sendSuperDetails: z.string(),
  xeroEmployeeId: z.string().or(z.undefined()),
});

export const stp2Schema = syncToXeroSchema.and(
  salaryAndCloselyHeld.or(labourHire).or(workingHolidayMaker).or(nonEmployee),
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskDesign = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const TaskDesignTypes_1 = require("./TaskDesignTypes");
class TaskDesign extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskDesign.prototype, "taskDesignId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(TaskDesignTypes_1.TaskDesignType),
    (0, tslib_1.__metadata)("design:type", String)
], TaskDesign.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TaskDesign.prototype, "name", void 0);
exports.TaskDesign = TaskDesign;

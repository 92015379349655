"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadTargetType = void 0;
var FileUploadTargetType;
(function (FileUploadTargetType) {
    FileUploadTargetType["PDF"] = "PDF";
    FileUploadTargetType["JPEG"] = "JPEG";
    FileUploadTargetType["PNG"] = "PNG";
    FileUploadTargetType["GIF"] = "GIF";
    FileUploadTargetType["MP4"] = "MP4";
    FileUploadTargetType["CSV"] = "CSV";
})(FileUploadTargetType = exports.FileUploadTargetType || (exports.FileUploadTargetType = {}));

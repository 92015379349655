import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { VisaCheck } from '@zap-onboard/api-client/src/visa/models/VisaCheck';
import { Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';

type NavMenuItemProps = {
  visaCheckId: string;
  visaCheckCreatedDate: string;
  selectedNavMenuItemId: string;
  handleNavMenuItemSelected: Dispatch<SetStateAction<string>>;
};

const NavMenuItem: React.FC<NavMenuItemProps> = ({
  visaCheckId,
  visaCheckCreatedDate,
  selectedNavMenuItemId,
  handleNavMenuItemSelected,
}) => {
  const isSelected = selectedNavMenuItemId === visaCheckId;
  return (
    <Button
      color="brand.black"
      bg={isSelected ? 'gray.400' : 'gray.200'}
      borderRadius="md"
      boxShadow="none"
      width="200px"
      _hover={isSelected ? {} : { textDecoration: 'none', bg: 'gray.300' }}
      onClick={() => handleNavMenuItemSelected(visaCheckId)}
    >
      {visaCheckCreatedDate}
    </Button>
  );
};

type VisaHistoryNavMenuProps = {
  visaChecks?: VisaCheck[];
  selectedNavMenuItemId: string;
  handleNavMenuItemSelected: Dispatch<SetStateAction<string>>;
};

export const VisaHistoryNavMenu: React.FC<VisaHistoryNavMenuProps> = ({
  visaChecks,
  handleNavMenuItemSelected,
  selectedNavMenuItemId,
}) => {
  return (
    <Stack>
      <Heading size="small">Submission History</Heading>
      {visaChecks?.length && visaChecks.length > 0 ? (
        visaChecks.map(({ visaCheckId, createdAt }) => (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, x: -20 }}
          >
            <NavMenuItem
              key={visaCheckId}
              handleNavMenuItemSelected={handleNavMenuItemSelected}
              visaCheckCreatedDate={createdAt.toFormat('D MMM YYYY')}
              visaCheckId={visaCheckId}
              selectedNavMenuItemId={selectedNavMenuItemId}
            />
          </motion.div>
        ))
      ) : (
        <Text>No visa checks found</Text>
      )}
    </Stack>
  );
};

import * as React from 'react';
import {
  Text,
  Box,
  Heading,
  Stack,
  Icon,
  Center,
  HStack,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { FlowDetailTaskManager } from '../../components/Flows/TaskManager';
import { CompleteUserProfile } from '../../components/CompleteUserProfile';
import { Spinner } from '../../components/Spinner';
import { useFlow } from '../../hooks/data/flow/useFlow';
import { track } from '../../libs/track';
import { useHandlerQuery } from '../../hooks/useHandlerQuery';
import { auth } from '../../auth';
import { WorkflowsToAction } from '../../components';
import { MdCheckCircle } from 'react-icons/md';

export const FlowDetailPage: React.FC<{
  context: 'user' | 'business';
  flowId?: string;
}> = ({ context, flowId: passedFlowId }) => {
  const userProfile = auth.useProfile();
  const { flowId: paramFlowId } = useParams<{ flowId: string }>();

  const flowId = passedFlowId ?? paramFlowId;

  const { flow, isLoadingFlow, setFlow } = useFlow({ flowId });

  track.useOnTrue(!!flow, `Viewed Flow as ${context}`, {
    flowName: flow?.name,
    flowId: flow?.flowId,
  });

  const prereqs = useHandlerQuery((api) => api.flows().getMyPrerequisites(), {
    key: ['MY_PREREQUISITES'],
    staleAfter: '15m',
  });

  if (userProfile && prereqs.data && !prereqs.data.satisfiedBy(userProfile)) {
    return (
      <CompleteUserProfile
        onlyFields={prereqs.data.profileAttributes}
        userProfile={userProfile}
      />
    );
  }

  if (isLoadingFlow || !prereqs.data) {
    return <Spinner />;
  }

  if (!flow) {
    return (
      <Stack spacing={8} p={8}>
        <Center>
          <HStack>
            <Center>
              <Icon as={MdCheckCircle} w={8} h={8} color="brand.green" />
            </Center>

            <Box>
              <Heading variant="subsection" size="md">
                Workflow Complete!
              </Heading>
              <Text>
                That workflow is complete or no longer requires your input.
              </Text>
            </Box>
          </HStack>
        </Center>

        <Stack spacing={4} p={4}>
          <WorkflowsToAction heading="Remaining work" context={context} />
        </Stack>
      </Stack>
    );
  }

  return (
    <FlowDetailTaskManager
      context={context}
      flow={flow}
      setFlow={setFlow}
      canEditTask={!flow.isComplete()}
    />
  );
};

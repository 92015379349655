import * as React from 'react';
import { Flex } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

import { InfoBox } from '../../../../InfoBox';

export const TaskCompleted: React.FC = () => (
  <Flex justify="center">
    <InfoBox
      icon={MdCheckCircle}
      iconColor="brand.green"
      heading="Task completed"
      message="This task has been successfully completed. No further action is required."
      w="90%"
    />
  </Flex>
);

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useHandlerQuery } from '../../hooks/useHandlerQuery';

export const useBillingAccountSubscriptions = ({
  billingAccountId,
}: {
  billingAccountId: string;
}) =>
  useHandlerQuery(
    (api) => api.billing().getBillingAccountSubscriptions({ billingAccountId }),
    {
      key: ['BILLING_ACCOUNT_SUBSCRIPTIONS', billingAccountId],
      staleAfter: '15s',
    },
  );

export const useBillingPaymentSummary = ({
  billingAccountId,
}: {
  billingAccountId?: string;
}) =>
  useHandlerQuery(
    (api) =>
      api.billing().getBillingAccountPaymentsSummary({
        billingAccountId: billingAccountId!,
      }),
    {
      enabled: !!billingAccountId,
      key: ['BILLING_ACCOUNT_PAYMENT_SUMMARY', billingAccountId!],
      staleAfter: '15s',
    },
  );

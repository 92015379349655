import React from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { Percentage } from '@zap-onboard/api-client';

type ProgressMeterProps = {
  progress: Percentage;
};

export const ProgressMeter: React.FC<ProgressMeterProps> = ({ progress }) => {
  const circularProgressSize = useBreakpointValue({ base: '40px', md: '48px' });

  return progress.exact() === 100 ? (
    <Icon as={MdCheckCircle} color="brand.green" boxSize={9} />
  ) : (
    <CircularProgress
      value={progress.exact()}
      color="brand.light-blue"
      size={circularProgressSize}
    >
      <CircularProgressLabel>{`${progress.toPlaces(
        0,
      )}%`}</CircularProgressLabel>
    </CircularProgress>
  );
};

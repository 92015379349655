"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MYOBCompanyFileAuthorization = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var MYOBCompanyFileAuthorization;
(function (MYOBCompanyFileAuthorization) {
    let Create;
    (function (Create) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "myobCompanyFileId", void 0);
        Create.Request = Request;
    })(Create = MYOBCompanyFileAuthorization.Create || (MYOBCompanyFileAuthorization.Create = {}));
})(MYOBCompanyFileAuthorization = exports.MYOBCompanyFileAuthorization || (exports.MYOBCompanyFileAuthorization = {}));

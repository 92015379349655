var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, } from '@chakra-ui/react';
export var Simple = function (args) {
    var _a;
    var d = (_a = args.disclosure) !== null && _a !== void 0 ? _a : useDisclosure();
    return (_jsxs(_Fragment, { children: [args.trigger && typeof args.trigger === 'function' && args.trigger(d), args.trigger && typeof args.trigger === 'string' && (_jsx(Button, __assign({}, args._trigger, { onClick: d.onOpen }, { children: args.trigger }), void 0)), _jsxs(Modal, __assign({ scrollBehavior: "inside", closeOnOverlayClick: true }, args._modal, d, { children: [_jsx(ModalOverlay, {}, void 0), _jsxs(ModalContent, __assign({ m: 3 }, args._modalContent, { children: [args.header &&
                                (typeof args.header === 'function' ? (_jsx(ModalHeader, __assign({}, args._header, { children: args.header(d) }), void 0)) : (_jsx(ModalHeader, __assign({}, args._header, { children: args.header }), void 0))), _jsx(ModalCloseButton, { size: "lg" }, void 0), _jsx(ModalBody, __assign({ px: 6, pb: 6, pt: 0 }, args._body, { children: args.children &&
                                    (typeof args.children === 'function'
                                        ? args.children(d)
                                        : args.children) }), void 0), args.footer &&
                                (typeof args.footer === 'function' ? (_jsx(ModalFooter, __assign({}, args._footer, { children: args.footer(d) }), void 0)) : (_jsx(ModalFooter, __assign({}, args._footer, { children: args.footer }), void 0)))] }), void 0)] }), void 0)] }, void 0));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormHelperText, Select as ChakraInput, InputGroup, } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../wrappers/FormControl';
export var SelectInput = function (_a) {
    var name = _a.name, isDisabled = _a.isDisabled, placeholder = _a.placeholder, defaultValue = _a.defaultValue, children = _a.children, helper = _a.helper, leftInputElement = _a.leftInputElement, isReadOnly = _a.isReadOnly, options = _a.options, isLoading = _a.isLoading, onChange = _a.onChange, controlProps = __rest(_a, ["name", "isDisabled", "placeholder", "defaultValue", "children", "helper", "leftInputElement", "isReadOnly", "options", "isLoading", "onChange"]);
    var register = useFormContext().register;
    return (_jsxs(FormControl, __assign({ name: name }, controlProps, { children: [_jsxs(InputGroup, { children: [leftInputElement, _jsx(ChakraInput, __assign({ name: name, isDisabled: isDisabled || isLoading, placeholder: isLoading ? 'Loading...' : placeholder, isReadOnly: isReadOnly, onChange: onChange, defaultValue: defaultValue, variant: (isDisabled !== null && isDisabled !== void 0 ? isDisabled : isReadOnly) ? 'filled' : 'outline', ref: register }, { children: options.map(function (v, i) {
                            var _a = normalizeOption(v), value = _a.value, name = _a.name;
                            return (
                            // eslint-disable-next-line react/no-array-index-key
                            _jsx("option", __assign({ value: value }, { children: name }), value + i));
                        }) }), void 0)] }, void 0), helper && (_jsx(FormHelperText, __assign({ fontSize: "sm", fontStyle: "italic" }, { children: helper }), void 0)), children] }), void 0));
};
function normalizeOption(opt) {
    if (typeof opt === 'string') {
        return { name: opt, value: opt };
    }
    return opt;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientVerification = exports.IndividualClientSubmission = exports.TrustClientSubmission = exports.CompanyClientSubmission = exports.PartnershipClientSubmission = exports.SoleTraderClientSubmission = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const utils_1 = require("../../../utils");
const ResponseBase_1 = require("../../../ResponseBase");
const file_1 = require("../../../file");
// Client verification requirements from the Tax Practioners Board https://drive.google.com/file/d/1oGXlZBoGE2zD1nrUNI-QImjQuDweQ7AD/view?usp=sharing
class BaseSubmission extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], BaseSubmission.prototype, "isRepresentative", void 0);
class SoleTraderClientSubmission extends BaseSubmission {
}
SoleTraderClientSubmission.type = 'SOLE_TRADER';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['SOLE_TRADER']),
    (0, tslib_1.__metadata)("design:type", String)
], SoleTraderClientSubmission.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(60),
    (0, tslib_1.__metadata)("design:type", String)
], SoleTraderClientSubmission.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", utils_1.AustralianAddress)
], SoleTraderClientSubmission.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], SoleTraderClientSubmission.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], SoleTraderClientSubmission.prototype, "representativeVerificationFileClaim", void 0);
exports.SoleTraderClientSubmission = SoleTraderClientSubmission;
class PartnershipClientSubmission extends BaseSubmission {
}
PartnershipClientSubmission.type = 'PARTNERSHIP';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['PARTNERSHIP']),
    (0, tslib_1.__metadata)("design:type", String)
], PartnershipClientSubmission.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(60),
    (0, tslib_1.__metadata)("design:type", String)
], PartnershipClientSubmission.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", utils_1.AustralianAddress)
], PartnershipClientSubmission.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], PartnershipClientSubmission.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], PartnershipClientSubmission.prototype, "nonIndividualVerificationFileClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], PartnershipClientSubmission.prototype, "representativeVerificationFileClaim", void 0);
exports.PartnershipClientSubmission = PartnershipClientSubmission;
class CompanyClientSubmission extends BaseSubmission {
}
CompanyClientSubmission.type = 'COMPANY';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['COMPANY']),
    (0, tslib_1.__metadata)("design:type", String)
], CompanyClientSubmission.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(60),
    (0, tslib_1.__metadata)("design:type", String)
], CompanyClientSubmission.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", utils_1.AustralianAddress)
], CompanyClientSubmission.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], CompanyClientSubmission.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ACN),
    (0, tslib_1.__metadata)("design:type", utils_1.ACN)
], CompanyClientSubmission.prototype, "acn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], CompanyClientSubmission.prototype, "nonIndividualVerificationFileClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], CompanyClientSubmission.prototype, "representativeVerificationFileClaim", void 0);
exports.CompanyClientSubmission = CompanyClientSubmission;
class TrustClientSubmission extends BaseSubmission {
}
TrustClientSubmission.type = 'TRUST';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['TRUST']),
    (0, tslib_1.__metadata)("design:type", String)
], TrustClientSubmission.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(60),
    (0, tslib_1.__metadata)("design:type", String)
], TrustClientSubmission.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", utils_1.AustralianAddress)
], TrustClientSubmission.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], TrustClientSubmission.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], TrustClientSubmission.prototype, "nonIndividualVerificationFileClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], TrustClientSubmission.prototype, "representativeVerificationFileClaim", void 0);
exports.TrustClientSubmission = TrustClientSubmission;
class IndividualClientSubmission extends BaseSubmission {
}
IndividualClientSubmission.type = 'INDIVIDUAL';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['INDIVIDUAL']),
    (0, tslib_1.__metadata)("design:type", String)
], IndividualClientSubmission.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(60),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], IndividualClientSubmission.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AustralianAddress),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", utils_1.AustralianAddress)
], IndividualClientSubmission.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], IndividualClientSubmission.prototype, "dateOfBirth", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], IndividualClientSubmission.prototype, "representativeVerificationFileClaim", void 0);
exports.IndividualClientSubmission = IndividualClientSubmission;
class ClientVerification extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [
        IndividualClientSubmission,
        CompanyClientSubmission,
        PartnershipClientSubmission,
        SoleTraderClientSubmission,
        TrustClientSubmission,
    ],
}) {
}
exports.ClientVerification = ClientVerification;

import * as React from 'react';
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { MdInfoOutline } from 'react-icons/md';

import {
  ABN,
  AccountNumber,
  BSB,
  ContactNumber,
  FileClaim,
  FileUploadFor,
  FileUploadTargetType,
  SuperChoice,
  SuperChoiceTask as APISuperChoiceTask,
} from '@zap-onboard/api-client';

import { InfoBox } from '../../../../../InfoBox';
import { DocumentUpload } from '../../../../../DocumentUpload';
import { z } from '../../../../../../helpers/schema';
import { inputs } from '@zap-onboard/web-components';
import { SuperTypes } from '../SuperType';

type SMSFProps = {
  isDisabled: boolean;
  task?: APISuperChoiceTask;
  setIsUploading: (arg: boolean) => void;
};

export const SMSFSchema = z.object({
  superChoiceType: z.enum([SuperTypes.SMSF]),
  fundABN: z.valueObject(z.string(), ABN),
  fundName: z.string().max(76, { message: 'Max 76 characters' }),
  fundAddress: z.string().min(1, { message: 'Required' }),
  fundPhoneNumber: z.valueObject(z.string(), ContactNumber),
  fundESA: z
    .string()
    .min(1, { message: 'Required' })
    .max(16, { message: 'Max 16 characters' }),
  fundBSB: z.valueObject(z.string(), BSB),
  fundAccountNumber: z.valueObject(z.string(), AccountNumber),
  isTrusteeOrDirector: z.boolean(),
  requiredDocumentationClaim: z.instanceof(FileClaim, {
    message: 'This documentation is required',
  }),
});

export const getSMSFDefaults = (
  choice?: SuperChoice,
): Omit<z.infer<typeof SMSFSchema>, 'superChoiceType'> => {
  if (choice?.value?.type !== 'SELF_MANAGED_SUPER_FUND') {
    return {
      fundAccountNumber: '',
      fundABN: '',
      fundBSB: '',
      fundESA: '',
      fundPhoneNumber: '',
      fundAddress: '',
      fundName: '',
      isTrusteeOrDirector: false,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      requiredDocumentationClaim: undefined!,
    };
  }
  const {
    fundName,
    fundAddress,
    fundPhoneNumber,
    fundESA,
    fundBSB,
    fundABN,
    fundAccountNumber,
    isTrusteeOrDirector,
    requiredDocumentationClaim,
  } = choice.value;
  return {
    fundName,
    fundAddress,
    fundPhoneNumber: fundPhoneNumber?.asJSON(),
    fundESA,
    fundBSB: fundBSB?.asJSON(),
    fundABN: fundABN?.asJSON(),
    fundAccountNumber: fundAccountNumber?.asJSON(),
    isTrusteeOrDirector,
    requiredDocumentationClaim,
  };
};

export const SMSF: React.FC<SMSFProps> = ({
  isDisabled,
  task,
  setIsUploading,
}) => {
  const { control, errors, register } = useFormContext();
  const superChoice = task?.employeeSubmission?.superChoice?.value;

  return (
    <Stack spacing={4}>
      <Stack>
        <Heading variant="subSection">
          Nominating your self-managed super fund (SMSF)
        </Heading>
        <Text>
          You will need current details from your SMSF trustee to complete this
          item.
        </Text>
      </Stack>
      <Stack spacing={6}>
        <inputs.TextInput
          label="Fund ABN"
          name="superChoice.fundABN"
          placeholder="Your fund ABN"
          isDisabled={isDisabled}
        />

        <inputs.TextInput
          label="Fund Name"
          name="superChoice.fundName"
          placeholder="Your fund name"
          isDisabled={isDisabled}
        />

        <inputs.TextInput
          label="Fund Address"
          name="superChoice.fundAddress"
          placeholder="Your fund address"
          isDisabled={isDisabled}
        />

        <inputs.TextInput
          label="Fund phone"
          name="superChoice.fundPhoneNumber"
          placeholder="Your fund phone"
          isDisabled={isDisabled}
        />

        <inputs.TextInput
          label="Fund ESA"
          name="superChoice.fundESA"
          placeholder="Your fund ESA"
          isDisabled={isDisabled}
        />

        <Stack>
          <Text fontWeight="bold">Fund bank account</Text>
          <Stack>
            <inputs.TextInput
              label="BSB code"
              name="superChoice.fundBSB"
              placeholder="Your BSB code"
              isDisabled={isDisabled}
            />

            <inputs.TextInput
              label="Account number"
              isDisabled={isDisabled}
              name="superChoice.fundAccountNumber"
              placeholder="Your account number"
            />
          </Stack>
        </Stack>
      </Stack>

      <InfoBox
        icon={MdInfoOutline}
        heading="Required documentation"
        message="You need to attach a document confirming the SMSF is an ATO regulated super fund. You can locate and print a copy of the compliance status for your SMSF by searching using the ABN or fund name in the Super Fund Lookup service at https://superfundlookup.gov.au"
      />

      <Stack spacing={3}>
        <Text>
          If you are the trustee, or a director of the corporate trustee you can
          confirm that your SMSF will accept contributions from your employer by
          making the following declaration:
        </Text>
        <FormControl isInvalid={!!errors?.superChoice?.isTrusteeOrDirector}>
          <Checkbox
            name="superChoice.isTrusteeOrDirector"
            isDisabled={isDisabled}
            ref={register({
              validate: (value) => value || 'Please accept',
            })}
            defaultChecked={
              superChoice?.type === SuperTypes.SMSF
                ? superChoice.isTrusteeOrDirector
                : undefined
            }
          >
            I am the trustee, or a director of the corporate trustee of the SMSF
            and I declare that the SMSF will accept contributions from my
            employer.
          </Checkbox>
          <FormErrorMessage>
            {errors?.superChoice?.isTrusteeOrDirector?.message}
          </FormErrorMessage>
        </FormControl>
        <Text>
          If you are not the trustee, or a director of the corporate trustee of
          the SMSF, then you must attach a letter from the trustee confirming
          that the fund will accept contributions from your employer.
        </Text>
      </Stack>

      <FormControl
        isInvalid={!!errors?.superChoice?.requiredDocumentationClaim}
      >
        <Stack spacing={2}>
          <FormLabel>Documentation</FormLabel>
          {task && (
            <DocumentUpload
              uploadedFor={{ type: FileUploadFor.TASK, taskId: task.taskId }}
              isReadOnly={isDisabled}
              allowedTypes={Object.values(FileUploadTargetType)}
              setIsUploading={setIsUploading}
              controlled={{
                name: 'superChoice.requiredDocumentationClaim',
                rules: { required: 'Documentation required' },
                control,
                defaultValue:
                  superChoice?.type === SuperTypes.SMSF
                    ? superChoice.requiredDocumentationClaim
                    : null,
              }}
            />
          )}
        </Stack>
        <FormErrorMessage>
          {errors?.superChoice?.requiredDocumentationClaim?.message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
};

import { onError } from '../../../../libs/errorLib';
import { useHandler } from '../../../useHandlerMutation';

export function useInviteMember({
  memberId,
  afterInvite,
}: {
  memberId: string;
  afterInvite?: () => unknown;
}) {
  const [inviteMember, isLoading] = useHandler(
    (api) => api.membership().invitation.sendToMember,
    {
      invalidates: () => [['MEMBER', memberId], ['MEMBER_SEARCH']],
      always: {
        onError,
        onSuccess: () => {
          if (afterInvite) {
            afterInvite();
          }
        },
      },
    },
  );

  return {
    inviteMember,
    isLoading,
  };
}

import { useHandlerQuery } from '../../useHandlerQuery';

export const useMYOBCompanyFiles = () => {
  const { data, isLoading } = useHandlerQuery(
    (api) => api.myob().getCompanyFiles(),
    {
      key: ['MYOB_COMPANY_FILE_LIST'],
      retry: 0,
    },
  );
  return {
    companyFiles: data,
    isLoadingCompanyFiles: isLoading,
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUPayroll = void 0;
var AUPayroll;
(function (AUPayroll) {
    /**
     * NONEMPLOYEE intentionally not included.
     */
    AUPayroll.IncomeTypes = [
        {
            name: 'Salary and wages',
            code: 'SALARYANDWAGES',
            help: `Most STP reporting will use this income type. It covers the most common kinds of payments to an employee like regular salary and wages.\n If the payments you are making to your employee don't belong in one of the other specific income types, use the SAW income type.`,
        },
        {
            name: 'Working holiday makers',
            code: 'WORKINGHOLIDAYMAKER',
            help: `This income type tells us about the income a person has earned while they were a working holiday maker. Use this income type when you're making payments to a person that is a working holiday maker. This means they're a temporary visitor to Australia holding a subclass 417 (working holiday) or subclass 462 (work and holiday) visa.`,
        },
        {
            code: 'CLOSELYHELDPAYEES',
            name: 'Closely held payees',
            help: `If you have 19 or fewer employees and are using the concession available for reporting closely held payees through STP in relation to the individual, then you must use this income type to tell us that you're using the concession.\n If you are not using the concession available for reporting closely held payees, then you don't need to use the CHP income type and may use the SAW income type instead. This includes where you aren't eligible for the concession or have chosen not to use it.`,
        },
        {
            code: 'LABOURHIRE',
            name: 'Labour hire',
            help: `Use this income type to report payments by a labour hire firm that arranges for persons to perform work or services, or performances, directly for their clients.\nThis income type relates to contractors only. It doesn't include amounts paid to employees of labour hire firms. Employees of labour hire firms should be reported as the relevant income type, such as SAW.`,
        },
        {
            code: 'NONEMPLOYEE',
            name: 'Non-employee',
            help: `Non-employee`,
        },
    ];
    AUPayroll.EmploymentBasis = [
        {
            code: 'FULLTIME',
            name: 'Full time',
        },
        {
            code: 'PARTTIME',
            name: 'Part time',
        },
        {
            code: 'CASUAL',
            name: 'Casual',
        },
        {
            code: 'LABOURHIRE',
            name: 'Labour hire',
        },
        {
            code: 'NONEMPLOYEE',
            name: 'Non-employee',
        },
    ];
    AUPayroll.EmploymentTypes = [
        {
            code: 'EMPLOYEE',
            name: 'Employee',
        },
        {
            code: 'CONTRACTOR',
            name: 'Contractor',
        },
    ];
    /**
     * Not allowed when TFND is NOTSELECTED
     */
    AUPayroll.TaxScaleTypes = [
        {
            code: 'REGULAR',
            name: 'Regular',
        },
        {
            code: 'ACTORSARTISTSENTERTAINERS',
            name: 'Actors, artists, entertainers',
        },
        {
            code: 'HORTICULTURISTORSHEARER',
            name: 'Horticulturist or shearers',
        },
        {
            code: 'SENIORORPENSIONER',
            name: 'Senior or pensioner',
        },
        {
            code: 'WORKINGHOLIDAYMAKER',
            name: 'Working holiday maker',
        },
        {
            code: 'FOREIGN',
            name: 'Foreign',
            help: 'Only valid when the employee is a Foreign Resident.',
        },
    ];
    /**
     * Only required for ACTORSARTISTSENTERTAINERS
     */
    AUPayroll.WorkConditions = [
        {
            code: 'PROMOTIONAL',
            name: 'Promotional',
        },
        {
            code: 'THREELESSPERFORMANCESPERWEEK',
            name: 'Three or less performances per week',
        },
        {
            code: 'NONE',
            name: 'None',
        },
    ];
    /**
     * Only required for SENIORORPENSIONER scale
     */
    AUPayroll.SeniorMaritalStatus = [
        {
            code: 'MEMBEROFCOUPLE',
            name: 'Member of a couple',
        },
        {
            code: 'MEMBEROFILLNESSSEPARATEDCOUPLE',
            name: 'Member of an illness separated couple',
        },
        {
            code: 'SINGLE',
            name: 'Single',
        },
    ];
})(AUPayroll = exports.AUPayroll || (exports.AUPayroll = {}));

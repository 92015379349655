"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactList = exports.Contact = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const XeroContact_1 = require("../../xero/XeroContact");
const ResponseBase_1 = require("../../ResponseBase");
class Contact extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Contact.prototype, "contactId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Contact.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], Contact.prototype, "companyNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Contact.prototype, "isSupplier", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Contact.prototype, "isCustomer", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Contact.prototype, "isArchived", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(XeroContact_1.XeroContactListing),
    (0, tslib_1.__metadata)("design:type", XeroContact_1.XeroContactListing)
], Contact.prototype, "xeroContact", void 0);
exports.Contact = Contact;
class ContactList extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: Contact }),
    (0, class_validator_1.IsInstance)(Contact, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], ContactList.prototype, "contacts", void 0);
exports.ContactList = ContactList;

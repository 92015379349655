"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteList = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
var InviteList;
(function (InviteList) {
    class Item extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "userId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "businessName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Item.prototype, "userName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Item.prototype, "sentAt", void 0);
    InviteList.Item = Item;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Item }),
        (0, class_validator_1.IsInstance)(Item, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "items", void 0);
    InviteList.Response = Response;
})(InviteList = exports.InviteList || (exports.InviteList = {}));

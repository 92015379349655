import { useHandlerQuery } from '../..';

export const useFlowDesignsLinkedToPosition = (
  positionId: string | undefined,
) =>
  useHandlerQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (api) => api.position().getFlowDesignsLinkedToPosition(positionId!),
    {
      enabled: !!positionId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      key: ['POSITION_WORKFLOWS', positionId!],
    },
  );

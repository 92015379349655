/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Stack,
  Table,
  Td,
  Tr,
  Tbody,
  HStack,
  Text,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import { TaskActionType } from '@zap-onboard/api-client';
import React from 'react';
import { MdCheck, MdVisibility } from 'react-icons/md';
import { useTaskActivity } from '../../../../hooks/data/flow/useTaskActivity';

const statusMap = {
  [TaskActionType.META_SKIP]: 'Skipped',
  [TaskActionType.FORM_SUBMIT]: 'Submitted',
  [TaskActionType.RAW_TEXT_ACCEPT]: 'Accepted',
  [TaskActionType.PROFILE_COMPLETE]: 'Completed',
  [TaskActionType.ESIGN_SIGN_ACTION]: 'Signed',
  [TaskActionType.TEMPLATE_SIGN]: 'Signed',
  [TaskActionType.TFND_PAYEE_SUBMISSION]: 'Submitted',
  [TaskActionType.SUPER_CHOICE_EMPLOYEE_SUBMISSION]: 'Submitted',
  [TaskActionType.DEPUTY_LINK_EMPLOYEE_PERFORM]: 'Linked',
  [TaskActionType.XERO_SYNC_EMPLOYEE_PERFORM]: 'Synced to Xero',
  [TaskActionType.BANK_ACCOUNT_SUBMISSION]: 'Submitted',
  [TaskActionType.ESIGN_ATTACH_DOCUMENT]: 'Attached Document',
  [TaskActionType.CLIENT_VERIFICATION_SUBMISSION]: 'Submitted',
  [TaskActionType.CLIENT_VERIFICATION_APPROVAL]: 'Approval',
  [TaskActionType.PROOF_OF_IDENTITY_SUBMISSION]: 'Submitted',
  [TaskActionType.PROOF_OF_IDENTITY_APPROVAL]: 'Approval',
  [TaskActionType.VISA_DETAILS_COMPLETED]: 'Completed',
  [TaskActionType.VISA_DETAILS_SUBMISSION]: 'Submitted',
} as const;

const actionIconMap = {
  VIEWED: MdVisibility,
  ACTIONED: MdCheck,
};

export const TaskActivity: React.FC<{ taskId: string }> = ({ taskId }) => {
  const { taskActivity, isTaskActivityLoading } = useTaskActivity({ taskId });

  if (isTaskActivityLoading) {
    return null;
  }

  return (
    <Box maxH="200px" overflow="auto">
      <Table size="sm">
        <Tbody>
          {taskActivity.map((a) => {
            const ActionIcon = actionIconMap[a.type];
            return (
              <Tr key={a.trackingEventId}>
                <Td>
                  <Tooltip label={a.timestamp.toTimeFromNow()} fontSize="sm">
                    <Stack>
                      <HStack>
                        <ActionIcon />
                        <Text>
                          {a.type === 'VIEWED'
                            ? 'Viewed'
                            : a.actionType === 'unknown'
                              ? null
                              : statusMap[a.actionType]}
                        </Text>
                      </HStack>
                      <Text fontSize="sm" color="brand.dark-gray">
                        {a.timestamp.toTimestampString()}
                      </Text>
                    </Stack>
                  </Tooltip>
                </Td>

                <Td>
                  <Tooltip label={a.userAgent} fontSize="sm">
                    <Stack>
                      <Text>{a.userName}</Text>
                      <HStack>
                        <Text fontSize="sm" color="brand.dark-gray">
                          {a.sourceIp}
                        </Text>
                      </HStack>
                    </Stack>
                  </Tooltip>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};

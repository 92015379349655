"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTaskDesign = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TaskAssignmentRequest_1 = require("../TaskAssignmentRequest");
const TaskDesign_1 = require("../TaskDesign/TaskDesign");
const TaskDesignTypes_1 = require("../TaskDesign/TaskDesignTypes");
const FormSchema_1 = require("./FormSchema");
class FormTaskDesign extends TaskDesign_1.TaskDesign {
    toCreateArgs() {
        const { type, viewers, submitters, schema, name, taskDesignId } = this;
        return {
            name,
            type,
            viewers: viewers.map((v) => v.value),
            submitters: submitters.map((v) => v.value),
            schema: schema.asJSON(),
            taskDesignId,
        };
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TaskAssignmentRequest_1.TaskAssignedResponse }),
    (0, class_validator_1.IsInstance)(TaskAssignmentRequest_1.TaskAssignedResponse, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], FormTaskDesign.prototype, "viewers", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TaskAssignmentRequest_1.TaskAssignedResponse }),
    (0, class_validator_1.IsInstance)(TaskAssignmentRequest_1.TaskAssignedResponse, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], FormTaskDesign.prototype, "submitters", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)([TaskDesignTypes_1.TaskDesignType.FORM]),
    (0, tslib_1.__metadata)("design:type", String)
], FormTaskDesign.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FormSchema_1.FormSchema),
    (0, tslib_1.__metadata)("design:type", FormSchema_1.FormSchema)
], FormTaskDesign.prototype, "schema", void 0);
exports.FormTaskDesign = FormTaskDesign;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useController } from 'react-hook-form';
export var InputControl = function (_a) {
    var name = _a.name, control = _a.control, defaultValue = _a.defaultValue, render = _a.render, rules = _a.rules;
    var _b = useController(__assign(__assign({ name: name, control: control }, (rules && { rules: rules })), { defaultValue: defaultValue })).field, onChange = _b.onChange, value = _b.value, ref = _b.ref;
    return render({ name: name, onChange: onChange, value: value, ref: ref });
};

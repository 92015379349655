"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroClient = exports.GetXeroSyncEmployeeSyncDetailsRequest = exports.XeroSetBusinessConnectionRequest = exports.XeroMyConnectionDetailsResponse = exports.XeroMyConnectionDetailsResponseConnection = exports.XeroMyConnectionDetailsResponseBusiness = exports.XeroExchangeResponse = exports.XeroExchangeRequest = exports.XeroBeginConnectionDetails = exports.XeroBeginConnectionRequest = exports.XeroConnectionPurpose = exports.XeroSuperFundListing = exports.UpdateXeroBusinessNominatedSuperRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const XeroContact_1 = require("./XeroContact");
const flow_1 = require("../flow");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
const handler_1 = require("../utils/handler");
const UpdateOrCreateUserInXeroRequest_1 = require("./UpdateOrCreateUserInXeroRequest");
const XeroEmployee_1 = require("./XeroEmployee");
const LoginFlowResponse_1 = require("../identity/models/LoginFlowResponse");
class UpdateXeroBusinessNominatedSuperRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['XERO_UPDATE_SUPER']),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateXeroBusinessNominatedSuperRequest.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateXeroBusinessNominatedSuperRequest.prototype, "xeroSuperFundId", void 0);
exports.UpdateXeroBusinessNominatedSuperRequest = UpdateXeroBusinessNominatedSuperRequest;
class XeroSuperFundListing extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSuperFundListing.prototype, "xeroSuperFundId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSuperFundListing.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], XeroSuperFundListing.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSuperFundListing.prototype, "usi", void 0);
exports.XeroSuperFundListing = XeroSuperFundListing;
var XeroConnectionPurpose;
(function (XeroConnectionPurpose) {
    XeroConnectionPurpose["SIGN_UP"] = "SIGN_UP";
    XeroConnectionPurpose["SIGN_IN"] = "SIGN_IN";
    XeroConnectionPurpose["CONNECT_ORG"] = "CONNECT_ORG";
})(XeroConnectionPurpose = exports.XeroConnectionPurpose || (exports.XeroConnectionPurpose = {}));
class XeroBeginConnectionRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroBeginConnectionRequest.prototype, "isXeroLead", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(Object.values(XeroConnectionPurpose)),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBeginConnectionRequest.prototype, "purpose", void 0);
exports.XeroBeginConnectionRequest = XeroBeginConnectionRequest;
class XeroBeginConnectionDetails extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBeginConnectionDetails.prototype, "connectionURL", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(Object.values(XeroConnectionPurpose)),
    (0, tslib_1.__metadata)("design:type", String)
], XeroBeginConnectionDetails.prototype, "purpose", void 0);
exports.XeroBeginConnectionDetails = XeroBeginConnectionDetails;
class XeroExchangeRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroExchangeRequest.prototype, "state", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroExchangeRequest.prototype, "code", void 0);
exports.XeroExchangeRequest = XeroExchangeRequest;
class XeroExchangeResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(Object.values(XeroConnectionPurpose)),
    (0, tslib_1.__metadata)("design:type", String)
], XeroExchangeResponse.prototype, "purpose", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(LoginFlowResponse_1.LoginFlowResponse),
    (0, tslib_1.__metadata)("design:type", LoginFlowResponse_1.LoginFlowResponse)
], XeroExchangeResponse.prototype, "loginFlow", void 0);
exports.XeroExchangeResponse = XeroExchangeResponse;
/**
 * Determine whether a user manages the business elsewhere
 */
class XeroMyConnectionDetailsResponseBusiness extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseBusiness.prototype, "businessName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseBusiness.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroMyConnectionDetailsResponseBusiness.prototype, "canManage", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseBusiness.prototype, "xeroConnectedOrganizationId", void 0);
exports.XeroMyConnectionDetailsResponseBusiness = XeroMyConnectionDetailsResponseBusiness;
class XeroMyConnectionDetailsResponseConnection extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseConnection.prototype, "xeroConnectedOrganizationId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseConnection.prototype, "xeroTenantName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseConnection.prototype, "xeroShortCode", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseConnection.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroMyConnectionDetailsResponseConnection.prototype, "userName", void 0);
exports.XeroMyConnectionDetailsResponseConnection = XeroMyConnectionDetailsResponseConnection;
class XeroMyConnectionDetailsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: XeroMyConnectionDetailsResponseBusiness }),
    (0, class_validator_1.IsInstance)(XeroMyConnectionDetailsResponseBusiness, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], XeroMyConnectionDetailsResponse.prototype, "businesses", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: XeroMyConnectionDetailsResponseConnection }),
    (0, class_validator_1.IsInstance)(XeroMyConnectionDetailsResponseConnection, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], XeroMyConnectionDetailsResponse.prototype, "connections", void 0);
exports.XeroMyConnectionDetailsResponse = XeroMyConnectionDetailsResponse;
class XeroSetBusinessConnectionRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSetBusinessConnectionRequest.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSetBusinessConnectionRequest.prototype, "xeroConnectedOrganizationId", void 0);
exports.XeroSetBusinessConnectionRequest = XeroSetBusinessConnectionRequest;
class GetXeroSyncEmployeeSyncDetailsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetXeroSyncEmployeeSyncDetailsRequest.prototype, "memberId", void 0);
exports.GetXeroSyncEmployeeSyncDetailsRequest = GetXeroSyncEmployeeSyncDetailsRequest;
class XeroClient {
    constructor(http, $error, $loginFlow) {
        this.http = http;
        this.$error = $error;
        this.$loginFlow = $loginFlow;
        this.beginConnection = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .bodyJSON(XeroBeginConnectionRequest.create(args))
            .to('/xero/connection/begin')
            .post((d) => XeroBeginConnectionDetails.create(d)));
        this.exchangeCode = (0, handler_1.handler)(this, async (args) => {
            const response = await this.http
                .request()
                .to('/xero/connection/exchange')
                .bodyJSON(XeroExchangeRequest.create(args))
                .post((d) => XeroExchangeResponse.create(d));
            response
                .maybe('loginFlow')
                .tap((response) => this.$loginFlow.publish(response));
            return response;
        });
        this.getMyConnectionDetails = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/xero/connection/details')
            .get((d) => designed_1.Optional.of(d?.details).map((data) => XeroMyConnectionDetailsResponse.create(data))));
        this.setBusinessConnection = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/xero/connection/grant')
            .bodyJSON(XeroSetBusinessConnectionRequest.create(args))
            .post());
        this.deleteMyConnection = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/xero/connection/${args.xeroConnectedOrganizationId}`)
            .delete());
        this.purgeMyConnection = (0, handler_1.handler)(this, async () => this.http.request().to('/xero/connection').delete());
        this.disconnect = (0, handler_1.handler)(this, async () => await this.http.request().to('/xero/connection').delete());
        this.getEmployees = (0, handler_1.handler)(this, async () => utils_1.TokenPaginator.from({
            itemClass: XeroEmployee_1.XeroEmployee,
            contentsKey: 'employees',
            makeRequest: async (page) => this.http
                .request()
                .to('/xero/employees')
                .optionalParams({ page })
                .get(),
        }));
        this.updateOrCreateUserInXero = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/xero/employee')
            .bodyJSON(UpdateOrCreateUserInXeroRequest_1.UpdateOrCreateUserInXeroRequest.create(args))
            .post());
        this.getXeroSyncEmployeeDetails = (0, handler_1.handler)(this, async (memberId) => this.http
            .request()
            .to(`/xero/employee/sync-details/${memberId}`)
            .get((d) => flow_1.XeroSyncEmployeeTaskSyncDetails.create(d)));
        this.getContacts = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/xero/contacts')
            .get((v) => XeroContact_1.XeroContactListResponse.create(v)));
    }
}
exports.XeroClient = XeroClient;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Result } from 'designed';
import mixpanel from 'mixpanel-browser';
import config from '../../config';

const initialized = false;

export const initialize = () => {
  if (initialized) {
    return;
  }

  const { mixpanelToken } = config;
  if (mixpanelToken) {
    Result.fromThrowable(() => {
      mixpanel.init(mixpanelToken, { ignore_dnt: true });
    });
  }
};

import * as React from 'react';
import { Stack } from '@chakra-ui/react';

import { ABN, SuperChoice } from '@zap-onboard/api-client';

import { z } from '../../../../../helpers/schema';
import { inputs } from '@zap-onboard/web-components';
import { SuperTypes } from './SuperType';
import { useHandlerQuery } from '../../../../../hooks/useHandlerQuery';
import { ErrorDisplay } from '../../../../../billing/ErrorDisplay';
import { useFormContext } from 'react-hook-form';
import { InfoBox, Spinner } from '../../../..';

export const EmployerNominatedSchema = z.object({
  superChoiceType: z.enum([SuperTypes.EMPLOYER_NOMINATED_V2]),
  fundABN: z.valueObject(z.string(), ABN),
  fundName: z.string(),
  fundSPINOrUSI: z.string(),
});

export const getEmployerNominatedDefaults = (
  choice?: SuperChoice,
): Omit<z.infer<typeof EmployerNominatedSchema>, 'superChoiceType'> => {
  if (choice?.value?.type !== 'EMPLOYER_NOMINATED_V2') {
    return {
      fundABN: '',
      fundSPINOrUSI: '',
      fundName: '',
    };
  }
  const { fundName, fundSPINOrUSI, fundABN } = choice.value;
  return {
    fundName,
    fundSPINOrUSI,
    fundABN: fundABN.asJSON(),
  };
};

export const SuperChoiceEmployerNominatedFields: React.FC<{
  businessId: string;
  superChoice?: SuperChoice;
}> = ({ businessId, superChoice }) => {
  const { setValue } = useFormContext();

  const nominatedFund = useHandlerQuery(
    (api) => api.business().getBusinessNominatedSuper(businessId),
    {
      key: ['BUSINESS_NOMINATED_SUPER_FUND', businessId],
      staleAfter: '2m',
      enabled: !superChoice,
    },
  );

  React.useEffect(() => {
    nominatedFund.data?.map((fund) => {
      setValue('superChoice.fundABN', fund.abn.asJSON());
      setValue('superChoice.fundSPINOrUSI', fund.usi);
      setValue('superChoice.fundName', fund.name);
    });
  }, [nominatedFund.data, setValue]);

  const noNominatedFund = !!nominatedFund.data && nominatedFund.data.isAbsent();

  const errorEl = (
    <ErrorDisplay
      title="We couldn't load your employers nominated super choice right now"
      {...nominatedFund}
    />
  );

  if (!nominatedFund.data) {
    return (
      <>
        {errorEl}
        <Spinner />
      </>
    );
  }

  return (
    <Stack spacing={6}>
      {errorEl}

      {noNominatedFund && (
        <InfoBox message="Until your employer has nominated a fund you will not be able to select this option." />
      )}

      <inputs.TextInput
        label="Fund Name"
        name="superChoice.fundName"
        isReadOnly
      />
      <inputs.TextInput
        label="Fund ABN"
        name="superChoice.fundABN"
        isReadOnly
      />
      <inputs.TextInput
        label="Fund USI"
        name="superChoice.fundSPINOrUSI"
        isReadOnly
      />
    </Stack>
  );
};

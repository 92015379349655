/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import {
  Stack,
  Text,
  Box,
  Flex,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { MdContentPaste } from 'react-icons/md';
import { GoKebabVertical } from 'react-icons/go';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { Action } from '../../../../pages/Business/FlowDesignDetail/FlowDesignReducer';
import { TaskItem, TaskItemProps } from './TaskItem';
import { WarningModal } from '../../../Modals';
import {
  CreateFormTaskDesignFormData,
  CreateTaskDesignFormData,
  CreateTaskDesignFormDataSimple,
} from './CreateTaskDesignFormData';
import { PasteCopiedFormTaskModal } from './PasteCopiedFormTaskModal';
import { TaskDesignType } from '@zap-onboard/api-client';

export enum TaskItemStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  NORMAL = 'NORMAL',
}

type TaskManagerSideBarProps = {
  isReadOnly: boolean;
  isTaskFormDirty?: boolean;
  taskItems: (TaskItemProps & { key: string })[];
  tasks?: CreateTaskDesignFormData[];
  dispatch?: React.Dispatch<Action>;
  renderFooter?: () => React.ReactElement;
  renderHeader?: () => React.ReactElement;
  renderUnder?: () => React.ReactElement;
  setSelectedTask?: (arg: number) => void;
};

export const TaskManagerSideBar: React.FC<TaskManagerSideBarProps> = ({
  isReadOnly,
  isTaskFormDirty,
  taskItems,
  tasks,
  dispatch,
  renderHeader,
  renderFooter,
  setSelectedTask,
}) => {
  const [isUnableToReorderTasks, setIsUnableToReorderTasks] =
    React.useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const pasteFormTaskHandler = (parsedForm: {
    name: string;
    schema: CreateFormTaskDesignFormData['schema'];
  }) => {
    const copiedForm: CreateTaskDesignFormDataSimple = {
      name: parsedForm.name,
      __isNew: true,
      type: TaskDesignType.FORM,
      viewers: [],
      schema: parsedForm.schema,
      submitters: [
        {
          key: 'SUBJECT',
          memberId: undefined,
          name: 'Subject',
          positionId: undefined,
          type: 'SUBJECT',
        },
      ],
    };
    dispatch?.({
      type: 'CREATE_TASK',
      payload: copiedForm,
    });
    setSelectedTask?.(tasks?.length ?? 0);
  };

  const onDragEnd = (result: DropResult): void => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Prevent any reordering if there are unsaved (task) changes
    if (isTaskFormDirty) {
      setIsUnableToReorderTasks(true);
      return;
    }

    // Reorder only when using the Flow Design Form (manager tool) and in edit mode
    // NB: Do not reorder when just viewing a Flow Design
    if (!isReadOnly && tasks && dispatch && setSelectedTask) {
      const newTaskList = Array.from(tasks);
      const taskToBeMoved = newTaskList[source.index];

      newTaskList.splice(source.index, 1);
      newTaskList.splice(destination.index, 0, taskToBeMoved);

      const newState = Array.from(newTaskList);

      dispatch({
        type: 'REORDER_TASKS',
        payload: {
          reorderedTaskList: newState,
        },
      });

      setSelectedTask(destination.index);
    }
  };

  return (
    <>
      <WarningModal
        header="Unable to reorder tasks"
        body="Please complete all changes before reordering your tasks"
        isWarning={isUnableToReorderTasks}
        setIsWarning={setIsUnableToReorderTasks}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Box w="100%" layerStyle="base" p={3} px={4}>
          <Flex direction="column">
            {!isReadOnly && (
              <Menu>
                <MenuButton
                  alignSelf="self-end"
                  height="45px"
                  width="45px"
                  as={IconButton}
                  icon={<GoKebabVertical />}
                  variant="ghost"
                  size={['sm', 'sm', 'lg']}
                />
                <MenuList>
                  <MenuItem icon={<MdContentPaste />} onClick={onOpen}>
                    Paste form task
                  </MenuItem>
                  <PasteCopiedFormTaskModal
                    onPaste={pasteFormTaskHandler}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </MenuList>
              </Menu>
            )}
            {renderHeader ? (
              renderHeader()
            ) : (
              <Text fontSize="lg" fontWeight="bold" py={{ base: 0, md: 4 }}>
                Tasks
              </Text>
            )}
          </Flex>
          <Droppable droppableId="task-list">
            {(provided) => (
              <Stack
                spacing={1}
                my={3}
                align="center"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {taskItems.length > 0 &&
                  taskItems.map((task) => (
                    <TaskItem {...task} isReadOnly={isReadOnly} />
                  ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
          {renderFooter && renderFooter()}
        </Box>
      </DragDropContext>
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessTFN = exports.IndividualTFN = exports.AnyTFN = void 0;
const errors_1 = require("@zap-onboard/errors");
class AnyTFN {
    constructor(value) {
        this.value = value;
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('TFN was given a non string value');
        }
        const onlyDigits = s.replace(/\D/g, '');
        const tfn = new this(onlyDigits);
        tfn.validate();
        return tfn;
    }
    static isValid(s) {
        return this.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            this.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    toString() {
        return `TFN{${this.asNumbers().reduce((safe, n, index) => {
            const next = index > 5 ? n.toString() : 'X';
            return safe + next;
        }, '')}}`;
    }
    asJSON() {
        return this.value;
    }
    validate() {
        const checksum = this.calculateChecksum();
        if (checksum == 0 || checksum % 11 != 0) {
            throw errors_1.errs.ValidationError.create('TFN did not pass checksum verification');
        }
    }
    calculateChecksum() {
        const weight = this.weights();
        return this.asNumbers().reduce((sum, number, index) => {
            const weightedNumber = number * weight[index];
            return sum + weightedNumber;
        }, 0);
    }
    asNumbers() {
        const numbers = this.value.split('').map(Number);
        numbers.forEach((n) => {
            if (Number.isNaN(n)) {
                throw errors_1.errs.ValidationError.create('TFN is not a number');
            }
        });
        return numbers;
    }
    weights() {
        if (this.value.length === 8) {
            return [10, 7, 8, 4, 6, 3, 5, 1, 0];
        }
        if (this.value.length === 9) {
            return [1, 4, 3, 7, 5, 8, 6, 9, 10];
        }
        throw errors_1.errs.ValidationError.create('TFN must be 8 digits for an individual TFN or 9 digits for a business');
    }
}
exports.AnyTFN = AnyTFN;
class IndividualTFN extends AnyTFN {
    static fake() {
        return IndividualTFN.fromJSON('459599230');
    }
    weights() {
        if (this.value.length === 9) {
            return [1, 4, 3, 7, 5, 8, 6, 9, 10];
        }
        throw errors_1.errs.ValidationError.create('TFN must be 9 digits long');
    }
}
exports.IndividualTFN = IndividualTFN;
class BusinessTFN extends AnyTFN {
    weights() {
        if (this.value.length === 8) {
            return [10, 7, 8, 4, 6, 3, 5, 1, 0];
        }
        throw errors_1.errs.ValidationError.create('TFN must be 8 digits long');
    }
}
exports.BusinessTFN = BusinessTFN;

import { useContext, createContext } from 'react';
import { Action } from './FlowDesignReducer';
import { FlowDesignComponentState } from './FlowDesignDetail';

interface FlowDesignContextInterface {
  componentState: FlowDesignComponentState;
  dispatch: React.Dispatch<Action>;
  setFormAndTaskAsDirty: () => void;
}

export const FlowDesignContext = createContext<
  FlowDesignContextInterface | undefined
>(undefined);

export function useFlowDesignContext(): FlowDesignContextInterface {
  const cont = useContext(FlowDesignContext);
  if (cont === undefined) {
    throw new TypeError('useCtx must be inside a Provider with a value');
  }
  return cont;
}

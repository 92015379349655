"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadTarget = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const FileUploadTargetType_1 = require("./FileUploadTargetType");
class FileUploadTarget extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadTarget.prototype, "fileId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadTarget.prototype, "uploadURL", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(FileUploadTargetType_1.FileUploadTargetType),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadTarget.prototype, "targetType", void 0);
exports.FileUploadTarget = FileUploadTarget;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, HStack, Center, Heading } from '@chakra-ui/react';
export var DetailButton = function (_a) {
    var icon = _a.icon, children = _a.children, onClick = _a.onClick, title = _a.title;
    var Icon = icon;
    return (_jsx(Box, __assign({ textAlign: "left", as: "button", onClick: onClick, w: "100%", layerStyle: "outline", p: 4, _hover: { layerStyle: 'hovered' }, display: "flex" }, { children: _jsxs(HStack, __assign({ color: "gray.700" }, { children: [Icon && (_jsx(Center, __assign({ w: "50px", h: "50px" }, { children: _jsx(Icon, { size: "22px" }, void 0) }), void 0)), _jsxs(Box, { children: [_jsx(Heading, __assign({ size: "xs" }, { children: title }), void 0), _jsx(Text, __assign({ fontSize: 14 }, { children: children }), void 0)] }, void 0)] }), void 0) }), void 0));
};

import { useToast } from '@chakra-ui/react';
import { CreateVisaCheckRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { track } from '../../../libs';
import { useHandler } from '../../useHandlerMutation';

export const useCreateVisaCheck = (memberId: string, callback: () => void) => {
  const toast = useToast();
  const [createVisaCheck, isCreateVisaCheckLoading] = useHandler(
    (api) => api.visa().createVisaCheck,
    {
      invalidates: () => [['VISA_CHECKS', memberId], ['VISA_CONFIG']],
      always: {
        onSuccess: async () => {
          callback();
          track.event('Finished Task', {
            taskType: 'CREATE_VISA_CHECK',
          });
          toast({
            status: 'success',
            description: 'Visa check created successfully',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
      },
    },
  );

  return {
    createVisaCheck: (data: Entity.Attributes<CreateVisaCheckRequest>) =>
      createVisaCheck(data),
    isCreateVisaCheckLoading,
  };
};

import { FlowTaskActivityListing } from '@zap-onboard/api-client';
import { useState, useEffect } from 'react';

import { onError } from '../../../libs/errorLib';
import { useAPI } from '../../../services/API';

export const useTaskActivity = (req: { taskId: string }) => {
  const { taskId } = req;
  const api = useAPI();
  const [activity, setActivity] = useState<FlowTaskActivityListing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    api
      .flows()
      .getTaskActivity({ taskId, count: 150 })
      .map((r) => {
        setActivity([...r.contents()]);
      }, onError);
    setIsLoading(false);
  }, [taskId, api]);

  return { taskActivity: activity, isTaskActivityLoading: isLoading };
};

import * as React from 'react';
import {
  Center,
  HStack,
  Spinner,
  Stack,
  useToast,
  Text,
  Icon,
  Button,
  Link,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdErrorOutline } from 'react-icons/md';
import { useAPI } from '../../services/API';
import { onError } from '../../libs/errorLib';
import { useCurrentBusiness } from '../../hooks/data/business/useBusiness';
import { track } from '../../libs/track';

const { useEffect, useState } = React;

export const OAuthDeputyConnect = () => {
  const api = useAPI();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const [failed, hasFailed] = useState<boolean>(false);
  const code = new URLSearchParams(location.search).get('code');

  useEffect(() => {
    (async () => {
      if (!code) {
        hasFailed(true);
        return;
      }
      const response = await api.deputy().connect({ code });
      response.tap(
        () => {
          track.event('Connected Deputy');
          toast({
            description: 'Deputy was connected',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
          history.push('/business/settings');
        },
        (err) => {
          hasFailed(true);
          onError(err);
        },
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {failed || (
        <Center minH="100vh" minW="100vw">
          <Spinner />
        </Center>
      )}
      {failed && <Failed />}
    </>
  );
};

const Failed = () => {
  const business = useCurrentBusiness();
  const history = useHistory();

  return (
    <Center
      bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
      minH="100vh"
      minW="100vw"
    >
      <Center
        layerStyle="base"
        minH="400px"
        minW={{
          base: '90%',
          md: '70%',
          lg: '50%',
          xl: '40%',
        }}
        p={3}
        m={6}
      >
        <Stack spacing={2} align="center">
          <HStack>
            <Icon as={MdErrorOutline} boxSize="1.5em" color="red.400" />
            <Text fontWeight="bold">
              Could not establish connection to Deputy
            </Text>
          </HStack>
          {!business.data && <Spinner />}

          {!!business.data?.config && (
            <HStack>
              <Button
                size="sm"
                as={Link}
                href={business.data.config.linkToDeputyURL}
              >
                Try Again
              </Button>

              <Button
                size="sm"
                onClick={() => history.push('/business/settings')}
              >
                Go Back
              </Button>
            </HStack>
          )}
        </Stack>
      </Center>
    </Center>
  );
};

import { createSignatureSVGDataURL } from '@zap-onboard/pdfme-common';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  Image,
  ModalOverlay,
  Box,
  Text,
  Spacer,
  Center,
  Button,
  Stack,
  Flex,
  ModalCloseButton,
} from '@chakra-ui/react';
import { inputs, Tabs } from '@zap-onboard/web-components';
import { usePDFState } from '../usePDFState';
import SignatureCanvas from 'react-signature-canvas';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedValue } from 'src/hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { onError } from 'src/libs';
import { z } from 'src/helpers';
import { errs } from '@zap-onboard/errors';
import { auth } from '../../auth';

export const SignModal = () => {
  const modalState = usePDFState((s) => s.signatureModal);
  const closeModal = () => usePDFState.setState({ signatureModal: null });

  const setFieldValue = usePDFState((s) => s.setFieldValue);
  const insertFieldSignature = (signatureDataURL?: string) => {
    if (signatureDataURL) {
      const { groups, currentGroup } = usePDFState.getState();
      const group = groups.find((g) => g.groupId === currentGroup);
      if (!group) {
        throw errs.UnexpectedError.create('No current group for signature');
      }
      usePDFState.setState({
        groups: groups.map((g) =>
          g.groupId === currentGroup ? { ...g, signatureDataURL } : g,
        ),
      });
    }
    if (modalState?.fieldId) {
      setFieldValue(modalState.fieldId, true);
    }
    closeModal();
  };

  return (
    <Modal size="lg" isOpen={!!modalState} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent rounded={0}>
        <ModalHeader>Add your signature</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0} fontSize="sm">
          <Tabs
            tabs={['Type Signature', 'Draw Signature']}
            tabPanels={[
              <TypeSignature onInsert={insertFieldSignature} />,
              <DrawSignature onInsert={insertFieldSignature} />,
            ]}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const DrawSignature: React.FC<{
  onInsert: (signatureDataURL: string) => unknown;
}> = ({ onInsert }) => {
  const canvasRef = useRef<SignatureCanvas>(null);

  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const onEnd = () => {
    setIsEmpty(canvasRef.current?.isEmpty() ?? true);
  };

  const onClear = () => {
    canvasRef.current?.clear();
    setIsEmpty(true);
  };

  return (
    <Stack spacing={4}>
      <Stack>
        <Center p={4} bg="brand.gray">
          <Box bg="white" border="1px solid black">
            <SignatureCanvas
              ref={canvasRef}
              penColor="black"
              onEnd={onEnd}
              canvasProps={{ height: 150, width: 300 }}
            />
          </Box>
        </Center>
        <Flex px={4}>
          <Spacer flexGrow={1} />
          <Button variant="ghost" disabled={isEmpty} onClick={onClear}>
            Clear
          </Button>
        </Flex>
      </Stack>

      <Flex p={4} gridGap={4} bg="brand.gray">
        <Text flexGrow={1}>
          By inserting my signature I consent to the electronic execution of
          this document by all parties.
        </Text>
        <Center>
          <Button
            disabled={isEmpty}
            onClick={() => {
              if (isEmpty || !canvasRef.current) {
                return;
              }
              try {
                onInsert(canvasRef.current.toDataURL('image/svg+xml'));
              } catch (error) {
                onError(error);
              }
            }}
          >
            Insert
          </Button>
        </Center>
      </Flex>
    </Stack>
  );
};

const TypeSignature: React.FC<{
  onInsert: (signatureDataURL: string) => unknown;
}> = ({ onInsert }) => {
  const userProfile = auth.useProfile();

  const form = useForm({
    resolver: z.zodResolver(
      z.object({ value: z.string().min(1, 'Must enter your name') }),
    ),
    defaultValues: { value: userProfile?.getFullName() ?? '' },
  });
  const value = form.watch('value', '');
  const [svg, setSvg] = useState<string>();

  const debouncedValue = useDebouncedValue(value, 200);

  useEffect(() => {
    let cancelled = false;
    if (debouncedValue.trim().length > 0) {
      createSignatureSVGDataURL({ text: debouncedValue })
        .then((svg) => (cancelled ? null : setSvg(svg)))
        .catch(onError);
    } else {
      setSvg(undefined);
    }
    return () => {
      cancelled = true;
    };
  }, [debouncedValue]);

  const submit = form.handleSubmit(async ({ value }) => {
    try {
      const signature = await createSignatureSVGDataURL({ text: value });
      onInsert(signature);
    } catch (err) {
      onError(err);
    }
  });

  return (
    <Stack spacing={4}>
      <Stack>
        <Center p={4} bg="brand.gray">
          <Center bg="white" h="150px" w="100%" border="1px solid black">
            {svg && <Image src={svg} />}
          </Center>
        </Center>
      </Stack>

      <Box p={4}>
        <FormProvider {...form}>
          <inputs.TextInput label="Type your name" name="value" isRequired />
        </FormProvider>
      </Box>

      <Flex p={4} gridGap={4} bg="brand.gray">
        <Text flexGrow={1}>
          By inserting my signature I consent to the electronic execution of
          this document by all parties.
        </Text>
        <Center>
          <Button
            isLoading={form.formState.isSubmitting}
            isDisabled={form.formState.isSubmitting}
            onClick={submit}
          >
            Insert
          </Button>
        </Center>
      </Flex>
    </Stack>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rolePermissions = exports.PermissionDefinitions = void 0;
exports.PermissionDefinitions = {
    CONFIGURE_BUSINESS: [
        'manageWorkflowDesigns',
        'manageCertificationDesigns',
        'managePositions',
        'manageIntegrations',
        'manageBilling',
        'manageBusinessProfile',
        'manageDefaultSuper',
        'configureQRCode',
        'registerBusiness',
        'createAdminMember',
    ],
    MANAGE_ACCESS: ['changeEmail', 'changeRole', 'resetMFA'],
    MANAGE_SENSITIVE_INFORMATION: ['manageSensitiveInformation'],
    // Supervisor Abilities
    MANAGE_RESOURCES: [
        'manageWorkflowsAndTasks',
        'manageCertifications',
        'manageUserDocuments',
        'crudReminders',
        'messaging',
        'manageVideos',
    ],
    MANAGE_MEMBERS: [
        'updateProfile',
        'archiveMember',
        'removeAccess',
        'importFromXero',
        'changePosition',
        'sendMemberInvite',
        'createUserOrSupervisorMember',
        'manageContacts',
    ],
};
const allPermissions = Object.keys(exports.PermissionDefinitions);
exports.rolePermissions = {
    OWNER: allPermissions,
    ADMIN: allPermissions,
    SUPERVISOR: ['MANAGE_RESOURCES', 'MANAGE_MEMBERS'],
    USER: [],
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Center,
  HStack,
  Link,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { DevActionType, UserProfile } from '@zap-onboard/api-client';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../auth';
import { Spinner } from '../../components/Spinner/Spinner';
import { onError } from '../../libs/errorLib';
import { useAPI } from '../../services/API';

export const DevActions: React.FC = () => {
  const userProfile = auth.useProfile();

  const toast = useToast();
  const [profiles, setProfiles] = useState<UserProfile[]>([]);
  const [loadingProfiles, setLoadingProfiles] = useState<boolean>(true);
  const history = useHistory();

  const api = useAPI();
  const getUsers = React.useCallback(async () => {
    setLoadingProfiles(true);
    await api
      .devAction(DevActionType.GET_USERS)
      .map(
        ({ profiles }) =>
          (profiles as any).map((data: any) => UserProfile.create(data)),
        onError,
      )
      .map(setProfiles);
    setLoadingProfiles(false);
  }, [api]);

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  const seed = (type: string) => async () => {
    const res = await api.devAction(DevActionType.SEED, { type });
    res.map((r) => {
      getUsers();
      toast({ title: 'Seeded', description: (r.status as string) ?? `${r}` });
    }, onError);
  };

  const [isLoadingDB, setIsLoadingDB] = useState(false);
  const loadDB = async () => {
    if (isLoadingDB) {
      return;
    }
    setIsLoadingDB(true);
    await api
      .devAction(DevActionType.LOAD_DB)
      .tap(getUsers)
      .tap(() => toast({ title: 'Loaded DB' }), onError);
    setIsLoadingDB(false);
  };

  const saveDB = () =>
    api
      .devAction(DevActionType.SAVE_DB)
      .tap(() => toast({ title: 'Saved DB' }), onError);

  const login = async ({ email }: { email: string }) => {
    const res = await api.devAction(DevActionType.LOGIN_DIRECTLY_BY_EMAIL, {
      email,
    });

    res.tap((r) => {
      api.setToken(r.token as string);
      history.push('/');
    }, onError);
  };

  const [selectedEmail, setSelectedEmail] = useState<string>();

  useEffect(() => {
    if ((profiles?.length ?? 0) > 0) {
      setSelectedEmail(
        profiles
          .sort((a, b) => a.getUserName().localeCompare(b.getUserName()))[0]
          .email.asJSON(),
      );
    }
  }, [profiles]);

  if (loadingProfiles) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <Center>
      <Stack spacing={7} maxW="500px" height="100%">
        <Button bg="brand.red" onClick={seed('PoppasPizza')} type="submit">
          Seed PoppasPizza
        </Button>
        <Button bg="brand.red" onClick={seed('KitchenSinkFlow')} type="submit">
          Seed KitchenSinkFlow
        </Button>

        <Stack mt="lg">
          Login as
          <select
            onChange={(e) => {
              setSelectedEmail(e.target.value);
            }}
          >
            {profiles
              .sort((a, b) => a.getUserName().localeCompare(b.getUserName()))
              .map(({ email, userId }) => (
                <option key={userId} value={email.asJSON()}>
                  {email.asJSON()}
                </option>
              ))}
          </select>
          <Button
            onClick={() => login({ email: selectedEmail ?? 'No email' })}
            type="submit"
          >
            Login as {selectedEmail ?? '--'}
          </Button>
        </Stack>
        <Stack>
          <Button as={Link} href="/deputy/oauth?code=MOCK_DEPUTY_CLIENT_CODE">
            Connect Deputy Page
          </Button>
          <Button as={Link} href="/xero/quicksignup">
            Quick Signup with Xero
          </Button>
          <Button
            onClick={() =>
              api
                .devAction(DevActionType.SEND_NOTIFICATION, {})
                .tap(() => toast({ title: 'Sent notifications' }), onError)
            }
          >
            Send Test Notifications
          </Button>

          <Button
            onClick={() =>
              api
                .devAction(DevActionType.FIFTEEN_CRON, {})
                .tap(() => toast({ title: 'Cron Done' }), onError)
            }
          >
            Fifteen Minute Cron
          </Button>

          <Button
            onClick={() =>
              api
                .devAction(DevActionType.MORNING_CRON, {})
                .tap(() => toast({ title: 'Cron Done' }), onError)
            }
          >
            Morning Cron
          </Button>
          <Button
            onClick={() =>
              api
                .devAction(DevActionType.XERO_BILLING_WEBHOOK, {})
                .tap(() => toast({ title: 'Xero webhook delivered' }), onError)
            }
          >
            Send Xero Billing Webhook
          </Button>
        </Stack>

        <HStack justifyContent="space-around">
          <Button isLoading={isLoadingDB} onClick={loadDB}>
            Load db
          </Button>
          <Button variant="outline" onClick={saveDB}>
            Save DB
          </Button>
        </HStack>

        {userProfile && (
          <Button onClick={() => api.setToken(undefined)}>
            Logout of {userProfile.getUserName()}
          </Button>
        )}
      </Stack>
    </Center>
  );
};

import {
  Button,
  Center,
  HStack,
  Icon,
  Stack,
  useToast,
  Text,
} from '@chakra-ui/react';
import { errs } from '@zap-onboard/errors';
import React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from '../../components';
import { useMYOBBeginOAuthAuthentication } from '../../hooks/data/MYOB';
import { logError } from '../../libs/errorLib';
import { track } from '../../libs/track';
import { useAPI } from '../../services/API';

export const OAuthMYOBConnect: React.FC = () => {
  const api = useAPI();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const [failed, setFailed] = React.useState<errs.DomainError>();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  React.useEffect(() => {
    (async () => {
      if (!code || !state) {
        setFailed(errs.ClientError.create('Invalid request'));
        return;
      }
      const response = await api
        .myob()
        .completeOAuthAuthentication({ code, state });
      response.tap(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (res) => {
          const description = 'MYOB Authenticated';
          track.event('MYOB OAuth completed');
          history.push(`/business/settings?openMYOBModal=true`);

          toast({
            description,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top',
          });
        },
        (err) => {
          setFailed(err);
          logError(err);
        },
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {!!failed || (
        <Center minH="100vh" minW="100vw">
          <Spinner />
        </Center>
      )}
      {!!failed && <Failed error={failed} />}
    </>
  );
};

const Failed = ({ error }: { error: errs.DomainError }) => {
  const history = useHistory();
  const { beginOAuthAuthentication, isLoadingBeginOAuth } =
    useMYOBBeginOAuthAuthentication();
  return (
    <Center
      bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
      minH="100vh"
      minW="100vw"
    >
      <Center
        layerStyle="base"
        minH="400px"
        minW={{
          base: '90%',
          md: '70%',
          lg: '50%',
          xl: '40%',
        }}
        p={3}
        m={6}
      >
        <Stack spacing={2} align="center">
          <HStack>
            <Icon as={MdErrorOutline} boxSize="1.5em" color="red.400" />
            <Text fontWeight="bold">
              Could not establish connection to MYOB
            </Text>
          </HStack>
          <Text>{error.message}</Text>

          <Button
            isDisabled={isLoadingBeginOAuth}
            isLoading={isLoadingBeginOAuth}
            onClick={beginOAuthAuthentication}
          >
            Connect to MYOB
          </Button>

          <Button variant="link" onClick={() => history.push('/')}>
            Back to Dashboard
          </Button>
        </Stack>
      </Center>
    </Center>
  );
};

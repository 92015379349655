import { membership } from '@zap-onboard/api-client';

import { useDebouncedValue } from '../../useDebouncedValue';
import { useHandlerQuery } from '../../useHandlerQuery';

type Params = {
  search?: string;
  userId?: string;
  anonymous?: boolean;
  positionId?: string;
  uninvited?: boolean;
  invited?: boolean;
  archived?: boolean;
  role?: membership.RoleName;
  staleAfter?: string;
  enabled?: boolean;
  contactId?: string;
};

export const useMemberSearch = (args: Params) => {
  const {
    userId,
    uninvited,
    role,
    anonymous,
    positionId,
    invited,
    archived,
    contactId,
  } = args;

  const search = useDebouncedValue(
    args.search && args.search.length > 2 ? args.search : undefined,
    500,
  );

  const getRole = () => {
    if (role === 'ADMIN') {
      return { isOwnerOrAdmin: true as const };
    }
    if (role != null) {
      return { role };
    }
    return undefined;
  };

  const params = {
    search,
    uninvited: (uninvited ? true : undefined) as true,
    anonymous: (anonymous ? true : undefined) as true,
    archived,
    invited: (invited ? true : undefined) as true,
    positionId: positionId === '' ? undefined : positionId,
    userId: userId === '' ? undefined : userId,
    contactId: contactId === '' ? undefined : contactId,
    ...getRole(),
  };

  return useHandlerQuery((api) => api.membership().searchMembers(params), {
    key: ['MEMBER_SEARCH', params],
    staleAfter: args.staleAfter,
    enabled: args.enabled,
  });
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Document = exports.Page = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const RemoteFile_1 = require("../RemoteFile");
const ResponseBase_1 = require("../../ResponseBase");
const Point_1 = require("./Point");
class Page extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Page.prototype, "number", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Point_1.Size),
    (0, tslib_1.__metadata)("design:type", Point_1.Size)
], Page.prototype, "size", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.TemporaryRemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.TemporaryRemoteFile)
], Page.prototype, "image", void 0);
exports.Page = Page;
class Document extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: Page }),
    (0, class_validator_1.IsInstance)(Page, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], Document.prototype, "pages", void 0);
exports.Document = Document;

import { CreateVideoRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useCreateVideo = (afterSubmit?: () => unknown) => {
  const [create, createIsLoading] = useHandler(
    (api) => api.video().createVideo,
    {
      invalidates: () => [['VIDEOS']],
      always: {
        onSuccess: async () => {
          if (afterSubmit) {
            await afterSubmit();
          }
        },
      },
    },
  );

  return {
    create: (data: Entity.Attributes<CreateVideoRequest>) => create(data),
    createIsLoading,
  };
};

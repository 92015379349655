import * as React from 'react';
import { Center, Heading, Stack } from '@chakra-ui/react';

import {
  RawTextTask as APIRawTextTask,
  ProfileTask as APIProfileTask,
  FormTask as APIFormTask,
  EsignTask as APIEsignTask,
  TemplateTask as APITemplateTask,
  TFNDTask as APITFNDTask,
  SuperChoiceTask as APISuperChoiceTask,
  XeroSyncEmployeeTask as APIXeroSyncEmployeeTask,
  DeputyLinkEmployeeTask as APIDeputyLinkEmployeeTask,
  BankAccountTask as APIBankAccountTask,
  ClientVerificationTask as APIClientVerificationTask,
  ProofOfIdentityTask as APIProofOfIdentityTask,
  VisaDetailsTask as APIVisaDetailsTask,
  flow,
} from '@zap-onboard/api-client';
import { BaseTaskPropTypes } from '../../TaskTypes/BaseTaskPropTypes';

import { TaskSkipped } from '../../TaskStatus';
import {
  RawTextTask,
  FormTask,
  EsignTask,
  TemplateTask,
  TFNDTask,
  SuperChoiceTask,
  XeroSyncEmployeeTask,
  DeputyTask,
  BankAccountTask,
  ProofOfIdentityTask,
  ProfileTask,
  ClientVerificationTask,
  VisaDetailsTask,
} from '../../TaskTypes';
import { auth } from '../../../../../auth';

type Props = BaseTaskPropTypes & {
  flow: flow.GetFlows.Flow;
  setSideBarTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const RenderedTask: React.FC<Props> = (props) => {
  const { task, setSideBarTaskId } = props;

  if (task.taskStateId === 'META_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        <Heading variant="section" mt={{ base: 0, md: 5 }} textAlign="center">
          {task.name}
        </Heading>
        <TaskSkipped />
      </Stack>
    );
  }
  if (task instanceof APIRawTextTask) {
    return <RawTextTask {...props} task={task} />;
  }
  if (task instanceof APIFormTask) {
    return <FormTask {...props} task={task} />;
  }
  if (task instanceof APIEsignTask) {
    return <EsignTask {...props} task={task} />;
  }
  if (task instanceof APITemplateTask) {
    return <TemplateTask {...props} task={task} />;
  }
  if (task instanceof APITFNDTask) {
    return (
      <auth.MFAWrapper heading="MFA must be configured to view tax details">
        <TFNDTask {...props} task={task} />
      </auth.MFAWrapper>
    );
  }
  if (task instanceof APISuperChoiceTask) {
    return (
      <auth.MFAWrapper heading="MFA must be configured to view super details">
        <SuperChoiceTask {...props} task={task} />
      </auth.MFAWrapper>
    );
  }
  if (task instanceof APIXeroSyncEmployeeTask) {
    return (
      <auth.MFAWrapper heading="MFA must be configured to access Xero">
        <XeroSyncEmployeeTask {...props} task={task} />
      </auth.MFAWrapper>
    );
  }
  if (task instanceof APIDeputyLinkEmployeeTask) {
    return <DeputyTask {...props} task={task} />;
  }
  if (task instanceof APIBankAccountTask) {
    return <BankAccountTask {...props} task={task} />;
  }
  if (task instanceof APIVisaDetailsTask) {
    return <VisaDetailsTask {...props} task={task} />;
  }
  if (task instanceof APIProofOfIdentityTask) {
    return (
      <ProofOfIdentityTask
        {...props}
        task={task}
        setSideBarTaskId={setSideBarTaskId}
      />
    );
  }

  if (task instanceof APIProfileTask) {
    return <ProfileTask {...props} task={task} />;
  }
  if (task instanceof APIClientVerificationTask) {
    return (
      <ClientVerificationTask
        {...props}
        task={task}
        setSideBarTaskId={setSideBarTaskId}
      />
    );
  }

  return (
    <Center>
      <Heading variant="section">Unable to render this task</Heading>
    </Center>
  );
};

import * as React from 'react';
import { useIsComponentMounted } from './useIsComponentMounted';

export const useCancelablePromise = () => {
  const isMounted = useIsComponentMounted();
  return React.useCallback(
    <T>(promise: PromiseLike<T>) =>
      new Promise<T>((resolve, reject) => {
        promise.then(
          (result) => {
            if (isMounted()) {
              resolve(result);
            }
          },
          (error) => {
            if (isMounted()) {
              reject(error);
            }
          },
        );
      }),
    [isMounted],
  );
};

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useCurrentBusiness } from '../hooks';
import { BusinessSetupBillingWizardModal } from './BusinessSetupBillingWizard/BusinessSetupBillingWizardModal';
import { ErrorDisplay } from './ErrorDisplay';

export const CurrentBusinessBillingAlert: React.FC = () => {
  const business = useCurrentBusiness({ staleAfter: '15s' });
  const disclosure = useDisclosure();
  const error = (
    <ErrorDisplay
      title="We're having some issues loading your business details"
      {...business}
    />
  );
  if (!business.data) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{error}</>;
  }
  const status = business.data.billingStatus.getStatus();
  const trialExpiry = business.data.billingStatus.trialExpiresAt;
  const hasPaidSubscription = business.data.billingStatus.hasPaidSubscription;
  const alertTitle = hasPaidSubscription
    ? 'Subscription linking required'
    : 'This business is not linked to a paid subscription';
  const alertDescription = hasPaidSubscription
    ? '- Click here to link to your exisiting subscription (no charge applies)'
    : '- Click here to subscribe or link to an existing subscription';
  return (
    <>
      {error}
      {status === 'IN_TRIAL' && (
        <Alert as="button" onClick={disclosure.onOpen} status="info">
          <AlertIcon />
          <AlertTitle mr={2}>You are trialling a subscription</AlertTitle>
          {trialExpiry && (
            <AlertDescription>
              - expires {trialExpiry.toTimeFromNow()}
            </AlertDescription>
          )}
        </Alert>
      )}
      {status === 'EXPIRED' && (
        <Alert as="button" onClick={disclosure.onOpen} status="error">
          <AlertIcon />
          <AlertTitle>{alertTitle}</AlertTitle>
          <AlertDescription>{alertDescription}</AlertDescription>
        </Alert>
      )}
      <BusinessSetupBillingWizardModal disclosure={disclosure} />
    </>
  );
};

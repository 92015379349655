"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSchema = exports.formSchemaItemClassMap = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const IsInstanceOfAny_1 = require("../../../utils/validations/IsInstanceOfAny");
const FileUploadFormItem_1 = require("./FileUploadFormItem");
const YoutubeVideoFormItem_1 = require("./YoutubeVideoFormItem");
const FormSchemaValidator_1 = require("./FormSchemaValidator");
const MultipleChoiceFormItem_1 = require("./MultipleChoiceFormItem");
const TextBlockFormItem_1 = require("./TextBlockFormItem");
const TextQuestionFormItem_1 = require("./TextQuestionFormItem");
const VideoFormItem_1 = require("./VideoFormItem");
exports.formSchemaItemClassMap = {
    [MultipleChoiceFormItem_1.MultipleChoiceFormSchemaItem.type]: MultipleChoiceFormItem_1.MultipleChoiceFormSchemaItem,
    [TextQuestionFormItem_1.TextQuestionFormSchemaItem.type]: TextQuestionFormItem_1.TextQuestionFormSchemaItem,
    [FileUploadFormItem_1.FileUploadFormSchemaItem.type]: FileUploadFormItem_1.FileUploadFormSchemaItem,
    [YoutubeVideoFormItem_1.YoutubeVideoFormSchemaItem.type]: YoutubeVideoFormItem_1.YoutubeVideoFormSchemaItem,
    [VideoFormItem_1.VideoFormSchemaItem.type]: VideoFormItem_1.VideoFormSchemaItem,
    [TextBlockFormItem_1.TextBlockFormSchemaItem.type]: TextBlockFormItem_1.TextBlockFormSchemaItem,
};
class FormSchema extends ResponseBase_1.ResponseBase {
    async validateItems(items, extra) {
        await FormSchemaValidator_1.FormSchemaValidator.apply(this, items, extra);
    }
    // @TODO remove bang
    toIndexedItems() {
        const aggregate = {};
        this.items.forEach((item) => {
            if (aggregate[item.formSchemaItemId]) {
                throw errors_1.errs.UnexpectedError.create(`a formSchemaItemId was found twice`, { details: { items: this.items } });
            }
            aggregate[item.formSchemaItemId] = item;
        });
        return aggregate;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({
        taggedUnion: {
            getTag: (item) => item.type,
            handlers: exports.formSchemaItemClassMap,
        },
    }),
    (0, IsInstanceOfAny_1.IsInstanceOfAny)(Object.values(exports.formSchemaItemClassMap), { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], FormSchema.prototype, "items", void 0);
exports.FormSchema = FormSchema;

import * as React from 'react';
import { Button, ButtonGroup, Stack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { ZodIssueCode } from 'zod';

import {
  ABN,
  XeroMyConnectionDetailsResponseConnection,
} from '@zap-onboard/api-client';
import { createToast, removeSpaces } from '../../../../../../helpers';
import { onError } from '../../../../../../libs/errorLib';
import { useAPI } from '../../../../../../services/API';
import { z } from '../../../../../../helpers/schema';
import { track } from '../../../../../../libs/track';

import { inputs } from '@zap-onboard/web-components';
import { AcceptTOCCheckbox } from '../../../../../../components';
import { auth } from '../../../../../../auth';

export const SetupBusinessForm = (props: {
  afterSetup?: (businessId: string) => unknown;
  onCancel?: () => unknown;
  xeroOrg?: XeroMyConnectionDetailsResponseConnection;
  /**
   * Passed to prevent a user who is a xero lead marking _another_ business as
   * a lead on their second business registration
   */
  isXeroLead?: boolean;
}) => {
  const { onCancel, afterSetup, xeroOrg, isXeroLead } = props;
  const { useState } = React;
  const api = useAPI();

  const managers = auth.useBusinessManagers();

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const businessNames = React.useMemo(
    () => managers.map((r) => removeSpaces(r.businessName.toLowerCase())),
    [managers],
  );

  const SetupBusinessFormSchema = z.object({
    businessName: z
      .string()
      .min(4)
      .max(150)
      .superRefine((val, ctx) => {
        const businessName = removeSpaces(val.toLowerCase());

        if (businessNames.includes(businessName)) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: 'A business with this name is already registered',
          });
        }
      }),
    abn: z.optionalValueObject(z.string(), ABN),
  });

  const useFormMethods = useForm<z.infer<typeof SetupBusinessFormSchema>>({
    defaultValues: {
      businessName: xeroOrg?.xeroTenantName ?? '',
      abn: '',
    },
    resolver: z.zodResolver(SetupBusinessFormSchema),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useFormMethods;

  const onSubmit = handleSubmit(async ({ businessName, abn }) => {
    const name = businessName.trim();

    await api
      .business()
      .register({
        isXeroLead: isXeroLead ?? false,
        abn: abn.trim().length === 0 ? undefined : abn,
        name,
      })
      .map(async ({ businessId }) => {
        track.event('Business Registered', {
          businessId,
        });
        if (afterSetup) {
          await afterSetup(businessId);
        }
        await auth.reInit({ currentBusinessId: businessId });
        createToast({
          status: 'success',
          title: 'Business registered',
          description: `We have added ${name} to your profile`,
          position: 'top',
          duration: 6000,
        });
      }, onError);
  });

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={onSubmit}>
        <Stack spacing={2} data-testid="registerBusinessForm" w="100%">
          <Stack spacing={2}>
            <inputs.TextInput name="businessName" label="Business name" />
            <inputs.TextInput name="abn" label="ABN" />
            <Text fontSize="xs" mb={3}>
              If you are an employee of this business or it already exists
              contact the current owner.
            </Text>
            <AcceptTOCCheckbox
              hasAcceptedTerms={hasAcceptedTerms}
              toggle={() => setHasAcceptedTerms(!hasAcceptedTerms)}
            />
          </Stack>
        </Stack>

        <ButtonGroup justifyContent="center" w="100%" mt={3}>
          <Button
            type="submit"
            disabled={isSubmitting || !hasAcceptedTerms}
            isLoading={isSubmitting}
          >
            Register
          </Button>
          {onCancel && (
            <Button
              disabled={isSubmitting}
              variant="outline"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </form>
    </FormProvider>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPTransport = void 0;
const TimeAndZone_1 = require("../../time/TimeAndZone");
const HTTPRequest_1 = require("./HTTPRequest");
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class HTTPTransport {
    constructor(config, getToken, getCurrentBusinessId) {
        this.config = config;
        this.getToken = getToken;
        this.getCurrentBusinessId = getCurrentBusinessId;
        this.timezone = TimeAndZone_1.TimeAndZone.getTimezone();
    }
    for(args) {
        return HTTPRequest_1.HTTPRequest.for(args.url).timeoutInMS(args.timeoutInMs);
    }
    request() {
        const request = HTTPRequest_1.HTTPRequest.for(this.config.baseAPIURL).timeoutInMS(this.config.timeoutInMS);
        this.getToken().map((token) => request.bearerToken(token));
        this.getCurrentBusinessId().map((businessId) => request.header('current-business-id', businessId));
        return request.header('x-timezone', this.timezone);
    }
}
exports.HTTPTransport = HTTPTransport;

import * as React from 'react';
import { Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

import { MdInfoOutline } from 'react-icons/md';
import { ChakraFlexProps } from '../../types/Chakra';

type InfoBoxProps = {
  icon?: IconType;
  iconColor?: string;
  textColor?: string;
  heading?: string;
  message?: string;
} & Partial<ChakraFlexProps>;

export const InfoBox: React.FC<InfoBoxProps> = ({
  icon = MdInfoOutline,
  iconColor,
  heading,
  message,
  textColor,
  children,
  ...props
}) => (
  <Flex layerStyle="outline" p={4} m={4} w="100%" {...props}>
    <Stack spacing={1} w="100%">
      <HStack>
        <Icon as={icon} color={iconColor} boxSize={6} />
        <Text fontWeight="bold" color={textColor || 'brand.black'}>
          {heading || 'Info'}
        </Text>
      </HStack>
      <Text wordBreak="break-word" color={textColor || 'brand.black'}>
        {message}
      </Text>
      {children}
    </Stack>
  </Flex>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMember = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const utils_1 = require("../../utils");
const identity_1 = require("../../identity");
const ResponseBase_1 = require("../../ResponseBase");
const Role_1 = require("../AccessControl/Role");
var GetMember;
(function (GetMember) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "memberId", void 0);
    GetMember.Request = Request;
    class Invitation extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Invitation.prototype, "sentAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Invitation.prototype, "acceptedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Invitation.prototype, "declinedAt", void 0);
    GetMember.Invitation = Invitation;
    class MembershipPositions extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], MembershipPositions.prototype, "positionId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], MembershipPositions.prototype, "positionName", void 0);
    GetMember.MembershipPositions = MembershipPositions;
    class EmailFailureResponse extends ResponseBase_1.ResponseBase {
        getMessage() {
            if (this.category?.toLowerCase() === 'bounce') {
                return `An email sent to this address ${this.timestamp.toTimeFromNow()} bounced. You may need to update the email address.`;
            }
            if (this.category?.toLowerCase() === 'complaint') {
                return `An email sent to this address ${this.timestamp.toTimeFromNow()} was marked as spam. You may need to update the email address.`;
            }
            return `An email sent to this address ${this.timestamp.toTimeFromNow()} failed to deliver. You may need to update the email address.`;
        }
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], EmailFailureResponse.prototype, "category", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], EmailFailureResponse.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], EmailFailureResponse.prototype, "subtype", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], EmailFailureResponse.prototype, "timestamp", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Memoize.All(),
        (0, tslib_1.__metadata)("design:type", Function),
        (0, tslib_1.__metadata)("design:paramtypes", []),
        (0, tslib_1.__metadata)("design:returntype", void 0)
    ], EmailFailureResponse.prototype, "getMessage", null);
    GetMember.EmailFailureResponse = EmailFailureResponse;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Response.prototype, "archived", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "userName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "userId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.Email),
        (0, tslib_1.__metadata)("design:type", utils_1.Email)
    ], Response.prototype, "email", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Role_1.Role),
        (0, tslib_1.__metadata)("design:type", Role_1.Role)
    ], Response.prototype, "role", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(Invitation),
        (0, tslib_1.__metadata)("design:type", Invitation)
    ], Response.prototype, "invite", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Response.prototype, "mfaEnabled", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: MembershipPositions }),
        (0, class_validator_1.IsInstance)(MembershipPositions, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "membershipPositions", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(identity_1.ProfileResponse),
        (0, tslib_1.__metadata)("design:type", identity_1.ProfileResponse)
    ], Response.prototype, "profile", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(EmailFailureResponse),
        (0, tslib_1.__metadata)("design:type", EmailFailureResponse)
    ], Response.prototype, "emailFailure", void 0);
    GetMember.Response = Response;
})(GetMember = exports.GetMember || (exports.GetMember = {}));

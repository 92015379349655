import { AsyncResult } from 'designed';

export const downloadURL = AsyncResult.wrap(
  async (url: string, filename?: string) => {
    const res = await fetch(url);
    const blob = await res.blob();

    const objectURL = window.URL.createObjectURL(blob);
    try {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = objectURL;
      if (filename) {
        a.download = filename;
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } finally {
      window.URL.revokeObjectURL(objectURL);
    }
  },
);

import * as React from 'react';
import { Button, MenuButton, Image } from '@chakra-ui/react';
import deputyLogo from 'assets/img/deputy-logo.png';
import xeroLogo from 'assets/img/xero-logo.png';

import {
  CreateBankAccountTaskDesignArgs,
  CreateDeputyLinkEmployeeTaskDesignArgs,
  CreateProfileTaskDesignArgs,
  CreateSuperChoiceTaskDesignArgs,
  CreateTFNDTaskDesignArgs,
  CreateVisaDetailsTaskDesignArgs,
  CreateXeroSyncEmployeeTaskDesignArgs,
  EsignConfigurationType,
  TaskDesignType,
} from '@zap-onboard/api-client';
import { track } from '../../../../libs/track';
import { createToast } from '../../../../helpers';

import { Menu } from '@zap-onboard/web-components';
import { useFlowDesignContext } from '../../../../pages/Business/FlowDesignDetail/FlowDesignContext';
import { CreateTaskDesignFormDataSimple } from './CreateTaskDesignFormData';
import { useFeatureFlag } from '../../..';

export const taskDesignTypeToIcon = {
  [TaskDesignType.RAW_TEXT]: <span>✏️</span>,
  [TaskDesignType.PROFILE]: <span>🧑</span>,
  [TaskDesignType.CLIENT_VERIFICATION]: <span>🔎</span>,
  [TaskDesignType.PROOF_OF_IDENTITY]: <span>🪪</span>,
  [TaskDesignType.VISA_DETAILS]: <span>🗂️</span>,
  [TaskDesignType.ESIGN]: <span>✍️</span>,
  [TaskDesignType.TEMPLATE]: <span>📄</span>,
  [TaskDesignType.FORM]: <span>📋</span>,
  [TaskDesignType.TFND]: <span>🏛</span>,
  [TaskDesignType.SUPER_CHOICE]: <span>☑️</span>,
  [TaskDesignType.BANK_ACCOUNT]: <span>🏦</span>,
  [TaskDesignType.DEPUTY_LINK_EMPLOYEE]: (
    <Image
      src={deputyLogo}
      style={{
        width: '14px',
      }}
    />
  ),
  [TaskDesignType.XERO_SYNC_EMPLOYEE]: (
    <Image
      src={xeroLogo}
      style={{
        width: '14px',
      }}
    />
  ),
} as const;

type AutoAddTasks =
  | CreateTFNDTaskDesignArgs
  | CreateProfileTaskDesignArgs
  | CreateSuperChoiceTaskDesignArgs
  | CreateDeputyLinkEmployeeTaskDesignArgs
  | CreateXeroSyncEmployeeTaskDesignArgs
  | CreateVisaDetailsTaskDesignArgs
  | CreateBankAccountTaskDesignArgs;

type AddNewTaskButtonProps = {
  isDisabled: boolean;
  setTaskIdx: (idx: number) => void;
};

export const AddNewTaskButton: React.FC<AddNewTaskButtonProps> = ({
  isDisabled,
  setTaskIdx,
}) => {
  const {
    componentState: { flowDesign, isTaskFormDirty },
    dispatch,
  } = useFlowDesignContext();

  const handleAddTask = (raw: CreateTaskDesignFormDataSimple) => {
    const newTaskIndex = flowDesign.tasks.length;
    track.event('Flow Design Added Task', { type: raw.type });
    dispatch({
      type: 'CREATE_TASK',
      payload: { ...raw, __isNew: true },
    });
    setTaskIdx(newTaskIndex);
  };

  const handleAutoAddTask = (task: AutoAddTasks) => {
    handleAddTask(task);
    dispatch({
      type: 'UPDATE_TASK',
      payload: {
        taskData: { ...task },
        taskIndex: flowDesign.tasks.length,
      },
    });
    createToast({
      status: 'success',
      description: 'Task added',
    });
  };

  function isTaskPresent(type: TaskDesignType) {
    return !!flowDesign.tasks.find((t) => t.type === type);
  }
  const isTemplatesOn = useFeatureFlag('ESIGN');

  return (
    <Menu
      render={() => (
        <MenuButton
          as={Button}
          variant="secondaryAction"
          data-testid="addTaskButton"
          disabled={isDisabled || isTaskFormDirty}
        >
          Add task
        </MenuButton>
      )}
      menuItems={[
        {
          categoryTitle: 'Standard',
          items: [
            {
              title: 'User profile',
              Icon: <span>🧑</span>,
              action: () =>
                handleAutoAddTask({
                  name: 'User profile',
                  type: TaskDesignType.PROFILE,
                  excludeFields: [],
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.PROFILE),
            },
            {
              title: 'TFN declaration',
              Icon: <span>🏛</span>,
              action: () =>
                handleAutoAddTask({
                  name: 'TFN Declaration',
                  type: TaskDesignType.TFND,
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.TFND),
            },
            {
              title: 'Super choice',
              Icon: <span>☑️</span>,
              action: () =>
                handleAutoAddTask({
                  name: 'Super Choice',
                  type: TaskDesignType.SUPER_CHOICE,
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.SUPER_CHOICE),
            },
            {
              title: 'Bank account',
              Icon: <span>🏦</span>,
              action: () =>
                handleAutoAddTask({
                  name: 'Nominate bank account',
                  type: TaskDesignType.BANK_ACCOUNT,
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.BANK_ACCOUNT),
            },
          ],
        },
        {
          categoryTitle: 'Custom',
          items: [
            {
              title: 'Form',
              Icon: <span>📋</span>,
              action: () =>
                handleAddTask({
                  name: '',
                  submitters: [
                    { type: 'SUBJECT', name: 'Subject', key: 'SUBJECT' },
                  ],
                  viewers: [],
                  schema: { items: [] },
                  type: TaskDesignType.FORM,
                }),
            },
            {
              title: 'E-Sign document',
              Icon: <span>✍️</span>,
              action: () =>
                handleAddTask({
                  name: '',
                  configuration: {
                    type: EsignConfigurationType.USE_UPLOADED,
                    claim: undefined,
                  },
                  type: TaskDesignType.ESIGN,
                }),
            },
            isTemplatesOn
              ? {
                  title: 'E-Sign template',
                  Icon: <span>📄</span>,
                  action: () =>
                    handleAddTask({
                      name: '',
                      type: TaskDesignType.TEMPLATE,
                      templateId: '',
                    }),
                }
              : undefined,
            {
              title: 'Visa details',
              Icon: <span>🗂️</span>,
              action: () =>
                handleAutoAddTask({
                  name: 'Visa details',
                  type: TaskDesignType.VISA_DETAILS,
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.VISA_DETAILS),
            },
          ],
        },
        {
          categoryTitle: 'Integrations',
          hideMenuCategory:
            isTaskPresent(TaskDesignType.DEPUTY_LINK_EMPLOYEE) &&
            isTaskPresent(TaskDesignType.XERO_SYNC_EMPLOYEE),
          items: [
            {
              title: 'Link Deputy employee',
              Icon: taskDesignTypeToIcon[TaskDesignType.DEPUTY_LINK_EMPLOYEE],
              action: () =>
                handleAutoAddTask({
                  name: 'Link to Deputy',
                  type: TaskDesignType.DEPUTY_LINK_EMPLOYEE,
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.DEPUTY_LINK_EMPLOYEE),
            },
            {
              title: 'Sync Xero employee',
              Icon: taskDesignTypeToIcon[TaskDesignType.XERO_SYNC_EMPLOYEE],
              action: () =>
                handleAutoAddTask({
                  name: 'Sync Xero employee',
                  type: TaskDesignType.XERO_SYNC_EMPLOYEE,
                }),
              hideMenuItem: isTaskPresent(TaskDesignType.XERO_SYNC_EMPLOYEE),
            },
          ],
        },
      ]}
    />
  );
};

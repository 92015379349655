"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetaTaskActions = exports.SkipTaskAction = exports.MetaTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
var MetaTaskActionType;
(function (MetaTaskActionType) {
    MetaTaskActionType["META_SKIP"] = "META_SKIP";
})(MetaTaskActionType = exports.MetaTaskActionType || (exports.MetaTaskActionType = {}));
class SkipTaskAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return SkipTaskAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SkipTaskAction.prototype, "taskId", void 0);
exports.SkipTaskAction = SkipTaskAction;
exports.MetaTaskActions = {
    [MetaTaskActionType.META_SKIP]: SkipTaskAction,
};

/* eslint-disable react/no-danger */
import { Box } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { purifyHTML } from '../helpers/purifyHTML';
import { theme } from '../theme';

export const HTMLDisplay = ({
  children,
  ...props
}: { children: string } & Partial<React.ComponentProps<typeof Box>>) => {
  const html = useMemo(() => purifyHTML(children), [children]);

  return (
    <Box
      {...props}
      sx={{
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes.md,
        h1: {
          fontSize: theme.fontSizes['3xl'],
          mb: 4,
        },
        h2: {
          fontSize: theme.fontSizes['2xl'],
          mb: 4,
        },
        h3: {
          fontSize: theme.fontSizes.xl,
          mb: 4,
        },
        bold: {
          fontWeight: 'bold',
        },
        a: {
          color: 'brand.blue',
          textDecoration: 'underline',
        },
        'a:hover': {
          color: 'brand.light-blue',
        },
        em: {},
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

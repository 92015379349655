import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isDev } from '../../helpers';

export const DevelopmentRoute: React.FC<React.ComponentProps<typeof Route>> = ({
  children,
  ...rest
}) => (
  <Route {...rest} component={isDev ? rest.component : undefined}>
    {isDev ? children : <Redirect to="/" />}
  </Route>
);

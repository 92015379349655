import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { createToast, z } from '../../../../helpers';

import { inputs } from '@zap-onboard/web-components';
import { FormActions } from '../FormActions';
import { useSendSMS } from '../../../../hooks/data/notifications';
import { membership } from '@zap-onboard/api-client';
import { auth } from '../../../../auth';

type Props = {
  onClose: () => unknown;
  memberId: membership.GetMember.Response['memberId'];
};

export const SendSMS: React.FC<Props> = ({ onClose, memberId }) => {
  const { sendSMS, isLoading } = useSendSMS();
  const [error, setError] = React.useState<string>('');
  const SendSMSSchema = z.object({
    message: z.string().min(1).max(320),
  });

  const userProfile = auth.useProfile();
  const manager = auth.useCurrentBusinessManager();
  const useFormMethods = useForm<z.infer<typeof SendSMSSchema>>({
    defaultValues: {
      message: `{type your message here...}\r\nSent from${
        userProfile?.givenName ? ` ${userProfile.givenName}` : ''
      } - ${manager?.businessName}\r\nDo not reply to this message`,
    },
    resolver: z.zodResolver(SendSMSSchema),
  });

  const onSubmit = useFormMethods.handleSubmit(async ({ message }) => {
    const result = await sendSMS({ message, memberId });

    result?.map(
      () => {
        createToast({
          status: 'success',
          description: `Message sent`,
        });
        onClose();
      },
      (err) => {
        setError(
          `${err.message} - ensure the member has a valid mobile number as primary contact.`,
        );
        return err;
      },
    );
  });

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={onSubmit}>
        <Stack>
          <inputs.Textarea name="message" label="Message" />
          {error && <Text color="brand.red">{error}</Text>}
          <FormActions
            buttonText="Send SMS"
            onClose={onClose}
            isLoading={isLoading}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

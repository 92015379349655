"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = exports.ResetMembersMFAResponse = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const handler_1 = require("../utils/handler");
const models_1 = require("./models");
const Position_1 = require("./models/Position");
const ArchiveMember_1 = require("./models/ArchiveMember");
const InviteMember_1 = require("./models/InviteMember");
const RevokeAccess_1 = require("./models/RevokeAccess");
const InviteList_1 = require("./models/InviteList");
const Role_1 = require("./AccessControl/Role");
const LoginFlowResponse_1 = require("../identity/models/LoginFlowResponse");
const ResponseBase_1 = require("../ResponseBase");
const contactNumber_1 = require("../utils/contactNumber");
class ResetMembersMFAResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], ResetMembersMFAResponse.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(contactNumber_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", contactNumber_1.ContactNumber)
], ResetMembersMFAResponse.prototype, "number", void 0);
exports.ResetMembersMFAResponse = ResetMembersMFAResponse;
class Client {
    constructor(http, $error, $loginFlow) {
        this.http = http;
        this.$error = $error;
        this.$loginFlow = $loginFlow;
        this.searchMembers = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/membership/members/search')
            .optionalParams(models_1.Search.Request.create(args).asJSON())
            .get((d) => models_1.Search.Response.create(d).members));
        this.addMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/membership/member/add')
            .bodyJSON(models_1.AddMember.Request.create(args))
            .post((d) => models_1.AddMember.Response.create(d)));
        this.getMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/membership/member')
            .optionalParams(args)
            .get((d) => models_1.GetMember.Response.create(d)));
        this.resetMFA = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/membership/member/reset-mfa')
            .bodyJSON(ResetMembersMFAResponse.create(args).asJSON())
            .post());
        this.updateProfile = (0, handler_1.handler)(this, async (args) => {
            await this.http
                .request()
                .to(`/membership/member/profile`)
                .bodyJSON(models_1.UpdateProfile.Request.create(args))
                .post();
        });
        this.toggleArchiveMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .bodyJSON(ArchiveMember_1.ArchiveMember.Request.create(args))
            .to('/membership/member/archive')
            .post());
        this.position = positions.bind(this)();
        this.accessControl = {
            setRole: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/access')
                .bodyJSON(Role_1.SetRole.Request.create(args))
                .post()),
        };
        this.accessRequest = {
            forMyBusiness: (0, handler_1.handler)(this, async (args = {}) => this.http
                .request()
                .to('/membership/business/access/requests')
                .optionalParams(models_1.AccessRequest.List.Request.create(args).asJSON())
                .get((d) => models_1.AccessRequest.List.Response.create(d).requests)),
            mine: (0, handler_1.handler)(this, async (args = {}) => this.http
                .request()
                .to('/membership/my/access/requests')
                .optionalParams(models_1.AccessRequest.List.Request.create(args).asJSON())
                .get((d) => models_1.AccessRequest.List.Response.create(d).requests)),
            accept: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/access/request/accept')
                .bodyJSON(models_1.AccessRequest.Accept.Request.create(args))
                .post((d) => models_1.AccessRequest.Accept.Response.create(d))),
            reject: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/access/request/reject')
                .bodyJSON(models_1.AccessRequest.Reject.Request.create(args))
                .post()),
            open: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/access/request')
                .bodyJSON(models_1.AccessRequest.Open.Request.create(args))
                .post()),
        };
        this.revokeAccessToMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/membership/member/revoke')
            .bodyJSON(RevokeAccess_1.RevokeAccess.Request.create(args))
            .post());
        this.invitation = {
            mine: (0, handler_1.handler)(this, async () => this.http
                .request()
                .to('/membership/invites')
                .get((d) => InviteList_1.InviteList.Response.create(d).items)),
            sendToMember: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/invite')
                .bodyJSON(InviteMember_1.InviteMember.Request.create(args))
                .post()),
            acceptWithToken: (0, handler_1.handler)(this, async (args) => {
                const response = await this.http
                    .request()
                    .to('/membership/invite/accept/token')
                    .bodyJSON(models_1.AcceptInvitation.TokenRequest.create(args))
                    .post((data) => LoginFlowResponse_1.LoginFlowResponse.create(data));
                this.$loginFlow.publish(response);
                return response;
            }),
            acceptFromBusiness: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/invite/accept/business')
                .bodyJSON(models_1.AcceptInvitation.BusinessRequest.create(args))
                .post()),
            declineFromBusiness: (0, handler_1.handler)(this, async (args) => this.http
                .request()
                .to('/membership/invite/decline/business')
                .bodyJSON(models_1.AcceptInvitation.BusinessRequest.create(args))
                .post()),
        };
    }
}
exports.Client = Client;
function positions() {
    const attach = (0, handler_1.handler)(this, async (args) => this.http
        .request()
        .to('/membership/member/position/attachment')
        .bodyJSON(Position_1.Position.Attach.Request.create(args))
        .post());
    // TODO: Unlink needs to accept a positionId
    const unlink = (0, handler_1.handler)(this, async (args) => this.http
        .request()
        .to('/membership/member/position/attachment')
        .bodyJSON(Position_1.Position.Remove.Request.create(args))
        .delete());
    const change = (0, handler_1.handler)(this, async (args) => await unlink({ memberId: args.memberId })
        .flatMapAsync(() => attach(args))
        .getOrThrowFailure());
    return {
        attach,
        change,
        unlink,
    };
}

import { useHandlerQuery } from '../../useHandlerQuery';

export const useVisaChecksForMember = (memberId: string) => {
  const { data, isLoading } = useHandlerQuery(
    (api) => api.visa().getVisaChecksForMember({ memberId }),
    {
      key: ['VISA_CHECKS', memberId],
    },
  );
  return {
    isVisaChecksForMemberLoading: isLoading,
    visaChecksForMember: data?.visaChecks,
  };
};

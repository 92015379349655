import { Route } from 'react-router-dom';
import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
} from '../../routes/RouteTypes';
import { AcceptInvitePage } from './AcceptInvite';
import { AuthPage } from './AuthPage';
import { ConfirmRegistration } from './ConfirmRegistration';
import { LoginPage } from './Login';
import { MagicLink } from './MagicLink';
import { SignupPage } from './Signup';

export const Routes = () => {
  return [
    <Route
      exact
      key="accept-invite"
      path="/accept-invite"
      component={AcceptInvitePage}
    />,
    <UnauthenticatedRoute key="login" path="/login" component={LoginPage} />,
    <UnauthenticatedRoute
      key="confirm-registration"
      path="/confirm-registration"
    >
      <ConfirmRegistration />
    </UnauthenticatedRoute>,
    <UnauthenticatedRoute key="signup" path="/signup" component={SignupPage} />,
    <AuthenticatedRoute
      key="authentication"
      path="/authentication"
      component={AuthPage}
    />,
    <Route key="magic-link-login" path="/magic-link-login">
      <MagicLink />
    </Route>,
  ];
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveCertificationDesign = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var ArchiveCertificationDesign;
(function (ArchiveCertificationDesign) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "certificationDesignId", void 0);
    ArchiveCertificationDesign.Request = Request;
})(ArchiveCertificationDesign = exports.ArchiveCertificationDesign || (exports.ArchiveCertificationDesign = {}));

import * as React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

type WarningModalProps = {
  header?: string;
  body: string;
  isWarning: boolean;
  setIsWarning: (val: false) => void;
};

export const WarningModal: React.FC<WarningModalProps> = ({
  body,
  header,
  isWarning,
  setIsWarning,
}) => {
  const { onClose } = useDisclosure();

  const handleClick = (): void => setIsWarning(false);

  return (
    <Modal isOpen={isWarning} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent m={4}>
        <ModalHeader fontSize="md">{header || 'Warning'}</ModalHeader>
        <ModalBody fontSize="sm" pb={6}>
          {body}
        </ModalBody>

        <ModalFooter>
          <Button variant="outline" mr={3} onClick={handleClick}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import { useRadioGroupContextForItem } from './RadioGroup';
export var RadioButton = function (_a) {
    var children = _a.children, _wrapper = _a._wrapper, _button = _a._button, propsDisabled = _a.isDisabled, props = __rest(_a, ["children", "_wrapper", "_button", "isDisabled"]);
    var _b = useRadioGroupContextForItem(props), checkboxProps = _b.checkboxProps, inputProps = _b.inputProps, labelProps = _b.labelProps, state = _b.state;
    var isChecked = state.isChecked, hideUnselected = state.hideUnselected;
    var isDisabled = state.isDisabled || propsDisabled;
    if (hideUnselected && !isChecked) {
        return null;
    }
    return (_jsxs(Box, __assign({}, labelProps, { as: "label" }, _wrapper, { children: [_jsx("input", __assign({}, inputProps), void 0), _jsx(Box, __assign({}, checkboxProps, { layerStyle: "base", px: 5, py: 3, cursor: isDisabled ? 'inherit' : 'pointer', _focus: {
                    boxShadow: 'none',
                }, _disabled: {
                    bg: 'brand.gray',
                    borderColor: 'brand.gray',
                    boxShadow: 'none',
                }, _checked: {
                    borderColor: 'brand.blue',
                    color: 'brand.white',
                    bg: 'blue.50',
                } }, _button, { children: children }), void 0)] }), void 0));
};

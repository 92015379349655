"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembershipDetails = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const RemoteFile_1 = require("../../file/RemoteFile");
const Role_1 = require("../../membership/AccessControl/Role");
const ResponseBase_1 = require("../../ResponseBase");
const UserDetails_1 = require("./UserDetails");
class MembershipDetails extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], MembershipDetails.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], MembershipDetails.prototype, "businessId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MembershipDetails.prototype, "businessName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Role_1.Role),
    (0, tslib_1.__metadata)("design:type", Role_1.Role)
], MembershipDetails.prototype, "role", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
], MembershipDetails.prototype, "logo", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: UserDetails_1.MembershipPositions }),
    (0, class_validator_1.IsInstance)(UserDetails_1.MembershipPositions, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], MembershipDetails.prototype, "membershipPositions", void 0);
exports.MembershipDetails = MembershipDetails;

import * as React from 'react';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import {
  ABN,
  AccountName,
  SuperChoice,
  SuperMemberNumber,
} from '@zap-onboard/api-client';
import { SuperChoiceNameAutocomplete } from './SuperChoiceNameAutocomplete';
import { z } from '../../../../../../helpers/schema';
import { inputs } from '@zap-onboard/web-components';
import { SuperTypes } from '../SuperType';

type APRAOrRSAProps = {
  isDisabled: boolean;
};

export const APRAOrRSASchema = z.object({
  superChoiceType: z.enum([SuperTypes.APRA_OR_RSA]),
  fundABN: z.valueObject(z.string(), ABN),
  fundSPINOrUSI: z.string().min(1, { message: 'USI is required' }),
  fundName: z.string().min(1, { message: 'Fund name is required' }),
  memberNumber: z.valueObject(
    z.string().min(1, { message: 'Member number is required' }),
    SuperMemberNumber,
  ),
  accountName: z.optionalValueObject(z.string(), AccountName),
});

export const getAPRAOrRSADefaultValues = (
  choice?: SuperChoice,
): Omit<z.infer<typeof APRAOrRSASchema>, 'superChoiceType'> => {
  if (!choice || choice.value.type !== 'SUPER_CHOICE_APRA_OR_RSA') {
    return {
      fundABN: '',
      memberNumber: '',
      fundSPINOrUSI: '',
      fundName: '',
      accountName: '',
    };
  }

  return {
    fundABN: choice.value.fundABN.asJSON(),
    memberNumber: choice.value.memberNumber.asJSON(),
    fundSPINOrUSI: choice.value.fundSPINOrUSI,
    fundName: choice.value.fundName,
    accountName: choice.value.accountName?.asJSON() ?? '',
  };
};

export const APRAOrRSA: React.FC<APRAOrRSAProps> = ({ isDisabled }) => {
  const { setValue } = useFormContext();
  const superFundSearchTip = ({ inputName }: { inputName: string }) =>
    `The ${inputName} field will automatically generate when you search and select a super fund.`;

  return (
    <Stack spacing={4}>
      <Stack>
        <Heading variant="subSection">Nominating your APRA fund or RSA</Heading>
        <Text>
          You will need current details from your APRA regulated fund or RSA to
          complete this item.
        </Text>
      </Stack>
      <Stack spacing={6}>
        <SuperChoiceNameAutocomplete
          onPicked={({ abn, usi, productName }) => {
            setValue('superChoice.fundName', productName ?? '');
            setValue('superChoice.fundABN', abn?.asJSON() ?? '');
            setValue('superChoice.fundSPINOrUSI', usi ?? '');
          }}
          isDisabled={isDisabled}
        />

        <inputs.TextInput
          name="superChoice.fundABN"
          label="Fund ABN"
          helper={superFundSearchTip({ inputName: 'ABN' })}
          placeholder="Fund ABN"
          isReadOnly
        />

        <inputs.TextInput
          label="Fund USI"
          name="superChoice.fundSPINOrUSI"
          helper={superFundSearchTip({ inputName: 'USI' })}
          placeholder="Fund USI"
          isReadOnly
        />

        <inputs.TextInput
          label="Your account name (if applicable)"
          name="superChoice.accountName"
          placeholder="If applicable, please fill in"
          isDisabled={isDisabled}
        />

        <inputs.TextInput
          label="Your member number"
          placeholder="If applicable, please fill in"
          name="superChoice.memberNumber"
          isDisabled={isDisabled}
        />
      </Stack>
    </Stack>
  );
};

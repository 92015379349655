/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react';
import { SizePX } from '@zap-onboard/pdfme-common';
import { PX_IN_MM } from '../constants';
import { usePDFState } from '../usePDFState';
import { RemoteFile } from '@zap-onboard/api-client';
import { useUpgradePDF } from './useUpgradePDF';

type RemoteUIPreProcessorProps = {
  file: RemoteFile;
  outerSize: SizePX;
};

export const pt2mm = (pt: number) => {
  // https://www.ddc.co.jp/words/archives/20090701114500.html
  const mmRatio = 0.3527;

  return parseFloat(String(pt)) * mmRatio;
};

const MAX_DEFAULT_SCALE = 1.5;

export const useRemoteUIPreProcessor = ({
  file,
  outerSize,
}: RemoteUIPreProcessorProps) => {
  const upgradeJob = useUpgradePDF(file);

  const { backgrounds } = useMemo(() => {
    return {
      backgrounds: upgradeJob.document?.pages.map((p) => p.image.url) ?? [],
    };
  }, [upgradeJob.document]);

  useEffect(() => {
    if (!upgradeJob.document) {
      return;
    }

    const { size } = upgradeJob.document.pages[0]!;
    const paperWidth = pt2mm(size.width.pts) * PX_IN_MM + 180;
    const scaledToFit = outerSize.width / paperWidth;
    const scale =
      scaledToFit > MAX_DEFAULT_SCALE ? MAX_DEFAULT_SCALE : scaledToFit;

    const pageSizes = upgradeJob.document.pages.map(
      ({ size }) =>
        ({
          type: 'MM',
          height: pt2mm(size.height.pts),
          width: pt2mm(size.width.pts),
        }) as const,
    );
    usePDFState.setState({ pageSizes, scale });
  }, [upgradeJob.document, outerSize.height, outerSize.width]);

  return { backgrounds, upgradeJob };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Center, Text, Heading, Stack, HStack, Spacer, } from '@chakra-ui/react';
export var Container = function (_a) {
    var children = _a.children;
    return (_jsx(Center, __assign({ bgGradient: "linear(to-b, brand.blue, brand.dark-blue)", w: "100vw", minH: "100vh" }, { children: _jsx(Box, __assign({ layerStyle: "base", w: { base: '90%', md: '80%', lg: '900px' } }, { children: _jsx(Stack, __assign({ h: "100%" }, { children: children }), void 0) }), void 0) }), void 0));
};
export var Content = function (_a) {
    var children = _a.children;
    return (_jsx(Stack, __assign({ py: 6, px: { base: 6, md: 12 } }, { children: children }), void 0));
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var Header = function (_a) {
    var children = _a.children, description = _a.description;
    return (_jsxs(Stack, __assign({ p: 6 }, { children: [_jsx(Heading, __assign({ size: "md" }, { children: children }), void 0), description && _jsx(Text, { children: description }, void 0)] }), void 0));
};
export var Footer = function (_a) {
    var children = _a.children;
    return (_jsxs(_Fragment, { children: [_jsx(Spacer, {}, void 0), _jsxs(HStack, __assign({ p: 6 }, { children: [_jsx(Spacer, {}, void 0), children] }), void 0)] }, void 0));
};

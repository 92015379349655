import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import config from '../config';
import { History } from 'history';
import { errs } from '@zap-onboard/errors';

export function initializeSentry<T extends React.FC>(
  Component: T,
  history: History,
): T {
  if (window.location.hostname !== 'localhost') {
    Sentry.init({
      release: process.env.REACT_APP_VERSION,
      beforeSend(event, hint) {
        if (hint?.originalException instanceof errs.PublicError) {
          const e = hint.originalException;
          // eslint-disable-next-line no-param-reassign
          event.fingerprint = [
            e.message,
            e.apiCode,
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...e?.getPreviousErrors().map((e) => e.message),
          ];
        }
        return event;
      },
      environment: config.name,
      integrations: [
        new ExtraErrorData({ depth: 19 }),
        new TracingIntegrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          tracingOrigins: [
            'localhost',
            'api.canyou.com.au',
            'api.nonprod.canyou.com.au',
          ],
        }),
      ],
      normalizeDepth: 20,
      tracesSampleRate: 0.2,
      dsn: 'https://90663edaa6804bfb8d9b4a995a9d3c0a@o477662.ingest.sentry.io/5519056',
    });
    return Sentry.withProfiler(Component) as T;
  }
  return Component;
}

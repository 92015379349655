export var Textarea = {
    baseStyle: {
        minH: '125px',
    },
    sizes: {},
    variants: {},
    defaultProps: {
        size: 'md',
    },
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountNumber = void 0;
const errors_1 = require("@zap-onboard/errors");
class AccountNumber {
    constructor(value) {
        this.value = value;
    }
    static fake() {
        return AccountNumber.fromJSON('132 414');
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('Account number was given a non string value');
        }
        const onlyDigits = s.replace(/\D/g, '');
        const number = new AccountNumber(onlyDigits);
        number.validate();
        return number;
    }
    static isValid(s) {
        return AccountNumber.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            AccountNumber.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    toString() {
        return `AccountNumber{${this.value}}`;
    }
    asJSON() {
        return this.value;
    }
    validate() {
        if (this.value.length < 1) {
            throw errors_1.errs.ValidationError.create('Account number must be at least one digit long');
        }
        if (this.value.length > 9) {
            throw errors_1.errs.ValidationError.create('Account number must be at most 9 digits long');
        }
        if (this.value.split('').find((digit) => Number.isNaN(Number(digit)))) {
            throw errors_1.errs.ValidationError.create('Account number must only contain numbers');
        }
    }
}
exports.AccountNumber = AccountNumber;

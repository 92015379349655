import { useEffect } from 'react';
import { usePDFFontContext } from '../contexts';

export const useLoadFont = () => {
  const font = usePDFFontContext();
  useEffect(() => {
    const fontFaces = Object.entries(font).map((entry) => {
      const [key, value] = entry;
      const fontFace = new FontFace(key, value.data);
      return fontFace.load();
    });
    Promise.all(fontFaces).then((loadedFontFaces) => {
      loadedFontFaces.forEach((loadedFontFace) => {
        if (document && document.fonts && document.fonts.add) {
          document.fonts.add(loadedFontFace);
        }
      });
    });
  }, [font]);
};

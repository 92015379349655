/* eslint-disable react/no-array-index-key */
import { ReactNode, useRef } from 'react';
import { getFallbackFontName, SizePX } from '@zap-onboard/pdfme-common';
import { usePDFFontContext } from '../contexts';
import { PX_IN_MM } from '../constants';
import { usePDFState } from '../usePDFState';
import { pdf } from '@zap-onboard/api-client';
import { useDroppable } from '@dnd-kit/core';
import {
  useLazyBackground,
  useObserveIntersections,
} from '../../hooks/useLazyImage';
import { Stack } from '@chakra-ui/react';
import { ScaledBox } from './ScaledBox';

interface Props {
  backgrounds: string[];
  renderPaper?: (arg: { index: number; paperSize: SizePX }) => ReactNode;
  renderField: (arg: { field: pdf.Schema.Field.UIData }) => ReactNode;
}

export const Pages = (props: Props) => {
  const { backgrounds } = props;

  const intersectionMap = useRef<Map<number, number>>(new Map());
  const paperRefs = usePDFState((s) => s.refs.papers);
  useObserveIntersections(paperRefs, (events) => {
    events.forEach((event) => {
      const pageId = Number(event.target.id.replace('page-', ''));
      if (Number.isNaN(pageId)) {
        return;
      }
      intersectionMap.current.set(pageId, event.intersectionRatio);
    });

    const max: [page: number, ratio: number] = [0, 0];
    intersectionMap.current.forEach((ratio, page) => {
      if (ratio > max[1]) {
        max[0] = page;
        max[1] = ratio;
      }
    });
    usePDFState.getState().setCursor(max[0]);
  });

  return (
    <Stack mx={8} mt={10} spacing={4}>
      {backgrounds.map((_, paperIndex) => {
        return (
          <PaperPage key={`${paperIndex}page`} {...props} index={paperIndex} />
        );
      })}
    </Stack>
  );
};

const PaperPage = (props: Props & { index: number }) => {
  const { backgrounds, renderPaper, renderField, index } = props;
  const font = usePDFFontContext();

  const pageCursor = usePDFState((s) => s.pageCursor);
  const { setNodeRef, node } = useDroppable({
    id: `page-${index}`,
    data: {
      page: index,
    },
  });

  const fields = usePDFState((state) => state.fields);
  const pageSizes = usePDFState((s) => s.pageSizes);
  const pageSize = {
    width: pageSizes[index].width * PX_IN_MM,
    height: pageSizes[index].height * PX_IN_MM,
    type: 'PX',
  } as const;

  const background = useLazyBackground(
    backgrounds[index],
    {
      loadedCss: {
        backgroundSize: `${pageSize.width}px ${pageSize.height}px`,
      },
    },
    node,
  );

  return (
    <ScaledBox
      id={`page-${index}`}
      height={pageSize.height}
      width={pageSize.width}
      scale={usePDFState((s) => s.computed.scale)}
      animation={background.animation}
      wrapperRef={setNodeRef}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={(e: any) => {
        if (e) {
          usePDFState.getState().refs.papers.set(index, e);
        }
      }}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onClick={(e: any) => {
        if (
          e.currentTarget === e.target &&
          document &&
          document.hasFocus() &&
          document.activeElement instanceof HTMLElement
        ) {
          document.activeElement.blur();
          usePDFState.setState({ focusedFieldId: undefined });
        }
      }}
      {...background.style}
      fontFamily={`'${getFallbackFontName(font)}'`}
      boxShadow={
        pageCursor === index ? '0px 0px 0px 1px blue' : '0px 0px 0px 1px #ccc'
      }
    >
      {renderPaper &&
        renderPaper({
          paperSize: pageSize,
          index,
        })}
      {fields
        .filter((f) => f.page === index)
        .map((field) => {
          return renderField({ field });
        })}
    </ScaledBox>
  );
};

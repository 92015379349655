import * as React from 'react';
import { Center, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { MdErrorOutline } from 'react-icons/md';

export const ExpiredLink: React.FC = () => (
  <Center
    bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
    minH="100vh"
    minW="100vw"
  >
    <Center
      layerStyle="base"
      minH="400px"
      minW={{ base: '90%', md: '70%', lg: '50%', xl: '40%' }}
      p={3}
      m={6}
    >
      <Stack spacing={2} align="center">
        <HStack>
          <Icon as={MdErrorOutline} boxSize="1.5em" color="red.400" />
          <Text fontWeight="bold">An error occurred</Text>
        </HStack>
        <Text>The link you have used is expired. </Text>
      </Stack>
    </Center>
  </Center>
);

export const queryString = (
  name: string,
  url = window.location.href,
): null | string => {
  const params = new URL(url).searchParams;
  const param = params.get(name);
  if (!param) {
    return null;
  }
  return param;
};

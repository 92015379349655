import { useEffect } from 'react';
import { usePDFState } from '../usePDFState';

/**
 * Ensures that when the user zooms in/out the focus stays centered.
 */
export const useMaintainZoomScroll = () => {
  useEffect(
    () =>
      usePDFState.subscribe(
        ({ zoom: currentZoom, scale }, { zoom: prevZoom }) => {
          const designerRef = usePDFState.getState().refs.designer;

          if (!designerRef.current || currentZoom === prevZoom) {
            return;
          }
          const curScale = scale * currentZoom;
          const prevScale = scale * prevZoom;

          const updateScrollValue = (n: number) => (curScale / prevScale) * n;

          const { scrollTop, clientHeight, clientWidth, scrollLeft } =
            designerRef.current;

          const newScrollTop =
            updateScrollValue(scrollTop + clientHeight / 2) - // Scale the scroll position
            updateScrollValue(clientHeight / 2); // Center the scroll position
          const newScrollLeft =
            updateScrollValue(scrollLeft + clientWidth / 2) -
            updateScrollValue(clientWidth / 2); // Center the scroll position

          setTimeout(() => {
            designerRef.current?.scrollTo({
              top: newScrollTop,
              left: newScrollLeft,
              behavior: 'auto',
            });
          }, 0);
        },
      ),
    [],
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowDesignClient = exports.GetFlowDesignsRequest = exports.GetFlowDesignsResponse = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const handler_1 = require("../../utils/handler");
const FlowDesignListing_1 = require("./models/FlowDesignListing");
const FlowDesign_1 = require("./models/FlowDesign");
const ResponseBase_1 = require("../../ResponseBase");
const FlowDesignSharing_1 = require("./models/FlowDesignSharing");
const SelfService_1 = require("./models/SelfService");
const FlowDesignTemplate_1 = require("./models/FlowDesignTemplate");
class GetFlowDesignsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: FlowDesignListing_1.FlowDesignListing }),
    (0, class_validator_1.IsInstance)(FlowDesignListing_1.FlowDesignListing, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GetFlowDesignsResponse.prototype, "flowDesigns", void 0);
exports.GetFlowDesignsResponse = GetFlowDesignsResponse;
class GetFlowDesignsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetFlowDesignsRequest.prototype, "showDeleted", void 0);
exports.GetFlowDesignsRequest = GetFlowDesignsRequest;
class FlowDesignClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        /**
         * Returns flow designs for the business. This can only be accessed by
         * business users.
         * @example
         * api.flowDesign().getFlowDesigns()
         */
        this.getFlowDesigns = (0, handler_1.handler)(this, async (opts) => this.http
            .request()
            .to(`/flow-designs`)
            .optionalParams({ ...opts })
            .get((d) => GetFlowDesignsResponse.create(d)));
        /**
         * Throws a `VersionMismatchError` if the stored version does not match what
         * is stored in the API. This is to prevent accidentally modifying a flow across
         * multiple tabs and committing potentially incorrect changes.
         *
         * @example
         * ```ts
         * api.flowDesign().createFlowDesign({
         *   businessId,
         *   name: 'Senior Butcher'
         *   tasks: []
         * })
         * ```
         */
        this.createFlowDesign = (0, handler_1.handler)(this, async (flowDesign) => await this.http
            .request()
            .bodyJSON({ ...flowDesign })
            .to(`/flow-designs`)
            .post((data) => data));
        /**
         * Get a complete flow design including task designs.
         */
        this.getFlowDesign = (0, handler_1.handler)(this, async (flowDesignId) => this.http
            .request()
            .to(`/flow-designs/${flowDesignId}`)
            .get((data) => FlowDesign_1.FlowDesign.create({ ...data })));
        this.deleteFlowDesign = (0, handler_1.handler)(this, async (flowDesignId, closeExistingFlows) => this.http
            .request()
            .to(`/flow-designs/${flowDesignId}`)
            .bodyJSON({ closeExistingFlows })
            .delete());
        this.import = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/flow-designs/import`)
            .bodyJSON(FlowDesignSharing_1.FlowDesignSharing.ImportRequest.create(args).toJSON())
            .post((d) => FlowDesignSharing_1.FlowDesignSharing.ImportResponse.create(d)));
        this.export = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/flow-designs/export`)
            .bodyJSON(FlowDesignSharing_1.FlowDesignSharing.ExportRequest.create(args).toJSON())
            .post((d) => FlowDesignSharing_1.FlowDesignSharing.ExportResponse.create(d)));
        this.setSelfService = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/flow-designs/toggle-self-service`)
            .bodyJSON(SelfService_1.SelfService.SetRequest.create(args).toJSON())
            .post());
        /** Since flow designs return their self service status by default this is only used for non-admin members */
        this.getSelfServiceDesigns = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .optionalParams(args)
            .to(`/flow-designs/self-service`)
            .get((d) => SelfService_1.SelfService.GetResponse.create(d)));
        this.startSelfServiceFlow = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/flow-designs/self-service/start')
            .bodyJSON(SelfService_1.SelfService.StartRequest.create(args))
            .post((b) => SelfService_1.SelfService.StartResponse.create(b)));
        this.getFlowDesignTemplates = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to(`/flow-designs/templates`)
            .get((d) => FlowDesignTemplate_1.FlowDesignTemplateList.create(d)));
        this.importFlowDesignTemplate = (0, handler_1.handler)(this, async (flowDesignTemplateRequest) => this.http
            .request()
            .to('/flow-designs/import-template')
            .bodyJSON(FlowDesignTemplate_1.ImportFlowDesignTemplateRequest.create(flowDesignTemplateRequest))
            .post());
    }
}
exports.FlowDesignClient = FlowDesignClient;

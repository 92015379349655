export const prettyTaskStatus = (taskStateId?: string): string => {
  let status;
  if (taskStateId) {
    status = {
      CLIENT_VERIFICATION_UNSUBMITTED: 'Awating client submission',
      CLIENT_VERIFICATION_SUBMITTED: 'Awaiting approval',
      CLIENT_VERIFICATION_APPROVED: 'Approved',
      CLIENT_VERIFICATION_REJECTED: 'Rejected',
      META_SKIPPED: 'Skipped',
    }[taskStateId];
  }
  return status || 'Preview';
};

export const prettyIndividualSingular = (individualType?: string): string => {
  if (!individualType) {
    return 'individual';
  }
  return individualType.toLowerCase().replace('_', '-');
};

export const prettyIndividualType = (
  individualType:
    | 'INDIVIDUAL'
    | 'SOLE_TRADER'
    | 'PARTNERSHIP'
    | 'COMPANY'
    | 'TRUST',
) => {
  return individualType === 'INDIVIDUAL'
    ? "individuals'"
    : {
        SOLE_TRADER: "sole-trader's",
        PARTNERSHIP: "partnership's",
        COMPANY: "company's",
        TRUST: "trust's",
      }[individualType];
};

const partnershipVerificationDocuments = [
  'Constituting or governing documentation (for example, a partnership agreement)',
  "Invoices issued/received in the partnership's name",
  "Proof of the partnership's business address",
];

const sharedCompanyAndTrustDocs = [
  'Constituting or governing documentation (for example, a trust deed)',
  'Extracts issued by the Australian Securities and Investments Commission (ASIC) or other Australian Government body',
];

const companyVerificationDocuments = [
  ...sharedCompanyAndTrustDocs,
  "Invoices issued/received in the company's name",
  "Proof of the company's business address",
];

const trustVerificationDocuments = [...sharedCompanyAndTrustDocs];

const representativeOfIndividualDocuments = [
  'Official or legal documents demonstrating parental, guardianship or power of attorney representation, for example:',
  'Enduring power of attorney or similar document o birth certificate',
  'Adoption paper',
  'Court order',
  'Letter of authority',
  "Aigned doctor's letter with explanation of circumstances.",
];

const representativeOfSoleTraderDocuments = [
  'Letter of authority',
  'ABR details',
  'Employment contract indicating position; for example, manager',
];

const representativeOfPartnershipDocuments = [
  'Letter of authority',
  'ABR details',
  'A partnership agreement',
  'Employment contract indicating position; for example, manager',
];

const representativeOfNonIndividualDocuments = [
  'An Annual Company Statement or current company extract from Australian Securities & Investment Commission (ASIC), identifying the individual as an officeholder',
  'Confirmation from ASIC that the individual is an officeholder; for example, through the ASIC registered agent portal if you are also an ASIC registered agent',
  'A trust deed',
  'The constitution',
  'The constitution of a registered cooperative',
  'Copies of board meeting minutes documenting the appointment',
  'ABR details',
  'Employment contract indicating position; for example, manager',
];

export const nonIndividualVerificationDocs = {
  COMPANY: companyVerificationDocuments,
  PARTNERSHIP: partnershipVerificationDocuments,
  TRUST: trustVerificationDocuments,
} as const;

export const representativeDocs = {
  INDIVIDUAL: representativeOfIndividualDocuments,
  SOLE_TRADER: representativeOfSoleTraderDocuments,
  PARTNERSHIP: representativeOfPartnershipDocuments,
  COMPANY: representativeOfNonIndividualDocuments,
  TRUST: representativeOfNonIndividualDocuments,
};

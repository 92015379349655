import * as React from 'react';
import { Text, Link, HStack } from '@chakra-ui/react';
import { inputs } from '@zap-onboard/web-components';

type Props = {
  hasAcceptedTerms: boolean;
  toggle: () => unknown;
};

export const AcceptTOCCheckbox: React.FC<Props> = ({
  hasAcceptedTerms,
  toggle,
}) => (
  <HStack pt={4} spacing={4}>
    <inputs.checkbox.SingleUncontrolled
      aria-label="I have read and accepted the terms of service and privacy policy"
      data-testid="acceptTermsCheckbox"
      name="hasAcceptedTermsOfService"
      isChecked={hasAcceptedTerms}
      onChange={toggle}
    >
      <Text fontSize="xs">
        I have read and accepted the{' '}
        <Link
          color="brand.blue"
          href="https://canyou.com.au/terms-and-conditions"
          isExternal
          tabIndex={-1}
        >
          Terms and Conditions
        </Link>{' '}
        and{' '}
        <Link
          color="brand.blue"
          href="https://canyou.com.au/privacy-policy"
          isExternal
          tabIndex={-1}
        >
          Privacy Policy
        </Link>
      </Text>
    </inputs.checkbox.SingleUncontrolled>
  </HStack>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoFormSubmissionItem = exports.VideoFormSchemaItem = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FormSchemaItem_1 = require("./FormSchemaItem");
const type = 'VIDEO';
class VideoFormSchemaItem extends FormSchemaItem_1.FormSchemaItem {
    constructor() {
        super(...arguments);
        this.type = type;
    }
    async validateItem() { }
    makeEmptySubmission() {
        return VideoFormSubmissionItem.create({
            type: 'VIDEO',
            formSchemaItemId: this.formSchemaItemId,
        });
    }
}
VideoFormSchemaItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], VideoFormSchemaItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], VideoFormSchemaItem.prototype, "videoId", void 0);
exports.VideoFormSchemaItem = VideoFormSchemaItem;
class VideoFormSubmissionItem extends FormSchemaItem_1.FormSubmissionItemBase {
    constructor() {
        super(...arguments);
        this.type = type;
    }
}
VideoFormSubmissionItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], VideoFormSubmissionItem.prototype, "type", void 0);
exports.VideoFormSubmissionItem = VideoFormSubmissionItem;

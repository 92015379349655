import * as React from 'react';
import { Divider as ChakraDivider, Flex, Text } from '@chakra-ui/react';

import { ChakraDividerProps, ChakraTextProps } from '../../types/Chakra';

type Props = {
  text?: string;
  dividerProps?: Partial<ChakraDividerProps>;
  textProps?: Partial<ChakraTextProps>;
};

export const Divider: React.FC<Props> = ({ text, dividerProps, textProps }) => {
  if (text) {
    return (
      <Flex align="center" gridGap={2}>
        <ChakraDivider color="brand.dark-gray" {...dividerProps} />
        <Text color="brand.dark-gray" fontSize="xs" {...textProps}>
          {text}
        </Text>
        <ChakraDivider color="brand.dark-gray" {...dividerProps} />
      </Flex>
    );
  }

  return <ChakraDivider {...dividerProps} />;
};

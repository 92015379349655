"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormTaskActions = exports.SubmitFormTaskAction = exports.FormTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FormSubmission_1 = require("./FormSubmission");
var FormTaskActionType;
(function (FormTaskActionType) {
    FormTaskActionType["FORM_SUBMIT"] = "FORM_SUBMIT";
})(FormTaskActionType = exports.FormTaskActionType || (exports.FormTaskActionType = {}));
class SubmitFormTaskAction extends FormSubmission_1.FormSubmissionResponse {
    static fromArgs(submission) {
        return SubmitFormTaskAction.create(submission);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SubmitFormTaskAction.prototype, "taskId", void 0);
exports.SubmitFormTaskAction = SubmitFormTaskAction;
exports.FormTaskActions = {
    [FormTaskActionType.FORM_SUBMIT]: SubmitFormTaskAction,
};

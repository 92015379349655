/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';

import { ZapIcon } from '../../Icons/Zap';

type InvitationCardProps = {
  inviterName: string;
  isAcceptingInvite: boolean;
  isDecliningInvite: boolean;
  message: string;
  acceptInvite: () => unknown;
  declineInvite: () => unknown;
};

export const InvitationCard: React.FC<InvitationCardProps> = ({
  inviterName,
  isAcceptingInvite,
  isDecliningInvite,
  message,
  acceptInvite,
  declineInvite,
}) => (
  <Flex
    layerStyle="base"
    minH="160px"
    maxW="280px"
    direction="column"
    justify="space-between"
    h="100%"
    p={3}
  >
    <Stack spacing={2}>
      <Flex align="center" gridGap={2}>
        <ZapIcon iconColor="brand.blue" width="16px" height="16px" />
        <Heading fontSize="md" color="brand.black">
          {inviterName}
        </Heading>
      </Flex>
      <Text>{message}</Text>
    </Stack>

    <Center>
      <HStack>
        <ButtonGroup>
          <Button
            onClick={acceptInvite}
            isLoading={isAcceptingInvite}
            disabled={isDecliningInvite || isAcceptingInvite}
          >
            Accept
          </Button>

          <Button
            variant="outline"
            color="brand.red"
            borderColor="brand.red"
            isLoading={isDecliningInvite}
            disabled={isAcceptingInvite || isDecliningInvite}
            onClick={declineInvite}
          >
            Decline
          </Button>
        </ButtonGroup>
      </HStack>
    </Center>
  </Flex>
);

import { CreateVisaConfigRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useCreateVisaConfig = () => {
  const [createVisaConfig, isCreateVisaConfigLoading] = useHandler(
    (api) => api.visa().createVisaConfig,
    {
      invalidates: () => [['VISA_CONFIG']],
    },
  );

  return {
    createVisaConfig: (data: Entity.Attributes<CreateVisaConfigRequest>) =>
      createVisaConfig(data),
    isCreateVisaConfigLoading,
  };
};

import { onError } from '../../../libs/errorLib';
import { useHandlerQuery, useQueryInvalidator } from '../../useHandlerQuery';

export const usePositions = (args?: {
  staleAfter?: string;
  enabled?: boolean;
}) => {
  const invalidate = useQueryInvalidator();
  const rposition = useHandlerQuery((api) => api.position().getPositions({}), {
    key: ['POSITION_LIST'],
    retry: 0,
    onError,
    staleAfter: args?.staleAfter,
    enabled: args?.enabled !== false,
  });
  const { data, isLoading } = rposition;
  return {
    // rposition exists temporarily until migrated to the standard format
    rposition,
    isLoadingPositions: isLoading,
    positions: data?.positions ?? [],
    refetchPositions: () => invalidate(['POSITION_LIST']),
  };
};

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  FormTask as FormTaskModel,
  MultipleChoiceFormSubmissionItem,
  TaskActionType,
  TextQuestionFormSubmissionItem,
  SubmitFormTaskItemsCreateArgs,
  FileUploadFormSubmissionItem,
} from '@zap-onboard/api-client';
import { BaseTaskPropTypes } from '../BaseTaskPropTypes';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';

import { FormTaskTextInput } from './TextInput';
import { FormTaskMultipleChoiceInput } from './MultipleChoiceInput';
import { FormTaskFileInput } from './FileInput';
import { FormTaskTextBlock } from './TextBlock';
import { FormTaskYoutubeVideoBlock } from './YoutubeVideoBlock';
import { TaskActionSubmitter } from '../../../../../hooks';
import { FormTaskVideoBlock } from './VideoBlock';

interface FormTaskProps extends BaseTaskPropTypes {
  task: FormTaskModel;
}

type FormDataStructure = {
  items: { [taskItemId: string]: SubmitFormTaskItemsCreateArgs[number] };
};

const { useState } = React;

export const FormTask: React.FC<FormTaskProps> = ({
  canEdit,
  task,
  submitAction,
}) => {
  const isTaskCompleted = task.taskCompleted();
  const isTaskDisabled = isTaskCompleted || !canEdit;

  if (task.taskStateId === 'META_SKIPPED') {
    return (
      <Layout>
        <TaskContent task={task} isDisabled={isTaskDisabled}>
          <TaskSkipped />
        </TaskContent>
      </Layout>
    );
  }

  if (isTaskCompleted) {
    return (
      <Layout>
        <TaskContent task={task} isDisabled={isTaskDisabled}>
          <TaskCompleted />
        </TaskContent>
      </Layout>
    );
  }

  return (
    <Layout>
      <TaskContent task={task} isDisabled={isTaskDisabled} />
      <TaskActions
        task={task}
        isDisabled={isTaskDisabled}
        submitAction={submitAction}
      />
    </Layout>
  );
};

const Layout: React.FC = ({ children }) => {
  const useFormMethods = useForm<FormDataStructure>({
    // NOTE: If this is set to false, inputs from two form tasks will shadow
    // each others values. Causing submission errors
    shouldUnregister: true,
  });

  return (
    <FormProvider {...useFormMethods}>
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} minH="360px">
        <Flex direction="column" justify="space-between" flex={1}>
          {children}
        </Flex>
      </Stack>
    </FormProvider>
  );
};

const TaskContent: React.FC<{
  task: FormTaskModel;
  isDisabled: boolean;
}> = ({ task, isDisabled, children }) => (
  <Stack spacing={0}>
    <TaskHeading heading={task.name} />
    {children}
    <TaskBody task={task} isDisabled={isDisabled} />
  </Stack>
);

const TaskHeading: React.FC<{ heading: string }> = ({ heading }) => (
  <Flex justify="center">
    <Heading
      variant="section"
      maxW={{
        base: '200px',
        lg: '320px',
      }}
      wordBreak="break-all"
    >
      {heading}
    </Heading>
  </Flex>
);

const TaskBody: React.FC<{
  task: FormTaskModel;
  isDisabled: boolean;
}> = ({ task, isDisabled }) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const getSubmissionItem = (formSchemaItemId: string) =>
    task.submission?.items.find(
      (item) => item.value.formSchemaItemId === formSchemaItemId,
    );

  const isUser = task.allowedToAction;

  const getPath = (...paths: string[]) => [...paths].join('.');

  return (
    <Stack spacing={2}>
      {task.schema.items.map((schemaItem) => {
        const key = schemaItem.formSchemaItemId!;
        if (schemaItem.type === 'TEXT_QUESTION') {
          return (
            <FormTaskTextInput
              disabled={!isUser || isDisabled}
              key={key}
              schemaItem={schemaItem}
              submissionItem={
                getSubmissionItem(schemaItem.formSchemaItemId!)?.value as
                  | TextQuestionFormSubmissionItem
                  | undefined
              }
              getPath={(...paths: string[]) =>
                getPath('items', schemaItem.formSchemaItemId!, ...paths)
              }
            />
          );
        }
        if (schemaItem.type === 'MULTIPLE_CHOICE') {
          return (
            <FormTaskMultipleChoiceInput
              disabled={!isUser || isDisabled}
              key={key}
              schemaItem={schemaItem}
              submissionItem={
                getSubmissionItem(schemaItem.formSchemaItemId!)?.value as
                  | MultipleChoiceFormSubmissionItem
                  | undefined
              }
              getPath={(...paths: string[]) =>
                getPath('items', schemaItem.formSchemaItemId!, ...paths)
              }
            />
          );
        }
        if (schemaItem.type === 'FILE_UPLOAD') {
          return (
            <FormTaskFileInput
              disabled={!isUser || isDisabled || isUploading}
              key={key}
              task={task}
              schemaItem={schemaItem}
              submissionItem={
                getSubmissionItem(schemaItem.formSchemaItemId!)?.value as
                  | FileUploadFormSubmissionItem
                  | undefined
              }
              getPath={(...paths: string[]) =>
                getPath('items', schemaItem.formSchemaItemId!, ...paths)
              }
              setIsUploading={setIsUploading}
            />
          );
        }
        if (schemaItem.type === 'TEXT_BLOCK') {
          return (
            <FormTaskTextBlock
              key={key}
              schemaItem={schemaItem}
              getPath={(...paths: string[]) =>
                getPath('items', schemaItem.formSchemaItemId!, ...paths)
              }
            />
          );
        }

        if (schemaItem.type === 'YOUTUBE_VIDEO') {
          return (
            <FormTaskYoutubeVideoBlock
              key={key}
              schemaItem={schemaItem}
              getPath={(...paths: string[]) =>
                getPath('items', schemaItem.formSchemaItemId!, ...paths)
              }
            />
          );
        }
        if (schemaItem.type === 'VIDEO') {
          return (
            <FormTaskVideoBlock
              key={key}
              schemaItem={schemaItem}
              getPath={(...paths: string[]) =>
                getPath('items', schemaItem.formSchemaItemId!, ...paths)
              }
            />
          );
        }
        return <p key={key}>Unknown form schema item type</p>;
      })}
    </Stack>
  );
};

const TaskActions: React.FC<{
  isDisabled: boolean;
  submitAction: TaskActionSubmitter;
  task: FormTaskModel;
}> = ({ isDisabled, task, submitAction }) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useFormContext();

  const onSubmit = async (data: FormDataStructure) => {
    await submitAction(TaskActionType.FORM_SUBMIT, {
      taskId: task.taskId,
      items: Object.entries(data.items ?? {}).map(
        ([formSchemaItemId, rest]) => {
          let isOtherSelected: boolean | undefined;

          if (rest.type === 'MULTIPLE_CHOICE') {
            isOtherSelected = Boolean(rest.isOther);
          }

          return {
            ...rest,
            isOther: isOtherSelected ?? false,
            formSchemaItemId,
          };
        },
      ),
    });
  };

  return (
    <Center mt={4}>
      <ButtonGroup>
        {task.allowedToAction && (
          <Button
            isDisabled={isDisabled || isSubmitting}
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        )}
      </ButtonGroup>
    </Center>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Result } from 'designed';
import m from 'mixpanel-browser';
import { createToast, isDev } from '../../helpers';

export const event = (eventName: string, data?: Record<string, any>) => {
  Result.fromThrowable(() => {
    if (isDev) {
      createToast({
        title: eventName,
        description: JSON.stringify(data),
        status: 'info',
        duration: 2000,
        isClosable: true,
        variant: 'subtle',
        position: 'bottom-left',
      });
    }
    m.track(eventName, data);
  });
};

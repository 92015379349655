import React from 'react';
import { useAPI } from '../../../services/API';
import { onError } from '../../../libs/errorLib';

export const useMYOBBeginOAuthAuthentication = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const beginAuth = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await api
        .myob()
        .beginOAuthAuthentication()
        .map(
          ({ connectionURL }) => (window.location.href = connectionURL),
          onError,
        );

      setIsLoading(false);
    }
  };
  return {
    beginOAuthAuthentication: beginAuth,
    isLoadingBeginOAuth: isLoading,
  };
};

import { Stack, ButtonGroup, Button, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { membership } from '@zap-onboard/api-client';
import { inputs } from '@zap-onboard/web-components';

import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useCreateVisaConfig } from '../../hooks';

type Props = {
  memberId: membership.GetMember.Response['memberId'];
  onClose: () => unknown;
};

const VisaConfigFormSchema = z.object({
  expiryNotificationDays: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(1, { message: 'Must be between 1 and 120' })
      .max(120, { message: 'Must be between 1 and 120' }),
  ),
});

type VisaConfigFormValues = z.infer<typeof VisaConfigFormSchema>;

export const VisaConfigForm: React.FC<Props> = ({ onClose }) => {
  const { createVisaConfig, isCreateVisaConfigLoading } = useCreateVisaConfig();
  const useFormMethods = useForm<VisaConfigFormValues>({
    defaultValues: {
      expiryNotificationDays: 30,
    },
    resolver: async (data, context, options) => {
      return zodResolver(VisaConfigFormSchema)(data, context, options);
    },
  });
  const onSubmit = useFormMethods.handleSubmit(
    async ({ expiryNotificationDays }) => {
      await createVisaConfig({ expiryNotificationDays });
    },
  );
  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={onSubmit}>
        <Stack spacing={3} mt={5}>
          <inputs.TextInput
            _input={{ maxW: '150px' }}
            name="expiryNotificationDays"
            label="Visa expiry notification days"
          />
          <Text>
            The number of days to be reminded before a Visa expires. You can
            update this at any time in the settings menu
          </Text>
          <ButtonGroup justifyContent="center" pt={6} alignSelf="self-end">
            <Button
              type="submit"
              isLoading={isCreateVisaConfigLoading}
              isDisabled={isCreateVisaConfigLoading}
            >
              Save settings and continue
            </Button>
            <Button
              variant="outline"
              onClick={onClose}
              isDisabled={isCreateVisaConfigLoading}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </FormProvider>
  );
};

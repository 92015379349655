/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Resolver, ResolverResult } from 'react-hook-form';

/**
 * Add semantic validation to a zodResolver for react-hook-form.
 *
 * Validation is only appled if structural/type (zod) validation passes.
 *
 * @example
 *  ```typescript
 *  useForm({
 *    resolver: withSemanticValidation(zodResolver(schema), ({ from, to }) => ({
 *      from: from === to ? 'From and to must be different' : undefined,
 *     }))
 *  })
 *  ```
 */
export const withSemanticValidation = <T extends Record<string, any>>(
  r: Resolver<T>,
  validator: (data: T) => Record<string, string | undefined>,
): Resolver<T> => {
  const newResolver = (data: T, context: any, options: any) => {
    const mutateResult = (result: ResolverResult<T>) => {
      if (!result.errors || Object.keys(result.errors).length === 0) {
        const errors: Record<string, FieldError> = {};
        Object.entries(validator(data)).forEach(([path, value]) => {
          if (value) {
            errors[path] = { type: 'required', message: value };
          }
        });

        return { ...result, errors: { ...result.errors, ...errors } };
      }

      return result;
    };

    const result = r(data as any, context, options);
    if (result instanceof Promise) {
      return result.then(mutateResult);
    }
    return mutateResult(result);
  };

  return newResolver as Resolver<T>;
};

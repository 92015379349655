/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import {
  primaryNonPhotographicIdentificationDocuments,
  primaryPhotographicIdentificationDocuments,
  secondaryIdentificationDocuments,
  prettyTaskStatus,
} from './constants';
import {
  FileUploadFor,
  FileUploadTargetType,
  ProofOfIdentityTask as APIProofOfIdentityTask,
  TaskActionType,
  flow,
  TaskDesignType,
} from '@zap-onboard/api-client';
import * as React from 'react';
import { inputs } from '@zap-onboard/web-components';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from '../../../../../helpers';
import { TaskActionSubmitter } from '../../../../../hooks';
import { InfoBox } from '../shared/InfoBox';
import { formSchema } from './formSchema';
import { DocumentUpload } from '../../../../DocumentUpload';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';
import { Link } from 'react-router-dom';

interface ProofOfIdentityTaskProps {
  task?: APIProofOfIdentityTask;
  context: 'user' | 'business';
  canEdit: boolean;
  submitAction?: TaskActionSubmitter;
  flow?: flow.GetFlows.Flow;
  setSideBarTaskId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ProofOfIdentityTask: React.FC<ProofOfIdentityTaskProps> = ({
  task,
  context,
  canEdit,
  submitAction,
  flow,
  setSideBarTaskId,
}) => {
  const defaultValues = React.useMemo(
    () => ({
      identificationType:
        task?.proofOfIdentitySubmission?.value.identificationType,
      primaryDocument: task?.proofOfIdentitySubmission?.merged.primaryDocument,
      secondaryDocument:
        task?.proofOfIdentitySubmission?.merged.secondaryDocument,
    }),
    [task],
  );

  let nextTask: flow.GetFlows.Task<TaskDesignType> | undefined;
  if (flow?.tasks && task) {
    const currentTaskFlowIndex = flow.tasks
      .map((flowTask) => flowTask?.taskId)
      .indexOf(task.taskId);
    nextTask = flow?.tasks?.[currentTaskFlowIndex + 1];
  }

  const useFormMethods = useForm<z.infer<typeof formSchema>>({
    resolver: z.zodResolver(formSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
    reset,
    control,
  } = useFormMethods;

  const isProofOfIdentityUnsubmitted = task
    ? task.taskStateId === 'PROOF_OF_IDENTITY_UNSUBMITTED'
    : true;

  const isProofOfIdentitySubmitted =
    task?.taskStateId === 'PROOF_OF_IDENTITY_SUBMITTED';

  const isTaskCompleted = task?.taskCompleted();

  const isSubmissionDisabled =
    !isProofOfIdentityUnsubmitted || !canEdit || isSubmitting;

  const isBusiness = context === 'business';

  const canReview = isBusiness && isProofOfIdentitySubmitted && canEdit;

  React.useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, reset]);

  const identificationType = watch('identificationType');

  const acceptablePrimaryDocs =
    identificationType === 'PHOTOGRAPHIC'
      ? primaryPhotographicIdentificationDocuments
      : primaryNonPhotographicIdentificationDocuments;

  const onManagerSubmit = async (isApproved: boolean) => {
    if (!task || !submitAction) {
      return;
    }
    await submitAction(TaskActionType.PROOF_OF_IDENTITY_APPROVAL, {
      taskId: task?.taskId,
      isApproved,
    });
  };

  const onIndividualSubmit = handleSubmit(async (submission) => {
    if (!task || !submitAction) {
      return;
    }

    await submitAction(TaskActionType.PROOF_OF_IDENTITY_SUBMISSION, {
      taskId: task.taskId,
      submission,
    });
  });

  const taskHeading = (status: string) => (
    <Heading variant="section" textAlign="center">
      Proof of Identity {`- ${status}`}
    </Heading>
  );

  const taskBody = (
    <FormProvider {...useFormMethods}>
      <form>
        <Flex w="100%" justify="center">
          <Stack spacing={4} p={4} w={{ base: '100%', sm: '80%' }}>
            <Text>Provide proof of your identity by supplying either:</Text>
            <UnorderedList>
              <ListItem>
                A form of primary photographic identification, or;
              </ListItem>
              <ListItem>
                A primary non-photographic identification document and a
                secondary identification document
              </ListItem>
            </UnorderedList>
            <inputs.radio.Group
              name="identificationType"
              label="Select an identification type"
              isDisabled={isSubmissionDisabled}
            >
              <Stack mb={4} textAlign="center">
                <inputs.radio.Button value="PHOTOGRAPHIC">
                  Primary photographic identification
                </inputs.radio.Button>
                <inputs.radio.Button value="NON_PHOTOGRAPHIC">
                  Primary non-photographic identification document and a
                  secondary identification document
                </inputs.radio.Button>
              </Stack>
            </inputs.radio.Group>
            {identificationType != null && (
              <>
                <Text fontWeight="bold">Upload primary documents</Text>
                <InfoBox>
                  <Text>Acceptable forms of primary proof include:</Text>
                  <UnorderedList>
                    {acceptablePrimaryDocs.map((doc) => (
                      <ListItem>{doc}</ListItem>
                    ))}
                  </UnorderedList>
                </InfoBox>
                {isTaskCompleted ? (
                  <Flex direction="column" align="center">
                    <Heading variant="section" textAlign="center">
                      Documents uploaded
                    </Heading>
                    {task?.taskStateId === 'PROOF_OF_IDENTITY_APPROVED' ? (
                      <Tag bg="brand.green">
                        <TagLabel color="white">
                          {prettyTaskStatus(task?.taskStateId)}
                        </TagLabel>
                      </Tag>
                    ) : (
                      <Tag bg="brand.red">
                        <TagLabel color="white">
                          {prettyTaskStatus(task?.taskStateId)}
                        </TagLabel>
                      </Tag>
                    )}
                  </Flex>
                ) : (
                  <FormControl isInvalid={!!(errors as any)?.primaryDocument}>
                    <Flex justifyContent="center">
                      <DocumentUpload
                        isReadOnly={!task || isSubmissionDisabled}
                        uploadedFor={{
                          type: FileUploadFor.TASK,
                          taskId: task?.taskId || '',
                        }}
                        allowedTypes={[
                          FileUploadTargetType.PDF,
                          FileUploadTargetType.JPEG,
                          FileUploadTargetType.PNG,
                          FileUploadTargetType.GIF,
                        ]}
                        controlled={{
                          name: 'primaryDocument',
                          rules: { required: 'File required' },
                          control,
                        }}
                      />
                    </Flex>
                    <FormErrorMessage pl={2}>
                      {(errors as any)?.primaryDocument?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </>
            )}
            {identificationType === 'NON_PHOTOGRAPHIC' && !isTaskCompleted && (
              <>
                <Text fontWeight="bold">Upload secondary documents</Text>
                <InfoBox>
                  <Text>Acceptable forms of secondary proof include:</Text>
                  <UnorderedList>
                    {secondaryIdentificationDocuments.map((doc) => (
                      <ListItem>{doc}</ListItem>
                    ))}
                  </UnorderedList>
                </InfoBox>
                <FormControl
                  isInvalid={!!(errors as any)?.secondaryDocument}
                  textAlign="center"
                >
                  <Flex justifyContent="center">
                    <DocumentUpload
                      isReadOnly={!task || isSubmissionDisabled}
                      uploadedFor={{
                        type: FileUploadFor.TASK,
                        taskId: task?.taskId || '',
                      }}
                      allowedTypes={[
                        FileUploadTargetType.PDF,
                        FileUploadTargetType.JPEG,
                        FileUploadTargetType.PNG,
                        FileUploadTargetType.GIF,
                      ]}
                      controlled={{
                        name: 'secondaryDocument',
                        rules: { required: 'File required' },
                        control,
                      }}
                    />
                  </Flex>
                  <FormErrorMessage pl={2}>
                    {(errors as any)?.secondaryDocument?.message}
                  </FormErrorMessage>
                </FormControl>
              </>
            )}
          </Stack>
        </Flex>
      </form>
    </FormProvider>
  );

  const managerReviewFooter = (
    <Center mt={4}>
      {isBusiness ? (
        <Stack spacing={4}>
          <Stack spacing={2}>
            <InfoBox>
              <Text>Review the submission and confirm the following:</Text>
              <UnorderedList>
                <ListItem>
                  The documents uploaded are clear and legible
                </ListItem>
                <ListItem>
                  There does not appear to be reason to question the
                  identification docs provided
                </ListItem>
              </UnorderedList>
              <Text mt={2} fontWeight="semibold">
                Once documents are approved / rejected they will no longer be
                visible
              </Text>
            </InfoBox>
          </Stack>
          <Center>
            <Flex gridGap={3}>
              <Button
                onClick={() => onManagerSubmit(true)}
                isLoading={isSubmitting}
                isDisabled={!canReview}
              >
                Approve
              </Button>
              <Button
                onClick={() => onManagerSubmit(false)}
                isLoading={isSubmitting}
                isDisabled={!canReview}
                variant="danger"
              >
                Reject
              </Button>
            </Flex>
          </Center>
        </Stack>
      ) : (
        <Center mt={4} flexDirection="column">
          <InfoBox>
            <Text>
              Your submission has been sent. We are now awaiting approval
            </Text>
          </InfoBox>
          {nextTask && setSideBarTaskId ? (
            <Button onClick={() => setSideBarTaskId(nextTask?.taskId)}>
              Go to next task
            </Button>
          ) : (
            <Button as={Link} to="/user/dashboard">
              Go to dashboard
            </Button>
          )}
        </Center>
      )}
    </Center>
  );

  const individualSubmissionFooter = task ? (
    <Center mt={4}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Text>
            DECLARATION: I declare that the information I have given is true and
            correct. There are penalties for deliberately providing false or
            misleading information.
          </Text>
        </Stack>
        <Center>
          <Button
            onClick={onIndividualSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmissionDisabled}
          >
            Submit
          </Button>
        </Center>
      </Stack>
    </Center>
  ) : (
    <Center mt={4}>
      <InfoBox>
        <Text>
          This is a task preview. To make a submission assign the workflow to a
          member
        </Text>
      </InfoBox>
    </Center>
  );

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading(prettyTaskStatus(task?.taskStateId))}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading(prettyTaskStatus(task?.taskStateId))}
        {taskBody}
        {managerReviewFooter}
        <TaskCompleted />
      </Stack>
    );
  }

  if (isProofOfIdentitySubmitted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
        <Flex
          direction="column"
          justify="space-between"
          h="100%"
          minH={{ md: '360px' }}
        >
          <Stack>
            {taskHeading(prettyTaskStatus(task?.taskStateId))}
            {taskBody}
          </Stack>
          {managerReviewFooter}
        </Flex>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack>
          {taskHeading(prettyTaskStatus(task?.taskStateId))}
          {taskBody}
        </Stack>
        {individualSubmissionFooter}
      </Flex>
    </Stack>
  );
};

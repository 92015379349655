import React, { useEffect, useRef } from 'react';
import {
  DEFAULT_FONT_SIZE,
  DEFAULT_ALIGNMENT,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_FONT_COLOR,
} from '@zap-onboard/pdfme-common';
import type { FieldProps } from './Field';
import { PX_IN_MM } from '../constants';
import { pdf } from '@zap-onboard/api-client';
import { usePDFState } from '../usePDFState';
import { Tooltip, Text } from '@chakra-ui/react';
import TextareaAutosize from 'react-textarea-autosize';
import { getFieldConfig } from '../Designer/FieldConfig';

type Props = FieldProps & { field: pdf.Schema.Field.TextData };

export const getTextLikeStyle = (field: pdf.Schema.Field.TextLikeData) => {
  return {
    padding: 0,
    resize: 'none',
    position: 'absolute',
    fontFamily: field.fontName ?? 'inherit',
    height: field.placement.height * PX_IN_MM,
    // Increase the width by 1 point. (0.75 pixels)
    width:
      (field.placement.width +
        (field.characterSpacing ?? DEFAULT_CHARACTER_SPACING) * 0.75) *
      PX_IN_MM,
    textAlign: field.alignment ?? DEFAULT_ALIGNMENT,
    fontSize: `${field.fontSize ?? DEFAULT_FONT_SIZE}pt`,
    letterSpacing: `${field.characterSpacing ?? DEFAULT_CHARACTER_SPACING}pt`,
    lineHeight: `${field.lineHeight ?? DEFAULT_LINE_HEIGHT}em`,
    whiteSpace: 'pre-line',
    // Overflow is not hidden during generation so we allow it here
    overflow: 'visible',

    // This appears to be incorrect
    // wordBreak: 'break-all',
    border: 'none',
    color: field.fontColor ? field.fontColor : DEFAULT_FONT_COLOR,
    backgroundColor:
      // field.data && field.backgroundColor
      field.backgroundColor ?? 'transparent',
  } as const;
};

/**
 * Changing styles here will need to be synced to other "text-like" fields
 * (time)
 */
export const TextField = ({
  field,
  isForm,
  tabIndex,
  onFocus,
  isReadonly,
}: Props) => {
  const style = getTextLikeStyle(field);
  const sampleValue = field.text || field.name;
  const value = usePDFState((s) => s.computed.getFieldValue(field.fieldId, ''));
  const setValue = usePDFState((s) => s.setFieldValue);
  const config = getFieldConfig(field);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(
    () =>
      usePDFState.subscribe((a) => {
        if (a.focusedFieldId == field.fieldId) {
          inputRef.current?.focus?.({ preventScroll: true });
        }
      }),
    [field.fieldId],
  );

  const isFocused = usePDFState((s) => s.focusedFieldId === field.fieldId);

  const validationMessage = usePDFState(
    (s) => s.fieldValidations[field.fieldId],
  );
  if (!isForm) {
    return (
      <div style={style}>
        {/*  Set the letterSpacing of the last character to 0. */}
        {sampleValue.split('').map((l, i) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={{
              letterSpacing:
                String(sampleValue).length === i + 1 ? 0 : 'inherit',
            }}
          >
            {l}
          </span>
        ))}
      </div>
    );
  }

  return (
    <Tooltip
      isOpen={isFocused}
      isDisabled={isReadonly}
      label={
        <>
          <Text>{field.name}</Text>
          <Text>{config.getTooltipDescription(field)}</Text>
          <Text color="red">{validationMessage}</Text>
        </>
      }
    >
      <TextareaAutosize
        ref={inputRef}
        disabled={isReadonly}
        readOnly={isReadonly}
        className="pdf-ignore-pan"
        tabIndex={tabIndex}
        style={{ ...style, height: undefined }}
        onChange={(e) => setValue(field.fieldId, e.target.value)}
        onFocus={onFocus}
        value={value}
      />
    </Tooltip>
  );
};

import { PriceName } from '@zap-onboard/api-client';

type Price = {
  stripePriceId: PriceName;
  amount: number;
  billingPeriod: 'MONTHLY' | 'YEARLY';
  gstAmount: number;
};

export type PlanProduct = {
  stripeProductId: string;
  name: string;
  price: Price[];
  description: string;
  features: string[];
  bestForTags: string[];
};

export type PriceTier = {
  id: string;
  amount: number;
  gstAmount: number;
  quantity: number;
};

export type AddOnProduct = {
  stripeProductId: string;
  name: string;
  price: {
    stripePriceId: string;
    amount: number;
    gstAmount: number;
    tiers?: PriceTier[];
  };
};

type VisaProduct = {
  id: '1check' | '10check' | '50check' | '100check';
  amount: number;
  description: string;
  quantity: number;
};

export const workRightsAddOnProducts: VisaProduct[] = [
  {
    id: '1check',
    quantity: 1,
    description: 'Pay as you go',
    amount: 9.9,
  },
  {
    id: '10check',
    quantity: 10,
    description: '10 Work Rights Checks',
    amount: 99,
  },
  {
    id: '50check',
    quantity: 50,
    description: '50 Work Rights Checks',
    amount: 440,
  },
  {
    id: '100check',
    quantity: 100,
    description: '100 Work Rights Checks',
    amount: 770,
  },
];

export const plans: PlanProduct[] = [
  {
    stripeProductId: 'BUSINESS_PLAN',
    name: 'Business',
    description: '1 x single business',
    price: [
      {
        stripePriceId: 'STRIPE_BUSINESS_PLAN_MONTHLY_PRICE_ID',
        amount: 30,
        billingPeriod: 'MONTHLY',
        gstAmount: 2.73,
      },
      {
        stripePriceId: 'STRIPE_BUSINESS_PLAN_YEARLY_PRICE_ID',
        amount: 330,
        billingPeriod: 'YEARLY',
        gstAmount: 30,
      },
    ],
    features: [
      'Employee onboarding',
      'Client / supplier onboarding',
      'Identity verification',
      'Licence and cert management',
      'Australian support 🇦🇺',
    ],
    bestForTags: ['Small medium business'],
  },
  {
    stripeProductId: 'ADVISOR_PLAN',
    name: 'Advisor',
    description: 'Multi business',
    price: [
      {
        stripePriceId: 'STRIPE_ADVISOR_PLAN_MONTHLY_PRICE_ID',
        amount: 39,
        billingPeriod: 'MONTHLY',
        gstAmount: 3.55,
      },
      {
        stripePriceId: 'STRIPE_ADVISOR_PLAN_YEARLY_PRICE_ID',
        amount: 429,
        billingPeriod: 'YEARLY',
        gstAmount: 39,
      },
    ],
    features: ['Everything in business plan', 'Unlimited businesses'],
    bestForTags: ['Bookkeeper', 'HR Consultant', 'Accountant'],
  },
];

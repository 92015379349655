import * as React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';

import { modal } from '@zap-onboard/web-components';
import { SendMessage } from '../SendMessage';
import { BiMessageDetail } from 'react-icons/bi';
import { membership } from '@zap-onboard/api-client';

type Props = {
  memberId: membership.GetMember.Response['memberId'];
  memberName: string;
};

export const SendMessageModal: React.FC<Props> = ({ memberId, memberName }) => {
  const disclosure = useDisclosure();

  return (
    <modal.Simple
      header
      disclosure={disclosure}
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={({ onOpen }) => (
        <Button
          leftIcon={<BiMessageDetail />}
          onClick={onOpen}
          variant="outline"
          color="brand.dark-gray"
          borderColor="brand.dark-gray"
          borderRadius="lg"
          w="120px"
        >
          Message
        </Button>
      )}
    >
      <SendMessage
        memberName={memberName}
        memberId={memberId}
        onClose={disclosure.onClose}
      />
    </modal.Simple>
  );
};

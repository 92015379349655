import { useHandlerQuery } from '../../useHandlerQuery';

export const useVideos = () => {
  const { data, isLoading } = useHandlerQuery(
    (api) => api.video().getVideos(),
    {
      key: ['VIDEOS'],
    },
  );
  return {
    isLoadingVideos: isLoading,
    videos: data?.videos ?? [],
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exporters = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const TimeAndDate_1 = require("../utils/time/TimeAndDate");
const RemoteFile_1 = require("./RemoteFile");
var exporters;
(function (exporters) {
    class ExportJobCompleted extends ResponseBase_1.ResponseBase {
    }
    ExportJobCompleted.type = 'completed';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['completed']),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportJobCompleted.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportJobCompleted.prototype, "exportId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ExportJobCompleted.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], ExportJobCompleted.prototype, "startedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], ExportJobCompleted.prototype, "completedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(RemoteFile_1.RemoteFile),
        (0, tslib_1.__metadata)("design:type", RemoteFile_1.RemoteFile)
    ], ExportJobCompleted.prototype, "file", void 0);
    exporters.ExportJobCompleted = ExportJobCompleted;
    class InProgressExportJob extends ResponseBase_1.ResponseBase {
    }
    InProgressExportJob.type = 'in_progress';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['in_progress']),
        (0, tslib_1.__metadata)("design:type", String)
    ], InProgressExportJob.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], InProgressExportJob.prototype, "exportId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], InProgressExportJob.prototype, "startedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], InProgressExportJob.prototype, "name", void 0);
    exporters.InProgressExportJob = InProgressExportJob;
    class FailedExportJob extends ResponseBase_1.ResponseBase {
    }
    FailedExportJob.type = 'failed';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['failed']),
        (0, tslib_1.__metadata)("design:type", String)
    ], FailedExportJob.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FailedExportJob.prototype, "exportId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FailedExportJob.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], FailedExportJob.prototype, "startedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FailedExportJob.prototype, "issue", void 0);
    exporters.FailedExportJob = FailedExportJob;
    class ExportJob extends designed_1.Entity.Union.define({
        key: 'type',
        entries: [ExportJobCompleted, InProgressExportJob, FailedExportJob],
    }) {
    }
    exporters.ExportJob = ExportJob;
    class ListResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: ExportJob }),
        (0, class_validator_1.IsInstance)(ExportJob, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], ListResponse.prototype, "jobs", void 0);
    exporters.ListResponse = ListResponse;
    exporters.ExportTypes = ['certification'];
    class QueueExportRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(exporters.ExportTypes),
        (0, tslib_1.__metadata)("design:type", String)
    ], QueueExportRequest.prototype, "type", void 0);
    exporters.QueueExportRequest = QueueExportRequest;
})(exporters = exports.exporters || (exports.exporters = {}));

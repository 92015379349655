"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSignatureSVGDataURL = exports.createSignatureSVG = void 0;
const opentype_js_1 = require("opentype.js");
const constants_1 = require("./constants");
const helpers_1 = require("./helpers");
/** DancingScript is wider than it thinks. The capital A character will bleed
 * without padding on left */
const LEFT_PADDING = 8;
const DEFAULT_FONT_SIZE = 72;
/**
 * Most PDF signature tools appear to allow the signature to overflow to the
 * left infinitely
 */
const createSignatureSVG = async ({ text }) => {
    const fontArray = (0, helpers_1.b64toUint8Array)(constants_1.fonts.DancingScript);
    const fontBuffer = (0, helpers_1.typedArrayToBuffer)(fontArray);
    const font = (0, opentype_js_1.parse)(fontBuffer);
    if (!font.supported) {
        throw new TypeError('Unable to parse font');
    }
    const cleanedText = text
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/\s+/, ' ')
        .trim();
    const fontScale = (1 / font.unitsPerEm) * DEFAULT_FONT_SIZE;
    const textPath = font.getPath(cleanedText, LEFT_PADDING, font.ascender * fontScale, DEFAULT_FONT_SIZE, {
        kerning: true,
    });
    const path = `<path stroke="#212427" d="${textPath.toPathData(2)}" />`;
    const { x1, x2, y1, y2 } = textPath.getBoundingBox();
    const width = LEFT_PADDING + x1 + x2;
    const height = y1 + y2;
    return `<svg  width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${path}</svg>`;
};
exports.createSignatureSVG = createSignatureSVG;
const createSignatureSVGDataURL = async (args) => {
    const svg = await (0, exports.createSignatureSVG)(args);
    return `data:image/svg+xml;base64,${(0, helpers_1.encodeBase64)(svg)}`;
};
exports.createSignatureSVGDataURL = createSignatureSVGDataURL;

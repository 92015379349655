/* eslint-disable @typescript-eslint/no-explicit-any */
export const recursivelyRemoveEmptyStrings = <T>(
  value: T,
  setTo?: null | undefined,
): T => impl(value, setTo) as T;

const impl = (value: unknown, setTo: null | undefined = undefined): unknown => {
  const object: unknown = value;

  if (typeof object === 'object') {
    if (object === null) {
      return object;
    }
    Object.entries(object).forEach(([k, v]) => {
      (object as any)[k] = impl(v);
    });
    return object;
  }

  if (typeof object === 'string') {
    if (object.length === 0) {
      return setTo;
    }
    return object;
  }

  if (object instanceof Array) {
    return object
      .map((v) => recursivelyRemoveEmptyStrings(v))
      .filter((v) => v !== undefined);
  }

  return object;
};

import { useHandler } from '../../useHandlerMutation';

export const useDeleteMYOBCompanyFileAuthorization = () => {
  const [deleteCompanyFileAuth, isDeleteLoading] = useHandler(
    (api) => api.myob().deleteMyobCompanyFileAuthorization,
    {
      invalidates: () => [['BUSINESS']],
    },
  );

  return {
    deleteCompanyFileAuth: (businessId: string) =>
      deleteCompanyFileAuth(businessId),
    isDeleteLoading,
  };
};

/* eslint-disable import/no-useless-path-segments */
import * as React from 'react';

import { cert } from '@zap-onboard/api-client';
import { useMyCertOverview, useStateIfMounted } from '../../../hooks';

export const useCertsToBeActioned = () => {
  const certs = useMyCertOverview({ required: true });
  const [isLoading, setIsLoading] = useStateIfMounted<boolean>(true);
  const [relevantCerts, setRelevantCerts] = useStateIfMounted<
    cert.GetSubmission.Submission.Union[] | undefined
  >(undefined);
  const { getCertsRequiringAction } = Helpers;

  React.useMemo(() => {
    if (certs.data) {
      setIsLoading(true);
      setRelevantCerts(getCertsRequiringAction(certs.data));
      setIsLoading(false);
    }
  }, [certs.data, getCertsRequiringAction, setRelevantCerts, setIsLoading]);

  return {
    ...certs,
    isLoading: certs.isLoading || isLoading,
    data: relevantCerts,
  };
};

namespace Helpers {
  export function getCertsRequiringAction(data: cert.GetOverview.Response) {
    const certs = data.submissions.filter(({ value: { status } }) => {
      if (status === 'REJECTED' || status === 'UNSUBMITTED') {
        return true;
      }
      return false;
    });

    return certs;
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Union = exports.Checkbox = exports.Signature = exports.Dropdown = exports.Text = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
class Text extends ResponseBase_1.ResponseBase {
    getRawValue() {
        return this.value;
    }
}
Text.type = 'text';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Text.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Text.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Text.prototype, "value", void 0);
exports.Text = Text;
class Dropdown extends ResponseBase_1.ResponseBase {
    getRawValue() {
        return this.optionId;
    }
}
Dropdown.type = 'dropdown';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Dropdown.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Dropdown.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Dropdown.prototype, "optionId", void 0);
exports.Dropdown = Dropdown;
class Signature extends ResponseBase_1.ResponseBase {
    getRawValue() {
        return this.signed;
    }
}
Signature.type = 'signature';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Signature.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Signature.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Signature.prototype, "signed", void 0);
exports.Signature = Signature;
class Checkbox extends ResponseBase_1.ResponseBase {
    getRawValue() {
        return this.checked;
    }
}
Checkbox.type = 'checkbox';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Checkbox.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Checkbox.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Checkbox.prototype, "checked", void 0);
exports.Checkbox = Checkbox;
class Union extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [Text, Signature, Checkbox, Dropdown],
}) {
    get fieldId() {
        return this.value.fieldId;
    }
    get rawValue() {
        return this.value.getRawValue();
    }
}
exports.Union = Union;

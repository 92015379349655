import * as React from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { BiBell } from 'react-icons/bi';

type ExpiryReminder = {
  notificationInDays: number;
};

export const ExpiryReminder: React.FC<ExpiryReminder> = ({
  notificationInDays,
}) => (
  <Flex gridGap={1}>
    <Icon as={BiBell} color="gray" />
    <Text
      color="gray"
      fontSize="xs"
    >{`Sending reminders ${notificationInDays} days before expiry`}</Text>
  </Flex>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var Settings;
(function (Settings) {
    Settings.LEVELS = ['OFF', 'NOTIFICATION', 'EMAIL'];
    class Category extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Category.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Category.prototype, "description", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Category.prototype, "categoryId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(Settings.LEVELS),
        (0, tslib_1.__metadata)("design:type", String)
    ], Category.prototype, "level", void 0);
    Settings.Category = Category;
    class Business extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Business.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Business.prototype, "businessName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Category }),
        (0, class_validator_1.IsInstance)(Category, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Business.prototype, "categories", void 0);
    Settings.Business = Business;
    class GetResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Business }),
        (0, class_validator_1.IsInstance)(Business, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], GetResponse.prototype, "businesses", void 0);
    Settings.GetResponse = GetResponse;
    class UpdateCategory extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], UpdateCategory.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], UpdateCategory.prototype, "categoryId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(Settings.LEVELS),
        (0, tslib_1.__metadata)("design:type", String)
    ], UpdateCategory.prototype, "level", void 0);
    Settings.UpdateCategory = UpdateCategory;
    class UpdateRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: UpdateCategory }),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(UpdateCategory, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], UpdateRequest.prototype, "categories", void 0);
    Settings.UpdateRequest = UpdateRequest;
})(Settings = exports.Settings || (exports.Settings = {}));

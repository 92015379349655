import React from 'react';
import ReactPlayer from 'react-player/lazy';

type Props = {
  videoUrl: string;
};

export const VideoPlayer: React.FC<Props> = ({ videoUrl }) => {
  return (
    <ReactPlayer
      url={videoUrl}
      controls
      width="100%"
      height="100%"
      style={{ paddingTop: '20px', paddingBottom: '20px' }}
    />
  );
};

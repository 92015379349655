import React from 'react';
import { Grid, GridItem, Heading } from '@chakra-ui/react';

import { UserProfile } from './UserProfile';
import { PageLayoutContainer } from '../Layout';
import { track } from '../../libs/track';

export const ProfilePage: React.FC = () => {
  track.useOnMount('Viewed My Profile');
  return (
    <PageLayoutContainer>
      <Heading variant="page">Profile</Heading>
      <Grid
        w="100%"
        templateColumns="1fr"
        templateRows={{ base: '1fr 1fr', lg: '1fr' }}
        gap={4}
      >
        <GridItem
          colStart={{ base: 1, lg: 1 }}
          colSpan={1}
          rowStart={{ base: 1, lg: 1 }}
          rowSpan={1}
        >
          <UserProfile />
        </GridItem>
      </Grid>
    </PageLayoutContainer>
  );
};

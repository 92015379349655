"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfService = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const ResponseBase_1 = require("../../../ResponseBase");
var SelfService;
(function (SelfService) {
    class FlowDesignResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FlowDesignResponse.prototype, "flowDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FlowDesignResponse.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FlowDesignResponse.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], FlowDesignResponse.prototype, "description", void 0);
    SelfService.FlowDesignResponse = FlowDesignResponse;
    class GetResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: FlowDesignResponse }),
        (0, class_validator_1.IsInstance)(FlowDesignResponse, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], GetResponse.prototype, "designs", void 0);
    SelfService.GetResponse = GetResponse;
    class GetRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)('4', { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], GetRequest.prototype, "businessIds", void 0);
    SelfService.GetRequest = GetRequest;
    class SetRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], SetRequest.prototype, "flowDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], SetRequest.prototype, "selfService", void 0);
    SelfService.SetRequest = SetRequest;
    class StartRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, tslib_1.__metadata)("design:type", String)
    ], StartRequest.prototype, "flowDesignId", void 0);
    SelfService.StartRequest = StartRequest;
    class StartResponse extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], StartResponse.prototype, "flowId", void 0);
    SelfService.StartResponse = StartResponse;
})(SelfService = exports.SelfService || (exports.SelfService = {}));

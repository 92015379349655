import React from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useCompactView } from '../../hooks/useCompactView';
import { NavBar } from './NavBar';
import { SideBar } from './SideBar';
import { CurrentBusinessBillingAlert } from '../../billing/CurrentBusinessBillingAlert';
import { useFeatureFlag } from '../../components';

type PageLayoutContainerProps = {
  children: React.ReactNode;
};

export const PageLayoutContainer: React.FC<PageLayoutContainerProps> = ({
  children,
}) => {
  const { isCompactView } = useCompactView();
  const navBarHeight = '56px';
  const sidebarWidth = '220px';
  const hideSubscriptionBanner = useFeatureFlag('HIDE_SUBSCRIPTION_BANNER');
  return (
    <Grid
      minH="100vh"
      templateRows={{ base: `${navBarHeight} 1fr` }}
      templateColumns={{ base: '1fr', lg: `${sidebarWidth} 1fr` }}
      mb={4}
    >
      <GridItem
        position="fixed"
        w="100vw"
        rowStart={1}
        rowSpan={1}
        colStart={1}
        colSpan={2}
        zIndex={1000}
      >
        <NavBar navBarHeight={navBarHeight} />
      </GridItem>
      {isCompactView ? null : (
        <GridItem
          position="fixed"
          w={sidebarWidth}
          top={`${navBarHeight}`}
          rowStart={2}
          rowSpan={1}
          colStart={1}
          colSpan={1}
          zIndex={900}
        >
          <SideBar />
        </GridItem>
      )}
      <GridItem
        rowStart={2}
        rowSpan={1}
        colStart={{ base: 1, lg: 2 }}
        colSpan={1}
      >
        {!hideSubscriptionBanner && <CurrentBusinessBillingAlert />}
        <Box px={{ base: 4, lg: 8 }}> {children} </Box>
      </GridItem>
    </Grid>
  );
};

import { SendSMSToMemberRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useSendSMS = () => {
  const [sendSMS, isLoading] = useHandler(
    (api) => api.notification().sendSMSToMember,
    { invalidates: ({ memberId }) => [['BUSINESS_NOTIFICATIONS', memberId]] },
  );

  return {
    sendSMS: (data: Entity.Attributes<SendSMSToMemberRequest>) => sendSMS(data),
    isLoading,
  };
};

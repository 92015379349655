import * as React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
  Radio,
  RadioGroup,
  Input,
} from '@chakra-ui/react';
import { get, useFormContext, useController } from 'react-hook-form';

import {
  MultipleChoiceFormSchemaItem,
  MultipleChoiceFormSubmissionItem,
} from '@zap-onboard/api-client';

type Props = {
  schemaItem: MultipleChoiceFormSchemaItem;
  submissionItem: MultipleChoiceFormSubmissionItem | undefined;
  disabled: boolean;
  getPath: (...paths: string[]) => string;
};

export const FormTaskMultipleChoiceInput: React.FC<Props> = ({
  schemaItem,
  disabled,
  getPath,
  submissionItem,
}) => {
  const { control, errors } = useFormContext();

  const {
    field: { onChange, value, ref, onBlur },
  } = useController({
    control,
    name: getPath(),
    rules: {
      validate: (submission) => {
        if (!schemaItem.required) {
          return true;
        }

        if (
          submission.isOther &&
          (typeof submission.value !== 'string' || submission.value.length < 1)
        ) {
          return 'Answer required';
        }

        if (!submission.value) {
          return 'Select an option';
        }

        return !!submission.value || 'Select an option';
      },
    },
    defaultValue: submissionItem ?? schemaItem.makeEmptySubmission(),
  });

  const [currentOption, setCurrentOption] = React.useState<
    string | 'OTHER' | undefined
  >(() =>
    submissionItem?.isOther ? 'OTHER' : submissionItem?.value ?? undefined,
  );
  const [otherValue, setOtherValue] = React.useState<string>(() =>
    submissionItem?.isOther ? submissionItem?.value ?? '' : '',
  );

  React.useEffect(() => {
    const newValue = {
      type: 'MULTIPLE_CHOICE',
      value: currentOption === 'OTHER' ? otherValue : currentOption,
      isOther: currentOption === 'OTHER',
    };

    if (newValue.value === value.value && value.isOther === newValue.isOther) {
      return;
    }

    onChange(newValue);
  }, [currentOption, onChange, otherValue, value]);

  return (
    <FormControl my="2" isInvalid={!!get(errors, getPath())}>
      <FormLabel>{schemaItem.title}</FormLabel>
      <RadioGroup value={currentOption} onChange={setCurrentOption}>
        <Stack direction="column">
          {schemaItem.multipleChoiceOptions.map((option, index) => (
            <Radio
              key={option.name}
              ref={index === 0 ? ref : undefined}
              isDisabled={disabled}
              value={option.name}
              isChecked={option.name === currentOption}
              onBlur={onBlur}
            >
              {option.name}
            </Radio>
          ))}

          {schemaItem.allowOther && (
            <Stack>
              <Radio
                isDisabled={disabled}
                value="OTHER"
                isChecked={currentOption === 'OTHER'}
                onBlur={onBlur}
              >
                Other
              </Radio>
              {currentOption === 'OTHER' && (
                <Input
                  placeholder="Your answer"
                  value={otherValue}
                  onChange={(e) => setOtherValue(e.target.value)}
                  isDisabled={disabled}
                  onBlur={onBlur}
                />
              )}
            </Stack>
          )}
        </Stack>
      </RadioGroup>
      <FormErrorMessage>{get(errors, getPath('message'))}</FormErrorMessage>
    </FormControl>
  );
};

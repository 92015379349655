"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddMember = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const UserProfile_1 = require("../../identity/models/UserProfile");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
var AddMember;
(function (AddMember) {
    class AddPositionRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AddPositionRequest.prototype, "positionId", void 0);
    AddMember.AddPositionRequest = AddPositionRequest;
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.Email),
        (0, tslib_1.__metadata)("design:type", utils_1.Email)
    ], Request.prototype, "email", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "sendInvite", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(AddPositionRequest),
        (0, tslib_1.__metadata)("design:type", AddPositionRequest)
    ], Request.prototype, "addPosition", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(UserProfile_1.UpdateUserProfile),
        (0, tslib_1.__metadata)("design:type", UserProfile_1.UpdateUserProfile)
    ], Request.prototype, "profile", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "assignNewFlows", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, tslib_1.__metadata)("design:type", Object)
    ], Request.prototype, "roleName", void 0);
    AddMember.Request = Request;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "memberId", void 0);
    AddMember.Response = Response;
})(AddMember = exports.AddMember || (exports.AddMember = {}));

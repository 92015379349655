"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Submit = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const enums_1 = require("../enums");
const utils_1 = require("../../utils");
const FileClaim_1 = require("../../file/FileClaim");
const ResponseBase_1 = require("../../ResponseBase");
const FormSubmission_1 = require("../../flow/task/FormTask/FormSubmission");
var Submit;
(function (Submit) {
    let Data;
    (function (Data) {
        class DriversLicence extends ResponseBase_1.ResponseBase {
        }
        DriversLicence.type = enums_1.CertificationType.DRIVERS_LICENCE;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([enums_1.CertificationType.DRIVERS_LICENCE]),
            (0, tslib_1.__metadata)("design:type", String)
        ], DriversLicence.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: FileClaim_1.FileClaim }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.ArrayMinSize)(1),
            (0, class_validator_1.ArrayMaxSize)(5),
            (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], DriversLicence.prototype, "claims", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsNotEmpty)(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], DriversLicence.prototype, "licenceNumber", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
            (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
        ], DriversLicence.prototype, "expiryDate", void 0);
        Data.DriversLicence = DriversLicence;
        class RSA extends ResponseBase_1.ResponseBase {
        }
        RSA.type = enums_1.CertificationType.RSA;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([enums_1.CertificationType.RSA]),
            (0, tslib_1.__metadata)("design:type", String)
        ], RSA.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: FileClaim_1.FileClaim }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.ArrayMinSize)(1),
            (0, class_validator_1.ArrayMaxSize)(5),
            (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], RSA.prototype, "claims", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], RSA.prototype, "licenceNumber", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
            (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
        ], RSA.prototype, "expiryDate", void 0);
        Data.RSA = RSA;
        class FirstAid extends ResponseBase_1.ResponseBase {
        }
        FirstAid.type = enums_1.CertificationType.FIRST_AID;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([enums_1.CertificationType.FIRST_AID]),
            (0, tslib_1.__metadata)("design:type", String)
        ], FirstAid.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: FileClaim_1.FileClaim }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.ArrayMinSize)(1),
            (0, class_validator_1.ArrayMaxSize)(5),
            (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], FirstAid.prototype, "claims", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
            (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
        ], FirstAid.prototype, "expiryDate", void 0);
        Data.FirstAid = FirstAid;
        class Freeform extends ResponseBase_1.ResponseBase {
        }
        Freeform.type = enums_1.CertificationType.FREEFORM;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([enums_1.CertificationType.FREEFORM]),
            (0, tslib_1.__metadata)("design:type", String)
        ], Freeform.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: FileClaim_1.FileClaim }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], Freeform.prototype, "claims", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
            (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
        ], Freeform.prototype, "expiryDate", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(FormSubmission_1.FormSubmissionResponse),
            (0, tslib_1.__metadata)("design:type", FormSubmission_1.FormSubmissionResponse)
        ], Freeform.prototype, "submission", void 0);
        Data.Freeform = Freeform;
        class Union extends designed_1.Entity.Union.define({
            key: 'type',
            entries: [Freeform, FirstAid, RSA, DriversLicence],
        }) {
        }
        Data.Union = Union;
    })(Data = Submit.Data || (Submit.Data = {}));
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Response.prototype, "certificationSubmissionId", void 0);
    Submit.Response = Response;
    class PersonalRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], PersonalRequest.prototype, "certificationDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Data.Union),
        (0, tslib_1.__metadata)("design:type", Data.Union)
    ], PersonalRequest.prototype, "data", void 0);
    Submit.PersonalRequest = PersonalRequest;
    class AdminRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AdminRequest.prototype, "certificationDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AdminRequest.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Data.Union),
        (0, tslib_1.__metadata)("design:type", Data.Union)
    ], AdminRequest.prototype, "data", void 0);
    Submit.AdminRequest = AdminRequest;
})(Submit = exports.Submit || (exports.Submit = {}));

/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import {
  Box,
  Center,
  Tag,
  TagLabel,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Percentage } from '@zap-onboard/api-client';

import { Table } from '@zap-onboard/web-components';
import { ProgressMeter } from '../../ProgressMeter';

export enum ActionItemType {
  WORKFLOW = 'WORKFLOW',
  CERT = 'CERT',
  MULTI = 'MULTI', // quantity or type
}

export type ActionItemProps = {
  id: string;
  title: string;
  subtitle?: string;
  status?: string;
  statusColor?: string;
  progress?: Percentage;
  type: ActionItemType;
  flowId?: string;
  memberId?: string;
  context: 'user' | 'business';
};

type SimpleActionItemProps = {
  title: string;
  subtitle?: string;
  progress?: Percentage;
  tag?: { text: string; color: string };
  onClick: () => unknown;
};

export const SimpleActionItem: React.FC<SimpleActionItemProps> = ({
  tag,
  title,
  subtitle,
  progress,
  onClick,
}) => {
  const isCompactView = useBreakpointValue({ base: true, md: false });
  return (
    <Box onClick={onClick} cursor="pointer">
      <Table.Row
        heading={title}
        subheading={subtitle}
        renderCenter={
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {tag && (
              <Center h="100%">
                <Tag
                  textAlign="center"
                  colorScheme={tag.color}
                  py={1}
                  px={2}
                  size="sm"
                >
                  <TagLabel>{tag.text}</TagLabel>
                </Tag>
              </Center>
            )}
          </>
        }
        renderEnd={
          progress ? (
            <Center h="100%" justifyContent="flex-end">
              {' '}
              <ProgressMeter progress={progress} />{' '}
            </Center>
          ) : undefined
        }
        type={isCompactView ? 'compact' : 'standard'}
      />
    </Box>
  );
};

/*
 * @deprecated
 */
export const ActionItem: React.FC<ActionItemProps> = ({
  title,
  subtitle,
  status,
  statusColor,
  progress,
}: ActionItemProps) => {
  const isCompactView = useBreakpointValue({ base: true, md: false });

  const renderCenterContent = () => {
    if (status && statusColor) {
      return <StatusLabel status={status} statusColor={statusColor} />;
    }

    return null;
  };

  const renderEndContent = () => {
    if (progress) {
      return (
        <Center h="100%" justifyContent="flex-end">
          <ProgressMeter progress={progress} />
        </Center>
      );
    }
    return null;
  };

  return (
    <Table.Row
      heading={title}
      subheading={subtitle ?? null}
      renderCenter={renderCenterContent}
      renderEnd={renderEndContent}
      type={isCompactView ? 'compact' : 'standard'}
    />
  );
};

const StatusLabel: React.FC<{ statusColor: string; status: string }> = ({
  statusColor,
  status,
}) => (
  <Center h="100%">
    <Tag
      bg={statusColor}
      color="white"
      textAlign="center"
      py={1}
      px={2}
      size="sm"
    >
      <TagLabel>{status}</TagLabel>
    </Tag>
  </Center>
);

import React from 'react';
import { Box, Grid, Heading } from '@chakra-ui/react';

import { Info } from './Info';
import { useCompactView } from '../../hooks/useCompactView';

export const SupportPage: React.FC = () => {
  const { isCompactView } = useCompactView();
  return (
    <>
      <Heading variant="page" textAlign={isCompactView ? 'center' : 'left'}>
        Support
      </Heading>
      <Box p={[0, '4']}>
        <Grid
          templateColumns="repeat(auto-fit, minmax(320px, 1fr))"
          gap={['2', '6']}
        >
          <Info />
        </Grid>
      </Box>
    </>
  );
};

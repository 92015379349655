import { Pages } from '../shared/Paper';
import { Field } from '../Field/Field';
import { usePDFState } from '../usePDFState';
import { useMemo } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useCompactView } from 'src/hooks';
import { SELECTABLE_CLASSNAME } from '../constants';

interface Props {
  backgrounds: string[];
}

export const Form = (props: Props) => {
  const { backgrounds } = props;

  const order = useMemo(() => usePDFState.getState().computed.fieldOrder, []);

  const focusField = usePDFState((s) => s.setFocusedFieldId);
  const { isCompactView } = useCompactView();

  const mobilePanner = usePDFState((s) => s.refs.mobilePanner);
  const wrapper = (children: JSX.Element) => {
    if (!isCompactView) {
      return children;
    }

    const excluded = [
      SELECTABLE_CLASSNAME,
      'input',
      'textarea',
      'button',
      'pdf-ignore-pan',
    ];
    return (
      <TransformWrapper
        ref={mobilePanner}
        zoomAnimation={{ disabled: true }}
        alignmentAnimation={{ disabled: true }}
        doubleClick={{ disabled: true }}
        panning={{ velocityDisabled: true, excluded }}
        pinch={{ excluded }}
        wheel={{ excluded }}
      >
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </TransformComponent>
      </TransformWrapper>
    );
  };

  return wrapper(
    <Pages
      backgrounds={backgrounds}
      renderField={({ field }) => (
        <Field
          onFocus={() => focusField(field.fieldId)}
          key={field.fieldId}
          tabIndex={order[field.fieldId]}
          field={field}
          isForm
        />
      )}
    />,
  );
};

import { XeroConnectionPurpose } from '@zap-onboard/api-client';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../components';
import { onError } from '../../libs/errorLib';
import { useAPI } from '../../services/API';

export const OAuthXeroFastSignup = () => {
  const api = useAPI();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await api
        .xero()
        .beginConnection({
          purpose: XeroConnectionPurpose.SIGN_UP,
          isXeroLead: true,
        })
        .tap(
          ({ connectionURL }) => (window.location.href = connectionURL),
          (err) => {
            history.push('/');
            onError(err);
          },
        );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return <Spinner />;
};

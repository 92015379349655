/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { createStandaloneToast } from '@chakra-ui/react';
import { errs } from '@zap-onboard/errors';
import { Topic } from 'designed';
import { track } from './track';
import { theme } from '../theme';
import { isDev } from '../helpers';

const standardErrors = [
  errs.InvalidSessionTokenError,
  errs.NoAuthorizationHeaderError,
  errs.MFARequiredError,
] as const;

type MessageOnlyError = {
  message: string;
};

const isMessageOnlyError = (err: unknown): err is MessageOnlyError =>
  (err as MessageOnlyError).message !== undefined;

export const $errorToasts = Topic.create<string>();

const seenErrors = new WeakSet();

export function onError<T = unknown>(error: T): T {
  if (seenErrors.has(error as any)) {
    return error as T;
  }
  if (!standardErrors.find((errorClass) => error instanceof errorClass)) {
    const message = extractMessage(error);
    logError(error);
    const toast = createStandaloneToast({ theme });
    toast({
      title: 'Info',
      description: `${message}`,
      status: 'info',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    $errorToasts.publish(message);
    track.event('Displayed Error', { errorMessage: message });
  }
  return error as T;
}

export const $errorLog = Topic.create<Error>();

if (isDev) {
  $errorLog.subscribe((err) => {
    const toast = createStandaloneToast({ theme });
    toast({
      title: 'Sentry',
      description: `${err.name}, ${err.message}`,
      status: 'error',
      duration: 5000,
      isClosable: true,
      position: 'bottom-right',
    });
  });
}

export function logError<T>(error: T): T {
  const message = extractMessage(error);
  if (seenErrors.has(error as any)) {
    return error as T;
  }

  if (error instanceof Error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Sentry.captureException(error, { contexts: { ...error } as any });
    $errorLog.publish(error);
  } else {
    Sentry.captureMessage(message);
  }

  if (process.env.NODE_ENV !== 'test') {
    console.group(message);
    console.dir(error);
    console.groupEnd();
  }

  seenErrors.add(error as any);
  return error;
}

const extractMessage = (error: unknown) => {
  let message: string;

  if (error instanceof Error) {
    message = error.message;
  } else if (error && isMessageOnlyError(error)) {
    message = error.message;
  } else if (error && typeof error === 'object') {
    // eslint-disable-next-line @typescript-eslint/ban-types
    message = (error as Object).toString();
  } else {
    message = 'An unkown error occured';
  }

  return message;
};

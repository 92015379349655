"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeputyLinkEmployeeActions = exports.DeputyLinkEmployeePerformAction = exports.DeputyLinkEmployeeActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
var DeputyLinkEmployeeActionType;
(function (DeputyLinkEmployeeActionType) {
    DeputyLinkEmployeeActionType["DEPUTY_LINK_EMPLOYEE_PERFORM"] = "DEPUTY_LINK_EMPLOYEE_PERFORM";
})(DeputyLinkEmployeeActionType = exports.DeputyLinkEmployeeActionType || (exports.DeputyLinkEmployeeActionType = {}));
class DeputyLinkEmployeePerformAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return DeputyLinkEmployeePerformAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyLinkEmployeePerformAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyLinkEmployeePerformAction.prototype, "deputyLocationId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyLinkEmployeePerformAction.prototype, "deputyEmployeeId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DeputyLinkEmployeePerformAction.prototype, "sendInvite", void 0);
exports.DeputyLinkEmployeePerformAction = DeputyLinkEmployeePerformAction;
exports.DeputyLinkEmployeeActions = {
    [DeputyLinkEmployeeActionType.DEPUTY_LINK_EMPLOYEE_PERFORM]: DeputyLinkEmployeePerformAction,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadFormSubmissionItem = exports.FileUploadFormSchemaItem = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../../../file");
const FileClaim_1 = require("../../../file/FileClaim");
const FormSchemaItem_1 = require("./FormSchemaItem");
const type = 'FILE_UPLOAD';
class FileUploadFormSchemaItem extends FormSchemaItem_1.FormSchemaItem {
    constructor() {
        super(...arguments);
        this.type = type;
    }
    async validateItem({ item, }) {
        const { claim } = item;
        if (this.required && !claim) {
            throw errors_1.errs.ValidationError.create(`${this.title} did not have a file attached`, {
                details: { item, schema: this },
            });
        }
    }
    makeEmptySubmission() {
        return FileUploadFormSubmissionItem.create({
            type: 'FILE_UPLOAD',
            formSchemaItemId: this.formSchemaItemId,
            claim: undefined,
        });
    }
}
FileUploadFormSchemaItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", Object)
], FileUploadFormSchemaItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadFormSchemaItem.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(file_1.FileUploadTargetType, { each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, class_validator_1.ArrayUnique)(),
    (0, tslib_1.__metadata)("design:type", Array)
], FileUploadFormSchemaItem.prototype, "permittedFileTypes", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadFormSchemaItem.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], FileUploadFormSchemaItem.prototype, "required", void 0);
exports.FileUploadFormSchemaItem = FileUploadFormSchemaItem;
class FileUploadFormSubmissionItem extends FormSchemaItem_1.FormSubmissionItemBase {
    constructor() {
        super(...arguments);
        this.type = type;
    }
}
FileUploadFormSubmissionItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], FileUploadFormSubmissionItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], FileUploadFormSubmissionItem.prototype, "claim", void 0);
exports.FileUploadFormSubmissionItem = FileUploadFormSubmissionItem;

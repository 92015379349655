"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSubmission = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const CertificationType_1 = require("../enums/CertificationType");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const flow_1 = require("../../flow");
const FormSubmission_1 = require("../../flow/task/FormTask/FormSubmission");
const file_1 = require("../../file");
const enums_1 = require("../enums");
var GetSubmission;
(function (GetSubmission) {
    let Request;
    (function (Request) {
        let Mine;
        (function (Mine) {
            class Latest extends ResponseBase_1.ResponseBase {
            }
            (0, tslib_1.__decorate)([
                designed_1.Entity.Field(),
                (0, class_validator_1.IsUUID)(),
                (0, tslib_1.__metadata)("design:type", String)
            ], Latest.prototype, "certificationDesignId", void 0);
            Mine.Latest = Latest;
            class Specific extends ResponseBase_1.ResponseBase {
            }
            (0, tslib_1.__decorate)([
                designed_1.Entity.Field(),
                (0, class_validator_1.IsUUID)(),
                (0, tslib_1.__metadata)("design:type", String)
            ], Specific.prototype, "certificationSubmissionId", void 0);
            Mine.Specific = Specific;
        })(Mine = Request.Mine || (Request.Mine = {}));
        let ForMember;
        (function (ForMember) {
            class Latest extends ResponseBase_1.ResponseBase {
            }
            (0, tslib_1.__decorate)([
                designed_1.Entity.Field(),
                (0, class_validator_1.IsUUID)(),
                (0, tslib_1.__metadata)("design:type", String)
            ], Latest.prototype, "certificationDesignId", void 0);
            (0, tslib_1.__decorate)([
                designed_1.Entity.Field(),
                (0, class_validator_1.IsUUID)(),
                (0, tslib_1.__metadata)("design:type", String)
            ], Latest.prototype, "memberId", void 0);
            ForMember.Latest = Latest;
            class Specific extends ResponseBase_1.ResponseBase {
            }
            (0, tslib_1.__decorate)([
                designed_1.Entity.Field(),
                (0, class_validator_1.IsUUID)(),
                (0, tslib_1.__metadata)("design:type", String)
            ], Specific.prototype, "certificationSubmissionId", void 0);
            ForMember.Specific = Specific;
        })(ForMember = Request.ForMember || (Request.ForMember = {}));
    })(Request = GetSubmission.Request || (GetSubmission.Request = {}));
    let Submission;
    (function (Submission) {
        class PositionListing extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionListing.prototype, "positionId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], PositionListing.prototype, "name", void 0);
        Submission.PositionListing = PositionListing;
        class Base extends ResponseBase_1.ResponseBase {
            isExemptable() {
                return (this.requirementStatus === enums_1.CertRequirementStatus.REQUIRED &&
                    this.requiredForPosition);
            }
            isMemberRequirementRemovable() {
                return (this.requirementStatus === enums_1.CertRequirementStatus.REQUIRED &&
                    this.requiredForMember &&
                    !this.requiredForPosition);
            }
            isArchived() {
                return this.requirementStatus === enums_1.CertRequirementStatus.ARCHIVED;
            }
            isExempt() {
                return this.requirementStatus === enums_1.CertRequirementStatus.EXEMPT;
            }
            isExpiring() {
                if (this.isExpired() || !this.expiryDate || !this.expiryNoticePeriod) {
                    return false;
                }
                if (this.expiryDate.add({ days: -this.expiryNoticePeriod }).isInPast()) {
                    return true;
                }
                return false;
            }
            isExpired() {
                if (!this.expiryDate) {
                    return false;
                }
                return this.expiryDate.isInPast();
            }
            hasBeenSubmitted() {
                return !!this.certificationSubmissionId;
            }
            isNotReferenced() {
                return this.requirementStatus === enums_1.CertRequirementStatus.NONE;
            }
            isIgnored() {
                if ([
                    enums_1.CertRequirementStatus.ARCHIVED,
                    enums_1.CertRequirementStatus.EXEMPT,
                ].includes(this.requirementStatus) ||
                    this.designArchived) {
                    return true;
                }
                if (!this.hasBeenSubmitted() &&
                    this.requirementStatus === enums_1.CertRequirementStatus.NONE) {
                    return true;
                }
                return false;
            }
            isUnassigned() {
                return this.requirementStatus === enums_1.CertRequirementStatus.NONE;
            }
            isInvalid() {
                return (this.status === enums_1.CertSubmissionStatus.REJECTED ||
                    this.status === enums_1.CertSubmissionStatus.SUBMITTED ||
                    this.isExpired() ||
                    this.status === enums_1.CertSubmissionStatus.UNSUBMITTED);
            }
            isValid() {
                return (this.status === enums_1.CertSubmissionStatus.APPROVED && !this.isExpired());
            }
            statusOnlyDisplay() {
                return {
                    [enums_1.CertSubmissionStatus.APPROVED]: {
                        text: 'Approved',
                        color: 'green',
                    },
                    [enums_1.CertSubmissionStatus.REJECTED]: {
                        text: 'Rejected',
                        color: 'red',
                    },
                    [enums_1.CertSubmissionStatus.SUBMITTED]: {
                        text: 'Submitted',
                        color: 'blue',
                    },
                    [enums_1.CertSubmissionStatus.UNSUBMITTED]: {
                        text: 'Unsubmitted',
                        color: 'yellow',
                    },
                }[this.status];
            }
            statusDisplay() {
                if (this.requirementStatus === enums_1.CertRequirementStatus.EXEMPT) {
                    return {
                        text: 'Exempt',
                        color: 'teal',
                    };
                }
                if (this.requirementStatus === enums_1.CertRequirementStatus.ARCHIVED) {
                    return {
                        text: 'Archived',
                        color: 'orange',
                    };
                }
                if (this.requirementStatus === enums_1.CertRequirementStatus.NONE &&
                    this.status === enums_1.CertSubmissionStatus.UNSUBMITTED) {
                    return {
                        text: 'Not required',
                        color: 'blackAlpha',
                    };
                }
                return this.statusOnlyDisplay();
            }
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: PositionListing }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.IsInstance)(PositionListing, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], Base.prototype, "positions", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "rejectionMessage", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsInt)(),
            (0, tslib_1.__metadata)("design:type", Number)
        ], Base.prototype, "expiryNoticePeriod", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "certificationDesignId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "certificationSubmissionId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], Base.prototype, "designArchived", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsEnum)(enums_1.CertSubmissionStatus),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "status", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsEnum)(enums_1.CertRequirementStatus),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "requirementStatus", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], Base.prototype, "requiredForMember", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], Base.prototype, "requiredForPosition", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
            (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
        ], Base.prototype, "submittedAt", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
            (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
        ], Base.prototype, "expiryDate", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "name", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "userName", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "memberId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Base.prototype, "businessName", void 0);
        class DriversLicence extends Base {
            getFileCount() {
                return utils_1.RequiredIntegerRange.create({
                    min: 2,
                    max: 2,
                });
            }
        }
        DriversLicence.type = CertificationType_1.CertificationType.DRIVERS_LICENCE;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.DRIVERS_LICENCE]),
            (0, tslib_1.__metadata)("design:type", String)
        ], DriversLicence.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: file_1.RemoteFile }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.IsInstance)(file_1.RemoteFile, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], DriversLicence.prototype, "files", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], DriversLicence.prototype, "licenceNumber", void 0);
        Submission.DriversLicence = DriversLicence;
        class RSA extends Base {
            getFileCount() {
                return utils_1.RequiredIntegerRange.create({
                    min: 1,
                    max: 5,
                });
            }
        }
        RSA.type = CertificationType_1.CertificationType.RSA;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.RSA]),
            (0, tslib_1.__metadata)("design:type", String)
        ], RSA.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: file_1.RemoteFile }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.IsInstance)(file_1.RemoteFile, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], RSA.prototype, "files", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], RSA.prototype, "licenceNumber", void 0);
        Submission.RSA = RSA;
        class FirstAid extends Base {
            getFileCount() {
                return utils_1.RequiredIntegerRange.create({
                    min: 1,
                    max: 5,
                });
            }
        }
        FirstAid.type = CertificationType_1.CertificationType.FIRST_AID;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.FIRST_AID]),
            (0, tslib_1.__metadata)("design:type", String)
        ], FirstAid.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: file_1.RemoteFile }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.IsInstance)(file_1.RemoteFile, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], FirstAid.prototype, "files", void 0);
        Submission.FirstAid = FirstAid;
        class FreeformDesign extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInt)(),
            (0, tslib_1.__metadata)("design:type", Number)
        ], FreeformDesign.prototype, "version", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(flow_1.FormSchema),
            (0, tslib_1.__metadata)("design:type", flow_1.FormSchema)
        ], FreeformDesign.prototype, "schema", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(utils_1.RequiredIntegerRange),
            (0, tslib_1.__metadata)("design:type", utils_1.RequiredIntegerRange)
        ], FreeformDesign.prototype, "fileCount", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], FreeformDesign.prototype, "hasExpiryDate", void 0);
        Submission.FreeformDesign = FreeformDesign;
        class Freeform extends Base {
            getFileCount() {
                return this.latestDesign.fileCount;
            }
        }
        Freeform.type = CertificationType_1.CertificationType.FREEFORM;
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)([CertificationType_1.CertificationType.FREEFORM]),
            (0, tslib_1.__metadata)("design:type", String)
        ], Freeform.prototype, "type", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: file_1.RemoteFile }),
            (0, class_validator_1.ArrayUnique)(),
            (0, class_validator_1.IsInstance)(file_1.RemoteFile, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], Freeform.prototype, "files", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(FreeformDesign),
            (0, tslib_1.__metadata)("design:type", FreeformDesign)
        ], Freeform.prototype, "design", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(FreeformDesign),
            (0, tslib_1.__metadata)("design:type", FreeformDesign)
        ], Freeform.prototype, "latestDesign", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsInstance)(FormSubmission_1.FormSubmissionResponse),
            (0, tslib_1.__metadata)("design:type", FormSubmission_1.FormSubmissionResponse)
        ], Freeform.prototype, "submission", void 0);
        Submission.Freeform = Freeform;
        class Union extends designed_1.Entity.Union.define({
            key: 'type',
            entries: [DriversLicence, RSA, FirstAid, Freeform],
        }) {
        }
        Submission.Union = Union;
    })(Submission = GetSubmission.Submission || (GetSubmission.Submission = {}));
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Submission.Union),
        (0, tslib_1.__metadata)("design:type", Submission.Union)
    ], Response.prototype, "submission", void 0);
    GetSubmission.Response = Response;
})(GetSubmission = exports.GetSubmission || (exports.GetSubmission = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button, ButtonGroup, } from '@chakra-ui/react';
/**
 * TODO: Migrate to modal directory alongside other use-case modals
 */
export var ConfirmationAlert = function (_a) {
    var proceedWithAction = _a.proceedWithAction, isOpen = _a.isOpen, setIsConfirmationAlertOpen = _a.setIsConfirmationAlertOpen, _b = _a.header, header = _b === void 0 ? 'Confirmation required' : _b, _c = _a.body, body = _c === void 0 ? 'Do you want to leave this task? You will lose any unsaved changes.' : _c;
    var onClose = function () { return setIsConfirmationAlertOpen(false); };
    var cancelRef = React.useRef();
    var handleClick = function () {
        proceedWithAction();
        onClose();
    };
    return (_jsx(AlertDialog, __assign({ isOpen: isOpen, leastDestructiveRef: cancelRef.current, onClose: onClose }, { children: _jsx(AlertDialogOverlay, { children: _jsxs(AlertDialogContent, { children: [_jsx(AlertDialogHeader, __assign({ fontSize: "md", fontWeight: "bold" }, { children: header }), void 0), _jsx(AlertDialogBody, __assign({ fontSize: "sm" }, { children: body }), void 0), _jsx(AlertDialogFooter, { children: _jsxs(ButtonGroup, __assign({ spacing: 2 }, { children: [_jsx(Button, __assign({ variant: "danger", onClick: handleClick, ml: 3 }, { children: "Confirm" }), void 0), _jsx(Button, __assign({ variant: "outline", ref: cancelRef.current, onClick: onClose }, { children: "Cancel" }), void 0)] }), void 0) }, void 0)] }, void 0) }, void 0) }), void 0));
};

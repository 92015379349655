import * as React from 'react';
import {
  Avatar,
  Button,
  Center,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { biz, UserProfile } from '@zap-onboard/api-client';
import { errs } from '@zap-onboard/errors';
import { logError, onError } from '../../../libs/errorLib';
import { useAPI } from '../../../services/API';
import { useStateIfMounted } from '../../../hooks';

import { UserNameForm } from './UserNameForm';
import { auth } from '../../../auth';

type CreateAndStartApplicationProps = {
  business: biz.Get.Business.Response;
  userProfile: UserProfile;
};
const Layout: React.FC<{
  heading: string;
  business: biz.Get.Business.Response;
}> = ({ heading, business, children }) => (
  <Center>
    <Stack spacing={3} p={2} textAlign="center">
      <Center>
        <Avatar
          sx={{
            '& img': {
              objectFit: 'contain',
            },
          }}
          background={business.business.logo ? 'none' : undefined}
          borderRadius="0"
          size="xl"
          name={business?.business.name}
          src={business.business.logo?.url}
        />
      </Center>
      <Heading textAlign="center" size="lg">
        {heading}
      </Heading>
      {children}
    </Stack>
  </Center>
);

export const CreateAccessRequest = ({
  business,
  userProfile,
}: CreateAndStartApplicationProps) => {
  const { useCallback } = React;
  const api = useAPI();
  const history = useHistory();
  const toast = useToast();

  const [isSubmitting, setIsSubmitting] = useStateIfMounted(false);
  const isDisabled = isSubmitting;

  const requestAccess = useCallback(async () => {
    setIsSubmitting(true);
    await api
      .membership()
      .accessRequest.open({ businessId: business.business.businessId })
      .tapAsync(
        async () => {
          history.push(`/`);
          await auth.reInit({ currentBusinessId: undefined });
        },
        async (err) => {
          if (err instanceof errs.AlreadyExistsError) {
            logError(
              errs.ClientError.wrap(
                err,
                'user submitted access request more than once',
              ),
            );
            toast({
              title: 'Access request already complete',
              status: 'success',
            });
            history.push('/');
            await auth.reInit({ currentBusinessId: undefined });
          } else {
            onError(err);
          }
        },
      );
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, business.business.businessId, history, toast, setIsSubmitting]);

  if (!userProfile.getFullName()) {
    return (
      <Layout heading="Almost there!" business={business}>
        <Text>
          Please provide your name so <strong>{business.business.name}</strong>{' '}
          knows who you are.
        </Text>
        <Center>
          <UserNameForm userProfile={userProfile} />
        </Center>
      </Layout>
    );
  }

  return (
    <Layout heading="Great!" business={business}>
      <Stack spacing={4}>
        <Text>
          <strong>{business.business.name}</strong> will now be able to view and
          update your profile
        </Text>
        <Center>
          <Button
            isDisabled={isDisabled}
            isLoading={isSubmitting}
            onClick={requestAccess}
          >
            Submit
          </Button>
        </Center>
      </Stack>
    </Layout>
  );
};

import { Topic } from 'designed';
import { Store } from './Store';

export class LocalStorageStore<T> implements Store<T> {
  constructor(
    private key: string,
    private serialize: (value: T) => string,
    private deserialize: (value: string) => T,
  ) {
    window.addEventListener('storage', this.handleStorageChange);
  }

  $remoteChanges: Topic<T | null> = Topic.create();

  get(): T | null {
    const value = localStorage.getItem(this.key);
    if (value == null) {
      return null;
    }
    return this.deserialize(value);
  }

  store(value: T): void {
    if (this.get() === value) {
      return;
    }
    localStorage.setItem(this.key, this.serialize(value));
  }

  set(value: T | null | undefined) {
    if (value == null) {
      this.clear();
    } else {
      this.store(value);
    }
  }

  clear(): void {
    localStorage.removeItem(this.key);
  }

  private handleStorageChange = (event: StorageEvent) => {
    if (event.key === this.key && event.oldValue != event.newValue) {
      this.$remoteChanges.publish(
        event.newValue == null ? null : this.deserialize(event.newValue),
      );
    }
  };
}

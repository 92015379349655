import * as React from 'react';
import { useToast, Text, Button, Center } from '@chakra-ui/react';
import config from '../config';
import { useHandlerQuery } from '../hooks/useHandlerQuery';
import { AsyncResult } from 'designed';
import { useEffect, useRef } from 'react';
import { isDev } from '../helpers/environment';

export const VersionPoll: React.FC = () => {
  const toast = useToast();

  const lastManifest = useRef<string>();

  const manifest = useHandlerQuery(
    () =>
      AsyncResult.fromPromise(
        fetch(`${config.url}/asset-manifest.json`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }),
      ).mapAsync((r) => {
        if (!r.ok) {
          throw new Error('Could not fetch asset manifest');
        }
        return r.text();
      }),
    {
      key: ['ASSET_MANIFEST'],
      staleAfter: '5s',
      refetchEvery: '30s',
      enabled: !isDev,
    },
  );

  useEffect(() => {
    if (manifest.data && lastManifest.current) {
      if (manifest.data !== lastManifest.current) {
        toast({
          position: 'top',
          title: 'Refresh Required',
          isClosable: false,
          duration: null,
          id: 'ASSET_MANIFEST',
          status: 'success',
          description: (
            <>
              <Text>A new version of the app is available, please refresh</Text>
              <Center mt={2}>
                <Button
                  variant="outline"
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </Button>
              </Center>
            </>
          ),
        });
      }
    }
    lastManifest.current = manifest.data;
  }, [manifest.data, toast]);

  return null;
};

import {
  Box,
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Button,
  ButtonGroup,
  Heading,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useQueryInvalidator } from '../../hooks/useHandlerQuery';
import { onError } from '../../libs/errorLib';
import { useAPI } from '../../services/API';
import { WithCardInput } from './ProvideStripeBilling';

export const StripeBillingAccountUpdatePaymentMethodForm = (args: {
  onCancel?: () => unknown;
  onSaved?: () => unknown;
  billingAccountId: string;
}) => {
  const { billingAccountId, onCancel, onSaved } = args;
  const { errors, handleSubmit, register, formState } = useForm();

  const invalidate = useQueryInvalidator();
  const api = useAPI();
  return (
    <WithCardInput>
      {({
        cardInput,
        loadPaymentMethod,
        canSubmit,
        billingAgreementText,
        error,
      }) => {
        const submit = handleSubmit(async ({ name }) => {
          const setupResult = await api
            .billing()
            .createStripeCardSetupIntent({ billingAccountId })
            .map((s) => s.stripeSetupIntentSecret, onError);
          if (!setupResult.isSuccess()) {
            return;
          }

          const paymentMethod = await loadPaymentMethod({
            name,
            stripeSetupIntentSecret: setupResult.get(),
          }).map((s) => s.payment_method, onError);
          if (!paymentMethod.isSuccess()) {
            return;
          }

          const method = paymentMethod.get();
          const stripePaymentMethodId =
            typeof method === 'string' ? method : method?.id;
          if (!stripePaymentMethodId) {
            return;
          }
          await api
            .billing()
            .attachStripePaymentMethodToBillingAccount({
              billingAccountId,
              stripePaymentMethodId,
            })
            .map(() => {
              invalidate(['BUSINESS'], ['MY_BILLING_ACCOUNTS']);
            });
          if (onSaved) {
            onSaved();
          }
        });
        return (
          <>
            <Heading size="md" mb={4}>
              Add card details
            </Heading>
            <Stack spacing={3}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel color="brand.black">Name on card</FormLabel>
                <Input
                  name="name"
                  type="text"
                  variant="outline"
                  placeholder="JOHN SMITH"
                  defaultValue=""
                  ref={register({
                    required: 'You must provide the name on your card',
                    maxLength: 200,
                  })}
                />
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!error}>
                <FormLabel color="brand.black">Card number</FormLabel>
                <Box layerStyle="outline" p={3}>
                  {cardInput}
                </Box>
                <FormErrorMessage>{error}</FormErrorMessage>
              </FormControl>
            </Stack>

            <Text mt={2} color="brand.darker-gray" fontSize="xs">
              {billingAgreementText}
            </Text>
            <ButtonGroup mt={3} display="flex" justifyContent="space-between">
              {onCancel && (
                <Button tabIndex={-1} variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <Button
                onClick={submit}
                isDisabled={!canSubmit}
                isLoading={formState.isSubmitting}
              >
                Save card
              </Button>
            </ButtonGroup>
          </>
        );
      }}
    </WithCardInput>
  );
};

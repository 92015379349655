"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Update = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../../file");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const SuperFundResponse_1 = require("../../utils/SuperFundResponse");
var Update;
(function (Update) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(utils_1.ABN),
        (0, tslib_1.__metadata)("design:type", utils_1.ABN)
    ], Request.prototype, "abn", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.MaxLength)(150),
        (0, class_validator_1.MinLength)(5),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ nullable: true, entity: file_1.FileClaim }),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(file_1.FileClaim),
        (0, tslib_1.__metadata)("design:type", Object)
    ], Request.prototype, "logo", void 0);
    Update.Request = Request;
    let NominatedSuper;
    (function (NominatedSuper) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(SuperFundResponse_1.SuperFundResponse),
            (0, tslib_1.__metadata)("design:type", SuperFundResponse_1.SuperFundResponse)
        ], Request.prototype, "fund", void 0);
        NominatedSuper.Request = Request;
    })(NominatedSuper = Update.NominatedSuper || (Update.NominatedSuper = {}));
})(Update = exports.Update || (exports.Update = {}));

import * as React from 'react';
import { Button, Center, Icon, Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';

import { WorkflowsToAction } from '../../../../WorkflowsToAction';
import { CertsToAction } from '../../../../CertsToAction';

type FlowCompleteProps = {
  context: 'business' | 'user';
};

export const FlowComplete: React.FC<FlowCompleteProps> = ({ context }) => {
  const history = useHistory();

  return (
    <Stack spacing={4} w="100%" h="100%" align="center" px={4}>
      <Icon as={MdCheckCircle} boxSize={16} color="brand.green" />
      <Text textAlign="center">
        This workflow has been successfully completed!
      </Text>
      <WorkflowsToAction context={context} />
      <CertsToAction context={context} />

      <Center>
        <Button
          onClick={() =>
            history.push(
              `/${context === 'business' ? 'business' : 'user'}/dashboard`,
            )
          }
        >
          Dashboard
        </Button>
      </Center>
    </Stack>
  );
};

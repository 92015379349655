import * as React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { inputs } from '@zap-onboard/web-components';

export enum SuperTypes {
  APRA_OR_RSA = 'SUPER_CHOICE_APRA_OR_RSA',
  SMSF = 'SELF_MANAGED_SUPER_FUND',
  EMPLOYER_NOMINATED = 'EMPLOYER_NOMINATED',
  EMPLOYER_NOMINATED_V2 = 'EMPLOYER_NOMINATED_V2',
}

type SuperTypeSelectorProps = {
  isDisabled: boolean;
};

export const SuperTypeSelector: React.FC<SuperTypeSelectorProps> = ({
  isDisabled,
}) => (
  <Stack w="100%">
    <inputs.radio.Group
      name="superChoice.superChoiceType"
      errorPath="NULL"
      label="I request that all my future super contributions be paid to:"
      isDisabled={isDisabled}
    >
      <Stack spacing={2} mt={1}>
        <inputs.radio.Button value={SuperTypes.APRA_OR_RSA}>
          <Text fontWeight="bold">My existing super fund</Text>
          <Text>
            I want my employer to pay into a super account I have already
            opened.
          </Text>
        </inputs.radio.Button>
        <inputs.radio.Button value={SuperTypes.SMSF}>
          <Text fontWeight="bold">My private self managed super fund</Text>
          <Text>
            I am a member and a trustee responsible for managing the fund. I may
            have up to 6 members in the fund.
          </Text>
        </inputs.radio.Button>
        <inputs.radio.Button value={SuperTypes.EMPLOYER_NOMINATED_V2}>
          <Text fontWeight="bold"> My employer&apos;s default super fund</Text>
          <Text>
            I want my employer to open a new account for me in their default
            fund.
          </Text>
        </inputs.radio.Button>
      </Stack>
    </inputs.radio.Group>
  </Stack>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetRole = exports.Role = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const Permissions_1 = require("./Permissions");
class Role extends designed_1.Entity.Base {
    getPermissions() {
        return Permissions_1.Permissions.fromRoleId(this.roleId);
    }
    assumeAdminRole() {
        if (this.isOwner()) {
            throw errors_1.errs.AuthorizationError.create('That member is already the owner');
        }
        if (this.isAdmin()) {
            return;
        }
        this.roleId = 'ADMIN';
    }
    assumeSupervisorRole() {
        if (this.isOwner()) {
            throw errors_1.errs.AuthorizationError.create('That member is the owner of the business. To change the owner of your business contact support.');
        }
        if (this.isSupervisor()) {
            return;
        }
        this.roleId = 'SUPERVISOR';
    }
    assumeUserRole() {
        if (this.isOwner()) {
            throw errors_1.errs.AuthorizationError.create('That member is the owner of the business. To change the owner of your business contact support.');
        }
        this.roleId = 'USER';
    }
    isOwner() {
        return this.roleId === 'OWNER';
    }
    isSupervisor() {
        return this.roleId === 'SUPERVISOR';
    }
    isAdmin() {
        return this.roleId === 'ADMIN';
    }
    isBusinessManager() {
        return ['ADMIN', 'OWNER', 'SUPERVISOR'].includes(this.roleId);
    }
    hasAllPermissions() {
        return ['ADMIN', 'OWNER'].includes(this.roleId);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Role.prototype, "roleId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Function),
    (0, tslib_1.__metadata)("design:paramtypes", []),
    (0, tslib_1.__metadata)("design:returntype", Permissions_1.Permissions)
], Role.prototype, "getPermissions", null);
exports.Role = Role;
var SetRole;
(function (SetRole) {
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "roleId", void 0);
    SetRole.Request = Request;
})(SetRole = exports.SetRole || (exports.SetRole = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperFundResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ABN_1 = require("./ABN");
const ResponseBase_1 = require("../ResponseBase");
class SuperFundResponse extends ResponseBase_1.ResponseBase {
    equals(that) {
        return this.abn.equals(that.abn) && this.usi.trim() === that.usi.trim();
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ABN_1.ABN),
    (0, tslib_1.__metadata)("design:type", ABN_1.ABN)
], SuperFundResponse.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperFundResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperFundResponse.prototype, "usi", void 0);
exports.SuperFundResponse = SuperFundResponse;

import { useEffect, useRef } from 'react';
import { debounce } from '../helpers';
import { usePDFState } from '../usePDFState';

export const useWrapperSize = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current } = wrapperRef;
    if (!current) {
      throw new Error('Could not mount PDF component due to missing reference');
    }
    const updateSize = debounce(() => {
      usePDFState.setState({
        outerSize: {
          type: 'PX',
          height: current.clientHeight || window.innerHeight,
          width: current.clientWidth || window.innerWidth,
        },
      });
    }, 100);
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { wrapperRef, wrapperSize: usePDFState((s) => s.outerSize) };
};

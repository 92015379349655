var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tabs as ChakraTabs, TabList, TabPanels, Tab, TabPanel, } from '@chakra-ui/react';
export var Tabs = function (_a) {
    var tabs = _a.tabs, tabPanels = _a.tabPanels, _b = _a.tabPanelProps, tabPanelProps = _b === void 0 ? { p: 0 } : _b, isLazy = _a.isLazy;
    return (_jsxs(ChakraTabs, __assign({ isLazy: isLazy }, { children: [_jsx(TabList, { children: tabs.map(function (tab) { return (_jsx(Tab, { children: tab }, tab)); }) }, void 0), _jsx(TabPanels, { children: tabPanels.map(function (tabPanel, idx) {
                    var _a;
                    return (_jsx(TabPanel, __assign({}, tabPanelProps, { children: tabPanel }), (_a = tabs[idx]) !== null && _a !== void 0 ? _a : idx));
                }) }, void 0)] }), void 0));
};

import React from 'react';
import { Box, Link, Text, Icon, Heading, Button } from '@chakra-ui/react';
import { useIntercom } from 'react-use-intercom';
import { FiExternalLink } from 'react-icons/fi';
import config from '../../../config';

export const Info: React.FC = () => {
  const { show } = useIntercom();
  return (
    <Box p="4" layerStyle="base">
      <Box mt="4">
        <Heading size="lg" py={3}>
          Online Support Page 👩‍💻
        </Heading>
        <Text>
          The User Guide and How-To section on the Canyou website offers
          step-by-step instructions and helpful resources to assist users in
          maximizing their experience with our platform.
        </Text>
        <Link
          href={`${config.landingPageUrl}/support`}
          isExternal
          color="brand.blue"
        >
          User Guide and How To&apos;s <Icon as={FiExternalLink} mx="2px" />
        </Link>
      </Box>
      <Box mt="4">
        <Heading size="lg" py={3}>
          Instructional Videos 🎥
        </Heading>
        <Text>
          Our library of instructional videos provides visual guides on how to
          use the Canyou platform and its features.
        </Text>
        <Link
          href="https://youtube.com/@canyouapp/videos"
          isExternal
          color="brand.blue"
        >
          Video library
          <Icon as={FiExternalLink} mx="2px" />
        </Link>
      </Box>
      <Box mt="4">
        <Heading size="lg" py={3}>
          Send Us a Message 💬
        </Heading>
        <Text py={3} mb={2}>
          Need to get in touch or send us a message? Start a live chat now.
        </Text>
        <Button onClick={() => show()} mb={4}>
          Start a chat
        </Button>
      </Box>
    </Box>
  );
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Heading,
  Link,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { SiXero } from 'react-icons/si';

import { useAPI } from '../../services/API';
import { useMyBillingAccounts } from '../hooks/useMyBillingAccounts';

import { StripeBillingAccountUpdatePaymentMethodForm } from '../stripe/StripeBillingAccountUpdatePaymentMethodForm';
import { UpdateBillingAccountDetailsForm } from '../UpdateBillingAccountDetailsForm';
import { useCurrentBusiness } from '../../hooks';
import {
  makeXeroCancelLink,
  makeXeroSubscribeLink,
} from '../../helpers/xeroDeepLinks';
import { BillingAccountListingResponse } from '@zap-onboard/api-client';
import { useBillingPaymentSummary } from '../hooks/useBillingAccountSubscriptions';
import { Spinner } from '../../components';
import { useBillingMutations } from '../hooks/useBillingMutations';
import { useHistory } from 'react-router-dom';
import { auth } from '../../auth';

type Stage =
  | 'PLAN_AND_ACCOUNT_SELECT'
  | 'BILLING_DETAILS'
  | 'PAYMENT_DETAILS'
  | 'CONFIRMATION'
  | 'SUCCESS';

export const BusinessSetupBillingWizard = (props: {
  onComplete?: () => unknown;
}) => {
  const { onComplete } = props;

  const [stage, setStage] = useState<Stage>('PLAN_AND_ACCOUNT_SELECT');
  const myBillingAccounts = useMyBillingAccounts();
  const [selectedBillingAccountId, setSelectedBillingAccountId] =
    useState<string>();
  const selectedBillingAccount = useMemo(
    () =>
      myBillingAccounts.data?.find(
        (account) => account.billingAccountId === selectedBillingAccountId,
      ),
    [selectedBillingAccountId, myBillingAccounts.data],
  );

  const { newBillingAccountId, clearNewBillingAccount } =
    useCreateNewBillingAccount({ stage });

  if (myBillingAccounts.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {stage === 'PLAN_AND_ACCOUNT_SELECT' && (
        <PickPlan
          selectedBillingAccountId={selectedBillingAccountId}
          setSelectedBillingAccountId={setSelectedBillingAccountId}
          selectedBillingAccount={selectedBillingAccount}
          setStage={setStage}
        />
      )}

      {stage === 'BILLING_DETAILS' && newBillingAccountId && (
        <UpdateBillingAccountDetailsForm
          billingAccountId={newBillingAccountId}
          onSaved={() => setStage('PAYMENT_DETAILS')}
          onCancel={() => {
            setStage('PLAN_AND_ACCOUNT_SELECT');
            clearNewBillingAccount();
          }}
        />
      )}

      {stage === 'PAYMENT_DETAILS' && (
        <StripeBillingAccountUpdatePaymentMethodForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          billingAccountId={newBillingAccountId!}
          onSaved={() => setStage('CONFIRMATION')}
          onCancel={() => setStage('BILLING_DETAILS')}
        />
      )}

      {stage === 'CONFIRMATION' && (
        <Confirmation
          newBillingAccountId={newBillingAccountId}
          selectedBillingAccount={selectedBillingAccount}
          setStage={setStage}
          onComplete={onComplete}
        />
      )}
    </>
  );
};

function useCreateNewBillingAccount(args: { stage: Stage }) {
  const { stage } = args;
  const api = useAPI();
  const [newBillingAccountId, setNewBillingAccountId] = useState<string>();
  const [creatingNewBillingAccount, setCreatingNewBillingAccount] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      stage !== 'BILLING_DETAILS' ||
      newBillingAccountId ||
      creatingNewBillingAccount
    ) {
      return;
    }
    setCreatingNewBillingAccount(true);
  }, [newBillingAccountId, api, creatingNewBillingAccount, stage]);

  return {
    newBillingAccountId,
    clearNewBillingAccount: () => {
      setNewBillingAccountId(undefined);
      setCreatingNewBillingAccount(false);
    },
  };
}
const Confirmation = (props: {
  selectedBillingAccount: BillingAccountListingResponse | undefined;
  newBillingAccountId: string | undefined;
  setStage: (v: Stage) => unknown;
  onComplete?: () => unknown;
}) => {
  const { selectedBillingAccount, setStage, onComplete, newBillingAccountId } =
    props;
  const selectedBillingAccountId = selectedBillingAccount?.billingAccountId;
  const stripeBillingAccountId = selectedBillingAccount
    ?.is('STRIPE')
    .map(({ billingAccountId }) => billingAccountId)
    .orElse(undefined);

  const xeroBillingAccount = selectedBillingAccount
    ?.is('XERO')
    .orElse(undefined);

  const payment = useBillingPaymentSummary({
    billingAccountId: stripeBillingAccountId,
  });

  const businessId = auth.useCurrentBusinessId();
  const { isSubscribing, subscribeBusinessWithBillingAccount } =
    useBillingMutations({
      onSubscribe: onComplete,
    });
  const confirmButton = (
    <Button
      isLoading={isSubscribing}
      isDisabled={isSubscribing}
      onClick={() => {
        subscribeBusinessWithBillingAccount({
          billingAccountId: selectedBillingAccountId ?? newBillingAccountId!,
          businessId: businessId!,
          stripePriceId: payment.data?.price?.priceName,
        });
      }}
    >
      {selectedBillingAccount ? 'Add to billing account' : 'Start subscription'}
    </Button>
  );

  const firstTimeSubPriceBox = (
    <Box>
      <Heading size="sm" fontWeight="extrabold" mb={2}>
        Monthly subscription
      </Heading>
      <Text mb={4}>$30 / month per business</Text>
      <Text fontSize="xs">Billed monthly in advance, cancel anytime.</Text>
    </Box>
  );

  const addingSubPriceBox = (
    <Box>
      {!payment.data && <Spinner />}
      {!!payment.data && (
        <>
          <Heading size="sm" fontWeight="extrabold">
            {payment.data.price?.name ?? 'Unknown Plan'}
          </Heading>
          <Text mb={4} fontSize="sm">
            {payment.data.price?.description}
          </Text>
        </>
      )}
    </Box>
  );

  return (
    <Stack spacing={6}>
      <Heading size="md" fontWeight="extrabold">
        {selectedBillingAccount
          ? selectedBillingAccount.paidUntil?.isInFuture()
            ? 'Add to an existing billing account'
            : 'Restart subscription and add to Account'
          : 'Confirm subscription'}
      </Heading>

      <Box layerStyle="base" p={4}>
        {!xeroBillingAccount && (
          <Stack>
            {newBillingAccountId ? firstTimeSubPriceBox : addingSubPriceBox}
          </Stack>
        )}
        {!!xeroBillingAccount && (
          <Stack>
            <Box>
              <Heading size="sm" fontWeight="extrabold">
                {xeroBillingAccount.planName ?? 'Unknown Plan'} -{' '}
                {xeroBillingAccount.name}
              </Heading>
              <Text mb={4} fontSize="sm">
                This business will be added to your existing Xero App Store
                subscription
              </Text>
            </Box>

            {xeroBillingAccount.xeroShortCode && (
              <Box>
                <Button
                  as={Link}
                  isExternal
                  href={makeXeroCancelLink(xeroBillingAccount.xeroShortCode)}
                  variant="ghost"
                >
                  Change plan
                </Button>
              </Box>
            )}
          </Stack>
        )}
      </Box>
      <ButtonGroup mt={3} display="flex" justifyContent="space-between">
        <Button
          onClick={() =>
            newBillingAccountId
              ? setStage('PAYMENT_DETAILS')
              : setStage('PLAN_AND_ACCOUNT_SELECT')
          }
          tabIndex={-1}
          variant="outline"
        >
          Cancel
        </Button>
        {confirmButton}
      </ButtonGroup>
    </Stack>
  );
};

const PickPlan = (props: {
  selectedBillingAccountId: string | undefined;
  setSelectedBillingAccountId: (v: string | undefined) => unknown;
  selectedBillingAccount: BillingAccountListingResponse | undefined;
  setStage: (v: Stage) => unknown;
}) => {
  const {
    selectedBillingAccountId,
    setSelectedBillingAccountId,
    selectedBillingAccount,
    setStage,
  } = props;
  const business = useCurrentBusiness();
  const myBillingAccounts = useMyBillingAccounts();
  const history = useHistory();

  const stripeBillingAccount = selectedBillingAccount
    ?.is('STRIPE')
    .orElse(undefined);

  const xeroBillingAccount = selectedBillingAccount
    ?.is('XERO')
    .orElse(undefined);

  const maxBusinessesMessage = selectedBillingAccount
    ?.is('XERO')
    .map((x) => x.maxBusinesses)
    .filter((x) => x > 1)
    .map((v) => `You can add up to ${v} businesses to this account.`)
    .orElse('Only 1 business can be linked to this account');

  if (myBillingAccounts.isLoading || business.isLoading) {
    return <Spinner />;
  }
  return (
    <Stack spacing={6}>
      {!!myBillingAccounts?.data?.length && (
        <Box p={4} layerStyle="base">
          <Stack>
            <Box>
              <Heading size="sm" fontWeight="extrabold" my={1}>
                Link business to existing billing account
              </Heading>
              <FormLabel
                htmlFor="billingAccountSelector"
                fontSize="sm"
                color="brand.black"
              >
                Choose a billing account
              </FormLabel>
              <Select
                id="billingAccountSelector"
                size="sm"
                placeholder="-"
                value={selectedBillingAccountId ?? ''}
                onChange={(evt) =>
                  evt.target.value === 'NONE'
                    ? setSelectedBillingAccountId(undefined)
                    : setSelectedBillingAccountId(evt.target.value)
                }
              >
                {myBillingAccounts.data.map((account) => (
                  <option
                    key={account.billingAccountId}
                    value={account.billingAccountId}
                  >
                    {account.name}
                  </option>
                ))}
              </Select>
            </Box>

            <Box mt={4}>
              {!!stripeBillingAccount && (
                <Text fontSize="xs">
                  {stripeBillingAccount.paidUntil?.isInFuture()
                    ? 'You will only be charged for the time remaining in your current billing cycle'
                    : 'Your subscription will be renewed immediately'}
                </Text>
              )}
              {!!xeroBillingAccount && (
                <Text fontSize="xs">{maxBusinessesMessage}</Text>
              )}
            </Box>

            <Box>
              <Button
                disabled={!selectedBillingAccount}
                onClick={() => setStage('CONFIRMATION')}
              >
                Add to billing account
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      {!business.data?.business?.isXeroLead && (
        <Box p={4} layerStyle="base">
          <Stack>
            <Box>
              <Heading size="sm" fontWeight="extrabold" my={1}>
                Setup new billing account and monthly subscription
              </Heading>
              <Text fontSize="xs">
                Billed monthly in advance, cancel anytime.
              </Text>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  history.push(
                    `/subscribe/${business.data?.business.businessId}/plans`,
                  );
                }}
              >
                Setup billing account
              </Button>
            </Box>
          </Stack>
        </Box>
      )}
      {!!business.data?.config?.xeroShortCode &&
        !!business.data?.business?.isXeroLead && (
          <Box p={4} layerStyle="base">
            <Stack>
              <Box>
                <Heading size="sm" fontWeight="extrabold">
                  Xero App Store
                </Heading>

                <Text mb={4} fontSize="sm">
                  Pay and manage through the Xero App Store
                </Text>

                <Text fontSize="xs">
                  You wil be redirected to the Xero App Store to choose a plan
                </Text>
              </Box>
              <Box>
                <Button
                  as={Link}
                  isExternal
                  href={makeXeroSubscribeLink(
                    business.data.config.xeroShortCode,
                  )}
                  leftIcon={<SiXero size="1.2rem" />}
                  variant="xero"
                >
                  Subscribe
                </Button>
              </Box>
            </Stack>
          </Box>
        )}
    </Stack>
  );
};

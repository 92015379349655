"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var Action;
(function (Action) {
    let Type;
    (function (Type) {
        class Approve extends ResponseBase_1.ResponseBase {
        }
        Approve.action = 'APPROVE';
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)(['APPROVE']),
            (0, tslib_1.__metadata)("design:type", String)
        ], Approve.prototype, "action", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Approve.prototype, "certificationSubmissionId", void 0);
        Type.Approve = Approve;
        class Reject extends ResponseBase_1.ResponseBase {
        }
        Reject.action = 'REJECT';
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)(['REJECT']),
            (0, tslib_1.__metadata)("design:type", String)
        ], Reject.prototype, "action", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Reject.prototype, "certificationSubmissionId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Reject.prototype, "message", void 0);
        Type.Reject = Reject;
        class MarkExempt extends ResponseBase_1.ResponseBase {
        }
        MarkExempt.action = 'MARK_EXEMPT';
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)(['MARK_EXEMPT']),
            (0, tslib_1.__metadata)("design:type", String)
        ], MarkExempt.prototype, "action", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], MarkExempt.prototype, "memberId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], MarkExempt.prototype, "certificationDesignId", void 0);
        Type.MarkExempt = MarkExempt;
        class Archive extends ResponseBase_1.ResponseBase {
        }
        Archive.action = 'ARCHIVE';
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsIn)(['ARCHIVE']),
            (0, tslib_1.__metadata)("design:type", String)
        ], Archive.prototype, "action", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Archive.prototype, "memberId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Archive.prototype, "certificationDesignId", void 0);
        Type.Archive = Archive;
        class Union extends designed_1.Entity.Union.define({
            key: 'action',
            entries: [Approve, Reject, MarkExempt, Archive],
        }) {
        }
        Type.Union = Union;
    })(Type = Action.Type || (Action.Type = {}));
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Type.Union),
        (0, tslib_1.__metadata)("design:type", Type.Union)
    ], Request.prototype, "action", void 0);
    Action.Request = Request;
})(Action = exports.Action || (exports.Action = {}));

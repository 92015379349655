import * as React from 'react';
import { Flex } from '@chakra-ui/react';

import { ChakraFlexProps } from '../../../types/Chakra';

type TileGroupLayoutProps = Partial<ChakraFlexProps>;

export const TileGroupLayout: React.FC<TileGroupLayoutProps> = ({
  children,
  ...props
}) => (
  <Flex
    justify={{ base: 'center', md: 'flex-start' }}
    w="100%"
    wrap="wrap"
    gridGap={4}
    {...props}
  >
    {children}
  </Flex>
);

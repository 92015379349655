"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyUserDetails = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const MembershipDetails_1 = require("../../position/models/MembershipDetails");
const ResponseBase_1 = require("../../ResponseBase");
const FeatureFlags_1 = require("./FeatureFlags");
const UserProfile_1 = require("./UserProfile");
class MyUserDetails extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(UserProfile_1.UserProfile),
    (0, tslib_1.__metadata)("design:type", UserProfile_1.UserProfile)
], MyUserDetails.prototype, "profile", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MyUserDetails.prototype, "isXeroLead", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: MembershipDetails_1.MembershipDetails }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(MembershipDetails_1.MembershipDetails, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], MyUserDetails.prototype, "memberships", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: FeatureFlags_1.UserFeatureFlags }),
    (0, class_validator_1.IsInstance)(FeatureFlags_1.UserFeatureFlags),
    (0, tslib_1.__metadata)("design:type", FeatureFlags_1.UserFeatureFlags)
], MyUserDetails.prototype, "featureFlags", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MyUserDetails.prototype, "intercomToken", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MyUserDetails.prototype, "superAdmin", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(['SMS', 'TOTP', 'DEV']),
    (0, tslib_1.__metadata)("design:type", String)
], MyUserDetails.prototype, "mfaSource", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(['GOOGLE', 'XERO', 'MAGIC_LINK']),
    (0, tslib_1.__metadata)("design:type", String)
], MyUserDetails.prototype, "authSource", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MyUserDetails.prototype, "mfaConfigured", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MyUserDetails.prototype, "mfaSetupRequired", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MyUserDetails.prototype, "googleConnected", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MyUserDetails.prototype, "googleAvatar", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], MyUserDetails.prototype, "xeroConnected", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], MyUserDetails.prototype, "xeroEmail", void 0);
exports.MyUserDetails = MyUserDetails;

import { useHandler } from '../../useHandlerMutation';

export const useXeroUpdateOrCreateUser = () => {
  const [updateOrCreate, isLoading] = useHandler(
    (api) => api.xero().updateOrCreateUserInXero,
    {
      invalidates: () => [['XERO_EMPLOYEES']],
    },
  );
  return {
    updateOrCreate,
    isLoading,
  };
};

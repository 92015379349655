import { cert } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useMemo } from 'react';
import { auth } from '../../../auth';
import { trackWorkCompletion } from '../../../helpers/tracking';
import { useHandler } from '../../useHandlerMutation';

interface useSubmitCertArgs {
  submission: cert.GetSubmission.Submission.All;
  afterSubmit?: () => unknown;
}

export const useSubmitCert = ({
  submission,
  afterSubmit,
}: useSubmitCertArgs) => {
  const memberships = auth.useMemberships();
  const isForMyself = useMemo(
    () =>
      memberships.find(
        (membership) => membership.memberId === submission.memberId,
      ),
    [memberships, submission],
  );

  const [submit, isSubmitting] = useHandler(
    (api) => (isForMyself ? api.cert().submit : api.cert().submitForMember),
    {
      invalidates: () => [
        ['MEMBER', submission.memberId],
        ['EMPLOYEE_OVERVIEW'],
        ['EMPLOYEE_CERT_SUBMISSION'],
        ['MY_CERT_SUMMARIES'],
        ['BUSINESS_CERT_SUMMARY'],
      ],
      always: {
        onSuccess: async () => {
          trackWorkCompletion({
            workType: 'Certifications',
          });
          if (afterSubmit) {
            await afterSubmit();
          }
        },
      },
    },
  );

  return {
    submit: (data: Entity.Attributes<cert.Submit.Data.Union>) =>
      submit({
        memberId: submission.memberId,
        certificationDesignId: submission.certificationDesignId,
        data,
      }),
    isSubmitting,
  };
};

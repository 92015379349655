import * as React from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ActionItem, ActionItemProps, ActionItemType } from '../ActionItem';

export const UserActionItem: React.FC<ActionItemProps> = (props) => {
  const { id, flowId, type } = props;
  const toast = useToast();

  if (type === ActionItemType.WORKFLOW) {
    return (
      <Link key={id} to={`/user/flows/${flowId}`}>
        <ActionItem key={props.id} {...props} />
      </Link>
    );
  }

  return (
    <Box
      key={id}
      onClick={() =>
        toast({
          status: 'info',
          description: 'Unable to navigate to task',
          duration: 3000,
          isClosable: true,
          position: 'top',
        })
      }
    >
      <ActionItem {...props} />
    </Box>
  );
};

import { useEffect } from 'react';
import hotkeys, { KeyHandler } from 'hotkeys-js';

interface Config<T extends string> {
  keys: T[];
  preventDefault?: true;
  action: (shortcut: T) => unknown;
  onUp?: (shortcut: T) => unknown;
}

/* eslint-disable react-hooks/exhaustive-deps */
export const useHotkey = <T extends string>(
  config: Config<T>,
  dependencies?: unknown[],
) => {
  useEffect(() => {
    const handle: KeyHandler = (event, { shortcut }) => {
      if (config.preventDefault) {
        event.preventDefault();
      }
      if (event.type === 'keydown') {
        config.action(shortcut as T);
      } else {
        config.onUp?.(shortcut as T);
      }
    };

    hotkeys(
      config.keys.join(),
      { splitKey: '.', keydown: true, keyup: true },
      handle,
    );

    return () => {
      hotkeys.unbind(config.keys.join(), handle);
    };
  }, dependencies ?? []);
};

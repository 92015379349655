import { Center, Flex, Spinner } from '@chakra-ui/react';

import { ChakraCenterProps, ChakraSpinnerProps } from '../../../types/Chakra';
import {
  ErrorDisplayFetchable,
  ErrorDisplayMany,
} from '../../../billing/ErrorDisplay';

type LoadingStateProps = {
  layoutProps?: Partial<ChakraCenterProps>;
  spinnerProps?: Partial<ChakraSpinnerProps>;
};

export const LoadingState = ({
  layoutProps,
  spinnerProps,
}: LoadingStateProps) => {
  return (
    <Flex
      gridColumnStart="1"
      gridColumnEnd="none"
      w="100%"
      h="100%"
      {...layoutProps}
    >
      <Center w="100%" h="100%">
        <Spinner
          thickness="4px"
          speed="0.65s"
          color="brand.blue"
          size="xl"
          data-testid="loadingStateSpinner"
          {...spinnerProps}
        />
      </Center>
    </Flex>
  );
};

export const LoadingStateQuery = (props: {
  queries?: ErrorDisplayFetchable[];
}) => {
  const hasError = !!props.queries?.find((q) => q.error);
  const isLoading = !!props.queries?.find((q) => q.isLoading);

  return hasError || isLoading ? (
    <Flex gridColumnStart="1" gridColumnEnd="none" w="100%" h="100%">
      <Center w="100%" h="100%">
        <ErrorDisplayMany queries={props.queries ?? []} />
        {!hasError && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="brand.blue"
            size="xl"
            data-testid="loadingStateSpinner"
          />
        )}
      </Center>
    </Flex>
  ) : null;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { NumberInput as ChakraInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Text, FormHelperText, } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useMemo } from 'react';
import { FormControl } from '../wrappers/FormControl';
export var NumberInput = function (_a) {
    var _b;
    var name = _a.name, placeholder = _a.placeholder, defaultValue = _a.defaultValue, onKeyDown = _a.onKeyDown, onFocus = _a.onFocus, children = _a.children, helper = _a.helper, description = _a.description, isAutoFocus = _a.isAutoFocus, isDisabled = _a.isDisabled, isReadOnly = _a.isReadOnly, _input = _a._input, max = _a.max, min = _a.min, useStepper = _a.useStepper, controlProps = __rest(_a, ["name", "placeholder", "defaultValue", "onKeyDown", "onFocus", "children", "helper", "description", "isAutoFocus", "isDisabled", "isReadOnly", "_input", "max", "min", "useStepper"]);
    var field = useController({
        name: name,
        rules: {
            min: min,
            max: max,
        },
    }).field;
    var _c = React.useState(function () {
        return Number.isNaN(field.value) ? '' : String(field.value);
    }), value = _c[0], setValue = _c[1];
    var lastValue = React.useRef((_b = field.value) !== null && _b !== void 0 ? _b : 0);
    React.useEffect(function () {
        try {
            var trimmed = "".concat(value).trim();
            if (trimmed === '' || trimmed === '-') {
                return;
            }
            var newValue = Number(value);
            if (Number.isNaN(newValue)) {
                field.onChange(field.value);
            }
            if (field.value !== newValue) {
                if (lastValue.current == field.value) {
                    if (max != null && newValue > max) {
                        return;
                    }
                    if (min != null && newValue < min) {
                        return;
                    }
                    field.onChange(newValue);
                }
                else {
                    // This _should_ handle react-hook-form's reset value case
                    setValue(String(field.value));
                }
            }
        }
        finally {
            lastValue.current = field.value;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value, value]);
    var variantProps = useMemo(function () {
        if (controlProps.variant === 'small') {
            return {
                size: 'sm',
            };
        }
        return {};
    }, [controlProps.variant]);
    var input = (_jsxs(ChakraInput, __assign({ autoFocus: isAutoFocus, onFocus: onFocus, onKeyDown: onKeyDown, name: field.name, isDisabled: isDisabled, placeholder: placeholder, isReadOnly: isReadOnly, defaultValue: defaultValue, variant: "outline", min: min, max: max, autoComplete: "off", value: value, onBlur: field.onBlur, onChange: setValue }, variantProps, _input, { children: [_jsx(NumberInputField, { name: field.name, ref: field.ref }, void 0), useStepper && (_jsxs(NumberInputStepper, { children: [_jsx(NumberIncrementStepper, {}, void 0), _jsx(NumberDecrementStepper, {}, void 0)] }, void 0))] }), void 0));
    return (_jsxs(FormControl, __assign({ name: name }, controlProps, { children: [_jsx(Text, { children: description }, void 0), input, helper && (_jsx(FormHelperText, __assign({ fontSize: "sm", fontStyle: "italic" }, { children: helper }), void 0)), children] }), void 0));
};

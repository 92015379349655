import * as React from 'react';
import { Stack, Text, Heading } from '@chakra-ui/react';

type CheckEmailSectionProps = {
  email: string;
};

export const CheckEmailSection: React.FC<CheckEmailSectionProps> = ({
  email,
}) => {
  if (!email) {
    return <Text>No email has been provided. Please try again.</Text>;
  }

  return (
    <Stack align="center" textAlign="center" spacing={2}>
      <Heading size="md">Awesome 🎉</Heading>
      <Text>An email has been sent to your inbox.</Text>
      <Text>Follow the link in your email to continue.</Text>
    </Stack>
  );
};

import {
  Button,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  Center,
} from '@chakra-ui/react';
import { useQueryInvalidator } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useAPI } from '../../../services/API';
import { onError, track } from 'libs';
import { useOTP } from './useOTP';

export const VerifySMSForm = ({
  secondFactorId,
  number,
  onBack,
  onComplete,
  verifyMFAToken,
}: {
  secondFactorId: string;
  number: string;
  onBack?: () => unknown;
  onComplete: () => unknown;
  verifyMFAToken?: string;
}) => {
  const api = useAPI();

  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const sendVerificationCode = useCallback(async () => {
    if (!isSendingCode) {
      setIsSendingCode(true);
      await api
        .identity()
        .mfa.requestSMSCode({ secondFactorId, verifyMFAToken })
        .mapFailure(onError);
      setCode('');
      setIsSendingCode(false);
    }
  }, [api, isSendingCode, secondFactorId, verifyMFAToken]);

  useEffect(() => {
    sendVerificationCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  const invalidate = useQueryInvalidator();
  const submit = async () => {
    setIsVerifyingCode(true);
    if (!isVerifyingCode) {
      await api
        .identity()
        .mfa.verifySMSNumber({ code, secondFactorId, verifyMFAToken })
        .tap(() => {
          track.event('Verified MFA');
          invalidate(['MFA_SOURCES']);
          onComplete();
        }, onError);
    }
    setIsVerifyingCode(false);
  };

  const codeIsValid = code.length === 4;

  const { restartOTP } = useOTP({ gotOTP: setCode });

  return (
    <>
      <Text>Enter the 4 character verification code sent to {number}</Text>

      <Center>
        <FormControl maxW="230px">
          <FormLabel fontSize={14} color="gray.600">
            Verification Code
          </FormLabel>
          <Input
            onChange={(e) => {
              if (
                e.target.value.length <= 4 &&
                e.target.value.match(/^[a-zA-Z0-9]*$/)
              ) {
                setCode(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              if (!codeIsValid || isVerifyingCode || isSendingCode) {
                return;
              }
              if (e.key === 'Enter') {
                e.preventDefault();
                submit();
              }
            }}
            autoFocus
            value={code}
            autoComplete="one-time-code"
            isDisabled={isVerifyingCode}
            size="lg"
            textTransform="uppercase"
            pattern="^[a-zA-Z0-9]{4}$"
          />
        </FormControl>
      </Center>

      <Center>
        <Stack maxW="230px">
          <ButtonGroup>
            <Button
              w="240px"
              isDisabled={!codeIsValid || isVerifyingCode || isSendingCode}
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              Verify
            </Button>

            {onBack && (
              <Button variant="outline" onClick={onBack}>
                Back
              </Button>
            )}
          </ButtonGroup>

          <Text
            as="button"
            disabled={isVerifyingCode || isSendingCode}
            onClick={() => {
              restartOTP();
              sendVerificationCode();
            }}
            textAlign="left"
            fontSize="sm"
            _hover={{
              textDecoration: 'underline',
              color: 'brand.blue',
            }}
            color="brand.dark-gray"
            variant="outline"
            fontWeight="bold"
          >
            Resend Code
          </Text>
        </Stack>
      </Center>
    </>
  );
};

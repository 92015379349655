"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextValidation = exports.IsTFN = exports.IsLetters = exports.IsDate = exports.IsACN = exports.IsPhone = exports.IsEmail = exports.IsBSB = exports.IsNumeric = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const contactNumber_1 = require("../../../utils/contactNumber");
const ResponseBase_1 = require("../../../ResponseBase");
const ACN_1 = require("../../../utils/ACN");
const BSB_1 = require("../../../utils/BSB");
const Email_1 = require("../../../utils/email/Email");
const AbsoluteDate_1 = require("../../../utils/time/AbsoluteDate");
const TFN_1 = require("../../../utils/TFN");
class IsNumeric extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        if (typeof value !== 'string') {
            return 'Must be a string';
        }
        const strValue = value.trim();
        if (strValue.length === 0) {
            return 'Must not be empty';
        }
        const firstChar = strValue[0];
        if (firstChar !== '-' && /\d/.test(firstChar) === false) {
            return 'Must start with a number or dash';
        }
        if (/\d/.test(strValue) === false) {
            return 'Must be a number';
        }
        const regex = /^(-|\d|\s|,|\.)+$$/;
        if (!regex.test(strValue)) {
            return 'Must be a number';
        }
        return undefined;
    }
}
IsNumeric.type = 'numeric';
IsNumeric.label = 'Number';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsNumeric.prototype, "type", void 0);
exports.IsNumeric = IsNumeric;
class IsBSB extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        return BSB_1.BSB.validationMessage(value);
    }
}
IsBSB.type = 'bsb';
IsBSB.label = 'BSB';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsBSB.prototype, "type", void 0);
exports.IsBSB = IsBSB;
class IsEmail extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        return Email_1.Email.validationMessage(value);
    }
}
IsEmail.type = 'email';
IsEmail.label = 'Email';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsEmail.prototype, "type", void 0);
exports.IsEmail = IsEmail;
class IsPhone extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        if (typeof value !== 'string' || value.length === 0) {
            return 'Must be a string';
        }
        return contactNumber_1.ContactNumber.validationMessage(value);
    }
}
IsPhone.type = 'phone';
IsPhone.label = 'Phone';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsPhone.prototype, "type", void 0);
exports.IsPhone = IsPhone;
class IsACN extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        return ACN_1.ACN.validationMessage(value);
    }
}
IsACN.type = 'acn';
IsACN.label = 'ACN';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsACN.prototype, "type", void 0);
exports.IsACN = IsACN;
class IsDate extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        return AbsoluteDate_1.AbsoluteDate.validationMessageFromUserInput(value);
    }
}
IsDate.type = 'date';
IsDate.label = 'Date';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsDate.prototype, "type", void 0);
exports.IsDate = IsDate;
class IsLetters extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        if (typeof value !== 'string') {
            return 'Must be a string';
        }
        const strValue = value.trim();
        if (strValue.length === 0) {
            return 'Must not be empty';
        }
        if (strValue.match(/[^a-zA-Z\s]/)) {
            return 'Must only contain letters';
        }
        return undefined;
    }
}
IsLetters.type = 'letters';
IsLetters.label = 'Letters';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsLetters.prototype, "type", void 0);
exports.IsLetters = IsLetters;
class IsTFN extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        if (typeof value !== 'string') {
            return 'Must be a string';
        }
        if (value.length === 0) {
            return 'Must not be empty';
        }
        return TFN_1.AnyTFN.validationMessage(value);
    }
}
IsTFN.type = 'tfn';
IsTFN.label = 'TFN';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], IsTFN.prototype, "type", void 0);
exports.IsTFN = IsTFN;
class TextValidation extends designed_1.Entity.Union.define({
    entries: [IsEmail, IsDate, IsLetters, IsNumeric, IsBSB, IsACN, IsTFN],
    key: 'type',
}) {
    validationMessage(value) {
        return this.value.validationMessage(value);
    }
}
exports.TextValidation = TextValidation;
_a = TextValidation;
TextValidation.options = Object.values(_a.classes).map((klass) => {
    return {
        name: klass.label,
        value: klass.type,
    };
});

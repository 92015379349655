import { Box, Button, Center } from '@chakra-ui/react';
import { page } from '@zap-onboard/web-components';
import { useAPI } from '../../../services/API';
import { SetupMFAContent } from './MFASetupFlow';

export const MFASetupPage: React.FC = () => {
  const api = useAPI();
  return (
    <page.focused.Container>
      <page.focused.Header>
        Setup Multi Factor Authentication for your account to continue
      </page.focused.Header>
      <page.focused.Content>
        <Center>
          <Box maxW={{ base: undefined, lg: '80%' }}>
            <SetupMFAContent />
          </Box>
        </Center>
      </page.focused.Content>
      <page.focused.Footer>
        <Button variant="ghost" onClick={() => api.identity().logout()}>
          Logout
        </Button>
      </page.focused.Footer>
    </page.focused.Container>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFlows = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const allTaskStates_generated_1 = require("../../allTaskStates.generated");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const FlowStatus_1 = require("../FlowStatus");
const task_1 = require("../task");
const TaskAssignmentPolicyResponse_1 = require("../task/TaskAssignmentPolicyResponse");
const TaskAssignmentRequest_1 = require("../task/TaskAssignmentRequest");
const AssignmentQuery_1 = require("./AssignmentQuery");
const FlowTaskListing_1 = require("./FlowTaskListing");
var GetFlows;
(function (GetFlows) {
    const taskStateToTag = {
        BANK_ACCOUNT_SUBMITTED: 'Submitted Bank Account',
        BANK_ACCOUNT_UNSUBMITTED: 'Waiting for Bank Account',
        CLIENT_VERIFICATION_SUBMITTED: 'Submitted',
        CLIENT_VERIFICATION_APPROVED: 'Approved',
        CLIENT_VERIFICATION_REJECTED: 'Rejected',
        CLIENT_VERIFICATION_UNSUBMITTED: 'Waiting to submit the client verification',
        PROOF_OF_IDENTITY_SUBMITTED: 'Submitted',
        PROOF_OF_IDENTITY_APPROVED: 'Approved',
        PROOF_OF_IDENTITY_REJECTED: 'Rejected',
        PROOF_OF_IDENTITY_UNSUBMITTED: 'Waiting to submit proof of identity',
        DEPUTY_LINK_EMPLOYEE_LINKED: 'Synced to Deputy',
        DEPUTY_LINK_EMPLOYEE_UNLINKED: 'Waiting to Sync to Deputy',
        ESIGN_SIGNED: 'Signed',
        ESIGN_UNSIGNED: 'Waiting for Signature',
        ESIGN_WAITING_FOR_DOCUMENT: 'Waiting for Document',
        FORM_SUBMITTED: 'Completed',
        FORM_UNSUBMITTED: 'Waiting for Completion',
        META_SKIPPED: 'Skipped',
        RAW_TEXT_ACCEPTED: 'Actioned',
        RAW_TEXT_UNACCEPTED: 'Waiting for Action',
        SUPER_CHOICE_SUBMITTED: 'Submitted Super Choice',
        SUPER_CHOICE_UNSUBMITTED: 'Waiting for Super Declaration',
        TFND_SUBMITTED: 'Submitted TFND',
        TFND_UNSUBMITTED: 'Waiting for TFND',
        XERO_SYNC_EMPLOYEE_SYNCED: 'Synced to Xero',
        XERO_SYNC_EMPLOYEE_UNSYNCED: 'Waiting to Sync to Xero',
        PROFILE_PENDING: 'Waiting for Action',
        PROFILE_COMPLETED: 'Updated',
        TEMPLATE_SIGNED: 'Signed',
        TEMPLATE_WAITING: 'Waiting for Additional Signatures',
        VISA_DETAILS_COMPLETED: 'Completed',
        VISA_DETAILS_SUBMITTED: 'Submitted',
        VISA_DETAILS_UNSUBMITTED: 'Waiting for Visa Details',
    };
    class Task extends ResponseBase_1.ResponseBase {
        getRequirements() {
            return this.assignments.filter((a) => a.getLevel() === 'REQUIRES_ACTION');
        }
        getTag() {
            return taskStateToTag[this.taskStateId] ?? 'Unknown';
        }
        getPolicy() {
            return TaskAssignmentPolicyResponse_1.TaskAssignmentPolicyResponse.makeDeduplicated(this.assignments);
        }
        getNoInputRequired() {
            return [
                FlowTaskListing_1.FlowTaskStatus.COMPLETE,
                FlowTaskListing_1.FlowTaskStatus.BUSINESS_SKIPPED,
            ].includes(this.status);
        }
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "taskId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Task.prototype, "lastStateChange", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsEnum)(task_1.TaskDesignType),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "taskDesignType", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsEnum)(FlowTaskListing_1.FlowTaskStatus),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "status", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(allTaskStates_generated_1.TaskStates),
        (0, tslib_1.__metadata)("design:type", Object)
    ], Task.prototype, "taskStateId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: TaskAssignmentRequest_1.TaskAssignedResponse }),
        (0, class_validator_1.IsInstance)(TaskAssignmentRequest_1.TaskAssignedResponse, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Task.prototype, "assignments", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Memoize.All(),
        (0, tslib_1.__metadata)("design:type", Function),
        (0, tslib_1.__metadata)("design:paramtypes", []),
        (0, tslib_1.__metadata)("design:returntype", void 0)
    ], Task.prototype, "getPolicy", null);
    GetFlows.Task = Task;
    const statusToTag = {
        IN_PROGRESS: 'In Progress',
        NOT_STARTED: 'Not Started',
        COMPLETE: 'Completed',
        BUSINESS_CANCELLED: 'Cancelled',
    };
    class Flow extends ResponseBase_1.ResponseBase {
        isComplete() {
            return this.completion().exact() >= 100;
        }
        getTag() {
            return statusToTag[this.getStatus()];
        }
        getLastStateChange() {
            return designed_1.Optional.of(this.tasksByLastChange()[0])
                .map((task) => task.lastStateChange)
                .orElse(undefined);
        }
        tasksByLastChange() {
            return [...this.tasks].sort((a, b) => b.lastStateChange.valueOf() - b.lastStateChange.valueOf());
        }
        getStatus() {
            if (this.skipped) {
                return FlowStatus_1.FlowStatus.BUSINESS_CANCELLED;
            }
            const completed = this.completion().exact();
            if (completed <= 0) {
                return FlowStatus_1.FlowStatus.NOT_STARTED;
            }
            if (completed >= 100) {
                return FlowStatus_1.FlowStatus.COMPLETE;
            }
            return FlowStatus_1.FlowStatus.IN_PROGRESS;
        }
        completion() {
            return utils_1.Percentage.of(this.tasks, this.tasks.filter((t) => t.getNoInputRequired()));
        }
        firstIncompleteTask() {
            return this.tasks.find((task) => [FlowTaskListing_1.FlowTaskStatus.NOT_STARTED, FlowTaskListing_1.FlowTaskStatus.IN_PROGRESS].includes(task.status));
        }
        getAdditivePolicy() {
            return TaskAssignmentPolicyResponse_1.TaskAssignmentPolicyResponse.makeDeduplicated(this.tasks.flatMap((t) => t.assignments));
        }
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
    ], Flow.prototype, "startedAt", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Flow.prototype, "archived", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "flowId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "flowDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "userId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "userName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Flow.prototype, "businessName", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Flow.prototype, "skipped", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Memoize.All(),
        (0, tslib_1.__metadata)("design:type", Function),
        (0, tslib_1.__metadata)("design:paramtypes", []),
        (0, tslib_1.__metadata)("design:returntype", void 0)
    ], Flow.prototype, "tasksByLastChange", null);
    (0, tslib_1.__decorate)([
        designed_1.Memoize.All(),
        (0, tslib_1.__metadata)("design:type", Function),
        (0, tslib_1.__metadata)("design:paramtypes", []),
        (0, tslib_1.__metadata)("design:returntype", void 0)
    ], Flow.prototype, "completion", null);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Task }),
        (0, class_validator_1.IsInstance)(Task, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Flow.prototype, "tasks", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Memoize.All(),
        (0, tslib_1.__metadata)("design:type", Function),
        (0, tslib_1.__metadata)("design:paramtypes", []),
        (0, tslib_1.__metadata)("design:returntype", void 0)
    ], Flow.prototype, "getAdditivePolicy", null);
    GetFlows.Flow = Flow;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Flow }),
        (0, class_validator_1.IsInstance)(Flow, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "flows", void 0);
    GetFlows.Response = Response;
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(AssignmentQuery_1.AssignmentQuery.Assignment),
        (0, tslib_1.__metadata)("design:type", AssignmentQuery_1.AssignmentQuery.Assignment)
    ], Request.prototype, "assignedAs", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(AssignmentQuery_1.AssignmentQuery.Requirement),
        (0, tslib_1.__metadata)("design:type", AssignmentQuery_1.AssignmentQuery.Requirement)
    ], Request.prototype, "requirements", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "businessId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "subjectMemberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], Request.prototype, "flowIsOpen", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Request.prototype, "flowDesignId", void 0);
    GetFlows.Request = Request;
})(GetFlows = exports.GetFlows || (exports.GetFlows = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanPrice = exports.priceNames = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
exports.priceNames = [
    'STRIPE_ADVISOR_PLAN_YEARLY_PRICE_ID',
    'STRIPE_ADVISOR_PLAN_MONTHLY_PRICE_ID',
    'STRIPE_BUSINESS_PLAN_YEARLY_PRICE_ID',
    'STRIPE_BUSINESS_PLAN_MONTHLY_PRICE_ID',
    'STRIPE_STANDARD_PRICE_ID',
    'STRIPE_ADVISOR_PLAN_PRICE_ID',
];
class PlanPrice extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanPrice.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanPrice.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], PlanPrice.prototype, "priceName", void 0);
exports.PlanPrice = PlanPrice;

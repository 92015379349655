var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-underscore-dangle */
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure, } from '@chakra-ui/react';
import { useRef, useState } from 'react';
export var Dialog = function (props) {
    var _a, _b;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var d = (_a = props.disclosure) !== null && _a !== void 0 ? _a : useDisclosure();
    var render = function (renderer, base) {
        if (typeof renderer === 'function') {
            return renderer(d);
        }
        if (typeof renderer === 'string') {
            return base(renderer);
        }
        return renderer;
    };
    var renderAction = function (action, state, setState, el) {
        if (!action) {
            return null;
        }
        return el(__assign(__assign({}, action), { run: function () { return __awaiter(void 0, void 0, void 0, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (state) {
                                return [2 /*return*/];
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            setState(true);
                            return [4 /*yield*/, action.run(d)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            setState(false);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); } }));
    };
    var _c = useState(false), confirming = _c[0], setConfirming = _c[1];
    var cancelRef = useRef(null);
    return (_jsxs(_Fragment, { children: [props.trigger &&
                render(props.trigger, function (value) { return (_jsx(Button, __assign({}, props._trigger, { onClick: d.onOpen }, { children: value }), void 0)); }), _jsxs(AlertDialog, __assign({ motionPreset: "slideInBottom", leastDestructiveRef: cancelRef, onClose: d.onClose, isOpen: d.isOpen, isCentered: true }, props._dialog, { children: [_jsx(AlertDialogOverlay, {}, void 0), _jsxs(AlertDialogContent, __assign({}, props._content, { children: [_jsx(AlertDialogHeader, __assign({}, props._header, { children: props.header &&
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    render(props.header, function (value) { return _jsx(_Fragment, { children: value !== null && value !== void 0 ? value : 'Confirm' }, void 0); }) }), void 0), _jsx(AlertDialogCloseButton, {}, void 0), _jsx(AlertDialogBody, __assign({}, props._body, { children: props.children && render(props.children, function (value) { return _jsx(_Fragment, { children: value }, void 0); }) }), void 0), _jsxs(AlertDialogFooter, __assign({}, props._footer, { children: [render((_b = props.cancel) !== null && _b !== void 0 ? _b : 'Cancel', function (value) { return (_jsx(Button, __assign({ ref: cancelRef, variant: "outline", onClick: d.onClose }, props._cancel, { children: value }), void 0)); }), renderAction(props.confirm, confirming, setConfirming, function (action) {
                                        var _a, _b, _c, _d;
                                        return (_jsx(Button, __assign({ isLoading: (_a = action.isRunning) !== null && _a !== void 0 ? _a : confirming, isDisabled: (_b = action.isRunning) !== null && _b !== void 0 ? _b : confirming, colorScheme: (_c = action.colorScheme) !== null && _c !== void 0 ? _c : 'red', ml: 3, onClick: action.run }, action.props, { children: (_d = action.label) !== null && _d !== void 0 ? _d : 'Confirm' }), void 0));
                                    })] }), void 0)] }), void 0)] }), void 0)] }, void 0));
};

import * as React from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { Draggable } from 'react-beautiful-dnd';
import { TaskDesignType } from '@zap-onboard/api-client';
import { taskDesignTypeToIcon } from '../AddTaskButton';
import { TaskItemStatus } from '../SideBar';

export type TaskItemProps = {
  id: string;
  index: number;
  isReadOnly?: boolean;
  focused?: boolean;
  name: string;
  status: TaskItemStatus;
  onClick: () => void;
  renderActions?: () => React.ReactElement | null;
  subtitle?: string;
  type?: TaskDesignType;
};

export const TaskItem: React.FC<TaskItemProps> = (props) => (
  <Layout {...props}>
    <Flex justify="space-between" align="center" w="100%">
      <Flex align="center" gridGap={2}>
        {props.type && taskDesignTypeToIcon[props.type]}
        <Title {...props} />
      </Flex>
      <Actions {...props} />
    </Flex>
  </Layout>
);

const Layout: React.FC<{
  id: string;
  index: number;
  isReadOnly?: boolean;
  focused?: boolean;
  name: string;
  onClick: () => void;
}> = ({ id, index, isReadOnly, focused, name, onClick, children }) => (
  <Draggable draggableId={id} index={index} isDragDisabled={isReadOnly ?? true}>
    {(provided, snapshot) => (
      <Box w="100%">
        <Flex
          layerStyle="base"
          bg={focused ? 'brand.light-gray' : 'inherit'}
          data-testid={`taskLink-${name}`}
          gridGap={2}
          px={4}
          role="button"
          align="center"
          w="100%"
          minHeight="48px"
          _hover={{ bg: 'brand.light-gray' }}
          onClick={onClick}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          shadow={snapshot.isDragging ? 'sm' : 'none'}
        >
          {children}
        </Flex>
      </Box>
    )}
  </Draggable>
);

const Title: React.FC<{
  name: string;
  subtitle?: string;
  focused?: boolean;
  renderActions?: () => React.ReactElement | null;
}> = ({ name, subtitle, focused, renderActions }) => {
  const shortenWidth = renderActions && renderActions();

  return (
    <Stack
      maxW={{
        base: `${shortenWidth ? '148px' : '180px'}`,
        lg: '280px',
      }}
      spacing={0}
    >
      <Text fontSize="sm" isTruncated>
        {name}
      </Text>
      {subtitle && (
        <Text
          m={0}
          fontSize="xs"
          isTruncated
          color={focused ? 'brand.black' : 'brand.dark-gray'}
        >
          {subtitle}
        </Text>
      )}
    </Stack>
  );
};

const Actions: React.FC<{
  renderActions?: () => React.ReactElement | null;
}> = ({ renderActions }) => {
  if (renderActions) {
    return renderActions();
  }
  return null;
};

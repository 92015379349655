import {
  Collapse,
  Flex,
  Stack,
  Text,
  Icon as ChakraIcon,
  Spacer,
} from '@chakra-ui/react';
import * as React from 'react';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BaseIconProps } from '../Icons/BaseIcon';

type DesktopMenuItemProps = {
  route: string;
  label: string;
  Icon: React.FC<BaseIconProps>;
  subMenu?: {
    route: string;
    label: string;
  }[];
};

const colorSettings = {
  primary: {
    backgroundColor: 'transparent',
    textColor: 'white',
  },
  secondary: {
    backgroundColor: 'rgba(0,0,0, 0.2)',
    textColor: 'white',
  },
};

export const DesktopMenuItem: React.FC<DesktopMenuItemProps> = ({
  label,
  route: topRoute,
  Icon,
  subMenu,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const [isHoveredMenuItem, setIsHoveredMenuItem] =
    React.useState<boolean>(false);
  const isSelectedMenuItem = pathname.startsWith(topRoute);

  const colorType =
    isSelectedMenuItem || isHoveredMenuItem ? 'secondary' : 'primary';
  const menuItemBg = colorSettings[colorType].backgroundColor;
  const textColor = colorSettings[colorType].textColor;

  const currentRoute = React.useMemo(() => {
    const allRoutes = [...(subMenu?.map((m) => m.route) ?? []), topRoute];
    if (!pathname.startsWith(topRoute)) {
      return null;
    }
    return allRoutes.reduce((longest, curr) => {
      if (pathname.startsWith(curr) && curr.length > longest.length) {
        return curr;
      }
      return longest;
    }, topRoute);
  }, [subMenu, topRoute, pathname]);

  return (
    <Flex
      onMouseEnter={() => setIsHoveredMenuItem(true)}
      onMouseLeave={() => setIsHoveredMenuItem(false)}
      borderRadius="3px 0 0 3px"
      userSelect="none"
      onClick={() => history.push(subMenu ? subMenu[0].route : topRoute)}
      cursor="pointer"
      bg={menuItemBg}
      w="100%"
      overflow="visible"
      shadow={isSelectedMenuItem ? 'md' : undefined}
    >
      <Stack w="100%" spacing={0}>
        <Flex py={2} px={4}>
          <Flex justify="center" align="center" mr={6}>
            <Icon iconColor={textColor} width="12px" height="12px" />
          </Flex>
          <Text color={textColor} fontSize={{ base: 'sm', lg: 'sm' }}>
            {label}
          </Text>
          <Spacer flexGrow={1} />
          {subMenu ? (
            <ChakraIcon
              as={MdOutlineArrowDropDown}
              color="white"
              transform={
                isHoveredMenuItem || isSelectedMenuItem
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)'
              }
              transition="transform 0.2s"
            />
          ) : null}
        </Flex>

        {(subMenu?.length ?? 0) > 0 && (
          <Collapse in={isSelectedMenuItem || isHoveredMenuItem}>
            <Stack spacing={0}>
              {subMenu?.map(({ label, route }) => {
                return (
                  <Flex
                    key={label}
                    py={2}
                    px={4}
                    onClick={(e) => e.stopPropagation()}
                    as={Link}
                    to={route}
                    pl="56px"
                    color={route === currentRoute ? textColor : 'blue.200'}
                    bg={route === currentRoute ? 'rgba(0, 0, 0, 0.05)' : 'none'}
                    _hover={{ color: textColor, bg: 'rgba(0, 0, 0, 0.05)' }}
                  >
                    <Text fontSize="xs">{label}</Text>
                  </Flex>
                );
              })}
            </Stack>
          </Collapse>
        )}
      </Stack>
    </Flex>
  );
};

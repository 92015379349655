"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenPaginator = exports.TokenMetadata = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const errors_1 = require("@zap-onboard/errors");
class TokenMetadata extends designed_1.Entity.Base {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], TokenMetadata.prototype, "token", void 0);
exports.TokenMetadata = TokenMetadata;
class TokenPaginator {
    constructor(config) {
        this.config = config;
        this.itemClass = this.config.itemClass;
        this.makeRequest = this.config.makeRequest;
        this.contentsKey = this.config.contentsKey;
        this.parsedContents = [];
        this.nextToken = null;
        this.parseResponse = (response) => {
            const responses = response[this.contentsKey].map(this.itemClass.create.bind(this.itemClass));
            this.parsedContents = [
                ...this.parsedContents,
                ...responses,
            ];
            this.nextToken = TokenMetadata.create(response.pagination).token;
        };
        this.contents = () => this.parsedContents;
        this.loading = false;
    }
    static async from(config) {
        const paginator = new TokenPaginator(config);
        paginator.parseResponse(await config.makeRequest());
        return paginator;
    }
    async nextPage() {
        if (this.loading) {
            return designed_1.Result.fail(errors_1.errs.UnexpectedError.create('Cannot fetch next page of results until loading is finished'));
        }
        if (!this.nextToken) {
            return designed_1.Result.fail(errors_1.errs.UnexpectedError.create('No next page. Check `Paginator.hasNextPage()` before trying to fetch early'));
        }
        this.loading = true;
        const response = await designed_1.Result.fromPromise(this.makeRequest(this.nextToken));
        this.loading = false;
        response.map(this.parseResponse);
        return response.map(() => this);
    }
    hasNextPage() {
        return this.nextToken != null;
    }
    isLoading() {
        return this.loading;
    }
}
exports.TokenPaginator = TokenPaginator;

import { Button, Stack, Flex } from '@chakra-ui/react';

import { withFileUpload } from '../FileUploader';
import { Spinner } from '../Spinner';
import { FilePreviewModal } from '../FilePreviewModal';

export const DocumentUpload = withFileUpload(
  ({
    file,
    isUploading,
    isReadOnly,
    direction = 'row',
    oustRemoveAction,
    onClick,
    clearFile,
  }) => {
    if (!file) {
      return isUploading ? (
        <Spinner />
      ) : (
        <Flex>
          <Button disabled={isUploading || isReadOnly} onClick={onClick}>
            Upload document
          </Button>
        </Flex>
      );
    }

    return (
      <Stack direction={direction} spacing={2}>
        {file && <FilePreviewModal file={file} trigger="View document" />}

        {!oustRemoveAction && file && (
          // Multiupload has own remove actions
          <Button
            variant="outline"
            onClick={() => {
              clearFile();
            }}
            disabled={isUploading || isReadOnly}
          >
            Remove document
          </Button>
        )}
      </Stack>
    );
  },
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloudPayrollClient = exports.CreateCloudPayrollEmployeeRequest = exports.CloudPayrollCompleteOAuthRequest = exports.CloudPayrollBeginOAuthResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const handler_1 = require("../utils/handler");
class CloudPayrollBeginOAuthResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CloudPayrollBeginOAuthResponse.prototype, "authorizationPageUrl", void 0);
exports.CloudPayrollBeginOAuthResponse = CloudPayrollBeginOAuthResponse;
class CloudPayrollCompleteOAuthRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CloudPayrollCompleteOAuthRequest.prototype, "authCode", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CloudPayrollCompleteOAuthRequest.prototype, "authIntentionId", void 0);
exports.CloudPayrollCompleteOAuthRequest = CloudPayrollCompleteOAuthRequest;
class CreateCloudPayrollEmployeeRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateCloudPayrollEmployeeRequest.prototype, "memberId", void 0);
exports.CreateCloudPayrollEmployeeRequest = CreateCloudPayrollEmployeeRequest;
class CloudPayrollClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.beginOAuthAuthentication = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/cloud-payroll/account/oauth/authorize/begin')
            .get((d) => CloudPayrollBeginOAuthResponse.create(d)));
        this.completeOAuthAuthentication = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/cloud-payroll/account/oauth/authorize/complete')
            .bodyJSON(CloudPayrollCompleteOAuthRequest.create(args))
            .post());
        this.deleteOAuthAuthorization = (0, handler_1.handler)(this, async () => this.http.request().to(`/cloud-payroll/account/oauth/authorize`).delete());
        this.createCloudPayrollEmployee = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/cloud-payroll/employee')
            .bodyJSON(CreateCloudPayrollEmployeeRequest.create(args))
            .post());
    }
}
exports.CloudPayrollClient = CloudPayrollClient;

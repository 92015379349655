import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { SetupBusinessForm } from './SetupBusinessForm';

type SetupBusinessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SetupBusinessModal: React.FC<SetupBusinessModalProps> = ({
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Business registration</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text mb={3}>Add a new business to your profile</Text>
          <SetupBusinessForm
            afterSetup={() => {
              history.push('/');
              onClose();
            }}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

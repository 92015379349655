import * as React from 'react';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { FlowStatus } from '@zap-onboard/api-client';
import { useMyFlows } from '../../hooks';

import {
  SimpleTile,
  TileGroupLayout,
  Status,
  LoadingState,
} from '../../components';
import { ErrorDisplay } from '../../billing/ErrorDisplay';
import { auth } from '../../auth';

type Props = {
  context: 'business' | 'user';
  heading?: string | null;
};

export const flowStatusMap = {
  [FlowStatus.COMPLETE]: 'Complete',
  [FlowStatus.BUSINESS_CANCELLED]: 'Cancelled',
  [FlowStatus.NOT_STARTED]: 'Not started',
  [FlowStatus.IN_PROGRESS]: 'In progress',
};

export const WorkflowsToAction: React.FC<Props> = ({
  context,
  heading = 'Workflows to complete',
}) => {
  const manager = auth.useCurrentBusinessManager();

  const { data, isLoading, error } = useMyFlows({
    requirements: 'ACTION_REQUIRED',
    businessId: manager?.businessId,
    assignedAs: manager ? 'ADMIN' : 'SUBJECT',
  });

  if (isLoading) {
    return <LoadingState />;
  }

  if (data?.flows.length === 0 || context !== 'user' || manager) {
    return null;
  }

  return (
    <Stack spacing={2} align="center" w="100%">
      {heading && (
        <Heading size="sm" textAlign="center">
          {heading}
        </Heading>
      )}

      {error && <ErrorDisplay error={error} />}
      <TileGroupLayout justify="center">
        {data?.flows.map((flow) => {
          const userType = manager ? 'business' : 'user';
          const linkToOpenFlow = `/${userType}/flows/${flow.flowId}`;

          return (
            <SimpleTile key={flow.flowId} as={Link} to={linkToOpenFlow}>
              <Text>{flow.name}</Text>
              <Status
                type="flowStatus"
                status={flow.getStatus()}
                label={flow.getTag()}
              />
            </SimpleTile>
          );
        })}
      </TileGroupLayout>
    </Stack>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.solidifyConfig = void 0;
function solidifyConfig(config) {
    return Object.freeze({ ...config, ...defaults });
}
exports.solidifyConfig = solidifyConfig;
const defaults = {
    timeoutInMS: 30000,
};

import { onError } from '../../../../libs/errorLib';
import { useHandler } from '../../../useHandlerMutation';

export function useRevokeAccess({
  memberId,
  afterAction,
}: {
  memberId: string;
  afterAction?: () => unknown;
}) {
  const [revokeAccess, isLoading] = useHandler(
    (api) => api.membership().revokeAccessToMember,
    {
      invalidates: () => [['MEMBER', memberId], ['MEMBER_SEARCH']],
      always: {
        onError,
        onSuccess: async () => {
          if (afterAction) {
            await afterAction();
          }
        },
      },
    },
  );

  return {
    revokeAccess,
    isLoading,
  };
}

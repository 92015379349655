import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { CreateFormTaskDesignFormData } from './CreateTaskDesignFormData';

type PasteFormModalProps = {
  isOpen: boolean;
  onClose: () => unknown;
  onPaste: (parsedForm: {
    name: string;
    schema: CreateFormTaskDesignFormData['schema'];
  }) => void;
};

export const PasteCopiedFormTaskModal: React.FC<PasteFormModalProps> = ({
  isOpen,
  onClose,
  onPaste,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [form, setForm] = React.useState<
    { name: string; schema: CreateFormTaskDesignFormData['schema'] } | undefined
  >(undefined);

  const readClipboard = React.useCallback(async () => {
    try {
      const encodedString = await navigator.clipboard.readText();
      if (encodedString) {
        const decodedString = window.atob(encodedString);
        const parsedForm = JSON.parse(decodeURI(decodedString));
        setForm(parsedForm);
      }
    } catch (e) {
      return;
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    if (isOpen) {
      readClipboard();
    }
  }, [isOpen, readClipboard]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Paste form task</ModalHeader>
        <ModalCloseButton />
        {!isLoading && (
          <ModalBody p={{ base: 0, md: 5 }} pb={6}>
            {form ? (
              <Text>
                Paste form task &quot;{form.name}&quot; into this workflow?
              </Text>
            ) : (
              <Text>
                Nothing to paste. An existing form task can be pasted into this
                workflow. Click on the form task you wish to copy and click the
                &quot;Copy&quot; button
              </Text>
            )}
          </ModalBody>
        )}
        <ModalFooter>
          <Flex gridGap={3}>
            <Button
              disabled={!form}
              onClick={() => {
                if (form) {
                  onPaste(form);
                  onClose();
                }
              }}
            >
              Paste
            </Button>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

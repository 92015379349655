"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityValidationError = exports.TemplateValidation = exports.MissingScopes = exports.NotImplemented = exports.UnexpectedError = exports.AlreadyExistsError = exports.NotFoundError = exports.AuthorizationError = exports.NoAuthorizationHeaderError = exports.MFARequiredError = exports.InvalidSessionTokenError = exports.InvalidTokenError = exports.AuthenticationError = exports.VersionMismatchError = exports.ValidationError = exports.SerializationError = exports.TimeoutError = exports.RateLimitError = exports.ClientError = exports.PublicError = void 0;
const DomainError_1 = require("../DomainError");
/**
 * Represents errors that are visible to consumers of API's
 */
class PublicError extends DomainError_1.DomainError {
    constructor() {
        super(...arguments);
        this.statusCode = 500;
    }
    get apiCode() {
        return this.constructor.apiCode;
    }
    toAPI() {
        return {
            error: {
                message: this.message,
                code: this.apiCode,
            },
        };
    }
}
exports.PublicError = PublicError;
class ClientError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 400;
    }
}
exports.ClientError = ClientError;
ClientError.apiCode = 'CLIENT';
class RateLimitError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 429;
        this.message = 'Too many requests. Try again later.';
    }
}
exports.RateLimitError = RateLimitError;
RateLimitError.apiCode = 'RATE_LIMIT';
class TimeoutError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 400;
    }
}
exports.TimeoutError = TimeoutError;
TimeoutError.apiCode = 'TIMEOUT';
class SerializationError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 400;
    }
}
exports.SerializationError = SerializationError;
SerializationError.apiCode = 'SERIALIZATION';
class ValidationError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 400;
    }
}
exports.ValidationError = ValidationError;
ValidationError.apiCode = 'VALIDATION';
class VersionMismatchError extends ValidationError {
    constructor() {
        super(...arguments);
        this.statusCode = 400;
    }
}
exports.VersionMismatchError = VersionMismatchError;
VersionMismatchError.apiCode = 'VERSION_MISMATCH';
class AuthenticationError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 401;
    }
}
exports.AuthenticationError = AuthenticationError;
AuthenticationError.apiCode = 'AUTHENTICATION';
class InvalidTokenError extends AuthenticationError {
    constructor() {
        super(...arguments);
        this.statusCode = 401;
    }
}
exports.InvalidTokenError = InvalidTokenError;
InvalidTokenError.apiCode = 'INVALID_TOKEN';
class InvalidSessionTokenError extends InvalidTokenError {
    constructor() {
        super(...arguments);
        this.statusCode = 401;
    }
}
exports.InvalidSessionTokenError = InvalidSessionTokenError;
InvalidSessionTokenError.apiCode = 'INVALID_SESSION_TOKEN';
class MFARequiredError extends AuthenticationError {
}
exports.MFARequiredError = MFARequiredError;
MFARequiredError.apiCode = 'MFA_REQUIRED';
class NoAuthorizationHeaderError extends InvalidTokenError {
    constructor() {
        super(...arguments);
        this.statusCode = 401;
    }
}
exports.NoAuthorizationHeaderError = NoAuthorizationHeaderError;
NoAuthorizationHeaderError.apiCode = 'NO_AUTHORIZATION_HEADER';
class AuthorizationError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 401;
        this.message = "You don't have permission to view that";
    }
}
exports.AuthorizationError = AuthorizationError;
AuthorizationError.apiCode = 'AUTHORIZATION';
class NotFoundError extends ClientError {
    constructor() {
        super(...arguments);
        // NOTE: Never return a 404 due to cloudfront being hosted on same domain.
        this.statusCode = 400;
        this.message = 'Not Found';
    }
    static of(model) {
        return NotFoundError.create(`Could not find that ${model}`);
    }
}
exports.NotFoundError = NotFoundError;
NotFoundError.apiCode = 'NOT_FOUND';
class AlreadyExistsError extends ClientError {
    constructor() {
        super(...arguments);
        this.statusCode = 400;
        this.message = 'That already exists';
    }
}
exports.AlreadyExistsError = AlreadyExistsError;
AlreadyExistsError.apiCode = 'ALREADY_EXISTS';
class UnexpectedError extends PublicError {
    constructor() {
        super(...arguments);
        this.statusCode = 500;
        this.message = 'Looks like something went wrong with your request';
    }
}
exports.UnexpectedError = UnexpectedError;
UnexpectedError.apiCode = 'SERVER';
class NotImplemented extends UnexpectedError {
    constructor() {
        super(...arguments);
        this.message = "That feature hasn't been deployed yet";
    }
}
exports.NotImplemented = NotImplemented;
NotImplemented.apiCode = 'NOT_IMPLEMENTED';
class MissingScopes extends ClientError {
    constructor() {
        super(...arguments);
        this.message = 'You are missing required scopes for that API';
    }
}
exports.MissingScopes = MissingScopes;
MissingScopes.apiCode = 'MISSING_SCOPES';
class TemplateValidation extends ValidationError {
    constructor() {
        super(...arguments);
        this.message = 'That submission was invalid';
    }
}
exports.TemplateValidation = TemplateValidation;
TemplateValidation.apiCode = 'TEMPLATE_VALIDATION';
class EntityValidationError extends ValidationError {
    constructor() {
        super(...arguments);
        this.causes = [];
        this.message = 'That request was invalid';
    }
    addCause(error) {
        this.causes.push(error);
    }
    /**
     * TODO: Make this much friendlier
     */
    static fromClassValidatorErrors(errors, path, domainError = EntityValidationError.default({
        details: { cause: errors.map((err) => err.toString()).join(', ') },
    })) {
        try {
            errors.forEach((err) => {
                if (err.children?.length) {
                    this.fromClassValidatorErrors(err.children, err.property, domainError);
                }
                if (domainError) {
                    domainError.addCause({
                        path: path ? `${path}.${err.property}` : err.property,
                        issue: Object.values(err.constraints ?? {}).join(', '),
                    });
                }
            });
            return domainError;
        }
        catch (e) {
            throw EntityValidationError.create('Validation error parsing failed', {
                previousError: e,
                details: { originalErrors: errors },
            });
        }
    }
}
exports.EntityValidationError = EntityValidationError;
EntityValidationError.apiCode = 'ENTITY_VALIDATION';

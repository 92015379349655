import { auth } from '../../../../auth';
import { onError } from '../../../../libs/errorLib';
import { useAPI } from '../../../../services/API';

export const useRefreshUserProfile = () => {
  const api = useAPI();

  const refreshUserProfile = async () =>
    api.identity().myProfile().map(auth.setProfile, onError);

  return { refreshUserProfile };
};

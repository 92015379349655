import React from 'react';
import {
  useVisaChecksForMember,
  useVisaConfig,
  useVisaDetailsForMember,
} from '../../hooks';
import { Spinner } from '../Spinner';
import { VisaCheckForm } from './VisaCheckForm';
import { VisaConfigForm } from './VisaConfigForm';
import { VisaHistory } from './VisaHistory/VisaHistory';
import { VisaProductSelector } from './VisaProductSelector';

type Props = {
  memberId: string;
  onClose: () => unknown;
};
export const VisaCheckWizard: React.FC<Props> = ({ onClose, memberId }) => {
  const [showVisaCheckForm, setShowVisaCheckForm] = React.useState(false);
  const { visaChecksForMember } = useVisaChecksForMember(memberId);
  const { visaConfig, isVisaConfigLoading } = useVisaConfig();
  const { visaDetailsForMember, isVisaDetailsForMemberLoading } =
    useVisaDetailsForMember(memberId);

  if (isVisaConfigLoading) {
    return <Spinner />;
  }

  if (!visaConfig) {
    return <VisaConfigForm onClose={onClose} memberId={memberId} />;
  }

  if (isVisaDetailsForMemberLoading) {
    return <Spinner />;
  }

  if (showVisaCheckForm || !visaChecksForMember?.length) {
    if (visaConfig.paidVisaCheckBalance < 1) {
      return <VisaProductSelector onClose={onClose} />;
    }
    return (
      <VisaCheckForm
        visaDetailsForMember={visaDetailsForMember}
        memberId={memberId}
        paidVisaCheckBalance={visaConfig.paidVisaCheckBalance}
        setShowVisaCheckForm={setShowVisaCheckForm}
      />
    );
  }

  return (
    <VisaHistory
      visaChecks={visaChecksForMember.sort(
        (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
      )}
      setShowVisaCheckForm={setShowVisaCheckForm}
    />
  );
};

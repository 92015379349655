/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import {
  Box,
  Button,
  Center,
  Spacer,
  Stack,
  Image,
  Text,
  HStack,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdFileUpload } from 'react-icons/md';
import { inputs } from '@zap-onboard/web-components';
import { FaIdCard } from 'react-icons/fa';

import {
  FileClaim,
  FileUploadFor,
  FileUploadTargetType,
  cert,
} from '@zap-onboard/api-client';

import { FilePreviewModal, Spinner } from '../components';
import { useFileUploader } from '../components/FileUploader';
import { useDropzone } from 'react-dropzone';
import { useClaimThumbnail } from '../hooks';

type CertUploadInputProps = {
  submission: cert.GetSubmission.Submission.All;
};

export const CertUpload: React.FC<CertUploadInputProps> = ({ submission }) => {
  const { control } = useFormContext();
  const { append, remove, fields } = useFieldArray<{ claim: FileClaim }>({
    control,
    name: 'files',
  });
  const { isUploading, performUpload, allowedMime } = useFileUploader({
    allowedTypes: Object.values(FileUploadTargetType),
    uploadedFor: {
      type: FileUploadFor.CERTIFICATION,
      certificationDesignId: submission.certificationDesignId,
    },
    onNewFile: (claim) => append({ claim }),
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: allowedMime,
    multiple: false,
    onDrop: (files) => files.forEach((file) => performUpload(file)),
  });

  const canAddAnotherFile = fields.length + 1 <= submission.getFileCount().max;
  return (
    <Stack w="100%" spacing={3}>
      <inputs.wrap.FormControl name="files">
        <Stack spacing={2}>
          {fields.map((item, index) => (
            <FileImage
              key={item.id}
              claim={item.claim!}
              onRemove={() => remove(index)}
              size="42px"
              width={150}
              height={150}
            />
          ))}
        </Stack>
      </inputs.wrap.FormControl>

      {canAddAnotherFile && (
        <Center
          {...getRootProps()}
          cursor="pointer"
          borderRadius="8px"
          bg={isDragActive ? '#F3F7FF' : '#E9F0FF'}
          p={6}
          border={isDragActive ? '3px solid' : '3px dashed'}
          boxSizing="border-box"
          borderColor="brand.blue"
          color="brand.blue"
          transition="background 0.1s ease-out"
          _hover={{ bg: '#F3F7FF' }}
        >
          <Box textAlign="center">
            <input {...getInputProps()} />
            {isDragActive && (
              <>
                <Center mb={4}>
                  <MdFileUpload size="38px" />
                </Center>
                <Text mb={4} fontSize="sm">
                  Drop here
                </Text>
              </>
            )}

            {!isDragActive && (
              <>
                {isUploading && <Spinner />}
                {!isUploading && (
                  <>
                    {fields.length == 0 && (
                      <>
                        <Center mb={4}>
                          <FaIdCard size="38px" />
                        </Center>
                        <Text mb={4} fontSize="sm" color="brand.black">
                          Upload <strong>all pages</strong> of your certificate
                          <br />
                          or <strong>both front and back</strong> of licence
                        </Text>
                      </>
                    )}
                    <Button
                      rightIcon={<MdFileUpload size="16px" />}
                      variant="outline"
                      fontWeight="bold"
                      size="sm"
                    >
                      Upload
                    </Button>
                  </>
                )}
              </>
            )}
          </Box>
        </Center>
      )}
    </Stack>
  );
};

const FileImage: React.FC<
  Parameters<typeof useClaimThumbnail>[0] & {
    size: string;
    claim: FileClaim;
    onRemove: () => unknown;
  }
> = ({ claim, size, width, height, onRemove }) => {
  const { fileThumbnail } = useClaimThumbnail({
    claim,
    width,
    height,
  });

  const [loaded, setLoaded] = React.useState<boolean>(false);
  return (
    <FilePreviewModal
      file={claim}
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={({ onOpen }) => (
        <HStack color="brand.blue" w="100%" p={2} layerStyle="outline">
          <>
            {fileThumbnail && (
              <Image
                onClick={onOpen}
                onLoad={() => setLoaded(true)}
                display={loaded ? 'block' : 'none'}
                boxSize={size}
                src={fileThumbnail.url}
              />
            )}
            {!loaded && <AiOutlineFilePdf onClick={onOpen} size={size} />}

            <Spacer />
            <Button
              fontWeight="bold"
              size="sm"
              variant="outline"
              onClick={onOpen}
            >
              Preview
            </Button>
            <Button
              fontWeight="bold"
              size="sm"
              variant="outline"
              onClick={onRemove}
            >
              Remove
            </Button>
          </>
        </HStack>
      )}
    />
  );
};

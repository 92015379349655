import { Stack, Heading, Flex, Text } from '@chakra-ui/react';
import { VisaCheck } from '@zap-onboard/api-client';
import { Tag } from '@zap-onboard/web-components';
import { useMemo } from 'react';
import { purifyHTML } from '../../../helpers/purifyHTML';
import { LineItem } from './VisaHistoryCheckDetails';

type Props = {
  visaCheck: VisaCheck;
};

const expiredTag = <Tag label="Expired" bg="brand.red" labelColor="white" />;
const validTag = <Tag label="Valid" bg="brand.green" labelColor="white" />;

export const VisaHistoryCheckResult: React.FC<Props> = ({
  visaCheck: {
    workEntitlement,
    conditionsIds,
    conditions,
    result,
    visaTypeDetails,
    visaType,
    expiryDate,
    grantDate,
    visaTypeName,
    visaClass,
    visaApplicant,
    error,
    status,
  },
}) => {
  const sanitizedConditions = useMemo(
    () => purifyHTML(conditions || ''),
    [conditions],
  );
  if (result === 'OK') {
    return (
      <Stack>
        <Heading size="small">Submission Results</Heading>
        <Flex gridGap="8px">
          <Flex direction="column" gridGap="4px">
            <LineItem
              label="Expiry Date"
              details={expiryDate?.asUserFacingFormat()}
            />
          </Flex>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!expiryDate ? null : expiryDate?.isInPast() ? expiredTag : validTag}
        </Flex>
        {status && <LineItem label="Status" details={status} />}
        <LineItem label="Work Entitlement" details={workEntitlement} />
        <LineItem label="Condition Ids" details={conditionsIds} />
        <Text color="brand.dark-gray">Conditions</Text>
        <Text dangerouslySetInnerHTML={{ __html: sanitizedConditions }} />
        <LineItem label="Visa Type Details" details={visaTypeDetails} />
        <LineItem label="Visa Type" details={visaType} />
        <LineItem
          label="Grant Date"
          details={grantDate?.asUserFacingFormat()}
        />
        <LineItem label="Visa Type Name" details={visaTypeName} />
        <LineItem label="Visa Class" details={visaClass} />
        <LineItem label="Visa Applicant" details={visaApplicant} />
      </Stack>
    );
  }

  return (
    <Stack>
      <Heading size="small">Submission Results</Heading>
      {error && (
        <Flex gridGap="8px">
          <Flex direction="column" gridGap="4px">
            <LineItem label="Error" details={error} />
          </Flex>
          <Tag label="Visa check failed" bg="brand.red" labelColor="white" />
        </Flex>
      )}
      <LineItem label="Status" details={status} />
    </Stack>
  );
};

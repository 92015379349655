import React, { useEffect } from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';

import { useCompactView } from '../../../hooks/useCompactView';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebouncedValue, usePositions } from '../../../hooks';
import { inputs } from '@zap-onboard/web-components';
import { membership } from '@zap-onboard/api-client';

export type MemberSearchFilter = {
  search?: string;
  positionId?: string;
  uninvited?: boolean;
  invited?: boolean;
  archived?: boolean;
  role?: membership.RoleName;
};

type Form = {
  search?: string;
  positionId?: string;
  state: MemberStateSelection;
  role: 'ADMIN' | 'SUPERVISOR' | 'USER' | 'ALL';
};

type MemberStateSelection =
  | 'ARCHIVED'
  | 'INVITED'
  | 'UNINVITED'
  | 'ADMIN'
  | 'ALL';

export const Filter: React.FC<{
  onChange: (arg: MemberSearchFilter) => void;
}> = ({ onChange }) => {
  const { isCompactView } = useCompactView();
  const { positions } = usePositions();

  const form = useForm<Form>({
    defaultValues: {
      search: '',
      positionId: '',
      state: 'ALL',
      role: 'ALL',
    },
  });
  const filters = useDebouncedValue(form.watch(), 300);

  useEffect(() => {
    onChange({
      search: filters.search?.trim(),
      positionId: filters.positionId,
      role: filters.role !== 'ALL' ? filters.role : undefined,
      uninvited: filters.state === 'UNINVITED',
      invited: filters.state === 'INVITED',
      archived: filters.state === 'ARCHIVED',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.state, filters.positionId, filters.search, filters.role]);

  return (
    <FormProvider {...form}>
      <Flex justify="center" w="100%" minH={{ base: 'auto', lg: '156px' }}>
        <Box
          layerStyle={isCompactView ? 'none' : 'base'}
          w={{ base: '100%', sm: '70%', md: '60%', lg: '100%' }}
          py={isCompactView ? 4 : 0}
        >
          <Flex direction="column">
            {isCompactView ? null : (
              <Flex
                bg="brand.blue"
                borderRadius="8px 8px 0px 0px"
                mb={4}
                justifyContent="center"
              >
                <Text fontSize="sm" textAlign="center" color="#fff" py={1}>
                  Filters
                </Text>
              </Flex>
            )}
            <Stack px={{ base: 0, md: 6 }} pb={2}>
              <Text fontSize="xs" color="#7d8cb5">
                Search
              </Text>
              <inputs.TextInput
                placeholder="Search email or names"
                name="search"
              />

              <Text fontSize="xs" color="#7d8cb5">
                Position
              </Text>
              <inputs.SelectInput
                name="positionId"
                placeholder="Any"
                options={
                  positions.map((p) => ({
                    name: p.name,
                    value: p.positionId,
                  })) ?? []
                }
              />

              <Text fontSize="xs" color="#7d8cb5">
                Role
              </Text>
              <inputs.SelectInput
                name="role"
                options={[
                  {
                    name: 'Members',
                    value: 'USER',
                  },
                  {
                    name: 'Supervisors',
                    value: 'SUPERVISOR',
                  },
                  {
                    name: 'Admins',
                    value: 'ADMIN',
                  },
                  {
                    name: 'All',
                    value: 'ALL',
                  },
                ]}
              />

              <Text fontSize="xs" color="#7d8cb5">
                Status
              </Text>
              <inputs.radio.Group name="state">
                <Stack spacing={2}>
                  <inputs.radio.Button value="ALL">All</inputs.radio.Button>
                  <inputs.radio.Button value="INVITED">
                    Invited
                  </inputs.radio.Button>
                  <inputs.radio.Button value="UNINVITED">
                    Uninvited
                  </inputs.radio.Button>
                  <inputs.radio.Button value="ARCHIVED">
                    Archived
                  </inputs.radio.Button>
                </Stack>
              </inputs.radio.Group>
            </Stack>
          </Flex>
        </Box>
      </Flex>
    </FormProvider>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskDesignClasses = void 0;
const EsignTaskDesign_1 = require("../EsignTask/EsignTaskDesign");
const TemplateTaskDesign_1 = require("../TemplateTask/TemplateTaskDesign");
const FormTaskDesign_1 = require("../FormTask/FormTaskDesign");
const RawTextTaskDesign_1 = require("../RawTextTask/RawTextTaskDesign");
const TFNDTaskDesign_1 = require("../TFNDTask/TFNDTaskDesign");
const SuperChoiceTaskDesign_1 = require("../SuperChoiceTask/SuperChoiceTaskDesign");
const DeputyLinkEmployeeTaskDesign_1 = require("../DeputyLinkEmployeeTask/DeputyLinkEmployeeTaskDesign");
const XeroSyncEmployeeTaskDesign_1 = require("../XeroSyncEmployeeTask/XeroSyncEmployeeTaskDesign");
const BankAccountTaskDesign_1 = require("../BankAccountTask/BankAccountTaskDesign");
const TaskDesignTypes_1 = require("./TaskDesignTypes");
const ProfileTaskDesign_1 = require("../ProfileTask/ProfileTaskDesign");
const ClientVerificationTaskDesign_1 = require("../ClientVerificationTask/ClientVerificationTaskDesign");
const ProofOfIdentityTaskDesign_1 = require("../ProofOfIdentityTask/ProofOfIdentityTaskDesign");
const VisaDetailsTaskDesign_1 = require("../VisaDetailsTask/VisaDetailsTaskDesign");
exports.TaskDesignClasses = {
    [TaskDesignTypes_1.TaskDesignType.RAW_TEXT]: RawTextTaskDesign_1.RawTextTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.PROFILE]: ProfileTaskDesign_1.ProfileTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.FORM]: FormTaskDesign_1.FormTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.ESIGN]: EsignTaskDesign_1.EsignTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.TEMPLATE]: TemplateTaskDesign_1.TemplateTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.TFND]: TFNDTaskDesign_1.TFNDTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.SUPER_CHOICE]: SuperChoiceTaskDesign_1.SuperChoiceTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.DEPUTY_LINK_EMPLOYEE]: DeputyLinkEmployeeTaskDesign_1.DeputyLinkEmployeeTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.XERO_SYNC_EMPLOYEE]: XeroSyncEmployeeTaskDesign_1.XeroSyncEmployeeTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.BANK_ACCOUNT]: BankAccountTaskDesign_1.BankAccountTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.CLIENT_VERIFICATION]: ClientVerificationTaskDesign_1.ClientVerificationTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.PROOF_OF_IDENTITY]: ProofOfIdentityTaskDesign_1.ProofOfIdentityTaskDesign,
    [TaskDesignTypes_1.TaskDesignType.VISA_DETAILS]: VisaDetailsTaskDesign_1.VisaDetailsTaskDesign,
};

import { Button, Center, Text, ButtonGroup, Stack } from '@chakra-ui/react';
import { inputs } from '@zap-onboard/web-components';
import { useHandler } from 'hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { z } from 'helpers';
import { ContactNumber, MFA } from '@zap-onboard/api-client';
import { useProfile } from '../../selectors';

const Schema = z.object({
  number: z.valueObject(z.string(), ContactNumber),
});

export const AddSMSSourceForm = (props: {
  onAdded: (secondFactor: MFA.SMS.AddResponse, number: string) => unknown;
  onBack?: () => unknown;
}) => {
  const userProfile = useProfile();

  const [addSMS] = useHandler((api) => api.identity().mfa.addSMSNumber, {
    invalidates: () => [['MFA_SOURCES']],
  });

  const form = useForm<z.infer<typeof Schema>>({
    resolver: z.zodResolver(Schema),
    defaultValues: {
      number: userProfile?.primaryContactNumber?.asJSON() ?? '',
    },
    shouldUnregister: false,
  });

  const submit = form.handleSubmit(async ({ number }) => {
    await addSMS({ number })?.tap((res) => {
      props.onAdded(res, number);
    });
  });

  return (
    <>
      <Text mb={4}>
        Enter your mobile phone number. You will receive an SMS with a security
        code.
      </Text>

      <Center>
        <Stack>
          <FormProvider {...form}>
            <inputs.TextInput
              placeholder="E.g. 0400 123 456"
              _label={{
                fontSize: 14,
                color: 'gray.600',
              }}
              _control={{
                maxW: '230px',
              }}
              _input={{
                autoComplete: 'shipping tel',
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    submit();
                  }
                },
              }}
              name="number"
              label="Mobile Number"
              isAutoFocus
              isDisabled={form.formState.isSubmitting}
            />
          </FormProvider>
          <Text fontWeight="bold" color="gray.500" fontSize="xs">
            SMS may not be available in all countries.
          </Text>
        </Stack>
      </Center>

      <Center>
        <ButtonGroup>
          <Button
            isDisabled={form.formState.isSubmitting}
            isLoading={form.formState.isSubmitting}
            onClick={submit}
          >
            Send Security Code
          </Button>

          {props.onBack && (
            <Button variant="outline" onClick={props.onBack}>
              Back
            </Button>
          )}
        </ButtonGroup>
      </Center>
    </>
  );
};

import * as React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdLaptop } from 'react-icons/md';

import { BaseIconProps } from '../BaseIcon';

type DeveloperIconProps = BaseIconProps;

export const DeveloperIcon: React.FC<DeveloperIconProps> = ({
  iconColor,
  width,
  height,
}) => (
  <Icon
    as={MdLaptop}
    width={width}
    height={height}
    color={iconColor === 'white' ? 'white' : 'brand.blue'}
  />
);

import * as React from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { get, useFormContext } from 'react-hook-form';

import {
  FileUploadFor,
  FileUploadFormSchemaItem,
  FileUploadFormSubmissionItem,
  FormTask as FormTaskModel,
} from '@zap-onboard/api-client';

import { DocumentUpload } from '../../../../../DocumentUpload';

type FormTaskFileInputProps = {
  schemaItem: FileUploadFormSchemaItem;
  submissionItem: FileUploadFormSubmissionItem | undefined;
  disabled: boolean;
  task: FormTaskModel;
  getPath: (...paths: string[]) => string;
  setIsUploading: (arg: boolean) => void;
};

export const FormTaskFileInput: React.FC<FormTaskFileInputProps> = ({
  disabled,
  schemaItem,
  submissionItem,
  task,
  getPath,
  setIsUploading,
}) => {
  const { control, register, errors } = useFormContext();

  return (
    <>
      <input
        type="hidden"
        ref={register()}
        value={schemaItem.type}
        name={getPath('type')}
      />
      <FormControl my="2" isInvalid={!!get(errors, getPath('claim'))}>
        <FormLabel>{schemaItem.title}</FormLabel>
        {schemaItem.description ? <Text>{schemaItem.description}</Text> : null}
        <Flex justify="flex-start">
          <DocumentUpload
            uploadedFor={{ type: FileUploadFor.TASK, taskId: task.taskId }}
            isReadOnly={disabled}
            allowedTypes={schemaItem.permittedFileTypes}
            controlled={{
              name: getPath('claim'),
              control,
              rules: {
                required: schemaItem.required
                  ? 'This file must be provided'
                  : false,
              },
              defaultValue: submissionItem?.claim,
            }}
            setIsUploading={setIsUploading}
          />
        </Flex>

        <FormErrorMessage>
          {get(errors, getPath('claim', 'message'))}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

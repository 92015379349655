"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TFNDPayeeSubmission = exports.TFNDPayeeSubmissionValue = exports.TFNDPayeeSubmissionExemption = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const ResponseBase_1 = require("../../../ResponseBase");
const IsInstanceOfAny_1 = require("../../../utils/validations/IsInstanceOfAny");
const TFN_1 = require("../../../utils/TFN");
const AbsoluteDate_1 = require("../../../utils/time/AbsoluteDate");
const utils_1 = require("../../../utils");
const paymentBasis = [
    'FULL_TIME',
    'PART_TIME',
    'LABOUR_HIRE',
    'SUPERANNUATION',
    'CASUAL',
];
const individualType = [
    'AUSTRALIAN_RESIDENT',
    'FOREIGN_RESIDENT',
    'WORKING_HOLIDAY_MAKER',
];
const titles = ['MR', 'MRS', 'MISS', 'MS'];
const exemptions = [
    'UNDER_18_AND_DOES_NOT_EARN_ENOUGH',
    'RECEIVING_PENSION_BENEFIT_OR_ALLOWANCE',
    'MADE_SEPERATE_QUERY_FOR_TFN',
];
class TFNDPayeeSubmissionExemption extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['EXEMPTION']),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmissionExemption.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(exemptions),
    (0, tslib_1.__metadata)("design:type", Object)
], TFNDPayeeSubmissionExemption.prototype, "exemption", void 0);
exports.TFNDPayeeSubmissionExemption = TFNDPayeeSubmissionExemption;
class TFNDPayeeSubmissionValue extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['TFN']),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmissionValue.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TFN_1.IndividualTFN),
    (0, tslib_1.__metadata)("design:type", TFN_1.IndividualTFN)
], TFNDPayeeSubmissionValue.prototype, "tfn", void 0);
exports.TFNDPayeeSubmissionValue = TFNDPayeeSubmissionValue;
class TFNDPayeeSubmission extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({
        taggedUnion: {
            getTag: (v) => v.type,
            handlers: {
                TFN: TFNDPayeeSubmissionValue,
                EXEMPTION: TFNDPayeeSubmissionExemption,
            },
        },
    }),
    (0, IsInstanceOfAny_1.IsInstanceOfAny)([TFNDPayeeSubmissionValue, TFNDPayeeSubmissionExemption]),
    (0, tslib_1.__metadata)("design:type", Object)
], TFNDPayeeSubmission.prototype, "tfnResponse", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmission.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(titles),
    (0, tslib_1.__metadata)("design:type", Object)
], TFNDPayeeSubmission.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmission.prototype, "familyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmission.prototype, "firstGivenName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmission.prototype, "otherGivenNames", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], TFNDPayeeSubmission.prototype, "previousFamilyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], TFNDPayeeSubmission.prototype, "primaryEmailAddress", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(AbsoluteDate_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", AbsoluteDate_1.AbsoluteDate)
], TFNDPayeeSubmission.prototype, "dateOfBirth", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(paymentBasis),
    (0, tslib_1.__metadata)("design:type", Object)
], TFNDPayeeSubmission.prototype, "paymentBasis", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(individualType),
    (0, tslib_1.__metadata)("design:type", Object)
], TFNDPayeeSubmission.prototype, "individualType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.Country),
    (0, tslib_1.__metadata)("design:type", utils_1.Country)
], TFNDPayeeSubmission.prototype, "countryOfResidence", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], TFNDPayeeSubmission.prototype, "claimTaxFreeThreshold", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], TFNDPayeeSubmission.prototype, "hasSupportDebt", void 0);
exports.TFNDPayeeSubmission = TFNDPayeeSubmission;

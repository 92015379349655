import { useHandlerQuery } from '../../useHandlerQuery';
import { useQueryClient } from 'react-query';
import { errs } from '@zap-onboard/errors';

export const useVisaConfig = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useHandlerQuery(
    (api) => api.visa().getVisaConfig(),
    {
      key: ['VISA_CONFIG'],
      retry: 1,
      skipRefetch: true,
      onError: (err) => {
        if (err instanceof errs.NotFoundError) {
          queryClient.setQueryData('VISA_CONFIG', undefined);
        }
      },
    },
  );
  return {
    isVisaConfigLoading: isLoading,
    visaConfig: data,
  };
};

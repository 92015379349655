import * as React from 'react';

export const useIsComponentMounted = () => {
  const { useCallback, useEffect, useRef } = React;

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback(() => isMounted.current, []);
};

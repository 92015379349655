"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthCheck = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const time_1 = require("../utils/time");
class HealthCheck extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    (0, class_validator_1.IsInstance)(time_1.TimeAndDate),
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", time_1.TimeAndDate)
], HealthCheck.prototype, "date", void 0);
exports.HealthCheck = HealthCheck;

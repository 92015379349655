import React from 'react';
import { MdLogout } from 'react-icons/md';
import { Icon } from '@chakra-ui/react';
import { BaseIconProps } from '../BaseIcon';

type LogoutIconProps = BaseIconProps;

export const LogoutIcon: React.FC<LogoutIconProps> = ({ iconColor, width }) => (
  <Icon as={MdLogout} color={iconColor} boxSize={width} />
);

import * as React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';

type CancelUpdateModalProps = {
  header: string;
  body: string;
  onCancel: () => void;
  isDirty: boolean;
  isSubmitting: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
};

export const CancelUpdateModal: React.FC<CancelUpdateModalProps> = ({
  header,
  body,
  isDirty,
  isSubmitting,
  onCancel,
  setIsEditMode,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    if (isDirty) {
      onOpen();
    } else {
      setIsEditMode(false);
    }
  };

  const handleCancel = (): void => {
    onCancel();
    onClose();
  };

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        disabled={isSubmitting}
        onClick={handleClick}
      >
        Cancel
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="md">{header}</ModalHeader>
          <ModalBody fontSize="sm" pb={6}>
            {body}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" size="sm" onClick={handleCancel} mr={3}>
              Yes
            </Button>
            <Button onClick={onClose} size="sm" variant="outline">
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import {
  Box,
  Text,
  Button,
  Stack,
  Icon,
  HStack,
  Spacer,
  ButtonGroup,
  Heading,
  TabPanel,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  Badge,
  Link,
  Spinner,
  useDisclosure,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import {
  BillingAccountSubscriptionResponse,
  TimeAndDate,
  XeroBillingAccountListingResponse,
} from '@zap-onboard/api-client';
import { SiXero } from 'react-icons/si';
import { DeleteModal } from '../components';
import {
  makeXeroCancelLink,
  makeXeroSubscribeLink,
} from '../helpers/xeroDeepLinks';
import { ErrorDisplay } from './ErrorDisplay';
import { useBillingAccountSubscriptions } from './hooks/useBillingAccountSubscriptions';
import { useBillingMutations } from './hooks/useBillingMutations';

export const XeroAccountListing = (args: {
  billingAccount: XeroBillingAccountListingResponse;
}) => {
  const a = args.billingAccount;

  a.upcomingPlanName = 'Advisory';
  a.paidUntil = TimeAndDate.present().add({ days: 4 });
  return (
    <Box layerStyle="base">
      {a.upcomingPlanName && (
        <Alert status="info" variant="left-accent">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Upcoming Plan Change</AlertTitle>
            <AlertDescription display="block">
              This billing account will change to the{' '}
              {`"${a.upcomingPlanName}"`} plan on{' '}
              {a.paidUntil
                ? a?.paidUntil.toAbsoluteDate().asShortPretty()
                : 'the next billing cycle'}
            </AlertDescription>
          </Box>
        </Alert>
      )}

      <HStack p={6}>
        <HStack>
          <Heading variant="subSection">{a.name ?? a.billingAccountId}</Heading>
          {!!a.paidUntil && (
            <Badge>
              Paid Until {a.paidUntil.toAbsoluteDate().asShortPretty()}
            </Badge>
          )}
        </HStack>
        <Spacer />

        <Icon color="brand.xero.bg" w={8} h={8} as={SiXero} />
      </HStack>

      <Tabs variant="line" size="sm" isLazy>
        <TabList flexDir={{ sm: 'row', base: 'column' }}>
          <Tab>Preferences</Tab>
          <Tab>Businesses</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={6}>
            <Stack>
              <Stack direction={{ sm: 'row', base: 'column' }} wrap="wrap">
                <Stack w="200px">
                  <Text fontSize="xs" fontWeight="bold">
                    {a.planName ?? 'Unknown'}
                  </Text>
                  <Text fontSize="sm">{a.price?.toString() ?? '0'}/month</Text>
                </Stack>

                {a.maxBusinesses <= 1 && (
                  <Stack w="200px">
                    <Text fontSize="xs" fontWeight="bold">
                      Max Businesses
                    </Text>
                    <Text fontSize="sm">{a.maxBusinesses}</Text>
                  </Stack>
                )}
              </Stack>
              <Spacer py={4} />

              <ButtonGroup justifyContent="space-between" w="100%">
                {a.xeroShortCode && (
                  <Button
                    as={Link}
                    isExternal
                    href={makeXeroSubscribeLink(a.xeroShortCode)}
                  >
                    Manage on Xero
                  </Button>
                )}

                {a.xeroShortCode && (
                  <Button
                    as={Link}
                    isExternal
                    href={makeXeroCancelLink(a.xeroShortCode)}
                    variant="outline"
                  >
                    Cancel
                  </Button>
                )}
              </ButtonGroup>
            </Stack>
          </TabPanel>
          <TabPanel p={6}>
            <BusinessPanel billingAccount={a} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const BusinessPanel = ({
  billingAccount,
}: {
  billingAccount: XeroBillingAccountListingResponse;
}) => {
  const subscriptions = useBillingAccountSubscriptions({
    billingAccountId: billingAccount.billingAccountId,
  });

  if (!subscriptions.isFetched) {
    return <Spinner />;
  }
  const errors = (
    <ErrorDisplay title="Could not load your subscription" {...subscriptions} />
  );
  if (subscriptions.data == null) {
    return errors;
  }

  return (
    <Stack spacing={6}>
      {errors}
      <Stack spacing={3}>
        {subscriptions.data?.map((sub) => (
          <BusinessRow
            key={sub.businessId}
            billingAccountId={billingAccount.billingAccountId}
            subscription={sub}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const BusinessRow = ({
  billingAccountId,
  subscription: { businessId, businessName },
}: {
  billingAccountId: string;
  subscription: BillingAccountSubscriptionResponse;
}) => {
  const disclosure = useDisclosure();
  const { unsubscribe } = useBillingMutations();
  return (
    <HStack p={3} layerStyle="outline" key={businessId}>
      <Text fontWeight="bold">{businessName}</Text>
      <Spacer />
      <Button onClick={disclosure.onOpen}>Remove</Button>
      <DeleteModal
        {...disclosure}
        modalBodyText="You can always attach this business again later."
        modalHeaderText={`Remove ${businessName} from your Billing Account`}
        confirmText="Remove"
        callBack={() =>
          unsubscribe({
            businessId,
            billingAccountId,
          })
        }
      />
    </HStack>
  );
};

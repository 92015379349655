import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe, SetupIntent, Stripe } from '@stripe/stripe-js';
import { AsyncResult, Fail, Result } from 'designed';
import { useCallback, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import config from '../../config';

let stripePromise: Promise<Stripe | null> | null = null;
const getStripe = () => {
  if (config.stripePublicKey) {
    return (stripePromise ||= loadStripe(config.stripePublicKey));
  }
  return null;
};

type WithCardElementProps = {
  children: (args: {
    cardInput: JSX.Element;
    billingAgreementText: string;
    error?: string;
    canSubmit: boolean;
    loadPaymentMethod: (args: {
      stripeSetupIntentSecret: string;
      name: string;
    }) => AsyncResult<SetupIntent, Error>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => any;
};

export const WithCardInput: React.FC<WithCardElementProps> = ({ children }) => (
  <Elements stripe={getStripe()}>
    <CardInput>{children}</CardInput>
  </Elements>
);

const BILLING_AGREEMENT =
  'I authorise Canyou to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.';

const CardInput: React.FC<WithCardElementProps> = ({ children }) => {
  const [error, setError] = useState<string>();

  const [complete, setComplete] = useState<boolean>(false);

  const [stripeRequestInFlight, setStripeRequestInFlight] =
    useState<boolean>(false);

  const stripe = useStripe();
  const elements = useElements();

  const loadPaymentMethod = useCallback(
    ({
      name,
      stripeSetupIntentSecret,
    }: {
      stripeSetupIntentSecret: string;
      name: string;
    }) => {
      const result = Result.fromPromise<SetupIntent, Error>(
        (async () => {
          if (stripeRequestInFlight) {
            throw Fail.of(
              new Error('Please wait for previous requests to finish'),
            );
          }

          setStripeRequestInFlight(true);
          const stripeCardElement = elements?.getElement(CardElement);
          if (!elements || !stripeCardElement || !stripe) {
            throw new Error('Card element not found');
          }

          const { error, setupIntent } = await stripe.confirmCardSetup(
            stripeSetupIntentSecret,
            {
              payment_method: {
                card: stripeCardElement,
                billing_details: {
                  name,
                },
              },
            },
            /**
             * This opens the 3D secure modal provided by the users bank over
             * the input if required.
             */
            { handleActions: true },
          );

          if (error) {
            // TODO: Error type
            throw new Error(error.message);
          }

          if (!setupIntent) {
            throw new Error('could not link you through our billing provider');
          }

          return setupIntent;
        })(),
      );
      result.then(() => setStripeRequestInFlight(false));
      return result;
    },
    [stripeRequestInFlight, elements, stripe],
  );

  if (!stripe || !elements) {
    return <Spinner />;
  }

  const cardInput = (
    <CardElement
      onChange={(e) => {
        if (e.error?.type) {
          setError(e.error.message);
        } else {
          setError(undefined);
        }
        setComplete(e.complete);
      }}
      options={{
        hidePostalCode: true,
        iconStyle: 'solid',
        style: {
          base: {},
        },
      }}
    />
  );

  return children({
    cardInput,
    error,
    canSubmit: complete && !error && !stripeRequestInFlight,
    billingAgreementText: BILLING_AGREEMENT,
    loadPaymentMethod,
  });
};

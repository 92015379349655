"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskActions = exports.TaskActionType = void 0;
const MetaTaskActions_1 = require("./MetaTaskActions");
const RawTextTaskActions_1 = require("../RawTextTask/RawTextTaskActions");
const ProfileTaskActions_1 = require("../ProfileTask/ProfileTaskActions");
const FormTaskActions_1 = require("../FormTask/FormTaskActions");
const EsignTaskActions_1 = require("../EsignTask/EsignTaskActions");
const TemplateTaskActions_1 = require("../TemplateTask/TemplateTaskActions");
const TFNDTaskActions_1 = require("../TFNDTask/TFNDTaskActions");
const SuperChoiceTaskActions_1 = require("../SuperChoiceTask/SuperChoiceTaskActions");
const DeputyLinkEmployeeTaskActions_1 = require("../DeputyLinkEmployeeTask/DeputyLinkEmployeeTaskActions");
const XeroSyncEmployeeTaskActions_1 = require("../XeroSyncEmployeeTask/XeroSyncEmployeeTaskActions");
const BankAccountTaskActions_1 = require("../BankAccountTask/BankAccountTaskActions");
const ClientVerificationTaskActions_1 = require("../ClientVerificationTask/ClientVerificationTaskActions");
const ProofOfIdentityTaskActions_1 = require("../ProofOfIdentityTask/ProofOfIdentityTaskActions");
const VisaDetailsTask_1 = require("../VisaDetailsTask");
exports.TaskActionType = {
    ...MetaTaskActions_1.MetaTaskActionType,
    ...RawTextTaskActions_1.RawTextActionType,
    ...ProfileTaskActions_1.ProfileActionType,
    ...FormTaskActions_1.FormTaskActionType,
    ...EsignTaskActions_1.EsignTaskActionType,
    ...TemplateTaskActions_1.TemplateTaskActionType,
    ...TFNDTaskActions_1.TFNDTaskActionType,
    ...SuperChoiceTaskActions_1.SuperChoiceTaskActionType,
    ...DeputyLinkEmployeeTaskActions_1.DeputyLinkEmployeeActionType,
    ...XeroSyncEmployeeTaskActions_1.XeroSyncEmployeeActionType,
    ...BankAccountTaskActions_1.BankAccountTaskActionType,
    ...ProofOfIdentityTaskActions_1.ProofOfIdentityTaskActionType,
    ...ClientVerificationTaskActions_1.ClientVerificationTaskActionType,
    ...VisaDetailsTask_1.VisaDetailsTaskActionType,
};
exports.TaskActions = {
    ...MetaTaskActions_1.MetaTaskActions,
    ...RawTextTaskActions_1.RawTextActions,
    ...ProfileTaskActions_1.ProfileActions,
    ...FormTaskActions_1.FormTaskActions,
    ...EsignTaskActions_1.EsignTaskActions,
    ...TemplateTaskActions_1.TemplateTaskActions,
    ...TFNDTaskActions_1.TFNDTaskActions,
    ...SuperChoiceTaskActions_1.SuperChoiceTaskActions,
    ...DeputyLinkEmployeeTaskActions_1.DeputyLinkEmployeeActions,
    ...XeroSyncEmployeeTaskActions_1.XeroSyncEmployeeActions,
    ...BankAccountTaskActions_1.BankAccountTaskActions,
    ...ClientVerificationTaskActions_1.ClientVerificationTaskActions,
    ...ProofOfIdentityTaskActions_1.ProofOfIdentityTaskActions,
    ...VisaDetailsTask_1.VisaDetailsTaskActions,
};

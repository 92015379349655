import { useController, useFormContext } from 'react-hook-form';
/**
 * Exists to hold react-hook-form default values in scope
 */
export var Hidden = function (args) {
    var control = useFormContext().control;
    useController({
        name: args.name,
        control: control,
    });
    return null;
};

import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ShareIcon = createIcon({
  path: (
    <g data-name="Layer 2">
      <path
        d="M37.38 32.42A8.55 8.55 0 0 0 30.46 36l-13.59-7.08a9.1 9.1 0 0 0 .38-2.46 8.75 8.75 0 0 0-.64-3.28l14.23-8.72a8.51 8.51 0 0 0 6.54 3.12A8.72 8.72 0 0 0 46 8.79a8.63 8.63 0 1 0-17.25 0 8.92 8.92 0 0 0 .6 3.16L15.08 20.7a8.48 8.48 0 0 0-6.46-3 8.79 8.79 0 0 0 0 17.57 8.53 8.53 0 0 0 7-3.69l13.55 7.07a8.82 8.82 0 0 0-.42 2.58 8.63 8.63 0 1 0 8.63-8.79z"
        fill="currentColor"
        data-name="Layer 1"
      />
    </g>
  ),
  viewBox: '0 0 50 50',
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ABN = void 0;
const errors_1 = require("@zap-onboard/errors");
const abnWeights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
class ABN {
    constructor(value) {
        this.value = value;
    }
    static fake() {
        return ABN.fromJSON('76 861 743 447');
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('ABN was given a non string value');
        }
        const onlyDigits = s.replace(/\D/g, '');
        const abn = new ABN(onlyDigits);
        abn.validate();
        return abn;
    }
    static isValid(s) {
        return ABN.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            ABN.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    toString() {
        return `ABN{${this.value}}`;
    }
    asJSON() {
        return this.value;
    }
    equals(that) {
        return this.value === that.value;
    }
    validate() {
        const checksum = this.calculateChecksum();
        if (checksum % 89 !== 0) {
            throw errors_1.errs.ValidationError.create('ABN did not pass checksum verification');
        }
    }
    calculateChecksum() {
        if (!this.isValidLength()) {
            throw errors_1.errs.ValidationError.create('ABN must be exactly 11 digits');
        }
        const numbers = this.asNumbers();
        numbers[0] -= 1;
        return numbers.reduce((sum, number, index) => {
            const weightedNumber = number * abnWeights[index];
            return sum + weightedNumber;
        }, 0);
    }
    isValidLength() {
        return this.value.length === 11;
    }
    asNumbers() {
        const numbers = this.value.split('').map(Number);
        numbers.forEach((n) => {
            if (Number.isNaN(n)) {
                throw errors_1.errs.ValidationError.create('ABN is not a number');
            }
        });
        return numbers;
    }
}
exports.ABN = ABN;

import * as React from 'react';
import {
  Button,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Text,
  Link,
  Flex,
} from '@chakra-ui/react';

import { NotificationListing } from '@zap-onboard/api-client';

import { NotificationsList } from '../NotificationsList';
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  unread: NotificationListing[];
  onClose: () => void;
  markAllNotificationsAsRead: () => unknown;
};

export const NotificationsModal: React.FC<Props> = ({
  isOpen,
  unread,
  onClose,
  markAllNotificationsAsRead,
}) => (
  <Modal
    closeOnOverlayClick
    isOpen={isOpen}
    onClose={onClose}
    scrollBehavior="inside"
  >
    <ModalOverlay />
    <ModalContent m={4} data-testid="notificationsModal">
      <ModalHeader fontSize="md">
        <Flex justifyContent="space-between">
          <Text>Notifications</Text>
          <Link
            fontWeight="bold"
            fontSize="sm"
            color="gray.600"
            to="/notification-settings"
            onClick={onClose}
            as={ReactRouterLink}
          >
            Settings
          </Link>
        </Flex>
      </ModalHeader>
      <ModalBody fontSize="sm" pb={6}>
        <NotificationsList />
      </ModalBody>

      <ModalFooter>
        {unread && unread?.length > 0 && (
          <Button mr={3} onClick={markAllNotificationsAsRead}>
            Mark all as read
          </Button>
        )}
        <Button variant="outline" mr={3} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportFlowDesignTemplateResponse = exports.ImportFlowDesignTemplateRequest = exports.FlowDesignTemplateList = exports.FlowDesignTemplate = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
class FlowDesignTemplate extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowDesignTemplate.prototype, "flowDesignId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowDesignTemplate.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowDesignTemplate.prototype, "description", void 0);
exports.FlowDesignTemplate = FlowDesignTemplate;
class FlowDesignTemplateList extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: FlowDesignTemplate }),
    (0, class_validator_1.IsInstance)(FlowDesignTemplate, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], FlowDesignTemplateList.prototype, "flowDesignTemplates", void 0);
exports.FlowDesignTemplateList = FlowDesignTemplateList;
class ImportFlowDesignTemplateRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], ImportFlowDesignTemplateRequest.prototype, "flowDesignId", void 0);
exports.ImportFlowDesignTemplateRequest = ImportFlowDesignTemplateRequest;
class ImportFlowDesignTemplateResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], ImportFlowDesignTemplateResponse.prototype, "flowDesignId", void 0);
exports.ImportFlowDesignTemplateResponse = ImportFlowDesignTemplateResponse;

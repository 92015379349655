"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploadForVideo = exports.FileUploadForCertification = exports.FileUploadForTask = exports.FileUploadForMyBusiness = exports.FileUploadForMember = exports.FileUploadFor = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
var FileUploadFor;
(function (FileUploadFor) {
    FileUploadFor["MY_BUSINESS"] = "MY_BUSINESS";
    FileUploadFor["TASK"] = "TASK";
    FileUploadFor["CERTIFICATION"] = "CERTIFICATION";
    FileUploadFor["MEMBER"] = "MEMBER";
    FileUploadFor["VIDEO"] = "VIDEO";
})(FileUploadFor = exports.FileUploadFor || (exports.FileUploadFor = {}));
class FileUploadForMember extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)([FileUploadFor.MEMBER]),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForMember.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForMember.prototype, "memberId", void 0);
exports.FileUploadForMember = FileUploadForMember;
class FileUploadForMyBusiness extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)([FileUploadFor.MY_BUSINESS]),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForMyBusiness.prototype, "type", void 0);
exports.FileUploadForMyBusiness = FileUploadForMyBusiness;
class FileUploadForTask extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)([FileUploadFor.TASK]),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForTask.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForTask.prototype, "taskId", void 0);
exports.FileUploadForTask = FileUploadForTask;
class FileUploadForCertification extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)([FileUploadFor.CERTIFICATION]),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForCertification.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForCertification.prototype, "certificationDesignId", void 0);
exports.FileUploadForCertification = FileUploadForCertification;
class FileUploadForVideo extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)([FileUploadFor.VIDEO]),
    (0, tslib_1.__metadata)("design:type", String)
], FileUploadForVideo.prototype, "type", void 0);
exports.FileUploadForVideo = FileUploadForVideo;

import * as React from 'react';
import { Box, Button, Heading, Stack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { SiXero } from 'react-icons/si';

import { Email, GoogleConnectPurpose } from '@zap-onboard/api-client';
import { track, onError } from 'libs';
import { useGoogleConnect, useXeroConsentConfig } from 'hooks';
import { createToast, z } from 'helpers';

import { inputs } from '@zap-onboard/web-components';
import {
  AcceptTOCCheckbox,
  Divider,
  LoadingState,
  GoogleConnectButton,
} from 'components';
import { useAPI } from '../../../../services/API';

export const SignUpForm: React.FC = () => {
  const { useState } = React;
  const api = useAPI();
  const { isLoadingXeroConsentConfig, startXeroSignup } =
    useXeroConsentConfig();
  const { connectGoogle, isGoogleConnecting } = useGoogleConnect({
    purpose: GoogleConnectPurpose.SIGN_UP,
  });

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const SignUpFormSchema = z.object({
    email: z.valueObject(z.string(), Email),
  });

  const useFormMethods = useForm<z.infer<typeof SignUpFormSchema>>({
    defaultValues: {
      email: '',
    },
    resolver: z.zodResolver(SignUpFormSchema),
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useFormMethods;

  const onSubmit = handleSubmit(async ({ email }) => {
    await api
      .identity()
      .register({ email })
      .map(() => {
        track.event('Signed Up');
        createToast({
          title: "Great! We've sent you an email to confirm your account.",
          description: `Please click the link in your email to complete your registration`,
          status: 'success',
          duration: null,
        });
      }, onError);
  });

  if (isGoogleConnecting) {
    return <LoadingState />;
  }

  return (
    <Box w="100%">
      <FormProvider {...useFormMethods}>
        <form onSubmit={onSubmit}>
          <Stack spacing={4}>
            <Heading size="md">Sign up</Heading>
            <Stack spacing={0}>
              <inputs.TextInput name="email" label="Email" />
              <AcceptTOCCheckbox
                hasAcceptedTerms={hasAcceptedTerms}
                toggle={() => setHasAcceptedTerms(!hasAcceptedTerms)}
              />
            </Stack>
            <Button
              data-testid="signUpButton"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !hasAcceptedTerms}
            >
              Sign up
            </Button>
            <Divider text="or" />
            <Button
              leftIcon={<SiXero />}
              onClick={startXeroSignup}
              color="brand.xero.fg"
              background="brand.xero.bg"
              variant="button"
              isLoading={isLoadingXeroConsentConfig}
              isDisabled={isLoadingXeroConsentConfig}
            >
              Sign up with Xero
            </Button>

            <GoogleConnectButton
              context="signup"
              text="signup_with"
              onIdentityTokenReturned={connectGoogle}
            />
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

import * as React from 'react';
import { Center, Fade, Spinner as ChakraSpinner } from '@chakra-ui/react';

import { ChakraSpinnerProps } from '../../types/Chakra';

type SpinnerProps = Partial<ChakraSpinnerProps> & {
  _wrapper?: Partial<React.ComponentProps<typeof Center>>;
  _fade?: Partial<React.ComponentProps<typeof Fade>>;
};

export const Spinner: React.FC<SpinnerProps> = ({
  _fade,
  _wrapper,
  ...props
}) => (
  <Center p={6} w="100%" h="100%" {..._wrapper}>
    <Fade in {..._fade}>
      <ChakraSpinner
        thickness="4px"
        speed="0.65s"
        color="brand.blue"
        size="xl"
        {...props}
      />
    </Fade>
  </Center>
);

import * as React from 'react';
import { Icon } from '@chakra-ui/react';

import { MdOutlineOndemandVideo } from 'react-icons/md';

import { BaseIconProps } from '../BaseIcon';

type VideosIconProps = BaseIconProps;

export const VideosIcon: React.FC<VideosIconProps> = ({
  iconColor,
  width,
  height,
}) => (
  <Icon as={MdOutlineOndemandVideo} color={iconColor} w={width} h={height} />
);

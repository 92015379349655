var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useRadioGroup, useRadio } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormControl } from '../wrappers/FormControl';
var radioGroupContext = React.createContext(undefined);
export var useRadioGroupContextForItem = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var groupCtx = useContext(radioGroupContext);
    if (!groupCtx) {
        throw new Error('Radio group context was not defined');
    }
    var getRadioProps = groupCtx.getRadioProps, isDisabled = groupCtx.isDisabled, hideUnselected = groupCtx.hideUnselected;
    var radioProps = getRadioProps.apply(void 0, args);
    var _a = useRadio(__assign(__assign({}, radioProps), { isDisabled: isDisabled || radioProps.disabled })), getInputProps = _a.getInputProps, getCheckboxProps = _a.getCheckboxProps, getLabelProps = _a.getLabelProps, state = _a.state;
    return {
        inputProps: getInputProps(),
        checkboxProps: getCheckboxProps(),
        labelProps: getLabelProps(),
        state: __assign({ hideUnselected: hideUnselected && (state.isDisabled || state.isReadOnly) }, state),
    };
};
export var RadioGroup = function (_a) {
    var children = _a.children, isDisabled = _a.isDisabled, defaultValue = _a.defaultValue, hideUnselected = _a.hideUnselected, onChange = _a.onChange, props = __rest(_a, ["children", "isDisabled", "defaultValue", "hideUnselected", "onChange"]);
    var control = useFormContext().control;
    var field = useController({ control: control, name: props.name, defaultValue: defaultValue }).field;
    var _b = useRadioGroup(__assign(__assign({}, field), { onChange: function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            field.onChange.apply(field, args);
            if (onChange) {
                onChange.apply(void 0, args);
            }
        } })), getRootProps = _b.getRootProps, getRadioProps = _b.getRadioProps;
    return (_jsx(FormControl, __assign({}, props, { children: _jsx(radioGroupContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        , __assign({ 
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value: {
                getRadioProps: getRadioProps,
                isDisabled: isDisabled,
                hideUnselected: hideUnselected,
            } }, { children: _jsx(Box, __assign({}, getRootProps(), { children: children }), void 0) }), void 0) }), void 0));
};

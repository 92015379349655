import * as React from 'react';
import {
  Button,
  Text,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Link,
  Stack,
} from '@chakra-ui/react';

import { NotificationListing } from '@zap-onboard/api-client';

import { NotificationsList } from '../NotificationsList';
import { Link as ReactRouterLink } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  unread: NotificationListing[];
  onClose: () => void;
  markAllNotificationsAsRead: () => unknown;
};

export const NotificationsDrawer: React.FC<Props> = ({
  isOpen,
  unread,
  onClose,
  markAllNotificationsAsRead,
}) => (
  <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
    <DrawerOverlay>
      <DrawerContent data-testid="notificationsDrawer">
        <DrawerCloseButton />
        <DrawerHeader>
          <Stack>
            <Text>Notifications</Text>
            <Link
              fontWeight="bold"
              fontSize="sm"
              color="gray.600"
              to="/notification-settings"
              onClick={onClose}
              as={ReactRouterLink}
            >
              Settings
            </Link>
          </Stack>
        </DrawerHeader>

        <DrawerBody p={0}>
          <NotificationsList />
        </DrawerBody>

        <DrawerFooter>
          <HStack w="100%">
            {unread && unread?.length > 0 && (
              <Button
                mt={8}
                mr="auto"
                ml={6}
                variant="link"
                color="brand.blue"
                fontWeight="bold"
                onClick={markAllNotificationsAsRead}
              >
                Mark all as read
              </Button>
            )}
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </DrawerOverlay>
  </Drawer>
);

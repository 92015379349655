import React from 'react';
import { Redirect } from 'react-router-dom';
import { auth } from '../../auth';

export const HomePage: React.FC = () => {
  const manager = auth.useCurrentBusinessManager();
  const userProfile = auth.useProfile();

  if (!userProfile) {
    return <Redirect to="/login" />;
  }

  return manager ? (
    <Redirect to="/business/dashboard" />
  ) : (
    <Redirect to="/user/dashboard" />
  );
};

import { useHandler } from '../../useHandlerMutation';

export const useImportFlowDesignTemplate = ({
  afterSubmit,
}: {
  afterSubmit: () => unknown;
}) => {
  const [importTemplate, importIsLoading] = useHandler(
    (api) => api.flowDesigns().importFlowDesignTemplate,
    {
      invalidates: () => [['FLOW_DESIGNS']],
      always: {
        onSuccess: async () => {
          if (afterSubmit) {
            await afterSubmit();
          }
        },
      },
    },
  );

  return {
    importTemplate,
    importIsLoading,
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskStates = void 0;
/** DO NOT EDIT -- GENERATED FILE */
/* eslint-disable */
exports.TaskStates = [
    "META_SKIPPED",
    "BANK_ACCOUNT_SUBMITTED",
    "BANK_ACCOUNT_UNSUBMITTED",
    "CLIENT_VERIFICATION_UNSUBMITTED",
    "CLIENT_VERIFICATION_SUBMITTED",
    "CLIENT_VERIFICATION_APPROVED",
    "CLIENT_VERIFICATION_REJECTED",
    "DEPUTY_LINK_EMPLOYEE_LINKED",
    "DEPUTY_LINK_EMPLOYEE_UNLINKED",
    "ESIGN_SIGNED",
    "ESIGN_UNSIGNED",
    "ESIGN_WAITING_FOR_DOCUMENT",
    "FORM_UNSUBMITTED",
    "FORM_SUBMITTED",
    "PROFILE_COMPLETED",
    "PROFILE_PENDING",
    "PROOF_OF_IDENTITY_UNSUBMITTED",
    "PROOF_OF_IDENTITY_SUBMITTED",
    "PROOF_OF_IDENTITY_APPROVED",
    "PROOF_OF_IDENTITY_REJECTED",
    "RAW_TEXT_ACCEPTED",
    "RAW_TEXT_UNACCEPTED",
    "SUPER_CHOICE_UNSUBMITTED",
    "SUPER_CHOICE_SUBMITTED",
    "TFND_SUBMITTED",
    "TFND_UNSUBMITTED",
    "TEMPLATE_WAITING",
    "TEMPLATE_SIGNED",
    "VISA_DETAILS_SUBMITTED",
    "VISA_DETAILS_UNSUBMITTED",
    "VISA_DETAILS_COMPLETED",
    "XERO_SYNC_EMPLOYEE_UNSYNCED",
    "XERO_SYNC_EMPLOYEE_SYNCED",
];

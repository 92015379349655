"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSubmissionItemBase = exports.FormSchemaItem = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
class FormSchemaItem extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FormSchemaItem.prototype, "formSchemaItemId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FormSchemaItem.prototype, "type", void 0);
exports.FormSchemaItem = FormSchemaItem;
class FormSubmissionItemBase extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], FormSubmissionItemBase.prototype, "formSchemaItemId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FormSubmissionItemBase.prototype, "type", void 0);
exports.FormSubmissionItemBase = FormSubmissionItemBase;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskActionRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TaskAction_1 = require("./TaskAction");
const ResponseBase_1 = require("../../../ResponseBase");
class TaskActionRequest extends ResponseBase_1.ResponseBase {
    static fromArgs(type, ...args) {
        return TaskActionRequest.create({
            type,
            data: TaskAction_1.TaskActions[type].fromArgs(...args).asJSON(),
        });
    }
    getClass() {
        return (this._class || (this._class = TaskAction_1.TaskActions[this.type]));
    }
    getInstance() {
        return (this._instance || (this._instance = this.getClass().create(this.data)));
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(TaskAction_1.TaskActionType),
    (0, tslib_1.__metadata)("design:type", String)
], TaskActionRequest.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsObject)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", Object)
], TaskActionRequest.prototype, "data", void 0);
exports.TaskActionRequest = TaskActionRequest;

export var Heading = {
    baseStyle: {},
    sizes: {},
    variants: {
        page: {
            fontSize: '2xl',
            fontWeight: 'bold',
            my: '4',
        },
        section: {
            fontSize: 'lg',
            fontWeight: 'bold',
            my: '2',
        },
        subSection: {
            fontSize: 'md',
            fontWeight: 'bold',
            my: '2',
        },
    },
};

import { useState, useEffect } from 'react';
import deepEqual from 'fast-deep-equal';

export function useDebouncedValue<T>(value: T, ms: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (deepEqual(value, debouncedValue)) {
      return () => {};
    }
    const handler = setTimeout(() => setDebouncedValue(value), ms);
    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, ms]);

  return debouncedValue;
}

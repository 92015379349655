"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeputyClient = void 0;
const designed_1 = require("designed");
const handler_1 = require("../utils/handler");
const DeputyEmployeeDetails_1 = require("./models/DeputyEmployeeDetails");
class DeputyClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.connect = (0, handler_1.handler)(this, async (args) => await this.http
            .request()
            .to('/deputy/connection')
            .bodyJSON({ code: args.code })
            .post());
        this.disconnect = (0, handler_1.handler)(this, async () => await this.http.request().to('/deputy/connection').delete());
        this.getEmployeeDetails = (0, handler_1.handler)(this, async (request) => await this.http
            .request()
            .to('/deputy/employee-link')
            .optionalParams(request)
            .get(({ linkedEmployee, inferredEmployee }) => ({
            linkedEmployee: designed_1.Optional.of(linkedEmployee).map((v) => DeputyEmployeeDetails_1.DeputyEmployeeDetailsResponse.create(v)),
            inferredEmployee: designed_1.Optional.of(inferredEmployee).map((v) => DeputyEmployeeDetails_1.DeputyEmployeeDetailsResponse.create(v)),
        })));
        this.getLocations = (0, handler_1.handler)(this, async () => await this.http
            .request()
            .to('/deputy/locations')
            .get(({ locations }) => locations.map((location) => DeputyEmployeeDetails_1.DeputyLocationListResponse.create(location))));
        this.getEmployees = (0, handler_1.handler)(this, async () => await this.http
            .request()
            .to('/deputy/employees')
            .get(({ employees }) => employees.map((data) => DeputyEmployeeDetails_1.DeputyEmployeeDetailsResponse.create(data))));
    }
}
exports.DeputyClient = DeputyClient;

import * as React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import {
  AbsoluteDate,
  ContactNumber,
  Email,
  ProfileField,
  ProfileResponse,
} from '@zap-onboard/api-client';

import { inputs } from '@zap-onboard/web-components';
import { z } from '../../../../helpers/schema';
import { Address } from '../../../../components/Inputs/AddressInput';
import { DatePicker } from '../../../../components/Inputs/DateInput';
import { auth } from '../../../../auth';

type UserProfileFormProps = {
  isEditMode: boolean;
  onlyFields?: ProfileField[];
};

const baseSchema = {
  givenName: z.string().min(1).max(35),
  familyName: z.string().min(1).max(35),
  otherGivenName: z.string().max(35),
  email: z.valueObject(z.string(), Email),
  gender: z.string().min(1),
  primaryContactNumber: z.valueObject(z.string(), ContactNumber),
  secondaryContactNumber: z.optionalValueObject(z.string(), ContactNumber),
  address: Address.Schema,
  emergencyContactName: z.string().min(1).max(35),
  emergencyContactNumber: z.valueObject(z.string(), ContactNumber),
  emergencyContactRelationship: z.string().min(1).max(35),
  birthday: z
    .instanceof(AbsoluteDate, 'Invalid birthday')
    .refine((b: AbsoluteDate | undefined) => !b || b.isInPast(), {
      message: 'Cannot be born in the future',
    })
    .refine(
      (b) => {
        const { year } = b.prettyValues();
        const currentYear = new Date().getFullYear();
        const ageInYears = currentYear - Number(year);
        return ageInYears <= 120;
      },
      { message: 'Age must be less than 120' },
    ),
};

export namespace ProfileForm {
  export const Schema = z.object(baseSchema);

  export const PartialSchema = z
    .object({
      givenName: baseSchema.givenName.or(z.literal('')),
      familyName: baseSchema.familyName.or(z.literal('')),
      otherGivenName: baseSchema.otherGivenName.or(z.literal('')),
      gender: baseSchema.gender.or(z.literal('')),
      primaryContactNumber: baseSchema.primaryContactNumber.or(z.literal('')),
      secondaryContactNumber: baseSchema.secondaryContactNumber.or(
        z.literal(''),
      ),
      /**
       * TODO: Address validation not included here. Not sure how to manage that
       * with zod and the current input type.
       */
      address: z.any(),
      emergencyContactName: baseSchema.emergencyContactName.or(z.literal('')),
      emergencyContactNumber: baseSchema.emergencyContactNumber.or(
        z.literal(''),
      ),
      emergencyContactRelationship: baseSchema.emergencyContactRelationship.or(
        z.literal(''),
      ),
      birthday: baseSchema.birthday.or(z.undefined()),
    })
    .passthrough();

  export const getDefaultValue = (
    profile?: ProfileResponse,
  ): z.infer<typeof Schema> => {
    const p = profile ?? ({} as ProfileResponse);
    return {
      givenName: p.givenName ?? '',
      otherGivenName: p.otherGivenName ?? '',
      familyName: p.familyName ?? '',
      email: p.email.asJSON() ?? '',
      gender: p.gender ?? '',
      emergencyContactRelationship: p.emergencyContactRelationship ?? '',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      birthday: p.birthday!,
      emergencyContactNumber: p.emergencyContactNumber?.asJSON() ?? '',
      emergencyContactName: p.emergencyContactName ?? '',
      primaryContactNumber: p.primaryContactNumber?.asJSON() ?? '',
      secondaryContactNumber: p.secondaryContactNumber?.asJSON() ?? '',
      address: Address.getDefaultValue(p.address),
    };
  };

  export const SelectedSchema = ({ onlyFields }: UserProfileFormProps) => {
    const schema: Partial<typeof baseSchema> = {};

    const display = (field: Exclude<typeof onlyFields, undefined>[number]) =>
      !onlyFields || onlyFields.includes(field);

    if (display('name')) {
      schema.familyName = baseSchema.familyName;
      schema.givenName = baseSchema.givenName;
      schema.otherGivenName = baseSchema.otherGivenName;
    }

    if (display('email')) {
      schema.email = baseSchema.email;
    }

    if (display('gender')) {
      schema.gender = baseSchema.gender;
    }

    if (display('primaryContactNumber')) {
      schema.primaryContactNumber = baseSchema.primaryContactNumber;
      schema.secondaryContactNumber = baseSchema.secondaryContactNumber;
    }

    if (display('address')) {
      schema.address = baseSchema.address;
    }

    if (display('birthday')) {
      schema.birthday = baseSchema.birthday;
    }

    if (display('emergencyContact')) {
      schema.emergencyContactName = baseSchema.emergencyContactName;
      schema.emergencyContactNumber = baseSchema.emergencyContactNumber;
      schema.emergencyContactRelationship =
        baseSchema.emergencyContactRelationship;
    }

    return z.object(schema);
  };

  export const Component: React.FC<UserProfileFormProps> = ({
    isEditMode,
    onlyFields,
  }) => {
    const { errors } = useFormContext();

    const display = (field: Exclude<typeof onlyFields, undefined>[number]) =>
      !onlyFields || onlyFields.includes(field);
    const manager = auth.useCurrentBusinessManager();
    const emailMessage = manager
      ? 'Change email in the 3 dot menu on the users page'
      : 'Email changes must be done in the business admin panel';
    return (
      <form>
        <Stack spacing={4} data-testid="userProfileForm">
          {display('name') && (
            <>
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="givenName"
                label="Given name"
              />
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="otherGivenName"
                label="Other given name"
              />
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="familyName"
                label="Family name"
              />
            </>
          )}

          {display('email') && (
            <inputs.TextInput
              isDisabled={!isEditMode}
              isReadOnly
              name="email"
              label="Email"
              helper={isEditMode ? emailMessage : undefined}
            />
          )}

          {display('gender') && (
            <inputs.radio.Group
              name="gender"
              hideUnselected
              isDisabled={!isEditMode}
              label="Gender"
            >
              <Stack spacing={2}>
                <inputs.radio.Button value="female">Female</inputs.radio.Button>
                <inputs.radio.Button value="male">Male</inputs.radio.Button>
                <inputs.radio.Button value="non binary">
                  Non-binary
                </inputs.radio.Button>
                <inputs.radio.Button value="prefer not to say">
                  Prefer not to say
                </inputs.radio.Button>
              </Stack>
            </inputs.radio.Group>
          )}

          {display('primaryContactNumber') && (
            <>
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="primaryContactNumber"
                label="Primary contact number"
              />

              <inputs.TextInput
                isDisabled={!isEditMode}
                name="secondaryContactNumber"
                label="Secondary contact number"
              />
            </>
          )}

          {display('address') && (
            <Address.Input
              isDisabled={!isEditMode}
              name="address"
              label="Home address"
            />
          )}

          {display('birthday') && (
            <FormControl isInvalid={!!errors.birthday}>
              <FormLabel fontWeight="bold" m={0}>
                Date of birth
              </FormLabel>
              <DatePicker isDisabled={!isEditMode} name="birthday" />
              <FormErrorMessage>{errors.birthday?.message}</FormErrorMessage>
            </FormControl>
          )}

          {display('emergencyContact') && (
            <>
              <FormLabel fontWeight="bold">Emergency contact</FormLabel>
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="emergencyContactName"
                placeholder="Full name"
              />
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="emergencyContactNumber"
                placeholder="Contact number"
              />
              <inputs.TextInput
                isDisabled={!isEditMode}
                name="emergencyContactRelationship"
                placeholder="Relationship E.g. Father, Sister"
              />
            </>
          )}
        </Stack>
      </form>
    );
  };
}

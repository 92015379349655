import * as React from 'react';
import {
  Center,
  Text,
  Button,
  HStack,
  Icon,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { errs } from '@zap-onboard/errors';
import { XeroConnectionPurpose } from '@zap-onboard/api-client';
import { MdErrorOutline } from 'react-icons/md';
import { SiXero } from 'react-icons/si';
import { useAPI } from '../../services/API';
import { logError } from '../../libs/errorLib';
import { Spinner } from '../../components';
import { useXeroConsentConfig } from '../../hooks/data/xero/useXeroConsentConfig';
import { track } from '../../libs/track';
import { auth } from '../../auth';

const { useEffect, useState } = React;

// This is hacky but this component should only run once per "page load" which
// can race with the identity refresh call
let hasRun = false;

export const OAuthXeroConnect = () => {
  const api = useAPI();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const [failed, hasFailed] = useState<errs.DomainError>();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');

  useEffect(() => {
    (async () => {
      if (hasRun) {
        return;
      }
      if (!code || !state) {
        hasFailed(errs.ClientError.create('Invalid request'));
        return;
      }
      hasRun = true;
      await api
        .xero()
        .exchangeCode({ code, state })
        .tap(
          (r) => {
            let description = 'Completed Xero auth';
            if (r.purpose === XeroConnectionPurpose.SIGN_IN) {
              track.event('Xero Login');
              description = 'Signed in from Xero';
            } else if (r.purpose === XeroConnectionPurpose.SIGN_UP) {
              track.event('Xero Signup');
              description = 'Welcome to Canyou!';
            } else if (r.purpose === XeroConnectionPurpose.CONNECT_ORG) {
              track.event('Xero Account Connected');
              history.push(`/business/settings?openXeroModal=true`);
              description = 'Connected Xero Organisation';
            }

            toast({
              description,
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
          },
          (err) => {
            hasFailed(err);
            logError(err);
          },
        );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!failed || (
        <Center minH="100vh" minW="100vw">
          <Spinner />
        </Center>
      )}
      {!!failed && <Failed error={failed} />}
    </>
  );
};

const Failed = ({ error }: { error: errs.DomainError }) => {
  const history = useHistory();
  const userProfile = auth.useProfile();
  const {
    startXeroLogin,
    startXeroSignup,
    isLoadingXeroConsentConfig,
    startXeroConnection,
  } = useXeroConsentConfig();

  return (
    <Center
      bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
      minH="100vh"
      minW="100vw"
    >
      <Center
        layerStyle="base"
        minH="400px"
        minW={{
          base: '90%',
          md: '70%',
          lg: '50%',
          xl: '40%',
        }}
        p={3}
        m={6}
      >
        <Stack spacing={2} align="center">
          <HStack>
            <Icon as={MdErrorOutline} boxSize="1.5em" color="red.400" />
            <Text fontWeight="bold">
              Could not establish connection to Xero
            </Text>
          </HStack>
          <Text>{error.message}</Text>

          {userProfile && (
            <Button
              isDisabled={isLoadingXeroConsentConfig}
              isLoading={isLoadingXeroConsentConfig}
              leftIcon={<SiXero size="1.2rem" />}
              onClick={startXeroConnection}
              variant="xero"
            >
              Connect to Xero
            </Button>
          )}

          {!userProfile && (
            <Button
              isDisabled={isLoadingXeroConsentConfig}
              isLoading={isLoadingXeroConsentConfig}
              variant="xero"
              leftIcon={<SiXero size="1.2rem" />}
              onClick={startXeroLogin}
            >
              Sign in with Xero
            </Button>
          )}

          {!userProfile && (
            <Button
              isDisabled={isLoadingXeroConsentConfig}
              isLoading={isLoadingXeroConsentConfig}
              variant="xero"
              leftIcon={<SiXero size="1.2rem" />}
              onClick={startXeroSignup}
            >
              Sign up with Xero
            </Button>
          )}

          {!userProfile && (
            <Button variant="link" onClick={() => history.push('/login')}>
              Back to Login
            </Button>
          )}

          {userProfile && (
            <Button variant="link" onClick={() => history.push('/')}>
              Back to Dashboard
            </Button>
          )}
        </Stack>
      </Center>
    </Center>
  );
};

import * as React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';

type Props = {
  buttonText: string;
  onClose: () => unknown;
  isLoading: boolean;
};

export const FormActions: React.FC<Props> = ({
  buttonText,
  onClose,
  isLoading,
}) => (
  <ButtonGroup pt={2} justifyContent="flex-end">
    <Button type="submit" isLoading={isLoading} isDisabled={isLoading}>
      {buttonText}
    </Button>
    <Button variant="outline" onClick={onClose}>
      Cancel
    </Button>
  </ButtonGroup>
);

import { flow } from '@zap-onboard/api-client';
import { useMemo } from 'react';
import { auth } from '../../../auth';
import {
  ActionItemProps,
  ActionItemType,
} from '../../../components/ActionItemList/ActionItem';
import { QueryConfig, useHandlerQuery } from '../../useHandlerQuery';

export const useMyBusinessFlows = (
  args: Parameters<flow.Client['getMyBusinessFlows']>[0],
  ovr?: Partial<QueryConfig<flow.GetFlows.Response>>,
) =>
  useHandlerQuery((api) => api.flows().getMyBusinessFlows(args), {
    key: ['BUSINESS_FLOWS', args],
    ...ovr,
  });

export const useMyFlows = (
  args: Parameters<flow.Client['getMyFlows']>[0],
  ovr?: Partial<QueryConfig<flow.GetFlows.Response>>,
) =>
  useHandlerQuery((api) => api.flows().getMyFlows(args), {
    key: ['MY_FLOWS', args],
    ...ovr,
  });

/**
 * Currently this is only used to contextualize the user dashboard results
 */
class ContextualFlow {
  constructor(
    private data: { flow: flow.GetFlows.Flow; currentUserId: string },
  ) {}

  asActionItem(): ActionItemProps {
    const { flow: f } = this.data;
    return {
      id: f.flowId,
      flowId: f.flowId,
      memberId: f.memberId,
      title: f.name,
      subtitle: this.isSubject()
        ? f.businessName
        : `${f.businessName}, ${f.userName}`,
      status: f.getTag(),
      statusColor: `flowStatus.${f.getStatus()}`,
      progress: f.completion(),
      type: ActionItemType.WORKFLOW,
      context: 'user',
    };
  }

  isSubject() {
    const { currentUserId, flow } = this.data;
    return currentUserId === flow.userId;
  }
}

export const useContextualFlows = (flows: ReturnType<typeof useMyFlows>) => {
  const userProfile = auth.useProfile();

  const contextual = useMemo(
    () =>
      userProfile
        ? flows.data?.flows.map(
            (flow) =>
              new ContextualFlow({ flow, currentUserId: userProfile?.userId }),
          )
        : undefined,
    [flows.data, userProfile],
  );

  return { ...flows, data: contextual };
};

import * as React from 'react';
import {
  Text,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Input,
  Heading,
  Stack,
  FormLabel,
  VisuallyHidden,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Email, GoogleConnectPurpose } from '@zap-onboard/api-client';
import { useAPI } from '../../../services/API';
import { onError } from '../../../libs/errorLib';
import { track } from '../../../libs/track';
import { GoogleConnectButton } from '../../../components/GoogleConnectButton';
import { useGoogleConnect } from '../../../hooks/useGoogleConnect';

type EmailSectionProps = {
  businessId: string;
  nextStage: () => void;
  setEmail: (email: string) => void;
};

type FormInputs = {
  email: string;
};

export const EmailSection: React.FC<EmailSectionProps> = ({
  businessId,
  nextStage,
  setEmail,
}) => {
  const api = useAPI();
  const {
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { email } = data;

    track.event('Requested Access to Business', { businessId });
    await api
      .identity()
      .magicLoginOrRegister({
        email,
        businessId,
      })
      .tap(() => {
        setEmail(email);
        nextStage();
      }, onError);
  };
  const { connectGoogle } = useGoogleConnect({
    purpose: GoogleConnectPurpose.SIGN_UP,
  });

  return (
    <Stack spacing={4}>
      <Heading size="md" textAlign="center">
        Account registration
      </Heading>
      <Text textAlign="center">Provide an email for account setup</Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <Stack spacing={0}>
            <VisuallyHidden>
              <FormLabel fontSize="sm">Email</FormLabel>
            </VisuallyHidden>
            <FormControl isInvalid={!!errors.email}>
              <Input
                type="email"
                name="email"
                placeholder="Your email"
                ref={register({
                  required: 'Email required',
                  validate: Email.validationMessage,
                })}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <Center>
            <Button type="submit" isLoading={isSubmitting} w="100%">
              Send Email
            </Button>
          </Center>
          <Text
            color="gray.500"
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
            display="block"
          >
            OR
          </Text>
          <GoogleConnectButton onIdentityTokenReturned={connectGoogle} />
        </Stack>
      </form>
    </Stack>
  );
};

import * as React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { get, useFormContext } from 'react-hook-form';

import {
  TextQuestionFormSchemaItem,
  TextQuestionFormSubmissionItem,
} from '@zap-onboard/api-client';

type FormTaskTextInputProps = {
  schemaItem: TextQuestionFormSchemaItem;
  submissionItem: TextQuestionFormSubmissionItem | undefined;
  disabled: boolean;
  getPath: (...paths: string[]) => string;
};

export const FormTaskTextInput: React.FC<FormTaskTextInputProps> = ({
  schemaItem,
  getPath,
  disabled,
  submissionItem,
}) => {
  const { register, errors } = useFormContext();

  const requiredMessage = schemaItem.required
    ? 'this question is required'
    : undefined;

  return (
    <>
      <input
        type="hidden"
        ref={register()}
        value={schemaItem.type}
        name={getPath('type')}
      />
      <FormControl my="2" isInvalid={!!get(errors, getPath('value'))}>
        <Stack spacing={1}>
          <FormLabel m={0}>{schemaItem.title}</FormLabel>
          <Stack spacing={2}>
            {schemaItem.description ? (
              <Text>{schemaItem.description}</Text>
            ) : null}
            <Input
              placeholder="Your answer"
              as={schemaItem.size === 'paragraph' ? Textarea : Input}
              defaultValue={submissionItem?.value}
              isDisabled={disabled}
              name={getPath('value')}
              ref={register({ required: requiredMessage })}
            />
          </Stack>
        </Stack>
        <FormErrorMessage>
          {get(errors, getPath('value', 'message'))}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

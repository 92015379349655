"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroSyncEmployeeTask = exports.XeroSyncEmployeeTaskSyncDetails = exports.XeroSyncDetailsPrefillResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const UpdateOrCreateUserInXeroRequest_1 = require("../../../xero/UpdateOrCreateUserInXeroRequest");
const BaseTask_1 = require("../Task/BaseTask");
class XeroSyncDetailsPrefillResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(UpdateOrCreateUserInXeroRequest_1.AdditionalXeroTaxDetailsRequest),
    (0, tslib_1.__metadata)("design:type", UpdateOrCreateUserInXeroRequest_1.AdditionalXeroTaxDetailsRequest)
], XeroSyncDetailsPrefillResponse.prototype, "additionalTaxDetails", void 0);
exports.XeroSyncDetailsPrefillResponse = XeroSyncDetailsPrefillResponse;
class XeroSyncEmployeeTaskSyncDetails extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeeTaskSyncDetails.prototype, "isXeroLinkedToBusiness", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeeTaskSyncDetails.prototype, "employeeTaxDetailsComplete", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeeTaskSyncDetails.prototype, "employeeBankDetailsComplete", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeeTaskSyncDetails.prototype, "employeeSuperDetailsComplete", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeeTaskSyncDetails.prototype, "employeeProfileComplete", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeeTaskSyncDetails.prototype, "employerHasNominatedSuper", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(XeroSyncDetailsPrefillResponse),
    (0, tslib_1.__metadata)("design:type", XeroSyncDetailsPrefillResponse)
], XeroSyncEmployeeTaskSyncDetails.prototype, "prefill", void 0);
exports.XeroSyncEmployeeTaskSyncDetails = XeroSyncEmployeeTaskSyncDetails;
class XeroSyncEmployeeTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(XeroSyncEmployeeTaskSyncDetails),
    (0, tslib_1.__metadata)("design:type", XeroSyncEmployeeTaskSyncDetails)
], XeroSyncEmployeeTask.prototype, "syncDetails", void 0);
exports.XeroSyncEmployeeTask = XeroSyncEmployeeTask;

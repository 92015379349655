"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOverview = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const GetSubmission_1 = require("./GetSubmission");
var GetOverview;
(function (GetOverview) {
    class ForBusinessRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], ForBusinessRequest.prototype, "historical", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], ForBusinessRequest.prototype, "required", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ForBusinessRequest.prototype, "certificationDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ForBusinessRequest.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], ForBusinessRequest.prototype, "includeArchivedMembers", void 0);
    GetOverview.ForBusinessRequest = ForBusinessRequest;
    class ForMemberRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], ForMemberRequest.prototype, "historical", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsBoolean)(),
        (0, tslib_1.__metadata)("design:type", Boolean)
    ], ForMemberRequest.prototype, "required", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ForMemberRequest.prototype, "certificationDesignId", void 0);
    GetOverview.ForMemberRequest = ForMemberRequest;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: GetSubmission_1.GetSubmission.Submission.Union }),
        (0, class_validator_1.IsInstance)(GetSubmission_1.GetSubmission.Submission.Union, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "submissions", void 0);
    GetOverview.Response = Response;
})(GetOverview = exports.GetOverview || (exports.GetOverview = {}));

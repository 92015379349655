"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperChoiceEmployeeSubmission = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const TFN_1 = require("../../../utils/TFN");
const SuperChoice_1 = require("./SuperChoice");
class SuperChoiceEmployeeSubmission extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployeeSubmission.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(20),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployeeSubmission.prototype, "employeeIdentificationNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(TFN_1.IndividualTFN),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", TFN_1.IndividualTFN)
], SuperChoiceEmployeeSubmission.prototype, "tfn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(SuperChoice_1.SuperChoice),
    (0, tslib_1.__metadata)("design:type", SuperChoice_1.SuperChoice)
], SuperChoiceEmployeeSubmission.prototype, "superChoice", void 0);
exports.SuperChoiceEmployeeSubmission = SuperChoiceEmployeeSubmission;

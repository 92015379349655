"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTextActions = exports.RawTextAcceptAction = exports.RawTextActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
var RawTextActionType;
(function (RawTextActionType) {
    RawTextActionType["RAW_TEXT_ACCEPT"] = "RAW_TEXT_ACCEPT";
})(RawTextActionType = exports.RawTextActionType || (exports.RawTextActionType = {}));
class RawTextAcceptAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return RawTextAcceptAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], RawTextAcceptAction.prototype, "taskId", void 0);
exports.RawTextAcceptAction = RawTextAcceptAction;
exports.RawTextActions = {
    [RawTextActionType.RAW_TEXT_ACCEPT]: RawTextAcceptAction,
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
import { Center, Divider, GridItem, Fade, Image as CImage, Progress, Button, TabList, Tab, TabPanels, TabPanel, Heading, HStack, Spacer, Stack, Tag, TagLabel, Grid, Text, Box, Flex, Tabs, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from '../menu';
var PADDING_SIZE = '16px';
export var Card;
(function (Card) {
    Card.Layout = React.forwardRef(function (_a, ref) {
        var children = _a.children, _b = _a.width, width = _b === void 0 ? '480px' : _b, sublink = _a.sublink, actions = _a.actions, header = _a.header, _heading = _a._heading, _grid = _a._grid, _wrapper = _a._wrapper, other = __rest(_a, ["children", "width", "sublink", "actions", "header", "_heading", "_grid", "_wrapper"]);
        return (_jsxs(Stack, __assign({ ref: ref, spacing: { base: 4, md: 2 } }, _wrapper, other, { children: [(header || actions) && (_jsxs(Flex, __assign({ justify: "space-between", direction: { base: 'column', sm: 'row' }, alignItems: "center" }, { children: [_jsx(Heading, __assign({ variant: "section" }, _heading, { children: header }), void 0), _jsx(Box, { children: actions }, void 0)] }), void 0)), _jsxs(Box, { children: [_jsx(Grid, __assign({ gap: 4, gridTemplateColumns: {
                                base: 'repeat(auto-fill,minmax(220px, 1fr))',
                                md: "repeat(auto-fill,minmax(".concat(width, ", 1fr))"),
                            } }, _grid, { children: children }), void 0), sublink && (_jsx(Center, __assign({ pt: 6 }, { children: _jsx(Button, __assign({ fontWeight: "bold", color: "brand.blue", variant: "link", onClick: sublink.onClick }, { children: sublink.text }), void 0) }), void 0))] }, void 0)] }), void 0));
    });
    Card.TabLayout = function (_a) {
        var tabs = _a.tabs, width = _a.width, header = _a.header, actions = _a.actions, isLazy = _a.isLazy, _wrapper = _a._wrapper, _box = _a._box, _grid = _a._grid;
        return (_jsxs(Stack, __assign({ spacing: { base: 4, md: 2 } }, _wrapper, { children: [header && (_jsxs(Flex, __assign({ justify: "space-between", direction: { base: 'column', sm: 'row' } }, { children: [_jsx(Heading, __assign({ variant: "section" }, { children: header }), void 0), _jsx(Box, { children: actions }, void 0)] }), void 0)), _jsx(Box, __assign({}, _box, { children: _jsxs(Tabs, __assign({ isLazy: isLazy }, { children: [_jsx(TabList, { children: tabs.map(function (t) { return (!t ? null : _jsx(Tab, { children: t.tab }, t.key)); }) }, void 0), _jsx(TabPanels, { children: tabs.map(function (t) {
                                    return !t ? null : (_jsx(TabPanel, __assign({ as: Grid, gap: 4, gridTemplateColumns: {
                                            base: 'repeat(auto-fill,minmax(230px, 1fr))',
                                            md: "repeat(auto-fill,minmax(".concat(width !== null && width !== void 0 ? width : '230px', ", 1fr))"),
                                        } }, _grid, { justify: "space-between", direction: { base: 'column', sm: 'row' }, p: 4 }, { children: t.page }), t.key));
                                }) }, void 0)] }), void 0) }), void 0)] }), void 0));
    };
    Card.Item = React.forwardRef(function (_a, ref) {
        var children = _a.children, link = _a.link, onClick = _a.onClick, _wrapper = _a._wrapper, rest = __rest(_a, ["children", "link", "onClick", "_wrapper"]);
        var history = useHistory();
        var render = function (children) { return (_jsx(GridItem, __assign({ ref: ref, overflow: "hidden", layerStyle: "base" }, _wrapper, rest, { children: children }), void 0)); };
        if (onClick) {
            render = function (children) { return (_jsx(GridItem, __assign({ ref: ref, overflow: "hidden", layerStyle: "base", onClick: onClick, role: "button", tabIndex: 0, _hover: { layerStyle: 'hoveredOutline' } }, _wrapper, rest, { children: children }), void 0)); };
        }
        if (link) {
            render = function (children) { return (_jsx(GridItem, __assign({ layerStyle: "base", overflow: "hidden", onClick: function () { return history.push(link); }, onAuxClick: function () {
                    return window.open("".concat(window.location.origin).concat(link), '_blank');
                }, role: "button", tabIndex: 0, _hover: { layerStyle: 'hoveredOutline' } }, _wrapper, rest, { children: children }), void 0)); };
        }
        return render(_jsx(Stack, __assign({ h: "100%", margin: "auto", spacing: 4, p: PADDING_SIZE }, { children: children }), void 0));
    });
    Card.Top = function (_a) {
        var menu = _a.menu, _menu = _a._menu, tag = _a.tag, _tag = _a._tag, subtext = _a.subtext, header = _a.header;
        var getSubtext = function (display) {
            return (_jsxs(_Fragment, { children: [typeof subtext === 'string' && (_jsx(Text, __assign({ fontSize: "sm", display: display }, { children: subtext }), void 0)), typeof subtext === 'object' && (_jsx(Box, __assign({ display: display }, { children: subtext }), void 0))] }, void 0));
        };
        var tagEl = tag ? (_jsx(Box, { children: _jsx(Tag, __assign({ size: "sm", colorScheme: tag.color }, _tag, { children: _jsx(TagLabel, __assign({ textTransform: "uppercase", fontWeight: "bold", fontSize: "xs" }, { children: tag.text }), void 0) }), void 0) }, void 0)) : undefined;
        return (_jsxs(Stack, { children: [_jsxs(HStack, __assign({ minH: 8 }, { children: [header && _jsx(Text, __assign({ fontWeight: "bold" }, { children: header }), void 0), tag && !header && tagEl, _jsx(Spacer, {}, void 0), getSubtext({ base: 'none', md: 'inherit' }), _jsx(Box, __assign({ onClick: function (e) { return e.stopPropagation(); } }, { children: menu && (_jsx(Menu, __assign({ menuItems: menu, _button: { size: 28 } }, _menu), void 0)) }), void 0)] }), void 0), tag && header && tagEl, getSubtext({ base: 'inherit', md: 'none' })] }, void 0));
    };
    Card.Header = function (_a) {
        var children = _a.children, subheader = _a.subheader, _header = _a._header;
        return (_jsxs(Stack, __assign({ m: 8 }, { children: [_jsx(Heading, __assign({ m: 0, size: "sm" }, _header, { children: children }), void 0), subheader && (_jsx(Text, __assign({ fontSize: "sm", as: "span", m: 0 }, { children: subheader }), void 0))] }), void 0));
    };
    Card.BreakPadding = function (_a) {
        var children = _a.children;
        return (_jsx(Box, __assign({ width: "100%" }, { children: _jsx(Box, __assign({ w: "CALC(100% + ( ".concat(PADDING_SIZE, " * 2 ))"), ml: "-".concat(PADDING_SIZE) }, { children: children }), void 0) }), void 0));
    };
    Card.Percentage = function (_a) {
        var value = _a.value, label = _a.label, _b = _a.colorScheme, colorScheme = _b === void 0 ? 'green' : _b;
        return (_jsxs(Box, { children: [_jsx(Text, __assign({ fontSize: "sm", color: "brand.dark-gray" }, { children: label }), void 0), _jsx(Progress, { flex: "1 1 auto", minWidth: "100px", borderRadius: "4px", colorScheme: colorScheme, value: value }, void 0)] }, void 0));
    };
    Card.Action = function (_a) {
        var _b = _a.cta, cta = _b === void 0 ? 'View' : _b, onClick = _a.onClick, rightAction = _a.rightAction, _c = _a.disableSpacer, disableSpacer = _c === void 0 ? false : _c;
        return (_jsxs(_Fragment, { children: [!disableSpacer && _jsx(Spacer, {}, void 0), _jsx(Divider, {}, void 0), _jsxs(HStack, { children: [typeof cta === 'string' && (_jsx(Button, __assign({ size: "sm", onClick: function (e) {
                                if (onClick) {
                                    onClick();
                                }
                                e.stopPropagation();
                            }, variant: "outline" }, { children: cta }), void 0)), typeof cta === 'object' && _jsx(_Fragment, { children: cta }, void 0), _jsx(Spacer, {}, void 0), _jsx(Box, { children: rightAction }, void 0)] }, void 0)] }, void 0));
    };
    Card.Image = function (_a) {
        var src = _a.src, size = _a.size;
        var _b = useState(false), loaded = _b[0], setLoaded = _b[1];
        return (_jsx(Center, __assign({ h: size, bg: "brand.light-gray" }, { children: _jsx(Fade, __assign({ in: loaded }, { children: _jsx(CImage, { src: src, display: loaded ? undefined : 'none', objectFit: "cover", alt: "file thumbnail", boxSize: size, onLoad: function () { return setLoaded(true); } }, void 0) }), void 0) }), void 0));
    };
})(Card || (Card = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevActionType = void 0;
var DevActionType;
(function (DevActionType) {
    DevActionType["SEED"] = "SEED";
    DevActionType["LOGIN_DIRECTLY_BY_EMAIL"] = "LOGIN_DIRECTLY_BY_EMAIL";
    DevActionType["GET_USERS"] = "GET_USERS";
    DevActionType["SEND_NOTIFICATION"] = "SEND_NOTIFICATION";
    DevActionType["MORNING_CRON"] = "MORNING_CRON";
    DevActionType["FIFTEEN_CRON"] = "FIFTEEN_CRON";
    DevActionType["XERO_BILLING_WEBHOOK"] = "XERO_BILLING_WEBHOOK";
    DevActionType["SAVE_DB"] = "SAVE_DB";
    DevActionType["LOAD_DB"] = "LOAD_DB";
})(DevActionType = exports.DevActionType || (exports.DevActionType = {}));

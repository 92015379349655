/* eslint-disable @typescript-eslint/no-explicit-any */
import { AsyncResult } from 'designed';
import { QueryKeys } from './QueryKeys';
import { useAPI } from '../services/API';
import { APIClient } from '@zap-onboard/api-client';
import { useQueryInvalidator } from './useHandlerQuery';
import { useCallback, useMemo, useState } from 'react';
import { useIsComponentMounted } from './useIsComponentMounted';
import { onError } from '../libs/errorLib';

type Handler = (...args: any) => AsyncResult<unknown, Error>;

type InferSuccess<T> = T extends AsyncResult<infer RT, Error> ? RT : unknown;
type InferFailure<T> = T extends AsyncResult<unknown, infer RT> ? RT : unknown;

interface HandlerWrapperArgs<HT extends Handler> {
  invalidates?: (
    ...args: Parameters<HT>
  ) => readonly (Partial<QueryKeys> | undefined)[];

  always?: {
    before?: (...args: Parameters<HT>) => unknown;
    onSuccess?: (
      value: InferSuccess<ReturnType<HT>>,
      ...args: Parameters<HT>
    ) => unknown;
    onError?: (
      err: InferFailure<ReturnType<HT>>,
      ...args: Parameters<HT>
    ) => unknown;
  };

  whenMounted?: {
    before?: (...args: Parameters<HT>) => unknown;
    onSuccess?: (
      value: InferSuccess<ReturnType<HT>>,
      ...args: Parameters<HT>
    ) => unknown;
    onError?: (
      err: InferFailure<ReturnType<HT>>,
      ...args: Parameters<HT>
    ) => unknown;
  };
}

export const useHandler = <HT extends Handler>(
  getHandler: (api: APIClient) => HT,
  config: HandlerWrapperArgs<HT>,
) => {
  const { invalidates, whenMounted = {}, always = {} } = config;

  /**
   * Fallback to displaying an error toast if we aren't managing the response
   */
  always.onError = always.onError ?? (onError as any);

  const api = useAPI();
  const invalidate = useQueryInvalidator();
  const isMounted = useIsComponentMounted();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useMemo(() => getHandler(api), [api]);

  const wrapped = useCallback(
    (...args: Parameters<HT>): ReturnType<HT> | undefined => {
      if (isLoading) {
        return undefined;
      }
      setIsLoading(true);

      if (always.before) {
        always.before(...args);
      }
      if (isMounted() && whenMounted.before) {
        whenMounted.before(...args);
      }

      const result = handler(...args);

      result.map(
        (rt) => {
          if (invalidates) {
            const invalidations = invalidates(...args);
            invalidate(
              ...(invalidations.filter((v) => v) as Partial<QueryKeys>[]),
            );
          }

          if (always.onSuccess) {
            always.onSuccess(rt as any, ...args);
          }
          if (isMounted() && whenMounted.onSuccess) {
            whenMounted.onSuccess(rt as any, ...args);
          }
          return rt;
        },
        (err) => {
          if (always.onError) {
            always.onError(err as any, ...args);
          }
          if (whenMounted.onError && isMounted()) {
            whenMounted.onError(err as any, ...args);
          }
          return err;
        },
      );

      result.then((result) => {
        setIsLoading(false);
        return result;
      });

      return result as ReturnType<HT>;
    },
    [
      always,
      handler,
      invalidate,
      invalidates,
      isLoading,
      isMounted,
      whenMounted,
    ],
  );

  return [wrapped, isLoading] as const;
};

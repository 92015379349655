"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TFNDTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const BaseTask_1 = require("../Task/BaseTask");
const TFNDPayeeSubmission_1 = require("./TFNDPayeeSubmission");
class TFNDTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(TFNDPayeeSubmission_1.TFNDPayeeSubmission),
    (0, tslib_1.__metadata)("design:type", TFNDPayeeSubmission_1.TFNDPayeeSubmission)
], TFNDTask.prototype, "payeeSubmission", void 0);
exports.TFNDTask = TFNDTask;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisaClient = exports.CreateVisaCheckRequest = exports.CreateVisaConfigRequest = exports.UpdateVisaConfigRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const models_1 = require("./models");
const ResponseBase_1 = require("../ResponseBase");
const handler_1 = require("../utils/handler");
const utils_1 = require("../utils");
class UpdateVisaConfigRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], UpdateVisaConfigRequest.prototype, "expiryNotificationDays", void 0);
exports.UpdateVisaConfigRequest = UpdateVisaConfigRequest;
class CreateVisaConfigRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], CreateVisaConfigRequest.prototype, "expiryNotificationDays", void 0);
exports.CreateVisaConfigRequest = CreateVisaConfigRequest;
class CreateVisaCheckRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateVisaCheckRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateVisaCheckRequest.prototype, "givenNames", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateVisaCheckRequest.prototype, "familyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], CreateVisaCheckRequest.prototype, "dateOfBirth", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateVisaCheckRequest.prototype, "documentId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(models_1.visaCheckDocumentType.map((type) => type.code)),
    (0, tslib_1.__metadata)("design:type", String)
], CreateVisaCheckRequest.prototype, "documentType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Country),
    (0, tslib_1.__metadata)("design:type", utils_1.Country)
], CreateVisaCheckRequest.prototype, "countryOfIssue", void 0);
exports.CreateVisaCheckRequest = CreateVisaCheckRequest;
class VisaClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.createVisaCheck = (0, handler_1.handler)(this, async (visaCheck) => this.http
            .request()
            .to('business/visa/checks')
            .bodyJSON(CreateVisaCheckRequest.create(visaCheck))
            .post());
        this.getVisaChecksForMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`business/visa/checks/${args.memberId}`)
            .get((d) => models_1.VisaCheckList.create(d)));
        this.getVisaDetailsForMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`business/visa/details/${args.memberId}`)
            .get((d) => models_1.VisaDetails.create(d)));
        this.getVisaConfig = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('business/visa/config')
            .get((d) => models_1.VisaConfig.create(d)));
        this.createVisaConfig = (0, handler_1.handler)(this, async (config) => this.http
            .request()
            .to('business/visa/config')
            .bodyJSON(CreateVisaConfigRequest.create(config))
            .post());
        this.updateVisaConfig = (0, handler_1.handler)(this, async (config) => this.http
            .request()
            .to('business/visa/config')
            .bodyJSON(UpdateVisaConfigRequest.create(config))
            .put());
    }
}
exports.VisaClient = VisaClient;

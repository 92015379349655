import * as React from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { AbsoluteDate } from '@zap-onboard/api-client';

import { DatePicker, InfoBox } from '../components';
import { Entity } from 'designed';

export const CertExpiryDateInput: React.FC = () => {
  const { errors, watch } = useFormContext();
  const watchExpiryDate: Entity.Attributes<AbsoluteDate> | undefined =
    watch('expiryDate');

  const expiryDateWarning = () => {
    if (
      watchExpiryDate
        ? AbsoluteDate.create(watchExpiryDate).isInPast()
        : undefined
    ) {
      return (
        <InfoBox
          heading="Expired certification"
          message="The given date has already expired"
          iconColor="brand.dark-gray"
          textColor="brand.dark-gray"
          borderColor="brand.dark-gray"
          p={2}
          my={2}
          mx={0}
        />
      );
    }

    return null;
  };

  return (
    <FormControl isInvalid={!!errors.expiryDate}>
      <FormLabel fontWeight="bold">Expiry date</FormLabel>
      {expiryDateWarning()}
      <DatePicker aria-label="expiry date" name="expiryDate" />
      <FormErrorMessage>{errors.expiryDate?.message}</FormErrorMessage>
    </FormControl>
  );
};

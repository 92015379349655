"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateTaskDesign = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TaskDesign_1 = require("../TaskDesign/TaskDesign");
const TaskDesignTypes_1 = require("../TaskDesign/TaskDesignTypes");
class TemplateTaskDesign extends TaskDesign_1.TaskDesign {
    toCreateArgs() {
        const { name, type, taskDesignId, templateId } = this;
        return {
            name,
            type,
            taskDesignId,
            templateId,
        };
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskDesign.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateTaskDesign.prototype, "templateId", void 0);
exports.TemplateTaskDesign = TemplateTaskDesign;

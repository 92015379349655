import { track } from '../../../libs/track';

type WorkArgs = {
  workType: 'Workflows' | 'Certifications';
};

type AdministrativeWorkArgs = {
  workType: 'Administrative';
  workDetail: 'Xero' | 'Deputy' | 'Certification review';
};

export function trackWorkCompletion(data: WorkArgs | AdministrativeWorkArgs) {
  track.event('Work Completed', {
    ...data,
  });
  track.people.set({
    workLastCompleted: new Date(),
  });
  track.people.setOnce({
    workFirstCompleted: new Date(),
  });
  track.people.increment({
    totalWorkCompleted: 1,
  });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
import { Center, Box, Image as CImage, VStack, HStack, Text as CText, Fade, Spacer, Box as CBox, } from '@chakra-ui/react';
import { useState } from 'react';
import { Menu } from '../menu/Menu';
export var info;
(function (info) {
    info.Header = function (_a) {
        var children = _a.children, rightAction = _a.rightAction, menu = _a.menu;
        return (_jsxs(HStack, { children: [_jsx(CText, __assign({ fontWeight: "bold" }, { children: children }), void 0), _jsx(Spacer, {}, void 0), rightAction, menu && _jsx(Menu, { _button: { size: 24 }, menuItems: menu }, void 0)] }, void 0));
    };
    info.Layout = function (_a) {
        var children = _a.children;
        return (_jsx(VStack, __assign({ spacing: 4, align: "stretch", bg: "gray.50", borderRadius: "md", p: 5 }, { children: children }), void 0));
    };
    info.Image = function (_a) {
        var src = _a.src, size = _a.size, title = _a.title;
        var _b = useState(false), loaded = _b[0], setLoaded = _b[1];
        return (_jsxs(Box, { children: [_jsx(CText, __assign({ fontWeight: "bold", color: "gray.600" }, { children: title }), void 0), _jsx(Center, __assign({ h: size, bg: "brand.light-gray" }, { children: _jsx(Fade, __assign({ in: loaded }, { children: _jsx(CImage, { src: src, display: loaded ? undefined : 'none', objectFit: "cover", alt: "file thumbnail", boxSize: size, onLoad: function () { return setLoaded(true); } }, void 0) }), void 0) }), void 0)] }, void 0));
    };
    info.Text = function (_a) {
        var title = _a.title, children = _a.children;
        return (_jsxs(CBox, { children: [_jsx(CText, __assign({ fontWeight: "bold", color: "gray.600" }, { children: title }), void 0), _jsx(CText, { children: children }, void 0)] }, void 0));
    };
})(info || (info = {}));

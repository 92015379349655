import { FileClaim } from '@zap-onboard/api-client';
import {
  Center,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react';
import { FormLayout } from './FormLayout';
import { useFile } from 'src/hooks/data/file/useFile';
import { Spinner } from 'src/components/Spinner/Spinner';
import { useRef } from 'react';
import { PDFFormGroup, PDFSerializedFormData } from 'src/pdfme/usePDFState';

export const FormModal = (args: {
  disclosure: ReturnType<typeof useDisclosure>;
  currentGroup: PDFFormGroup;
  submittedGroups: PDFFormGroup[];
  pdf?: FileClaim;
  onSave: React.ComponentProps<typeof FormLayout>['onSave'];
  renderSubmitModal: (
    args: { onClose: () => void } & PDFSerializedFormData,
  ) => React.ReactElement;
}) => {
  const { isOpen, onClose } = args.disclosure;

  const file = useFile(args.pdf);

  const focusRef = useRef<HTMLElement>(null);

  return (
    <Modal
      size="full"
      initialFocusRef={focusRef}
      isOpen={isOpen}
      onClose={() => {}}
      closeOnEsc={false}
    >
      <ModalContent rounded={0}>
        <ModalBody p={0} fontSize="sm">
          {!file.data && (
            <Center>
              <Spinner />
            </Center>
          )}
          {file.data && (
            <FormLayout
              initialFocusRef={focusRef}
              currentGroup={args.currentGroup}
              submittedGroups={args.submittedGroups}
              file={file.data}
              onSave={(...v) => {
                args.onSave?.(...v);
                onClose();
              }}
              renderSubmitModal={(data) =>
                args.renderSubmitModal({ onClose, ...data })
              }
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ZodIssueCode, ZodType } from 'zod';

type ValueObjectClass<V> = {
  validationMessage(value: any): string | undefined;
  new (...args: any[]): {
    asJSON(): V;
  };
};

export const valueObject = <T extends ZodType<V, any, unknown>, V>(
  base: T,
  klass: ValueObjectClass<V>,
) =>
  base.superRefine((val, ctx) => {
    const message = klass.validationMessage(val);
    if (message) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message,
      });
    }
  });

export const optionalValueObject = <T extends ZodType<V, any, unknown>, V>(
  base: T,
  klass: ValueObjectClass<V>,
) =>
  base.superRefine((val, ctx) => {
    if (typeof val === 'string' && val.trim() === '') {
      return;
    }
    if (val == null) {
      return;
    }
    const message = klass.validationMessage(val);
    if (message) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message,
      });
    }
  });

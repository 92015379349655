import * as React from 'react';

export const businessPages = () => {
  const { lazy } = React;

  const SupportPage = lazy(() => import('../Support'));
  const FlowDetailPage = lazy(() => import('../FlowDetail'));

  const BusinessCertificationsPage = lazy(
    () => import('../../certs/CertificationsPage'),
  );
  const DashboardPage = lazy(() => import('./Dashboard'));
  const ContactsPage = lazy(() => import('./Contacts'));
  const ContactDetailPage = lazy(() => import('./ContactDetail'));
  const FlowDesignsPage = lazy(() => import('./FlowDesigns/FlowDesignsPage'));
  const FlowDesignDetailsPage = lazy(() => import('./FlowDesignDetail'));
  const PositionsPage = lazy(() => import('./Positions'));
  const PositionDetailPage = lazy(() => import('./PositionDetail'));
  const RemindersPage = lazy(() => import('./RemindersPage'));
  const SettingsPage = lazy(() => import('./Settings'));
  const UsersPage = lazy(() => import('./Users'));
  const UserDetailsPage = lazy(() => import('./UserDetail'));
  const VideosPage = lazy(() => import('./Videos'));
  const TemplatesPage = lazy(() => import('./Esign/TemplatesPage'));
  const TemplatePage = lazy(() => import('./Esign/TemplatePage'));
  const TemplateDocumentsPage = lazy(() => import('./Esign/DocumentsPage'));
  const QuickAssignTemplatePage = lazy(() => import('./Esign/QuickAssignPage'));

  return {
    TemplatesPage,
    TemplatePage,
    SupportPage,
    FlowDetailPage,
    BusinessCertificationsPage,
    DashboardPage,
    TemplateDocumentsPage,
    QuickAssignTemplatePage,
    FlowDesignsPage,
    FlowDesignDetailsPage,
    PositionsPage,
    PositionDetailPage,
    RemindersPage,
    SettingsPage,
    UsersPage,
    UserDetailsPage,
    VideosPage,
    ContactsPage,
    ContactDetailPage,
  };
};

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { BusinessSetupBillingWizard } from './BusinessSetupBillingWizard';

export const BusinessSetupBillingWizardModal = (args: {
  disclosure: ReturnType<typeof useDisclosure>;
}) => {
  const { isOpen, onClose } = args.disclosure;

  return (
    <Modal
      closeOnOverlayClick
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody fontSize="sm" py={6}>
          <BusinessSetupBillingWizard onComplete={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import { FeatureFlag } from '@zap-onboard/api-client';
import React, { useMemo } from 'react';
import { auth } from '../auth';

export interface FeatureFlaggedProps {
  flag: FeatureFlag;
}

export const useFeatureFlag = (flag: FeatureFlag) => {
  const membership = auth.useCurrentBusinessManager();
  const flags = auth.useUserFeatureFlags();

  return useMemo(
    () => flags?.isEnabled(flag, membership?.businessId) ?? false,
    [flags, flag, membership?.businessId],
  );
};

export const FeatureFlagged: React.FC<FeatureFlaggedProps> = ({
  flag,
  children,
  // eslint-disable-next-line react/jsx-no-useless-fragment
}) => (useFeatureFlag(flag) ? <>{children}</> : null);

import * as React from 'react';
import { Center, Flex, Icon, Text } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';
import { IconType } from 'react-icons';

import { ChakraCenterProps } from '../../../types/Chakra';

type EmptyStateProps = {
  icon?: IconType | null;
  iconColor?: string;
  iconSize?: number;
  message: string;
  layoutProps?: Partial<ChakraCenterProps>;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  icon,
  iconColor,
  iconSize,
  layoutProps,
}) => (
  <Center
    data-testid="emptyStateComponent"
    gridColumnStart="1"
    gridColumnEnd="none"
    w="100%"
    minH="80px"
    h="100%"
    p={4}
    {...layoutProps}
  >
    <Flex gridGap={3} align="center">
      {icon !== null && (
        <Icon
          as={icon || MdInfoOutline}
          boxSize={iconSize || 6}
          color={iconColor ?? 'brand.dark-gray'}
          data-testid="emptyStateIcon"
        />
      )}
      <Text color="brand.dark-gray">{message}</Text>
    </Flex>
  </Center>
);

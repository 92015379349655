/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FileClaim, Percentage } from '@zap-onboard/api-client';
import { Optional } from 'designed';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFile } from '../../hooks/data/file/useFile';
import { useHandlerQuery } from '../../hooks/useHandlerQuery';

export const useUpgradePDF = (
  file?: { claim: FileClaim },
  onFileChange?: () => unknown,
) => {
  const { claim } = file || {};

  const getFile = useFile(claim);
  const { data: { fileId } = {} } = getFile;

  const [completed, setCompleted] = useState<boolean>(false);

  const prevFileId = useRef(fileId);
  useEffect(() => {
    if (prevFileId.current && fileId && prevFileId.current !== fileId) {
      onFileChange?.();
    }
    if (prevFileId.current && !fileId && completed) {
      setCompleted(false);
    }
    if (fileId) {
      prevFileId.current = fileId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  const enabled = !!claim && !!fileId && !completed;

  const upgradeJob = useHandlerQuery(
    (api) => api.file().upgradeFileToPDF({ file: claim! }),
    {
      key: ['PDF_UPGRADE', fileId!],
      refetchEvery: enabled ? '2s' : undefined,
      skipRefetch: true,
      enabled,
      staleAfter: '30m',
      keepPreviousData: false,
    },
  );

  const failed = upgradeJob.data?.is('FAILED') ?? Optional.empty();
  const complete = upgradeJob.data?.is('COMPLETE') ?? Optional.empty();
  const inProgress = upgradeJob.data?.is('IN_PROGRESS') ?? Optional.empty();

  useEffect(() => {
    if (completed || !upgradeJob.data) {
      return;
    }
    if (!inProgress?.isPresent()) {
      setCompleted(true);
    }
  }, [completed, inProgress, upgradeJob.data]);

  const failureMessage =
    upgradeJob.error?.message ??
    failed?.map((f) => f.failureMessage).orElse(undefined);

  return {
    queries: [upgradeJob, getFile],
    loading: upgradeJob.isLoading || !completed,
    error: useMemo(
      () => (failureMessage ? new Error(failureMessage) : undefined),
      [failureMessage],
    ),
    document: complete.map((c) => c.document).orElse(undefined),
    progress:
      inProgress.map((p) => p.progress) ??
      complete.map(({ document: { pages } }) =>
        Percentage.create({ total: pages.length, value: pages.length }),
      ),
  };
};

import * as React from 'react';
import {
  Center,
  Flex,
  Stack,
  SlideFade,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { flow, FlowStatus } from '@zap-onboard/api-client';
import { useTask } from '../../../../hooks/data/flow/useTask';
import { useTaskActionSubmission } from '../../../../hooks/data/flow/useTaskActionSubmission';
import { trackWorkCompletion } from '../../../../helpers/tracking';
import { track } from '../../../../libs/track';
import { useCompactView } from '../../../../hooks/useCompactView';

import { TaskMetaActionMenu } from './TaskMetaActionMenu';
import { Spinner } from '../../../Spinner';
import { FlowComplete } from './FlowComplete';
import { RenderedTask } from './RenderedTask';

const { useEffect, useRef, useCallback } = React;

type TaskContentProps = {
  flow: flow.GetFlows.Flow;
  canEditTask: boolean;
  context: 'user' | 'business';
  taskId: string;
  flowStatus: FlowStatus;
  setFlow: (flow: flow.GetFlows.Flow) => void;
  updateFocusedTask: (taskId: string) => void;
  setSideBarTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const TaskContent: React.FC<TaskContentProps> = ({
  canEditTask,
  context,
  flowStatus,
  taskId,
  setFlow,
  updateFocusedTask,
  flow,
  setSideBarTaskId,
}) => {
  const { task, setTask, isLoadingTask } = useTask({
    taskId,
  });

  const handleSetTaskFromSubmission: Parameters<
    typeof useTaskActionSubmission
  >[0]['setData'] = useCallback(
    ({ flow, nextTaskId, task: newTask }) => {
      const oldTask = task;
      if (!oldTask) {
        return;
      }
      setTask(newTask);
      setFlow(flow);
      if (newTask.status !== oldTask.status && newTask.taskCompleted()) {
        track.event('Finished Task', {
          taskId: newTask.taskId,
          taskType: newTask.type,
          taskStatus: newTask.status,
        });
      }
      if (!nextTaskId) {
        track.event('Completed Flow', { flowName: flow.name });
        trackWorkCompletion({
          workType: 'Workflows',
        });
      } else {
        updateFocusedTask(nextTaskId);
      }
    },
    [setFlow, setTask, task, updateFocusedTask],
  );

  const { submitAction, isLoadingSubmission } = useTaskActionSubmission({
    setData: handleSetTaskFromSubmission,
    context,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { isCompactView } = useCompactView();
  useEffect(() => {
    if (!isLoadingTask && ref.current && isCompactView && window.scrollTo) {
      window.scrollTo({
        top: ref.current.getBoundingClientRect().top + window.pageYOffset - 100,
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTask]);

  if (!task) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <SlideFade ref={ref} in={!isLoadingTask} offsetY="20px">
      <Grid templateColumns="90% 10%" templateRows="1fr" w="100%">
        <GridItem colStart={1} colSpan={2} rowStart={1} rowSpan={1}>
          <Stack
            id="taskWrapperScrollTarget"
            align="center"
            w="100%"
            layerStyle="base"
            pt={2}
            pb={4}
          >
            <RenderedTask
              flow={flow}
              key={task.taskId}
              isSubmitting={isLoadingSubmission}
              businessId={flow.businessId}
              context={context}
              task={task}
              submitAction={submitAction}
              canEdit={canEditTask}
              setSideBarTaskId={setSideBarTaskId}
            />
            {/* TODO: This needs to show when the user has completed all of _their_ required actions */}
            {flowStatus === FlowStatus.COMPLETE && (
              <FlowComplete context={context} />
            )}
          </Stack>
        </GridItem>

        <GridItem colStart={2} colSpan={1} rowStart={1} rowSpan={1}>
          <Flex mt={{ base: 4, md: 3 }}>
            <TaskMetaActionMenu
              task={task}
              submitAction={submitAction}
              context={context}
            />
          </Flex>
        </GridItem>
      </Grid>
    </SlideFade>
  );
};

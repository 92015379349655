import * as React from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Center,
  Flex,
  Box,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { UserProfile } from '@zap-onboard/api-client';
import { useUpdateUserProfile } from '../../../../hooks';

type FormInputs = {
  givenName: string;
  otherGivenName?: string;
  familyName: string;
};

type UserNameFormProps = {
  userProfile: UserProfile;
};

export const UserNameForm: React.FC<UserNameFormProps> = ({ userProfile }) => {
  const { givenName, otherGivenName, familyName } = userProfile;
  const isDisabled = !!userProfile.getFullName();
  const {
    errors,
    formState: { isSubmitting },
    handleSubmit,
    register,
  } = useForm();
  const { updateUserProfile } = useUpdateUserProfile();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const otherGivenName = data?.otherGivenName;

    await updateUserProfile({
      ...data,
      otherGivenName: otherGivenName?.length === 0 ? undefined : otherGivenName,
    });
  };

  return (
    <Flex w={{ base: '100%', lg: '60%' }}>
      <Box w="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <FormControl isInvalid={!!errors.givenName}>
              <FormLabel fontWeight="bold" m={0}>
                Given name
              </FormLabel>
              <Input
                name="givenName"
                defaultValue={givenName}
                ref={register({
                  required: 'Given name required',
                  maxLength: { value: 35, message: 'Max length 35 characters' },
                })}
                isDisabled={isDisabled}
              />
              <FormErrorMessage>{errors.givenName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.otherGivenName}>
              <FormLabel fontWeight="bold" m={0}>
                Other given names
              </FormLabel>
              <Input
                name="otherGivenName"
                defaultValue={otherGivenName}
                ref={register({
                  maxLength: { value: 35, message: 'Max length 35 characters' },
                })}
                isDisabled={isDisabled}
              />
              <FormErrorMessage>
                {errors.otherGivenName?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.familyName}>
              <FormLabel fontWeight="bold" m={0}>
                Family name
              </FormLabel>
              <Input
                name="familyName"
                defaultValue={familyName}
                ref={register({
                  required: 'Family name required',
                  maxLength: { value: 35, message: 'Max length 35 characters' },
                })}
                isDisabled={isDisabled}
              />
              <FormErrorMessage>{errors.familyName?.message}</FormErrorMessage>
            </FormControl>

            <Center>
              <Button type="submit" isDisabled={isDisabled || isSubmitting}>
                Submit
              </Button>
            </Center>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

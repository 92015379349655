/* eslint-disable react-hooks/rules-of-hooks */
import ms from 'ms';
import { errs } from '@zap-onboard/errors';
import { AsyncResult } from 'designed';
import { useQuery, useQueryClient } from 'react-query';
import { QueryKeys, QueryKeysToIgnoreBusinessContext } from './QueryKeys';
import { useAPI } from '../services/API';
import { APIClient } from '@zap-onboard/api-client';
import { useRef } from 'react';
import { auth } from '../auth';

export function useHandlerQuery<RT>(
  query: Query<RT>,
  args: QueryConfig<NoInfer<RT>>,
) {
  const api = useAPI();
  const invalidate = useQueryInvalidator();

  const ignoreBusinessContext = QueryKeysToIgnoreBusinessContext.includes(
    args.key[0],
  );

  let currentBusinessId: string | undefined = auth.useCurrentBusinessId();
  if (ignoreBusinessContext) {
    currentBusinessId = undefined;
  }

  const key = ignoreBusinessContext
    ? args.key
    : [...args.key, { currentBusinessId }];

  const queried = useQuery<RT, InstanceType<errs.APIError>>({
    queryFn: () => query(api, { invalidate }).getOrThrowFailure(),
    onError: args.onError,
    retry: args.retry,
    queryKey: key,
    enabled: args.enabled,
    staleTime: args.staleAfter ? ms(args.staleAfter) : undefined,
    refetchInterval: args.refetchEvery ? ms(args.refetchEvery) : undefined,
    onSuccess: args.onSuccess,
    refetchOnWindowFocus: !args.skipRefetch,
    keepPreviousData: args.keepPreviousData,
  });

  if (args.onChange) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const lastData = useRef<RT | undefined>(undefined);
    if (queried.data !== lastData?.current) {
      args.onChange();
    }
    lastData.current = queried.data;
  }

  return queried;
}

export const useQueryInvalidator = () => {
  const qc = useQueryClient();
  return (...keys: Partial<QueryKeys>[]) =>
    Promise.all(keys.map((keySet) => qc.invalidateQueries(keySet)));
};

type NoInfer<T> = T & { [K in keyof T]: T[K] };

export interface QueryConfig<RT> {
  keepPreviousData?: boolean;
  skipRefetch?: boolean;
  onError?: (err: InstanceType<errs.APIError>) => unknown;
  retry?: number;
  enabled?: boolean;
  staleAfter?: string;
  refetchEvery?: string;
  /**
   * Called anytime the value changes using strict equality
   */
  onChange?: () => unknown;
  onSuccess?: (data: NoInfer<RT>) => unknown;
  key: QueryKeys;
}

type Query<RT> = (
  api: APIClient,
  opts: {
    invalidate: ReturnType<typeof useQueryInvalidator>;
  },
) => AsyncResult<RT, InstanceType<errs.APIError>>;

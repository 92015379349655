import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Button,
  ButtonGroup,
  Heading,
} from '@chakra-ui/react';
import { ABN, ContactNumber, Email } from '@zap-onboard/api-client';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { onError } from '../libs/errorLib';
import { useAPI } from '../services/API';

export interface DefaultBillingAccountDetails {
  name?: string;
  abn?: string;
  phoneNumber?: string;
  email?: string;
}

export const UpdateBillingAccountDetailsForm = (args: {
  onCancel?: () => unknown;
  onSaved?: () => unknown;
  /**
   * Providing no billingAccountId indivates it is being loaded
   */
  billingAccountId?: string;
  defaults?: DefaultBillingAccountDetails;
}) => {
  const {
    abn: initialABN = '',
    name: initialName = '',
    email: initialEmail = '',
    phoneNumber: initialPhoneNumber = '',
  } = args.defaults ?? {};

  const { billingAccountId, onCancel, onSaved } = args;
  const { errors, handleSubmit, register, formState } = useForm();

  const api = useAPI();
  const qc = useQueryClient();

  const submit = handleSubmit(async ({ name, abn, email, phoneNumber }) => {
    await api
      .billing()
      .updateBillingAccountDetails({
        abn: abn?.length === 0 ? undefined : abn,
        name,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        billingAccountId: billingAccountId!,
        email: email?.length === 0 ? undefined : email,
        phoneNumber: phoneNumber?.length === 0 ? undefined : phoneNumber,
      })
      .map(() => {
        qc.invalidateQueries('MY_BILLING_ACCOUNTS');
        qc.invalidateQueries(['BILLING_ACCOUNT', billingAccountId]);
        if (onSaved) {
          onSaved();
        }
      }, onError);
  });

  return (
    <>
      <Heading size="md" mb={4}>
        {args.defaults ? 'Update' : 'Create'} billing account
      </Heading>
      <Stack spacing={3}>
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel color="brand.black">
            Account name (to appear on invoice)
          </FormLabel>
          <Input
            name="name"
            type="text"
            variant="outline"
            defaultValue={initialName}
            ref={register({
              required: 'Account name required',
              maxLength: 200,
            })}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel color="brand.black">Email invoices to:</FormLabel>
          <Input
            name="email"
            type="text"
            variant="outline"
            defaultValue={initialEmail}
            ref={register({
              required: 'Email required',
              validate: Email.validationMessage,
            })}
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.phoneNumber}>
          <FormLabel color="brand.black">Contact Number</FormLabel>
          <Input
            name="phoneNumber"
            type="text"
            variant="outline"
            defaultValue={initialPhoneNumber}
            ref={register({
              required: 'Contact number required',
              validate: (v) => ContactNumber.validationMessage(v),
            })}
          />
          <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.abn}>
          <FormLabel color="brand.black">ABN (optional)</FormLabel>
          <Input
            name="abn"
            type="text"
            variant="outline"
            defaultValue={initialABN}
            ref={register({
              validate: (v) =>
                v?.length === 0 ? undefined : ABN.validationMessage(v),
            })}
          />
          <FormErrorMessage>{errors.abn?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup pt={8} display="flex" justifyContent="space-between">
          {onCancel && (
            <Button tabIndex={-1} variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button
            onClick={submit}
            disabled={formState.isSubmitting || !billingAccountId}
            isLoading={formState.isSubmitting}
          >
            Save
          </Button>
        </ButtonGroup>
      </Stack>
    </>
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var brandColors = {
    'light-blue': '#709BFF',
    'lighter-blue': '#2567FF',
    'focus-blue': '#E8F7FF',
    blue: '#2667FF',
    'dark-blue': '#00257A',
    green: '#06A77D',
    red: '#F56565',
    yellow: '#FFC145',
    'dark-gray': '#718096',
    'darkish-gray': '#C3CFD5',
    gray: '#E6E6E6',
    'light-gray': '#E2E8F0',
    black: '#1A202C',
    myob: '#A130F5',
    deputy: '#332a5d',
    cloudPayroll: '#24ACE6',
    xero: {
        fg: '#F7FBFF',
        bg: '#00a1df',
    },
};
var flowStatus = {
    COMPLETE: brandColors.green,
    IN_PROGRESS: brandColors['light-blue'],
    NOT_STARTED: brandColors.yellow,
    BUSINESS_CANCELLED: brandColors.gray,
};
var flowTaskStatus = {
    NOT_STARTED: brandColors.yellow,
    BUSINESS_SKIPPED: brandColors.gray,
    IN_PROGRESS: brandColors['light-blue'],
    COMPLETE: brandColors.green,
};
var certSubmissionStatus = {
    APPROVED: brandColors.green,
    REJECTED: brandColors.red,
    SUBMITTED: brandColors['light-blue'],
    UNSUBMITTED: brandColors.yellow,
};
export var colors = {
    brand: __assign({}, brandColors),
    // Hack to force chakra to use this as a "color scheme" for inputs
    'focus-blue': {
        50: '#F8FDFF',
        100: '#F4FBFF',
        200: '#ECF8FF',
        300: '#EEF9FF',
        400: '#ECF8FF',
        500: '#E8F7FF',
        600: '#DAEFFB',
        700: '#DBE7FF',
        800: '#C4E0FB',
        900: '#AECEFF',
    },
    flowStatus: flowStatus,
    flowTaskStatus: flowTaskStatus,
    certSubmissionStatus: certSubmissionStatus,
};

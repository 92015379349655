"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPrerequisites = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const identity_1 = require("../../identity");
const ResponseBase_1 = require("../../ResponseBase");
var GetPrerequisites;
(function (GetPrerequisites) {
    class Response extends ResponseBase_1.ResponseBase {
        satisfiedBy(p) {
            return this.profileAttributes.every((attr) => {
                if (attr === 'name') {
                    return p.givenName && p.familyName;
                }
                if (attr === 'emergencyContact') {
                    return (p.emergencyContactName &&
                        p.emergencyContactNumber &&
                        p.emergencyContactRelationship);
                }
                return p[attr] != null;
            });
        }
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(identity_1.AllProfileFields, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "profileAttributes", void 0);
    GetPrerequisites.Response = Response;
})(GetPrerequisites = exports.GetPrerequisites || (exports.GetPrerequisites = {}));

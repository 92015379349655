/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import * as React from 'react';
import { Center, Grid, GridItem, Image, useToast } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { useCompactView } from '../../../hooks/useCompactView';
import image from 'assets/img/login-screen.png';

import { Logo } from '../../../components/Logo';
import { Spinner } from '../../../components/Spinner';
import { UserInvitationContent } from './Content';
import { track } from '../../../libs/track';
import { Result } from 'designed';
import { useHandlerQuery } from '../../../hooks/useHandlerQuery';
import { ErrorDisplay } from '../../../billing/ErrorDisplay';
import { useHandler } from '../../../hooks/useHandlerMutation';
import { errs } from '@zap-onboard/errors';
import { logError } from '../../../libs/errorLib';
import { useProfile } from '../../selectors';

type Token = {
  businessId: string;
  email: string;
  exp: number;
  iat: number;
  jti: string;
  sub: string;
  type: string;
};

export const AcceptInvitePage: React.FC = () => {
  const userProfile = useProfile();
  const toast = useToast();
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get('token');
  const { isCompactView } = useCompactView();

  const [accept, isAccepting] = useHandler(
    (api) => api.membership().invitation.acceptWithToken,
    {
      always: {
        onSuccess: () => {
          track.event('Accepted Employee Invite');
          history.push(`/user/dashboard`);
        },
        onError: (err) => {
          if (err instanceof errs.InvalidTokenError) {
            toast({
              title: 'Invite already actioned',
              status: 'info',
              duration: 5000,
              description: userProfile
                ? 'You are already logged in'
                : 'You will need to log in to continue',
              position: 'top',
            });
          } else {
            logError(err);
          }
          history.push('/login');
        },
      },
    },
  );

  const decoded = React.useMemo(
    () =>
      Result.fromThrowable(() => (token ? jwtDecode<Token>(token) : undefined))
        .toOptional()
        .orElse(undefined),
    [token],
  );

  const business = useHandlerQuery(
    (api) => api.business().get(decoded?.businessId!),
    {
      key: ['BUSINESS', decoded?.businessId!],
      enabled: !!decoded?.businessId,
    },
  );

  /**
   * This alleviates an infinite spin if no token or an invalid token is passed.
   */
  React.useEffect(() => {
    if (!decoded) {
      history.push('/login');
    }
  }, [decoded, history]);

  return (
    <Center
      align="center"
      bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
      justify="center"
      minW="100vw"
      minH="100vh"
    >
      {!business.data || !token ? (
        <>
          <ErrorDisplay {...business} />
          <Spinner color="white" />
        </>
      ) : (
        <Grid
          templateColumns={isCompactView ? '1fr' : '50% 50%'}
          templateRows="1fr auto"
          layerStyle="base"
          m={4}
          w={{ base: '90%', md: '70%', lg: '60%' }}
          minH="400px"
        >
          <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
            <ErrorDisplay {...business} />

            <UserInvitationContent
              business={business.data.business}
              isLoading={isAccepting}
              handleAcceptInvite={() => accept({ token })}
            />
          </GridItem>

          <GridItem colStart={1} colSpan={1} rowStart={2} rowSpan={1} mb={3}>
            <Logo color="brand.blue" height="28px" />
          </GridItem>

          {isCompactView ? null : (
            <GridItem colStart={2} colSpan={1} rowStart={1} rowSpan={2}>
              <Image src={image} />
            </GridItem>
          )}
        </Grid>
      )}
    </Center>
  );
};

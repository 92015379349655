/* eslint-disable no-underscore-dangle */
import {
  Button,
  ButtonGroup,
  Text,
  HStack,
  Image,
  Center,
} from '@chakra-ui/react';
import {
  FileClaim,
  FileUploadTargetType,
  RemoteFile,
} from '@zap-onboard/api-client';
import { modal } from '@zap-onboard/web-components';
import { useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { ErrorDisplay } from '../billing/ErrorDisplay';
import { useFile } from '../hooks/data/file/useFile';
import { Spinner } from './Spinner/Spinner';
import { downloadURL } from '../helpers/downloadURL';
import { onError } from '../libs';
import { MdDownload } from 'react-icons/md';

const extensions = {
  [FileUploadTargetType.PDF]: '.pdf',
  [FileUploadTargetType.GIF]: '.gif',
  [FileUploadTargetType.JPEG]: '.jpeg',
  [FileUploadTargetType.PNG]: '.png',
  [FileUploadTargetType.MP4]: '.mp4',
  [FileUploadTargetType.CSV]: '.csv',
};

export const FilePreviewModal: React.FC<{
  file: RemoteFile | FileClaim;
  filename?: string;
  trigger?: modal.SimpleProps['trigger'];
  _trigger?: modal.SimpleProps['_trigger'];
  disclosure?: modal.SimpleProps['disclosure'];
}> = ({ file: passed, trigger, _trigger, filename, disclosure }) => {
  let file: RemoteFile | FileClaim = passed;
  if (!(file instanceof RemoteFile) && !(file instanceof FileClaim)) {
    if ('token' in file) {
      file = FileClaim.create(file);
    } else {
      file = RemoteFile.create(file);
    }
  }

  const [remoteFile, setRemoteFile] = useState<RemoteFile | undefined>(
    file instanceof RemoteFile ? file : undefined,
  );

  const [downloading, setDownloading] = useState<boolean>(false);

  return (
    <modal.Simple
      disclosure={disclosure}
      trigger={disclosure ? trigger : trigger ?? 'Open'}
      _trigger={_trigger}
      _modal={{ size: '6xl' }}
      header={
        <HStack>
          <Text fontSize="lg">File Preview</Text>
          {remoteFile && (
            <ButtonGroup variant="ghost" color="brand.blue" isAttached>
              <Button
                leftIcon={<MdDownload />}
                isLoading={downloading}
                isDisabled={downloading}
                onClick={() => {
                  if (downloading) {
                    return;
                  }
                  setDownloading(true);
                  downloadURL(
                    remoteFile.url,
                    `${filename ?? remoteFile.fileId}${
                      extensions[remoteFile.type]
                    }`,
                  )
                    .tap((i) => i, onError)
                    .then(() => setDownloading(false));
                }}
              >
                Download
              </Button>
              <Button
                as="a"
                target="_blank"
                rel="noopener"
                leftIcon={<FiExternalLink />}
                href={remoteFile.url}
              >
                Open
              </Button>
            </ButtonGroup>
          )}
        </HStack>
      }
    >
      {file instanceof FileClaim && (
        <FileClaimPreviewer
          afterLoaded={(remoteFile) => setRemoteFile(remoteFile)}
          height="90vh"
          claim={file}
        />
      )}
      {file instanceof RemoteFile && (
        <FilePreviewer height="90vh" file={file} />
      )}
    </modal.Simple>
  );
};

const FileClaimPreviewer: React.FC<{
  height: string;
  width?: string;
  claim: FileClaim;
  afterLoaded?: (file: RemoteFile) => unknown;
}> = ({ claim, afterLoaded, ...other }) => {
  const file = useFile(claim, afterLoaded);

  return (
    <>
      {!file.data && (
        <Center {...other}>
          <ErrorDisplay {...file} />
          <Spinner />
        </Center>
      )}

      {file.data && (
        <>
          <ErrorDisplay {...file} />
          <FilePreviewer file={file.data} {...other} />
        </>
      )}
    </>
  );
};

export const FilePreviewer: React.FC<{
  file: RemoteFile;
  /**
   * Height must be provided to allow pdf previews to properly scroll
   */
  height: string;
  width?: string;
}> = ({ file, height, width = '100%' }) => (
  <>
    {file.type === FileUploadTargetType.PDF && (
      <iframe
        src={file.url}
        title="PDF File Preview"
        frameBorder="0"
        style={{
          height,
          width,
        }}
      />
    )}
    {file.type !== FileUploadTargetType.PDF && (
      <Center w={width} bg="brand.light-gray" h={height} overflow="scroll">
        <Image
          // Without `crossorigin="anonymous"` the image will not be downloadable
          crossorigin="anonymous"
          maxW="100%"
          height="auto"
          src={file.url}
        />
      </Center>
    )}
  </>
);

import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { auth } from '../../auth';

export const AuthenticatedRoute: React.FC<
  React.ComponentProps<typeof Route>
> = ({ children, ...rest }) => {
  const userProfile = auth.useProfile();
  const { pathname, search } = useLocation();
  return (
    <Route {...rest} component={userProfile ? rest.component : undefined}>
      {userProfile ? (
        children
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  );
};

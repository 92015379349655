/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import {
  ClientVerificationTask as APIClientVerificationTask,
  FileUploadFor,
  FileUploadTargetType,
  flow,
  TaskActionType,
  TaskDesignType,
} from '@zap-onboard/api-client';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from '../../../../../helpers';
import { inputs } from '@zap-onboard/web-components';
import { TaskActionSubmitter } from '../../../../../hooks';
import { formSchema } from './formSchema';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';
import { Address, DatePicker } from '../../../../Inputs';
import { DocumentUpload } from '../../../../DocumentUpload';
import {
  prettyTaskStatus,
  prettyIndividualType,
  nonIndividualVerificationDocs,
  representativeDocs,
  prettyIndividualSingular,
} from './constants';
import { InfoBox } from '../shared/InfoBox';
import { Optional } from 'designed';
import { Link } from 'react-router-dom';

interface ClientVerificationTaskProps {
  task?: APIClientVerificationTask;
  context: 'user' | 'business';
  canEdit: boolean;
  submitAction?: TaskActionSubmitter;
  setSideBarTaskId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  flow?: flow.GetFlows.Flow;
}

export const ClientVerificationTask: React.FC<ClientVerificationTaskProps> = ({
  context,
  canEdit,
  task,
  submitAction,
  setSideBarTaskId,
  flow,
}) => {
  const defaultValues = React.useMemo(
    () => ({
      name: task?.clientVerificationSubmission?.value.name,
      type: task?.clientVerificationSubmission?.value.type,
      address: Address.getDefaultValue(
        task?.clientVerificationSubmission?.value.address,
      ),
      dateOfBirth: task?.clientVerificationSubmission?.merged.dateOfBirth,
      abn: task?.clientVerificationSubmission?.merged.abn?.asJSON(),
      acn: task?.clientVerificationSubmission?.merged.acn?.asJSON(),
      isRepresentative: Optional.of(
        task?.clientVerificationSubmission?.value.isRepresentative,
      )
        .map((isRepresentative) =>
          isRepresentative ? ('true' as const) : ('false' as const),
        )
        .orElse(undefined),
      nonIndividualVerificationFileClaim:
        task?.clientVerificationSubmission?.merged
          .nonIndividualVerificationFileClaim,
      representativeVerificationFileClaim:
        task?.clientVerificationSubmission?.value
          ?.representativeVerificationFileClaim,
    }),
    [task],
  );

  let nextTask: flow.GetFlows.Task<TaskDesignType> | undefined;
  if (flow?.tasks && task) {
    const currentTaskFlowIndex = flow.tasks
      .map((flowTask) => flowTask?.taskId)
      .indexOf(task.taskId);
    nextTask = flow?.tasks?.[currentTaskFlowIndex + 1];
  }

  const useFormMethods = useForm<z.infer<typeof formSchema>>({
    resolver: z.zodResolver(formSchema),
    defaultValues,
  });

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    register,
    watch,
    reset,
    control,
  } = useFormMethods;

  const isClientVerificationUnsubmitted = task
    ? task.taskStateId === 'CLIENT_VERIFICATION_UNSUBMITTED'
    : true;

  const isClientVerificationSubmitted =
    task?.taskStateId === 'CLIENT_VERIFICATION_SUBMITTED';

  const isTaskCompleted = task?.taskCompleted();

  const isSubmissionDisabled =
    !isClientVerificationUnsubmitted || !canEdit || isSubmitting;

  const isBusiness = context === 'business';

  const canReview = isBusiness && isClientVerificationSubmitted && canEdit;

  React.useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, reset]);

  const individualType = watch('type');
  const isRepresentative = watch('isRepresentative');

  const hasSelectedIndividualType = [
    'SOLE_TRADER',
    'PARTNERSHIP',
    'COMPANY',
    'INDIVIDUAL',
    'TRUST',
  ].includes(individualType as string);

  const onManagerSubmit = async (isApproved: boolean) => {
    if (!task || !submitAction) {
      return;
    }
    await submitAction(TaskActionType.CLIENT_VERIFICATION_APPROVAL, {
      taskId: task?.taskId,
      isApproved,
    });
  };

  const onClientSubmit = handleSubmit(async (submission) => {
    if (!task || !submitAction) {
      return;
    }

    await submitAction(TaskActionType.CLIENT_VERIFICATION_SUBMISSION, {
      taskId: task.taskId,
      submission: {
        ...submission,
        isRepresentative: submission.isRepresentative === 'true',
      },
    });
  });

  const taskHeading = (status: string) => (
    <Heading variant="section" textAlign="center">
      Client Verification {`- ${status}`}
    </Heading>
  );
  const isTheSubmittingIndividual =
    isRepresentative !== 'true' && individualType === 'INDIVIDUAL';

  const taskBody = (
    <FormProvider {...useFormMethods}>
      <form>
        <Flex w="100%" justify="center">
          <Stack spacing={4} p={4} w={{ base: '100%', sm: '80%' }}>
            <inputs.radio.Group
              name="type"
              label="What type structure is the organisation"
              isDisabled={isSubmissionDisabled}
            >
              <Stack mb={4} textAlign="center">
                <inputs.radio.Button value="INDIVIDUAL">
                  Individual
                </inputs.radio.Button>
                <inputs.radio.Button value="SOLE_TRADER">
                  Sole trader
                </inputs.radio.Button>
                <inputs.radio.Button value="PARTNERSHIP">
                  Partnership
                </inputs.radio.Button>
                <inputs.radio.Button value="COMPANY">
                  Company
                </inputs.radio.Button>
                <inputs.radio.Button value="TRUST">Trust</inputs.radio.Button>
              </Stack>
            </inputs.radio.Group>

            {['SOLE_TRADER', 'INDIVIDUAL'].includes(
              individualType as string,
            ) && (
              <inputs.radio.Group
                name="isRepresentative"
                label="Which of the following applies?"
                isDisabled={isSubmissionDisabled}
              >
                <Stack mb={4} textAlign="center">
                  <inputs.radio.Button value="false">
                    I am the {prettyIndividualSingular(individualType)} in this
                    application
                  </inputs.radio.Button>
                  <inputs.radio.Button value="true">
                    I am a representative of the{' '}
                    {prettyIndividualSingular(individualType)} in this
                    application
                  </inputs.radio.Button>
                </Stack>
              </inputs.radio.Group>
            )}

            {hasSelectedIndividualType && !isTheSubmittingIndividual && (
              <inputs.TextInput
                name="name"
                label={`What is the ${prettyIndividualType(
                  individualType,
                )} name`}
                isDisabled={isSubmissionDisabled}
              />
            )}
            {individualType === 'INDIVIDUAL' && isRepresentative === 'true' && (
              <FormControl isInvalid={!!(errors as any)?.dateOfBirth}>
                <FormLabel fontWeight="bold" m={0}>
                  Date of birth
                </FormLabel>
                <DatePicker
                  isDisabled={isSubmissionDisabled}
                  name="dateOfBirth"
                />
                <FormErrorMessage>
                  {(errors as any)?.dateOfBirth?.message}
                </FormErrorMessage>
              </FormControl>
            )}

            {isTheSubmittingIndividual &&
              task?.clientVerificationSubmission?.value.name && (
                <>
                  <FormLabel fontWeight="bold">Name</FormLabel>
                  <Text>{task?.clientVerificationSubmission?.value.name}</Text>
                  <FormLabel fontWeight="bold">Address</FormLabel>
                  <Text>
                    {task?.clientVerificationSubmission?.value.address?.asString()}
                  </Text>
                  <FormLabel fontWeight="bold">Date of birth</FormLabel>
                  <Text>
                    {task?.clientVerificationSubmission?.merged.dateOfBirth?.asDDMonthYYYY()}
                  </Text>
                </>
              )}

            {['SOLE_TRADER', 'PARTNERSHIP', 'COMPANY', 'TRUST'].includes(
              individualType as string,
            ) && (
              <inputs.TextInput
                name="abn"
                label={`What is the ${prettyIndividualType(
                  individualType,
                )} ABN`}
                isDisabled={isSubmissionDisabled}
              />
            )}

            {individualType === 'COMPANY' && (
              <inputs.TextInput
                name="acn"
                label={`What is the ${prettyIndividualType(
                  individualType,
                )} ACN`}
                isDisabled={isSubmissionDisabled}
              />
            )}
            {hasSelectedIndividualType && !isTheSubmittingIndividual && (
              <Address.Input
                isDisabled={isSubmissionDisabled}
                name="address"
                label={`What is the ${prettyIndividualType(
                  individualType,
                )} physical address`}
              />
            )}
            {['PARTNERSHIP', 'COMPANY', 'TRUST'].includes(
              individualType as string,
            ) && (
              <>
                <InfoBox>
                  <Text>
                    Upload a document to verify the existence of the{' '}
                    {prettyIndividualSingular(individualType)}.<br /> Acceptable
                    documents include:
                  </Text>
                  <UnorderedList>
                    {nonIndividualVerificationDocs[
                      individualType as keyof typeof nonIndividualVerificationDocs
                    ].map((doc, idx) => (
                      <ListItem key={idx}>{doc}</ListItem>
                    ))}
                  </UnorderedList>
                </InfoBox>
                {isTaskCompleted ? (
                  <Flex direction="column" align="center">
                    <Heading variant="section" textAlign="center">
                      Documents uploaded
                    </Heading>
                    {task?.taskStateId === 'CLIENT_VERIFICATION_APPROVED' ? (
                      <Tag bg="brand.green">
                        <TagLabel color="white">
                          {prettyTaskStatus(task?.taskStateId)}
                        </TagLabel>
                      </Tag>
                    ) : (
                      <Tag bg="brand.red">
                        <TagLabel color="white">
                          {prettyTaskStatus(task?.taskStateId)}
                        </TagLabel>
                      </Tag>
                    )}
                  </Flex>
                ) : (
                  <FormControl
                    isInvalid={
                      !!(errors as any)?.nonIndividualVerificationFileClaim
                    }
                    textAlign="center"
                  >
                    <DocumentUpload
                      isReadOnly={!task || isSubmissionDisabled}
                      uploadedFor={{
                        type: FileUploadFor.TASK,
                        taskId: task?.taskId || '',
                      }}
                      allowedTypes={[
                        FileUploadTargetType.PDF,
                        FileUploadTargetType.JPEG,
                        FileUploadTargetType.PNG,
                        FileUploadTargetType.GIF,
                      ]}
                      controlled={{
                        name: 'nonIndividualVerificationFileClaim',
                        rules: { required: 'File required' },
                        control,
                      }}
                    />
                    <FormErrorMessage pl={2}>
                      {
                        (errors as any)?.nonIndividualVerificationFileClaim
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                )}
              </>
            )}
            {(['PARTNERSHIP', 'COMPANY', 'TRUST'].includes(
              individualType as string,
            ) ||
              isRepresentative === 'true') && (
              <>
                <input
                  type="hidden"
                  name="isRepresentative"
                  value="true"
                  ref={register()}
                />
                <InfoBox>
                  <Text>
                    Upload a document to verify your authority as a
                    representative of the{' '}
                    {prettyIndividualSingular(individualType)}.<br />
                    Acceptable documents include:
                  </Text>
                  <UnorderedList>
                    {representativeDocs[individualType].map((doc, idx) => (
                      <ListItem key={idx}>{doc}</ListItem>
                    ))}
                  </UnorderedList>
                </InfoBox>
                {isTaskCompleted ? (
                  <Flex direction="column" align="center">
                    <Heading variant="section" textAlign="center">
                      Documents uploaded
                    </Heading>
                    {task?.taskStateId === 'CLIENT_VERIFICATION_APPROVED' ? (
                      <Tag bg="brand.green">
                        <TagLabel color="white">
                          {prettyTaskStatus(task?.taskStateId)}
                        </TagLabel>
                      </Tag>
                    ) : (
                      <Tag bg="brand.red">
                        <TagLabel color="white">
                          {prettyTaskStatus(task?.taskStateId)}
                        </TagLabel>
                      </Tag>
                    )}
                  </Flex>
                ) : (
                  <FormControl
                    isInvalid={
                      !!(errors as any)?.nonIndividualVerificationFileClaim
                    }
                    textAlign="center"
                  >
                    <DocumentUpload
                      isReadOnly={!task || isSubmissionDisabled}
                      uploadedFor={{
                        type: FileUploadFor.TASK,
                        taskId: task?.taskId || '',
                      }}
                      allowedTypes={[
                        FileUploadTargetType.PDF,
                        FileUploadTargetType.JPEG,
                        FileUploadTargetType.PNG,
                        FileUploadTargetType.GIF,
                      ]}
                      controlled={{
                        name: 'representativeVerificationFileClaim',
                        rules: { required: 'File required' },
                        control,
                      }}
                    />
                    <FormErrorMessage pl={2}>
                      {
                        (errors as any)?.nonIndividualVerificationFileClaim
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                )}
              </>
            )}
          </Stack>
        </Flex>
      </form>
    </FormProvider>
  );

  const managerReviewFooter = (
    <Center mt={4}>
      {isBusiness ? (
        <Stack spacing={4}>
          <Stack spacing={2}>
            <InfoBox>
              <Text>
                Client review of {prettyIndividualSingular(individualType)}.
                Review the following:
              </Text>
              <UnorderedList>
                <ListItem>The documents are clear and legible</ListItem>
                <ListItem>
                  There does not appear to be reason to question the
                  identification docs provided
                </ListItem>
              </UnorderedList>
              <Text mt={2} fontWeight="semibold">
                Once documents are approved / rejected they will no longer be
                visible
              </Text>
            </InfoBox>
          </Stack>
          <Center>
            <Flex gridGap={3}>
              <Button
                onClick={() => onManagerSubmit(true)}
                isLoading={isSubmitting}
                isDisabled={!canReview}
              >
                Approve
              </Button>
              <Button
                onClick={() => onManagerSubmit(false)}
                isLoading={isSubmitting}
                isDisabled={!canReview}
                variant="danger"
              >
                Reject
              </Button>
            </Flex>
          </Center>
        </Stack>
      ) : (
        <Center mt={4} flexDirection="column">
          <InfoBox>
            <Text>
              Your submission has been sent. We are now awaiting approval
            </Text>
          </InfoBox>
          {nextTask && setSideBarTaskId ? (
            <Button onClick={() => setSideBarTaskId(nextTask?.taskId)}>
              Go to next task
            </Button>
          ) : (
            <Button as={Link} to="/user/dashboard">
              Go to dashboard
            </Button>
          )}
        </Center>
      )}
    </Center>
  );

  const clientSubmissionFooter = task ? (
    <Center mt={4}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Text>
            DECLARATION: I declare that the information I have given is true and
            correct. There are penalties for deliberately providing false or
            misleading information.
          </Text>
        </Stack>
        <Center>
          <Button
            onClick={onClientSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmissionDisabled}
          >
            Submit
          </Button>
        </Center>
      </Stack>
    </Center>
  ) : (
    <Center mt={4}>
      <InfoBox>
        <Text>
          This is a task preview. To make a submission assign the workflow to a
          member
        </Text>
      </InfoBox>
    </Center>
  );

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading(prettyTaskStatus(task?.taskStateId))}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading(prettyTaskStatus(task?.taskStateId))}
        {taskBody}
        {managerReviewFooter}
        <TaskCompleted />
      </Stack>
    );
  }

  if (isClientVerificationSubmitted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
        <Flex
          direction="column"
          justify="space-between"
          h="100%"
          minH={{ md: '360px' }}
        >
          <Stack>
            {taskHeading(prettyTaskStatus(task?.taskStateId))}
            {taskBody}
          </Stack>
          {managerReviewFooter}
        </Flex>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack>
          {taskHeading(prettyTaskStatus(task?.taskStateId))}
          {taskBody}
        </Stack>
        {clientSubmissionFooter}
      </Flex>
    </Stack>
  );
};

import { XeroConnectionPurpose } from '@zap-onboard/api-client';
import { useState } from 'react';
import { onError } from '../../../libs/errorLib';
import { track } from '../../../libs/track';
import { useAPI } from '../../../services/API';

export const useXeroConsentConfig = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const beginConnection = (purpose: XeroConnectionPurpose) => async () => {
    if (!isLoading) {
      setIsLoading(true);
      await api
        .xero()
        .beginConnection({ purpose })
        .map(
          ({ connectionURL }) => (window.location.href = connectionURL),
          onError,
        );

      setIsLoading(false);
    }
  };

  const purgeXeroConnection = async () => {
    if (!isLoading) {
      setIsLoading(true);
      track.event('Xero Account Disconnected');
      await api.xero().purgeMyConnection().mapFailure(onError);
      setIsLoading(false);
    }
  };

  const disconnectConnectedXeroOrganization = async (args: {
    xeroConnectedOrganizationId: string;
  }) => {
    if (!isLoading) {
      setIsLoading(true);
      await api.xero().deleteMyConnection({
        xeroConnectedOrganizationId: args.xeroConnectedOrganizationId,
      });
      setIsLoading(false);
    }
  };

  return {
    startXeroSignup: beginConnection(XeroConnectionPurpose.SIGN_UP),
    startXeroLogin: beginConnection(XeroConnectionPurpose.SIGN_IN),
    startXeroConnection: beginConnection(XeroConnectionPurpose.CONNECT_ORG),
    purgeXeroConnection,
    disconnectConnectedXeroOrganization,
    isLoadingXeroConsentConfig: isLoading,
  };
};

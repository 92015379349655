import { VideoFormSchemaItem } from '@zap-onboard/api-client';
import { useVideo } from '../../../../../../hooks';
import { Input, Text } from '@chakra-ui/react';

import { VideoPlayer } from '../../../../../VideoPlayer';
import { Spinner } from '../../../../../Spinner';
import { useFormContext } from 'react-hook-form';

type Props = {
  schemaItem: VideoFormSchemaItem;
  getPath: (...paths: string[]) => string;
};
export const FormTaskVideoBlock: React.FC<Props> = ({
  schemaItem,
  getPath,
}) => {
  const { isLoadingVideo, video } = useVideo({ videoId: schemaItem.videoId });
  const { register } = useFormContext();

  if (isLoadingVideo) {
    return <Spinner />;
  }
  if (video) {
    return (
      <>
        <Input
          type="hidden"
          value={schemaItem.type}
          name={getPath('type')}
          ref={register}
        />
        <VideoPlayer videoUrl={video.videoClaim.url} />
      </>
    );
  }

  return <Text alignSelf="center">Could not load video</Text>;
};

/**
 * Pixel values on the canvas are divided by this value as pdf-lib uses MM internally.
 *
 * The PDF schema is purely in MM.
 *
 * Pretty sure we should be storing pixels and converting back when needed to
 * reduce complexity
 * */
export const PX_IN_MM = 3.7795275591;

export const SELECTABLE_CLASSNAME = 'selectable';
export const MOVEABLE_IGNORE_DRAG_CLASSNAME = 'moveable-ignore-drag';

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Size = exports.Point = void 0;
class Point {
    constructor(pts) {
        this.pts = pts;
    }
    static fromJSON(value) {
        if (typeof value !== 'number') {
            throw new Error('Invalid value');
        }
        if (Number.isNaN(value)) {
            throw new Error('Invalid value');
        }
        return new Point(value);
    }
    get mm() {
        if (this.pts === 0) {
            return 0;
        }
        return this.pts / 2.835;
    }
    asJSON() {
        return this.pts;
    }
    toJSON() {
        return this.pts;
    }
}
exports.Point = Point;
class Size {
    constructor(width, height) {
        this.width = width;
        this.height = height;
    }
    static fromJSON(value) {
        if (typeof value !== 'object' || value === null) {
            throw new Error('Invalid value');
        }
        if (!('width' in value) || !('height' in value)) {
            throw new Error('Invalid value');
        }
        return new Size(Point.fromJSON(value.width), Point.fromJSON(value.height));
    }
    asJSON() {
        return { width: this.width.asJSON(), height: this.height.asJSON() };
    }
    toJSON() {
        return { width: this.width, height: this.height };
    }
}
exports.Size = Size;

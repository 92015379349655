import { Select } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../auth';

export const SelectBusinessDropdown = () => {
  const currentBusinessManagerMembership = auth.useCurrentBusinessManager();
  const businessManagerMemberships = auth.useBusinessManagers();
  const history = useHistory();

  return businessManagerMemberships.length > 0 ? (
    <Select
      value={currentBusinessManagerMembership?.businessId}
      onChange={(e) => {
        const businessId = e.target.value;
        setTimeout(() => {
          if (businessId === 'NONE') {
            auth.setCurrentBusinessId(undefined);
            history.push('/user/dashboard');
          } else {
            auth.setCurrentBusinessId(businessId);
            history.push('/business/dashboard');
          }
        }, 0);
      }}
    >
      <option value="NONE">Your profile</option>
      {businessManagerMemberships.map((r) => (
        <option key={r.businessId} value={r.businessId}>
          {r.businessName}
        </option>
      ))}
    </Select>
  ) : null;
};

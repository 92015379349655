import { errs } from '@zap-onboard/errors';
import { useHandlerQuery } from '../../useHandlerQuery';
import { useQueryClient } from 'react-query';

export const useVisaDetailsForMember = (memberId: string) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useHandlerQuery(
    (api) => api.visa().getVisaDetailsForMember({ memberId }),
    {
      key: ['VISA_DETAILS', memberId],
      retry: 1,
      skipRefetch: true,
      onError: (err) => {
        if (err instanceof errs.NotFoundError) {
          queryClient.setQueryData(['VISA_DETAILS', memberId], undefined);
        }
      },
    },
  );
  return {
    isVisaDetailsForMemberLoading: isLoading,
    visaDetailsForMember: data,
  };
};

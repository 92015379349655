import {
  Box,
  Tag,
  Button,
  ButtonGroup,
  ChakraProvider,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { RemoteFile } from '@zap-onboard/api-client';
import { useMemo } from 'react';
import { ProvidePDFFontContext } from '../contexts';
import { useWrapperSize } from '../hooks/useWrapperSize';
import { SignModal } from '../SignModal/SignModal';
import {
  PDFFormGroup,
  PDFSerializedFormData,
  usePDFState,
} from '../usePDFState';
import { FormViewport } from './FormViewport';
import shallow from 'zustand/shallow';

const SubmitButton = (args: {
  renderSubmitModal: (args: PDFSerializedFormData) => JSX.Element;
}) => {
  const { signature, validations, fields, values } = usePDFState(
    (s) => ({
      values: s.fieldValues,
      fields: s.fields,
      validations: s.fieldValidations,
      signature: s.groups.find((g) => g.groupId === s.currentGroup)
        ?.signatureDataURL,
    }),
    shallow,
  );

  const serialized = useMemo(() => {
    return usePDFState.getState().serializeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, validations, fields, signature]);

  return serialized ? args.renderSubmitModal(serialized) : null;
};

export const FormLayout = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialFocusRef?: React.MutableRefObject<any>;
  currentGroup: PDFFormGroup;
  submittedGroups: PDFFormGroup[];
  file: RemoteFile;
  onSave?: (data: PDFSerializedFormData) => unknown;
  renderSubmitModal: (data: PDFSerializedFormData) => JSX.Element;
}) => {
  const {
    initialFocusRef,
    currentGroup,
    submittedGroups,
    file,
    onSave,
    renderSubmitModal,
  } = props;

  useMemo(
    // Run immediately on mount to save an additional two renders
    () =>
      usePDFState.getState().initForm({
        submittedGroups,
        currentGroup,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { wrapperRef, wrapperSize } = useWrapperSize();

  const remainingFieldCount = usePDFState(
    ({ computed: { invalidFieldIds } }) => invalidFieldIds.length,
  );

  const fieldsLeftTag = (
    <Box>
      <Tag colorScheme="blue">{remainingFieldCount} fields left</Tag>
    </Box>
  );

  const nextFieldId = usePDFState((s) => s.computed.nextFieldToFocusFieldId);

  const setFocusedFieldId = usePDFState((s) => s.setFocusedFieldId);
  const scrollFieldIntoView = usePDFState((s) => s.scrollToField);
  const openSignatureModal = usePDFState((s) => s.openSignatureModal);
  const hasSignature = usePDFState(
    (s) =>
      s.groups.find((g) => g.groupId === s.currentGroup)?.signatureDataURL !=
      undefined,
  );

  const toolbar = (
    <Flex h="100%" w="100%" alignItems="center" px={2}>
      {fieldsLeftTag}
      {hasSignature && (
        <Button
          variant="ghost"
          borderRadius="none"
          onClick={() => openSignatureModal()}
        >
          Change Signature
        </Button>
      )}

      <Spacer flexGrow={1} />

      <ButtonGroup>
        <Button
          variant="outline"
          borderRadius="none"
          onClick={() => {
            onSave?.(usePDFState.getState().serializeForm());
          }}
        >
          Close
        </Button>

        {nextFieldId && (
          <Button
            borderRadius="none"
            ref={initialFocusRef}
            onClick={() => {
              setFocusedFieldId(nextFieldId);
              scrollFieldIntoView(nextFieldId);
            }}
          >
            Next
          </Button>
        )}
        {!nextFieldId && <SubmitButton renderSubmitModal={renderSubmitModal} />}
      </ButtonGroup>
    </Flex>
  );

  return (
    <Box h="100vh" w="100vw" overflow="auto">
      <SignModal />
      <Box h="50px" />

      <Box
        ref={wrapperRef}
        w="100vw"
        h="calc(100vh - 50px)"
        bg="rgb(230, 230, 230)"
        overflow="auto"
      >
        <ProvidePDFFontContext>
          <ChakraProvider cssVarsRoot="pdf">
            {wrapperSize.width && wrapperSize.height && (
              <FormViewport file={file} />
            )}
          </ChakraProvider>
        </ProvidePDFFontContext>
      </Box>

      <Box w="100%" h="50px" bg="white" position="fixed" top="0" left="0">
        {toolbar}
      </Box>
    </Box>
  );
};

import * as React from 'react';
import { Button, Center, Flex, Heading, Stack, Text } from '@chakra-ui/react';

import { ChakraStackProps } from '../../../types/Chakra';

type Props = {
  icon?: React.ReactNode;
  heading: string;
  description: string;
  layerStyle?: string;
  onAction?: () => void;
  buttonText?: string;
} & Partial<ChakraStackProps>;

export const InfoCard: React.FC<Props> = ({
  icon,
  heading,
  description,
  buttonText,
  layerStyle,
  onAction,
  ...props
}) => (
  <Stack spacing={3} layerStyle={layerStyle || 'base'} p={4} {...props}>
    {icon ? (
      <Flex justify="center" data-testid="infoCardIcon">
        {icon}
      </Flex>
    ) : null}
    <Stack spacing={2}>
      <Heading size="md" textAlign="center">
        {heading}
      </Heading>
      <Text>{description}</Text>
    </Stack>
    {onAction ? (
      <Center>
        <Button onClick={onAction}>{buttonText || 'Action'}</Button>
      </Center>
    ) : null}
  </Stack>
);

export var Text = {
    baseStyle: {
        fontSize: 'md',
    },
    sizes: {},
    variants: {
        formLabel: {
            fontSize: 'sm',
            color: 'brand.black',
        },
    },
    defaultProps: {},
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateOrCreateUserInXeroRequest = exports.AdditionalXeroTaxDetailsRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const utils_1 = require("../utils");
const ResponseBase_1 = require("../ResponseBase");
/**
 * Additional information to be collected from the admin prior to sync
 */
class AdditionalXeroTaxDetailsRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(utils_1.AUPayroll.IncomeTypes.map((t) => t.code)),
    (0, tslib_1.__metadata)("design:type", Object)
], AdditionalXeroTaxDetailsRequest.prototype, "incomeType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(utils_1.AUPayroll.EmploymentTypes.map((t) => t.code)),
    (0, tslib_1.__metadata)("design:type", Object)
], AdditionalXeroTaxDetailsRequest.prototype, "employmentType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(utils_1.AUPayroll.EmploymentBasis.map((t) => t.code)),
    (0, tslib_1.__metadata)("design:type", Object)
], AdditionalXeroTaxDetailsRequest.prototype, "employmentBasis", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(utils_1.AUPayroll.TaxScaleTypes.map((t) => t.code)),
    (0, tslib_1.__metadata)("design:type", Object)
], AdditionalXeroTaxDetailsRequest.prototype, "taxScaleType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(utils_1.AUPayroll.WorkConditions.map((t) => t.code)),
    (0, tslib_1.__metadata)("design:type", Object)
], AdditionalXeroTaxDetailsRequest.prototype, "workCondition", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(utils_1.AUPayroll.SeniorMaritalStatus.map((t) => t.code)),
    (0, tslib_1.__metadata)("design:type", Object)
], AdditionalXeroTaxDetailsRequest.prototype, "seniorMaritalStatus", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.Country),
    (0, tslib_1.__metadata)("design:type", utils_1.Country)
], AdditionalXeroTaxDetailsRequest.prototype, "countryOfResidence", void 0);
exports.AdditionalXeroTaxDetailsRequest = AdditionalXeroTaxDetailsRequest;
class UpdateOrCreateUserInXeroRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpdateOrCreateUserInXeroRequest.prototype, "sendTaxDetails", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UpdateOrCreateUserInXeroRequest.prototype, "sendSuperDetails", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateOrCreateUserInXeroRequest.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateOrCreateUserInXeroRequest.prototype, "xeroEmployeeId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AdditionalXeroTaxDetailsRequest),
    (0, tslib_1.__metadata)("design:type", AdditionalXeroTaxDetailsRequest)
], UpdateOrCreateUserInXeroRequest.prototype, "additionalXeroTaxDetails", void 0);
exports.UpdateOrCreateUserInXeroRequest = UpdateOrCreateUserInXeroRequest;

import * as React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

import { BeginLogin } from './BeginLogin';
import { MagicLinkSent } from './MagicLinkSent';
import { MFA } from '@zap-onboard/api-client';
import { useLoginFlow } from '../../selectors';
import { useAuthState } from '../../useAuthState';
import { actions } from '@zap-onboard/web-components';
import { MdLockClock, MdTextsms, MdCode } from 'react-icons/md';
import { VerifySMSForm } from '../MFA/VerifySMSForm';
import { ConfirmOTPForm } from '../MFA/ConfirmOTPForm';
import { VerifyDevForm } from '../MFA/VerifyDevForm';

const { useState } = React;

export const LoginFlow: React.FC = () => {
  const loginFlow = useLoginFlow();
  const restart = () =>
    useAuthState.setState({
      loginFlow: undefined,
    });

  return (
    <Flex>
      {!loginFlow && <BeginLogin />}

      {loginFlow?.type === 'NOT_REGISTERED' && (
        <Modal closeOnOverlayClick={false} isOpen onClose={restart}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton onClick={restart} />
            <ModalBody pb={6}>
              <Stack direction="column" align="center" spacing={4} mt="10%">
                {/* change this to react-icons */}
                {/* <Icon name="warning" h="40px" w="40px" color="red.500" /> */}
                <Stack spacing={2} px={3} mt={2}>
                  <Text fontSize="sm">
                    The email you have provided is not registered.
                  </Text>
                  <Text fontSize="sm">
                    You may have entered the wrong email.
                  </Text>
                </Stack>
                <Button onClick={restart}>Try again</Button>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {loginFlow?.type === 'EMAIL_SENT' && <MagicLinkSent />}

      {loginFlow?.type === 'VERIFY_MFA' && (
        <VerifyMFA mfaToken={loginFlow.mfaToken} items={loginFlow.sources} />
      )}
    </Flex>
  );
};

const factorIcons = {
  TOTP: MdLockClock,
  SMS: MdTextsms,
  DEV: MdCode,
};

const factorTitles = {
  TOTP: 'Click here to verify with your authenticator app',
  SMS: 'Click here to send verification code to your phone',
  DEV: 'Click here to verify',
};

const VerifyMFA = (props: { items: MFA.Sources.Item[]; mfaToken: string }) => {
  const { items, mfaToken } = props;
  const [secondFactorId, setSecondFactorId] = useState<string>('');

  const secondFactor = items.find(
    (item) => item.secondFactorId === secondFactorId,
  );

  if (!secondFactor) {
    return (
      <Box w="100%">
        <Heading variant="subSection">Verify your Identity</Heading>
        <Stack>
          {items.map(({ name, type, secondFactorId }) => {
            return (
              <actions.DetailButton
                key={secondFactorId}
                icon={factorIcons[type]}
                onClick={() => setSecondFactorId(secondFactorId)}
                title={name}
              >
                {factorTitles[type]}
              </actions.DetailButton>
            );
          })}
        </Stack>
      </Box>
    );
  }

  if (secondFactor.type === 'DEV') {
    return (
      <Stack spacing={2}>
        <VerifyDevForm verifyMFAToken={mfaToken} onComplete={() => {}} />
      </Stack>
    );
  }

  if (secondFactor.type === 'SMS') {
    return (
      <Stack spacing={2}>
        <VerifySMSForm
          verifyMFAToken={mfaToken}
          number={secondFactor.name}
          onComplete={() => {}}
          secondFactorId={secondFactor.secondFactorId}
          onBack={() => setSecondFactorId('')}
        />
      </Stack>
    );
  }

  if (secondFactor.type === 'TOTP') {
    return (
      <Stack spacing={2}>
        <ConfirmOTPForm
          verifyMFAToken={mfaToken}
          secondFactorId={secondFactor.secondFactorId}
          onBack={() => setSecondFactorId('')}
          onComplete={() => {}}
        />
      </Stack>
    );
  }

  return null;
};

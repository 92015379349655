import * as React from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { useCompactView } from '../../../../hooks';
import { useNotificationContext } from './notificationContext';

import { NotificationsModal } from './NotificationsModal';
import { NotificationsDrawer } from './NotificationsDrawer';

export const Notifications: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { notificationDrawer$, markAllNotificationsAsRead, unread } =
    useNotificationContext();
  const { isCompactView } = useCompactView();

  React.useEffect(
    () =>
      notificationDrawer$.subscribe(({ action }) => {
        if (action === 'CLOSE') {
          onClose();
        }
        if (action === 'OPEN') {
          onOpen();
        }
      }),
    [notificationDrawer$, onClose, onOpen],
  );

  const handleClose = () => {
    notificationDrawer$.publish({ action: 'CLOSE' });
  };

  const markAllAsRead = () => {
    markAllNotificationsAsRead();
    handleClose();
  };

  return (
    <>
      {isCompactView && (
        <NotificationsModal
          isOpen={isOpen}
          unread={unread}
          onClose={handleClose}
          markAllNotificationsAsRead={markAllAsRead}
        />
      )}

      {!isCompactView && (
        <NotificationsDrawer
          isOpen={isOpen}
          unread={unread}
          onClose={handleClose}
          markAllNotificationsAsRead={markAllAsRead}
        />
      )}
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskDesignType = void 0;
var TaskDesignType;
(function (TaskDesignType) {
    TaskDesignType["RAW_TEXT"] = "RAW_TEXT";
    TaskDesignType["PROFILE"] = "PROFILE";
    TaskDesignType["FORM"] = "FORM";
    TaskDesignType["ESIGN"] = "ESIGN";
    TaskDesignType["TEMPLATE"] = "TEMPLATE";
    TaskDesignType["TFND"] = "TFND";
    TaskDesignType["SUPER_CHOICE"] = "SUPER_CHOICE";
    TaskDesignType["DEPUTY_LINK_EMPLOYEE"] = "DEPUTY_LINK_EMPLOYEE";
    TaskDesignType["XERO_SYNC_EMPLOYEE"] = "XERO_SYNC_EMPLOYEE";
    TaskDesignType["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    TaskDesignType["CLIENT_VERIFICATION"] = "CLIENT_VERIFICATION";
    TaskDesignType["PROOF_OF_IDENTITY"] = "PROOF_OF_IDENTITY";
    TaskDesignType["VISA_DETAILS"] = "VISA_DETAILS";
})(TaskDesignType = exports.TaskDesignType || (exports.TaskDesignType = {}));

import * as React from 'react';
import { Checkbox, Stack, Text } from '@chakra-ui/react';

import { InfoBox } from '../../../../..';
import { UpdateNameModal } from './UpdateNameModal';
import { UserProfile } from '@zap-onboard/api-client';
import { auth } from '../../../../../../auth';

export const NameConfirmation: React.FC<{
  isNameConfirmed: boolean;
  isTaskDisabled: boolean;
  setIsNameConfirmed: (arg: boolean) => unknown;
}> = ({ isNameConfirmed, isTaskDisabled, setIsNameConfirmed }) => {
  const { NameDetails } = Local;
  const userProfile = auth.useProfile() ?? null;

  return (
    <Stack w="100%" pt={4} spacing={4}>
      <InfoBox
        message="These details are used for the e-signature:"
        m={0}
        w="100%"
      >
        <NameDetails userProfile={userProfile} />
        {userProfile && <UpdateNameModal userProfile={userProfile} />}
      </InfoBox>
      <Checkbox
        fontSize="sm"
        defaultChecked={false}
        isDisabled={isTaskDisabled}
        isChecked={isNameConfirmed}
        onChange={() => setIsNameConfirmed(!isNameConfirmed)}
      >
        I confirm that my name details match
      </Checkbox>
    </Stack>
  );
};

namespace Local {
  export const NameDetails: React.FC<{ userProfile: UserProfile | null }> = ({
    userProfile,
  }) => {
    if (!userProfile) {
      return <InfoBox message="Please create a member profile" />;
    }

    const { givenName, otherGivenName, familyName } = userProfile;

    if (!givenName || !familyName) {
      return (
        <InfoBox message="Your profile is incomplete. Please update your name details" />
      );
    }

    return (
      <Stack>
        <DetailItem label="Given name" value={givenName} />
        {otherGivenName && (
          <DetailItem label="Other given name(s)" value={otherGivenName} />
        )}
        <DetailItem label="Family name" value={familyName} />
      </Stack>
    );
  };

  const DetailItem: React.FC<{ label: string; value: string }> = ({
    label,
    value,
  }) => (
    <Stack spacing={0}>
      <Text color="brand.dark-gray" fontWeight="semibold" fontSize="sm">
        {label}
      </Text>
      <Text>{value}</Text>
    </Stack>
  );
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MYOBOAuth = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var MYOBOAuth;
(function (MYOBOAuth) {
    let Begin;
    (function (Begin) {
        class Response extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Response.prototype, "connectionURL", void 0);
        Begin.Response = Response;
    })(Begin = MYOBOAuth.Begin || (MYOBOAuth.Begin = {}));
    let Complete;
    (function (Complete) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "code", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "state", void 0);
        Complete.Request = Request;
    })(Complete = MYOBOAuth.Complete || (MYOBOAuth.Complete = {}));
})(MYOBOAuth = exports.MYOBOAuth || (exports.MYOBOAuth = {}));

import { MYOBCompanyFileAuthorization } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useCreateMYOBCompanyFileAuthorization = () => {
  const [create, isCreateLoading] = useHandler(
    (api) => api.myob().createMyobCompanyFileAuthorization,
    {
      invalidates: () => [['BUSINESS']],
    },
  );

  return {
    create: (
      data: Entity.Attributes<MYOBCompanyFileAuthorization.Create.Request>,
    ) => create(data),
    isCreateLoading,
  };
};

import * as React from 'react';
import { Link } from 'react-router-dom';

import { ActionItem, ActionItemProps, ActionItemType } from '../ActionItem';

export const BusinessActionItem: React.FC<ActionItemProps> = (props) => {
  const { id, flowId, memberId, type } = props;

  if (type === ActionItemType.WORKFLOW) {
    return (
      <Link key={id} to={`/business/flows/${flowId}`}>
        <ActionItem key={props.id} {...props} />
      </Link>
    );
  }

  return (
    <Link key={id} to={`/business/members/${memberId}`}>
      <ActionItem key={memberId} {...props} />
    </Link>
  );
};

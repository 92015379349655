/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
type Func = (...args: any[]) => any;

export const debounce = <T extends Func>(cb: T, wait = 20) => {
  let h: null | ReturnType<typeof setTimeout> = null;
  const callable = (...args: any) => {
    if (h) {
      clearTimeout(h);
    }
    h = setTimeout(() => cb(...args), wait);
  };
  return <T>(<any>callable);
};

const shift = (number: number, precision: number, reverseShift: boolean) => {
  if (reverseShift) {
    precision = -precision;
  }
  const numArray = `${number}`.split('e');

  return Number(
    `${numArray[0]}e${
      numArray[1] ? Number(numArray[1]) + precision : precision
    }`,
  );
};

export const incrementName = (
  name: string,
  allFieldNames: string[],
): string => {
  const match = name.match(/(\d+)$/);
  if (match) {
    const index = parseInt(match[1], 10);
    if (Number.isNaN(index)) {
      return `${name} 1`;
    }
    const nextName = name.replace(/(\d+)$/, `${index + 1}`);
    if (allFieldNames.includes(nextName)) {
      return incrementName(nextName, allFieldNames);
    }
    return nextName;
  }
  return `${name} 1`;
};
export const round = (number: number, precision = 2) => {
  return shift(Math.round(shift(number, precision, false)), precision, true);
};

export const cloneDeep = <T>(value: T): T => JSON.parse(JSON.stringify(value));

import {
  flow,
  TaskDesignType,
  VisaDetailsTask as APIVisaDetailsTask,
  TaskActionType,
  visaCheckDocumentType,
  Country,
  AbsoluteDate,
} from '@zap-onboard/api-client';
import { Button, Center, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { TaskActionSubmitter } from '../../../../../hooks';
import { VisaDetailsFormSchema, VisaDetailsFormValues } from './formSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { inputs } from '@zap-onboard/web-components';
import { InfoBox } from '../shared/InfoBox';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';
import { prettyTaskStatus } from './constants';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { VisaCheckModal } from '../../../../Visa/VisaCheckModal';

const { TextInput, SelectInput, radio } = inputs;
interface VisaDetailsTaskProps {
  task?: APIVisaDetailsTask;
  context: 'user' | 'business';
  canEdit: boolean;
  submitAction?: TaskActionSubmitter;
  setSideBarTaskId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  flow?: flow.GetFlows.Flow;
}

export const VisaDetailsTask: React.FC<VisaDetailsTaskProps> = ({
  context,
  canEdit,
  task,
  submitAction,
  setSideBarTaskId,
  flow,
}) => {
  const defaultValues = React.useMemo(
    () => ({
      type:
        task?.visaDetails?.value.type ??
        ('' as 'AU_OR_NZ_RESIDENT' | 'NON_AU_OR_NZ_RESIDENT'),
      givenNames: task?.visaDetails?.merged.givenNames,
      familyName: task?.visaDetails?.merged.familyName,
      dateOfBirthDay: task?.visaDetails?.merged.dateOfBirth?.day,
      dateOfBirthMonth: task?.visaDetails?.merged.dateOfBirth?.month,
      dateOfBirthYear: task?.visaDetails?.merged.dateOfBirth?.year,
      documentId: task?.visaDetails?.merged.documentId,
      documentType: task?.visaDetails?.merged.documentType,
      countryOfIssue: task?.visaDetails?.merged.countryOfIssue?.asJSON(),
    }),
    [task],
  );

  let nextTask: flow.GetFlows.Task<TaskDesignType> | undefined;
  if (flow?.tasks && task) {
    const currentTaskFlowIndex = flow.tasks
      .map((flowTask) => flowTask?.taskId)
      .indexOf(task.taskId);
    nextTask = flow?.tasks?.[currentTaskFlowIndex + 1];
  }

  const useFormMethods = useForm<VisaDetailsFormValues>({
    defaultValues,
    resolver: async (data, context, options) => {
      return zodResolver(VisaDetailsFormSchema)(data, context, options);
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    watch,
    reset,
  } = useFormMethods;

  const isVisaDetailsUnsubmitted = task
    ? task.taskStateId === 'VISA_DETAILS_UNSUBMITTED'
    : true;

  const isVisaDetailsSubmitted = task?.taskStateId === 'VISA_DETAILS_SUBMITTED';

  const isTaskCompleted = task?.taskCompleted();

  const isSubmissionDisabled =
    !isVisaDetailsUnsubmitted || !canEdit || isSubmitting;

  const isBusiness = context === 'business';

  const canReview = isBusiness && isVisaDetailsSubmitted && canEdit;

  React.useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, reset]);

  const individualType = watch('type');

  const onManagerSubmit = async (isApproved: boolean) => {
    if (!task || !submitAction) {
      return;
    }
    await submitAction(TaskActionType.VISA_DETAILS_COMPLETED, {
      taskId: task?.taskId,
      isApproved,
    });
  };

  const onEmployeeSubmit = handleSubmit(async (formData) => {
    if (!task || !submitAction) {
      return;
    }

    await submitAction(TaskActionType.VISA_DETAILS_SUBMISSION, {
      taskId: task.taskId,
      submission:
        formData.type === 'AU_OR_NZ_RESIDENT'
          ? formData
          : {
              ...formData,
              dateOfBirth: AbsoluteDate.create({
                day: formData.dateOfBirthDay,
                month: formData.dateOfBirthMonth,
                year: formData.dateOfBirthYear,
              }),
            },
    });
  });

  const taskHeading = (status: string) => (
    <Heading variant="section" textAlign="center">
      Visa Details {`- ${status}`}
    </Heading>
  );

  const taskBody = (
    <FormProvider {...useFormMethods}>
      <form>
        <Flex w="100%" justify="center">
          <Stack spacing={4} p={4} w={{ base: '100%', sm: '80%' }}>
            <radio.Group
              name="type"
              label="Are you an Australian or New Zealand resident"
              isDisabled={isSubmissionDisabled}
            >
              <Stack mb={4} textAlign="center">
                <radio.Button value="AU_OR_NZ_RESIDENT">Yes</radio.Button>
                <radio.Button value="NON_AU_OR_NZ_RESIDENT">No</radio.Button>
              </Stack>
            </radio.Group>
            {individualType === 'NON_AU_OR_NZ_RESIDENT' && (
              <>
                <TextInput
                  name="givenNames"
                  label="What are your given names"
                  isDisabled={isSubmissionDisabled}
                />
                <TextInput
                  name="familyName"
                  label="What is your family name"
                  isDisabled={isSubmissionDisabled}
                />
                <Stack>
                  <Text fontWeight="bold">Date of birth</Text>
                  <Flex gridColumnGap={2}>
                    <TextInput
                      name="dateOfBirthDay"
                      label="Day"
                      placeholder="DD"
                      isDisabled={isSubmissionDisabled}
                      _label={{ fontWeight: 'normal' }}
                    />
                    <TextInput
                      name="dateOfBirthMonth"
                      label="Month"
                      placeholder="MM"
                      isDisabled={isSubmissionDisabled}
                      _label={{ fontWeight: 'normal' }}
                    />
                    <TextInput
                      name="dateOfBirthYear"
                      label="Year"
                      placeholder="YYYY"
                      isDisabled={isSubmissionDisabled}
                      _label={{ fontWeight: 'normal' }}
                    />
                  </Flex>
                </Stack>
                <SelectInput
                  name="documentType"
                  label="Document type"
                  isDisabled={isSubmissionDisabled}
                  options={visaCheckDocumentType.map(({ code, name }) => ({
                    name,
                    value: code,
                  }))}
                />
                <TextInput
                  name="documentId"
                  label="Document Id"
                  isDisabled={isSubmissionDisabled}
                />
                <SelectInput
                  name="countryOfIssue"
                  label="Country of issue"
                  isDisabled={isSubmissionDisabled}
                  options={Country.options().concat({
                    name: 'Select..',
                    value: '',
                  })}
                />
              </>
            )}
          </Stack>
        </Flex>
      </form>
    </FormProvider>
  );

  const managerReviewFooter = (
    <Center mt={4}>
      {isBusiness ? (
        <Stack spacing={4}>
          <Stack spacing={2}>
            <InfoBox>
              <Text my={3} fontWeight="semibold" alignSelf="center">
                Visa details completed, start a visa check to verify the details
              </Text>

              {flow?.memberId && (
                <VisaCheckModal
                  memberId={flow?.memberId}
                  buttonText="Start visa check"
                />
              )}
              <Text my={3} alignSelf="center">
                When you are ready mark the task as complete. You can view visa
                check history in the user &apos;s profile
              </Text>
            </InfoBox>
          </Stack>
          <Center>
            <Flex gridGap={3}>
              <Button
                onClick={() => onManagerSubmit(true)}
                isLoading={isSubmitting}
                isDisabled={!canReview}
              >
                Complete task
              </Button>
            </Flex>
          </Center>
        </Stack>
      ) : (
        <Center mt={4} flexDirection="column">
          <InfoBox>
            <Text>Visa task completed. Proceed to next task</Text>
          </InfoBox>
          {nextTask && setSideBarTaskId ? (
            <Button onClick={() => setSideBarTaskId(nextTask?.taskId)}>
              Go to next task
            </Button>
          ) : (
            <Button as={Link} to="/user/dashboard">
              Go to dashboard
            </Button>
          )}
        </Center>
      )}
    </Center>
  );

  const employeeSubmissionFooter = task ? (
    <Center mt={4}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Text>
            DECLARATION: I declare that the information I have given is true and
            correct. There are penalties for deliberately providing false or
            misleading information.
          </Text>
        </Stack>
        <Center>
          <Button
            onClick={onEmployeeSubmit}
            isLoading={isSubmitting}
            isDisabled={isSubmissionDisabled}
          >
            Submit
          </Button>
        </Center>
      </Stack>
    </Center>
  ) : (
    <Center mt={4}>
      <InfoBox>
        <Text>
          This is a task preview. To make a submission assign the workflow to a
          member
        </Text>
      </InfoBox>
    </Center>
  );

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading(prettyTaskStatus(task?.taskStateId))}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading(prettyTaskStatus(task?.taskStateId))}
        {taskBody}
        {managerReviewFooter}
        <TaskCompleted />
      </Stack>
    );
  }

  if (isVisaDetailsSubmitted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
        <Flex
          direction="column"
          justify="space-between"
          h="100%"
          minH={{ md: '360px' }}
        >
          <Stack>
            {taskHeading(prettyTaskStatus(task?.taskStateId))}
            {taskBody}
          </Stack>
          {managerReviewFooter}
        </Flex>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack>
          {taskHeading(prettyTaskStatus(task?.taskStateId))}
          {taskBody}
        </Stack>
        {employeeSubmissionFooter}
      </Flex>
    </Stack>
  );
};

import * as React from 'react';
import { Grid, GridItem, CSSObject, ColorProps } from '@chakra-ui/react';

type Props = {
  renderHeader: () => JSX.Element;
  renderCenter?: () => JSX.Element;
  renderFooter?: () => JSX.Element;
  layerStyle?: 'base' | 'dotted';
  hover?: CSSObject;
  bg?: ColorProps['color'];
};

export const Tile: React.FC<Props> = ({
  renderHeader,
  renderCenter,
  renderFooter,
  bg = 'inherit',
  layerStyle = 'base',
  hover = {
    layerStyle: 'selected',
    transition: '500ms',
  },
}) => {
  const { Layout, Header, Center, Footer } = Local;

  return (
    <Layout layerStyle={layerStyle} hover={hover} bg={bg}>
      <Header renderHeader={renderHeader} />
      {renderCenter && <Center renderCenter={renderCenter} />}
      {renderFooter && <Footer renderFooter={renderFooter} />}
    </Layout>
  );
};

namespace Local {
  export const Layout: React.FC<{
    bg: ColorProps['color'];
    layerStyle: 'base' | 'dotted';
    hover: CSSObject;
  }> = ({ layerStyle, hover, bg, children }) => (
    <Grid
      templateColumns="minmax(0, 1fr)"
      templateRows="repeat(3, minmax(0,1fr))"
      bg={bg}
      layerStyle={layerStyle}
      h={{ base: '168px', md: '180px' }}
      w={{ base: '168px', md: '180px' }}
      p={4}
      _hover={hover}
    >
      {children}
    </Grid>
  );

  export const Header: React.FC<{
    renderHeader: () => JSX.Element;
  }> = ({ renderHeader }) => (
    <GridItem colStart={1} colSpan={1} rowStart={1} rowSpan={1}>
      {renderHeader()}
    </GridItem>
  );

  export const Center: React.FC<{
    renderCenter: () => JSX.Element;
  }> = ({ renderCenter }) => (
    <GridItem colStart={1} colSpan={1} rowStart={2} rowSpan={1}>
      {renderCenter()}
    </GridItem>
  );

  export const Footer: React.FC<{
    renderFooter: () => JSX.Element;
  }> = ({ renderFooter }) => (
    <GridItem colStart={1} colSpan={1} rowStart={3} rowSpan={1}>
      {renderFooter()}
    </GridItem>
  );
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccount = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const AccountName_1 = require("./AccountName");
const AccountNumber_1 = require("./AccountNumber");
const BSB_1 = require("./BSB");
class BankAccount extends ResponseBase_1.ResponseBase {
    static fake() {
        return BankAccount.create({
            bsb: BSB_1.BSB.fake(),
            name: AccountName_1.AccountName.fake(),
            number: AccountNumber_1.AccountNumber.fake(),
        });
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(BSB_1.BSB),
    (0, tslib_1.__metadata)("design:type", BSB_1.BSB)
], BankAccount.prototype, "bsb", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(AccountName_1.AccountName),
    (0, tslib_1.__metadata)("design:type", AccountName_1.AccountName)
], BankAccount.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(AccountNumber_1.AccountNumber),
    (0, tslib_1.__metadata)("design:type", AccountNumber_1.AccountNumber)
], BankAccount.prototype, "number", void 0);
exports.BankAccount = BankAccount;

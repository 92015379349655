/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  IndividualTFN,
  SuperChoice,
  SuperChoiceTask as APISuperChoiceTask,
  TaskActionType,
  UserProfile,
} from '@zap-onboard/api-client';

import { SuperTypeSelector, SuperTypes } from './SuperType';
import { TaskActionSubmitter } from '../../../../../hooks/data/flow/useTaskActionSubmission';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';

import {
  getSuperChoiceUserDetailsDefaults,
  SuperChoiceUserDetails,
} from './SuperChoiceUserDetails';
import {
  APRAOrRSA,
  APRAOrRSASchema,
  getAPRAOrRSADefaultValues,
} from './APRAOrRSA';
import { getSMSFDefaults, SMSF, SMSFSchema } from './SMSF';
import { z } from '../../../../../helpers/schema';
import {
  EmployerNominatedSchema,
  getEmployerNominatedDefaults,
  SuperChoiceEmployerNominatedFields,
} from './SuperChoiceEmployerNominated';
import { InfoBox } from '../../../..';
import { auth } from '../../../../../auth';

interface SuperChoiceTaskProps {
  context: 'user' | 'business';
  canEdit: boolean;
  task?: APISuperChoiceTask;
  submitAction?: TaskActionSubmitter;
  businessId: string;
}

const { useState } = React;

const SuperChoiceTaskSchema = z.object({
  userName: z.string().min(1, { message: 'Required' }),
  userEmployeeIdentificationNumber: z
    .string()
    .max(20, { message: 'Max 20 characters' }),
  userTFN: z.optionalValueObject(z.string(), IndividualTFN),
  superChoice: z.union([SMSFSchema, APRAOrRSASchema, EmployerNominatedSchema]),
});

const getDefaults = (task?: APISuperChoiceTask, subjectUser?: UserProfile) => {
  const type = task?.employeeSubmission?.superChoice?.value?.type ?? '';

  if (type === SuperTypes.APRA_OR_RSA) {
    return {
      ...getSuperChoiceUserDetailsDefaults(task, subjectUser),
      superChoice: {
        superChoiceType: type,
        ...getAPRAOrRSADefaultValues(task?.employeeSubmission?.superChoice),
      },
    };
  }

  if (type === SuperTypes.SMSF) {
    return {
      ...getSuperChoiceUserDetailsDefaults(task, subjectUser),
      superChoice: {
        superChoiceType: type,
        ...getSMSFDefaults(task?.employeeSubmission?.superChoice),
      },
    };
  }

  if (type === SuperTypes.EMPLOYER_NOMINATED_V2) {
    return {
      ...getSuperChoiceUserDetailsDefaults(task, subjectUser),
      superChoice: {
        superChoiceType: type,
        ...getEmployerNominatedDefaults(task?.employeeSubmission?.superChoice),
      },
    };
  }

  if (type === SuperTypes.EMPLOYER_NOMINATED) {
    return {
      ...getSuperChoiceUserDetailsDefaults(task, subjectUser),
      superChoice: {
        superChoiceType: type as SuperTypes,
      },
    };
  }

  return {
    ...getSuperChoiceUserDetailsDefaults(task, subjectUser),
    superChoice: {
      superChoiceType: type as SuperTypes,
      ...getAPRAOrRSADefaultValues(task?.employeeSubmission?.superChoice),
      ...getSMSFDefaults(task?.employeeSubmission?.superChoice),
      ...getEmployerNominatedDefaults(task?.employeeSubmission?.superChoice),
    },
  };
};

export const SuperChoiceTask: React.FC<SuperChoiceTaskProps> = (props) => {
  const { task, canEdit, context, submitAction, businessId } = props;

  const userProfile = auth.useProfile();
  const defaultValues = React.useMemo(
    () =>
      getDefaults(
        task,
        context === 'business' ? undefined : userProfile ?? undefined,
      ),
    [context, task, userProfile],
  );

  const useFormMethods = useForm<z.infer<typeof SuperChoiceTaskSchema>>({
    defaultValues: defaultValues as any,
    resolver: z.zodResolver(SuperChoiceTaskSchema),
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
    watch,
    reset,
  } = useFormMethods;

  React.useEffect(() => {
    reset(defaultValues as any);
  }, [defaultValues, reset]);

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const isUser = context === 'user';
  const isTaskCompleted = task?.taskCompleted();
  const isTaskDisabled =
    isTaskCompleted || !canEdit || isSubmitting || isUploading;
  const isAbleToAnswer = isUser && !isTaskDisabled;

  const watchSelectedSuperType = watch('superChoice.superChoiceType');

  const onSubmit = handleSubmit(async (data) => {
    const { superChoice } = data;
    if (!submitAction || !task) {
      return;
    }
    let selected: ReturnType<SuperChoice['__attributes']> | null = null;
    if (superChoice.superChoiceType === SuperTypes.APRA_OR_RSA) {
      selected = {
        type: 'SUPER_CHOICE_APRA_OR_RSA',
        fundABN: superChoice.fundABN,
        fundName: superChoice.fundName,
        memberNumber: superChoice.memberNumber,
        fundSPINOrUSI: superChoice.fundSPINOrUSI,
        accountName: superChoice.accountName,
      };
    }
    if (superChoice.superChoiceType === SuperTypes.SMSF) {
      selected = {
        type: 'SELF_MANAGED_SUPER_FUND',
        fundABN: superChoice.fundABN,
        fundAccountNumber: superChoice.fundAccountNumber,
        fundAddress: superChoice.fundAddress,
        fundBSB: superChoice.fundBSB,
        fundESA: superChoice.fundESA,
        fundName: superChoice.fundName,
        fundPhoneNumber: superChoice.fundPhoneNumber,
        isTrusteeOrDirector: superChoice.isTrusteeOrDirector,
        requiredDocumentationClaim: superChoice.requiredDocumentationClaim,
      };
    }
    if (superChoice.superChoiceType == SuperTypes.EMPLOYER_NOMINATED_V2) {
      selected = {
        type: 'EMPLOYER_NOMINATED_V2',
        fundABN: superChoice.fundABN,
        fundName: superChoice.fundName,
        fundSPINOrUSI: superChoice.fundSPINOrUSI,
      };
    }
    if (!selected) {
      return;
    }
    await submitAction(
      TaskActionType.SUPER_CHOICE_EMPLOYEE_SUBMISSION,
      z.recursivelyRemoveEmptyStrings({
        taskId: task.taskId,
        submission: {
          superChoice: selected,
          name: data.userName,
          tfn: data.userTFN,
          employeeIdentificationNumber: data.userEmployeeIdentificationNumber,
        },
      }),
    );
  });

  const readyToSubmit =
    !isTaskDisabled && watchSelectedSuperType && !isUploading;

  const taskHeading = (
    <Heading variant="section" textAlign="center">
      {task?.name || 'Super Choice'}
    </Heading>
  );

  const taskBody = (
    <FormProvider {...useFormMethods}>
      <Stack spacing={6} px={{ base: 4, md: 6 }}>
        <SuperChoiceUserDetails isDisabled={!isAbleToAnswer} />
        <SuperTypeSelector isDisabled={!isAbleToAnswer} />

        {watchSelectedSuperType === SuperTypes.APRA_OR_RSA && (
          <APRAOrRSA isDisabled={!isAbleToAnswer} />
        )}

        {watchSelectedSuperType === SuperTypes.SMSF && (
          <SMSF
            isDisabled={!isAbleToAnswer}
            task={task}
            setIsUploading={setIsUploading}
          />
        )}

        {watchSelectedSuperType === SuperTypes.EMPLOYER_NOMINATED_V2 && (
          <SuperChoiceEmployerNominatedFields businessId={businessId} />
        )}

        {(watchSelectedSuperType as any) === SuperTypes.EMPLOYER_NOMINATED && (
          <InfoBox message="This user selected your Xero employer nominated fund prior to August 2021. This function is no longer available. Please have the user complete another super fund selection if required." />
        )}
      </Stack>
    </FormProvider>
  );

  const taskActions = (
    <Center mt={4} py={2}>
      {isUser && (
        <Center>
          <ButtonGroup>
            <Button
              type="submit"
              disabled={!readyToSubmit}
              isLoading={isSubmitting}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </ButtonGroup>
        </Center>
      )}
    </Center>
  );

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        {taskBody}
        <TaskCompleted />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack>
          {taskHeading}
          {taskBody}
        </Stack>
        {taskActions}
      </Flex>
    </Stack>
  );
};

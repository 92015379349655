/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react';
import { errs } from '@zap-onboard/errors';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAPI } from '../services/API';
import { CurrentBusinessIdStore } from '../services/stores/CurrentBusinessIdStore';
import { IdentityTokenStore } from '../services/stores/IdentityTokenStore';
import { useAuthState } from './useAuthState';

export const useAuthSubscriptions = () => {
  const history = useHistory();
  const api = useAPI();
  const toast = useToast();

  useEffect(
    () =>
      useAuthState.subscribe((state, prev) => {
        if (state.user && !prev.user && state.currentBusinessId) {
          const member = state.user.memberships.find(
            (m) => m.businessId === state.currentBusinessId,
          );
          if (!member?.role?.isBusinessManager()) {
            useAuthState.setState({ currentBusinessId: undefined });
          }
        }
      }),
    [],
  );

  useEffect(() => {
    return api.$loginFlow.subscribe((flow) => {
      api.setToken(undefined);
      flow.allCases({
        COMPLETE: ({ accessToken, redirectTo }) => {
          api.setToken(accessToken);
          if (redirectTo) {
            history.push(redirectTo.toPath());
          }
        },
        MAGIC_LINK_SENT: () => {
          useAuthState.setState({
            loginFlow: {
              type: 'EMAIL_SENT',
            },
          });
        },
        NOT_REGISTERED: () => {
          useAuthState.setState({
            loginFlow: {
              type: 'NOT_REGISTERED',
            },
          });
        },
        VERIFY_MFA: ({ mfaSources, verifyMFAToken }) => {
          history.push('/login');
          useAuthState.setState({
            loginFlow: {
              type: 'VERIFY_MFA',
              mfaToken: verifyMFAToken,
              sources: mfaSources,
            },
          });
        },
      });
    });
  }, []);

  useEffect(
    () =>
      useAuthState.subscribe(({ currentBusinessId, hasInitialized }) => {
        api.setCurrentBusinessId(currentBusinessId);
        if (hasInitialized) {
          CurrentBusinessIdStore.set(currentBusinessId);
        }
      }),
    [],
  );

  useEffect(
    () =>
      api.$tokenUpdated.subscribe((token) => {
        IdentityTokenStore.set(token);
        if (!token) {
          useAuthState.setState({
            user: undefined,
          });
        }
      }),
    [],
  );

  useEffect(
    () =>
      api.$error.subscribe((err) => {
        if (err instanceof errs.InvalidSessionTokenError) {
          api.setToken(undefined);
          if (!toast.isActive('session-expired')) {
            toast({
              title: 'Your session has expired',
              description: 'Please log in again',
              id: 'session-expired',
              status: 'info',
              position: 'top',
            });
          }
        }
      }),
    [],
  );
};

import { Box, Center } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Spinner } from '../../components';
import { intercom } from '../../libs';

import { usePDFState } from '../usePDFState';

export const PDFViewport: React.FC = ({ children }) => {
  const size = usePDFState((s) => s.outerSize);
  const scale = usePDFState((s) => s.scale);

  intercom.useHide();
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Box overflow="auto" width={size.width} height={size.height}>
        {scale === 0 ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          children
        )}
      </Box>
    </>
  );
};

import * as React from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Center,
  Heading,
  Select,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Stack,
  useToast,
  Avatar,
  HStack,
  Link as CLink,
  Badge,
} from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

import {
  DeputyEmployeeDetailsResponse,
  DeputyLinkEmployeeTask,
  DeputyLocationListResponse,
  TaskActionType,
} from '@zap-onboard/api-client';
import { Link } from 'react-router-dom';

import { useAPI } from '../../../../../services/API';
import { onError } from '../../../../../libs/errorLib';
import { InfoBox } from '../../../../InfoBox';
import { Spinner } from '../../../../Spinner';
import { TaskActionSubmitter } from '../../../../../hooks/data/flow/useTaskActionSubmission';
import { TaskCompleted, TaskSkipped } from '../../TaskStatus';
import { trackWorkCompletion } from '../../../../../helpers/tracking';

type DeputyTaskProps = {
  context: 'user' | 'business';
  canEdit: boolean;
  task?: DeputyLinkEmployeeTask;
  submitAction?: TaskActionSubmitter;
};

const { useState, useEffect, useCallback } = React;

// To be sent instead of the deputyEmployeeId if creation option chosen
const CREATE_EMPLOYEE = 'CREATE_NEW_EMPLOYEE';

export const DeputyTask: React.FC<DeputyTaskProps> = (props) => {
  const { task, submitAction, context, canEdit } = props;
  const api = useAPI();
  const toast = useToast();

  const isDeputyConnected = task?.isBusinessLinkedToDeputy;
  const isUserProfileCompleted = task?.employeeProfileComplete;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [sendInvite, setSendInvite] = useState<boolean>(true);
  const [deputyLocationId, setDeputyLocationId] = useState<number | undefined>(
    task?.linkedEmployee?.deputyLocationId ??
      task?.inferredEmployee?.deputyLocationId,
  );
  const [deputyLocations, setDeputyLocations] =
    useState<DeputyLocationListResponse[]>();
  const [deputyEmployees, setDeputyEmployees] = useState<
    DeputyEmployeeDetailsResponse[]
  >([]);

  const [deputyEmployeeId, setDeputyEmployeeId] = useState<
    number | typeof CREATE_EMPLOYEE
  >(
    task?.linkedEmployee?.deputyEmployeeId ??
      task?.inferredEmployee?.deputyEmployeeId ??
      CREATE_EMPLOYEE,
  );

  const isBusiness = context === 'business';
  const isTaskCompleted = task?.taskCompleted();
  const isTaskDisabled =
    isTaskCompleted || isLoading || isSubmitting || !isBusiness || !canEdit;

  const handleLink = async () => {
    if (!deputyLocationId) {
      return;
    }

    if (submitAction && task) {
      setIsSubmitting(true);
      const result = await submitAction(
        TaskActionType.DEPUTY_LINK_EMPLOYEE_PERFORM,
        {
          taskId: task?.taskId,
          sendInvite,
          deputyEmployeeId:
            deputyEmployeeId === CREATE_EMPLOYEE ? undefined : deputyEmployeeId,
          deputyLocationId,
        },
      );
      result?.map(() => {
        trackWorkCompletion({
          workType: 'Administrative',
          workDetail: 'Deputy',
        });
        toast({
          title: 'Success!',
          description: 'Employee linked to Deputy',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
      });
      setIsSubmitting(false);
    }
  };

  const getDeputyLocations = useCallback(async () => {
    await api
      .deputy()
      .getLocations()
      .map((e) => {
        setDeputyLocations(e);
        setDeputyLocationId(e[0]?.deputyLocationId);
      }, onError);
  }, [api]);

  const getDeputyEmployees = useCallback(async () => {
    await api.deputy().getEmployees().map(setDeputyEmployees, onError);
  }, [api]);

  useEffect(() => {
    if (context === 'business' && isDeputyConnected) {
      Promise.all([getDeputyLocations(), getDeputyEmployees()]).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [context, isDeputyConnected, getDeputyLocations, getDeputyEmployees]);

  const selectedDeputyEmployee = React.useMemo(() => {
    if (task?.inferredEmployee?.deputyEmployeeId === deputyEmployeeId) {
      return task?.inferredEmployee;
    }
    if (task?.linkedEmployee?.deputyEmployeeId === deputyEmployeeId) {
      return task?.linkedEmployee;
    }
    return deputyEmployees?.find(
      (e) => e.deputyEmployeeId === deputyEmployeeId,
    );
  }, [
    task?.inferredEmployee,
    task?.linkedEmployee,
    deputyEmployees,
    deputyEmployeeId,
  ]);

  useEffect(() => {
    if (selectedDeputyEmployee?.deputyLocationId) {
      if (
        deputyLocations?.find(
          (location) =>
            location.deputyLocationId ===
            selectedDeputyEmployee.deputyLocationId,
        )
      ) {
        setDeputyLocationId(selectedDeputyEmployee.deputyLocationId);
      }
    }
  }, [selectedDeputyEmployee, deputyLocations]);

  const taskHeading = (
    <Heading variant="section" textAlign="center">
      {task?.name || 'Deputy'}
    </Heading>
  );

  const taskBody = (
    <Stack spacing={3}>
      <FormControl>
        <FormLabel>Location</FormLabel>
        <Select
          placeholder="Please select an option"
          isDisabled={isTaskDisabled}
          value={deputyLocationId}
          onChange={(e) => setDeputyLocationId(Number(e.target.value))}
        >
          {deputyLocations?.map((e) => (
            <option key={e.deputyLocationId} value={e.deputyLocationId}>
              {e.name}
            </option>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Employee</FormLabel>
        <Select
          value={deputyEmployeeId}
          isDisabled={isTaskDisabled || task?.inferredEmployee != null}
          onChange={(e) =>
            setDeputyEmployeeId(
              Number.isNaN(Number(e.target.value))
                ? CREATE_EMPLOYEE
                : Number(e.target.value),
            )
          }
        >
          {/**
                Archived employees may be linked to a user or have the same
                email. In the case that an archived employee is selected, we
                will reactivate them
              */}
          {task?.inferredEmployee?.deputyEmployeeId !==
            task?.linkedEmployee?.deputyEmployeeId &&
            task?.inferredEmployee?.archivedInDeputy && (
              <option value={task?.inferredEmployee.deputyEmployeeId}>
                {task?.inferredEmployee.deputyName}
              </option>
            )}

          {task?.linkedEmployee?.archivedInDeputy && (
            <option value={task?.linkedEmployee.deputyEmployeeId}>
              {'>'} {task?.linkedEmployee.deputyName}
            </option>
          )}

          {/**
                If a user has the same contact information as a user in deputy
                they will not be able to create a new account.
              */}
          {!task?.inferredEmployee && (
            <option value={CREATE_EMPLOYEE}>Create New Employee</option>
          )}

          {deputyEmployees.map((e) => (
            <option key={e.deputyEmployeeId} value={e.deputyEmployeeId}>
              {e.deputyEmployeeId === task?.linkedEmployee?.deputyEmployeeId &&
                '>'}{' '}
              {e.deputyName ?? e.deputyEmployeeId}
            </option>
          ))}
        </Select>
      </FormControl>

      {!selectedDeputyEmployee?.hasAcceptedInvite && (
        <FormControl pt={1}>
          <Checkbox
            isChecked={sendInvite}
            isDisabled={isTaskDisabled}
            onChange={() => setSendInvite(!sendInvite)}
          >
            Send Deputy email invite?
          </Checkbox>
        </FormControl>
      )}

      {selectedDeputyEmployee?.archivedInDeputy && (
        <Flex justify="center">
          <InfoBox
            icon={MdInfoOutline}
            iconColor="brand.yellow"
            heading="Archived Employee"
            message="This deputy employee has been archived previously. Linking this user will reactivate their account and resend any invitations to confirm their deputy account."
            w="90%"
          />
        </Flex>
      )}

      {selectedDeputyEmployee && (
        <Stack my={2} p={2} spacing={4} layerStyle="base">
          <HStack>
            <Avatar
              name={selectedDeputyEmployee.deputyName}
              src={selectedDeputyEmployee.deputyAvatarURL}
            />
            <Stack spacing={0}>
              <Text fontWeight="bold">{selectedDeputyEmployee.deputyName}</Text>

              {selectedDeputyEmployee?.hasAcceptedInvite && (
                <Badge ml={2} colorScheme="green">
                  Invite Accepted
                </Badge>
              )}
              <Text>{selectedDeputyEmployee.email?.asJSON()}</Text>
              <CLink
                color="brand.blue"
                fontSize="sm"
                href={selectedDeputyEmployee.manageLink}
                target="_blank"
              >
                View in Deputy
              </CLink>
            </Stack>
          </HStack>
        </Stack>
      )}
    </Stack>
  );

  const taskActions = isBusiness && (
    <Center mt={4}>
      <ButtonGroup>
        <Button
          onClick={handleLink}
          isLoading={isSubmitting}
          isDisabled={
            isTaskDisabled || !isUserProfileCompleted || !deputyLocationId
          }
        >
          {(() => {
            if (
              selectedDeputyEmployee &&
              task?.linkedEmployee &&
              selectedDeputyEmployee.deputyEmployeeId ===
                task?.linkedEmployee.deputyEmployeeId
            ) {
              return 'Sync';
            }
            if (selectedDeputyEmployee) {
              return 'Link';
            }
            return 'Create';
          })()}
        </Button>
      </ButtonGroup>
    </Center>
  );

  if (!isBusiness) {
    return (
      <Stack spacing={2} w="90%">
        {taskHeading}
        <Flex justify="center">
          <InfoBox
            icon={MdInfoOutline}
            heading="Business task"
            message="This task does not require your action. It will be handled by the business."
          />
        </Flex>
      </Stack>
    );
  }

  if (isLoading) {
    return (
      <Center h="100%" minH={{ md: '360px' }}>
        <Spinner />
      </Center>
    );
  }

  if (task && !isDeputyConnected) {
    return (
      <Stack spacing={2} w="90%">
        {taskHeading}
        <Flex justify="center">
          <InfoBox
            icon={MdInfoOutline}
            heading="Deputy is not connected"
            message="Until you have connected Deputy in your businesses settings, you will not be able to complete this task."
          >
            <Center pt={2}>
              <Button as={Link} to="/business/settings">
                Settings
              </Button>
            </Center>
          </InfoBox>
        </Flex>
      </Stack>
    );
  }

  if (task) {
    if (!isUserProfileCompleted) {
      return (
        <Stack spacing={2} w="90%">
          {taskHeading}
          <Flex justify="center">
            <InfoBox
              icon={MdInfoOutline}
              heading="Incomplete user profile"
              message="Until they complete their profile you will not be able to sync their data to Deputy."
            />
          </Flex>
          {taskActions}
        </Stack>
      );
    }

    if (!deputyLocations || deputyLocations.length === 0) {
      return (
        <Stack spacing={2} w="90%">
          {taskHeading}
          <Flex justify="center">
            <InfoBox
              icon={MdInfoOutline}
              heading="No locations"
              message="The Deputy account does not have any locations."
            />
          </Flex>
          {taskActions}
        </Stack>
      );
    }

    if (deputyLocations.length === 0 && !deputyLocationId) {
      return (
        <Stack spacing={2} w="90%">
          {taskHeading}
          <Flex justify="center">
            <InfoBox icon={MdInfoOutline} message="A location is required" />
          </Flex>
          {taskActions}
        </Stack>
      );
    }

    if (!deputyEmployeeId) {
      return (
        <Stack spacing={2} w="90%">
          {taskHeading}
          <Flex justify="center">
            <InfoBox icon={MdInfoOutline} message="An employee is required" />
          </Flex>
          {taskActions}
        </Stack>
      );
    }
  }

  if (task?.status === 'BUSINESS_SKIPPED') {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        <TaskSkipped />
        {taskBody}
      </Stack>
    );
  }

  if (isTaskCompleted) {
    return (
      <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }}>
        {taskHeading}
        {taskBody}
        <TaskCompleted />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} w={{ base: '90%', md: '75%', xl: '60%' }} h="100%">
      <Flex
        direction="column"
        justify="space-between"
        h="100%"
        minH={{ md: '360px' }}
      >
        <Stack>
          {taskHeading}
          {taskBody}
        </Stack>
        {taskActions}
      </Flex>
    </Stack>
  );
};

import { createContext, useContext, useMemo } from 'react';
import { getDefaultFont } from '@zap-onboard/pdfme-common';

const FontContext = createContext(getDefaultFont());

export const usePDFFontContext = () => {
  const font = useContext(FontContext);
  return font;
};

export const ProvidePDFFontContext: React.FC = ({ children }) => {
  return (
    <FontContext.Provider value={useMemo(getDefaultFont, [])}>
      {children}
    </FontContext.Provider>
  );
};

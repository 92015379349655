"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProofOfIdentitySubmission = exports.NonPhotographicPrimarySubmission = exports.PhotographicPrimarySubmission = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../../../file");
const ResponseBase_1 = require("../../../ResponseBase");
class PhotographicPrimarySubmission extends ResponseBase_1.ResponseBase {
}
PhotographicPrimarySubmission.identificationType = 'PHOTOGRAPHIC';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['PHOTOGRAPHIC']),
    (0, tslib_1.__metadata)("design:type", String)
], PhotographicPrimarySubmission.prototype, "identificationType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], PhotographicPrimarySubmission.prototype, "primaryDocument", void 0);
exports.PhotographicPrimarySubmission = PhotographicPrimarySubmission;
class NonPhotographicPrimarySubmission extends ResponseBase_1.ResponseBase {
}
NonPhotographicPrimarySubmission.identificationType = 'NON_PHOTOGRAPHIC';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['NON_PHOTOGRAPHIC']),
    (0, tslib_1.__metadata)("design:type", String)
], NonPhotographicPrimarySubmission.prototype, "identificationType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], NonPhotographicPrimarySubmission.prototype, "primaryDocument", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], NonPhotographicPrimarySubmission.prototype, "secondaryDocument", void 0);
exports.NonPhotographicPrimarySubmission = NonPhotographicPrimarySubmission;
class ProofOfIdentitySubmission extends designed_1.Entity.Union.define({
    key: 'identificationType',
    entries: [PhotographicPrimarySubmission, NonPhotographicPrimarySubmission],
}) {
}
exports.ProofOfIdentitySubmission = ProofOfIdentitySubmission;

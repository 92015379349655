var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-namespace */
import * as React from 'react';
import { DndContext, closestCenter, KeyboardSensor, useSensor, useSensors, DragOverlay, TouchSensor, MouseSensor, } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, useSortable, rectSortingStrategy, } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
/**
 * Simple sortable wrappers for grid based sorting.
 * Designed initially for use only with the `card.Item` and `card.Layout`
 * components. Children must forwardRefs and accept a spread of props.
 *
 * This component is still suitable to be used with onClick handlers and links
 * due to activation for touch and mouse usage being slightly delayed.
 */
export var Sort;
(function (Sort) {
    // eslint-disable-next-line react/function-component-definition
    function Parent(_a) {
        var children = _a.children, sortedItems = _a.sortedItems, handleChange = _a.handleChange, getId = _a.getId, renderPlaceholder = _a.renderPlaceholder;
        var _b = React.useState(null), active = _b[0], setActive = _b[1];
        var sensors = useSensors(useSensor(MouseSensor, {
            activationConstraint: {
                distance: 15,
            },
        }), useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }), useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }));
        return (_jsxs(DndContext, __assign({ sensors: sensors, collisionDetection: closestCenter, onDragCancel: function () { return setActive(null); }, onDragStart: function (_a) {
                var _b;
                var id = _a.active.id;
                return setActive((_b = sortedItems.find(function (item) { return getId(item) === id; })) !== null && _b !== void 0 ? _b : null);
            }, onDragEnd: function (_a) {
                var active = _a.active, over = _a.over;
                if (typeof active.id !== 'string' || typeof (over === null || over === void 0 ? void 0 : over.id) !== 'string') {
                    return;
                }
                if (active.id !== over.id) {
                    handleChange(active.id, over.id);
                }
                setActive(null);
            } }, { children: [_jsx(SortableContext, __assign({ items: sortedItems.map(getId), strategy: rectSortingStrategy }, { children: children }), void 0), renderPlaceholder && (_jsx(DragOverlay, { children: active && renderPlaceholder(active) }, void 0))] }), void 0));
    }
    Sort.Parent = Parent;
    /**
     * The React.cloneElement pattern is a bit magical but I think it's
     * appropriately easy to use with the `card.Item` component which is 90% of
     * our usecase
     */
    Sort.Child = function (_a) {
        var children = _a.children, id = _a.id;
        var _b = useSortable({ id: id }), attributes = _b.attributes, listeners = _b.listeners, setNodeRef = _b.setNodeRef, transform = _b.transform, transition = _b.transition, active = _b.active;
        var isCurrentlyLifted = (active === null || active === void 0 ? void 0 : active.id) === id;
        var style = {
            transform: CSS.Transform.toString(transform),
            transition: transition,
            opacity: isCurrentlyLifted ? 0.3 : 1,
        };
        var child = React.cloneElement(children, __assign(__assign({ ref: setNodeRef, style: style }, attributes), listeners));
        return child;
    };
})(Sort || (Sort = {}));

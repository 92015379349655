import * as React from 'react';
import { Box, Button, Heading, Stack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { SiXero } from 'react-icons/si';

import { Email, GoogleConnectPurpose } from '@zap-onboard/api-client';
import {
  useGoogleConnect,
  useHandler,
  useXeroConsentConfig,
} from '../../../../hooks';
import { z } from '../../../../helpers/schema';

import { inputs } from '@zap-onboard/web-components';
import {
  Divider,
  GoogleConnectButton,
  LoadingState,
} from '../../../../components';
import { onError } from '../../../../libs';

export const BeginLogin: React.FC = () => {
  const { isLoadingXeroConsentConfig, startXeroLogin } = useXeroConsentConfig();
  const { connectGoogle, isGoogleConnecting } = useGoogleConnect({
    purpose: GoogleConnectPurpose.SIGN_IN,
  });

  const LoginFormSchema = z.object({
    email: z.valueObject(z.string(), Email),
  });

  const useFormMethods = useForm<z.infer<typeof LoginFormSchema>>({
    defaultValues: {
      email: '',
    },
    resolver: z.zodResolver(LoginFormSchema),
  });
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useFormMethods;

  const [login, isLoggingIn] = useHandler((api) => api.identity().beginLogin, {
    always: {
      onError,
    },
  });

  const onSubmit = handleSubmit(({ email }) => login({ email }));

  if (isGoogleConnecting || isLoggingIn) {
    return <LoadingState />;
  }

  return (
    <Box w="100%">
      <FormProvider {...useFormMethods}>
        <form onSubmit={onSubmit}>
          <Stack spacing={4}>
            <Heading size="md">Login</Heading>
            <inputs.TextInput name="email" label="Email" />
            <Button
              data-testid="login"
              isLoading={isSubmitting}
              isDisabled={isSubmitting}
              type="submit"
            >
              Log in
            </Button>
            <Divider text="Or" />
            <Button
              leftIcon={<SiXero />}
              onClick={startXeroLogin}
              color="brand.xero.fg"
              background="brand.xero.bg"
              variant="button"
              isLoading={isLoadingXeroConsentConfig}
              isDisabled={isLoadingXeroConsentConfig}
            >
              Login with Xero
            </Button>

            <GoogleConnectButton
              context="signin"
              text="signin"
              onIdentityTokenReturned={connectGoogle}
            />
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

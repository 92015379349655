export const prettyTaskStatus = (taskStateId?: string): string => {
  let status;
  if (taskStateId) {
    status = {
      VISA_DETAILS_UNSUBMITTED: 'Awating employee submission',
      VISA_DETAILS_SUBMITTED: 'Awaiting acceptance',
      VISA_DETAILS_COMPLETED: 'Completed',
      META_SKIPPED: 'Skipped',
    }[taskStateId];
  }
  return status || 'Preview';
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxGroup = exports.Union = exports.isTextLike = exports.Checkbox = exports.Signature = exports.Time = exports.Dropdown = exports.DropdownOption = exports.Text = exports.PROFILE_PREFILL_TYPES = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TimeAndZone_1 = require("../../../utils/time/TimeAndZone");
const ResponseBase_1 = require("../../../ResponseBase");
const Value = (0, tslib_1.__importStar)(require("./Value"));
const Validation = (0, tslib_1.__importStar)(require("./Validation"));
const Utils_1 = require("./Utils");
const AbsoluteDate_1 = require("../../../utils/time/AbsoluteDate");
const IntegerRange_1 = require("../../../utils/IntegerRange");
exports.PROFILE_PREFILL_TYPES = {
    email: { label: 'Email' },
    name: { label: 'Name' },
    primaryContactNumber: { label: 'Phone Number' },
    birthday: { label: 'Birthday' },
    address: { label: 'Address' },
};
class TextLike extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextLike.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextLike.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Utils_1.Placement),
    (0, tslib_1.__metadata)("design:type", Utils_1.Placement)
], TextLike.prototype, "placement", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], TextLike.prototype, "page", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(Utils_1.alignment),
    (0, tslib_1.__metadata)("design:type", String)
], TextLike.prototype, "alignment", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], TextLike.prototype, "fontSize", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextLike.prototype, "fontName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextLike.prototype, "fontColor", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextLike.prototype, "backgroundColor", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], TextLike.prototype, "characterSpacing", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], TextLike.prototype, "lineHeight", void 0);
class Text extends TextLike {
    makeValue(value) {
        if (typeof value !== 'string') {
            throw errors_1.errs.UnexpectedError.create(`Value must be a string for ${this.name}`);
        }
        return Value.Union.create({
            type: 'text',
            fieldId: this.fieldId,
            value: value,
        });
    }
    getDefaultValue({ profile }) {
        if (!profile || !this.prefill) {
            return this.text || undefined;
        }
        if (this.prefill === 'name') {
            return profile.getFullName();
        }
        const value = profile[this.prefill];
        if (!value) {
            return undefined;
        }
        if (typeof value === 'string') {
            return value;
        }
        if (value instanceof AbsoluteDate_1.AbsoluteDate) {
            return value.asDMYFormat();
        }
        if ('asString' in value) {
            return value.asString();
        }
        if ('asJSON' in value) {
            return value.asJSON();
        }
        return undefined;
    }
    validationMessage(value) {
        if (!value || typeof value !== 'string') {
            if (this.isOptional) {
                return undefined;
            }
            return 'Required';
        }
        if (this.characterLimit && value.trim().length > this.characterLimit) {
            return `Must be less than ${this.characterLimit} characters`;
        }
        if (this.validation) {
            return this.validation.validationMessage(value);
        }
        return undefined;
    }
}
Text.type = 'text';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Text.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Text.prototype, "text", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: Validation.TextValidation }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(Validation.TextValidation),
    (0, tslib_1.__metadata)("design:type", Validation.TextValidation)
], Text.prototype, "validation", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Text.prototype, "characterLimit", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Text.prototype, "isOptional", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(Object.keys(exports.PROFILE_PREFILL_TYPES)),
    (0, tslib_1.__metadata)("design:type", String)
], Text.prototype, "prefill", void 0);
exports.Text = Text;
class DropdownOption extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DropdownOption.prototype, "label", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DropdownOption.prototype, "optionId", void 0);
exports.DropdownOption = DropdownOption;
class Dropdown extends TextLike {
    makeValue(value) {
        if (typeof value !== 'string') {
            throw errors_1.errs.UnexpectedError.create(`Value must be a string for ${this.name}`);
        }
        const option = this.options.find((o) => o.optionId === value);
        if (!option) {
            return undefined;
        }
        return Value.Union.create({
            type: 'dropdown',
            fieldId: this.fieldId,
            optionId: option.optionId,
        });
    }
    getDefaultValue() {
        const option = this.options.find((o) => o.optionId === this.defaultOptionId);
        if (!option) {
            return undefined;
        }
        return option.optionId;
    }
    validationMessage(value) {
        const option = this.options.find((o) => o.optionId === value);
        if (!option) {
            if (this.isOptional) {
                return undefined;
            }
            return 'Required';
        }
        return undefined;
    }
}
Dropdown.type = 'dropdown';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Dropdown.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Dropdown.prototype, "defaultOptionId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], Dropdown.prototype, "isOptional", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: DropdownOption }),
    (0, class_validator_1.IsInstance)(DropdownOption, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], Dropdown.prototype, "options", void 0);
exports.Dropdown = Dropdown;
class Time extends TextLike {
    makeValue() {
        return null;
    }
    getDefaultValue() {
        return undefined;
    }
    validationMessage() {
        return undefined;
    }
}
Time.type = 'time';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Time.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(Object.keys(TimeAndZone_1.TimeAndZone.FORMATS)),
    (0, tslib_1.__metadata)("design:type", Object)
], Time.prototype, "format", void 0);
exports.Time = Time;
class Signature extends ResponseBase_1.ResponseBase {
    validationMessage(value) {
        if (typeof value !== 'boolean' || !value) {
            return 'Required';
        }
        return undefined;
    }
    makeValue(value) {
        if (typeof value !== 'boolean') {
            throw errors_1.errs.UnexpectedError.create(`Value must be a boolean for ${this.name}`);
        }
        return Value.Union.create({
            type: 'signature',
            fieldId: this.fieldId,
            signed: value,
        });
    }
    getDefaultValue() {
        return undefined;
    }
}
Signature.type = 'signature';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Signature.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Signature.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Signature.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Signature.prototype, "page", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Utils_1.Placement),
    (0, tslib_1.__metadata)("design:type", Utils_1.Placement)
], Signature.prototype, "placement", void 0);
exports.Signature = Signature;
class Checkbox extends ResponseBase_1.ResponseBase {
    validationMessage(value, fields, values, checkboxGroups) {
        const checkboxGroup = checkboxGroups.find((g) => g.checkboxGroupId === this.checkboxGroupId);
        if (!checkboxGroup) {
            throw errors_1.errs.UnexpectedError.create(`Could not find checkbox group ${this.checkboxGroupId}`);
        }
        const group = CheckboxGroup.create(checkboxGroup);
        if (!group.validationRange) {
            return undefined;
        }
        const fieldsInGroup = fields.filter((field) => field.type === 'checkbox' &&
            field.checkboxGroupId === this.checkboxGroupId);
        const checkedCountForGroup = fieldsInGroup
            .map((f) => (this.fieldId === f.fieldId ? value : values[f.fieldId]))
            .filter((v) => v).length;
        const valid = group.validationRange.inclusiveContains(checkedCountForGroup);
        return valid ? undefined : group.getValidationMessage();
    }
    makeValue(value) {
        if (typeof value !== 'boolean') {
            throw errors_1.errs.UnexpectedError.create(`Value must be a boolean for ${this.name}`);
        }
        return Value.Union.create({
            type: 'checkbox',
            fieldId: this.fieldId,
            checked: value,
        });
    }
    getDefaultValue() {
        return false;
    }
}
Checkbox.type = 'checkbox';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], Checkbox.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Checkbox.prototype, "fieldId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], Checkbox.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Checkbox.prototype, "page", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Utils_1.Placement),
    (0, tslib_1.__metadata)("design:type", Utils_1.Placement)
], Checkbox.prototype, "placement", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], Checkbox.prototype, "checkboxGroupId", void 0);
exports.Checkbox = Checkbox;
const isTextLike = (data) => ['text', 'time', 'dropdown'].includes(data.type);
exports.isTextLike = isTextLike;
class Union extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [Text, Time, Signature, Checkbox, Dropdown],
}) {
    makeValue(value) {
        return this.value.makeValue(value);
    }
    static validationMessageForData(field, value, fields, values, checkboxGroups) {
        return this.create(field).validationMessage(value, fields, values, checkboxGroups);
    }
    validationMessage(value, fields, values, checkboxGroups) {
        return this.value.validationMessage(value, fields, values, checkboxGroups);
    }
    /**
     * TODO: Debug any causing `test:all` to fail for API when using
     * "ProfileResponse"
     */
    getDefaultValue(args = {}) {
        const value = this.value.getDefaultValue(args);
        return value ? this.makeValue(value) ?? undefined : undefined;
    }
}
exports.Union = Union;
class CheckboxGroup extends ResponseBase_1.ResponseBase {
    getValidationMessage() {
        if (!this.validationRange) {
            return '';
        }
        const { min, max } = this.validationRange;
        if (min === max) {
            return `Select ${min}`;
        }
        if (min && !max) {
            return `Select at least ${min}`;
        }
        if (!min && max) {
            return `Select at most ${max}`;
        }
        return `Select between ${min} and ${max}`;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], CheckboxGroup.prototype, "checkboxGroupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(IntegerRange_1.IntegerRange),
    (0, tslib_1.__metadata)("design:type", IntegerRange_1.IntegerRange)
], CheckboxGroup.prototype, "validationRange", void 0);
exports.CheckboxGroup = CheckboxGroup;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperChoiceTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const BaseTask_1 = require("../Task/BaseTask");
const SuperChoiceEmployeeSubmission_1 = require("./SuperChoiceEmployeeSubmission");
class SuperChoiceTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(SuperChoiceEmployeeSubmission_1.SuperChoiceEmployeeSubmission),
    (0, tslib_1.__metadata)("design:type", SuperChoiceEmployeeSubmission_1.SuperChoiceEmployeeSubmission)
], SuperChoiceTask.prototype, "employeeSubmission", void 0);
exports.SuperChoiceTask = SuperChoiceTask;

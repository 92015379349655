import * as React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
  useDisclosure,
  Stack,
  Center,
  Spacer,
  Button,
} from '@chakra-ui/react';
import { MdAddBusiness, MdMenu } from 'react-icons/md';

import { useMenuItems } from '../../menuItems';

import { CompactMenuItem } from '../../../../components';
import { SelectBusinessDropdown } from '../../SelectBusinessDropdown';
import { SetupBusinessModal } from '../../../Business/Settings/Business/SetupBusinessModal';
import { NotificationDrawerTrigger } from '../Notifications';
import { auth } from '../../../../auth';

export const NavBarMenu: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const managerMemberships = auth.useBusinessManagers();
  const manager = auth.useCurrentBusinessManager();

  const context = manager ? 'business' : 'user';
  const registerBusinessDisclosure = useDisclosure();

  return (
    <Center align="center" h="100%">
      <IconButton
        aria-label="menu"
        icon={<MdMenu />}
        colorScheme="white"
        size="24px"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="white" />
          <DrawerHeader bg="brand.blue" color="white">
            Menu
          </DrawerHeader>
          <DrawerBody mx={2} px={0}>
            <Stack spacing={1} w="100%" pl={2} pt={{ base: 0, large: 6 }}>
              {useMenuItems({
                context,
                showMobileItems: true,
                role: manager?.role,
              }).map(({ label, route, icon, subMenu }) => (
                <CompactMenuItem
                  key={label}
                  label={label}
                  route={route}
                  Icon={icon}
                  onClick={onClose}
                  subMenu={subMenu}
                />
              ))}
              <Stack>
                <SelectBusinessDropdown />
                {managerMemberships.length > 0 && (
                  <>
                    <Spacer />
                    <SetupBusinessModal {...registerBusinessDisclosure} />
                    <Button
                      onClick={registerBusinessDisclosure.onOpen}
                      leftIcon={<MdAddBusiness />}
                      mx={8}
                      my={3}
                      variant="outline"
                    >
                      Register business
                    </Button>
                  </>
                )}
              </Stack>

              <Spacer />
              <NotificationDrawerTrigger />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Center>
  );
};

import { Button, useDisclosure } from '@chakra-ui/react';
import { membership } from '@zap-onboard/api-client';
import { modal } from '@zap-onboard/web-components';
import { FaPassport } from 'react-icons/fa';
import { VisaCheckWizard } from './VisaCheckWizard';

type Props = {
  memberId: membership.GetMember.Response['memberId'];
  buttonText: string;
  buttonWidth?: string;
};

export const VisaCheckModal: React.FC<Props> = ({
  memberId,
  buttonText,
  buttonWidth,
}) => {
  const disclosure = useDisclosure();

  return (
    <modal.Simple
      header="Visa Work Rights"
      _modalContent={{
        maxW: { base: '700px', md: '1300px' },
      }}
      disclosure={disclosure}
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={({ onOpen }) => (
        <Button
          leftIcon={<FaPassport />}
          onClick={onOpen}
          variant="outline"
          color="brand.dark-gray"
          borderColor="brand.dark-gray"
          borderRadius="lg"
          w={buttonWidth}
        >
          {buttonText}
        </Button>
      )}
    >
      <VisaCheckWizard onClose={disclosure.onClose} memberId={memberId} />
    </modal.Simple>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIClient = void 0;
const tslib_1 = require("tslib");
require("reflect-metadata");
const designed_1 = require("designed");
const transports_1 = require("./utils/transports");
const config_1 = require("./utils/config");
const identity_1 = require("./identity");
const flow = (0, tslib_1.__importStar)(require("./flow"));
const BusinessClient_1 = require("./business/BusinessClient");
const AdminClient_1 = require("./admin/AdminClient");
const PositionClient_1 = require("./position/PositionClient");
const handler_1 = require("./utils/handler");
const HealthCheck_1 = require("./meta/HealthCheck");
const design_1 = require("./flow/design");
const FileClient_1 = require("./file/FileClient");
const billing_1 = require("./billing");
const GovClient_1 = require("./gov/GovClient");
const DeputyClient_1 = require("./deputy/DeputyClient");
const XeroClient_1 = require("./xero/XeroClient");
const certification_1 = require("./certification");
const notification_1 = require("./notification");
const reminder_1 = require("./reminder");
const contact_1 = require("./contact");
const video_1 = require("./video");
const myob_1 = require("./myob");
const membership_1 = require("./membership");
const visa_1 = require("./visa");
const cloudPayroll_1 = require("./cloudPayroll");
class APIClient {
    constructor(config) {
        this.config = config;
        this.$loginFlow = designed_1.Topic.create();
        this.getCurrentBusinessId = () => designed_1.Optional.of(this.currentBusinessId);
        this.rawToken = this.config.identityToken;
        this.$tokenUpdated = designed_1.Topic.create();
        this.setToken = (token) => {
            if (this.rawToken === token) {
                return;
            }
            this.rawToken = token;
            this.$tokenUpdated.publish(token);
        };
        this.getToken = () => designed_1.Optional.of(this.rawToken);
        this.$error = designed_1.Topic.create();
        this.http = new transports_1.HTTPTransport(this.config, this.getToken, this.getCurrentBusinessId);
        this.health = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('health')
            .get(HealthCheck_1.HealthCheck.fromJSON.bind(HealthCheck_1.HealthCheck)));
        this.devAction = (0, handler_1.handler)(this, async (action, body) => this.http
            .request()
            .to('/devaction')
            .bodyJSON({ ...body, action })
            .post());
        this.$tokenUpdated.subscribe((token) => (this.rawToken = token));
        this.$loginFlow.subscribe((res) => res.is('COMPLETE').tap((r) => this.$tokenUpdated.publish(r.accessToken)));
    }
    static create(config) {
        return new APIClient((0, config_1.solidifyConfig)(config));
    }
    setCurrentBusinessId(businessId) {
        if (this.currentBusinessId === businessId) {
            return;
        }
        this.currentBusinessId = businessId;
    }
    identity() {
        return (this.identityClient || (this.identityClient = new identity_1.IdentityClient(this.http, this.$error, this.$tokenUpdated, this.$loginFlow)));
    }
    flows() {
        return (this.flowClient || (this.flowClient = new flow.Client(this.http, this.$error, this.flowDesigns(), this.file())));
    }
    flowDesigns() {
        return (this.flowDesignClient || (this.flowDesignClient = new design_1.FlowDesignClient(this.http, this.$error)));
    }
    business() {
        return (this.businessClient || (this.businessClient = new BusinessClient_1.Client(this.http, this.$error)));
    }
    billing() {
        return (this.billingClient || (this.billingClient = new billing_1.BillingClient(this.http, this.$error)));
    }
    position() {
        return (this.positionClient || (this.positionClient = new PositionClient_1.PositionClient(this.http, this.$error)));
    }
    reminder() {
        return (this.reminderClient || (this.reminderClient = new reminder_1.ReminderClient(this.http, this.$error)));
    }
    visa() {
        return (this.visaClient || (this.visaClient = new visa_1.VisaClient(this.http, this.$error)));
    }
    file() {
        return (this.fileClient || (this.fileClient = new FileClient_1.FileClient(this.http, this.$error)));
    }
    notification() {
        return (this.notificationClient || (this.notificationClient = new notification_1.NotificationClient(this.http, this.$error)));
    }
    gov() {
        return (this.govClient || (this.govClient = new GovClient_1.GovClient(this.http, this.$error)));
    }
    deputy() {
        return (this.deputyClient || (this.deputyClient = new DeputyClient_1.DeputyClient(this.http, this.$error)));
    }
    xero() {
        return (this.xeroClient || (this.xeroClient = new XeroClient_1.XeroClient(this.http, this.$error, this.$loginFlow)));
    }
    myob() {
        return (this.myobClient || (this.myobClient = new myob_1.MYOBClient(this.http, this.$error)));
    }
    cloudPayroll() {
        return (this.cloudPayrollClient || (this.cloudPayrollClient = new cloudPayroll_1.CloudPayrollClient(this.http, this.$error)));
    }
    cert() {
        return (this.certClient || (this.certClient = new certification_1.Client(this.http, this.$error)));
    }
    membership() {
        return (this.membershipClient || (this.membershipClient = new membership_1.membership.Client(this.http, this.$error, this.$loginFlow)));
    }
    video() {
        return (this.videoClient || (this.videoClient = new video_1.VideoClient(this.http, this.$error)));
    }
    contact() {
        return (this.contactClient || (this.contactClient = new contact_1.ContactClient(this.http, this.$error)));
    }
    admin() {
        return (this.adminClient || (this.adminClient = new AdminClient_1.Client(this.http, this.$error)));
    }
}
exports.APIClient = APIClient;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskQuery = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const AssignmentQuery_1 = require("./AssignmentQuery");
const ResponseBase_1 = require("../../ResponseBase");
const TimeAndDate_1 = require("../../utils/time/TimeAndDate");
const TaskDesignTypes_1 = require("../task/TaskDesign/TaskDesignTypes");
const FlowTaskListing_1 = require("./FlowTaskListing");
var TaskQuery;
(function (TaskQuery) {
    class ByType extends ResponseBase_1.ResponseBase {
        matches(obj) {
            if ('taskDesignType' in obj) {
                return obj.taskDesignType === this.taskDesignType;
            }
            return obj.type === this.taskDesignType;
        }
    }
    ByType.type = 'TYPE';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['TYPE']),
        (0, tslib_1.__metadata)("design:type", String)
    ], ByType.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsEnum)(TaskDesignTypes_1.TaskDesignType),
        (0, tslib_1.__metadata)("design:type", String)
    ], ByType.prototype, "taskDesignType", void 0);
    TaskQuery.ByType = ByType;
    class ByDesign extends ResponseBase_1.ResponseBase {
        matches(obj) {
            return obj.taskDesignId === this.taskDesignId;
        }
    }
    ByDesign.type = 'DESIGN';
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsIn)(['DESIGN']),
        (0, tslib_1.__metadata)("design:type", String)
    ], ByDesign.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], ByDesign.prototype, "taskDesignId", void 0);
    TaskQuery.ByDesign = ByDesign;
    class Query extends designed_1.Entity.Union.define({
        key: 'type',
        entries: [ByDesign, ByType],
    }) {
        matches(obj) {
            return this.value.matches(obj);
        }
    }
    TaskQuery.Query = Query;
    class Task extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsEnum)(TaskDesignTypes_1.TaskDesignType),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "type", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsEnum)(FlowTaskListing_1.FlowTaskStatus),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "status", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", Object)
    ], Task.prototype, "taskStateId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "name", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "taskDesignId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "taskId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], Task.prototype, "flowId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(TimeAndDate_1.TimeAndDate),
        (0, tslib_1.__metadata)("design:type", TimeAndDate_1.TimeAndDate)
    ], Task.prototype, "started", void 0);
    TaskQuery.Task = Task;
    class AssignmentFilter extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], AssignmentFilter.prototype, "memberId", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(AssignmentQuery_1.AssignmentQuery.Assignment),
        (0, tslib_1.__metadata)("design:type", AssignmentQuery_1.AssignmentQuery.Assignment)
    ], AssignmentFilter.prototype, "assignedAs", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(AssignmentQuery_1.AssignmentQuery.Requirement),
        (0, tslib_1.__metadata)("design:type", AssignmentQuery_1.AssignmentQuery.Requirement)
    ], AssignmentFilter.prototype, "requirements", void 0);
    TaskQuery.AssignmentFilter = AssignmentFilter;
    class Request extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Query }),
        (0, class_validator_1.IsInstance)(Query, { each: true }),
        (0, class_validator_1.ArrayNotEmpty)(),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Request.prototype, "queries", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsOptional)(),
        (0, class_validator_1.IsInstance)(AssignmentFilter),
        (0, tslib_1.__metadata)("design:type", AssignmentFilter)
    ], Request.prototype, "assignment", void 0);
    TaskQuery.Request = Request;
    class ResultTuple extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsInstance)(Query),
        (0, tslib_1.__metadata)("design:type", Query)
    ], ResultTuple.prototype, "query", void 0);
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: Task }),
        (0, class_validator_1.IsInstance)(Task, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], ResultTuple.prototype, "tasks", void 0);
    TaskQuery.ResultTuple = ResultTuple;
    class Response extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field({ entity: ResultTuple }),
        (0, class_validator_1.IsInstance)(ResultTuple, { each: true }),
        (0, tslib_1.__metadata)("design:type", Array)
    ], Response.prototype, "results", void 0);
    TaskQuery.Response = Response;
})(TaskQuery = exports.TaskQuery || (exports.TaskQuery = {}));

import { track } from '../../../libs/track';

type WorkArgs = {
  workType: 'Workflows' | 'Certifications';
};

type AdministrativeWorkArgs = {
  workType: 'Administrative';
  workDetail: 'Add member';
};

export function trackWorkAssignment(data: WorkArgs | AdministrativeWorkArgs) {
  track.event('Work Assigned', {
    ...data,
  });
  track.people.set({
    workLastAssigned: new Date(),
  });
  track.people.setOnce({
    workFirstAssigned: new Date(),
  });
  track.people.increment({
    totalWorkAssigned: 1,
  });
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTextTask = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const BaseTask_1 = require("../Task/BaseTask");
class RawTextTask extends BaseTask_1.BaseTask {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], RawTextTask.prototype, "text", void 0);
exports.RawTextTask = RawTextTask;

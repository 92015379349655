import * as React from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  SlideFade,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import {
  TaskActionType,
  pdf,
  TemplateTask,
  TemplateTaskGroupPending,
} from '@zap-onboard/api-client';
import { inputs, modal } from '@zap-onboard/web-components';

import { AiOutlineFilePdf } from 'react-icons/ai';
import { FormModal } from 'src/pdfme/Form/FormModal';
import { useUpgradePDF } from 'src/pdfme/hooks/useUpgradePDF';
import { useLazyImage } from 'src/hooks/useLazyImage';
import { useState } from 'react';
import { TaskActionSubmitter } from 'src/hooks';
import { InfoBox } from '../shared/InfoBox';
import { onError } from 'src/libs';
import { PDFFormGroup } from 'src/pdfme/usePDFState';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'helpers/schema';
import { useAPI } from '../../../../../services/API';
import { auth } from '../../../../../auth';

export const TemplateTaskSigner = (props: {
  submitAction: TaskActionSubmitter;
  current: TemplateTaskGroupPending;
  task: TemplateTask;
  isSubmitting: boolean;
  businessName: string;
}) => {
  const { current, submitAction, task, isSubmitting, businessName } = props;

  const signD = useDisclosure();
  const [signatureDataURL, setSignatureDataURL] = useState<string>();
  const [values, setValues] = useState<pdf.Schema.Value.Union[]>(
    current.initialValues,
  );

  const upgradeJob = useUpgradePDF(task.templateFile);
  const firstPage = upgradeJob.document?.pages?.[0];
  const firstPageURL = useLazyImage(firstPage?.image?.url);

  const bgProps = firstPageURL
    ? {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        bgImg: `url(${firstPageURL})`,
      }
    : {};

  return (
    <Stack spacing={4}>
      <InfoBox>
        {current.instructions?.trim() || 'Please fill and sign this document'}
      </InfoBox>

      <Flex flexDir={{ base: 'column', lg: 'row' }} gridGap={3}>
        <Center>
          <Box layerStyle="base" p={8} h="400px" w="250px" {...bgProps}>
            <Stack h="100%" textAlign="center">
              {!firstPageURL && (
                <Icon
                  margin="auto"
                  color="brand.dark-gray"
                  as={AiOutlineFilePdf}
                  p={4}
                  boxSize={32}
                />
              )}

              <Spacer flexGrow={1} />

              <Box />
            </Stack>

            <FormModal
              disclosure={signD}
              currentGroup={React.useMemo(() => {
                return {
                  ...current,
                  signatureDataURL,
                  values,
                  submittedAt: undefined,
                };
              }, [values, signatureDataURL, current])}
              submittedGroups={React.useMemo(() => {
                return task.getSubmissions().map<PDFFormGroup>((s) => ({
                  ...s,
                  signatureDataURL: s.signatureSVG,
                  submittedAt: s.submittedAt,
                }));
              }, [task])}
              pdf={task.templateFile.claim}
              renderSubmitModal={(args) => (
                <SlideFade in>
                  <modal.Dialog
                    header="Finish signing"
                    trigger="Finish signing"
                    _trigger={{ borderRadius: 'none' }}
                    cancel="Review answers"
                    confirm={{
                      colorScheme: 'blue',
                      isRunning: isSubmitting,
                      label: 'Finish Signing',
                      run: ({ onClose }) =>
                        submitAction(TaskActionType.TEMPLATE_SIGN, {
                          taskId: task.taskId,
                          templateGroupId: current.groupId,
                          submission: {
                            values: args.fieldValues,
                            signatureSVG: args.signatureDataURL,
                          },
                        })?.map(onClose),
                    }}
                  >
                    <Text>
                      You will not be able to edit this document again after
                      signing.
                    </Text>
                  </modal.Dialog>
                </SlideFade>
              )}
              onSave={({ fieldValues, signatureDataURL }) => {
                setValues(fieldValues);
                setSignatureDataURL(signatureDataURL);
              }}
            />
          </Box>
        </Center>

        <ConfirmNameOrSign onOpen={signD.onOpen} />
      </Flex>

      <Stack spacing={3}>
        <Text fontSize="xs" textAlign="center">
          {businessName} requests your signature on this document
        </Text>
      </Stack>
    </Stack>
  );
};

const FormSchema = z.object({
  givenName: z.string().min(1, 'Must confirm your first name').max(35),
  familyName: z.string().min(1, 'Must confirm your last name').max(35),
});

const ConfirmNameOrSign = (props: { onOpen: () => unknown }) => {
  const { onOpen } = props;
  const userProfile = auth.useProfile();
  const api = useAPI();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: z.zodResolver(FormSchema),
    defaultValues: {
      givenName: userProfile?.givenName ?? '',
      familyName: userProfile?.familyName ?? '',
    },
  });
  const submit = form.handleSubmit(async (s) => {
    if (
      s.familyName !== userProfile?.familyName ||
      s.givenName !== userProfile?.givenName
    ) {
      const result = await api
        .identity()
        .updateMyProfile({
          givenName: s.givenName,
          familyName: s.familyName,
        })
        .tap(() => {}, onError);
      if (result.isFailure()) {
        return;
      }
    }
    onOpen();
  });

  if (!userProfile) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <Stack spacing={4} layerStyle="base" p={4}>
        <Text fontSize="sm">Confirm your Details</Text>
        <inputs.TextInput name="givenName" label="Given Name" variant="small" />
        <inputs.TextInput
          name="familyName"
          label="Family Name"
          variant="small"
        />
        <Spacer flexGrow={1} />

        <Box>
          <Button onClick={submit}>Confirm and Sign</Button>
        </Box>
      </Stack>
    </FormProvider>
  );
};

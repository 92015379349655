"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicBusinessBillingStatusResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
class PublicBusinessBillingStatusResponse extends ResponseBase_1.ResponseBase {
    getStatus() {
        if (this.hasPaidSubscription) {
            return 'PAID';
        }
        if (this.trialExpiresAt && this.trialExpiresAt.isInPast()) {
            return 'EXPIRED';
        }
        return 'IN_TRIAL';
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], PublicBusinessBillingStatusResponse.prototype, "trialExpiresAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], PublicBusinessBillingStatusResponse.prototype, "hasPaidSubscription", void 0);
exports.PublicBusinessBillingStatusResponse = PublicBusinessBillingStatusResponse;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EsignTaskDesign = exports.EsignTaskDesignConfig = exports.EsignTaskDesignConfigUseUploaded = exports.EsignTaskDesignConfigUploadOnAssignment = exports.EsignConfigurationType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FileClaim_1 = require("../../../file/FileClaim");
const ResponseBase_1 = require("../../../ResponseBase");
const TaskDesign_1 = require("../TaskDesign/TaskDesign");
const TaskDesignTypes_1 = require("../TaskDesign/TaskDesignTypes");
var EsignConfigurationType;
(function (EsignConfigurationType) {
    EsignConfigurationType["UPLOAD_ON_ASSIGNMENT"] = "UPLOAD_ON_ASSIGNMENT";
    EsignConfigurationType["USE_UPLOADED"] = "USE_UPLOADED";
})(EsignConfigurationType = exports.EsignConfigurationType || (exports.EsignConfigurationType = {}));
class EsignTaskDesignConfigUploadOnAssignment extends ResponseBase_1.ResponseBase {
}
EsignTaskDesignConfigUploadOnAssignment.type = 'UPLOAD_ON_ASSIGNMENT';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['UPLOAD_ON_ASSIGNMENT']),
    (0, tslib_1.__metadata)("design:type", String)
], EsignTaskDesignConfigUploadOnAssignment.prototype, "type", void 0);
exports.EsignTaskDesignConfigUploadOnAssignment = EsignTaskDesignConfigUploadOnAssignment;
class EsignTaskDesignConfigUseUploaded extends ResponseBase_1.ResponseBase {
}
EsignTaskDesignConfigUseUploaded.type = 'USE_UPLOADED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['USE_UPLOADED']),
    (0, tslib_1.__metadata)("design:type", String)
], EsignTaskDesignConfigUseUploaded.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: FileClaim_1.FileClaim }),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], EsignTaskDesignConfigUseUploaded.prototype, "claim", void 0);
exports.EsignTaskDesignConfigUseUploaded = EsignTaskDesignConfigUseUploaded;
class EsignTaskDesignConfig extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [
        EsignTaskDesignConfigUseUploaded,
        EsignTaskDesignConfigUploadOnAssignment,
    ],
}) {
}
exports.EsignTaskDesignConfig = EsignTaskDesignConfig;
class EsignTaskDesign extends TaskDesign_1.TaskDesign {
    toCreateArgs() {
        const { name, type, taskDesignId, configuration } = this;
        return {
            type,
            name,
            taskDesignId,
            configuration: configuration
                .is('USE_UPLOADED')
                .map((r) => ({ type: 'USE_UPLOADED', claim: r.claim }))
                .orElse({ type: 'UPLOAD_ON_ASSIGNMENT', claim: undefined }),
        };
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], EsignTaskDesign.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(EsignTaskDesignConfig),
    (0, tslib_1.__metadata)("design:type", EsignTaskDesignConfig)
], EsignTaskDesign.prototype, "configuration", void 0);
exports.EsignTaskDesign = EsignTaskDesign;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowTaskActivityListing = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const time_1 = require("../../utils/time");
const activityType = ['VIEWED', 'ACTIONED'];
const context = ['business', 'user', 'unknown'];
class FlowTaskActivityListing extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowTaskActivityListing.prototype, "trackingEventId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowTaskActivityListing.prototype, "userName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(time_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", time_1.TimeAndDate)
], FlowTaskActivityListing.prototype, "timestamp", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(activityType),
    (0, tslib_1.__metadata)("design:type", Object)
], FlowTaskActivityListing.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowTaskActivityListing.prototype, "actionType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(context),
    (0, tslib_1.__metadata)("design:type", Object)
], FlowTaskActivityListing.prototype, "performedAs", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowTaskActivityListing.prototype, "sourceIp", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], FlowTaskActivityListing.prototype, "userAgent", void 0);
exports.FlowTaskActivityListing = FlowTaskActivityListing;

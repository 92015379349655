export const TickFancy: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="8" fill="#95DFFF" />
      <path
        d="M4 8.5L6.5 11L12.5 5"
        stroke="#2567FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { usePDFState } from '../usePDFState';
import {
  MdOutlineTextFields,
  MdDateRange,
  MdEmail,
  MdOtherHouses,
  MdCheckBox,
  MdArrowDropDownCircle,
} from 'react-icons/md';
import { FaFileSignature, FaUserAlt } from 'react-icons/fa';
import { pdf } from '@zap-onboard/api-client';
import { v4 } from 'uuid';

const baseTextField = {
  icon: MdOutlineTextFields,
  name: 'Text',
  group: 'Standard',
  tooltip: 'Drag to add a text field the signer will fill out',
  editable: true,
  getTooltipDescription: (data: pdf.Schema.Field.TextData) => {
    if (data.prefill === 'address') {
      return 'Your address';
    }
    if (data.prefill === 'name') {
      return 'Your name';
    }
    if (data.prefill === 'email') {
      return 'Your email address';
    }
    if (data.prefill === 'birthday') {
      return 'Your birthday in DD/MM/YYYY format';
    }
    if (data.prefill === 'primaryContactNumber') {
      return 'Your phone number';
    }

    if (data.validation) {
      if (data.validation.type === 'bsb') {
        return '(BSB) 6 digit bank code';
      }
      if (data.validation.type === 'acn') {
        return '(ACN) 9 digit company code';
      }
      if (data.validation.type === 'email') {
        return 'Email address';
      }
      if (data.validation.type === 'date') {
        return 'Date formatted as DD/MM/YYYY';
      }
      if (data.validation.type === 'letters') {
        return 'Letters only';
      }
      if (data.validation.type === 'numeric') {
        return 'Numbers only';
      }
      if (data.validation.type === 'tfn') {
        return 'Tax File Number (TFN)';
      }
    }
    return undefined;
  },
  initialSize: { width: 30, height: 5 },
  getNew(
    page: number,
    placement: pdf.Schema.Field.Data['placement'],
  ): pdf.Schema.Field.TextData {
    return {
      type: 'text',
      fieldId: v4(),
      name: usePDFState.getState().computed.nextFieldName('Text '),
      page,
      placement,
      isOptional: false,
    };
  },
} as const;

export const FieldConfig = {
  signature: {
    icon: FaFileSignature,
    group: 'Standard',
    name: 'Signature',
    getTooltipDescription: () => undefined,
    tooltip: 'Drag to add a signature box',
    initialSize: { width: 45, height: 15 },
    editable: true,
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.SignatureData {
      return {
        type: 'signature',
        fieldId: v4(),
        name: usePDFState.getState().computed.nextFieldName('Signature '),
        page,
        placement,
      };
    },
  },

  dropdown: {
    icon: MdArrowDropDownCircle,
    group: 'Standard',
    name: 'Dropdown',
    getTooltipDescription: () => undefined,
    tooltip: 'Drag to add a dropdown',
    initialSize: { width: 40, height: 5 },
    editable: true,
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.DropdownData {
      return {
        type: 'dropdown',
        fieldId: v4(),
        name: usePDFState.getState().computed.nextFieldName('Dropdown '),
        page,
        placement,
        options: [{ optionId: v4(), label: 'Option 1' }],
      };
    },
  },

  checkbox: {
    icon: MdCheckBox,
    group: 'Standard',
    name: 'Checkbox',
    getTooltipDescription: () => undefined,
    tooltip: 'Drag to add a checkbox',
    initialSize: { width: 6, height: 6 },
    editable: true,
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.CheckboxData {
      return {
        type: 'checkbox',
        fieldId: v4(),
        checkboxGroupId: v4(),
        name: usePDFState.getState().computed.nextFieldName('Checkbox '),
        page,
        placement,
      };
    },
  },

  time: {
    icon: MdDateRange,
    group: 'Standard',
    name: 'Date Signed',
    getTooltipDescription: () => undefined,
    initialSize: { width: 30, height: 5 },
    editable: false,
    tooltip:
      'Drag to add a date field that will be auto populated with the date this template is signed',
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.TimeData {
      return {
        type: 'time',
        format: 'shortDate',
        fieldId: v4(),
        name: usePDFState.getState().computed.nextFieldName('Date Signed '),
        page,
        placement,
      };
    },
  },

  text: baseTextField,

  email: {
    ...baseTextField,
    group: 'Prefilled',
    icon: MdEmail,
    name: 'Email',
    tooltip: 'Drag to an email field that is pre-filled with the signers email',
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.TextData {
      return {
        ...baseTextField.getNew(page, placement),
        name: usePDFState.getState().computed.nextFieldName('Email '),
        prefill: 'email',
        validation: { type: 'email' },
      };
    },
  },

  name: {
    ...baseTextField,
    group: 'Prefilled',
    icon: FaUserAlt,
    name: 'Name',
    tooltip:
      'Drag to add a name field that is pre-filled with the signers name',
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.TextData {
      return {
        ...baseTextField.getNew(page, placement),
        name: usePDFState.getState().computed.nextFieldName('Name '),
        prefill: 'name',
      };
    },
  },

  dob: {
    ...baseTextField,
    icon: MdDateRange,
    group: 'Prefilled',
    name: 'Date of Birth',
    tooltip:
      'Drag to add a date field that is pre-filled with the signers date of birth',
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.TextData {
      return {
        ...baseTextField.getNew(page, placement),
        name: usePDFState.getState().computed.nextFieldName('DOB '),
        prefill: 'birthday',
        validation: { type: 'date' },
      };
    },
  },

  address: {
    ...baseTextField,
    group: 'Prefilled',
    icon: MdOtherHouses,
    name: 'Address',
    tooltip: 'Drag to add a field that is pre-filled with the signers address',
    initialSize: { width: 55, height: 17 },
    getNew(
      page: number,
      placement: pdf.Schema.Field.Data['placement'],
    ): pdf.Schema.Field.TextData {
      return {
        ...baseTextField.getNew(page, placement),
        name: usePDFState.getState().computed.nextFieldName('Address '),
        prefill: 'address',
      };
    },
  },
} as const;

export const GroupedFieldConfig = Object.entries(FieldConfig).reduce<
  Record<
    string,
    { [key: string]: (typeof FieldConfig)[keyof typeof FieldConfig] }
  >
>((acc, [fieldType, field]) => {
  acc[field.group] ??= {};
  acc[field.group][fieldType] = field;
  return acc;
}, {});

export const getFieldConfig = (data: pdf.Schema.Field.Data) => {
  if (data.type === 'text') {
    const key: keyof typeof FieldConfig = (data.prefill ??
      data.validation?.type ??
      data.type) as any;
    return FieldConfig[key] || FieldConfig.text;
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return FieldConfig[data.type]!;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { IconButton, Menu as ChakraMenu, MenuButton as ChakraMenuButton, MenuList, MenuItem as Category, MenuGroup, MenuDivider, } from '@chakra-ui/react';
import { MdMoreVert } from 'react-icons/md';
export var Menu = function (_a) {
    var _b = _a.ariaLabel, ariaLabel = _b === void 0 ? 'Options' : _b, _c = _a.Icon, Icon = _c === void 0 ? MdMoreVert : _c, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, menuItems = _a.menuItems, _button = _a._button, render = _a.render;
    return (_jsxs(ChakraMenu, { children: [_jsx(MenuButton, { ariaLabel: ariaLabel, isDisabled: isDisabled, Icon: Icon, render: render, _button: __assign(__assign({}, _button), { onClick: function (e) { return e.stopPropagation(); } }) }, void 0), _jsx(MenuList, __assign({ onClick: function (e) { return e.stopPropagation(); }, zIndex: 10 }, { children: menuItems
                    .filter(function (v) { return !!v; })
                    .map(function (_a, index) {
                    var categoryTitle = _a.categoryTitle, items = _a.items, _b = _a.hideMenuCategory, hideMenuCategory = _b === void 0 ? false : _b;
                    if (hideMenuCategory) {
                        return null;
                    }
                    var renderableItems = items.filter(function (v) { return !!v; });
                    if (renderableItems.length === 0) {
                        return null;
                    }
                    return (
                    // eslint-disable-next-line react/no-array-index-key
                    _jsxs(React.Fragment, { children: [index > 0 && _jsx(MenuDivider, {}, void 0), _jsx(MenuGroup, __assign({ title: categoryTitle }, { children: renderableItems.map(function (_a, index) {
                                    var title = _a.title, Icon = _a.Icon, action = _a.action, _b = _a.hideMenuItem, hideMenuItem = _b === void 0 ? false : _b;
                                    return !hideMenuItem && (_jsx(Category
                                    // eslint-disable-next-line react/no-array-index-key
                                    , __assign({ icon: Icon, onClick: action, fontSize: "sm" }, { children: title }), "".concat(title, "-").concat(index)));
                                }) }), void 0)] }, "".concat(categoryTitle, "-").concat(index)));
                }) }), void 0)] }, void 0));
};
var MenuButton = function (_a) {
    var Icon = _a.Icon, render = _a.render, _button = _a._button, props = __rest(_a, ["Icon", "render", "_button"]);
    if (render) {
        return render(props);
    }
    return (_jsx(ChakraMenuButton, __assign({ as: IconButton, icon: Icon ? (_jsx(Icon, { size: _button === null || _button === void 0 ? void 0 : _button.size }, void 0)) : (_jsx(MdMoreVert, { size: _button === null || _button === void 0 ? void 0 : _button.size }, void 0)), variant: "options", size: "lg", "aria-label": props.ariaLabel, isDisabled: props.isDisabled }, _button), void 0));
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationListing = exports.NotificationBusinessContext = exports.NotificationStatus = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const AppLink_1 = require("../../utils/AppLink");
var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["READ"] = "READ";
    NotificationStatus["UNREAD"] = "UNREAD";
})(NotificationStatus = exports.NotificationStatus || (exports.NotificationStatus = {}));
class NotificationBusinessContext extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], NotificationBusinessContext.prototype, "businessName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], NotificationBusinessContext.prototype, "businessId", void 0);
exports.NotificationBusinessContext = NotificationBusinessContext;
class NotificationListing extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], NotificationListing.prototype, "notificationId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], NotificationListing.prototype, "timestamp", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], NotificationListing.prototype, "subject", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], NotificationListing.prototype, "body", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AppLink_1.AppLink),
    (0, tslib_1.__metadata)("design:type", AppLink_1.AppLink)
], NotificationListing.prototype, "link", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(NotificationStatus),
    (0, tslib_1.__metadata)("design:type", String)
], NotificationListing.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(NotificationBusinessContext),
    (0, tslib_1.__metadata)("design:type", NotificationBusinessContext)
], NotificationListing.prototype, "business", void 0);
exports.NotificationListing = NotificationListing;

import { TimeAndDate } from '@zap-onboard/api-client';
import { z } from 'zod';

export const VisaCheckFormSchema = z.object({
  givenNames: z.string().min(1, { message: 'Required' }),
  familyName: z.string().min(1, { message: 'Required' }),
  dateOfBirthDay: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(1, { message: 'Must be between 1 and 31' })
      .max(31, { message: 'Must be between 1 and 31' }),
  ),
  dateOfBirthMonth: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(1, { message: 'Must be between 1 and 12' })
      .max(12, { message: 'Must be between 1 and 12' }),
  ),
  dateOfBirthYear: z.preprocess(
    (v) => Number(v),
    z
      .number()
      .min(TimeAndDate.present().getFullYear() - 100, {
        message: `Invalid year`,
      })
      .max(TimeAndDate.present().getFullYear(), {
        message: `Can't be in the future`,
      }),
  ),
  documentType: z.enum([
    'DFTTA',
    'PL056',
    'IMMICARD',
    'PASSPORT',
    'TITRE_DE_VOYAGE',
  ]),
  documentId: z.string().min(1, { message: 'Required' }),
  countryOfIssue: z.string().min(1, { message: 'Required' }),
});

export type VisaCheckFormValues = z.infer<typeof VisaCheckFormSchema>;

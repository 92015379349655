import { DeleteVideoRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useDeleteVideo = (afterSubmit?: () => unknown) => {
  const [deleteVideo, isLoading] = useHandler(
    (api) => api.video().deleteVideo,
    {
      invalidates: () => [['VIDEOS']],
      always: {
        onSuccess: async () => {
          if (afterSubmit) {
            await afterSubmit();
          }
        },
      },
    },
  );

  return {
    deleteVideo: (data: Entity.Attributes<DeleteVideoRequest>) =>
      deleteVideo(data),
    isLoading,
  };
};

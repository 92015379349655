import { ABN, AbsoluteDate, ACN, FileClaim } from '@zap-onboard/api-client';
import { z } from '../../../../../helpers/schema';
import { Address } from '../../../../Inputs/AddressInput';

const baseValues = z.object({
  name: z.string().min(1, { message: 'Name required' }),
  address: Address.Schema,
  isRepresentative: z.enum(['true', 'false'] as const),
});

const hasABN = z.object({
  abn: z.valueObject(z.string(), ABN),
});

const hasACN = z.object({ acn: z.valueObject(z.string(), ACN) });

const individualRequirements = z.object({
  representativeVerificationFileClaim: z
    .instanceof(FileClaim, {
      message: 'Upload a file',
    })
    .optional(),
});

const nonIndividualRequirements = z.object({
  nonIndividualVerificationFileClaim: z.instanceof(FileClaim, {
    message: 'Upload a file',
  }),
  representativeVerificationFileClaim: z.instanceof(FileClaim, {
    message: 'Upload a file',
  }),
});

const individual = z
  .object({
    type: z.enum(['INDIVIDUAL']),
    name: z.string().optional(),
    address: Address.Schema.optional(),
    dateOfBirth: z.instanceof(AbsoluteDate, 'Invalid date of birth').optional(),
    isRepresentative: z.enum(['true', 'false'] as const),
  })
  .merge(individualRequirements);

const soleTrader = z
  .object({
    type: z.enum(['SOLE_TRADER']),
  })
  .merge(baseValues)
  .merge(individualRequirements)
  .merge(hasABN);

const partnership = z
  .object({
    type: z.enum(['PARTNERSHIP']),
  })
  .merge(baseValues)
  .merge(nonIndividualRequirements)
  .merge(hasABN);

const company = z
  .object({
    type: z.enum(['COMPANY']),
  })
  .merge(baseValues)
  .merge(nonIndividualRequirements)
  .merge(hasABN)
  .merge(hasACN);

const trust = z
  .object({
    type: z.enum(['TRUST']),
  })
  .merge(baseValues)
  .merge(nonIndividualRequirements)
  .merge(hasABN);

export const formSchema = individual
  .or(soleTrader)
  .or(partnership)
  .or(company)
  .or(trust);

import { DeleteReminderRequest } from '@zap-onboard/api-client';
import { Entity } from 'designed';
import { useHandler } from '../../useHandlerMutation';

export const useDeleteReminder = () => {
  const [deleteReminder, isLoading] = useHandler(
    (api) => api.reminder().deleteReminder,
    {
      invalidates: () => [['REMINDER_LIST']],
    },
  );
  return {
    deleteReminder: (data: Entity.Attributes<DeleteReminderRequest>) =>
      deleteReminder(data),
    isLoading,
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextQuestionFormSubmissionItem = exports.TextQuestionFormSchemaItem = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FormSchemaItem_1 = require("./FormSchemaItem");
const type = 'TEXT_QUESTION';
class TextQuestionFormSchemaItem extends FormSchemaItem_1.FormSchemaItem {
    constructor() {
        super(...arguments);
        this.type = type;
    }
    async validateItem({ item, }) {
        const { value } = item;
        if (this.required && (0, class_validator_1.isEmpty)(value)) {
            throw errors_1.errs.ValidationError.create(`${this.title} cannot be empty`, {
                details: { item, schema: this },
            });
        }
    }
    makeEmptySubmission() {
        return TextQuestionFormSubmissionItem.create({
            type: 'TEXT_QUESTION',
            formSchemaItemId: this.formSchemaItemId,
            value: '',
        });
    }
}
TextQuestionFormSchemaItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", Object)
], TextQuestionFormSchemaItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextQuestionFormSchemaItem.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['short', 'paragraph']),
    (0, tslib_1.__metadata)("design:type", String)
], TextQuestionFormSchemaItem.prototype, "size", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextQuestionFormSchemaItem.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], TextQuestionFormSchemaItem.prototype, "required", void 0);
exports.TextQuestionFormSchemaItem = TextQuestionFormSchemaItem;
class TextQuestionFormSubmissionItem extends FormSchemaItem_1.FormSubmissionItemBase {
    constructor() {
        super(...arguments);
        this.type = type;
    }
}
TextQuestionFormSubmissionItem.type = type;
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", Object)
], TextQuestionFormSubmissionItem.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TextQuestionFormSubmissionItem.prototype, "value", void 0);
exports.TextQuestionFormSubmissionItem = TextQuestionFormSubmissionItem;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientVerificationTaskActions = exports.ClientVerificationApprovalAction = exports.ClientVerificationSubmissionAction = exports.ClientVerificationTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const ClientVerificationSubmission_1 = require("./ClientVerificationSubmission");
var ClientVerificationTaskActionType;
(function (ClientVerificationTaskActionType) {
    ClientVerificationTaskActionType["CLIENT_VERIFICATION_SUBMISSION"] = "CLIENT_VERIFICATION_SUBMISSION";
    ClientVerificationTaskActionType["CLIENT_VERIFICATION_APPROVAL"] = "CLIENT_VERIFICATION_APPROVAL";
})(ClientVerificationTaskActionType = exports.ClientVerificationTaskActionType || (exports.ClientVerificationTaskActionType = {}));
class ClientVerificationSubmissionAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return ClientVerificationSubmissionAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], ClientVerificationSubmissionAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ClientVerificationSubmission_1.ClientVerification),
    (0, tslib_1.__metadata)("design:type", ClientVerificationSubmission_1.ClientVerification)
], ClientVerificationSubmissionAction.prototype, "submission", void 0);
exports.ClientVerificationSubmissionAction = ClientVerificationSubmissionAction;
class ClientVerificationApprovalAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return ClientVerificationApprovalAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], ClientVerificationApprovalAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], ClientVerificationApprovalAction.prototype, "isApproved", void 0);
exports.ClientVerificationApprovalAction = ClientVerificationApprovalAction;
exports.ClientVerificationTaskActions = {
    [ClientVerificationTaskActionType.CLIENT_VERIFICATION_SUBMISSION]: ClientVerificationSubmissionAction,
    [ClientVerificationTaskActionType.CLIENT_VERIFICATION_APPROVAL]: ClientVerificationApprovalAction,
};

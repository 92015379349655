import config from '../config';

const makeXeroDeepLink = (shortCode: string, redirect: string) =>
  `https://go.xero.com/organisationlogin/default.aspx?shortcode=${shortCode}&redirecturl=${redirect}`;

export const makeXeroConnectedAppsShortLink = (shortCode: string) =>
  makeXeroDeepLink(shortCode, '/Settings/ConnectedApps/');

export const makeXeroDashboardShortLink = (shortCode: string) =>
  makeXeroDeepLink(shortCode, '/Dashboard/');

export const makeXeroCancelLink = (shortCode: string) =>
  `https://apps.xero.com/${shortCode}/my-apps/${config.xeroAppId}`;

export const makeXeroSubscribeLink = (shortCode: string) =>
  `https://apps.xero.com/${shortCode}/subscribe/${config.xeroAppId}`;

import { useHandlerQuery } from '../../useHandlerQuery';

export const useXeroSyncEmployeeDetails = (args: { memberId: string }) => {
  const { data, isLoading } = useHandlerQuery(
    (api) => api.xero().getXeroSyncEmployeeDetails(args.memberId),
    {
      key: ['XERO_SYNC_EMPLOYEE_DETAILS', args.memberId],
      retry: 0,
      staleAfter: '5s',
    },
  );
  return {
    xeroSyncEmployeeDetails: data,
    isLoading,
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsInstanceOfAny = void 0;
const class_validator_1 = require("class-validator");
function IsInstanceOfAny(classes, validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: 'isInstanceOfAny',
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: {
                validate(value) {
                    return (value != null &&
                        typeof value === 'object' &&
                        classes.find((klass) => value instanceof klass));
                },
            },
        });
    };
}
exports.IsInstanceOfAny = IsInstanceOfAny;

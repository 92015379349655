import { useHandlerQuery } from '../../useHandlerQuery';

export const useReminders = (args?: {
  staleAfter?: string;
  enabled?: boolean;
}) => {
  const { data, isLoading } = useHandlerQuery(
    (api) => api.reminder().getReminders(),
    {
      key: ['REMINDER_LIST'],
      retry: 0,
      staleAfter: args?.staleAfter,
      enabled: args?.enabled !== false,
    },
  );
  return {
    isLoadingReminders: isLoading,
    reminders: data?.reminders ?? [],
  };
};

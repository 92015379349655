import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner as RawSpinner,
  Collapse,
  Button,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { logError } from '../libs/errorLib';

const useLogOnce = (...errs: (Error | undefined | null)[]) => {
  const [hasAlerted, setAlerted] = useState<boolean>(false);
  const error = errs.find((e) => e instanceof Error);

  useEffect(() => {
    if (!hasAlerted && error) {
      logError(error);
      setAlerted(true);
    }
  }, [error, hasAlerted]);
};

export type ErrorDisplayFetchable = {
  title?: string;
  error?: Error | null;
  isLoading?: boolean;
  action?: { title: string; run: () => unknown };
};

export const ErrorDisplay = ({
  title = "We couldn't load that right now",
  error,
  isLoading,
  action,
}: ErrorDisplayFetchable) => {
  useLogOnce(error);

  return (
    <Collapse in={!!error} animateOpacity>
      <Alert status="info" variant="left-accent">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription display="block">{error?.message}</AlertDescription>
          {action ? (
            <Button
              borderRadius="none"
              variant="link"
              bg="none"
              onClick={action.run}
            >
              {action.title}
            </Button>
          ) : null}
        </Box>
        {isLoading && <RawSpinner />}
      </Alert>
    </Collapse>
  );
};

export const ErrorDisplayMany: React.FC<{
  queries: ErrorDisplayFetchable[];
}> = ({ queries }) => {
  const query = queries.find((q) => q.error);
  useLogOnce(...queries.map((q) => q.error));

  return (
    <Collapse in={!!query?.error} animateOpacity>
      <Alert status="info" variant="left-accent">
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>
            {query?.title ?? "We couldn't load that right now"}
          </AlertTitle>
          <AlertDescription display="block">
            {query?.error?.message}
          </AlertDescription>
        </Box>
        {query?.isLoading && <RawSpinner />}
      </Alert>
    </Collapse>
  );
};

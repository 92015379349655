"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTPResponse = void 0;
const errors_1 = require("@zap-onboard/errors");
const utils_1 = require("./utils");
class HTTPResponse {
    constructor(request, deserializer = (x) => x) {
        this.request = request;
        this.deserializer = deserializer;
    }
    static async handle(request, responseModel) {
        if (process.env.NODE_ENV === 'test') {
            console.log(`APIClient Request: [${request.requestDetails().method}] ${request.requestDetails().path}`);
        }
        return await new HTTPResponse(request, responseModel).handle();
    }
    async handle() {
        try {
            await this.performFetch();
            await this.setDataFromResponse();
            this.throwResponseErrors();
            return this.deserialized();
        }
        catch (e) {
            e.details || (e.details = {});
            e.details = { ...e.details, ...this.errorDetails() };
            throw e;
        }
    }
    async performFetch() {
        try {
            this.fetchResponse = await this.request.fetch();
        }
        catch (e) {
            if ((0, utils_1.isAbortError)(e)) {
                throw errors_1.errs.TimeoutError.wrap(e, 'The request timed out or was canceled');
            }
            if (e instanceof TypeError) {
                throw errors_1.errs.ClientError.wrap(e, 'Network Error');
            }
            if (e instanceof Error) {
                throw errors_1.errs.ClientError.wrap(e, 'Network Error');
            }
            throw errors_1.errs.UnexpectedError.create(`${e} was thrown`);
        }
    }
    async setDataFromResponse() {
        try {
            this.parsedData = JSON.parse(this.fetchResponse.data);
        }
        catch (e) {
            throw errors_1.errs.SerializationError.create(`${e} was thrown parsing response to json "${this.textData}"`);
        }
    }
    throwResponseErrors() {
        const { parsedData } = this;
        errors_1.errs.throwFromAPIIfError(parsedData);
        if ((0, utils_1.isSingleErrorResponse)(parsedData)) {
            throw errors_1.errs.UnexpectedError.create(parsedData.error);
        }
    }
    deserialized() {
        try {
            return this.deserializer(this.parsedData);
        }
        catch (e) {
            throw errors_1.errs.SerializationError.wrap(e);
        }
    }
    errorDetails() {
        const { status, statusText } = this.fetchResponse ?? {};
        return {
            ...this.request.requestDetails(),
            status,
            statusText,
            response: this.textData,
        };
    }
}
exports.HTTPResponse = HTTPResponse;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainError = void 0;
class DomainError extends Error {
    constructor() {
        super(...arguments);
        this.message = 'Unexpected Error';
        this.details = {};
    }
    getCause() {
        return this.previousError;
    }
    getRootCause() {
        const previousErrors = this.getPreviousErrors();
        return previousErrors[previousErrors.length - 1];
    }
    findInstanceofAny(...klasses) {
        const errors = [this, ...this.getPreviousErrors()];
        return (errors.find((err) => klasses.find((klass) => err instanceof klass)) ?? null);
    }
    getPreviousErrors() {
        const { previousError } = this;
        if (!previousError) {
            return [];
        }
        if (previousError instanceof DomainError) {
            return [previousError, ...previousError.getPreviousErrors()];
        }
        return [previousError];
    }
    static mergeTrace(target, old) {
        if (old && target.stack && old.stack) {
            const messageLines = (target.message.match(/\n/g) || []).length + 1;
            target.stack = `${target.stack
                .split('\n')
                .slice(0, messageLines + 1)
                .join('\n')}\n${old.stack}`;
        }
    }
    get name() {
        return this.previousError
            ? `${this._name}(${this.previousError.name})`
            : this._name;
    }
    get _name() {
        return this.constructor.name;
    }
    toString() {
        const currentErrorString = `${this.message}`;
        return this.previousError
            ? `${currentErrorString}\n\t${this.previousError.toString()}`
            : currentErrorString;
    }
    static wrap(error, ...rest) {
        const domainError = this.create(...rest);
        domainError.previousError = error;
        domainError.message = rest[0] ?? domainError.message ?? error.message;
        this.mergeTrace(domainError, error);
        return domainError;
    }
    static create(message, overrides) {
        const error = new this(message);
        if (message) {
            error.message = message;
        }
        if (overrides) {
            Object.assign(error, overrides);
        }
        return error;
    }
    static default(details, overrides) {
        const error = new this();
        if (details) {
            error.details = details;
        }
        if (overrides) {
            Object.assign(error, overrides);
        }
        return error;
    }
}
exports.DomainError = DomainError;

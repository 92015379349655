import {
  Box,
  Text,
  Button,
  Stack,
  HStack,
  Spacer,
  Stat,
  StatNumber,
  StatHelpText,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  Divider,
  Badge,
} from '@chakra-ui/react';
import { PlanInvoice, PlanInvoiceLine } from '@zap-onboard/api-client';

export const InvoiceDisplay = ({
  invoice,
  displaySummaryOutside,
}: {
  invoice: PlanInvoice;
  displaySummaryOutside?: boolean;
}) => {
  const summary = (
    <Stat>
      <StatNumber>{invoice.amount.toString()}</StatNumber>
      <StatHelpText />
    </Stat>
  );

  const description = {
    deleted: 'Void',
    void: 'Void',
    draft: 'Upcoming',
    open: 'Automatic payment failed',
    paid: 'Paid',
    uncollectible: 'Defaulted',
  }[invoice.status];
  const tagColor = {
    deleted: 'yellow',
    void: 'red',
    draft: undefined,
    open: 'green',
    paid: 'green',
    uncollectible: 'red',
  }[invoice.status];

  return (
    <Box>
      {displaySummaryOutside && summary}
      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text fontSize="sm">
                {invoice.start.toAbsoluteDate().asShortPretty()}{' '}
                <Badge ml={4} colorScheme={tagColor}>
                  {description}
                </Badge>
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Stack spacing={6}>
              {invoice.lines.map((line) => (
                <Box key={line.stripeInvoiceLineItemId}>
                  <InvoiceLineDisplay line={line} />
                  <Divider />
                </Box>
              ))}
              <HStack>
                {invoice.stripeInvoiceURL && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(invoice.stripeInvoiceURL);
                    }}
                  >
                    {invoice.status === 'open' ? 'Pay now' : 'View or download'}
                  </Button>
                )}

                <Spacer />
                <Text fontSize="sm" fontWeight="bold">
                  {invoice.amount.toString()}
                </Text>
              </HStack>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
const InvoiceLineDisplay = ({ line }: { line: PlanInvoiceLine }) => (
  <HStack p={1}>
    <Text fontSize="sm" fontWeight="thin">
      {line.description}
    </Text>{' '}
    <Spacer />
    <Text fontSize="sm" fontWeight="bold">
      {line.amount.toString()}
    </Text>
  </HStack>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTaskArgs = exports.TaskClasses = void 0;
const TaskDesign_1 = require("../TaskDesign");
const RawTextTask_1 = require("../RawTextTask/RawTextTask");
const TemplateTask_1 = require("../TemplateTask/TemplateTask");
const FormTask_1 = require("../FormTask/FormTask");
const EsignTask_1 = require("../EsignTask/EsignTask");
const TFNDTask_1 = require("../TFNDTask/TFNDTask");
const SuperChoiceTask_1 = require("../SuperChoiceTask/SuperChoiceTask");
const DeputyLinkEmployeeTask_1 = require("../DeputyLinkEmployeeTask/DeputyLinkEmployeeTask");
const XeroSyncEmployeeTask_1 = require("../XeroSyncEmployeeTask/XeroSyncEmployeeTask");
const BankAccountTask_1 = require("../BankAccountTask/BankAccountTask");
const ProfileTask_1 = require("../ProfileTask/ProfileTask");
const ClientVerificationTask_1 = require("../ClientVerificationTask/ClientVerificationTask");
const ProofOfIdentityTask_1 = require("../ProofOfIdentityTask/ProofOfIdentityTask");
const SkippedTask_1 = require("../SkippedTask");
const VisaDetailsTask_1 = require("../VisaDetailsTask/VisaDetailsTask");
exports.TaskClasses = {
    [TaskDesign_1.TaskDesignType.RAW_TEXT]: RawTextTask_1.RawTextTask,
    [TaskDesign_1.TaskDesignType.PROFILE]: ProfileTask_1.ProfileTask,
    [TaskDesign_1.TaskDesignType.FORM]: FormTask_1.FormTask,
    [TaskDesign_1.TaskDesignType.ESIGN]: EsignTask_1.EsignTask,
    [TaskDesign_1.TaskDesignType.TEMPLATE]: TemplateTask_1.TemplateTask,
    [TaskDesign_1.TaskDesignType.TFND]: TFNDTask_1.TFNDTask,
    [TaskDesign_1.TaskDesignType.SUPER_CHOICE]: SuperChoiceTask_1.SuperChoiceTask,
    [TaskDesign_1.TaskDesignType.DEPUTY_LINK_EMPLOYEE]: DeputyLinkEmployeeTask_1.DeputyLinkEmployeeTask,
    [TaskDesign_1.TaskDesignType.XERO_SYNC_EMPLOYEE]: XeroSyncEmployeeTask_1.XeroSyncEmployeeTask,
    [TaskDesign_1.TaskDesignType.BANK_ACCOUNT]: BankAccountTask_1.BankAccountTask,
    [TaskDesign_1.TaskDesignType.CLIENT_VERIFICATION]: ClientVerificationTask_1.ClientVerificationTask,
    [TaskDesign_1.TaskDesignType.PROOF_OF_IDENTITY]: ProofOfIdentityTask_1.ProofOfIdentityTask,
    [TaskDesign_1.TaskDesignType.VISA_DETAILS]: VisaDetailsTask_1.VisaDetailsTask,
    META_SKIPPED: SkippedTask_1.SkippedTask,
};
// Used to ensure all task designs are mapped to a response type
const typeTest = exports.TaskClasses;
const isTaskArgs = (data) => {
    if (typeof data !== 'object' || data == null) {
        return false;
    }
    return Object.values(TaskDesign_1.TaskDesignType).includes(data.type);
};
exports.isTaskArgs = isTaskArgs;

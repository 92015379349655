"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskAssignmentPolicyResponse = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
class TaskAssignmentPolicyResponse {
    constructor(assignments) {
        this.assignments = assignments;
    }
    static makeDeduplicated(assignments) {
        const mapped = {};
        assignments.forEach((ass) => {
            const key = ass.getKey();
            const existing = mapped[key];
            if (existing) {
                mapped[key] = ass.greaterOf(existing);
            }
            else {
                mapped[key] = ass;
            }
        });
        return new TaskAssignmentPolicyResponse(mapped);
    }
    checkStatusOf(level, membership, subjectMemberId) {
        if (membership.role.isBusinessManager() && this.mapped[level].admin) {
            return true;
        }
        if (this.mapped[level].subject && membership.memberId === subjectMemberId) {
            return true;
        }
        if (this.mapped[level].members.some(({ memberId }) => memberId === membership.memberId)) {
            return true;
        }
        const positionIds = membership.membershipPositions.map(({ positionId }) => positionId);
        return this.mapped[level].positions.some(({ positionId }) => positionIds.includes(positionId));
    }
    get requiresAdminInput() {
        return this.mapped.REQUIRES_ACTION.admin;
    }
    get requiresSubjectInput() {
        return this.mapped.REQUIRES_ACTION.subject;
    }
    get mapped() {
        const leveled = {
            REQUIRES_ACTION: {
                subject: false,
                admin: false,
                members: [],
                positions: [],
            },
            CAN_ACTION: {
                subject: false,
                admin: false,
                members: [],
                positions: [],
            },
            CAN_VIEW: {
                subject: false,
                admin: false,
                members: [],
                positions: [],
            },
        };
        this.all.forEach((ass) => {
            ass.is('ADMIN').tap(() => (leveled[ass.getLevel()].admin = true));
            ass.is('SUBJECT').tap(() => (leveled[ass.getLevel()].subject = true));
            ass
                .is('MEMBER')
                .tap(({ memberId, name }) => leveled[ass.getLevel()].members.push({ memberId, name }));
            ass
                .is('POSITION')
                .tap(({ positionId, name }) => leveled[ass.getLevel()].positions.push({ positionId, name }));
        });
        return leveled;
    }
    get all() {
        return Object.values(this.assignments);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Object),
    (0, tslib_1.__metadata)("design:paramtypes", [])
], TaskAssignmentPolicyResponse.prototype, "mapped", null);
(0, tslib_1.__decorate)([
    designed_1.Memoize.All(),
    (0, tslib_1.__metadata)("design:type", Object),
    (0, tslib_1.__metadata)("design:paramtypes", [])
], TaskAssignmentPolicyResponse.prototype, "all", null);
exports.TaskAssignmentPolicyResponse = TaskAssignmentPolicyResponse;

import * as React from 'react';

import { useIsComponentMounted } from './useIsComponentMounted';

const { useCallback, useState } = React;

export function useStateIfMounted<T>(initialValue: T) {
  const isMounted = useIsComponentMounted();
  const [state, setState] = useState<T>(initialValue);

  const newSetState = useCallback(
    (value: T) => {
      if (isMounted()) {
        setState(value);
      }
    },
    [isMounted],
  );
  return [state, newSetState] as const;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Money = void 0;
const tslib_1 = require("tslib");
const errors_1 = require("@zap-onboard/errors");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
class Money extends ResponseBase_1.ResponseBase {
    validate() {
        super.validate();
        if (!Number.isSafeInteger(this.cents)) {
            throw errors_1.errs.EntityValidationError.create('Money must be a safe integer', {
                details: { cents: this.cents },
            });
        }
    }
    toString() {
        const number = this.cents % 100 === 0
            ? this.absoluteDecimal()
            : this.absoluteDecimal().toFixed(2);
        return `${this.isNegative() ? '-' : ''}$${number}`;
    }
    static from({ cents, dollars, roundCents, }) {
        let sum = 0;
        if (dollars != null) {
            sum += dollars * 100;
        }
        if (cents != null) {
            sum += cents;
        }
        if (roundCents) {
            sum = Math.round(sum);
        }
        return Money.create({ cents: sum });
    }
    floor(other) {
        if (other.cents > this.cents) {
            return other;
        }
        return this;
    }
    times(int) {
        return designed_1.Result.fromThrowable(() => {
            if (!Number.isSafeInteger(int)) {
                throw errors_1.errs.EntityValidationError.create('invalid integer');
            }
            return Money.create({
                cents: this.cents * int,
            });
        });
    }
    add(...others) {
        return Money.create({
            cents: others.reduce((sum, o) => o.cents + sum, this.cents),
        });
    }
    isNegative() {
        return this.cents < 0;
    }
    isPositive() {
        return !this.isNegative();
    }
    absoluteDecimal() {
        return this.cents === 0 ? 0 : Math.abs(this.cents) / 100;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInt)(),
    (0, tslib_1.__metadata)("design:type", Number)
], Money.prototype, "cents", void 0);
exports.Money = Money;

/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react';
import { AbsoluteDate } from '@zap-onboard/api-client';
import React, { useEffect, useState } from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { ChakraInputProps } from '../../types/Chakra';

type DatePickerProps = Partial<ChakraInputProps> & {
  name: string;
  defaultValue?: AbsoluteDate;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  defaultValue,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field,
    meta: { isDirty },
  } = useController({ control, name, defaultValue });

  const {
    day: initialDay = '',
    month: initialMonth = '',
    year: initialYear = '',
  } = defaultValue?.prettyValues() ?? field.value ?? {};

  const [day, setDay] = useState(initialDay);
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    if (field.value == null && !isDirty) {
      setDay('');
      setMonth('');
      setYear('');
    }
  }, [field.value]);

  const onBlur = () => {
    const maybeDate = AbsoluteDate.fromUserInput(`${day}-${month}-${year}`);
    if (maybeDate) {
      const { year, month, day } = maybeDate.prettyValues();
      setYear(year);
      setMonth(month);
      setDay(day);
      field.onChange(maybeDate);
    } else {
      field.onChange(undefined);
    }
    field.onBlur();
  };

  return (
    <HStack>
      <FormControl w="30%">
        <FormLabel fontSize="sm">Day</FormLabel>
        <Input
          ref={field.ref}
          type="number"
          onBlur={onBlur}
          value={day}
          name={`${field.name}-day`}
          onChange={(e) => setDay(e.target.value)}
          placeholder="DD"
          autoComplete="off"
          {...props}
        />
      </FormControl>

      <FormControl w="30%">
        <FormLabel fontSize="sm">Month</FormLabel>
        <Input
          ref={field.ref}
          type="number"
          name={`${field.name}-month`}
          onBlur={onBlur}
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          placeholder="MM"
          autoComplete="off"
          {...props}
        />
      </FormControl>

      <FormControl w="40%">
        <FormLabel fontSize="sm">Year</FormLabel>
        <Input
          ref={field.ref}
          type="number"
          onBlur={onBlur}
          name={`${field.name}-year`}
          value={year}
          onChange={(e) => setYear(e.target.value)}
          placeholder="YYYY"
          autoComplete="off"
          {...props}
        />
      </FormControl>
    </HStack>
  );
};

/* eslint-disable @typescript-eslint/no-namespace */
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { AustralianAddress, AustralianState } from '@zap-onboard/api-client';
import { inputs } from '@zap-onboard/web-components';
import { z } from '../../helpers/schema';

interface AddressInputProps {
  name: string;
  isDisabled?: boolean;
  label: string;
}

export namespace Address {
  export const Schema = z.object({
    firstLine: z.string().max(50).min(1),
    secondLine: z.string().max(50).min(1).or(z.literal('')),
    country: z.literal('AUSTRALIA'),
    suburb: z.string().max(50).min(1),
    state: z
      .string()
      .min(1, 'State is required')
      .and(z.nativeEnum(AustralianState)),
    postalCode: z
      .string()
      .min(4)
      .max(4)
      .regex(/^[0-9]+$/),
  });

  export const getDefaultValue = (
    address?: AustralianAddress,
  ): z.infer<typeof Schema> => {
    if (address) {
      return {
        firstLine: address.firstLine,
        secondLine: address.secondLine ?? '',
        suburb: address.suburb,
        state: address.state,
        postalCode: address.postalCode,
        country: (address.country as 'AUSTRALIA') ?? 'AUSTRALIA',
      };
    }
    return {
      country: 'AUSTRALIA',
      postalCode: '',
      firstLine: '',
      secondLine: '',
      suburb: '',
      state: '' as AustralianState,
    };
  };

  export const Input = ({ name, isDisabled, label }: AddressInputProps) => {
    const { errors } = useFormContext();

    const getName = (...elements: string[]) => [name, ...elements].join('.');

    return (
      <Stack spacing={4}>
        <inputs.Hidden name={getName('country')} />

        <FormControl isInvalid={!!errors[getName()]}>
          <FormLabel fontWeight="bold">{label}</FormLabel>
          <FormErrorMessage>{errors[getName()]?.message}</FormErrorMessage>
        </FormControl>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <inputs.TextInput
            placeholder="First Line"
            isDisabled={isDisabled}
            name={getName('firstLine')}
          />
          <inputs.TextInput
            placeholder="Second Line"
            isDisabled={isDisabled}
            name={getName('secondLine')}
          />
        </Stack>

        <inputs.TextInput
          placeholder="Suburb"
          isDisabled={isDisabled}
          name={getName('suburb')}
        />
        <inputs.SelectInput
          placeholder="--"
          name={getName('state')}
          options={Object.values(AustralianState)}
          isDisabled={isDisabled}
        />
        <inputs.TextInput
          placeholder="Post Code"
          isDisabled={isDisabled}
          name={getName('postalCode')}
        />
      </Stack>
    );
  };
}

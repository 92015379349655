import * as React from 'react';
import { Button, ButtonGroup, Stack, useDisclosure } from '@chakra-ui/react';
import { MdPerson } from 'react-icons/md';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { UserProfile } from '@zap-onboard/api-client';
import { useUpdateUserProfile } from '../../../../../../../hooks';
import { z } from '../../../../../../../helpers';

import { modal } from '@zap-onboard/web-components';
import { ProfileForm } from '../../../../../../../pages/Profile/UserProfile/Form';
import { UpdateUserProfileAttributes } from '../../../../../../../pages/Profile/UserProfile';

export const UpdateNameModal: React.FC<{ userProfile: UserProfile }> = ({
  userProfile,
}) => {
  const useFormMethods = useForm<z.infer<typeof ProfileForm.Schema>>({
    resolver: z.zodResolver(
      ProfileForm.SelectedSchema({ onlyFields: ['name'], isEditMode: true }),
    ),
    defaultValues: ProfileForm.getDefaultValue(userProfile),
  });
  const disclosure = useDisclosure();

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useFormMethods;
  const { onOpen, onClose } = disclosure;
  const { updateUserProfile } = useUpdateUserProfile();

  const onSubmit: SubmitHandler<UpdateUserProfileAttributes> = async (data) => {
    await updateUserProfile(data);
    onClose();
  };

  return (
    <modal.Simple
      header="Update profile"
      disclosure={disclosure}
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <Button
          variant="link"
          justifySelf="center"
          leftIcon={<MdPerson />}
          onClick={onOpen}
        >
          Update my name
        </Button>
      )}
    >
      <FormProvider {...useFormMethods}>
        <Stack spacing={4}>
          <ProfileForm.Component onlyFields={['name']} isEditMode />
          <ButtonGroup alignSelf="center">
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              isDisabled={isSubmitting || !isDirty}
            >
              Submit
            </Button>
            <Button variant="outline" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
          </ButtonGroup>
        </Stack>
      </FormProvider>
    </modal.Simple>
  );
};

import * as React from 'react';
import { VStack } from '@chakra-ui/react';

import { ChakraVStackProps } from '../../../types/Chakra';

type Props = Partial<ChakraVStackProps>;

export const SimpleTile: React.FC<Props> = ({ children, ...props }) => (
  <VStack
    layerStyle="base"
    justify="space-between"
    p={4}
    h={{ base: '116px', sm: '140px', md: '160px' }}
    w={{ base: '116px', sm: '140px', md: '160px' }}
    _hover={{
      layerStyle: 'selected',
      cursor: 'pointer',
      transition: '500ms',
    }}
    {...props}
  >
    {children}
  </VStack>
);

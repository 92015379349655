import * as React from 'react';
import { useDisclosure, Stack } from '@chakra-ui/react';

import { cert, Percentage } from '@zap-onboard/api-client';

import { modal } from '@zap-onboard/web-components';
import { SimpleActionItem } from '../index';
import { CertForm } from '../../../../certs/CertForm';

export const CertActionItem: React.FC<{
  // eslint-disable-next-line react/no-unused-prop-types
  submission: cert.GetSubmission.Submission.All;
}> = (args) => {
  const d = useDisclosure();
  const s = args.submission;

  return (
    <>
      <SimpleActionItem
        title={s.name}
        subtitle={s.businessName}
        tag={s.statusDisplay()}
        progress={Percentage.create({ total: 1, value: 0 })}
        onClick={d.onOpen}
      />
      <modal.Simple
        disclosure={d}
        _body={{ p: 4 }}
        header={`Submit your ${s.name}`}
      >
        <Stack spacing={6}>
          <CertForm
            submission={s}
            onCancel={d.onClose}
            afterSubmit={d.onClose}
          />
        </Stack>
      </modal.Simple>
    </>
  );
};

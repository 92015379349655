import {
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Center,
  ButtonGroup,
  Stack,
} from '@chakra-ui/react';
import { useHandler } from 'hooks';
import { useState } from 'react';

export const ConfirmOTPForm: React.FC<{
  onBack: () => unknown;
  onComplete: () => unknown;
  secondFactorId: string;
  verifyMFAToken?: string;
}> = ({ onBack, onComplete, secondFactorId, verifyMFAToken }) => {
  const [code, setCode] = useState<string>('');

  const isValid = code.length === 6 && code.match(/^[0-9]+$/);

  const [complete, isCompleting] = useHandler(
    (api) => api.identity().mfa.verifyTOTPSource,
    {
      always: {
        onSuccess: onComplete,
      },
      invalidates: () => [['MFA_SOURCES']],
    },
  );

  const doComplete = () => {
    if (!isValid || isCompleting) {
      return;
    }
    complete({ code, secondFactorId, verifyMFAToken });
  };

  return (
    <>
      <Text mb={4}>
        Enter the 6 digit authentication code generated by your app:
      </Text>
      <Stack>
        <Center flexDir="column">
          <FormControl maxW="230px">
            <FormLabel fontSize="sm" color="gray.500">
              Code
            </FormLabel>
            <Input
              display="block"
              mb={4}
              onChange={(e) => {
                const updated = e.target.value.replace(/\s/g, '');
                if (updated.match(/^[0-9]*$/)) {
                  setCode(updated);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  doComplete();
                }
              }}
              autoFocus
              value={code}
              autoComplete="one-time-code"
              isDisabled={isCompleting}
              size="lg"
              pattern="^[0-9]{6}$"
            />
          </FormControl>
          <ButtonGroup>
            <Button
              w="240px"
              onClick={(e) => {
                e.preventDefault();
                doComplete();
              }}
              type="submit"
              isDisabled={isCompleting || !isValid}
            >
              Verify
            </Button>
            <Button variant="outline" onClick={onBack}>
              Back
            </Button>
          </ButtonGroup>
        </Center>
      </Stack>
    </>
  );
};

import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { auth } from '../auth';

export const SentryIdentifier = () => {
  const userProfile = auth.useProfile();
  const manager = auth.useCurrentBusinessManager();

  useEffect(() => {
    if (userProfile) {
      Sentry.setUser({
        id: userProfile.userId,
        email: userProfile.email?.asJSON(),
        username: userProfile.getUserName(),
        ip_address: '{{auto}}',
      });
    } else {
      Sentry.setUser(null);
    }

    if (manager) {
      const { businessId, businessName } = manager;
      Sentry.setContext('business', {
        name: businessName,
        businessId,
      });
    } else {
      Sentry.setContext('business', null);
    }
  }, [manager, userProfile]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

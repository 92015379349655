"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsInPast = void 0;
const class_validator_1 = require("class-validator");
const time_1 = require("../time");
function IsInPast(validationOptions) {
    return function (object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: 'isInPast',
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: {
                validate(value) {
                    return validateDates(value);
                },
            },
        });
    };
}
exports.IsInPast = IsInPast;
function validateDates(value) {
    const now = new Date().valueOf();
    if (value instanceof time_1.AbsoluteDate) {
        return value.toDate().valueOf() < now;
    }
    if (value instanceof Date || value instanceof time_1.TimeAndDate) {
        return value.valueOf() < now;
    }
    return false;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.XeroSyncEmployeeActions = exports.XeroSyncEmployeePerformAction = exports.XeroSyncEmployeeActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const UpdateOrCreateUserInXeroRequest_1 = require("../../../xero/UpdateOrCreateUserInXeroRequest");
var XeroSyncEmployeeActionType;
(function (XeroSyncEmployeeActionType) {
    XeroSyncEmployeeActionType["XERO_SYNC_EMPLOYEE_PERFORM"] = "XERO_SYNC_EMPLOYEE_PERFORM";
})(XeroSyncEmployeeActionType = exports.XeroSyncEmployeeActionType || (exports.XeroSyncEmployeeActionType = {}));
class XeroSyncEmployeePerformAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return XeroSyncEmployeePerformAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSyncEmployeePerformAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], XeroSyncEmployeePerformAction.prototype, "xeroEmployeeId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeePerformAction.prototype, "sendTaxDetails", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], XeroSyncEmployeePerformAction.prototype, "sendSuperDetails", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(UpdateOrCreateUserInXeroRequest_1.AdditionalXeroTaxDetailsRequest),
    (0, tslib_1.__metadata)("design:type", UpdateOrCreateUserInXeroRequest_1.AdditionalXeroTaxDetailsRequest)
], XeroSyncEmployeePerformAction.prototype, "additionalXeroTaxDetails", void 0);
exports.XeroSyncEmployeePerformAction = XeroSyncEmployeePerformAction;
exports.XeroSyncEmployeeActions = {
    [XeroSyncEmployeeActionType.XERO_SYNC_EMPLOYEE_PERFORM]: XeroSyncEmployeePerformAction,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Submission = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const errors_1 = require("@zap-onboard/errors");
const ResponseBase_1 = require("../../../ResponseBase");
const Value = (0, tslib_1.__importStar)(require("./Value"));
class Submission extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.validateFromFields = designed_1.Result.wrap((fields, checkboxGroups) => {
            const indexedValues = this.values.reduce((acc, value) => {
                acc[value.fieldId] = value?.rawValue;
                return acc;
            }, {});
            const fieldData = fields.map((field) => field.asJSON());
            fields.forEach((field) => {
                const value = indexedValues[field.value.fieldId];
                const validationMessage = field.validationMessage(value, fieldData, indexedValues, checkboxGroups);
                if (validationMessage) {
                    throw errors_1.errs.TemplateValidation.create(`${field.value.name}: ${validationMessage}`, {
                        fieldId: field.value.fieldId,
                    });
                }
            });
        });
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: Value.Union }),
    (0, class_validator_1.IsInstance)(Value.Union, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], Submission.prototype, "values", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], Submission.prototype, "signatureSVG", void 0);
exports.Submission = Submission;

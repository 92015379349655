/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-unused-expressions */
import * as React from 'react';
import {
  AiOutlineFilePdf,
  AiOutlineFileImage,
  AiOutlineFileExcel,
} from 'react-icons/ai';

import {
  FileClaim,
  FileUploadTargetType,
  IMAGE_SCALE_INCREMENTS,
  RemoteFile,
  TemporaryRemoteFile,
} from '@zap-onboard/api-client';
import { logError } from '../../../libs/errorLib';
import { useAPI } from '../../../services/API';
import { useStateIfMounted } from '../../useStateIfMounted';
import { useHandlerQuery } from '../../useHandlerQuery';
import { MdOutlineOndemandVideo } from 'react-icons/md';

type acceptedDimensions = (typeof IMAGE_SCALE_INCREMENTS)[number];

const typeIcons = {
  [FileUploadTargetType.PDF]: AiOutlineFilePdf,
  [FileUploadTargetType.GIF]: AiOutlineFileImage,
  [FileUploadTargetType.JPEG]: AiOutlineFileImage,
  [FileUploadTargetType.PNG]: AiOutlineFileImage,
  [FileUploadTargetType.MP4]: MdOutlineOndemandVideo,
  [FileUploadTargetType.CSV]: AiOutlineFileExcel,
};

export const useFileThumbnail = ({
  file,
  height = 64,
  width = 64,
}: {
  file: RemoteFile;
  height?: acceptedDimensions;
  width?: acceptedDimensions;
}) => {
  const { useEffect } = React;

  const api = useAPI();
  const [fileThumbnail, setFileThumbnail] =
    useStateIfMounted<TemporaryRemoteFile | null>(null);
  const [isLoading, setIsLoading] = useStateIfMounted<boolean>(true);

  const { type, claim } = file;
  const fileIcon = typeIcons[type];

  useEffect(() => {
    api
      .file()
      .getThumbnail({
        claim,
        widthInPixels: width,
        heightInPixels: height,
      })
      .map(setFileThumbnail, logError)
      .tap(
        () => setIsLoading(false),
        () => setIsLoading(false),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, claim, width, height]);

  return {
    fileThumbnail,
    isLoading,
    fileIcon,
  };
};

export const useClaimThumbnail = ({
  claim,
  height = 64,
  width = 64,
}: {
  claim?: FileClaim;
  height?: acceptedDimensions;
  width?: acceptedDimensions;
}) => {
  const args = {
    claim,
    widthInPixels: width,
    heightInPixels: height,
  };

  const file = useHandlerQuery((api) => api.file().getThumbnail(args as any), {
    key: ['FILE_THUMBNAIL', { token: claim?.token, width, height }],
    enabled: !!claim,
  });

  return {
    file,
    fileThumbnail: file.data,
    isLoading: file.isLoading,
  };
};

import * as React from 'react';
import { Tag, TagLabel } from '@chakra-ui/react';

type StatusProps = {
  type: 'flowStatus' | 'flowTaskStatus' | 'certSubmissionStatus';
  status: string;
  label: string;
};

export const Status: React.FC<StatusProps> = ({ type, status, label }) => (
  <Tag
    bg={`${type}.${status}`}
    color="white"
    textAlign="center"
    py={1}
    px={2}
    size="sm"
    h="min-content"
  >
    <TagLabel noOfLines={1}>{label}</TagLabel>
  </Tag>
);

/* eslint-disable import/no-unresolved */
import { MembershipDetails, UserProfile } from '@zap-onboard/api-client';
import { useEffect } from 'react';
import m from 'mixpanel-browser';
import { Result } from 'designed';

import { track } from '../track';

export const useIdentity = (args: {
  userProfile: UserProfile | null;
  adminMemberships: MembershipDetails[];
  currentBusinessManagerMembership?: MembershipDetails;
  memberships: MembershipDetails[];
}) => {
  const {
    adminMemberships,
    currentBusinessManagerMembership,
    userProfile,
    memberships,
  } = args;

  useEffect(() => {
    if (userProfile == null) {
      Result.fromThrowable(() => {
        m.reset();
      });
    } else {
      Result.fromThrowable(() => {
        m.identify(userProfile.userId);
        track.people.set({
          $name: userProfile.getUserName(),
          businesses: adminMemberships.map((b) => b.businessName),
          businessIds: adminMemberships.map((b) => b.businessId),
          memberAtBusiness: memberships.map((p) => p.businessName),
          positions: memberships.map((m) =>
            m.membershipPositions.map((p) => p.positionName),
          ),
        });
        m.register({
          currentBusiness: currentBusinessManagerMembership?.businessName,
        });
      });
    }
  }, [
    adminMemberships,
    currentBusinessManagerMembership?.businessName,
    memberships,
    userProfile,
  ]);
};

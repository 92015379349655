import { Heading, Stack, useDisclosure } from '@chakra-ui/react';
import { modal } from '@zap-onboard/web-components';
import { useMFASetupRequired } from '../../selectors';
import { useAuthState } from '../../useAuthState';
import { SetupMFAContent } from './MFASetupFlow';
import { MFASetupPage } from './MFASetupPage';

export const MFAWrapper: React.FC<{ heading: string }> = ({
  children,
  heading,
}) => {
  const isConfigured = useAuthState((s) => s.user?.mfaConfigured);

  if (isConfigured) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Stack spacing={4} p={4} w="100%" textAlign="center" alignItems="center">
      <Heading variant="subSection">{heading}</Heading>
      <SetupMFAContent />
    </Stack>
  );
};

export const MFAClickWrap: React.FC<{
  renderCTA: (onOpen: () => unknown) => React.ReactNode;
}> = ({ children, renderCTA }) => {
  const isConfigured = useAuthState((s) => s.user?.mfaConfigured);

  const configD = useDisclosure();
  if (isConfigured) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <>
      {renderCTA(configD.onOpen)}

      <modal.Simple
        header="Setting up MFA"
        _body={{ padding: 4 }}
        _modal={{ size: 'xl' }}
        disclosure={configD}
      >
        {({ onClose }) => <SetupMFAContent onClose={onClose} />}
      </modal.Simple>
    </>
  );
};

export const RequireMFASetupWrapper: React.FC = ({ children }) => {
  const required = useMFASetupRequired();

  if (!required) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <MFASetupPage />;
};

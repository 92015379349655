"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountTaskActions = exports.BankAccountSubmissionAction = exports.BankAccountTaskActionType = void 0;
const tslib_1 = require("tslib");
require("reflect-metadata");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const BankAccount_1 = require("../../../utils/BankAccount");
var BankAccountTaskActionType;
(function (BankAccountTaskActionType) {
    BankAccountTaskActionType["BANK_ACCOUNT_SUBMISSION"] = "BANK_ACCOUNT_SUBMISSION";
})(BankAccountTaskActionType = exports.BankAccountTaskActionType || (exports.BankAccountTaskActionType = {}));
class BankAccountSubmissionAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return BankAccountSubmissionAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BankAccountSubmissionAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(BankAccount_1.BankAccount),
    (0, tslib_1.__metadata)("design:type", BankAccount_1.BankAccount)
], BankAccountSubmissionAction.prototype, "accountSubmission", void 0);
exports.BankAccountSubmissionAction = BankAccountSubmissionAction;
exports.BankAccountTaskActions = {
    [BankAccountTaskActionType.BANK_ACCOUNT_SUBMISSION]: BankAccountSubmissionAction,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawTextTaskDesign = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const TaskDesign_1 = require("../TaskDesign/TaskDesign");
const TaskDesignTypes_1 = require("../TaskDesign/TaskDesignTypes");
class RawTextTaskDesign extends TaskDesign_1.TaskDesign {
    toCreateArgs() {
        const { name, text, type, taskDesignId } = this;
        return {
            name,
            text,
            type,
            taskDesignId,
        };
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], RawTextTaskDesign.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], RawTextTaskDesign.prototype, "text", void 0);
exports.RawTextTaskDesign = RawTextTaskDesign;

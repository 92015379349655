"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwFromAPIIfError = exports.DomainError = void 0;
const tslib_1 = require("tslib");
const DomainError_1 = require("./DomainError");
Object.defineProperty(exports, "DomainError", { enumerable: true, get: function () { return DomainError_1.DomainError; } });
(0, tslib_1.__exportStar)(require("./Errors"), exports);
var fromAPI_1 = require("./fromAPI");
Object.defineProperty(exports, "throwFromAPIIfError", { enumerable: true, get: function () { return fromAPI_1.throwFromAPIIfError; } });
(0, tslib_1.__exportStar)(require("./APIErrors"), exports);

import {
  Box,
  Button,
  Tooltip,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { notification } from '@zap-onboard/api-client';
import { inputs } from '@zap-onboard/web-components';
import { Topic } from 'designed';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHandler } from '../../../../../hooks';

const levelName = {
  OFF: 'None',
  NOTIFICATION: 'In-App',
  EMAIL: 'Email',
};

const levelDescription = {
  OFF: 'No notifications will be sent.',
  NOTIFICATION: 'Notifications will be sent in-app.',
  EMAIL:
    'Notifications will be sent in-app and a follow-up email will be sent every 5 minutes',
};

type SharedTopic = Topic<notification.Settings.Level>;
export const NotificationSettingsForm = (props: {
  settings: notification.Settings.GetResponse;
}) => {
  const { settings } = props;
  const { businesses } = settings;

  const [topic] = useState<SharedTopic>(() => Topic.create());
  const [update] = useHandler((api) => api.notification().updateMySettings, {});

  const updateAllLevels = (level: notification.Settings.Level) => {
    topic.publish(level);
    update({
      categories: businesses.flatMap((business) =>
        business.categories.map((category) => ({
          businessId: business.businessId,
          categoryId: category.categoryId,
          level,
        })),
      ),
    });
  };

  return (
    <Box>
      <Stack>
        <Box layerStyle="base">
          <Heading variant="section" size="md" alignSelf="center" p={4}>
            Global
          </Heading>
          <Divider />
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'normal', lg: 'center' }}
            justifyContent="space-between"
            p={4}
          >
            <Text fontWeight="bold" color="gray.600">
              Update All Settings
            </Text>
            <ButtonGroup isAttached>
              {Object.entries(levelName).map(([level, name]) => (
                <Tooltip
                  hasArrow
                  label={levelDescription[level as notification.Settings.Level]}
                  key={level}
                >
                  <Button
                    color="brand.black"
                    borderColor="brand.gray"
                    variant="outline"
                    onClick={() =>
                      updateAllLevels(level as notification.Settings.Level)
                    }
                  >
                    {name}
                  </Button>
                </Tooltip>
              ))}
            </ButtonGroup>
          </Flex>
        </Box>

        {businesses.map((business) => {
          return (
            <Box key={business.businessId} layerStyle="base">
              <BusinessNotificationSettingsForm
                topic={topic}
                business={business}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

const BusinessNotificationSettingsForm = (props: {
  business: notification.Settings.Business;
  topic: SharedTopic;
}) => {
  const { business, topic } = props;

  return (
    <Box>
      <Heading variant="section" size="md" alignSelf="center" p={4}>
        {business.businessName}
      </Heading>

      <Stack>
        {business.categories.map((category) => {
          return (
            <Box key={category.categoryId}>
              <Divider />

              <Box p={4}>
                <NotificationSettingCategoriesForm
                  businessId={business.businessId}
                  topic={topic}
                  category={category}
                />
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

const NotificationSettingCategoriesForm = (props: {
  businessId: string;
  topic: SharedTopic;
  category: notification.Settings.Category;
}) => {
  const { category, topic, businessId } = props;
  const form = useForm();
  const [update] = useHandler((api) => api.notification().updateMySettings, {});

  const { setValue } = form;
  const level = form.watch('level');
  useEffect(() => {
    // Only update the value on subsequent renders for the neato instant refresh
    if (level) {
      setValue('level', category.level);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.level]);

  useEffect(
    () => topic.subscribe((level) => form.setValue('level', level)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form.setValue, topic],
  );

  return (
    <FormProvider {...form}>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        alignItems={{ base: 'normal', lg: 'center' }}
        justifyContent="space-between"
      >
        <Box>
          <Text fontWeight="bold" color="gray.600">
            {category.name}
          </Text>
          <Text fontSize="sm">{category.description}</Text>
        </Box>
        <inputs.radio.Group
          _control={{
            w: 'inherit',
            display: 'block',
            flexShrink: 0,
          }}
          onChange={(v) =>
            update({
              categories: [
                {
                  level: v as notification.Settings.Level,
                  categoryId: category.categoryId,
                  businessId,
                },
              ],
            })
          }
          name="level"
          defaultValue={category.level}
        >
          <HStack spacing={0}>
            {notification.Settings.LEVELS.map((level, i) => {
              const borderRadius = (() => {
                if (i == 0) {
                  return '6px 0 0 6px';
                }

                if (i == notification.Settings.LEVELS.length - 1) {
                  return '0 6px 6px 0';
                }
                return 0;
              })();

              return (
                <Tooltip hasArrow label={levelDescription[level]} key={level}>
                  <Box>
                    <inputs.radio.Button
                      _button={{
                        borderRadius,
                        px: 4,
                        py: 2,
                        fontSize: 'sm',
                      }}
                      value={level}
                    >
                      {levelName[level]}
                    </inputs.radio.Button>
                  </Box>
                </Tooltip>
              );
            })}
          </HStack>
        </inputs.radio.Group>
      </Flex>
    </FormProvider>
  );
};

import * as React from 'react';
import { Icon, Text, Stack } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

export const MagicLinkSent: React.FC = () => (
  <Stack px={4} justify="center" align="center" w="100%">
    <Icon as={MdCheckCircle} w="48px" h="48px" color="green.400" mb={3} />
    <Text my={2} fontSize="sm">
      A link has been sent to your email. Please use it to log in.
    </Text>
    <Text fontSize="sm">
      If you cannot find the link, please check your spam.
    </Text>
  </Stack>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlowTaskStatus = void 0;
var FlowTaskStatus;
(function (FlowTaskStatus) {
    FlowTaskStatus["NOT_STARTED"] = "NOT_STARTED";
    FlowTaskStatus["BUSINESS_SKIPPED"] = "BUSINESS_SKIPPED";
    FlowTaskStatus["IN_PROGRESS"] = "IN_PROGRESS";
    FlowTaskStatus["COMPLETE"] = "COMPLETE";
})(FlowTaskStatus = exports.FlowTaskStatus || (exports.FlowTaskStatus = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLink = exports.AssumeConfig = exports.AssumeEmployeeConfig = exports.AssumeBusinessConfig = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
class AssumeBusinessConfig extends ResponseBase_1.ResponseBase {
}
AssumeBusinessConfig.type = 'BUSINESS';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['BUSINESS']),
    (0, tslib_1.__metadata)("design:type", String)
], AssumeBusinessConfig.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], AssumeBusinessConfig.prototype, "businessId", void 0);
exports.AssumeBusinessConfig = AssumeBusinessConfig;
class AssumeEmployeeConfig extends ResponseBase_1.ResponseBase {
}
AssumeEmployeeConfig.type = 'EMPLOYEE';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['EMPLOYEE']),
    (0, tslib_1.__metadata)("design:type", String)
], AssumeEmployeeConfig.prototype, "type", void 0);
exports.AssumeEmployeeConfig = AssumeEmployeeConfig;
class AssumeConfig extends designed_1.Entity.Union.define({
    entries: [AssumeBusinessConfig, AssumeEmployeeConfig],
    key: 'type',
}) {
}
exports.AssumeConfig = AssumeConfig;
class AppLink extends ResponseBase_1.ResponseBase {
    static toUserCerts() {
        return AppLink.create({
            assume: { type: 'EMPLOYEE' },
            path: '/user/certifications',
        });
    }
    static toUserFlow(flow) {
        return AppLink.create({
            path: `/user/flows/${flow.flowId}`,
            assume: { type: 'EMPLOYEE' },
        });
    }
    static toBusinessFlow(flow) {
        return AppLink.create({
            path: `/business/flows/${flow.flowId}`,
            assume: { type: 'BUSINESS', businessId: flow.businessId },
        });
    }
    withBaseURL(baseURL) {
        const url = new URL(this.path ?? '', baseURL);
        const pathParams = this.pathParams ?? {};
        if (this.assume?.value?.type === 'BUSINESS') {
            pathParams.asbusiness = this.assume?.value.businessId;
        }
        if (this.assume?.value?.type === 'EMPLOYEE') {
            pathParams.asemployee = 'true';
        }
        url.search = new URLSearchParams(pathParams).toString();
        return url.href;
    }
    toPath() {
        const pathParams = this.pathParams ?? {};
        if (this.assume?.value?.type === 'BUSINESS') {
            pathParams.asbusiness = this.assume?.value.businessId;
        }
        if (this.assume?.value?.type === 'EMPLOYEE') {
            pathParams.asemployee = 'true';
        }
        let paramString = new URLSearchParams(pathParams).toString();
        if (!paramString.startsWith('?') && paramString.length > 0) {
            paramString = `?${paramString}`;
        }
        return `${this.path ?? '/'}${paramString}`;
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], AppLink.prototype, "path", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsObject)(),
    (0, tslib_1.__metadata)("design:type", Object)
], AppLink.prototype, "pathParams", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AssumeConfig),
    (0, tslib_1.__metadata)("design:type", AssumeConfig)
], AppLink.prototype, "assume", void 0);
exports.AppLink = AppLink;

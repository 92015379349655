import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { auth } from '../../auth';

export const BusinessRoute: React.FC<React.ComponentProps<typeof Route>> = ({
  children,
  ...rest
}) => {
  const manager = auth.useCurrentBusinessManager();

  return (
    <Route {...rest} component={manager ? rest.component : undefined}>
      {manager ? children : <Redirect to="/login" />}
    </Route>
  );
};

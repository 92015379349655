"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperChoice = exports.SuperChoiceSMSF = exports.SuperChoiceAPRAOrRSA = exports.SuperChoiceEmployerNominated = exports.SuperChoiceEmployerNominatedV2 = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FileClaim_1 = require("../../../file/FileClaim");
const ResponseBase_1 = require("../../../ResponseBase");
const utils_1 = require("../../../utils");
const ABN_1 = require("../../../utils/ABN");
const BSB_1 = require("../../../utils/BSB");
const SuperMemberNumber_1 = require("../../../utils/SuperMemberNumber");
class SuperChoiceEmployerNominatedV2 extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.type = 'EMPLOYER_NOMINATED_V2';
    }
}
SuperChoiceEmployerNominatedV2.type = 'EMPLOYER_NOMINATED_V2';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployerNominatedV2.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ABN_1.ABN),
    (0, tslib_1.__metadata)("design:type", ABN_1.ABN)
], SuperChoiceEmployerNominatedV2.prototype, "fundABN", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployerNominatedV2.prototype, "fundName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployerNominatedV2.prototype, "fundSPINOrUSI", void 0);
exports.SuperChoiceEmployerNominatedV2 = SuperChoiceEmployerNominatedV2;
/**
 * @deprecated New submissions should use SuperChoiceEmployerNominatedV2
 * instead. This submission type exists to map the previous super choice
 * system.
 */
class SuperChoiceEmployerNominated extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.type = 'EMPLOYER_NOMINATED';
    }
}
SuperChoiceEmployerNominated.type = 'EMPLOYER_NOMINATED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceEmployerNominated.prototype, "type", void 0);
exports.SuperChoiceEmployerNominated = SuperChoiceEmployerNominated;
class SuperChoiceAPRAOrRSA extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.type = 'SUPER_CHOICE_APRA_OR_RSA';
    }
}
SuperChoiceAPRAOrRSA.type = 'SUPER_CHOICE_APRA_OR_RSA';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceAPRAOrRSA.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ABN_1.ABN),
    (0, tslib_1.__metadata)("design:type", ABN_1.ABN)
], SuperChoiceAPRAOrRSA.prototype, "fundABN", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceAPRAOrRSA.prototype, "fundName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceAPRAOrRSA.prototype, "fundSPINOrUSI", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.AccountName),
    (0, tslib_1.__metadata)("design:type", utils_1.AccountName)
], SuperChoiceAPRAOrRSA.prototype, "accountName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(SuperMemberNumber_1.SuperMemberNumber),
    (0, tslib_1.__metadata)("design:type", SuperMemberNumber_1.SuperMemberNumber)
], SuperChoiceAPRAOrRSA.prototype, "memberNumber", void 0);
exports.SuperChoiceAPRAOrRSA = SuperChoiceAPRAOrRSA;
class SuperChoiceSMSF extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.type = 'SELF_MANAGED_SUPER_FUND';
    }
}
SuperChoiceSMSF.type = 'SELF_MANAGED_SUPER_FUND';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceSMSF.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ABN_1.ABN),
    (0, tslib_1.__metadata)("design:type", ABN_1.ABN)
], SuperChoiceSMSF.prototype, "fundABN", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(76),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceSMSF.prototype, "fundName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceSMSF.prototype, "fundAddress", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], SuperChoiceSMSF.prototype, "fundPhoneNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(16),
    (0, tslib_1.__metadata)("design:type", String)
], SuperChoiceSMSF.prototype, "fundESA", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(BSB_1.BSB),
    (0, tslib_1.__metadata)("design:type", BSB_1.BSB)
], SuperChoiceSMSF.prototype, "fundBSB", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AccountNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.AccountNumber)
], SuperChoiceSMSF.prototype, "fundAccountNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], SuperChoiceSMSF.prototype, "requiredDocumentationClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], SuperChoiceSMSF.prototype, "isTrusteeOrDirector", void 0);
exports.SuperChoiceSMSF = SuperChoiceSMSF;
class SuperChoice extends designed_1.Entity.Union.define({
    key: 'type',
    entries: [
        SuperChoiceSMSF,
        SuperChoiceAPRAOrRSA,
        SuperChoiceEmployerNominated,
        SuperChoiceEmployerNominatedV2,
    ],
}) {
}
exports.SuperChoice = SuperChoice;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseBase = void 0;
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const errors_1 = require("@zap-onboard/errors");
class ResponseBase extends designed_1.Entity.Base {
    static tryCreate(args) {
        return designed_1.Result.fromThrowable(() => this.create(args));
    }
    validate() {
        try {
            const errors = (0, class_validator_1.validateSync)(this);
            if (errors.length > 0) {
                console.error('Parsing errors', errors);
                throw errors_1.errs.EntityValidationError.fromClassValidatorErrors(errors);
            }
        }
        catch (e) {
            if (e instanceof errors_1.errs.EntityValidationError) {
                e.details.validatedEntity = this;
                throw e;
            }
            else {
                throw errors_1.errs.EntityValidationError.wrap(e, 'entity threw during validations', {
                    details: { validatedEntity: this },
                });
            }
        }
    }
}
exports.ResponseBase = ResponseBase;

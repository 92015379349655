/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  AdditionalXeroTaxDetailsRequest,
  AUPayroll,
  Country,
  membership,
  XeroClient,
  XeroSyncEmployeeTaskSyncDetails,
} from '@zap-onboard/api-client';
import { capitalizeFirstLetter } from '../../../../../../../helpers';
import { inputs, Tag } from '@zap-onboard/web-components';
import { useXeroEmployees } from '../../../../../../../hooks';
import { useXeroSyncEmployeeDetails } from '../../../../../../../hooks/data/xero/useXeroSyncEmployeeDetails';
import { Spinner } from '../../../../../../../components';
import {
  ButtonGroup,
  Button,
  Stack,
  Text,
  Flex,
  Heading,
  HStack,
  Center,
  ListItem,
  UnorderedList,
  Box,
  VStack,
} from '@chakra-ui/react';
import { z } from '../../../../../../../helpers/schema';
import { Entity } from 'designed';
import { ErrorDisplayMany } from '../../../../../../../billing/ErrorDisplay';
import { syncToXeroSchema, stp2Schema } from './schema';
import { useEffect } from 'react';
import { useXeroUpdateOrCreateUser } from '../../../../../../../hooks/data/xero/useXeroUpdateOrCreateUser';

type Props = {
  member: membership.GetMember.Response;
  onCancel?: () => unknown;
  onSave?: (
    ...args: Parameters<XeroClient['updateOrCreateUserInXero']>
  ) => unknown;
  isSaving: boolean;
};

const CREATE_EMPLOYEE = 'CREATE_NEW_EMPLOYEE';

const getDefaultTaxValues = ({
  details,
}: {
  details: XeroSyncEmployeeTaskSyncDetails;
}) => {
  return {
    employmentType: details.prefill.additionalTaxDetails.employmentType ?? '',
    countryOfResidence:
      details.prefill.additionalTaxDetails.countryOfResidence?.alpha2Code ?? '',
    incomeType: details.prefill.additionalTaxDetails.incomeType ?? '',
    seniorMaritalStatus:
      details.prefill.additionalTaxDetails.seniorMaritalStatus,
    taxScaleType: details.prefill.additionalTaxDetails.taxScaleType,
    workCondition: details.prefill.additionalTaxDetails.workCondition ?? '',
    employmentBasis: details.prefill.additionalTaxDetails.employmentBasis ?? '',
  };
};

const RawSyncToXeroForm: React.FC<
  Props & { xeroSyncEmployeeDetails: XeroSyncEmployeeTaskSyncDetails }
> = ({ member, onCancel, isSaving, onSave, xeroSyncEmployeeDetails }) => {
  const { StatusRowWithRadio, StatusRow } = Local;
  const updater = useXeroUpdateOrCreateUser();
  const [syncSuccess, setSyncSuccess] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const form = useForm({
    resolver: (v, ctx, all) => {
      if (v.sendTaxDetails === 'true') {
        return z.zodResolver(stp2Schema)(v, ctx, all);
      }
      return z.zodResolver(syncToXeroSchema)(v, ctx, all);
    },
    defaultValues: {
      sendSuperDetails: xeroSyncEmployeeDetails.employeeSuperDetailsComplete
        ? 'true'
        : 'false',
      sendTaxDetails: xeroSyncEmployeeDetails.employeeTaxDetailsComplete
        ? 'true'
        : 'false',
      xeroEmployeeId: '', // xeroSyncEmployeeDetails.prefill.xeroEmployeeId,
      ...getDefaultTaxValues({ details: xeroSyncEmployeeDetails }),
    },
  });
  const sendTaxDetails = form.watch('sendTaxDetails') === 'true';
  const isLabourHire = form.watch('incomeType') === 'LABOURHIRE';
  const isNonEmployee = form.watch('incomeType') === 'NONEMPLOYEE';
  const isWorkingHolidayMaker =
    form.watch('incomeType') === 'WORKINGHOLIDAYMAKER';
  const isSeniorMaritialStatus =
    form.watch('taxScaleType') === 'SENIORORPENSIONER';
  const isActorArtsEntertainer =
    form.watch('taxScaleType') === 'ACTORSARTISTSENTERTAINERS';
  const xeroEmployees = useXeroEmployees();

  useEffect(() => {
    const employee = xeroEmployees.data?.find((e) =>
      e.email?.equals(member.email),
    );
    if (form.getValues().xeroEmployeeId === '' && employee) {
      form.setValue('xeroEmployeeId', employee.xeroEmployeeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xeroEmployees.data]);

  const onSubmit = form.handleSubmit(
    async ({
      sendSuperDetails,
      sendTaxDetails,
      xeroEmployeeId,
      taxScaleType,
      countryOfResidence,
      seniorMaritalStatus,
      workCondition,
      incomeType,
      employmentBasis,
    }) => {
      setIsLoading(true);
      const employmentType =
        xeroSyncEmployeeDetails.prefill.additionalTaxDetails.employmentType;
      const additionalXeroTaxDetails = Object.fromEntries(
        Object.entries({
          taxScaleType,
          countryOfResidence,
          seniorMaritalStatus,
          workCondition,
          employmentType,
          incomeType,
          employmentBasis,
        }).filter(([, value]) => value !== undefined && value !== ''),
      );
      if (onSave) {
        await onSave({
          memberId: member.memberId,
          sendSuperDetails: sendSuperDetails === 'true',
          sendTaxDetails: sendTaxDetails === 'true',
          xeroEmployeeId:
            xeroEmployeeId === 'CREATE_NEW_EMPLOYEE'
              ? undefined
              : xeroEmployeeId,
          additionalXeroTaxDetails:
            sendTaxDetails === 'true'
              ? (additionalXeroTaxDetails as Entity.Attributes<AdditionalXeroTaxDetailsRequest>)
              : undefined,
        });
        setIsLoading(false);
      } else {
        await updater
          .updateOrCreate({
            memberId: member.memberId,
            sendSuperDetails: sendSuperDetails === 'true',
            sendTaxDetails: sendTaxDetails === 'true',
            xeroEmployeeId:
              xeroEmployeeId === 'CREATE_NEW_EMPLOYEE'
                ? undefined
                : xeroEmployeeId,
            additionalXeroTaxDetails:
              sendTaxDetails === 'true'
                ? (additionalXeroTaxDetails as Entity.Attributes<AdditionalXeroTaxDetailsRequest>)
                : undefined,
          })
          ?.tap(
            () => {
              setIsLoading(false);
              setSyncSuccess(true);
            },
            () => {
              setIsLoading(false);
            },
          );
      }
    },
  );

  if (!xeroSyncEmployeeDetails?.isXeroLinkedToBusiness) {
    return (
      <Stack spacing={4} align="center">
        <Heading size="md">You need to setup the following</Heading>
        <UnorderedList>
          {!xeroSyncEmployeeDetails?.isXeroLinkedToBusiness && (
            <ListItem fontSize="lg">Connect to your Xero file</ListItem>
          )}
        </UnorderedList>
        <Box>
          <Button as={Link} to="/business/settings">
            Go to settings page
          </Button>
        </Box>
      </Stack>
    );
  }

  if (!xeroSyncEmployeeDetails.employeeProfileComplete) {
    return (
      <>
        <Text p={4}>
          <Box as="span" fontWeight="bold">
            Unable to sync employee
          </Box>{' '}
          <br />
        </Text>
        <StatusRow status="incomplete" heading="User profile" />
        <Text p={4}>
          {' '}
          Either:
          <br /> 1. Manually update the users profile or; <br />
          2. Add the &quot;Employee Profile&quot; task to a workflow and assign
          to the employee to complete.
        </Text>
      </>
    );
  }

  return (
    <Stack spacing={4}>
      <Text fontWeight="bold" fontSize="md" color="brand.dark-gray">
        {member.userName}
      </Text>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          {xeroSyncEmployeeDetails.employeeProfileComplete && (
            <inputs.SelectInput
              name="xeroEmployeeId"
              placeholder="Select..."
              options={[
                { name: 'Create New Employee', value: CREATE_EMPLOYEE },
                ...(xeroEmployees.data?.map((employee) => ({
                  name: employee.xeroName,
                  value: employee.xeroEmployeeId,
                })) ?? []),
              ]}
              isRequired
            />
          )}
          <StatusRow status="ready" heading="User profile" />
          <StatusRow
            status={
              xeroSyncEmployeeDetails.employeeBankDetailsComplete
                ? 'ready'
                : 'incomplete'
            }
            heading="Bank account details"
          />

          <StatusRowWithRadio
            isHidden={!xeroSyncEmployeeDetails.employeeSuperDetailsComplete}
            title="Sync super choice"
            status={
              xeroSyncEmployeeDetails.employeeSuperDetailsComplete
                ? 'ready'
                : 'incomplete'
            }
            inputName="sendSuperDetails"
          />

          <StatusRowWithRadio
            isHidden={!xeroSyncEmployeeDetails.employeeTaxDetailsComplete}
            title="Sync tax details"
            status={
              xeroSyncEmployeeDetails.employeeTaxDetailsComplete
                ? 'ready'
                : 'incomplete'
            }
            onYes={() => {
              Object.entries(
                getDefaultTaxValues({ details: xeroSyncEmployeeDetails }),
              ).forEach(([k, v]) => {
                form.setValue(k as any, v);
              });
            }}
            inputName="sendTaxDetails"
          />

          {sendTaxDetails && (
            <VStack layerStyle="base" p={4}>
              <inputs.SelectInput
                label="Income type"
                name="incomeType"
                options={
                  isLabourHire
                    ? [{ name: 'Labour hire', value: 'LABOURHIRE' }]
                    : isNonEmployee
                      ? [{ name: 'Non-employee', value: 'NONEMPLOYEE' }]
                      : isWorkingHolidayMaker
                        ? [
                            {
                              name: 'Working holiday maker',
                              value: 'WORKINGHOLIDAYMAKER',
                            },
                          ]
                        : AUPayroll.IncomeTypes.filter(
                            (element) =>
                              ![
                                'NONEMPLOYEE',
                                'LABOURHIRE',
                                'WORKINGHOLIDAYMAKER',
                              ].includes(element.code),
                          ).map(({ name, code }) => ({
                            name,
                            value: code,
                          }))
                }
              />

              <inputs.SelectInput
                label="Employment basis"
                name="employmentBasis"
                options={
                  isLabourHire
                    ? [{ name: 'Labour hire', value: 'LABOURHIRE' }]
                    : isNonEmployee
                      ? [{ name: 'Non-employee', value: 'NONEMPLOYEE' }]
                      : AUPayroll.EmploymentBasis.filter(
                          (element) =>
                            !['NONEMPLOYEE', 'LABOURHIRE'].includes(
                              element.code,
                            ),
                        ).map(({ name, code }) => ({
                          name,
                          value: code,
                        }))
                }
              />

              {!isNonEmployee && (
                <inputs.SelectInput
                  label="Tax scale"
                  name="taxScaleType"
                  options={
                    isWorkingHolidayMaker
                      ? [
                          {
                            name: 'Working holiday maker',
                            value: 'WORKINGHOLIDAYMAKER',
                          },
                        ]
                      : AUPayroll.TaxScaleTypes.filter(
                          (element) =>
                            !['WORKINGHOLIDAYMAKER'].includes(element.code),
                        ).map(({ name, code }) => ({
                          name,
                          value: code,
                        }))
                  }
                />
              )}

              {isSeniorMaritialStatus && (
                <inputs.SelectInput
                  label="Senior marital status"
                  name="seniorMaritalStatus"
                  options={[{ name: 'Select...', value: '' }].concat(
                    AUPayroll.SeniorMaritalStatus.map(
                      ({ name, code }) =>
                        ({
                          name,
                          value: code,
                        }) as { name: string; value: string },
                    ),
                  )}
                />
              )}

              {isActorArtsEntertainer && (
                <inputs.SelectInput
                  label="Work condition"
                  name="workCondition"
                  options={[{ name: 'Select...', value: '' }].concat(
                    AUPayroll.WorkConditions.map(
                      ({ name, code }) =>
                        ({
                          name,
                          value: code,
                        }) as { name: string; value: string },
                    ),
                  )}
                />
              )}

              {isWorkingHolidayMaker && (
                <inputs.SelectInput
                  label="Country of residence"
                  name="countryOfResidence"
                  options={[{ name: 'Select...', value: '' }].concat(
                    Country.options(),
                  )}
                />
              )}
            </VStack>
          )}

          {syncSuccess ? (
            <Text color="brand.green" textAlign="center" fontSize="lg" pt={4}>
              Sync Successful!
            </Text>
          ) : (
            <ButtonGroup justifyContent="center" w="100%" mt={2}>
              <Button
                type="submit"
                isLoading={isSaving || isLoading}
                isDisabled={isSaving || isLoading}
              >
                Sync
              </Button>
              {onCancel && (
                <Button variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </ButtonGroup>
          )}
        </form>
      </FormProvider>
    </Stack>
  );
};

export const SyncToXeroForm: React.FC<Props> = (props) => {
  const syncDetails = useXeroSyncEmployeeDetails({
    memberId: props.member.memberId,
  });
  return (
    <>
      <ErrorDisplayMany queries={[syncDetails]} />
      {!syncDetails.xeroSyncEmployeeDetails && <Spinner />}
      {syncDetails.xeroSyncEmployeeDetails && (
        <RawSyncToXeroForm
          {...props}
          xeroSyncEmployeeDetails={syncDetails.xeroSyncEmployeeDetails}
        />
      )}
    </>
  );
};

namespace Local {
  export const StatusRowWithRadio: React.FC<{
    title: string;
    isHidden: boolean;
    status: 'ready' | 'incomplete';
    inputName: string;
    onYes?: () => void;
  }> = ({ title, status, inputName, onYes, isHidden }) => {
    return (
      <Flex align="center" my={4} justify="space-between" px={7} h="45px">
        <Flex>
          <Text w="230px">{title}</Text>
          <Local.StatusTag status={status} />
        </Flex>
        <SyncChoice
          isHidden={isHidden}
          onYes={onYes}
          inputName={inputName}
          isDisabled={status === 'incomplete'}
        />
      </Flex>
    );
  };

  export const StatusTag: React.FC<{ status: 'ready' | 'incomplete' }> = ({
    status,
  }) => (
    <Tag
      bg={status === 'ready' ? 'brand.green' : 'brand.yellow'}
      label={capitalizeFirstLetter(status)}
      labelColor="white"
    />
  );

  export const StatusRow: React.FC<{
    status: 'ready' | 'incomplete';
    heading: string;
  }> = ({ status, heading }) => {
    return (
      <Flex align="center" my={4} px={7} h="45px">
        <Text w="230px">{heading}</Text>
        <Local.StatusTag status={status} />
      </Flex>
    );
  };

  const SyncChoice: React.FC<{
    inputName: string;
    isHidden: boolean;
    isDisabled: boolean;
    onYes?: () => void;
  }> = ({ inputName, isDisabled, onYes, isHidden }) => {
    const styles = {
      h: '45px',
      w: '60px',
      padding: 1,
      display: isHidden ? 'none' : 'inline-block',
    };
    return (
      <Flex align="center" h="100%">
        <inputs.radio.Group name={inputName} isDisabled={isDisabled}>
          <HStack>
            <inputs.radio.Button
              _wrapper={{ onClick: onYes }}
              _button={{ ...styles }}
              value="true"
            >
              <Center h="100%">Yes</Center>
            </inputs.radio.Button>
            <inputs.radio.Button _button={{ ...styles }} value="false">
              <Center h="100%">No</Center>
            </inputs.radio.Button>
          </HStack>
        </inputs.radio.Group>
      </Flex>
    );
  };
}

import * as React from 'react';
import {
  Center,
  Flex,
  Image,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';

import image from 'assets/img/login-screen.png';

import { LoginLink } from './LoginLink/LoginLink';
import { SignUpForm } from './Form';
import { Logo } from 'components';
import { track } from 'libs';

const LeftDisplay: React.FC = () => (
  <Stack justify="space-between" p={{ base: 4, md: 6 }} w="100%">
    <Flex justify="flex-start">
      <Logo color="brand.blue" height="24px" />
    </Flex>
    <SignUpForm />
    <LoginLink />
  </Stack>
);

const RightDisplay: React.FC = () => (
  <Image
    w="400px"
    alt="process app"
    borderRadius="0 0.375rem 0.375rem 0"
    shadow="sm"
    src={image}
  />
);

export const SignupPage: React.FC = () => {
  track.useOnMount('Viewed Signup Page');

  const isCompactView = useBreakpointValue({ base: true, lg: false });

  return (
    <Center bgGradient="linear(to-b, brand.blue, brand.dark-blue)" minH="100vh">
      <Flex
        layerStyle="base"
        border="none"
        minH="500px"
        w={{ base: '100%', md: '400px', lg: '800px' }}
        m={4}
      >
        <LeftDisplay />
        {isCompactView ? null : <RightDisplay />}
      </Flex>
    </Center>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const position_1 = require("../../position");
const ResponseBase_1 = require("../../ResponseBase");
const Email_1 = require("../../utils/email/Email");
var AccessRequest;
(function (AccessRequest) {
    let Accept;
    (function (Accept) {
        /**
         * Omitting the memberId should create a new member
         */
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "userId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "memberId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "positionId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsBoolean)(),
            (0, tslib_1.__metadata)("design:type", Boolean)
        ], Request.prototype, "assignNewFlows", void 0);
        Accept.Request = Request;
        class Response extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Response.prototype, "memberId", void 0);
        Accept.Response = Response;
    })(Accept = AccessRequest.Accept || (AccessRequest.Accept = {}));
    let Reject;
    (function (Reject) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "userId", void 0);
        Reject.Request = Request;
    })(Reject = AccessRequest.Reject || (AccessRequest.Reject = {}));
    let Open;
    (function (Open) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "businessId", void 0);
        Open.Request = Request;
    })(Open = AccessRequest.Open || (AccessRequest.Open = {}));
    let List;
    (function (List) {
        class Request extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsEnum)(position_1.AccessRequestStatus),
            (0, tslib_1.__metadata)("design:type", String)
        ], Request.prototype, "status", void 0);
        List.Request = Request;
        class Item extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsEnum)(position_1.AccessRequestStatus),
            (0, tslib_1.__metadata)("design:type", String)
        ], Item.prototype, "status", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Item.prototype, "accessRequestId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Item.prototype, "businessName", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Item.prototype, "businessId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsString)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Item.prototype, "userName", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsUUID)(),
            (0, tslib_1.__metadata)("design:type", String)
        ], Item.prototype, "userId", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(Email_1.Email),
            (0, tslib_1.__metadata)("design:type", Email_1.Email)
        ], Item.prototype, "email", void 0);
        List.Item = Item;
        class Response extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field({ entity: Item }),
            (0, class_validator_1.IsInstance)(Item, { each: true }),
            (0, tslib_1.__metadata)("design:type", Array)
        ], Response.prototype, "requests", void 0);
        List.Response = Response;
    })(List = AccessRequest.List || (AccessRequest.List = {}));
})(AccessRequest = exports.AccessRequest || (exports.AccessRequest = {}));

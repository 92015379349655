import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const PrintIcon = createIcon({
  path: (
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          className="cls-1"
          d="M41.29 9.67V7.32A7.34 7.34 0 0 0 34 0H16a7.34 7.34 0 0 0-7.39 7.32v2.35zM11.55 31.15v15.92A2.93 2.93 0 0 0 14.48 50h20.94a2.94 2.94 0 0 0 2.94-2.93V31.15zm17.31 12.5H21a1.47 1.47 0 1 1 0-2.93h7.82a1.47 1.47 0 1 1 0 2.93zm0-6.25H21a1.47 1.47 0 1 1 0-2.93h7.82a1.47 1.47 0 1 1 0 2.93z"
        />
        <path
          fill="currentColor"
          className="cls-1"
          d="M42.66 12.6H7.34A7.33 7.33 0 0 0 0 19.92v11.72A7.33 7.33 0 0 0 7.34 39h1.27v-7.85h-.88a1.47 1.47 0 1 1 0-2.93h34.44a1.47 1.47 0 1 1 0 2.93h-.88V39h1.37A7.33 7.33 0 0 0 50 31.64V19.92a7.33 7.33 0 0 0-7.34-7.32zm-30.23 9.18h-4.7a1.47 1.47 0 1 1 0-2.93h4.7a1.47 1.47 0 0 1 0 2.93z"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 50 50',
});

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react';
import { FormLabel, Input, VisuallyHidden } from '@chakra-ui/react';
import {
  FileUploadForMyBusiness,
  FileUploadForMember,
  FileUploadForTask,
  FileUploadTargetType,
  FileUploadForCertification,
  FileClaim,
  FileUploadForVideo,
} from '@zap-onboard/api-client';
import { useController } from 'react-hook-form';
import fileType, { MimeType } from 'file-type/browser';
import { Entity, Optional } from 'designed';
import { errs } from '@zap-onboard/errors';
import { useAPI } from '../services/API';
import { onError } from '../libs/errorLib';
import { useStateIfMounted } from '../hooks';
import { track } from '../libs/track';

const { useCallback } = React;

const typeToMime: { [K in keyof typeof FileUploadTargetType]: string } = {
  PDF: '.pdf,application/pdf',
  JPEG: '.jpg,.jpeg,image/jpeg',
  PNG: '.png,image/png',
  GIF: '.gif,image/gif',
  MP4: 'video/mp4',
  CSV: '.csv,text/csv',
};

const mimeToType: { [key in MimeType]?: FileUploadTargetType } = {
  'application/pdf': FileUploadTargetType.PDF,
  'image/jpeg': FileUploadTargetType.JPEG,
  'image/png': FileUploadTargetType.PNG,
  'image/gif': FileUploadTargetType.GIF,
  'video/mp4': FileUploadTargetType.MP4,
};

export const useFileUploader = ({
  allowedTypes,
  onNewFile,
  uploadedFor,
}: {
  onNewFile?: (file: FileClaim) => void;
  allowedTypes: FileUploadTargetType[];
  uploadedFor:
    | Entity.Attributes<FileUploadForTask>
    | Entity.Attributes<FileUploadForMember>
    | Entity.Attributes<FileUploadForCertification>
    | Entity.Attributes<FileUploadForVideo>
    | Entity.Attributes<FileUploadForMyBusiness>;
}) => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useStateIfMounted<boolean>(false);

  const performUpload = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true);

        const type = await fileType.fromBlob(file);
        if (!type?.mime) {
          throw errs.ValidationError.create(
            'could not determine file type. Check if your file is corrupted or has the correct extension',
          );
        }

        const targetType = mimeToType[type.mime];
        if (!targetType || !allowedTypes.includes(targetType)) {
          throw errs.ValidationError.create(
            `file is of type ${
              targetType ?? type.mime
            } but must be one of ${allowedTypes.join(', ')}`,
          );
        }

        const uploadTarget = await api
          .file()
          .createUploadTarget({ targetType, uploadedFor })
          .getOrThrowFailure();

        const response = await fetch(uploadTarget.uploadURL, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': type.mime,
          },
        });

        if (!response.ok) {
          throw errs.UnexpectedError.create(
            'could not remotely upload file. Ensure the file is below 4gb and is the correct type',
            {
              details: {
                uploadResponse: await response.text(),
                uploadStatus: response.status,
                uploadURL: uploadTarget.uploadURL,
              },
            },
          );
        }

        const confirmed = await api
          .file()
          .confirmUpload(uploadTarget.fileId)
          .getOrThrowFailure();

        track.event('Uploaded File', { size: file.size, type: file.type });

        onNewFile?.(confirmed.claim);
        return confirmed;
      } catch (e) {
        onError(e);
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [allowedTypes, api, onNewFile, uploadedFor, setIsLoading],
  );

  return {
    performUpload,
    isUploading: isLoading,
    allowedMime: allowedTypes.map((type) => typeToMime[type]).join(','),
  };
};

interface RequiredProps {
  controlled: Parameters<typeof useController>[0];
  allowedTypes: FileUploadTargetType[];
  uploadedFor:
    | Entity.Attributes<FileUploadForTask>
    | Entity.Attributes<FileUploadForMember>
    | Entity.Attributes<FileUploadForCertification>
    | Entity.Attributes<FileUploadForVideo>
    | Entity.Attributes<FileUploadForMyBusiness>;
  isReadOnly?: boolean;
  direction?: 'column' | 'row';
  oustRemoveAction?: boolean;
  afterChange?: () => void;
  setIsUploading?: (arg: boolean) => void;
  afterUpload?: () => void;
}

export interface FileUploadInjectedProps {
  isUploading: boolean;
  file: FileClaim | undefined;
  isReadOnly?: boolean;
  direction?: 'column' | 'row';
  oustRemoveAction?: boolean;
  afterUpload?: () => void;
  onClick: () => void;
  clearFile: () => void;
  setIsUploading?: (arg: boolean) => void;
}

export const withFileUpload =
  <T extends FileUploadInjectedProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, keyof FileUploadInjectedProps> & RequiredProps> =>
  ({
    controlled,
    direction,
    allowedTypes,
    uploadedFor,
    isReadOnly,
    oustRemoveAction,
    afterChange,
    setIsUploading,
    afterUpload,
    ...props
  }) => {
    const {
      field: { value: unwrapped, onChange },
    } = useController(controlled);
    const value = unwrapped as FileClaim | undefined;

    const { performUpload, isUploading } = useFileUploader({
      onNewFile: onChange,
      allowedTypes,
      uploadedFor,
    });

    const fileUploadInputRef = React.useRef<HTMLInputElement | null>(null);

    const handleOpenFilePicker = useCallback(() => {
      fileUploadInputRef?.current?.click();
    }, [fileUploadInputRef]);

    const clearFile = useCallback(() => {
      onChange(undefined);
      if (afterChange) {
        afterChange();
      }
      if (fileUploadInputRef.current) {
        fileUploadInputRef.current.value = '';
      }
    }, [afterChange, onChange]);

    const handleFileSelected = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        Optional.of(e.target.files)
          .map((files) => files[0])
          .map(async (v) => {
            if (setIsUploading) {
              setIsUploading(true);
              await performUpload(v);
              setIsUploading(false);
            } else {
              await performUpload(v);
            }
            if (afterUpload) {
              afterUpload();
            }
          });
        if (afterChange) {
          afterChange();
        }
      },
      [afterChange, afterUpload, performUpload, setIsUploading],
    );

    const injected: FileUploadInjectedProps = {
      onClick: handleOpenFilePicker,
      isUploading,
      file: value,
      clearFile,
      isReadOnly,
      direction,
      oustRemoveAction,
    };

    return (
      <>
        <VisuallyHidden>
          <FormLabel>File upload</FormLabel>
          <Input
            ref={fileUploadInputRef}
            onChange={handleFileSelected}
            type="file"
            accept={allowedTypes.map((type) => typeToMime[type]).join(',')}
          />
        </VisuallyHidden>

        <Component {...({ ...injected, ...props } as unknown as T)} />
      </>
    );
  };

// TODO: Remove `withFileUpload`
export const useFileUpload = ({
  controlled,
  direction,
  allowedTypes,
  uploadedFor,
  isReadOnly,
  oustRemoveAction,
  afterChange,
  setIsUploading,
  afterUpload,
}: RequiredProps): FileUploadInjectedProps & { element: JSX.Element } => {
  const {
    field: { value: unwrapped, onChange },
  } = useController(controlled);
  const value = unwrapped as FileClaim | undefined;

  const { performUpload, isUploading } = useFileUploader({
    onNewFile: onChange,
    allowedTypes,
    uploadedFor,
  });

  const fileUploadInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleOpenFilePicker = useCallback(() => {
    fileUploadInputRef?.current?.click();
  }, [fileUploadInputRef]);

  const clearFile = useCallback(() => {
    onChange(undefined);
    if (afterChange) {
      afterChange();
    }
    if (fileUploadInputRef.current) {
      fileUploadInputRef.current.value = '';
    }
  }, [afterChange, onChange]);

  const handleFileSelected = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      Optional.of(e.target.files)
        .map((files) => files[0])
        .map(async (v) => {
          if (setIsUploading) {
            setIsUploading(true);
            await performUpload(v);
            setIsUploading(false);
          } else {
            await performUpload(v);
          }
          if (afterUpload) {
            afterUpload();
          }
        });
      if (afterChange) {
        afterChange();
      }
    },
    [afterChange, afterUpload, performUpload, setIsUploading],
  );

  return {
    onClick: handleOpenFilePicker,
    isUploading,
    file: value,
    clearFile,
    isReadOnly,
    direction,
    oustRemoveAction,
    element: (
      <VisuallyHidden>
        <FormLabel>File upload</FormLabel>
        <Input
          ref={fileUploadInputRef}
          onChange={handleFileSelected}
          type="file"
          accept={allowedTypes.map((type) => typeToMime[type]).join(',')}
        />
      </VisuallyHidden>
    ),
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GovClient = exports.SuperLookup = exports.SearchSuperParams = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
const utils_1 = require("../utils");
const handler_1 = require("../utils/handler");
class SearchSuperParams extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], SearchSuperParams.prototype, "nameContains", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], SearchSuperParams.prototype, "count", void 0);
exports.SearchSuperParams = SearchSuperParams;
class SuperLookup extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperLookup.prototype, "superLookupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ABN),
    (0, tslib_1.__metadata)("design:type", utils_1.ABN)
], SuperLookup.prototype, "abn", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperLookup.prototype, "productName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperLookup.prototype, "fundName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], SuperLookup.prototype, "usi", void 0);
exports.SuperLookup = SuperLookup;
class GovClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.searchSuper = (0, handler_1.handler)(this, async (params) => this.http
            .request()
            .to('/gov/super/search')
            .optionalParams(params)
            .get((data) => (data.lookups ?? []).map((lookup) => SuperLookup.create(lookup))));
    }
}
exports.GovClient = GovClient;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AustralianAddress = exports.AustralianState = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../ResponseBase");
var AustralianState;
(function (AustralianState) {
    AustralianState["ACT"] = "ACT";
    AustralianState["NSW"] = "NSW";
    AustralianState["NT"] = "NT";
    AustralianState["QLD"] = "QLD";
    AustralianState["SA"] = "SA";
    AustralianState["TAS"] = "TAS";
    AustralianState["VIC"] = "VIC";
    AustralianState["WA"] = "WA";
})(AustralianState = exports.AustralianState || (exports.AustralianState = {}));
class AustralianAddress extends ResponseBase_1.ResponseBase {
    static fake() {
        return AustralianAddress.create({
            country: 'AUSTRALIA',
            firstLine: '123 Mulberry',
            secondLine: '',
            suburb: 'Drummoyne',
            state: AustralianState.WA,
            postalCode: '1234',
        });
    }
    asString() {
        return [
            this.firstLine,
            this.secondLine,
            this.suburb,
            this.state,
            this.postalCode,
            'Australia',
        ]
            .filter((v) => v)
            .join(' ');
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['AUSTRALIA']),
    (0, tslib_1.__metadata)("design:type", String)
], AustralianAddress.prototype, "country", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(50),
    (0, tslib_1.__metadata)("design:type", String)
], AustralianAddress.prototype, "firstLine", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(50),
    (0, tslib_1.__metadata)("design:type", String)
], AustralianAddress.prototype, "secondLine", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(50),
    (0, tslib_1.__metadata)("design:type", String)
], AustralianAddress.prototype, "suburb", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsEnum)(AustralianState),
    (0, tslib_1.__metadata)("design:type", String)
], AustralianAddress.prototype, "state", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumberString)(),
    (0, class_validator_1.Length)(4),
    (0, tslib_1.__metadata)("design:type", String)
], AustralianAddress.prototype, "postalCode", void 0);
exports.AustralianAddress = AustralianAddress;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProofOfIdentityTaskActions = exports.ProofOfIdentityApprovalAction = exports.ProofOfIdentitySubmissionAction = exports.ProofOfIdentityTaskActionType = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../../ResponseBase");
const ProofOfIdentitySubmission_1 = require("./ProofOfIdentitySubmission");
var ProofOfIdentityTaskActionType;
(function (ProofOfIdentityTaskActionType) {
    ProofOfIdentityTaskActionType["PROOF_OF_IDENTITY_SUBMISSION"] = "PROOF_OF_IDENTITY_SUBMISSION";
    ProofOfIdentityTaskActionType["PROOF_OF_IDENTITY_APPROVAL"] = "PROOF_OF_IDENTITY_APPROVAL";
})(ProofOfIdentityTaskActionType = exports.ProofOfIdentityTaskActionType || (exports.ProofOfIdentityTaskActionType = {}));
class ProofOfIdentitySubmissionAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return ProofOfIdentitySubmissionAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], ProofOfIdentitySubmissionAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(ProofOfIdentitySubmission_1.ProofOfIdentitySubmission),
    (0, tslib_1.__metadata)("design:type", ProofOfIdentitySubmission_1.ProofOfIdentitySubmission)
], ProofOfIdentitySubmissionAction.prototype, "submission", void 0);
exports.ProofOfIdentitySubmissionAction = ProofOfIdentitySubmissionAction;
class ProofOfIdentityApprovalAction extends ResponseBase_1.ResponseBase {
    static fromArgs(task) {
        return ProofOfIdentityApprovalAction.create(task);
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], ProofOfIdentityApprovalAction.prototype, "taskId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], ProofOfIdentityApprovalAction.prototype, "isApproved", void 0);
exports.ProofOfIdentityApprovalAction = ProofOfIdentityApprovalAction;
exports.ProofOfIdentityTaskActions = {
    [ProofOfIdentityTaskActionType.PROOF_OF_IDENTITY_SUBMISSION]: ProofOfIdentitySubmissionAction,
    [ProofOfIdentityTaskActionType.PROOF_OF_IDENTITY_APPROVAL]: ProofOfIdentityApprovalAction,
};

import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useManagedRedirects } from '../hooks/managers/useManagedRedirects';

import {
  NotFoundPage,
  HomePage,
  ProfilePage,
  DevActions,
  OAuthDeputyConnect,
  OAuthXeroConnect,
  OAuthMYOBConnect,
  RequestAccessToBusiness,
  OAuthXeroFastSignup,
  Subscribe,
} from '../pages';
import { BillingPage } from '../billing/BillingPage';
import { SetupBusinessAfterRegisteringXeroPage } from '../pages/Business/Settings/Business';
import {
  AuthenticatedRoute,
  BusinessRoute,
  DevelopmentRoute,
} from './RouteTypes';

import { ErrorBoundary, LoadingState } from '../components';
import { SuperadminRoute } from './RouteTypes/SuperadminRoute';
import { NotificationSettingsPage } from '../pages/Layout/NavBar/Notifications/NotificationSettings/NotificationSettings';
import { LogoutPage } from '../auth/pages/Logout';
import { auth } from '../auth';
import { OAuthCloudPayrollConnect } from '../pages/OAuthConnect/OAuthCloudPayrollConnect';

const { Suspense, lazy } = React;

const UserRoutes = lazy(() => import('./UserRoutes'));
const BusinessRoutes = lazy(() => import('./BusinessRoutes'));
const SuperadminRoutes = lazy(() => import('./SuperadminRoutes'));

export const Routes: React.FC = () => {
  useManagedRedirects();

  return (
    <ErrorBoundary>
      <Suspense
        fallback={<LoadingState layoutProps={{ h: '100vh', w: '100%' }} />}
      >
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/apply/:businessId"
            component={RequestAccessToBusiness}
          />
          <DevelopmentRoute exact path="/dev" component={DevActions} />
          <AuthenticatedRoute
            path="/subscribe/:businessId"
            component={Subscribe}
          />
          <BusinessRoute path="/business" component={BusinessRoutes} />
          <SuperadminRoute path="/superadmin" component={SuperadminRoutes} />
          <AuthenticatedRoute path="/user" component={UserRoutes} />
          <AuthenticatedRoute path="/profile" component={ProfilePage} />
          <AuthenticatedRoute
            path="/notification-settings"
            component={NotificationSettingsPage}
          />

          <AuthenticatedRoute path="/billing" component={BillingPage} />

          <BusinessRoute path="/deputy/oauth" component={OAuthDeputyConnect} />

          <AuthenticatedRoute
            path="/xero/aftersignup"
            component={SetupBusinessAfterRegisteringXeroPage}
          />
          <Route path="/xero/oauth" component={OAuthXeroConnect} />
          <Route path="/xero/quicksignup" component={OAuthXeroFastSignup} />
          <Route path="/myob/oauth" component={OAuthMYOBConnect} />
          <Route
            path="/cloud-payroll/oauth"
            component={OAuthCloudPayrollConnect}
          />
          <AuthenticatedRoute path="/logout" component={LogoutPage} />
          <Route path="/index.html">
            <Redirect to="/" />
          </Route>
          {auth.Routes()}
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

import * as React from 'react';
import { Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { TextBlockFormSchemaItem } from '@zap-onboard/api-client';

import { HTMLDisplay } from '../../../../../HTMLDisplay';

type Props = {
  schemaItem: TextBlockFormSchemaItem;
  getPath: (...paths: string[]) => string;
};

export const FormTaskTextBlock: React.FC<Props> = ({ schemaItem, getPath }) => {
  const { register } = useFormContext();

  return (
    <>
      <Input
        type="hidden"
        value={schemaItem.type}
        name={getPath('type')}
        ref={register}
      />
      <HTMLDisplay>{schemaItem.text}</HTMLDisplay>
    </>
  );
};

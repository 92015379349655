/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { pdf, templates, TimeAndZone } from '@zap-onboard/api-client';
import { GROUP_COLORS } from '@zap-onboard/api-client/src/file/Templates';
import { errs } from '@zap-onboard/errors';
import { SizeMM, SizePX } from '@zap-onboard/pdfme-common';
import { Topic } from 'designed';
import Moveable from 'react-moveable';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

import { v4 } from 'uuid';
import create from 'zustand';
import { onError } from '../libs';
import { PX_IN_MM } from './constants';
import { FieldConfig } from './Designer/FieldConfig';
import { cloneDeep, incrementName } from './helpers';

const ZOOM_STEP = 0.25;
export const MAX_ZOOM = 2;
export const MIN_ZOOM = 0.25;

type UPDATE_SOURCE =
  | 'MOVED'
  | 'DRAGGED'
  | 'RESIZED'
  | 'EDITOR'
  | 'GROUP_SELECTOR';
type TopicEvents =
  | {
      type: 'ADD_ITEM';
      fieldType: pdf.Schema.Field.Data['type'];
    }
  | {
      type: 'FIELDS_DRAGGING';
      fieldIds: string[];
    }
  | { type: 'FIELD_UPDATED'; fieldIds: string[]; source: UPDATE_SOURCE };

export interface PDFFormGroup {
  name: string;
  groupId: string;
  color: (typeof GROUP_COLORS)[number];
  values: pdf.Schema.Value.Union[];
  checkboxGroups: pdf.Schema.Field.CheckboxGroup[];
  items: pdf.Schema.Field.Union[];
  signatureDataURL: string | undefined;
  submittedAt: TimeAndZone | undefined;
}

interface Group {
  name: string;
  groupId: string;
  color: string;
  signatureDataURL?: string;
  submittedAt?: TimeAndZone;
}

interface State {
  fields: pdf.Schema.Field.UIData[];
  prevFields: pdf.Schema.Field.UIData[][];
  futureFields: pdf.Schema.Field.UIData[][];
  copiedFields: pdf.Schema.Field.UIData[];

  checkboxGroups: pdf.Schema.Field.CheckboxGroupData[];

  currentGroup: string | null;

  groups: Group[];

  enableDecoration: boolean;

  selectedFieldIds: string[];

  pageCursor: number;

  pageSizes: SizeMM[];

  outerSize: SizePX;

  fieldValues: Record<string, pdf.Schema.Value.Union['rawValue']>;

  fieldValidations: Record<string, string | undefined>;

  zoom: number;
  /** Scale is unzoomed. Multiply by zoom. */
  scale: number;

  shiftPressed: boolean;

  /** FORM */
  focusedFieldId: string | null;

  signatureModal: { fieldId?: string } | null;
}

/**
 * Refs probably don't belong in the global store but there's too much manual
 * DOM manipulation to keep them in the components.
 */
interface FakeRef<T> {
  current: T;
}
function newFakeRef<T>(): FakeRef<T> {
  let value: T = null!;
  return {
    get current() {
      return value;
    },
    set current(v: T) {
      value = v;
    },
  };
}
interface Refs {
  fields: Map<string, HTMLDivElement>;
  papers: Map<number, HTMLDivElement>;
  moveables: Map<number, Moveable>;
  designer: FakeRef<HTMLDivElement | null>;
  mobilePanner: FakeRef<ReactZoomPanPinchRef | null>;
}

export interface PDFSerializedFormData {
  fieldValues: pdf.Schema.Value.Union[];
  signatureDataURL?: string;
}

interface Actions {
  reset: (args: Partial<State>) => void;

  initForm: (args: {
    currentGroup: PDFFormGroup;
    submittedGroups: PDFFormGroup[];
  }) => void;
  serializeForm: () => PDFSerializedFormData;

  selectGroup: (groupId: string) => void;

  setCursor: (pageCursor: number) => void;

  zoomOut: () => void;
  zoomIn: () => void;
  zoomFitWidth: () => void;

  scrollToField: (fieldId: string) => void;
  scrollToPage: (pageId: number) => void;

  undo: () => void;
  redo: () => void;

  events: Topic<TopicEvents>;

  selectFields: (selectedFieldIds: string[], append?: boolean) => void;

  setFieldValue: (
    fieldId: string,
    value: pdf.Schema.Value.Union['rawValue'],
  ) => void;

  copyFields: () => void;
  pasteFields: () => void;

  toggleDecoration: () => void;

  addField: (field: pdf.Schema.Field.Data & { groupId?: string }) => void;
  removeFields: () => unknown;
  moveFields: (delta: { x: number; y: number }) => void;
  updateFields: (
    updaters: (
      | {
          id: string;
          source: UPDATE_SOURCE;
          mutate: (args: pdf.Schema.Field.UIData) => unknown;
        }
      | undefined
    )[],
  ) => void;

  /** FORM */
  setFocusedFieldId: (fieldId: string | null) => void;

  openSignatureModal: (fieldId?: string) => void;
}

const initialState = (): State & { refs: Refs } => ({
  shiftPressed: false,

  pageCursor: 0,

  enableDecoration: true,

  zoom: 1,
  scale: 0,

  pageSizes: [],
  outerSize: { width: 0, height: 0, type: 'PX' },

  selectedFieldIds: [],

  copiedFields: [],
  futureFields: [],
  prevFields: [],

  fields: [],
  checkboxGroups: [],

  currentGroup: null,
  groups: [],
  fieldValues: {},
  fieldValidations: {},

  focusedFieldId: null,

  signatureModal: null,

  refs: {
    fields: new Map(),
    papers: new Map(),
    moveables: new Map(),
    designer: newFakeRef(),
    mobilePanner: newFakeRef(),
  },
});

interface Computed {
  scale: number;
  selectedFields: pdf.Schema.Field.UIData[];
  pageFields(page: number): pdf.Schema.Field.UIData[];
  currentGroup: Group | null;
  nextFieldName: (prefix: string) => string;
  currentPage: SizeMM | null;
  getFieldValue<T extends pdf.Schema.Value.Union['rawValue'] | undefined>(
    fieldId: string,
    fallback?: T,
  ): T;
  fieldOrder: Record<string, number>;

  /** --- FORM --- */
  invalidFieldIds: string[];
  nextFieldToFocusFieldId: string | null;
  focusNextField: () => void;
}

export const usePDFState = create<
  State & Actions & { computed: Computed } & { refs: Refs }
>()((set, get) => {
  const getState = () => get() ?? initialState();

  return {
    ...initialState(),
    events: Topic.create<TopicEvents>({ onSubscriberErr: onError }),
    reset: (overwrite) => set({ ...initialState(), ...overwrite }),

    initForm({ submittedGroups: completedGroups, currentGroup }) {
      const color = templates.GROUP_COLOR_TO_HEX[templates.GROUP_COLORS[0]];

      const allGroups = [currentGroup, ...completedGroups];

      const allFields = allGroups.flatMap((g) => {
        return g.items.map((i) => ({ ...i.asJSON(), groupId: g.groupId }));
      });

      const allIndexedValues = Object.fromEntries(
        allGroups.flatMap((g) => {
          return g.values.map((i) => [i.fieldId, i.rawValue] as const);
        }),
      );
      getState().reset({
        currentGroup: currentGroup.groupId,
        fieldValues: allIndexedValues,
        fields: allFields,
        groups: allGroups.map((g) => ({
          color:
            templates.GROUP_COLOR_TO_HEX[g.color] ??
            templates.GROUP_COLOR_TO_HEX.BLUE,
          groupId: g.groupId,
          name: g.name,
          signatureDataURL: g.signatureDataURL,
          submittedAt: g.submittedAt ?? TimeAndZone.nowForCurrentProcess(),
        })),
        checkboxGroups: allGroups.flatMap((g) =>
          g.checkboxGroups.map((cg) => ({
            ...cg.asJSON(),
            groupId: g.groupId,
          })),
        ),
        fieldValidations: Object.fromEntries(
          currentGroup.items.map((field) => [
            field.value.fieldId,
            field.validationMessage(
              allIndexedValues[field.value.fieldId],
              allFields,
              allIndexedValues,
              currentGroup.checkboxGroups,
            ),
          ]),
        ),
      });
    },

    serializeForm() {
      const values: pdf.Schema.Value.Union[] = [];
      const { fieldValues, fields, groups, currentGroup } = get();
      const group = groups.find((g) => g.groupId === currentGroup);
      if (!group) {
        throw errs.UnexpectedError.create(
          'No group found for current group id',
        );
      }
      fields
        .filter((f) => f.groupId === currentGroup)
        .forEach((f) => {
          const field = pdf.Schema.Field.Union.create(f);
          const value = fieldValues[field.value.fieldId];
          if (value != null) {
            const parsed = field.makeValue(value);
            if (parsed != null) {
              values.push(parsed);
            }
          }
        });
      return {
        fieldValues: values,
        signatureDataURL: group.signatureDataURL,
        isComplete: getState().computed.invalidFieldIds.length === 0,
      };
    },

    selectGroup: (groupId) => {
      const nextGroupId =
        getState().groups.find((g) => g.groupId === groupId)?.groupId ??
        getState().groups[0]?.groupId ??
        null;

      set({ currentGroup: nextGroupId });
    },

    toggleDecoration: () => {
      set((s) => ({ enableDecoration: !s.enableDecoration }));
    },

    undo: () => {
      if (get().prevFields.length === 0) {
        return;
      }
      set((state) => {
        const newPrevFields = [...state.prevFields];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const fields = newPrevFields.pop()!;
        return {
          fields,
          prevFields: newPrevFields,
          futureFields: [...state.futureFields, state.fields],
          selectedFieldIds: [],
        };
      });
    },

    redo: () => {
      if (get().futureFields.length === 0) {
        return;
      }
      set((state) => {
        const newFutureFields = [...state.futureFields];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const fields = newFutureFields.pop()!;
        return {
          fields,
          prevFields: [...state.prevFields, state.fields],
          futureFields: newFutureFields,
          selectedFieldIds: [],
        };
      });
    },

    setCursor: (pageCursor) => {
      set(() => ({ pageCursor }));
    },

    selectFields: (selectedFieldIds, append) => {
      set((state) => {
        let newSelectedFieldIds: string[];
        if (append) {
          newSelectedFieldIds = [
            ...selectedFieldIds,
            ...state.selectedFieldIds,
          ];
        } else {
          newSelectedFieldIds = selectedFieldIds;
        }
        const firstField = state.fields.find((f) =>
          newSelectedFieldIds.some((fieldId) => fieldId === f.fieldId),
        );
        const newGroupId = firstField?.groupId ?? state.currentGroup;

        return {
          selectedFieldIds: newSelectedFieldIds,
          currentGroup: newGroupId,
        };
      });
    },

    scrollToPage: (pageId) => {
      const page = getState().refs.papers.get(pageId);
      if (!page) {
        return;
      }
      page.scrollIntoView({ behavior: 'auto', block: 'center' });
    },

    scrollToField: (fieldId) => {
      const field = getState().refs.fields.get(fieldId);
      if (!field) {
        return;
      }

      const {
        refs: {
          mobilePanner: { current: panner },
        },
      } = getState();
      if (panner) {
        panner.zoomToElement(field, 1, 0);
      } else {
        field.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    },

    // TODO: Would prefer to place near cursor (?)
    copyFields: () => {
      const selectedFields = getState().computed.selectedFields;
      if (selectedFields.length === 0) {
        return;
      }
      set(() => ({ copiedFields: selectedFields }));
    },
    pasteFields: () => {
      const copiedFields = cloneDeep(getState().copiedFields);
      if (copiedFields.length === 0) {
        return;
      }
      const newCheckboxGroups: ({
        oldCheckboxGroupId: string;
      } & pdf.Schema.Field.CheckboxGroupData)[] = [];
      copiedFields.forEach((f) => {
        if (f.page !== getState().pageCursor && f.type === 'checkbox') {
          const existingGroup = newCheckboxGroups.find(
            (g) => g.oldCheckboxGroupId === f.checkboxGroupId,
          );
          if (existingGroup) {
            f.checkboxGroupId = existingGroup.checkboxGroupId;
          } else {
            const realGroup = getState().checkboxGroups.find(
              (cg) => cg.checkboxGroupId === f.checkboxGroupId,
            );
            const newGroup = {
              ...(realGroup ?? {}),
              oldCheckboxGroupId: f.checkboxGroupId,
              checkboxGroupId: v4(),
              groupId: f.groupId,
            };
            newCheckboxGroups.push(newGroup);
            f.checkboxGroupId = newGroup.checkboxGroupId;
          }
        }
        f.fieldId = v4();
        f.page = getState().pageCursor;
        f.placement.x += 5;
        f.placement.y += 5;
        f.name = incrementName(
          f.name,
          getState().fields.map((f) => f.name),
        );
      });
      set((state) => {
        const fields = [
          ...state.fields,
          ...copiedFields.map((f) => {
            const page = getState().pageSizes[state.pageCursor];
            f.page = state.pageCursor;
            return constrainFieldToPage(f, page);
          }),
        ];
        return {
          fields,
          futureFields: [],
          prevFields: [...state.prevFields, state.fields],
          copiedFields: [],
          checkboxGroups: [...state.checkboxGroups, ...newCheckboxGroups],
        };
      });
    },

    addField: (fieldData) => {
      const groupId =
        fieldData.groupId ?? getState().computed.currentGroup?.groupId;
      let checkboxGroups = getState().checkboxGroups;
      if (!groupId) {
        return;
      }
      if (fieldData.type === 'checkbox') {
        const checkboxGroup = checkboxGroups.find(
          (g) => fieldData.checkboxGroupId === g.checkboxGroupId,
        );

        if (checkboxGroup && checkboxGroup.groupId !== groupId) {
          throw errs.UnexpectedError.create('Checkbox group already exists');
        }
        if (!checkboxGroup) {
          checkboxGroups = [
            ...checkboxGroups,
            {
              checkboxGroupId: fieldData.checkboxGroupId,
              groupId,
              validationRange: { min: 1 },
            },
          ];
        }
      }
      const field = { ...fieldData, groupId };
      // Push select onto back of event queue so that
      // "moveable-react" refs will update prior to state update.
      setTimeout(() => getState().selectFields([field.fieldId]), 0);
      const page = getState().pageSizes[field.page];
      set((state) => ({
        fields: [...state.fields, constrainFieldToPage(field, page)],
        prevFields: [...state.prevFields, state.fields],
        futureFields: [],
        checkboxGroups,
      }));
    },
    removeFields: () => {
      set((state) => {
        const ids = state.selectedFieldIds;
        const fields = state.fields.filter(
          (field) => !ids.includes(field.fieldId),
        );
        // Don't remove checkboxGroups so that undo/redo will function without
        // changing state.
        return {
          fields,
          prevFields: [...state.prevFields, state.fields],
          futureFields: [],
          selectedFieldIds: [],
        };
      });
    },
    moveFields: (delta) => {
      getState().updateFields(
        getState().computed.selectedFields.map((f) => {
          const page = getState().pageSizes[f.page];
          return {
            id: f.fieldId,
            source: 'MOVED',
            mutate: (f) => {
              f.placement.x = Math.max(
                0,
                Math.min(
                  page.width - f.placement.width,
                  f.placement.x + delta.x,
                ),
              );
              f.placement.y = Math.max(
                0,
                Math.min(
                  page.height - f.placement.height,
                  f.placement.y + delta.y,
                ),
              );
            },
          };
        }),
      );
    },

    updateFields: (updaters) => {
      set((state) => {
        const fields = [...state.fields];
        updaters.filter(isDefined).forEach((updater) => {
          const index = fields.findIndex(
            (field) => field.fieldId === updater.id,
          );
          if (index !== -1) {
            const field = cloneDeep(fields[index]);
            const page = getState().pageSizes[field.page];
            updater.mutate(field);
            fields[index] = constrainFieldToPage(field, page);
          }
        });
        return {
          fields,
          prevFields: [...state.prevFields, state.fields],
          futureFields: [],
        };
      });
      setTimeout(
        () =>
          getState().events.publish({
            type: 'FIELD_UPDATED',
            source: 'MOVED',
            fieldIds: updaters.filter(isDefined).map((f) => f.id),
          }),
        0,
      );
    },

    zoomOut: () => {
      set(({ zoom }) => {
        if (zoom <= MIN_ZOOM) {
          return { zoom };
        }
        return { zoom: zoom - ZOOM_STEP };
      });
    },
    zoomIn: () => {
      set(({ zoom }) => {
        if (zoom >= MAX_ZOOM) {
          return { zoom };
        }
        return { zoom: zoom + ZOOM_STEP };
      });
    },

    zoomFitWidth: () => {
      set(({ scale, refs: { designer }, computed: { currentPage } }) => {
        if (!designer.current || !currentPage) {
          return {};
        }
        const targetWidthPx = designer.current.clientWidth - 30;
        const currentWidthPx = currentPage.width * PX_IN_MM * scale;

        const newZoom = targetWidthPx / currentWidthPx;

        return {
          zoom: newZoom,
        };
      });
    },

    setFieldValue(fieldId: string, value: pdf.Schema.Value.Union['rawValue']) {
      set((state) => {
        const fieldValues = { ...state.fieldValues, [fieldId]: value };
        const field = state.fields.find((f) => f.fieldId === fieldId);
        if (!field) {
          throw errs.UnexpectedError.create('That field does not exist');
        }
        const fieldsToValidate: pdf.Schema.Field.Data[] = [];
        if (field.type === 'checkbox') {
          state.fields
            .filter(
              (f) =>
                f.type === 'checkbox' &&
                f.checkboxGroupId === field.checkboxGroupId,
            )
            .forEach((f) => {
              fieldsToValidate.push(f);
            });
        } else {
          fieldsToValidate.push(field);
        }

        const fieldValidations = {
          ...state.fieldValidations,
        };
        fieldsToValidate.forEach((f) => {
          fieldValidations[f.fieldId] =
            pdf.Schema.Field.Union.validationMessageForData(
              f,
              fieldValues[f.fieldId],
              state.fields,
              fieldValues,
              state.checkboxGroups,
            );
        });
        return { fieldValues, fieldValidations };
      });
    },

    setFocusedFieldId: (fieldId) => {
      const { focusedFieldId } = get();
      if (focusedFieldId === fieldId) {
        return;
      }
      set((current) => {
        return { focusedFieldId: fieldId };
      });
    },

    openSignatureModal: (fieldId) => {
      set(() => {
        return { signatureModal: { fieldId } };
      });
    },

    computed: {
      nextFieldName(prefix: string) {
        const fieldNameRegex = new RegExp(`^${prefix}(\\d+)$`);
        const fieldNames = getState().fields.map((f) => f.name);
        const max = fieldNames.reduce((max, name) => {
          const match = name.match(fieldNameRegex);
          if (!match) {
            return max;
          }
          const num = Number(match[1]);
          return Math.max(max, num);
        }, 0);
        return `${prefix}${max + 1}`;
      },
      get scale() {
        return getState().scale * getState().zoom;
      },
      get currentGroup() {
        return (
          getState().groups.find(
            (g) => g.groupId === getState().currentGroup,
          ) ?? null
        );
      },
      get currentPage() {
        return getState().pageSizes[getState().pageCursor];
      },
      get selectedFields() {
        return getState().fields.filter((field) =>
          getState().selectedFieldIds.includes(field.fieldId),
        );
      },
      pageFields(page: number) {
        return getState().fields.filter((field) => field.page === page);
      },

      getFieldValue<T extends pdf.Schema.Value.Union['rawValue'] | undefined>(
        fieldId: string,
        fallback?: T,
      ): T {
        const value = getState().fieldValues[fieldId];
        return (value as T) ?? fallback!;
      },

      get fieldOrder() {
        const order: Record<string, number> = {};
        getState()
          .fields.sort((a, b) => {
            if (a.page === b.page) {
              return (
                a.placement.y - b.placement.y || a.placement.x - b.placement.x
              );
            }
            return a.page - b.page;
          })
          .forEach((field, index) => {
            order[field.fieldId] = index;
          });
        return order;
      },

      get invalidFieldIds() {
        const { fields, fieldValues, fieldValidations } = getState();
        return fields
          .filter((f) => !!fieldValidations[f.fieldId])
          .map((f) => f.fieldId);
      },

      focusNextField() {
        const {
          setFocusedFieldId,
          scrollToField,
          computed: { nextFieldToFocusFieldId },
        } = getState();

        if (nextFieldToFocusFieldId) {
          setFocusedFieldId(nextFieldToFocusFieldId);
          scrollToField(nextFieldToFocusFieldId);
        }
      },

      get nextFieldToFocusFieldId() {
        const {
          computed: { fieldOrder, invalidFieldIds },
          focusedFieldId,
        } = getState();

        const invalidFieldIdsOrdered = invalidFieldIds.sort(
          (a, b) => fieldOrder[a] - fieldOrder[b],
        );

        let nextFieldId: string | null = invalidFieldIdsOrdered[0] ?? null;
        if (focusedFieldId) {
          const currentIdx = invalidFieldIdsOrdered.indexOf(focusedFieldId);
          const nextIdx = currentIdx + 1;
          if (currentIdx !== -1 && nextIdx < invalidFieldIdsOrdered.length) {
            nextFieldId = invalidFieldIdsOrdered[nextIdx];
          }
        }
        return nextFieldId;
      },

      get focusedField() {
        const { fields, focusedFieldId } = getState();
        return fields.find((f) => f.fieldId === focusedFieldId) ?? null;
      },
    },
  };
});

export const constrainFieldToPage = (
  field: pdf.Schema.Field.UIData,
  page: SizeMM,
) => {
  const { width, height } = page;
  const { x, y, width: fieldWidth, height: fieldHeight } = field.placement;
  const constrainedWidth = Math.min(page.width, fieldWidth);
  const constrainedHeight = Math.min(page.height, fieldHeight);
  const constrainedX = Math.max(0, Math.min(width - constrainedWidth, x));
  const constrainedY = Math.max(0, Math.min(height - constrainedHeight, y));
  return {
    ...field,
    placement: {
      ...field.placement,
      width: constrainedWidth,
      height: constrainedHeight,
      x: constrainedX,
      y: constrainedY,
    },
  };
};

window.addEventListener('keydown', (e: KeyboardEvent) => {
  if (e.shiftKey) {
    if (usePDFState.getState().shiftPressed) {
      return;
    }
    usePDFState.setState({ shiftPressed: true });
  }
});

window.addEventListener('keyup', (e) => {
  if (e.key === 'Shift') {
    if (!usePDFState.getState().shiftPressed) {
      return;
    }
    usePDFState.setState({ shiftPressed: false });
  }
});

function isDefined<T>(value: T | undefined | null): value is T {
  return value != null;
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateUserProfile = exports.UserProfile = exports.ProfileResponse = exports.AllProfileFields = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const AustralianAddress_1 = require("../../utils/AustralianAddress");
const IsInPast_1 = require("../../utils/validations/IsInPast");
exports.AllProfileFields = [
    'name',
    'email',
    'address',
    'primaryContactNumber',
    'birthday',
    'gender',
    'emergencyContact',
];
class ProfileResponse extends ResponseBase_1.ResponseBase {
    getFullName() {
        if (this.givenName && this.familyName) {
            return `${this.givenName} ${this.familyName}`;
        }
        return undefined;
    }
    getUserName() {
        return this.givenName && this.familyName
            ? `${this.givenName} ${this.familyName}`
            : this.email?.asJSON();
    }
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileResponse.prototype, "givenName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, class_validator_1.IsNotEmpty)(),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileResponse.prototype, "otherGivenName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileResponse.prototype, "familyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AustralianAddress_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", AustralianAddress_1.AustralianAddress)
], ProfileResponse.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileResponse.prototype, "gender", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], ProfileResponse.prototype, "birthday", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], ProfileResponse.prototype, "primaryContactNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], ProfileResponse.prototype, "secondaryContactNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileResponse.prototype, "emergencyContactName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], ProfileResponse.prototype, "emergencyContactNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], ProfileResponse.prototype, "emergencyContactRelationship", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], ProfileResponse.prototype, "email", void 0);
exports.ProfileResponse = ProfileResponse;
class UserProfile extends ProfileResponse {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UserProfile.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], UserProfile.prototype, "hasAcceptedTermsAndConditions", void 0);
exports.UserProfile = UserProfile;
class UpdateUserProfile extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, class_validator_1.MinLength)(1),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateUserProfile.prototype, "givenName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, class_validator_1.MinLength)(1),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateUserProfile.prototype, "familyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AustralianAddress_1.AustralianAddress),
    (0, tslib_1.__metadata)("design:type", AustralianAddress_1.AustralianAddress)
], UpdateUserProfile.prototype, "address", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateUserProfile.prototype, "gender", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, IsInPast_1.IsInPast)(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], UpdateUserProfile.prototype, "birthday", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], UpdateUserProfile.prototype, "primaryContactNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true, entity: utils_1.ContactNumber }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", Object)
], UpdateUserProfile.prototype, "secondaryContactNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", Object)
], UpdateUserProfile.prototype, "otherGivenName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateUserProfile.prototype, "emergencyContactName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", utils_1.ContactNumber)
], UpdateUserProfile.prototype, "emergencyContactNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.MaxLength)(35),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateUserProfile.prototype, "emergencyContactRelationship", void 0);
exports.UpdateUserProfile = UpdateUserProfile;

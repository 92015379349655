import {
  Box,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  TableContainer,
} from '@chakra-ui/react';

import {
  TaskAssignmentResolutionResponse,
  TemplateTask,
} from '@zap-onboard/api-client';

import { FilePreviewModal } from 'src/components/FilePreviewModal';
import { IoMdOpen } from 'react-icons/io';
import { useFlowResolveAssignments } from '../../../../../hooks/data/flow/useFlowResolveAssignments';
import { UseQueryResult } from 'react-query';

export const TemplateTaskHistory = (props: {
  task: TemplateTask;
  memberId?: string;
  businessId: string;
}) => {
  const { task, memberId, businessId } = props;

  const assignments = useFlowResolveAssignments({
    businessId,
    groups: task.groups.map((g) => {
      return {
        assignments: g.merged.defaultAssignments ?? [],
        groupId: g.merged.groupId,
      };
    }),
    subjectMemberId: memberId,
  });

  return (
    <Stack spacing={4}>
      <TableContainer>
        <Table size="md">
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Group</Th>
            </Tr>
          </Thead>
          <Tbody>
            {task.getHistoryLines().map((history) => (
              <Group
                key={history.groupId}
                history={history}
                assignees={assignments}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <FilePreviewModal
          file={task.getCurrentFile()}
          trigger="View document"
          _trigger={{
            variant: 'outline',
          }}
          filename={`${task.name}-current-document`}
        />
      </Box>
    </Stack>
  );
};

const Group = ({
  history,
  assignees,
}: {
  history: ReturnType<TemplateTask['getHistoryLines']>[number];
  assignees: UseQueryResult<TaskAssignmentResolutionResponse>;
}) => {
  const assigneeGroup = assignees.data?.find(history.groupId);

  const row = (onClick?: () => unknown) => (
    <Tr
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={{ bg: 'gray.100' }}
      role={onClick ? 'button' : undefined}
    >
      <Td>
        <Stack>
          <Box>
            <Text>{history.label}</Text>
          </Box>

          {history.status === 'SIGNED' && (
            <Tooltip label={history.submittedAtAgo}>
              <Text fontSize="sm" color="brand.dark-gray">
                {history.submittedAt}
              </Text>
            </Tooltip>
          )}
        </Stack>
      </Td>

      <Td>
        <Stack>
          <Box>
            <Text>{history.name}</Text>
          </Box>

          {history.status !== 'SIGNED' && !!assigneeGroup && (
            <Tooltip label={assigneeGroup.map((g) => g.userName).join('\n')}>
              <Stack my={2}>
                {assigneeGroup.slice(0, 8)?.map((assignee) => (
                  <Text
                    fontSize="sm"
                    color="brand.dark-gray"
                    key={assignee.memberId}
                  >
                    {assignee.userName}
                  </Text>
                ))}
                {assigneeGroup.length > 8 && (
                  <Text fontSize="sm" color="brand.dark-gray">
                    +{assigneeGroup.length - 8} more
                  </Text>
                )}
              </Stack>
            </Tooltip>
          )}

          {history.status === 'SIGNED' && (
            <Tooltip label={history.userAgent}>
              <Text fontSize="sm" color="brand.dark-gray">
                {history.ipAddress}
              </Text>
            </Tooltip>
          )}
        </Stack>
      </Td>
      <Td w="32px">
        {history.status === 'SIGNED' && (
          <Tooltip label="View File">
            <IconButton
              variant="outline"
              aria-label="Open file"
              icon={<IoMdOpen />}
            />
          </Tooltip>
        )}
      </Td>
    </Tr>
  );

  return history.signedFile ? (
    <FilePreviewModal
      file={history.signedFile}
      trigger={({ onToggle }) => row(onToggle)}
      filename={`${history.name}`}
    />
  ) : (
    row()
  );
};

import {
  Button,
  Stack,
  Text,
  Input,
  Spacer,
  HStack,
  Link,
  Center,
  ButtonGroup,
} from '@chakra-ui/react';
import { rawTheme } from '@zap-onboard/web-components';
import QRCode from 'qrcode.react';
import { Spinner } from 'components';
import { useEffect, useState } from 'react';
import { useAPI } from '../../../services/API';
import { MFA } from '@zap-onboard/api-client';
import { onError } from 'libs';

export const OTPQRCodeForm: React.FC<{
  onNext: (factor: MFA.TOTP.AddResponse) => unknown;
  onBack: () => unknown;
}> = ({ onBack, onNext }) => {
  const api = useAPI();

  const [setup, setSetup] = useState<MFA.TOTP.AddResponse>();

  useEffect(() => {
    api
      .identity()
      .mfa.addTOTPSource({ name: 'Primary' })
      .tap(setSetup, onError);
  }, [api]);

  if (!setup) {
    return <Spinner />;
  }

  return (
    <>
      <Text>You will need Google Authenticator or a password manager</Text>

      <HStack fontSize="sm">
        <Link
          isExternal
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_AU&gl=US"
          textDecoration="underline"
        >
          Android
        </Link>
        <Link
          isExternal
          href="https://apps.apple.com/au/app/google-authenticator/id388497605"
          textDecoration="underline"
        >
          Apple
        </Link>
      </HStack>

      <Stack textAlign="center" alignItems="center">
        <Text fontWeight="bold" color="gray.500" fontSize="xs">
          Scan the QR code into your app
        </Text>
        <Center>
          <QRCode
            size={140}
            renderAs="svg"
            fgColor={rawTheme.colors.brand.black}
            value={setup.totpURL}
          />
        </Center>
        <Text fontWeight="bold" color="gray.500" fontSize="xs">
          or manually enter this into your app
        </Text>
        <Input
          marginX="auto"
          maxW="230px"
          textAlign="center"
          readOnly
          value={setup.totpURL}
        />

        <Spacer p={2} />
        <ButtonGroup>
          <Button onClick={() => onNext(setup)}>
            I&apos;ve added the code
          </Button>
          <Button variant="outline" onClick={onBack}>
            Back
          </Button>
        </ButtonGroup>
      </Stack>
    </>
  );
};

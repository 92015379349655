"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
const designed_1 = require("designed");
const handler_1 = require("../utils/handler");
const Action_1 = require("./models/Action");
const Archive_1 = require("./models/Archive");
const GetDesign_1 = require("./models/GetDesign");
const Submit_1 = require("./models/Submit");
const UpsertDesign_1 = require("./models/UpsertDesign");
const models_1 = require("./models");
const GetOverview_1 = require("./models/GetOverview");
class Client {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.toggleDesignArchived = (0, handler_1.handler)(this, async (args) => {
            await this.http
                .request()
                .to('/certs/design')
                .bodyJSON(Archive_1.ArchiveCertificationDesign.Request.create(args))
                .delete();
        });
        this.upsertDesign = (0, handler_1.handler)(this, async (design) => this.http
            .request()
            .to('/certs/design')
            .bodyJSON(UpsertDesign_1.UpsertDesign.Request.create({ design }))
            .post((v) => GetDesign_1.GetDesign.Response.create(v)));
        this.getDesigns = (0, handler_1.handler)(this, async (args = {}) => this.http
            .request()
            .optionalParams(GetDesign_1.GetDesign.Request.create(args).asJSON())
            .to('/certs/designs')
            .get((r) => GetDesign_1.GetDesign.ListResponse.create(r)));
        this.getDesign = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .optionalParams(GetDesign_1.GetDesign.Request.create(args).asJSON())
            .to(`/certs/design/${args.certificationDesignId}`)
            .get((r) => GetDesign_1.GetDesign.Response.create(r)));
        this.submit = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/cert/submission')
            .bodyJSON(Submit_1.Submit.PersonalRequest.create(args))
            .post((d) => Submit_1.Submit.Response.create(d)));
        this.submitForMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to('/cert/business/submission')
            .bodyJSON(Submit_1.Submit.AdminRequest.create(args))
            .post((d) => Submit_1.Submit.Response.create(d)));
        this.getMembersLatestSubmission = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/certs/member/submission/latest`)
            .optionalParams(args)
            .get((data) => models_1.GetSubmission.Response.create(data)));
        this.getMemberSubmission = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/certs/member/submission/find`)
            .optionalParams(args)
            .get((data) => models_1.GetSubmission.Response.create(data)));
        this.getMyLatestSubmission = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/certs/my/submission/latest`)
            .optionalParams(args)
            .get((data) => models_1.GetSubmission.Response.create(data)));
        this.getMySubmission = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/certs/my/submission/find`)
            .optionalParams(args)
            .get((data) => models_1.GetSubmission.Response.create(data)));
        this.action = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/certs/submissions/action`)
            .bodyJSON(Action_1.Action.Request.create({
            action: args,
        }))
            .post());
        this.getRequirements = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .optionalParams(args)
            .to(`/certs/design/requirements`)
            .get((data) => models_1.Requirement.List.Response.create(data)));
        this.getPositionRequirements = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/position/${args.positionId}/certs`)
            .get((data) => models_1.Requirement.Response.create(data)));
        this.addRequirementToPosition = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/position/cert/requirements`)
            .bodyJSON(models_1.Requirement.Position.AddRequest.create(args))
            .post());
        this.addRequirementsToPosition = ({ positionId, certificationDesignIds, }) => {
            return designed_1.AsyncResult.fromPromise(Promise.all(certificationDesignIds.map(async (certificationDesignId) => this.addRequirementToPosition({
                positionId,
                certificationDesignId,
            }).getOrThrowFailure())));
        };
        this.removeRequirementFromPosition = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/position/cert/requirements`)
            .bodyJSON(models_1.Requirement.Position.AddRequest.create(args))
            .delete());
        this.getMemberCertRequirements = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/member/${args.memberId}/certs`)
            .get((data) => models_1.Requirement.Response.create(data)));
        this.addRequirementToMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/member/cert/requirements`)
            .bodyJSON(models_1.Requirement.Member.AddRequest.create(args))
            .post());
        this.removeCertRequirementFromMember = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .to(`/member/cert/requirements`)
            .bodyJSON(models_1.Requirement.Member.AddRequest.create(args))
            .delete());
        this.getBusinessOverview = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .optionalParams(GetOverview_1.GetOverview.ForBusinessRequest.create(args).asJSON())
            .to(`/certs/overview`)
            .get((d) => GetOverview_1.GetOverview.Response.create(d)));
        this.getMyOverview = (0, handler_1.handler)(this, async (args) => this.http
            .request()
            .optionalParams(GetOverview_1.GetOverview.ForBusinessRequest.create(args).asJSON())
            .to(`/certs/my/overview`)
            .get((d) => GetOverview_1.GetOverview.Response.create(d)));
    }
}
exports.Client = Client;

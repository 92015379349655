import * as React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { FlowDesignLinkedToPositionListing } from '@zap-onboard/api-client';

import {
  EmptyState,
  LoadingState,
  Tile,
  TileGroupLayout,
} from '../../components';

type AssignedFlowDesignsProps = {
  droppableId: string;
  flowDesigns: Readonly<FlowDesignLinkedToPositionListing[]>;
  isLoading: boolean;
  isReordering: boolean;
};

export const AssignedFlowDesigns: React.FC<AssignedFlowDesignsProps> = ({
  droppableId,
  flowDesigns,
  isLoading,
  isReordering,
}) => {
  const { Layout, FlowDesignTile } = Local;

  if (isLoading) {
    return (
      <Layout>
        <Flex justify="center" w="100%">
          <LoadingState />
        </Flex>
      </Layout>
    );
  }

  if (flowDesigns.length === 0) {
    return (
      <Layout>
        <Flex justify="center" w="100%">
          <EmptyState message="No assigned workflows" />
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout>
      <Droppable
        droppableId={droppableId}
        isDropDisabled={!isReordering}
        direction="horizontal"
      >
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            <TileGroupLayout p={{ base: 2, md: 4 }}>
              {flowDesigns.map(({ flowDesignId, flowDesignName }, index) => {
                if (isReordering) {
                  return (
                    <FlowDesignTile
                      key={flowDesignId}
                      flowDesignId={flowDesignId}
                      flowDesignName={flowDesignName}
                      index={index}
                      isReordering={isReordering}
                    />
                  );
                }

                return (
                  <Link
                    key={flowDesignId}
                    to={`/business/flow-designs/${flowDesignId}`}
                  >
                    <FlowDesignTile
                      flowDesignId={flowDesignId}
                      flowDesignName={flowDesignName}
                      index={index}
                      isReordering={isReordering}
                    />
                  </Link>
                );
              })}
            </TileGroupLayout>
          </Box>
        )}
      </Droppable>
    </Layout>
  );
};

namespace Local {
  export const Layout: React.FC = ({ children }) => (
    <Flex layerStyle="base" minH="320px">
      {children}
    </Flex>
  );

  export const FlowDesignTile: React.FC<{
    flowDesignId: string;
    flowDesignName: string;
    index: number;
    isReordering: boolean;
  }> = ({ flowDesignId, flowDesignName, index, isReordering }) => (
    <Draggable
      draggableId={flowDesignId}
      index={index}
      isDragDisabled={!isReordering}
    >
      {(provided, snapshot) => {
        return (
          <Box
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Tile
              layerStyle={isReordering ? 'dotted' : 'base'}
              bg={snapshot.isDragging ? 'blue.50' : 'inherit'}
              hover={isReordering ? { bg: 'blue.50' } : undefined}
              renderHeader={() => (
                <Text
                  fontSize={{ base: 'sm', md: 'md' }}
                  textAlign="center"
                  noOfLines={{ base: 3, md: 3 }}
                >
                  {flowDesignName}
                </Text>
              )}
            />
          </Box>
        );
      }}
    </Draggable>
  );
}

/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { Link } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { assertNever } from '../../helpers';

import { ReactComponent as WhiteLogo } from './canyou-white-logo.svg';
import { ReactComponent as BlueLogo } from './canyou-blue-logo.svg';
import { ReactComponent as BlackLogo } from './canyou-black-logo.svg';

type LogoProps = {
  color: 'brand.blue' | 'white' | 'black';
  height: string;
  link?: string;
};

const renderedLogo = ({ color, height }: LogoProps) => {
  switch (color) {
    case 'brand.blue':
      return <BlueLogo height={height} />;
    case 'white':
      return <WhiteLogo height={height} />;
    case 'black':
      return <BlackLogo height={height} />;
    default:
      return assertNever(color);
  }
};

export const Logo: React.FC<LogoProps> = (props) => {
  if (props.link) {
    return (
      <Link to={props.link} as={ReactRouterLink}>
        {renderedLogo(props)}
      </Link>
    );
  }

  return renderedLogo(props);
};

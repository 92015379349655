"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptInvitation = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
var AcceptInvitation;
(function (AcceptInvitation) {
    class BusinessRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsUUID)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], BusinessRequest.prototype, "businessId", void 0);
    AcceptInvitation.BusinessRequest = BusinessRequest;
    class TokenRequest extends ResponseBase_1.ResponseBase {
    }
    (0, tslib_1.__decorate)([
        designed_1.Entity.Field(),
        (0, class_validator_1.IsString)(),
        (0, tslib_1.__metadata)("design:type", String)
    ], TokenRequest.prototype, "token", void 0);
    AcceptInvitation.TokenRequest = TokenRequest;
})(AcceptInvitation = exports.AcceptInvitation || (exports.AcceptInvitation = {}));

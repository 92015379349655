"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpgradeResponse = exports.UpgradeFailedResponse = exports.UpgradeProgressResponse = exports.UpgradeCompleteResponse = exports.UpgradeRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const FileClaim_1 = require("../FileClaim");
const utils_1 = require("../../utils");
const Document_1 = require("./Document");
class UpgradeRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], UpgradeRequest.prototype, "file", void 0);
exports.UpgradeRequest = UpgradeRequest;
class UpgradeCompleteResponse extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.status = 'COMPLETE';
    }
}
UpgradeCompleteResponse.status = 'COMPLETE';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpgradeCompleteResponse.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(Document_1.Document),
    (0, tslib_1.__metadata)("design:type", Document_1.Document)
], UpgradeCompleteResponse.prototype, "document", void 0);
exports.UpgradeCompleteResponse = UpgradeCompleteResponse;
class UpgradeProgressResponse extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.status = 'IN_PROGRESS';
    }
}
UpgradeProgressResponse.status = 'IN_PROGRESS';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpgradeProgressResponse.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Percentage),
    (0, tslib_1.__metadata)("design:type", utils_1.Percentage)
], UpgradeProgressResponse.prototype, "progress", void 0);
exports.UpgradeProgressResponse = UpgradeProgressResponse;
class UpgradeFailedResponse extends ResponseBase_1.ResponseBase {
    constructor() {
        super(...arguments);
        this.status = 'FAILED';
    }
}
UpgradeFailedResponse.status = 'FAILED';
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpgradeFailedResponse.prototype, "status", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpgradeFailedResponse.prototype, "failureMessage", void 0);
exports.UpgradeFailedResponse = UpgradeFailedResponse;
class UpgradeResponse extends designed_1.Entity.Union.define({
    key: 'status',
    entries: [
        UpgradeCompleteResponse,
        UpgradeProgressResponse,
        UpgradeFailedResponse,
    ],
}) {
}
exports.UpgradeResponse = UpgradeResponse;

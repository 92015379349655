"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificationType = void 0;
var CertificationType;
(function (CertificationType) {
    CertificationType["FREEFORM"] = "FREEFORM";
    CertificationType["DRIVERS_LICENCE"] = "DRIVERS_LICENCE";
    CertificationType["RSA"] = "RSA";
    CertificationType["FIRST_AID"] = "FIRST_AID";
})(CertificationType = exports.CertificationType || (exports.CertificationType = {}));

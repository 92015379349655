var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormHelperText, Input as ChakraInput, InputGroup, Text, } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormControl } from '../wrappers/FormControl';
export var TextInput = function (_a) {
    var name = _a.name, placeholder = _a.placeholder, defaultValue = _a.defaultValue, onKeyDown = _a.onKeyDown, onFocus = _a.onFocus, children = _a.children, helper = _a.helper, leftInputElement = _a.leftInputElement, description = _a.description, isAutoFocus = _a.isAutoFocus, isDisabled = _a.isDisabled, isReadOnly = _a.isReadOnly, renderInput = _a.renderInput, onChange = _a.onChange, _input = _a._input, uncontrolled = _a.uncontrolled, controlProps = __rest(_a, ["name", "placeholder", "defaultValue", "onKeyDown", "onFocus", "children", "helper", "leftInputElement", "description", "isAutoFocus", "isDisabled", "isReadOnly", "renderInput", "onChange", "_input", "uncontrolled"]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    var contextProvider = uncontrolled ? undefined : useFormContext();
    var input = (_jsx(ChakraInput, __assign({ autoFocus: isAutoFocus, onFocus: onFocus, onKeyDown: onKeyDown, name: name, isDisabled: isDisabled, placeholder: placeholder, isReadOnly: isReadOnly, onChange: onChange, defaultValue: defaultValue, variant: "outline", ref: contextProvider === null || contextProvider === void 0 ? void 0 : contextProvider.register, autoComplete: "off" }, _input), void 0));
    return (_jsxs(FormControl, __assign({ uncontrolled: uncontrolled, name: name }, controlProps, { children: [_jsx(Text, { children: description }, void 0), !renderInput && (_jsxs(InputGroup, { children: [leftInputElement, input] }, void 0)), renderInput && renderInput({ input: input }), helper && (_jsx(FormHelperText, __assign({ fontSize: "sm", fontStyle: "italic" }, { children: helper }), void 0)), children] }), void 0));
};

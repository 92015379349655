/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import {
  Box,
  Center,
  Grid,
  GridItem,
  Icon,
  SlideFade,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { MdCheckCircle, MdFastForward } from 'react-icons/md';

import { flow, FlowTaskStatus } from '@zap-onboard/api-client';

import { FlowDetailsHeader } from '../../../pages/FlowDetail/FlowDetailsHeader';
import { TaskContent } from './Content';
import { TaskItemStatus, TaskManagerSideBar } from './Sidebar';
import { TaskActivity } from './Sidebar/TaskActivity';
import { FlowHistory } from './Sidebar/FlowHistory';
import { WorkflowsToAction } from '../../WorkflowsToAction';
import { TaskCompleted } from './TaskStatus/TaskCompleted';

type TaskManagerProps = {
  flow: flow.GetFlows.Flow;
  setFlow: (flow: flow.GetFlows.Flow) => void;
  canEditTask: boolean;
  context: 'user' | 'business';
};

const taskStatusMap = {
  [FlowTaskStatus.COMPLETE]: TaskItemStatus.SUCCESS,
  [FlowTaskStatus.BUSINESS_SKIPPED]: TaskItemStatus.SUCCESS,
};

const { useState } = React;

export const FlowDetailTaskManager: React.FC<TaskManagerProps> = ({
  flow,
  setFlow,
  canEditTask,
  context,
}) => {
  const [taskId, setTaskId] = useState<string | undefined>(
    () => flow.firstIncompleteTask()?.taskId ?? flow.tasks[0]?.taskId,
  );

  if (!taskId) {
    return (
      <Center>
        <Stack layerStyle="base">
          <TaskCompleted />
          <WorkflowsToAction context={context} />
        </Stack>
      </Center>
    );
  }

  const showAccessHistory = context === 'business';

  return (
    <Grid
      my={4}
      gap={[2, 4]}
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
      data-testid="flowTaskManager"
    >
      <GridItem colStart={1} colSpan={{ base: 1, md: 1 }}>
        <TaskManagerSideBar
          isReadOnly
          renderHeader={() => <FlowDetailsHeader flow={flow} />}
          taskItems={flow.tasks.map((t, index) => ({
            id: t.taskId,
            index,
            key: t.taskId,
            focused: t.taskId === taskId,
            type: t.taskDesignType,
            name: t.name,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            status: (taskStatusMap as any)[t.status] ?? TaskItemStatus.NORMAL,
            onClick: () => setTaskId(t.taskId),
            subtitle: t
              .getRequirements()
              .map((a) => a.value.name)
              .join(', '),
            renderActions: () => {
              if (t.status === FlowTaskStatus.COMPLETE) {
                return (
                  <SlideFade in>
                    <Icon as={MdCheckCircle} w={6} h={6} color="brand.green" />
                  </SlideFade>
                );
              }
              if (t.status === FlowTaskStatus.BUSINESS_SKIPPED) {
                return (
                  <SlideFade in>
                    <Icon
                      as={MdFastForward}
                      w={6}
                      h={6}
                      color="brand.dark-gray"
                    />
                  </SlideFade>
                );
              }

              return null;
            },
          }))}
        />

        {showAccessHistory && (
          <Box w="100%" layerStyle="base" py={4} mt={2}>
            <Tabs isLazy isFitted>
              <TabList>
                <Tab>Flow History</Tab>
                {showAccessHistory && <Tab>Access History</Tab>}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <FlowHistory
                    currentFlowId={flow.flowId}
                    flowDesignId={flow.flowDesignId}
                    memberId={flow.memberId}
                  />
                </TabPanel>
                {showAccessHistory && (
                  <TabPanel>
                    <TaskActivity taskId={taskId} />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </GridItem>
      <GridItem colStart={{ base: 1, md: 2 }} colSpan={{ base: 1, md: 2 }}>
        <TaskContent
          setSideBarTaskId={setTaskId}
          flow={flow}
          canEditTask={canEditTask}
          context={context}
          flowStatus={flow.getStatus()}
          taskId={taskId}
          setFlow={setFlow}
          updateFocusedTask={setTaskId}
        />
      </GridItem>
    </Grid>
  );
};

import * as React from 'react';
import { Stack } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';

import { z } from '../../../../helpers';

import { inputs } from '@zap-onboard/web-components';
import { FormActions } from '../FormActions';

type Props = { onClose: () => unknown; isFeatureFlagged: boolean };

export const SendEmail: React.FC<Props> = ({ onClose, isFeatureFlagged }) => {
  const { Layout } = Local;
  const { TextInput, Textarea } = inputs;

  return (
    <Layout onClose={onClose}>
      <TextInput name="subject" label="Subject" />
      <Textarea name="message" label="Message" />
      <FormActions
        buttonText="Send email"
        onClose={onClose}
        isLoading={!isFeatureFlagged}
      />
    </Layout>
  );
};

namespace Local {
  export const Layout: React.FC<{ onClose: () => unknown }> = ({
    onClose,
    children,
  }) => {
    const SendEmailSchema = z.object({
      subject: z.string().min(1),
      message: z.string().min(1),
    });

    const useFormMethods = useForm<z.infer<typeof SendEmailSchema>>({
      defaultValues: {
        subject: '',
        message: '',
      },
      resolver: z.zodResolver(SendEmailSchema),
    });
    const { handleSubmit } = useFormMethods;

    // TODO
    const onSubmit = handleSubmit(({ subject, message }) => {
      console.log({
        subject,
        message,
      });
      onClose();
    });

    return (
      <FormProvider {...useFormMethods}>
        <form onSubmit={onSubmit}>
          <Stack>{children}</Stack>
        </form>
      </FormProvider>
    );
  };
}

import * as React from 'react';
import { Center, useToast } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAPI } from '../../services/API';

import { Spinner } from '../../components/Spinner';
import { track } from '../../libs/track';
import { ExpiredLink } from './ExpiredLink';

const { useEffect, useState } = React;

export const ConfirmRegistration = () => {
  const api = useAPI();
  const history = useHistory();
  const location = useLocation();

  const [failed, hasFailed] = useState(false);
  const toast = useToast();
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (!token) {
      hasFailed(true);
      return;
    }
    api
      .identity()
      .confirmRegistration(token)
      .tap(
        () => {
          track.event('Confirmed Registration');
          history.push('/');
        },
        () => {
          hasFailed(true);
          toast({
            description: 'Unable to confirm your email',
            status: 'info',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);

  return (
    <>
      {failed || (
        <Center
          bgGradient="linear(to-b, brand.blue, brand.dark-blue)"
          minH="100vh"
          minW="100vw"
        >
          <Spinner color="white" />
        </Center>
      )}
      {failed && <ExpiredLink />}
    </>
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-namespace */
import { Checkbox, Icon } from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl } from '../wrappers/FormControl';
// Removes the `isChecked` prop from the `Checkbox` component to stop "unused
// prop" warnings.
var CheckboxIcon = function (_a) {
    var _ = _a.isChecked, __ = _a.isIndeterminate, props = __rest(_a, ["isChecked", "isIndeterminate"]);
    return _jsx(FiCheck, __assign({}, props), void 0);
};
var Single = function (_a) {
    var name = _a.name, isDisabled = _a.isDisabled, isReadOnly = _a.isReadOnly, children = _a.children, passedOnChange = _a.onChange, controlled = __rest(_a, ["name", "isDisabled", "isReadOnly", "children", "onChange"]);
    var control = useFormContext().control;
    return (_jsx(FormControl, __assign({ name: name }, controlled, { children: _jsx(Controller, { control: control, name: name, render: function (_a) {
                var onChange = _a.onChange, value = _a.value, ref = _a.ref, onBlur = _a.onBlur;
                return (_jsx(SingleUncontrolled, __assign({ name: name, isReadOnly: isReadOnly, isDisabled: isDisabled, onBlur: onBlur, ref: ref, isChecked: value, onChange: function () {
                        onChange(!value);
                        passedOnChange === null || passedOnChange === void 0 ? void 0 : passedOnChange(!value);
                    } }, { children: children }), void 0));
            } }, void 0) }), void 0));
};
var SingleUncontrolled = React.forwardRef(function (props, ref) {
    var canEdit = !props.isReadOnly && !props.isDisabled;
    return (_jsx(Checkbox, __assign({ ref: ref, id: props.name, iconColor: "brand.blue", fontWeight: "medium", color: "black", icon: _jsx(Icon, { boxSize: 5, as: CheckboxIcon }, void 0), sx: {
            '.chakra-checkbox__control': {
                borderRadius: '8px',
                border: '2px solid',
                bg: canEdit ? undefined : 'brand.gray',
                borderColor: 'brand.gray',
                transition: 'background-color 0.1s ease-out, border 0.1s ease-out',
                w: 6,
                h: 6,
            },
            ':hover .chakra-checkbox__control': canEdit
                ? {
                    background: 'blue.50',
                    borderColor: 'blue.200',
                }
                : {},
            '.chakra-checkbox__control[data-checked]': {
                background: 'blue.50',
                border: '1px solid',
                borderColor: 'blue.200',
            },
            '.chakra-checkbox__control[data-invalid]': {
                border: '1px solid',
                borderColor: 'brand.red',
            },
            ':hover .chakra-checkbox__control[data-checked]': canEdit
                ? {
                    borderColor: 'blue.300',
                }
                : {},
        } }, props, { children: props.children }), void 0));
});
export { Single, SingleUncontrolled };

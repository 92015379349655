import * as React from 'react';

import { YoutubeVideoFormSchemaItem } from '@zap-onboard/api-client';
import { Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

type Props = {
  schemaItem: YoutubeVideoFormSchemaItem;
  getPath: (...paths: string[]) => string;
};

export const FormTaskYoutubeVideoBlock: React.FC<Props> = ({
  schemaItem,
  getPath,
}) => {
  const { register } = useFormContext();
  return (
    <>
      <Input
        type="hidden"
        value={schemaItem.type}
        name={getPath('type')}
        ref={register}
      />
      <iframe
        src={schemaItem.video.embedURL}
        style={{ width: '100%', height: '500px' }}
        title={`${schemaItem.video.url} embedded video`}
      />
    </>
  );
};

/* eslint-disable no-await-in-loop */
import { useToast } from '@chakra-ui/react';
import { AsyncResult } from 'designed';
import { logError } from '../../../libs';
import { useHandlerQuery } from '../../useHandlerQuery';

export const useXeroEmployees = (args: { enabled?: boolean } = {}) => {
  const potentiallyRateLimitedToast = useToast({
    status: 'info',
    description:
      'This may be due to rate limiting. If you have a large number of employees please contact support',
    isClosable: true,
    duration: 4000,
    title: 'Could not load all of your employees from Xero',
  });

  return useHandlerQuery(
    (api) =>
      AsyncResult.fromPromise(
        (async () => {
          const paginator = await api.xero().getEmployees().getOrThrowFailure();
          try {
            while (paginator.hasNextPage()) {
              await paginator.nextPage();
            }
          } catch (e) {
            potentiallyRateLimitedToast();
            logError(e);
          }
          return paginator.contents();
        })(),
      ),
    {
      enabled: args.enabled,
      key: ['XERO_EMPLOYEES'],
      staleAfter: '20s',
    },
  );
};

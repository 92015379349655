import { Stack, Flex, Text, ButtonGroup, Button } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';

import {
  VisaCheckFormSchema,
  VisaCheckFormValues,
} from './VisaCheckFormSchema';
import { inputs } from '@zap-onboard/web-components';
import {
  AbsoluteDate,
  Country,
  visaCheckDocumentType,
  VisaDetails,
} from '@zap-onboard/api-client';
import { useCreateVisaCheck } from '../../hooks';
import { zodResolver } from '@hookform/resolvers/zod';

const { TextInput, SelectInput } = inputs;

type Props = {
  memberId: string;
  paidVisaCheckBalance: number;
  visaDetailsForMember?: VisaDetails;
  setShowVisaCheckForm: (showVisaCheckForm: boolean) => void;
};

export const VisaCheckForm: React.FC<Props> = ({
  visaDetailsForMember,
  paidVisaCheckBalance,
  memberId,
  setShowVisaCheckForm,
}) => {
  const useFormMethods = useForm<VisaCheckFormValues>({
    defaultValues: {
      givenNames: visaDetailsForMember?.merged.givenNames,
      familyName: visaDetailsForMember?.merged.familyName,
      dateOfBirthDay: visaDetailsForMember?.merged.dateOfBirth?.day,
      dateOfBirthMonth: visaDetailsForMember?.merged.dateOfBirth?.month,
      dateOfBirthYear: visaDetailsForMember?.merged.dateOfBirth?.year,
      documentType: visaDetailsForMember?.merged.documentType,
      documentId: visaDetailsForMember?.merged.documentId,
      countryOfIssue: visaDetailsForMember?.merged.countryOfIssue?.asJSON(),
    },
    resolver: async (data, context, options) => {
      return zodResolver(VisaCheckFormSchema)(data, context, options);
    },
  });
  const { createVisaCheck, isCreateVisaCheckLoading } = useCreateVisaCheck(
    memberId,
    () => setShowVisaCheckForm(false),
  );
  const onSubmit = useFormMethods.handleSubmit((formData) => {
    const dateOfBirth = AbsoluteDate.fromDate(
      new Date(
        Date.UTC(
          formData.dateOfBirthYear,
          formData.dateOfBirthMonth - 1, // Adjust month to be zero-indexed
          formData.dateOfBirthDay,
        ),
      ),
    );
    createVisaCheck({
      ...formData,
      dateOfBirth,
      memberId,
    });
  });

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={onSubmit}>
        <Text opacity={0.5} textAlign="right" px={3} mt={3}>
          Visa check balance {paidVisaCheckBalance}
        </Text>
        <Stack spacing={3}>
          <TextInput name="givenNames" label="Given name" />
          <TextInput name="familyName" label="Family name" />
          <Stack>
            <Text fontWeight="bold">Date of birth</Text>
            <Flex gridColumnGap={2}>
              <TextInput
                name="dateOfBirthDay"
                label="Day"
                placeholder="DD"
                _label={{ fontWeight: 'normal' }}
              />
              <TextInput
                name="dateOfBirthMonth"
                label="Month"
                placeholder="MM"
                _label={{ fontWeight: 'normal' }}
              />
              <TextInput
                name="dateOfBirthYear"
                label="Year"
                placeholder="YYYY"
                _label={{ fontWeight: 'normal' }}
              />
            </Flex>
          </Stack>
          <SelectInput
            name="documentType"
            label="Document type"
            options={visaCheckDocumentType.map(({ code, name }) => ({
              name,
              value: code,
            }))}
          />
          <TextInput name="documentId" label="Document Id" />
          <SelectInput
            name="countryOfIssue"
            label="Country of issue"
            options={Country.options().concat({
              name: 'Select..',
              value: '',
            })}
          />
          <ButtonGroup justifyContent="center" pt={2}>
            <Button
              type="submit"
              isLoading={isCreateVisaCheckLoading}
              isDisabled={isCreateVisaCheckLoading}
            >
              Run visa check
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowVisaCheckForm(false)}
              isDisabled={isCreateVisaCheckLoading}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
    </FormProvider>
  );
};

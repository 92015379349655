import * as React from 'react';

export const userPages = () => {
  const { lazy } = React;

  const FlowDetailPage = lazy(() => import('../FlowDetail'));

  const FlowsPage = lazy(() => import('./Flows'));
  const UserCertificationsPage = lazy(
    () => import('../../certs/UserCertificationsPage'),
  );
  const UserDashboardPage = lazy(() => import('./Dashboard'));

  return {
    FlowDetailPage,
    FlowsPage,
    UserCertificationsPage,
    UserDashboardPage,
  };
};

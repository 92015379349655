import { useHandlerQuery } from '../../useHandlerQuery';

export const useVideo = ({ videoId }: { videoId: string }) => {
  const { data, isLoading } = useHandlerQuery(
    (api) => api.video().getVideo(videoId),
    {
      key: ['VIDEOS', videoId],
      skipRefetch: true,
    },
  );
  return {
    isLoadingVideo: isLoading,
    video: data,
  };
};

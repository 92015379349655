"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperMemberNumber = void 0;
const errors_1 = require("@zap-onboard/errors");
class SuperMemberNumber {
    constructor(value) {
        this.value = value;
    }
    static fake() {
        return SuperMemberNumber.fromJSON('abc123');
    }
    static fromJSON(s) {
        if (typeof s !== 'string') {
            throw errors_1.errs.UnexpectedError.create('Super member number was given a non string value');
        }
        const name = new SuperMemberNumber(s.trim().replace(/[ -]/g, ''));
        name.validate();
        return name;
    }
    static isValid(s) {
        return SuperMemberNumber.validationMessage(s) == null;
    }
    static validationMessage(s) {
        try {
            SuperMemberNumber.fromJSON(s);
            return undefined;
        }
        catch (e) {
            if (e instanceof errors_1.errs.ValidationError) {
                return e.message;
            }
            throw errors_1.errs.UnexpectedError.wrap(e);
        }
    }
    toString() {
        return `SuperMemberNumber{${this.value}}`;
    }
    asJSON() {
        return this.value;
    }
    validate() {
        if (this.value.length < 1) {
            throw errors_1.errs.ValidationError.create('Super member number must be at least one character');
        }
        if (this.value.length > 20) {
            throw errors_1.errs.ValidationError.create('Super member number must be at most 20 characters');
        }
        if (!this.value.match(/^[a-zA-Z0-9]+$/)) {
            throw errors_1.errs.ValidationError.create('Super member number must be only comprised of letters and numbers');
        }
    }
}
exports.SuperMemberNumber = SuperMemberNumber;

import { Box, Text, Stack, useDisclosure, Button } from '@chakra-ui/react';
import { StripeBillingAccountListingResponse } from '@zap-onboard/api-client';
import { DeleteModal } from '../components';
import { useBillingPaymentSummary } from './hooks/useBillingAccountSubscriptions';
import { useBillingMutations } from './hooks/useBillingMutations';
import { InvoiceDisplay } from './InvoiceDisplay';

export const PaymentsPanel = ({
  billingAccount,
}: {
  billingAccount: StripeBillingAccountListingResponse;
}) => {
  const summary = useBillingPaymentSummary({
    billingAccountId: billingAccount.billingAccountId,
  });
  const deleteDisclosure = useDisclosure();
  const { cancelSubscription } = useBillingMutations();

  return (
    <Stack spacing={6}>
      {summary.data?.upcomingInvoice && (
        <Box p={3} layerStyle="outline">
          <Text mb={6} fontWeight="bold" fontSize="sm">
            Upcoming invoice
          </Text>
          <InvoiceDisplay
            displaySummaryOutside
            invoice={summary.data?.upcomingInvoice}
          />
          <Button
            mt={6}
            variant="outline"
            colorScheme="red"
            onClick={deleteDisclosure.onOpen}
          >
            Cancel subscription
          </Button>
          <DeleteModal
            modalHeaderText="Cancel your Subscription?"
            modalBodyText="Are you sure you want to cancel your subscription? Any credits will be applied to the billing account at the end of the billing period."
            confirmText="Cancel subscription"
            cancelText="Keep subscription"
            callBack={() =>
              cancelSubscription({
                billingAccountId: billingAccount.billingAccountId,
              })
            }
            {...deleteDisclosure}
          />
        </Box>
      )}
      <Box p={3} layerStyle="outline">
        <Text mb={6} fontWeight="bold" fontSize="sm">
          Previous invoices
        </Text>

        {summary.data?.invoices?.map((invoice) => (
          <InvoiceDisplay key={invoice.stripeInvoiceId} invoice={invoice} />
        ))}
      </Box>
    </Stack>
  );
};

import { Center } from '@chakra-ui/react';

import { GoogleLogin } from '@react-oauth/google';
import { onError } from '../libs/errorLib';

interface Props {
  onIdentityTokenReturned: (args: { identityToken: string }) => unknown;
  text?: React.ComponentProps<typeof GoogleLogin>['text'];
  context?: React.ComponentProps<typeof GoogleLogin>['context'];
}

export const GoogleConnectButton = (props: Props) => {
  return (
    <Center>
      <GoogleLogin
        onSuccess={({ credential: identityToken }) => {
          if (identityToken) {
            props.onIdentityTokenReturned({ identityToken });
          }
        }}
        shape="pill"
        theme="outline"
        size="large"
        ux_mode="popup"
        logo_alignment="center"
        onError={() => {
          onError('Could not log in with Google');
        }}
        text={props.text ?? 'continue_with'}
        context={props.context ?? 'signin'}
      />
    </Center>
  );
};

export var Button = {
    baseStyle: {
        borderRadius: '3xl',
        _hover: {
            opacity: 0.8,
        },
    },
    sizes: {
        base: {},
        xs: {
            fontSize: 'xs',
            padding: '2',
            minWidth: '60px',
        },
        sm: {
            fontSize: 'sm',
            py: '3',
            px: '5',
            fontWeight: 'normal',
        },
        md: {
            fontSize: 'md',
            padding: '6',
            minWidth: '150px',
        },
    },
    variants: {
        base: {},
        outline: {
            border: '1px solid',
            borderColor: 'brand.blue',
            bg: 'white',
            color: 'brand.blue',
            boxShadow: '0px 5px 15px #000F2F14',
        },
        xero: {
            bg: 'brand.xero.bg',
            color: 'brand.xero.fg',
            boxShadow: '0px 5px 15px #000F2F14',
        },
        myob: {
            bg: 'brand.myob',
            boxShadow: '0px 5px 15px #000F2F14',
            color: 'white',
        },
        google: {
            variant: 'solid',
            bg: 'white',
            border: '1px solid',
            borderColor: 'gray.300',
            shadow: 'sm',
            color: 'gray.500',
            w: '100%',
        },
        solid: {
            bg: 'brand.blue',
            boxShadow: '0 4px 14px 0 rgb(0 118 255 / 26%)',
            color: 'white',
            _hover: {
                bg: 'brand.blue',
            },
        },
        danger: {
            bg: 'brand.red',
            boxShadow: '0px 5px 15px #000F2F14',
            color: 'white',
            _hover: {
                bg: 'brand.red',
            },
        },
        success: {
            bg: 'brand.green',
            color: 'white',
            _hover: {
                bg: 'brand.green',
            },
        },
        secondaryAction: {
            bg: 'brand.dark-gray',
            color: 'white',
            minWidth: '112px',
            borderRadius: 'md',
        },
        options: {
            bg: 'transparent',
            color: 'brand.dark-gray',
        },
    },
    defaultProps: {
        size: 'sm',
        variant: 'solid',
    },
};

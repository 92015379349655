"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertRequirementStatus = exports.CertSubmissionStatus = void 0;
var CertSubmissionStatus;
(function (CertSubmissionStatus) {
    CertSubmissionStatus["UNSUBMITTED"] = "UNSUBMITTED";
    CertSubmissionStatus["SUBMITTED"] = "SUBMITTED";
    CertSubmissionStatus["APPROVED"] = "APPROVED";
    CertSubmissionStatus["REJECTED"] = "REJECTED";
})(CertSubmissionStatus = exports.CertSubmissionStatus || (exports.CertSubmissionStatus = {}));
var CertRequirementStatus;
(function (CertRequirementStatus) {
    CertRequirementStatus["EXEMPT"] = "EXEMPT";
    CertRequirementStatus["ARCHIVED"] = "ARCHIVED";
    CertRequirementStatus["REQUIRED"] = "REQUIRED";
    CertRequirementStatus["NONE"] = "NONE";
})(CertRequirementStatus = exports.CertRequirementStatus || (exports.CertRequirementStatus = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeputyLinkEmployeeRequest = exports.DeputyCreateEmployeeRequest = exports.DeputyLocationListResponse = exports.DeputyEmployeeDetailsResponse = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const utils_1 = require("../../utils");
const ContactNumber_1 = require("../../utils/contactNumber/ContactNumber");
const AbsoluteDate_1 = require("../../utils/time/AbsoluteDate");
class DeputyEmployeeDetailsResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DeputyEmployeeDetailsResponse.prototype, "archivedInDeputy", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DeputyEmployeeDetailsResponse.prototype, "hasAcceptedInvite", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], DeputyEmployeeDetailsResponse.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyEmployeeDetailsResponse.prototype, "deputyEmployeeId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyEmployeeDetailsResponse.prototype, "deputyLocationId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyEmployeeDetailsResponse.prototype, "deputyAvatarURL", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyEmployeeDetailsResponse.prototype, "manageLink", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyEmployeeDetailsResponse.prototype, "viewBusinessLink", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyEmployeeDetailsResponse.prototype, "deputyName", void 0);
exports.DeputyEmployeeDetailsResponse = DeputyEmployeeDetailsResponse;
class DeputyLocationListResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyLocationListResponse.prototype, "deputyLocationId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyLocationListResponse.prototype, "name", void 0);
exports.DeputyLocationListResponse = DeputyLocationListResponse;
class DeputyCreateEmployeeRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyCreateEmployeeRequest.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyCreateEmployeeRequest.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Email),
    (0, tslib_1.__metadata)("design:type", utils_1.Email)
], DeputyCreateEmployeeRequest.prototype, "email", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(ContactNumber_1.ContactNumber),
    (0, tslib_1.__metadata)("design:type", ContactNumber_1.ContactNumber)
], DeputyCreateEmployeeRequest.prototype, "mobileNumber", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.MaxLength)(64),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyCreateEmployeeRequest.prototype, "firstName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.MaxLength)(64),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyCreateEmployeeRequest.prototype, "lastName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyCreateEmployeeRequest.prototype, "deputyLocationId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], DeputyCreateEmployeeRequest.prototype, "sendInvite", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsIn)(['PREFER_NOT_TO_SAY', 'MALE', 'FEMALE', 'NON_BINARY']),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyCreateEmployeeRequest.prototype, "gender", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(AbsoluteDate_1.AbsoluteDate),
    (0, tslib_1.__metadata)("design:type", AbsoluteDate_1.AbsoluteDate)
], DeputyCreateEmployeeRequest.prototype, "dateOfBirth", void 0);
exports.DeputyCreateEmployeeRequest = DeputyCreateEmployeeRequest;
class DeputyLinkEmployeeRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyLinkEmployeeRequest.prototype, "type", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeputyLinkEmployeeRequest.prototype, "userId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsPositive)(),
    (0, tslib_1.__metadata)("design:type", Number)
], DeputyLinkEmployeeRequest.prototype, "deputyEmployeeId", void 0);
exports.DeputyLinkEmployeeRequest = DeputyLinkEmployeeRequest;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteTemplateRequest = exports.DemoTemplateResponse = exports.DemoTemplateRequest = exports.GetTemplatesRequest = exports.TemplateResponse = exports.CreateTemplateRequest = exports.GroupResponse = exports.CreateGroupRequest = exports.GROUP_COLOR_TO_HEX = exports.GROUP_COLORS = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const FileClaim_1 = require("./FileClaim");
const TaskAssignmentRequest_1 = require("../flow/task/TaskAssignmentRequest");
const ResponseBase_1 = require("../ResponseBase");
const pdf_1 = require("./pdf");
const RemoteFile_1 = require("./RemoteFile");
(0, tslib_1.__exportStar)(require("./TemplateSharing"), exports);
exports.GROUP_COLORS = [
    'BLUE',
    'CYAN',
    'GREEN',
    'YELLOW',
    'PINK',
    'ORANGE',
];
exports.GROUP_COLOR_TO_HEX = {
    BLUE: '#4A90E2',
    CYAN: '#50E3C2',
    GREEN: '#7ED321',
    YELLOW: '#F5A623',
    PINK: '#D0021B',
    ORANGE: '#F8E71C',
};
class BaseTemplateGroup extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTemplateGroup.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], BaseTemplateGroup.prototype, "instructions", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: pdf_1.Schema.Field.CheckboxGroup, iterable: true }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(pdf_1.Schema.Field.CheckboxGroup, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], BaseTemplateGroup.prototype, "checkboxGroups", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: pdf_1.Schema.Field.Union, iterable: true }),
    (0, class_validator_1.IsInstance)(pdf_1.Schema.Field.Union, { each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, tslib_1.__metadata)("design:type", Array)
], BaseTemplateGroup.prototype, "items", void 0);
class CreateGroupRequest extends BaseTemplateGroup {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateGroupRequest.prototype, "groupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TaskAssignmentRequest_1.TaskAssignmentRequest }),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(TaskAssignmentRequest_1.TaskAssignmentRequest, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], CreateGroupRequest.prototype, "defaultAssignments", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(exports.GROUP_COLORS),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], CreateGroupRequest.prototype, "color", void 0);
exports.CreateGroupRequest = CreateGroupRequest;
/**
 * Do not add sensitive data to this model without verifying it should also be
 * seen by all signers in the template task.
 */
class GroupResponse extends BaseTemplateGroup {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GroupResponse.prototype, "groupId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: TaskAssignmentRequest_1.TaskAssignmentRequest }),
    (0, class_validator_1.IsInstance)(TaskAssignmentRequest_1.TaskAssignmentRequest, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], GroupResponse.prototype, "defaultAssignments", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(exports.GROUP_COLORS),
    (0, tslib_1.__metadata)("design:type", Object)
], GroupResponse.prototype, "color", void 0);
exports.GroupResponse = GroupResponse;
class CreateTemplateRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateTemplateRequest.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], CreateTemplateRequest.prototype, "anonymous", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateTemplateRequest.prototype, "templateId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Number)
], CreateTemplateRequest.prototype, "version", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], CreateTemplateRequest.prototype, "template", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], CreateTemplateRequest.prototype, "useOrderedSigning", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: CreateGroupRequest }),
    (0, class_validator_1.IsInstance)(CreateGroupRequest, { each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, tslib_1.__metadata)("design:type", Array)
], CreateTemplateRequest.prototype, "groups", void 0);
exports.CreateTemplateRequest = CreateTemplateRequest;
class TemplateResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateResponse.prototype, "name", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], TemplateResponse.prototype, "templateId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsNumber)(),
    (0, tslib_1.__metadata)("design:type", Number)
], TemplateResponse.prototype, "version", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(FileClaim_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", FileClaim_1.FileClaim)
], TemplateResponse.prototype, "template", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], TemplateResponse.prototype, "useOrderedSigning", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: GroupResponse }),
    (0, class_validator_1.IsInstance)(GroupResponse, { each: true }),
    (0, class_validator_1.ArrayMinSize)(1),
    (0, tslib_1.__metadata)("design:type", Array)
], TemplateResponse.prototype, "groups", void 0);
exports.TemplateResponse = TemplateResponse;
class GetTemplatesRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetTemplatesRequest.prototype, "templateId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetTemplatesRequest.prototype, "includeDeleted", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsBoolean)(),
    (0, tslib_1.__metadata)("design:type", Boolean)
], GetTemplatesRequest.prototype, "includeAnonymous", void 0);
exports.GetTemplatesRequest = GetTemplatesRequest;
class DemoTemplateRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DemoTemplateRequest.prototype, "templateId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(pdf_1.Schema.Submission),
    (0, tslib_1.__metadata)("design:type", pdf_1.Schema.Submission)
], DemoTemplateRequest.prototype, "submission", void 0);
exports.DemoTemplateRequest = DemoTemplateRequest;
class DemoTemplateResponse extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(RemoteFile_1.TemporaryRemoteFile),
    (0, tslib_1.__metadata)("design:type", RemoteFile_1.TemporaryRemoteFile)
], DemoTemplateResponse.prototype, "generated", void 0);
exports.DemoTemplateResponse = DemoTemplateResponse;
class DeleteTemplateRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeleteTemplateRequest.prototype, "templateId", void 0);
exports.DeleteTemplateRequest = DeleteTemplateRequest;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSchemaValidator = void 0;
const errors_1 = require("@zap-onboard/errors");
class FormSchemaValidator {
    constructor(schemaItems, items, extra) {
        this.schemaItems = schemaItems;
        this.items = items;
        this.extra = extra;
    }
    static async apply(schema, items, extra) {
        const validator = new FormSchemaValidator(schema.toIndexedItems(), items, extra);
        await validator.validate();
    }
    async validate() {
        if (this.items.length !== Object.keys(this.schemaItems).length) {
            throw errors_1.errs.ValidationError.create('schema does not have the same length as submission', {
                details: {
                    items: this.items,
                    schemaItems: this.schemaItems,
                },
            });
        }
        await Promise.all(this.items.map((item) => this.validateItem(item)));
    }
    async validateItem(item) {
        const schemaForItem = this.schemaItems[item.value.formSchemaItemId];
        if (!schemaForItem) {
            throw errors_1.errs.ValidationError.create('no schema exists for item', {
                details: { item },
            });
        }
        if (schemaForItem.type !== item.value.type) {
            throw errors_1.errs.ValidationError.create('item in submission is not the same type as schema', {
                details: { item, schemaForItem },
            });
        }
        await schemaForItem.validateItem({
            ...this.extra,
            item: item.value,
        });
    }
}
exports.FormSchemaValidator = FormSchemaValidator;

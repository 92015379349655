/* eslint-disable no-plusplus */
import * as React from 'react';
import { Box, Collapse, Stack, Text } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

import { NotificationListing } from '@zap-onboard/api-client';
import { useNotificationContext } from '../notificationContext';

import { EmptyState } from '../../../../../components';
import { NotificationItem } from '../NotificationItem';
import { useMemo } from 'react';
import { max } from './max';

export const NotificationsList: React.FC = () => {
  const {
    notificationDrawer$,
    notifications,
    hiddenNotificationIds,
    markNotificationAsRead,
    visitNotification,
    unread,
  } = useNotificationContext();

  const notificationsGroupedByBusiness = useMemo(() => {
    const indexed: { [businessName: string]: NotificationListing[] } = {};

    [...(notifications ?? [])]
      ?.sort((a, b) => b.timestamp.valueOf() - a.timestamp.valueOf())
      ?.forEach((n) => {
        const idx = n.business?.businessName ?? 'General Updates';
        indexed[idx] ||= [];
        indexed[idx].push(n);
      });

    const byBusiness = Object.entries(indexed)
      .map(
        ([name, notifications]) =>
          [
            name,
            notifications,
            max(notifications, (a, b) => a.timestamp < b.timestamp),
          ] as const,
      )
      .sort(
        ([, , a], [, , b]) => a.timestamp.valueOf() - b.timestamp.valueOf(),
      );

    return byBusiness;
  }, [notifications]);

  function handleNavigation(n: NotificationListing) {
    if (n.link) {
      return (() => {
        visitNotification(n.notificationId);
        notificationDrawer$.publish({ action: 'CLOSE' });
      })();
    }
    return undefined;
  }

  if (!notifications) {
    return <EmptyState message="There are no notifications" />;
  }

  if (unread.length === 0) {
    return (
      <EmptyState
        icon={MdCheckCircle}
        iconColor="brand.green"
        message="All done! 👏"
      />
    );
  }

  return (
    <Layout>
      {notificationsGroupedByBusiness.map(([businessName, notifications]) => (
        <Collapse
          key={businessName}
          unmountOnExit
          in={
            !notifications.every((n) =>
              hiddenNotificationIds.has(n.notificationId),
            )
          }
        >
          <Text>{businessName}</Text>
          <Box p={3}>
            {notifications.map((n, index) => (
              <Collapse
                key={n.notificationId}
                unmountOnExit
                in={!hiddenNotificationIds.has(n.notificationId)}
              >
                <NotificationItem
                  handleNavigation={() => handleNavigation(n)}
                  notification={n}
                  markAsRead={() => {
                    markNotificationAsRead(n.notificationId);
                  }}
                  index={index}
                />
              </Collapse>
            ))}
          </Box>
        </Collapse>
      ))}
    </Layout>
  );
};

const Layout: React.FC = ({ children }) => (
  <Stack px={4} spacing={2}>
    {children}
  </Stack>
);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Get = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const ResponseBase_1 = require("../../ResponseBase");
const PrivateBusinessConfig_1 = require("./PrivateBusinessConfig");
const PublicBusinessBillingStatusResponse_1 = require("./PublicBusinessBillingStatusResponse");
const PublicBusinessDetails_1 = require("./PublicBusinessDetails");
var Get;
(function (Get) {
    let Business;
    (function (Business) {
        class Response extends ResponseBase_1.ResponseBase {
        }
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(PublicBusinessDetails_1.PublicBusinessDetailsResponse),
            (0, tslib_1.__metadata)("design:type", PublicBusinessDetails_1.PublicBusinessDetailsResponse)
        ], Response.prototype, "business", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsInstance)(PublicBusinessBillingStatusResponse_1.PublicBusinessBillingStatusResponse),
            (0, tslib_1.__metadata)("design:type", PublicBusinessBillingStatusResponse_1.PublicBusinessBillingStatusResponse)
        ], Response.prototype, "billingStatus", void 0);
        (0, tslib_1.__decorate)([
            designed_1.Entity.Field(),
            (0, class_validator_1.IsOptional)(),
            (0, class_validator_1.IsInstance)(PrivateBusinessConfig_1.PrivateBusinessConfig.Response),
            (0, tslib_1.__metadata)("design:type", PrivateBusinessConfig_1.PrivateBusinessConfig.Response)
        ], Response.prototype, "config", void 0);
        Business.Response = Response;
    })(Business = Get.Business || (Get.Business = {}));
})(Get = exports.Get || (exports.Get = {}));

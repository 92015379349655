"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoClient = exports.GetVideoRequest = exports.DeleteVideoRequest = exports.UpdateVideoRequest = exports.CreateVideoRequest = void 0;
const tslib_1 = require("tslib");
const class_validator_1 = require("class-validator");
const designed_1 = require("designed");
const file_1 = require("../file");
const ResponseBase_1 = require("../ResponseBase");
const handler_1 = require("../utils/handler");
const models_1 = require("./models");
class CreateVideoRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, tslib_1.__metadata)("design:type", String)
], CreateVideoRequest.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], CreateVideoRequest.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true, entity: file_1.FileClaim }),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], CreateVideoRequest.prototype, "thumbnailClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], CreateVideoRequest.prototype, "videoClaim", void 0);
exports.CreateVideoRequest = CreateVideoRequest;
class UpdateVideoRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateVideoRequest.prototype, "videoId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], UpdateVideoRequest.prototype, "title", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true }),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpdateVideoRequest.prototype, "description", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ nullable: true, entity: file_1.FileClaim }),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", Object)
], UpdateVideoRequest.prototype, "thumbnailClaim", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsInstance)(file_1.FileClaim),
    (0, tslib_1.__metadata)("design:type", file_1.FileClaim)
], UpdateVideoRequest.prototype, "videoClaim", void 0);
exports.UpdateVideoRequest = UpdateVideoRequest;
class DeleteVideoRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], DeleteVideoRequest.prototype, "videoId", void 0);
exports.DeleteVideoRequest = DeleteVideoRequest;
class GetVideoRequest extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], GetVideoRequest.prototype, "videoId", void 0);
exports.GetVideoRequest = GetVideoRequest;
class VideoClient {
    constructor(http, $error) {
        this.http = http;
        this.$error = $error;
        this.createVideo = (0, handler_1.handler)(this, async (video) => this.http
            .request()
            .to('/videos')
            .bodyJSON(CreateVideoRequest.create(video))
            .post());
        this.getVideo = (0, handler_1.handler)(this, async (videoId) => this.http
            .request()
            .to(`/videos/${videoId}`)
            .get((d) => models_1.Video.create(d)));
        this.getVideos = (0, handler_1.handler)(this, async () => this.http
            .request()
            .to('/videos')
            .get((d) => models_1.VideoList.create(d)));
        this.updateVideo = (0, handler_1.handler)(this, async (updateVideo) => this.http
            .request()
            .to('/videos/update')
            .bodyJSON(UpdateVideoRequest.create(updateVideo))
            .post());
        this.deleteVideo = (0, handler_1.handler)(this, async (deleteVideo) => this.http
            .request()
            .to('/videos')
            .bodyJSON(DeleteVideoRequest.create(deleteVideo))
            .delete());
    }
}
exports.VideoClient = VideoClient;

import React from 'react';
import { ReactComponent as BlueIcon } from './settings-blue.svg';
import { ReactComponent as WhiteIcon } from './settings-white.svg';
import { BaseIconProps } from '../BaseIcon';

type SettingsIconProps = BaseIconProps;

export const SettingsIcon: React.FC<SettingsIconProps> = ({
  iconColor,
  width,
  height,
}) =>
  iconColor === 'white' ? (
    <WhiteIcon width={width} height={height} />
  ) : (
    <BlueIcon width={width} height={height} />
  );

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VisaCheckList = exports.VisaCheck = exports.visaCheckDocumentType = void 0;
const tslib_1 = require("tslib");
const designed_1 = require("designed");
const class_validator_1 = require("class-validator");
const utils_1 = require("../../utils");
const ResponseBase_1 = require("../../ResponseBase");
exports.visaCheckDocumentType = [
    { name: 'Passport', code: 'PASSPORT' },
    { name: 'Immicard', code: 'IMMICARD' },
    { name: 'DFTTA', code: 'DFTTA' },
    { name: 'PL056', code: 'PL056' },
    { name: 'Titre de Voyage', code: 'TITRE_DE_VOYAGE' },
];
class VisaCheck extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "visaCheckId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsUUID)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "memberId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.TimeAndDate),
    (0, tslib_1.__metadata)("design:type", utils_1.TimeAndDate)
], VisaCheck.prototype, "createdAt", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "givenNames", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "familyName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], VisaCheck.prototype, "dateOfBirth", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "documentType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "documentId", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.Country),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", utils_1.Country)
], VisaCheck.prototype, "countryOfIssue", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['OK', 'Error']),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "result", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsIn)(['Primary', 'Secondary']),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "visaApplicant", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "visaClass", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "visaType", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "visaTypeName", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], VisaCheck.prototype, "grantDate", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsInstance)(utils_1.AbsoluteDate),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", utils_1.AbsoluteDate)
], VisaCheck.prototype, "expiryDate", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "visaTypeDetails", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "workEntitlement", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "conditionsIds", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "conditions", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "location", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "error", void 0);
(0, tslib_1.__decorate)([
    designed_1.Entity.Field(),
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    (0, tslib_1.__metadata)("design:type", String)
], VisaCheck.prototype, "status", void 0);
exports.VisaCheck = VisaCheck;
class VisaCheckList extends ResponseBase_1.ResponseBase {
}
(0, tslib_1.__decorate)([
    designed_1.Entity.Field({ entity: VisaCheck }),
    (0, class_validator_1.IsInstance)(VisaCheck, { each: true }),
    (0, tslib_1.__metadata)("design:type", Array)
], VisaCheckList.prototype, "visaChecks", void 0);
exports.VisaCheckList = VisaCheckList;

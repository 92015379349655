import { FileClaim } from '@zap-onboard/api-client';
import { z } from '../../../../../helpers/schema';

const photographicPrimarySubmission = z.object({
  identificationType: z.enum(['PHOTOGRAPHIC']),
  primaryDocument: z.instanceof(FileClaim, {
    message: 'Upload a file',
  }),
});

const nonPhotographicPrimarySubmission = z.object({
  identificationType: z.enum(['NON_PHOTOGRAPHIC']),
  primaryDocument: z.instanceof(FileClaim, {
    message: 'Upload a file',
  }),
  secondaryDocument: z.instanceof(FileClaim, {
    message: 'Upload a file',
  }),
});

export const formSchema = photographicPrimarySubmission.or(
  nonPhotographicPrimarySubmission,
);

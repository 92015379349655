import * as React from 'react';
import { Flex, Stack } from '@chakra-ui/react';

import { EmptyState, LoadingState } from '../States';

type ActionItemListProps = {
  emptyStateMessage?: string;
  isEmpty: boolean;
  isLoading: boolean;
};

export const ActionItemList: React.FC<ActionItemListProps> = ({
  emptyStateMessage,
  isEmpty,
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <Layout>
        <LoadingState layoutProps={{ h: '100%' }} />
      </Layout>
    );
  }

  if (isEmpty) {
    return (
      <Layout>
        <EmptyState
          message={emptyStateMessage || 'No tasks require action'}
          layoutProps={{ h: '100%' }}
        />
      </Layout>
    );
  }

  return <Layout>{children}</Layout>;
};

const Layout: React.FC = ({ children }) => (
  <Flex h="100%" w="100%">
    <Stack spacing={2} minH={{ base: '120px', lg: '240px' }} w="100%">
      {children}
    </Stack>
  </Flex>
);
